
import {
  // GET_DATASET_ALL,
  // DELETE_DATASET,
  // DELETE_DATASET_MULTI,
  // SET_FAV,
  // UNSET_FAV,
  // UPLOAD_FILE,
  POST_CHART_FORM,
  GET_DATASET_BY_ID,
} from "@/store/actionType";

export function openDatasetViz(item) {
  console.log('open me!', item)
  let params = {
    datasource: item.id,
    viz_type: 'table'
  };
  
  this.loading = true;
  this.$store.dispatch(GET_DATASET_BY_ID, item.id)
    .then( (res) => {
      let result = res.data.result
      let all_cols = result.columns.map(a => a.column_name)
      params.all_columns=all_cols;
      // Post Chart by form
      console.log('open sesame!', params)
      this.$store.dispatch(POST_CHART_FORM, params).then((res) => {
        this.$router.push(`/visualizations/null/${res.data.key}`);
        this.loading = false;
        params={};

  //       setTimeout(() => {
  //         // this.$store.commit("SELECTED_PIE_METRIC", null);
  //         // this.$store.commit("SELECTED_HEAT_METRIC", null);
  //         this.$router.push(`/visualizations/null/${res.data.key}`);
  //       }, 1000);
  //       // this.loading = false;
        // params={};
      })
      .catch((error) => {
        console.error('error in chart form post', error)
      })
    })
    .catch((error) => {
        console.error('error in dataset get by id', error)
      });
}