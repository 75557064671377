


export const ChartRowActions= {
  'edit':true, 
  'info':true, 
  'trashcan':false, 
  'action': true
}


export const ProjectRowActions= {
  'edit':true, 
  'info':true, 
  'trashcan':false, 
  'action': true
}

export const DashboardRowActions= {
    'edit':true, 
    'info':true, 
    'trashcan':false, 
    'action': true
}

export const DatasetRowActions= {
  'edit':true, 
  'info':true, 
  'trashcan':false, 
  'action': true
}