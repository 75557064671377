import CharService from "../services/CharService";

import {
  TRAIN_MODEL,
  GET_DATASET_CHAR_BY_ID,
  CREATE_CHAR,
  GET_CHARS,
  CREATE_PREDICTION,
  GET_PREDICTION,
  GET_EVALUATION,
  PUT_DATASET_CHAR_INDEX,
  DELETE_CHAR,
  TRAIN_EMBEDDING,
  POST_EMBEDDING,
  GET_EMBEDDING,
} from "./actionType";

const state = {};

const actions = {
  [CREATE_CHAR](_, params) {
    return CharService.create(params).then(
      res => {
        return res;
      }
    )
  },
  [DELETE_CHAR](_, id) {
    return CharService.deleteChar(id).then(
      res => {
        return res;
      }
    )
  },
  [TRAIN_MODEL](_, params) {
    return CharService.trainModels(params.charIDs, params.n_rows).then(
      res => {
        return res;
      }
    )
  },
  [GET_DATASET_CHAR_BY_ID](_, id) {
    return CharService.getDatasetChars(id).then(
      res => {
        return res;
      }
    )
  },
  [PUT_DATASET_CHAR_INDEX](_, params) {
    let id = params[0];
    let colId = params[1];
    console.log('char mod', id, colId)
    return CharService.putDatasetCharIndex(id, colId).then(
      res => {
        return res;
      }
    )
  },
  [GET_CHARS](_, params) {
    return CharService.getChars(params).then(
      res => {
        return res;
      }
    )
  },
  [CREATE_PREDICTION](_, params) {
    return CharService.createPrediction(
      params.modelId, 
      params.n_rows, 
      {datasetId: params.datasetId}
    ).then(
      res => {
        return res;
      }
    )
  },
  [GET_PREDICTION](_, params) {
    return CharService.getPrediction(
      params.jobId,
      params.modelId,
      params.datasetId,
      params.postPredictions,
    ).then(
      res => {
        return res;
      }
    )
  },
  [GET_EVALUATION](_, params) {
    return CharService.getEvaluation(
      params.modelId,
      params.postPredictions,
    ).then(
      res => {
        return res;
      }
    )
  },
  [TRAIN_EMBEDDING](_, params) {
    return CharService.trainEmbedding(
      params.modelId,
      params.n_rows,
    ).then(
      res => {
        return res;
      }
    )
  },
  [POST_EMBEDDING](_, params) {
    return CharService.postEmbedding(
      params.modelId,
      params.n_clusters,
      params.n_rows,
      params.body
    ).then(
      res => {
        return res;
      }
    )
  },
  [GET_EMBEDDING](_, params) {
    return CharService.getEmbedding(
      params.modelId,
      params.postData,
      params.datasetId,
      params.n_rows,
    ).then(
      res => {
        return res;
      }
    )
  }
};

const mutations = {};

export default {
  state,
  actions,
  mutations
}