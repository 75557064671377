var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex flex-column fill-height flex-grow-1 pt-6"},[_c('NewDB',{attrs:{"showNewDB":_vm.showNewDB},on:{"cancel":_vm.cancelNewDB}}),_c('NewFromDB',{attrs:{"showNewFromDB":_vm.showNewFromDB},on:{"cancel":_vm.cancelNewFromDB,"createDB":_vm.showCreateDB,"goToDatasets":_vm.goToDatasets}}),_c('NewFromFile',{attrs:{"showNewFromFile":_vm.showNewFromFile},on:{"cancel":_vm.cancelNewFromFile,"showAdvance":_vm.showAdvance,"save_file":_vm.saveFile}}),_c('NewFromFileAdvance',{attrs:{"showNewFromFileAdvance":_vm.showNewFromFileAdvance},on:{"cancel":_vm.cancelNewAdvance,"save":_vm.saveNewAdvance}}),_c('ModuleHeader',{attrs:{"title":"Datasets","disabled-grid-btn":true,"disabled-list-btn":true,"view-grid":false},on:{"updateViewFavOnly":function($event){_vm.viewFavOnly = !_vm.viewFavOnly}}}),_c('ModuleAction',{attrs:{"title":"Dataset","search-label":"Search Datasets","selectedItems":_vm.selectedItemIds,"menuItems":_vm.menuItems,"n_items":_vm.filteredItemCount},on:{"menuEvent":_vm.menuEvent,"deleteAll":_vm.deleteAll,"selectItemsEvent":_vm.selectItemsEvent,"moduleFilterChanged":_vm.moduleFilterChanged}}),_c('div',{staticClass:"data-table d-flex\n      flex-grow-1 flex-column flex-basis-0\n      fill-height overflow-x-auto\n    "},[_c('v-container',[(_vm.loading)?_c('v-row',{staticClass:"container-row-resolution py-5 d-flex justify-center"},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary"}})],1):(_vm.getModels(_vm.collection, _vm.viewFavOnly).length)?_c('v-data-table',{staticClass:"mt-3",attrs:{"id":"tblItems","fixed-header":"","items":_vm.getModels(_vm.collection, _vm.viewFavOnly),"items-per-page":_vm.$pageItemCount,"headers":_vm.headers,"search":_vm.search,"show-select":"","item-key":"id","hide-default-footer":true,"header-props":{ sortIcon: 'mdi-chevron-down' }},scopedSlots:_vm._u([{key:"top",fn:function(ref){
var pagination = ref.pagination;
var options = ref.options;
var updateOptions = ref.updateOptions;
return [_c('v-data-footer',{attrs:{"pagination":pagination,"options":options,"items-per-page-text":"$vuetify.dataTable.itemsPerPageText"},on:{"update:options":updateOptions}})]}},{key:"header.favorite",fn:function(ref){
var header = ref.header;
return [(header.favorite)?_c('v-icon',[_vm._v("mdi-star")]):_c('v-icon',[_vm._v("mdi-star-outline")])]}},{key:"item.favorite",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"actions",on:{"click":function($event){$event.stopPropagation();return _vm.toggleFav(item)}}},[_c('v-btn',{directives:[{name:"tippy",rawName:"v-tippy"}],attrs:{"icon":"","width":"24","content":"Favorite"}},[(item.favorite)?_c('v-icon',[_vm._v("mdi-star")]):_c('v-icon',[_vm._v("mdi-star-outline")])],1)],1)]}},{key:"item.table_name",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"title",on:{"click":function($event){$event.stopPropagation();}}},[_c('a',{on:{"click":function($event){return _vm.openDatasetViz(item)}}},[_vm._v(_vm._s(item.table_name))])])]}},{key:"item.changed_by.username",fn:function(ref){
var item = ref.item;
return [(item.created_by)?_c('div',{staticClass:"actions"},[_vm._v(" "+_vm._s(item.created_by.username)+" ")]):_vm._e()]}},{key:"item.database.database_name",fn:function(ref){
var item = ref.item;
return [(item.database)?_c('div',{staticClass:"actions"},[_c('v-chip',{attrs:{"color":"primary"}},[_vm._v(_vm._s(item.database.database_name))])],1):_vm._e()]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('RowAction',{attrs:{"item":item,"disabledList":_vm.disabledRowActions},on:{"editItem":_vm.editItem,"deleteItem":_vm.deleteItem,"infoItem":_vm.infoItem}})]}}],null,true),model:{value:(_vm.selectedItems),callback:function ($$v) {_vm.selectedItems=$$v},expression:"selectedItems"}}):_c('v-row',{staticClass:"container-row-resolution pt-5 d-flex justify-center"},[_c('p',[_vm._v("No Datasets Found ...")])])],1)],1),(_vm.showSnackbar)?_c('SnackbarMessage',{attrs:{"showSnackbar":_vm.showSnackbar,"snackbarError":_vm.snackbarError,"snackbarMsg":_vm.snackbarMsg,"color":_vm.snackbarColor},on:{"closeSnackbar":_vm.closeSnackbar}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }