<template>
<v-dialog v-model="showRename" 
    @click:outside="cancel"
    max-width="300px"
    >
    <v-card>
        <v-toolbar>Rename Tab</v-toolbar>
        <v-card-text class="pl-0 pr-0 pt-2">
            <v-container>
                <p>Tab Name: </p>
                <v-text-field 
                    v-model="name" 
                    outlined 
                    required
                    :rules="[rules.isEmpty, rules.isBlank]"
                    v-on:keyup.enter.stop="rename"
                ></v-text-field>
            </v-container>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn 
                color="white" plain @click="rename"
                :disabled="emptyQueryName"
            >Rename</v-btn>
            <v-btn color="white" plain @click="cancel">Cancel</v-btn>
        </v-card-actions>
    </v-card>
</v-dialog>
</template>

<script>
export default {
    props: {
        showRename: {
            type: Boolean,
            default: false
        },
        selectedTab: {
            type: String,
            default: null
        },
        tabName: {
            type: String,
            default: null
        }
    },
    data() {
        return {
            childName: "",
            rules: {
                isEmpty: value => !!value || 'Required.',
                isBlank: value => value.trim() !== '' || 'Empty chart title',
            },
        }
    },
    mounted() {
        // this.childName= this.name
    },
    computed: {
        name: {
            get() {
                // console.log("getter", this.tabName)
                return this.tabName;
            },
            set(value) {
                
                this.childName = value
                // console.log("setter", value, this.childName)
            },
        },
        emptyQueryName() {
            if (this.name.length <=0) {
            return true
            } else {
                return false
            }
        },
    },
    methods: {
        /**
         * rename action
         */
        rename(e) {
            this.$emit('rename', this.childName, this.selectedTab);

        },
        /**
         * cancel action
         */
        cancel() {
            // TODO: minor Bug, this doesn't reset the tabName to original name
            // if canceled on subsequent rename attempts
            this.$emit("cancel");
        },
    }
}
</script>

<style>

</style>
