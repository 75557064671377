<template>
<!-- <div> -->
<!-- {{ pillWidth }} -->
  <!-- v-if="column.applied" -->
  <!-- :color="column.applied ? false: column.type_generic === 0 ? '#1bbab9' : '#00A7FF'" -->
  <!--  -->
  <!-- :outlined="!column.applied"
    :class="
      column.type_generic === 0
        ? 'column-numeric-btn'
        : 'column-text-btn'
    "
    :color="!column.applied ? 
      'false': column.type_generic === 0 ? 
        '#1bbab9' : 
        '#00A7FF'
      " -->
  <v-btn class="mb-08"
    :outlined="!column.applied"
    :class="
      column.type_generic === 0
        ? 'column-numeric-btn'
        : 'column-text-btn'
    "
    :color="!column.applied ? 
      'false': column.type_generic === 0 ? 
        '#1bbab9' : 
        '#00A7FF'
      "
    rounded
    :width="pillWidth"
    height="24px"
  >
    <span class="ml-auto mr-auto">
      {{ column.column_name }}
    </span>
    <v-icon @click="$emit('deleteCol')" 
      class="ml-auto" right
    >
      mdi-close-circle
    </v-icon>
  </v-btn>

  <!-- <v-btn
    v-else
    class="mb-08"
    :class="
      column.type_generic === 0
        ? 'column-numeric-btn'
        : 'column-text-btn'
    "
    rounded
    width="100%"
    height="24px"
    outlined
  >
    <span class="ml-auto mr-auto">
      {{ column.column_name }}
    </span>
    <v-icon 
      @click="$emit('deleteCol')" 
      class="ml-auto" right
    >
      mdi-close-circle
    </v-icon>
  </v-btn> -->
<!-- </div> -->
</template>


<script>
export default {
  name: "VisModePill",
  data() {
    return {};
  },
  props: {
    isCalculated: {
      type: Boolean,
      default() {
        return false;
      }
    },
    calc_card: {
      type: Boolean,
      default() {
        return false;
      }
    },
    pillWidth: {
      type: String,
      default() {
        return "100%";
      },
    },
    // index: {
    //   type: Number,
    //   default() {
    //     return null;
    //   }
    // },
    column: {
      type: Object,
      default() {
        return null;
      },
    },
    // validVisCols: {
    //   type: Object,
    //   default() {
    //     return null;
    //   },
    // },
  },
  methods: {
    // isApplied(colname) {
    //   // const axis = 'all_c'
    //   console.log('current active cols', this.mode_active_cols.all_columns)
    //   return this.mode_active_cols.all_columns[colname]?.applied;
    // },
    // getSimpleCol(val) {
    //   console.log('getsimple', val, this.isApplied(val))
    //   return {
    //     column_name: this.form_data[val],
    //     type_generic: 0,
    //     // applied: false,
    //     applied: this.isApplied(val),
    //     }
    // },
  }
};
</script>

<style lang="scss" scoped>
.column-btn {
  border-color: #1bbab9 !important;
}

.column-numeric-btn {
  border-color: #1bbab9 !important;
}

.column-text-btn {
  border-color: #00a7ff !important;
}

</style>