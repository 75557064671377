import { mainAxios } from "./axios";

class SecurityService {
	getAll() {
		// const z = mainAxios.get(`/security/users`)
		// console.log('service', z)
		return mainAxios.get(`/security/users`);
	}
	registerUser(data) {
		return mainAxios.post(`/security/register_user`, data);
	}
	resetUser(data) {
		return mainAxios.post(`/security/users/resetpassword`, data);
	}
}

export default new SecurityService();
