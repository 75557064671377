import SqlpageService from "../services/SqlpageService";

import {
  GET_DB_ALL,
  GET_SCHEMA_ALL,
  GET_TABLE_ALL,
  FETCH_TABLE,
  RUN_QUERY,
  GET_QUERY_HISTORY_ALL
} from "./actionType";
import {
  SQLLAB_SELECTED_DB,
  SQLLAB,
  SELECTED_TAB,
  SELECTED_TABINDEX
} from "./mutationType";

const sqlLab = JSON.parse(localStorage.getItem('sqlLab'))
const sqlLab_selected_tabIndx = JSON.parse(localStorage.getItem('sqlLab_selected_tabIndex'))
const sqlLab_selected_tab = JSON.parse(localStorage.getItem('sqlLab_selected_tab'))

const state = {
  sqlLab_selected_tab,
  sqlLab_selected_tabIndx,
  sqlLab,
  sqlLab_selected_db: null
}

const actions = {
  [GET_DB_ALL](_) {
    return SqlpageService.getDbAll().then(
      res => {
        return res;
      }
    )
  },
  [GET_SCHEMA_ALL](_, pk) {
    return SqlpageService.getSchemaAll(pk).then(
      res => {
        return res;
      }
    )
  },
  [GET_TABLE_ALL](_, data) {
    return SqlpageService.getTableAll(data).then(
      res => {
        return res;
      }
    )
  },
  [FETCH_TABLE](_, data) {
    return SqlpageService.fetchTable(data).then(
      res => {
        return res;
      }
    )
  },
  [RUN_QUERY](_, data) {
    return SqlpageService.runQuery(data).then(
      res => {
        return res;
      }
    )
  },
  [GET_QUERY_HISTORY_ALL](_) {
    return SqlpageService.getQueryHistory().then(
      res => {
        return res;
      }
    )
  }
}

const mutations = {
  [SQLLAB_SELECTED_DB](state, payload) {
    state.sqlLab_selected_db = payload;
  },
  [SQLLAB](state, payload) {
    console.log('store sqllab', payload)
    localStorage.setItem('sqlLab', JSON.stringify(payload));
    state.sqlLab = payload;
  },
  [SELECTED_TAB](state, payload) {
    console.log('store tab', payload)
    localStorage.setItem('sqlLab_selected_tab', JSON.stringify(payload));
    state.sqlLab_selected_tab = payload;
  },
  [SELECTED_TABINDEX](state, payload) {
    localStorage.setItem('sqlLab_selected_tabIndex', JSON.stringify(payload))
    state.sqlLab_selected_tabIndx = payload;
  }
}

export default {
  state,
  actions,
  mutations
}