<template>
  <v-app-bar app height="auto" color="#151515" class="vis-header">
    <div class="d-flex">
    <div class="ml-0 d-flex logo" @click="$router.push('/')">
      <img alt="Forecastagility" src="@/assets/logo.svg" />
      <div class="d-flex">
        <span class="mt-auto mb-auto logo-text ml-1">Forecastagility</span>
      </div>
    </div>
    <div class = "d-flex" @click="backPage">
      <div>
      <v-btn icon>
        <v-icon large>mdi-chevron-left</v-icon>
      </v-btn>
      <!-- <template v-if="project_info">
        {{project_info.project_title}} /
      </template> -->
      </div>
      <div class="chart-name d-flex">
      {{slice_name}}
      </div>
    </div>
    </div>
    <!-- <v-btn
      color="transparent"
      class="mr-2 ml-auto"
      height="30"
      v-if="$store.state.auth.initialState.status.loggedIn"
    >
      <v-icon left> mdi-plus </v-icon>
      Invite members
    </v-btn>
    <v-btn color="primary" class="mr-2" height="30" dense> New Project </v-btn> -->
    <div class="d-flex">
      <v-btn icon small v-if="$store.state.auth.initialState.status.loggedIn">
        <v-icon small>mdi-bell</v-icon>
      </v-btn>
      <v-menu
        v-if="$store.state.auth.initialState.status.loggedIn"
        v-model="logoutMenu"
        :close-on-content-click="false"
        :nudge-width="200"
        offset-y
      >
        <template #activator="{ on, attrs }">
          <v-btn v-bind="attrs" icon small class="ml-1 mr-10" v-on="on">
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>
        <v-card>
          <v-list>
            <v-list-item>
              <v-list-item-avatar color="teal">
                <span class="white--text text-h6 font-weight-regular mx-auto">
                  {{ initials }}
                </span>
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title>{{ user.name }}</v-list-item-title>
                <v-list-item-subtitle>{{ user.email }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <v-divider></v-divider>
          <v-card-actions>
            <v-btn text elevation="1" outlined class="btn-logout" @click="logout">
              Logout
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-menu>
    </div>
  </v-app-bar>
</template>

<script>
import { LOGOUT } from "@/store/actionType";
import { mapState } from "vuex";
import { extractInitials } from "../../../utils/stringUtils";

export default {
  name: "VisHeader",
  props: {
    //chart title
    slice_name: {
      type: String,
      default() {
        return ""
      }
    },
    project_info: {
      type: Object,
      default() {
        return null;
      }
    }
  },
  data() {
    return {
      logoutMenu: false,
    };
  },
  methods: {
    logout() {
      this.$store.dispatch(LOGOUT);
      this.$router.push("/login");
      this.logoutMenu = false;
    },
    backPage() {
      if (this.project_info) {
        this.$router.push(`/projects/${this.project_info.project_id}`)
      } else {
        this.$router.push('/charts')
      }
    }
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.initialState.user,
    }),
    initials() {
      return extractInitials(this.user.name);
    },
  },
};
</script>

<style lang="scss" scoped>
.chart-name {
  align-items:center;
}
.vis-header {
  &::v-deep {
    .v-toolbar__content {
      // background: red;
      justify-content: space-between;
      padding-left: 0 !important;
    }
  }

  .logo {
    width: 240px;

    &-text {
      font-size: 18px;
    }
  }
}
</style>