import SecurityService from "../services/SecurityService";

import {
  GET_USERS_ALL,
  REGISTER_USER,
  RESET_USER,
} from "./actionType";

const state = {};

const actions = {
  [GET_USERS_ALL](_) {
    return SecurityService.getAll().then(
      res => {
        return res;
      }
    )
  },
  [REGISTER_USER](_, params) {
    return SecurityService.registerUser(params).then(
      res => {
        return res;
      }
    )
  },
  [RESET_USER](_, params) {
    return SecurityService.resetUser(params).then(
      res => {
        return res;
      }
    )
  },
};

const mutations = {};

export default {
  state,
  actions,
  mutations
}