var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"index"},[_c('div',{staticClass:"index-header d-flex"},[_c('div',{staticClass:"index-header-title"},[_vm._v("Index")]),_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"ml-auto",attrs:{"small":"","color":_vm.validVisCols.entity ? '#B9B9B9' : '#ffbb33'}},'v-icon',attrs,false),on),[_vm._v(" mdi-information-outline ")])]}}])},[_c('span',{staticStyle:{"color":"#ffbb33"}},[_vm._v(" Required"),_c('br')]),_c('span',[_vm._v(" Index to define each point on the plot"),_c('br'),_vm._v("   "),_c('i',[_vm._v("Categorical/Numerical")]),_c('br')])])],1),_c('div',{ref:"indexCon",staticClass:"index-container",on:{"dragover":function($event){$event.preventDefault();},"drop":function($event){return _vm.$emit('dragPillFinish', 'entity')}}},[(_vm.entity)?_c('VizModePill',{attrs:{"column":_vm.getSimpleCol('entity')},on:{"deleteCol":function($event){return _vm.$emit('deletePill', 'entity')}}}):_vm._e()],1)]),_c('div',{staticClass:"xAxis"},[_c('div',{staticClass:"xAxis-header d-flex"},[_c('div',{staticClass:"xAxis-header-title"},[_vm._v("X Axis")]),_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"ml-auto",attrs:{"small":"","color":_vm.validVisCols.xAxis ? '#B9B9B9' : '#ffbb33'}},'v-icon',attrs,false),on),[_vm._v(" mdi-information-outline ")])]}}])},[_c('span',{staticStyle:{"color":"#ffbb33"}},[_vm._v(" Required"),_c('br')]),_c('span',[_vm._v(" Column to show on the horizontal axis"),_c('br'),_c('i',[_vm._v("Numerical/Calculation")]),_c('br'),_vm._v("   - Usually the "),_c('i',[_vm._v("independent")]),_vm._v(" variable ")])])],1),_c('div',{ref:"xAxisCon",staticClass:"xAxis-container",on:{"dragover":function($event){$event.preventDefault();},"drop":function($event){return _vm.$emit('dragComputedAxisFinish', 'x')}}},[(_vm.xAxis)?_c('div',{staticClass:"d-flex",class:[_vm.showCalc.x ? 'size-menu': 'size-item']},[_c('v-menu',{attrs:{"attach":"","value":_vm.showCalc.x,"offset-x":"","nudge-right":"22px","nudge-top":"153","close-on-content-click":false,"height":"0","flat":"true","close-on-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(_vm.xAxis)?_c('v-btn',_vm._g({staticClass:"mb-08",attrs:{"outlined":!_vm.isApplied('x'),"color":_vm.xAxis.column.type_generic ? '#1bbab9' : '#00A7FF',"rounded":"","width":"100%","height":"24px"},on:{"click":function($event){$event.stopPropagation();return on.click.apply(null, arguments)},"contextmenu":function($event){$event.preventDefault();return _vm.contextClickPill('x')}}},on),[_vm._v(" "+_vm._s(_vm.getComputedCol('xAxis').column_name)+" "),_c('v-icon',{staticClass:"ml-auto",attrs:{"right":""},on:{"click":[function($event){$event.preventDefault();},function($event){return _vm.removePillX('x')}]}},[_vm._v(" mdi-close-circle ")])],1):_vm._e()]}}],null,false,221382519)},[(_vm.showCalc.x)?_c('CalculationCard',{attrs:{"calcStyle":'Calculation',"form_data":_vm.form_data,"aggregations":_vm.getAggType(_vm.xAxis),"size":_vm.xAxis},on:{"disableCalcCard":_vm.disableSimpleCalcX,"saveSimpleCalcSize":_vm.saveSimpleCalcX}}):_vm._e()],1)],1):_vm._e()])]),_c('div',{staticClass:"yAxis"},[_c('div',{staticClass:"yAxis-header d-flex"},[_c('div',{staticClass:"yAxis-header-title"},[_vm._v("Y Axis")]),_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"ml-auto",attrs:{"small":"","color":_vm.validVisCols.yAxis ? '#B9B9B9' : '#ffbb33'}},'v-icon',attrs,false),on),[_vm._v(" mdi-information-outline ")])]}}])},[_c('span',{staticStyle:{"color":"#ffbb33"}},[_vm._v(" Required"),_c('br')]),_c('span',[_vm._v(" Column to show on the vertical axis"),_c('br'),_c('i',[_vm._v("Numerical/Calculation")]),_c('br'),_vm._v("   - Usually the "),_c('i',[_vm._v("dependent")]),_vm._v(" variable ")])])],1),_c('div',{ref:"yAxisCon",staticClass:"yAxis-container",on:{"dragover":function($event){$event.preventDefault();},"drop":function($event){return _vm.$emit('dragComputedAxisFinish', 'y')}}},[(_vm.yAxis)?_c('div',{staticClass:"d-flex",class:[_vm.showCalc.y ? 'size-menu': 'size-item']},[_c('v-menu',{attrs:{"attach":"","value":_vm.showCalc.y,"offset-x":"","nudge-right":"22px","nudge-top":"153","close-on-content-click":false,"height":"0","flat":"true","close-on-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(_vm.yAxis)?_c('v-btn',_vm._g({staticClass:"mb-08",attrs:{"outlined":!_vm.isApplied('y'),"color":_vm.yAxis.column.type_generic ? '#1bbab9' : '#00A7FF',"rounded":"","width":"100%","height":"24px"},on:{"click":function($event){$event.stopPropagation();return on.click.apply(null, arguments)},"contextmenu":function($event){$event.preventDefault();return _vm.contextClickPill('y')}}},on),[_vm._v(" "+_vm._s(_vm.getComputedCol('yAxis').column_name)+" "),_c('v-icon',{staticClass:"ml-auto",attrs:{"right":""},on:{"click":[function($event){$event.preventDefault();},function($event){return _vm.removePillX('y')}]}},[_vm._v(" mdi-close-circle ")])],1):_vm._e()]}}],null,false,3486008887)},[(_vm.showCalc.y)?_c('CalculationCard',{attrs:{"calcStyle":'Calculation',"form_data":_vm.form_data,"aggregations":_vm.getAggType(_vm.yAxis),"size":_vm.yAxis},on:{"disableCalcCard":_vm.disableSimpleCalcY,"saveSimpleCalcSize":_vm.saveSimpleCalcY}}):_vm._e()],1)],1):_vm._e()])]),_c('div',{staticClass:"size"},[_c('div',{staticClass:"size-header d-flex"},[_c('div',{staticClass:"size-header-title"},[_vm._v("Size")]),_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"ml-auto",attrs:{"small":"","color":_vm.validVisCols.size ? '#B9B9B9' : '#ffbb33'}},'v-icon',attrs,false),on),[_vm._v(" mdi-information-outline ")])]}}])},[_c('span',{staticStyle:{"color":"#ffbb33"}},[_vm._v(" Required"),_c('br')]),_c('span',[_vm._v(" Used to change point size"),_c('br'),_vm._v("   "),_c('i',[_vm._v("Numerical/Calculation")]),_c('br'),_vm._v("   - Larger values are shown as larger points (bubbles)"),_c('br')])])],1),_c('div',{ref:"sizeCon",staticClass:"size-container",on:{"dragover":function($event){$event.preventDefault();},"drop":function($event){return _vm.$emit('dragComputedAxisFinish', 'size')}}},[(_vm.size)?_c('div',{staticClass:"d-flex",class:[_vm.showCalc.size ? 'size-menu': 'size-item']},[_c('v-dialog',{attrs:{"width":"400","content-class":"slider-dialog"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-auto",attrs:{"color":"#B9B9B9"},on:{"click":[function($event){$event.stopPropagation();return on.click.apply(null, arguments)},_vm.clickSizeAdjust]}},'v-icon',attrs,false),on),[_vm._v(" mdi-adjust ")])]}}],null,false,1510036704),model:{value:(_vm.showSizeSlider),callback:function ($$v) {_vm.showSizeSlider=$$v},expression:"showSizeSlider"}},[_c('v-card',{staticClass:"slider-card",style:([
              {'position': 'absolute'}, 
              {'top': this.sizeDialogPos.top - 231 + 'px'}, 
              {'left': this.sizeDialogPos.x + 'px'} ]),attrs:{"height":"201","width":"280"}},[_c('v-card-title',[_vm._v("Bubble Size")]),_c('v-slider',{attrs:{"dense":"","label":"Size","thumb-label":"","max":100,"min":-100,"ticks":false},on:{"end":_vm.changeAbsSizeSlider},model:{value:(_vm.bubbleSize),callback:function ($$v) {_vm.bubbleSize=$$v},expression:"bubbleSize"}}),_c('v-divider'),_c('v-range-slider',{attrs:{"dense":"","label":"Relative Size","max":50,"min":1,"ticks":false},on:{"end":_vm.changeAbsSizeSlider},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({on:{"click":_vm.resetRatio}},'v-icon',attrs,false),on),[_vm._v(" mdi-radiobox-marked ")])]}}],null,false,54694418)},[_c('span',[_vm._v("Reset Default")])])]},proxy:true}],null,false,1886463259),model:{value:(_vm.bubbleRelative),callback:function ($$v) {_vm.bubbleRelative=$$v},expression:"bubbleRelative"}}),_c('v-card-actions',[_c('v-btn',{staticClass:"ml-5",attrs:{"color":"primary","height":"30px","width":"90px"},on:{"click":function($event){_vm.showSizeSlider=false}}},[_vm._v(" Accept ")])],1)],1)],1),_c('v-menu',{attrs:{"attach":"","value":_vm.showCalc.size,"offset-x":"","nudge-right":"22px","nudge-top":"153","close-on-content-click":false,"height":"0","flat":"true","close-on-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
return [(_vm.size)?_c('v-btn',_vm._g({staticClass:"mb-08",attrs:{"outlined":!_vm.isApplied('size'),"color":_vm.size.column.type_generic ? '#1bbab9' : '#00A7FF',"rounded":"","width":"85%","height":"24px"},on:{"click":function($event){$event.stopPropagation();return on.click.apply(null, arguments)},"contextmenu":function($event){$event.preventDefault();return _vm.contextClickPill('size')}}},on),[_vm._v(" "+_vm._s(_vm.getComputedCol('size').column_name)+" "),_c('v-icon',{staticClass:"ml-auto",attrs:{"right":""},on:{"click":[function($event){$event.preventDefault();},function($event){return _vm.removePillX('size')}]}},[_vm._v(" mdi-close-circle ")])],1):_vm._e()]}}],null,false,1011895688)},[(_vm.showCalc.size)?_c('CalculationCard',{attrs:{"calcStyle":'Calculation',"form_data":_vm.form_data,"aggregations":_vm.getAggType(_vm.size),"size":_vm.size},on:{"disableCalcCard":_vm.disableSimpleCalcSize,"saveSimpleCalcSize":_vm.saveSimpleCalcSize}}):_vm._e()],1)],1):_vm._e()])]),_c('div',{staticClass:"categories"},[_c('div',{staticClass:"categories-header d-flex"},[_c('div',{staticClass:"categories-header-title"},[_vm._v("Categories")]),_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"ml-auto",attrs:{"small":"","color":"#B9B9B9"}},'v-icon',attrs,false),on),[_vm._v(" mdi-information-outline ")])]}}])},[_c('span',[_vm._v(" Column that encodes intersection of the x and y variables"),_c('br'),_vm._v("   "),_c('i',[_vm._v("Categorical/Numerical")]),_c('br'),_vm._v(" - Gives each point a distinct hue =>"),_c('br'),_vm._v("   Easily shows membership of each point to it's group"),_c('br'),_c('br'),_vm._v(" - Can be used to specify color and/or shape"),_c('br'),_vm._v(" - If numerical column (color only) is used the hue scale will be continuous ")])])],1),_c('div',{ref:"categoryCon",staticClass:"categories-container",on:{"dragover":function($event){$event.preventDefault();},"drop":function($event){return _vm.$emit('dragPillFinish', 'series')}}},[(_vm.series)?_c('div',{staticClass:"categories-item d-flex"},[_c('v-dialog',{attrs:{"content-class":"colorpicker-dialog"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-auto",attrs:{"color":"#B9B9B9"},on:{"click":[function($event){$event.stopPropagation();return on.click.apply(null, arguments)},_vm.clickColorAdjust]}},'v-icon',attrs,false),on),[_vm._v(" mdi-palette ")])]}}],null,false,2095680408),model:{value:(_vm.showColorPicker),callback:function ($$v) {_vm.showColorPicker=$$v},expression:"showColorPicker"}},[_c('v-card',{staticClass:"slider-card",style:([
              {'position': 'absolute'}, 
              {'top': this.colorDialogPos.top - 301 + 'px'}, 
              {'left': this.colorDialogPos.x + 'px'} ]),attrs:{"width":"520","height":"270"}},[_c('v-card-title',[_vm._v("Color Picker")]),_c('v-container',{attrs:{"fluid":""}},[_c('v-row',{attrs:{"justify":"space-between"}},[_c('v-col',{attrs:{"cols":"2"}},[_c('v-subheader',[_vm._v(" Color"),_c('br'),_vm._v("Pallate ")])],1),_c('v-col',{attrs:{"cols":"9"}},[_c('v-select',{attrs:{"items":_vm.color_schemes,"menu-props":{
                top: true,
                closeOnContentClick: true,
                },"item-text":"id","return-object":"","dense":""},on:{"change":_vm.changePallate},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
                var item = ref.item;
return [_c('PallatePicker',{attrs:{"item":item}})]}},{key:"item",fn:function(ref){
                var item = ref.item;
return [_c('PallatePicker',{attrs:{"item":item}})]}}],null,false,3242025404),model:{value:(_vm.color_pallate),callback:function ($$v) {_vm.color_pallate=$$v},expression:"color_pallate"}})],1)],1)],1),_c('v-divider'),_c('v-card-subtitle',[_vm._v("Opacity")]),_c('div',{staticClass:"opacity-slider"},[_c('v-slider',{attrs:{"prepend-icon":"mdi-circle-opacity","dense":"","thumb-label":"","max":100,"min":0,"ticks":false},on:{"end":_vm.changeOpacity},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({on:{"click":_vm.resetOpacity}},'v-icon',attrs,false),on),[_vm._v(" mdi-radiobox-marked ")])]}}],null,false,2734499754)},[_c('span',[_vm._v("Reset Default")])])]},proxy:true}],null,false,976651972),model:{value:(_vm.bubbleOpacity),callback:function ($$v) {_vm.bubbleOpacity=$$v},expression:"bubbleOpacity"}})],1),_c('v-card-actions',[_c('v-btn',{staticClass:"ml-5",attrs:{"color":"primary","height":"30px","width":"90px"},on:{"click":function($event){_vm.showColorPicker=false}}},[_vm._v(" Accept ")])],1)],1)],1),_c('VizModePill',{attrs:{"column":_vm.getSimpleCol('series'),"pillWidth":"85%"},on:{"deleteCol":function($event){return _vm.$emit('deletePill', 'series')}}})],1):_vm._e()])])])}
var staticRenderFns = []

export { render, staticRenderFns }