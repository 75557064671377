

<template>
<svg id="Group_160416" data-name="Group 160416" xmlns="http://www.w3.org/2000/svg" width="19" height="18" viewBox="0 0 19 18">
  <rect id="Canvas" width="19" height="18" fill="#b9b9b9" opacity="0"/>
  <path id="Path_83574" data-name="Path 83574" d="M13.5,9.05a4.45,4.45,0,1,0,4.45,4.45A4.45,4.45,0,0,0,13.5,9.05Zm-1.169,7.156-2.064-2.064a.25.25,0,0,1,0-.354l.518-.518a.25.25,0,0,1,.354,0L12.5,14.636l3.053-3.053a.25.25,0,0,1,.354,0l.521.521a.25.25,0,0,1,0,.354l-3.75,3.75a.25.25,0,0,1-.351,0Z" transform="translate(1)" fill="#b9b9b9"/>
  <path id="Path_83575" data-name="Path 83575" d="M7.35,13.5a6.113,6.113,0,0,1,.669-2.78L3.364,12.182,6,7.227V2h4V7.227l.49.913a6.115,6.115,0,0,1,.885-.405L11,7V2h.5a.5.5,0,0,0,.5-.5V.5a.5.5,0,0,0-.5-.5h-7A.5.5,0,0,0,4,.5v1a.5.5,0,0,0,.5.5H5V7L.464,15.88A1.5,1.5,0,0,0,1.83,18H9.318A6.126,6.126,0,0,1,7.35,13.5Z" fill="#b9b9b9"/>
</svg>


</template>



<script>
export default {
  name: "beakerIcon",
  props: {
    fill: {
      type: String,
      default() {
        return "#B9B9B9";
      },
    },
  },
};
</script>

<style>
.fill {
  fill: #B9B9B9;
  /* fill: blue; */
}
</style>
