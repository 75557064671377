<template>
  <v-dialog v-model="showModal" persistent max-width="350">
    <v-card>
      <v-card-title class="text-h5" style="background-color: #FFAC27;">
        <v-icon color="white">mdi-alert-outline</v-icon>
        <div class="ml-4">
          <span class="d-flex">ERROR: {{ message }}</span>          
        </div>
      </v-card-title>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click.prevent="close">
          CLOSE
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "ErrorModal",
  props: {
    message: {
      type: String,
      default: 'We have an error!!!'
    },
    showModal: {
      type: Boolean,
      default: false
    },
  },
  methods: {
    close() {
      this.$emit("close");
    },
  },
};
</script>
