<template>
<div>
<!-- max-width="1355px"  -->
<!-- height="200px" -->
  <v-dialog v-model="showNewDB" 
    max-width="1355px"
    persistent
  >
    <div class="connection-snackbars">
      <v-snackbar color="#FFAC27" centered v-model="showErrorSnackbar">
        <div class="d-flex">
          <v-icon color="white">mdi-alert-outline</v-icon>
          <div class="ml-24">
            <span class="d-flex">ERROR: Connection failed.</span>
            <span>Please check your connection settings</span>
          </div>
        </div>
        <template v-slot:action="{ attrs }">
          <v-btn
            color="white"
            text
            v-bind="attrs"
            @click="showErrorSnackbar = false"
          >
            Close
          </v-btn>
        </template>
      </v-snackbar>
      <v-snackbar color="#48A64C" centered v-model="showSuccessSnackbar">
        <div class="d-flex">
          <v-icon color="white">mdi-check-circle</v-icon>
          <div class="ml-24 d-flex">
            <span class="mt-auto mb-auto">Connection seems OK!</span>
          </div>
        </div>
        <template v-slot:action="{ attrs }">
          <v-btn
            color="white"
            text
            v-bind="attrs"
            @click="showSuccessSnackbar = false"
          >
            Close
          </v-btn>
        </template>
      </v-snackbar>
      <v-snackbar color="#454545" centered v-model="showProgressSnackbar">
        <div class="d-flex">
          <v-progress-circular indeterminate color="white"></v-progress-circular>
          <div class="ml-24 d-flex">
            <span class="mt-auto mb-auto">Testing Connection...</span>
          </div>
        </div>
        <template v-slot:action="{ attrs }">
          <v-btn
            color="white"
            text
            v-bind="attrs"
            @click="showProgressSnackbar = false"
          >
            Close
          </v-btn>
        </template>
      </v-snackbar>
    </div>
    <v-form v-model="dbFormValid">
    <v-card 
      color="#262626"
      class="new-db">
      <v-card-title>{{ cardTitle }} </v-card-title>
      <v-divider/>
      <!-- <v-card-subtitle>dbnaem: {{ database }} </v-card-subtitle> -->
      <!-- <p class="new-db-title">New Database</p> -->
      <!-- v-model="database" -->
      <div class="new-db-container">
        <!-- db name -->
        <div class="new-db-row">
          <p>Database Name *</p>
          <v-text-field
            :rules="dbFormRules"
            outlined
            dense
            background-color="#080808"
            height="32px"
            v-model="database.database_name"
            required
          />
        </div>
        <!-- uri input -->
        <div class="new-db-row">
          <p>SQL Alchemy URI*</p>
          <v-text-field
            outlined
            required
            :rules="dbFormRules"
            dense
            background-color="#080808"
            height="32px"
            v-model="database.sqlalchemy_uri"
            messages="Refer to the Alchemy docs for more information on how to structure your URL"
          />
        </div>
        <div class="new-db-row">
          <div></div>
          <v-btn
            height="26px"
            color="primary"
            class="test-connection"
            @click="testConnection"
          >
            Test Connection
          </v-btn>
        </div>
        <div class="new-db-row">
          <p>Chart Cache TimeOut</p>
          <v-text-field
            outlined
            dense
            type="number"
            v-model="database.cache_timeout"
            messages="Duration (in seconds) of the caching timeout for charts of this database. A timeout of 0 indicates that the cache newer exprires. Note this defaults to the global timeout if underlined."
          />
        </div>
        <div class="new-db-row top30">
          <p class="new-db-row-title">Expose in SQL Lab</p>
          <v-checkbox hide-details dense v-model="database.expose_in_sqllab">
            <template v-slot:label>
              <div class="checkbox-label">Expose this DB in SQL Explorer</div>
            </template>
          </v-checkbox>
        </div>
        <div class="new-db-row top30">
          <p class="new-db-row-title">Allow CSV Upload</p>
          <v-checkbox hide-details dense v-model="database.allow_file_upload">
            <template v-slot:label>
              <div class="checkbox-label">
                If selected, please set the schemas allowed for csv upload in
                Extra
              </div>
            </template>
          </v-checkbox>
        </div>
        <SeparatorTextV3 text="Advanced" />
        <div class="new-db-row top30">
          <p class="new-db-row-title">Async Execution</p>
          <v-checkbox
            class="new-db-row-checkbox"
            v-model="database.allow_run_async"
            hide-details
            dense
          >
            <template v-slot:label>
              <div class="checkbox-label">
                Operate the database in asynchronous mode, meaning that the
                queries are executed on remote workers as opposed to on the web
                server itself. This assumes that you have a Celery worker setup
                as well as a result backend. Refer to the installation docs for
                more information.
              </div>
            </template>
          </v-checkbox>
        </div>
        <div class="new-db-row top30">
          <p class="new-db-row-title">Allow Create Table As</p>
          <v-checkbox hide-details dense v-model="database.allow_ctas">
            <template v-slot:label>
              <div class="checkbox-label">
                Allow CREATE TABLE AS option in SQL Explorer
              </div>
            </template>
          </v-checkbox>
        </div>
        <div class="new-db-row top30">
          <p class="new-db-row-title">Allow Create View As</p>
          <v-checkbox hide-details dense v-model="database.allow_cvas">
            <template v-slot:label>
              <div class="checkbox-label">
                Allow CREATE VIEW AS option in SQL Explorer
              </div>
            </template>
          </v-checkbox>
        </div>
        <div class="new-db-row top30">
          <p class="new-db-row-title">Allow DML</p>
          <v-checkbox hide-details dense v-model="database.allow_dml">
            <template v-slot:label>
              <div class="checkbox-label">
                Allow users to run non-SELECT statements (UPDATE, DELETE,
                CREATE, ...) in SQL Explorer
              </div>
            </template>
          </v-checkbox>
        </div>
        <div class="new-db-row top30">
          <p>Impersonate Logged on User</p>
          <v-textarea
            outlined
            v-model="database.impersonate_user"
            dense
            multiline
            placeholder="If Presto, all the queries in SQL Lab are going to be executed as the currently logged on user who must have permission to run them. If Hive and hive.server2.enable.doAs is enabled, will run the queries as service account, but impersonalte the currently logged on user via hive.server2.proxy.user property"
          />
        </div>
      </div>
      <v-divider/>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn outlined color="white" height="32px" width="94px" plain @click="cancel">
          Cancel
        </v-btn>
        <v-btn color="primary" height="32px" width="94px" @click="applyDB"
          :disabled="!dbFormValid"
        >
          Apply
        </v-btn>
      </v-card-actions>
    </v-card>
    </v-form>
  </v-dialog>
  <v-snackbar 
    v-model="dBSnackbar"
    :color="snackbarColor" 
    :timeout="snackbarTimeout"
    bottom 
    min-width="360px"
  >
    <!-- <div class="d-flex"> -->
      <v-icon color="white">{{ snackbarIcon }}</v-icon>
      <!-- <div class="ml-24"> -->
        <span v-if="!snackbarSucess">Please check your connection settings:</span>
        <!-- <span class="d-flex"><br> {{ snackbarMsg }}</span> -->
        <span><br> {{ snackbarMsg }}</span>
        
      <!-- </div> -->
    <!-- </div> -->
    <template v-slot:action="{ attrs }">
      <v-btn
        color="white"
        text
        v-bind="attrs"
        @click="dBSnackbar = false"
      >
        Close
      </v-btn>
    </template>
  </v-snackbar>
</div>
</template>

<script>
import {
//   GET_DB_ALL,
  GET_DATABASE_BY_ID,
  TEST_DATABASE_CONNECTION,
  CREATE_DB,
  EDIT_DB,
} from "@/store/actionType";
import SeparatorTextV3 from "../core/SeparatorTextV3.vue";

import Database from '@/views/database/database_utils'

export default {
  components: {
    SeparatorTextV3,
  },
  props: {
    editItemId: {
      type: Number,
      default: null,
    },
    selectedDB: {
      type: Object,
      default() {
        return new Database()
      }
    },
    showNewDB: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      // Form params
      dbFormValid: null,
      dbFormRules:[
        v => !!v || 'DB Name/URI is required',
        v => v.trim().length > 0 || 'Name/URI must be non-empty',
      ],
      // Create/edit params
      dBSnackbar: false,
      snackbarSucess: null,
      snackbarColor: null,
      snackbarMsg: null,
      snackbarIcon: null,
      snackbarTimeout: 2000,
      // dBSnackbar:{
      //   "success": {
      //     "color": "#48A64C",
      //     "msg": "Success!"
      //   },
      //   "error": {
      //     "color": "#FFAC27"
      //   },
      // },
      //databases
      databases: [],
      //schemas
      schemas: [],
      //selected db
      // database: "",
      // database: "",
      database: new Database(),//this.selectedDB,//.get('database_name'),
      //tables
      tables: [],
      //sql alchemy URI
      // sqlUri: "",
      //cahced time out
      // cache_timeout: null,
      //expose in sql lab
      // exposeSql: true,
      //allow csv upload
      // allow_file_upload: true,
      //async excution
      // allow_run_async: false,
      //allow create table as
      // allow_ctas: false,
      //allow create view as
      // allow_cvas: false,
      //allow DML
      // allow_dml: false,
      //impersonate logged on user
      // impersonateLogged: "",
      //show snackbar
      showErrorSnackbar: false,
      //show success snackbar
      showSuccessSnackbar: false,
      //show progress snackbar
      showProgressSnackbar: false,
    };
  },
  watch: {
    async showNewDB() {
      await this.loadItem(this.editItemId)
      // console.log('open new dialog', this.database)
    }
  },
  // mounted() {
    // this.getDB();
  // },
  computed: {
    cardTitle () {
      if (!this.editItemId) {
        return "New Database";
      } else {
        return "Edit Database"; 
      }
    }
  },
  methods: {
    showDBSnackbar(showSuccess, msg) {
      if (showSuccess) {
        this.snackbarSucess=true;
        this.snackbarMsg = "Success!"
        this.snackbarColor = "#48A64C"
        this.snackbarIcon="mdi-check-circle"
        this.dBSnackbar=true;
      } else {
        this.snackbarMsg = msg
        this.snackbarColor="#FFAC27"
        this.snackbarIcon="mdi-alert-outline"
        this.snackbarTimeout=10000;
        this.dBSnackbar=true;

      }
    },
    // Database,
    applyDB () {
      if (!this.editItemId) {
        this.createDB()
      } else {
        console.log('not empty', this.editItemId)
        this.editDB()
      }
      this.$emit('cancel')
    },
    loadInfoItem(id) {
      let db_detail = this.$store
        .dispatch(GET_DATABASE_BY_ID, id)
        .then((res) => {
          console.log('running')
          return res.data.result;
        })
        .catch((error) => {
          this.errorMsg = 'While removing';
          this.errModalOpen = true;
        });
        return db_detail;
    },
    async loadItem(id) {
      let db_detail;
      // console.log('og', db_detail)
      if (!id) {
        db_detail = new Database();
      } else {
        db_detail = await this.loadInfoItem(id)
      }
      this.database = db_detail;
    },
    /**
     * cancel envet
     */
    cancel() {
      this.$emit("cancel");
    },
    /**
     * change DB event
     * @param db
     */
    // changeDB(db) {
      // this.database = db;
    // },
    /**
     * get DB
     */
    // getDB() {
    //   this.$store
    //     .dispatch(GET_DB_ALL)
    //     .then((res) => {
    //       this.databases = res.data.result;
    //     })
    //     .catch((error) => {
    //       console.error(error);
    //     });
    // },
    /**
     * test connection with database
     */
    testConnection() {
      this.showProgressSnackbar = true;
      const data = {
        configuration_method: "sqlalchemy_form",
        database_name: this.database.database_name,
        encrypted_extra: "",
        engine: "",
        extra: "",
        impersonate_user: this.database.impersonate_user,
        parameters: {
          additionalProp1: "",
          additionalProp2: "",
          additionalProp3: "",
        },
        server_cert: "",
        sqlalchemy_uri: this.database.sqlalchemy_uri,
      };
      console.log('api', this.database)
      this.$store
        .dispatch(TEST_DATABASE_CONNECTION, data)
        .then((res) => {
          this.showProgressSnackbar = false;
          this.showSuccessSnackbar = true;
        })
        .catch((error) => {
          console.error(error);
          this.showProgressSnackbar = false;
          this.showErrorSnackbar = true;
        });
    },
    // testConnection() {
    //   this.showProgressSnackbar = true;
    //   const data = {
    //     configuration_method: "sqlalchemy_form",
    //     database_name: this.database,
    //     encrypted_extra: "",
    //     engine: "",
    //     extra: "",
    //     impersonate_user: true,
    //     parameters: {
    //       additionalProp1: "",
    //       additionalProp2: "",
    //       additionalProp3: "",
    //     },
    //     server_cert: "",
    //     sqlalchemy_uri: this.sqlUri,
    //   };
    //   this.$store
    //     .dispatch(TEST_DATABASE_CONNECTION, data)
    //     .then((res) => {
    //       this.showProgressSnackbar = false;
    //       this.showSuccessSnackbar = true;
    //     })
    //     .catch((error) => {
    //       console.error(error);
    //       this.showProgressSnackbar = false;
    //       this.showErrorSnackbar = true;
    //     });
    // },
    /**
     * create DB
     */
    parseDBInfo() {
      const data = {
        allow_ctas: this.database.allow_ctas,
        allow_cvas: this.database.allow_cvas,
        allow_dml: this.database.allow_dml,
        allow_file_upload: this.database.allow_file_upload,
        allow_multi_schema_metadata_fetch: true,
        allow_run_async: this.database.allow_run_async,
        cache_timeout: this.database.cache_timeout,
        configuration_method: "sqlalchemy_form",
        database_name: this.database.database_name,
        encrypted_extra: "",
        engine: "",
        expose_in_sqllab: true,
        extra: "",
        force_ctas_schema: "",
        impersonate_user: this.database.impersonate_user,
        parameters: {
          additionalProp1: "",
          additionalProp2: "",
          additionalProp3: "",
        },
        server_cert: "",
        sqlalchemy_uri: this.database.sqlalchemy_uri,
      };
      return data
    },
    editDB() {
      let id = this.editItemId;
      const data = this.parseDBInfo()
      // data.sqlalchemy_uri='asdfadsfsad'
      // const data ={'adfads':'afadsfasd'}
      let params = {'id':id, 'data': data}
      // console.log("eidt the db", id)
      this.$store
        .dispatch(EDIT_DB, params)
        .then((res) => {

          // console.log('res', res.status_code);
          this.$emit("db_success");
          this.showDBSnackbar(true, 'Success!');
        })
        .catch((error) => {
          console.error('thiss', JSON.stringify(error.data.message, null, 2));
          let err_resp = JSON.stringify(error.data.message, null, 2)
          this.showDBSnackbar(false, err_resp)
            // JSON.parse(error.data.message)
              // JSON.parse(error.data.message), null, 2
            // )
          // );
        });
    },
    createDB() {
      const data = this.parseDBInfo()
      this.$store
        .dispatch(CREATE_DB, data)
        .then((res) => {
          console.log(res);
          this.$emit("db_success");
          this.showDBSnackbar(true, 'Success!');
        })
        .catch((error) => {
          let err_resp = JSON.stringify(error.data.message, null, 2)
          this.showDBSnackbar(false, err_resp)
          console.error(error);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.new-db {
  padding: 45px;
  height: 745px;

  &-title {
    font-size: 24px;
    color: #e3e3e3;
  }

  &-container {
    margin-top: 40px;
    margin-bottom: 20px;
    overflow-y: auto;
    height: 525px;
    padding-right: 50px;
    @include scrollbars(0.4rem, #717171, #00000080);
  }

  &-row {
    display: grid;
    grid-template-columns: 170px auto;

    &-title {
      margin-top: auto;
      margin-bottom: auto;
    }

    // &-checkbox {
    // }
  }
}

.top30 {
  margin-top: 30px;
}

.test-connection {
  width: 128px;
  margin-top: 20px;
  margin-bottom: 30px;
}

.checkbox-label {
  font-style: initial;
}

.ml-24 {
  margin-left: 24px;
}
</style>