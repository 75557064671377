import ExploreService from "../services/ExploreService";

import {
    GET_EXPLORE_FORM_BY_ID,
    POST_CHART_FORM,
    GET_CHART_FORM,
    SAVE_FORM_DATA,
} from "./actionType";

const state = {};

const actions = {
    async [GET_EXPLORE_FORM_BY_ID](_, id) {
        const res = await ExploreService.getExploreFormById(id);
        return res;
    },
    [POST_CHART_FORM](_, data) {
        return ExploreService.postChartForm(data).then(
            res => {
                return res;
            }
        )
    },
    [GET_CHART_FORM](_, data) {
        return ExploreService.getChartForm(data).then(
            res => {
                return res;
            }
        )
    },
    [SAVE_FORM_DATA](_, params) {
        return ExploreService.saveFormData(params.key, params.data)
            .then(
                res => {
                    return res;
                }
                
            )
            .catch((error) => {
                console.error('failed save form', error)
            }

            )
    }
};

const mutations = {};

export default {
    state,
    actions,
    mutations
}