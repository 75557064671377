<template>
  <div class="d-flex flex-column fill-height flex-grow-1 overflow-hidden pt-6">
    <ModuleHeader
      title="Dashboards"
      :view-grid="viewGrid"
      @updateLayoutOption="viewGrid = !viewGrid" 
      @updateViewFavOnly="viewFavOnly = !viewFavOnly"
    />

    <ModuleAction
      title="New Dashboard" 
      search-label="Search Dashboard Titles"
      :selectedItems="selectedItemIds"
      :n_items="filteredItemCount"
      @clickNewItem="clickNewItem"
      @deleteAll="deleteAll"
      @moduleFilterChanged="moduleFilterChanged"
      @selectItemsEvent="selectItemsEvent"
    />

    <div v-if="viewGrid">
      <v-container>
        <v-row v-if="loading" class="container-row-resolution py-5 d-flex justify-center">
          <v-progress-circular
            indeterminate
            color="primary"
          ></v-progress-circular>
        </v-row>
        <v-row v-else-if="getModels(collection, viewFavOnly).length" class="container-row-resolution">
          <BoxItem 
            v-for="item in getModels(collection, viewFavOnly)" 
            v-bind:key="item.id"
            :item="item" 
            :class-name="`dashboard`" 
            :title-field="`dashboard_title`" 
            :selectable=true
            @toggled="toggleFav"
            @checked="toggleChecked"
            @clickItem="clickItem"
          />
        </v-row>
        <v-row v-else class="container-row-resolution pt-5 d-flex justify-center">
          <p>No Dashboards Found ...</p>
        </v-row>
      </v-container>
    </div>
    <div
      v-if="!viewGrid"
      class="
        d-flex
        flex-grow-1 flex-column flex-basis-0
        fill-height
        overflow-x-auto
      "
    >
      <v-container>
        <v-row v-if="loading" class="container-row-resolution py-5 d-flex justify-center">
          <v-progress-circular
            indeterminate
            color="primary"
          ></v-progress-circular>
        </v-row>
        
        <v-data-table
          id="tblItems"
          v-else-if="getModels(collection, viewFavOnly).length"
          v-model="selectedItems"
          :items="getModels(collection, viewFavOnly)"
          :items-per-page="$pageItemCount"
          :headers="headers"
          :search="search"
          @click:row="clickItem"
          @item-selected="updateGridViewSelection"
          @toggle-select-all="toggleSelectAll"
          show-select
          item-key="id"      
          class="mt-3"
          :hide-default-footer="true"
          :header-props="{sortIcon: 'mdi-chevron-down'}"
        >
          <template v-slot:top="{ pagination, options, updateOptions }">
            <v-data-footer 
              :pagination="pagination" 
              :options="options"
              @update:options="updateOptions"
              items-per-page-text="$vuetify.dataTable.itemsPerPageText"/>
          </template>
          <template v-slot:[`header.favorite`]="{header}">
            <v-icon v-if="header.favorite">mdi-star</v-icon>
            <v-icon v-else>mdi-star-outline</v-icon>
          </template>
          <template #[`item.favorite`]="{ item }">
            <div @click.stop="toggleFav(item)" class="actions">
              <v-btn icon width="24" v-tippy content="Favorite">
                <v-icon v-if="item.favorite">mdi-star</v-icon>
                <v-icon v-else>mdi-star-outline</v-icon>
              </v-btn>
            </div>
          </template>
          <template #[`item.dashboard_title`]="{ item }">
            <div @click.stop class="actions">
              <a :href="`/#/dashboards/${item.id}`">{{item.dashboard_title}}</a>
            </div>
          </template>
          <template #[`item.created_by.username`]="{ item }">
            <div v-if="item.created_by" class="actions">
              {{item.created_by.username}}
            </div>
          </template>
          <template #[`item.action`]="{ item }">
            <RowAction 
              :item="item" 
              :disabledList="disabledRowActions"
              @clickItem="clickItem" 
              @deleteItem="deleteItem"/>
          </template>
        </v-data-table>

        <v-row v-else class="container-row-resolution pt-5 d-flex justify-center">
          <p>No dashboard found ...</p>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
import BoxItem from "@/views/_partials/BoxItem";
import RowAction from "@/views/_partials/RowAction";
import ModuleHeader from "@/views/_partials/ModuleHeader";
import ModuleAction from "@/views/_partials/ModuleAction";

import {
  DashboardRowActions as disabledRowActions
} 
from "@/utils/listRowActions";

import { 
    toggleSelectAll,
    modifyAllCheck,
    selectItemsEvent,
    filteredObjs,
  } from "@/utils/moduleUtils.js";

import { 
  GET_DASHBOARD_ALL,
  DELETE_DASHBOARD,
  DELETE_DASHBOARD_MULTI,
  SET_FAV,
  UNSET_FAV,
} from "@/store/actionType";

export default {
  name: "Dashboards-Index",
  components: {
    BoxItem,
    ModuleHeader,
    ModuleAction,
    RowAction
  },
  data: () => ({
    disabledRowActions,
    // disabledRowActions: {
    //   'edit':true, 
    //   'info':true, 
    //   'trashcan':false, 
    //   'action': true
    // },
    sortFields: [
      {txt: "Title", field: "dashboard_title"},
      {txt: "Date", field: "changed_on_utc"},
    ],
    curSortField: '',
    viewGrid: true,
    viewFavOnly: false,
    selectedItems: [],
    search: '',
    headers: [
      { text: "Favorite", value: "favorite", width: 75 },
      { text: "Title", value: "dashboard_title" },
      { text: "Description", value: "description" },
      { text: "Slices", value: "slice_count" },
      { text: "Created By", value: "created_by.username" },
      { text: "Modified By", value: "changed_by.username" },
      { text: "Last Modified", value: "changed_on_delta_humanized" },
      { text: "Actions", value: "action", width: 200, sortable: false }
    ],
    collection: [], // use one variable for Recent + Favorites for consistency,
    loading: false,
    moduleFilter:'',
  }),
  computed: {
    filteredItemCount () {
      return [ 
        this.collection.length, // count of items
        // filtered items
        this.getModels(this.collection, this.viewFavOnly).length
      ]
    },
    selectedItemIds () {
      let id_list = this.selectedItems.map(a => a.id);
      return id_list;
    },
  },
  methods: {
    // Imports
    //////////
    //moduleUtils
    toggleSelectAll,
    modifyAllCheck,
    selectItemsEvent,
    filteredObjs,
    //////////
    moduleFilterChanged (input) {
      this.moduleFilter = input;
    },
    getRecentModels(arr) {
      const filteredArr = arr.filter(function(item) {
        return item.recent === true;
      });
      return filteredArr;
    },
    getFavModels(arr) {
      return arr.filter(function(item) {
        return item.favorite === true;
      });
    },
    getModels(arr, viewFavOnly) {
      // return viewFavOnly ? this.getFavModels(arr) : this.getRecentModels(arr)
      if (arr.length > 0 && this.moduleFilter.length > 0) {
        arr = this.filteredObjs(arr, 'dashboard_title', this.moduleFilter)
      }
      return viewFavOnly ? this.getFavModels(arr) : this.getRecentModels(arr);
    },
    load() {
      const columns = [
        "id",
        "changed_by.username",
        'created_by.first_name',
        'created_by.last_name',
        'created_by.username',
        "changed_on_utc",
        "changed_on_delta_humanized",
        "dashboard_title",
        "description",
        "slice_count"
      ];
      
      const q= {
        "columns": columns,
        "order_column": this.curSortField,
        "order_direction": "desc",
      };

      const favQ = {
        ...q,
        "filters": [
          { 
          "col": "id", 
          "opr": "dashboard_is_favorite", 
          "value": "!t"
          }
        ]
      };

      // Load Recent Item
      let recentArr=[];
      this.loading = true
      this.$store
        .dispatch(GET_DASHBOARD_ALL, q)
        .then((r) => {
          recentArr  = r.data.result;          

          // Load Favorite Charts
          return this.$store
            .dispatch(GET_DASHBOARD_ALL, favQ);
        })
        .then((favResult) => {
          this.collection = this.mergeFavItems(recentArr, favResult);
        })
        .catch((r) => {
          console.error("We have error:", r);
        })
        .finally(() => {
          this.loading = false
        })
    },

    mergeFavItems(recentArr, favResult) {
      let favIds = favResult.data.ids;

      for (let index in favResult.data.result) {
        favResult.data.result[index]['favorite'] = true;
      }
      for (let index in recentArr) {
        const _index = favIds.indexOf(recentArr[index].id);
        if ( _index === -1) {
          recentArr[index]['favorite'] == false;
          recentArr[index]['recent'] = true;
          favResult.data.result.push(recentArr[index]);
        } else {
          favResult.data.result[_index]['recent'] = true;
        }
      }
      return favResult.data.result; 
    },

    toggleFav(item) {
      const param = {
        "id": item.id,
        "className": 'dashboard'
      };

      let collection = this.collection;
      let itemIndex = collection.indexOf(item);

      if (item.favorite === true) {
        this.$store
          .dispatch(UNSET_FAV, param)
          .then((r) => {
            item.favorite = false;            
            let editedItem = { ...item};
            collection.splice(itemIndex, 1, editedItem)
          })
          .catch((r) => {
            console.error("We have error: ", r);
          });
      } else {
        this.$store
          .dispatch(SET_FAV, param)
          .then((r) => {
            item.favorite = true;
            let editedItem = { ...item};
            collection.splice(itemIndex, 1, editedItem)
          })
          .catch((r) => {
            console.error("We have error: ", r);
          });
      }
    },    
    clickItem(item) {
      this.$router.push({ name: 'Dashboards-Detail', params: { id: item.id } });
    },
    deleteItem(item) {
      this.$store
        .dispatch(DELETE_DASHBOARD, item.id)
        .then(() => {
          this.load();
        })
        .catch((r) => {
          console.error("We have error:", r);
        });
    },
    deleteAll() {
      // console.log('delete all', this.selectedItemIds)
      let selIds = this.selectedItemIds
      // const selIds = this.getRecentModels(this.collection).filter(ele => ele.checked === true).map(ele => ele.id);
      this.$store
        .dispatch(DELETE_DASHBOARD_MULTI, selIds)
        .then(() => {
          this.load();
        })
        .catch((r) => {
          console.error("We have error:", r);
        });
    },
    clickNewItem() {
      this.$router.push({ name: 'Dashboards-New' });
    },
    /**
     * Update the selection status in Grid View
     */
    updateGridViewSelection(obj) {
      let selItem = this.collection.find(item => item.id === obj.item.id)
      if (selItem) {
        selItem.checked = obj.value
      }
    },
    /**
     * Update the selection status in Grid View
     */
    toggleChecked(item) {
      let index = this.selectedItems.findIndex(_item => _item.id === item.id)
      if (item.checked === true) {
        if (index == -1) {
          this.selectedItems.push(item)
        }
      }
      else {
        if (index > -1) {
          this.selectedItems.splice(index, 1)
        }
      }
      
    },
  },
  mounted() {
    this.load();
  },
};
</script>

