<template>
  <div class="d-flex">
    <div>
      <v-avatar :size="size" color="teal">
        <span dark :class="txtClass" v-text="initials"></span>
      </v-avatar>
    </div>
    <div class="ml-3 align-self-center">
      <span class="d-inline-block name">{{ item.name }}</span>
    </div>
  </div>
</template>

<script>
import { extractInitials } from "@/utils/stringUtils";

export default {
  components: {},
  props: {
    item: {
      type: Object,
      required: true,
    },
    size: {
      type: Number,
      default: 36,
    },
    txtClass: {
      type: String,
      default: "text-h6",
    },
  },
  data() {
    return {
      initials: extractInitials(this.item.name),
    };
  },
  methods: {},
};
</script>
