<template>
  <div>
    <!-- Members view -->
    <v-container class="pa-0 pt-3">
          <v-row>
            <v-col
              cols="6"
              class="flex-grow-0 flex-shrink-0 pt-0"
              style="min-width: 100px"
            >
              <v-card class="mx-auto" style="background: rgb(18, 18, 18)">
                <v-card-title class="pa-0"> Members </v-card-title>
                <ModuleAction
                  class="pl-0 pt-3"
                  title="Edit"
                  search-label="Search Members"
                  :n_items="filteredItemCount"
                  @clickNewItem="clickEditMetadata"
                  @moduleFilterChanged="moduleFilterChanged"
                  @selectItemsEvent="selectItemsEvent"
                />
              </v-card>
              <div
                class="
                  d-flex
                  flex-grow-1 flex-column
                  pa-0
                  flex-basis-0
                  fill-height
                  overflow-x-auto
                "
              >
                <!-- show-select -->
                <!-- @toggle-select-all="toggleSelectAll" -->
                <v-data-table
                  id="tblMembers"
                  v-model="selectedItems"
                  :items="collection"
                  :headers="memberHeaders"
                  :search="searchMember"
                  item-key="userId"
                  class="mt-3"
                  :hide-default-footer="true"
                  :header-props="{ sortIcon: 'mdi-chevron-down' }"
                >
                  <template #[`item.username`]="{ item }">
                    <div @click.stop class="title">
                      {{ item.name }}
                    </div>
                    <span>{{ item.email }}</span>
                  </template>
                  <template #[`item.action`]="{ item }">
                    <!-- @clickItem="clickDashboard" -->
                    <RowAction
                      :item="item"
                      :disabledList="disabledList"
                      @deleteItem="deleteItem"
                    />
                  </template>
                </v-data-table>
              </div>
            </v-col>
            <!-- Title and Description -->
            <v-col
              cols="6"
              style="min-width: 500px; padding: 0 !important"
              class=""
            >
              <v-card
                class="mx-auto"
                style="min-height: 80vh; background: rgb(18, 18, 18)"
              >
                <v-card-title> Project Name & Description </v-card-title>
                <v-card-text>
                  <div class="mb-3">Project Name</div>
                  <v-text-field
                    filled
                    label=""
                    v-model="project.project_title"
                    :readonly="true"
                  ></v-text-field>
                  <p>Description</p>
                  <v-textarea
                    filled
                    label=""
                    v-model="project.description"
                    :readonly="true"
                  ></v-textarea>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
    <div>
      <EditMetadata
        :projectMeta="project"
        v-model="member_select_modal"
        @reloadProject="$emit('reloadProject', project.id)"
        @showErrorSnackbar="snackbar=true"
        @closeDialog="closeDialog"
      />
    </div>
    <v-snackbar
      v-model="snackbar"
      color="#ffbb33"
    > Failed to add Member(s) to Project
      <template v-slot:action="{ attrs }">
        <v-btn
          color="#3366FF"
          v-bind="attrs"
          @click="snackbar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import RowAction from "@/views/_partials/RowAction";
import ModuleAction from "@/views/_partials/ModuleAction";
import EditMetadata from "./modals/EditMetadata";

import {
  REMOVE_PROJECT_CHILDREN,
} from "@/store/actionType";

import { 
    toggleSelectAll,
    modifyAllCheck,
    selectItemsEvent,
    filteredObjs,
  } from "@/utils/moduleUtils.js";

export default {
  name: "Members-Detail",
  components: {
    ModuleAction,
    RowAction,
    EditMetadata,
  },
  data: () => ({
    disabledList: {'edit':true, 'info':true, 'trashcan':false, 'action': true},
    moduleFilter: '',
    snackbar: false,
    selectedItems: [],
    searchMember: "",
    member_select_modal: false,
    memberHeaders: [
      { text: "Name", value: "username" },
      { text: "Role", value: "role" },
      { text: "Actions", value: "action", width: 200, sorteble: false },
    ],
  }),
  props: {
    project: {
      type: Object,
      default: null,
    },
    viewGrid: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    filteredItemCount () {
      return [this.project.members_metadata.length, this.collection.length]
    },
    collection() {
      console.log('computed', this.project)
      let arr = this.project.members_metadata;
      // console.log('computed', arr)
      if (arr && this.moduleFilter.length > 0) {
        arr = this.filteredObjs(arr, 'name', this.moduleFilter)
      }
      return arr
    },
    selectedItemIds () {
      let id_list = this.selectedItems.map(a => a.userId);
      return id_list;
    },
  },
  methods: {
    // Imports
    //////////
    //moduleUtils
    toggleSelectAll,
    modifyAllCheck,
    selectItemsEvent,
    filteredObjs,
    //////////
    closeDialog() {
      this.member_select_modal=false;
    },
    clickEditMetadata() {
      console.log('vis select', this.project, this.member_select_modal)
      this.member_select_modal = true;
    },
    moduleFilterChanged (input) {
      this.moduleFilter = input;
    },
    deleteItem(item) {
      console.log('delete me', item)
      const className = "metadata";
      const param = {
        className: className,
        id: this.project.id,
        q: [item.userId],
      };
      this.$store
        .dispatch(REMOVE_PROJECT_CHILDREN, param)
        .then((r) => {
          this.$emit('reloadProject', this.project.id);
        })
        .catch((r) => {
          console.error("Error: ", r);
          this.snackbar=true;
        });
    },
    clickMember(item) {
      console.log("Need to confirm how to implement");
    },
    // deleteAll() {
    //   // Check if deleting all members of project and throw error
    //   console.log('members', this.this.project.members_metadata)
    //   console.log('delete me', this.selectedItemIds)
    //   let selIds = this.selectedItemIds
    //   const param = {
    //     className: "metadata",
    //     id: this.project.id,
    //     q: selIds,
    //   };
    //   console.log('delete these', param)
    //   // this.$store
    //   //   .dispatch(REMOVE_PROJECT_CHILDREN, param)
    //   //   .then(() => {
    //   //     this.$emit('reloadProject', this.project.id);
    //   //   })
    //   //   .catch((r) => {
    //   //     console.error("We have error:", r);
    //   //     this.$emit('showErrorSnackbar')
    //   //   });
    //     this.selectedItems=[];
    // },
  },
};
</script>