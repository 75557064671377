<template>
<svg
    xmlns="http://www.w3.org/2000/svg"
    height="18"
    viewBox="0 0 36 36"
    width="18"
    aria-hidden="false"
    role="img"
    :fill=fill
    aria-label="title"
  >
    <path
      d="M33 2H3a1 1 0 0 0-1 1v30a1 1 0 0 0 1 1h30a1 1 0 0 0 1-1V3a1 1 0 0 0-1-1ZM12 32H4v-4h8Zm0-6H4v-4h8Zm0-6H4v-4h8Zm20 12H14v-4h18Zm0-6H14v-4h18Zm0-6H14v-4h18Zm0-6H4V4h28Z"
    />
  </svg>
</template>


<script>
export default {
  name: "tableIcon",
  props: {
    fill: {
      type: String,
      default() {
        return "#B9B9B9";
      },
    },
  },
};
</script>

<style>
.fill {
  fill: #B9B9B9;
  /* fill: blue; */
}
</style>