import { mainAxios } from "./axios";

class DatasourceService {
  getDB(id) {
    return mainAxios.get(`/database/${id}`);
  }
  createDB(params) {
    return mainAxios.post(`/database/`, params);
  }
  updateDB(id, params) {
    return mainAxios.put(`/database/${id}`, params);
  }
  testDbConnect(data) {
    return mainAxios.post('/database/test_connection', data);
  }

  uploadFile(params) {
    return mainAxios.post('/database/post_file', params);
  }
}

export default new DatasourceService();