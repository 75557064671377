<template>
  <v-tooltip right>
    <template v-slot:activator="{ on, attrs }">
      <v-icon 
        small
        v-bind="attrs" 
        v-on="on"
        class="ml-auto" 
        :color="validCol ? '#B9B9B9' : '#ffbb33'"
      >
        mdi-information-outline
      </v-icon>
    </template>
    <span v-if="requiredCol" style="color:#ffbb33">
      Required<br>
    </span>
    <span v-for="(line, key) in message" :key="key">
      {{ line }} <br>
    </span>
    <span>
      &ensp; <i>{{ displayType }}</i><br>
    </span>
  </v-tooltip>
</template>

<script>


export default {
  props: {
    requiredCol: {
      type: Boolean,
      default() {
        return false;
      },
    },
    validCol: {
      type: Boolean,
      default() {
        return null;
      },
    },
    message: {
      type: Array,
      default() {
        return [""];
      },
    },
    displayType: {
      type: String,
      default() {
        return "";
      },
    },
  },
}
</script>
