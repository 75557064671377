<template >
  <div v-if="!!vis_data" ref="tbGrid">
    <highcharts :options="pie_data" class="pie-chart" />
  </div>
</template>


<script>
import Highcharts from "highcharts"
require("highcharts/modules/accessibility");(Highcharts);
require("highcharts/modules/no-data-to-display")(Highcharts);

// These enable the export hamburger
// require("highcharts/modules/export-data")(Highcharts);
// require("highcharts/modules/exporting")(Highcharts);

export default {
  name: "PieVis",
  components: {
  },
  props: {
    form_data: {
      default() {
        return {}
      }
    },
    vis_data: {
      default() {
        return {
          'row_data': [],
          'columns': [],
          'coltypes': [],
        };
      },
    },
  },
  data() {
    return {
      // pie_data: null,
    };
  },
  computed: {
    // vis_data_cols() {
    //   // console.log('comnputed pie', this.vis_data)
    //   // return this.vis_data
    //   return this.vis_data && this.vis_data.columns ? this.vis_data.columns : [];
    // //   console.log('compute cols', this.vis_data)
    // //   return this.vis_data && this.vis_data.cols ? this.vis_data.cols : [];
    // },
    // vis_data_coltypes() {
    //   // console.log('comnputed pie', this.vis_data)
    //   // return this.vis_data
    //   return this.vis_data && this.vis_data.coltypes ? this.vis_data.coltypes : [];
    // //   console.log('compute cols', this.vis_data)
    // //   return this.vis_data && this.vis_data.cols ? this.vis_data.cols : [];
    // },
    // vis_data_rows() {
    //   // console.log('compute cols', this.vis_data)
    //   return this.vis_data && this.vis_data.row_data ? this.vis_data.row_data : [];
    // },
  // },
  // methods: {
    pie_data() {
      // console.log('my pie', this.vis_data)
      let pie_data;
      let series = [];
      // let nameFields = [];
      // let valueField;
      const cols = this.form_data.all_columns;
      const metric = this.form_data.metric.label

      this.vis_data.row_data.forEach(row => {
        let row_name = [];
        cols.forEach(col => row_name.push(row[col]))
        row_name = row_name.join(', ')
        const row_metric = row[metric]

        series.push({
          name: row_name,
          y: row_metric,
        })
      })

      pie_data = {
        chart: {
          type: "pie",
          marginTop: 40,
          marginBottom: 80,
          plotBorderWidth: 1,
          backgroundColor: "rgb(21, 21, 21)",
        },
        plotOptions: {
          pie: {
            dataLabels: {
              enabled: true,
            }
          }
        },
        credits: {
          enabled: false,
        },
        tooltip: {
          pointFormat:
            '<b>Slice Name: </b>{point.name}<br>' +
            '<b>Value: </b>{point.y}<br>' +
            '<b>% Total: </b>{point.percentage:.1f}%'
        },
        title: {
          text: "",
        },
        series: [
          {
            data: series,
          },
        ],
      };
      // console.log('pie_data', this.vis_data)
      return pie_data;
    },
  },
}
</script>

<style lang="scss" scoped>

</style>