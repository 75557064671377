import { mainAxios } from "./axios";

class DatabaseService {
  get(id) {
    return mainAxios.get(`/database/${id}`);
  }
  getData(id, q) {
    return mainAxios.get(`/database/${id}/data?q=${JSON.stringify(q)}`);
  }
  getAll(q) {
    return mainAxios.get(`/database/?q=${JSON.stringify(q)}`);
  }
  create(params) {
    return mainAxios.post(`/database/`, params);
  }
  update(id, params) {
    return mainAxios.put(`/database/${id}`, params);
  }
  delete(id) {
    return mainAxios.delete(`/database/${id}`);
  }
}

export default new DatabaseService();
