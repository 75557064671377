<template>
  <div>
    <v-navigation-drawer
      :clipped="$vuetify.breakpoint.lgAndUp"
      :mini-variant.sync="mini"
      app
      width="220"
      mini-variant-width="50"
    >
      <!-- Home Button -->
      <AppLogo @expandMinimize="changeMini" :mini="mini" @goToHome="goToHome" />
      <div class="separator-logo" v-if="!mini"></div>
      <v-list dense nav class="my-4">
        <template v-for="item in mnuProjects">
          <v-hover v-slot="{ hover }" :key="item.text">
            <v-tooltip color="#1BBAB9" right v-if="mini">
              <template v-slot:activator="{ on, attrs }">
                <v-list-item
                  :class="hover ? 'hover-item' : ''"
                  link
                  :to="item.url"
                  v-on="on"
                  v-bind="attrs"
                >
                  <v-list-item-icon class="mr-4">
                    <v-icon :color="hover ? 'white' : 'grey'">{{
                      item.icon
                    }}</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title :class="hover ? 'hover-title' : ''">{{
                      item.text
                    }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>
              <span>{{ item.text }}</span>
            </v-tooltip>
            <v-list-item
              :class="hover ? 'hover-item' : ''"
              link
              :to="item.url"
              v-else
            >
              <v-list-item-icon class="mr-4">
                <v-icon :color="hover ? 'white' : 'grey'">{{
                  item.icon
                }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title :class="hover ? 'hover-title' : ''">{{
                  item.text
                }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-hover>
        </template>
      </v-list>
      <div v-if="mini" class="separator-mini"></div>
      <SeparatorText v-else text="Visualizations" />
      <v-list dense nav class="mt-3">
        <template v-for="item in mnuVisualizations">
          <v-hover v-slot="{ hover }" :key="item.text">
            <v-tooltip color="#1BBAB9" right v-if="mini">
              <template v-slot:activator="{ on, attrs }">
                <v-list-item
                  :class="hover ? 'hover-item' : ''"
                  link
                  :to="item.url"
                  v-on="on"
                  v-bind="attrs"
                >
                  <v-list-item-icon class="mr-4">
                    <v-icon :color="hover ? 'white' : 'grey'">{{
                      item.icon
                    }}</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title :class="hover ? 'hover-title' : ''">{{
                      item.text
                    }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>
              <span>{{ item.text }}</span>
            </v-tooltip>
            <v-list-item
              v-else
              :class="hover ? 'hover-item' : ''"
              link
              :to="item.url"
            >
              <v-list-item-icon class="mr-4">
                <v-icon :color="hover ? 'white' : 'grey'">{{
                  item.icon
                }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title :class="hover ? 'hover-title' : ''">{{
                  item.text
                }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-hover>
        </template>
        <v-hover v-slot="{ hover }">
          <v-tooltip color="#1BBAB9" right v-if="mini">
            <template v-slot:activator="{ on, attrs }">
              <v-list-item
                :class="hover ? 'hover-item' : ''"
                link
                to="/sql-page"
                v-on="on"
                v-bind="attrs"
              >
                <v-list-item-icon class="mr-4">
                  <sql :hover="hover" />
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title :class="hover ? 'hover-title' : ''"
                    >Sql Explorer</v-list-item-title
                  >
                </v-list-item-content>
              </v-list-item>
            </template>
            <span>Sql Explorer</span>
          </v-tooltip>
          <v-list-item
            v-else
            :class="hover ? 'hover-item' : ''"
            link
            to="/sql-page"
          >
            <v-list-item-icon class="mr-4">
              <sql :hover="hover" />
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title :class="hover ? 'hover-title' : ''"
                >Sql Explorer</v-list-item-title
              >
            </v-list-item-content>
          </v-list-item>
        </v-hover>
      </v-list>
      <div v-if="mini" class="separator-mini"></div>
      <SeparatorText v-else text="Data Sources" />
      <v-list dense nav class="my-4">
        <template v-for="item in mnuDatasources">
          <v-hover v-slot="{ hover }" :key="item.text">
            <v-tooltip color="#1BBAB9" right v-if="mini">
              <template v-slot:activator="{ on, attrs }">
                <v-list-item
                  :class="hover ? 'hover-item' : ''"
                  link
                  :to="item.url"
                  v-on="on"
                  v-bind="attrs"
                >
                  <v-list-item-icon class="mr-4">
                    <v-icon :color="hover ? 'white' : 'grey'">{{
                      item.icon
                    }}</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title :class="hover ? 'hover-title' : ''">{{
                      item.text
                    }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>
              <span>{{ item.text }}</span>
            </v-tooltip>
            <v-list-item
              :class="hover ? 'hover-item' : ''"
              link
              :to="item.url"
              v-else
            >
              <v-list-item-icon class="mr-4">
                <v-icon :color="hover ? 'white' : 'grey'">{{
                  item.icon
                }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title :class="hover ? 'hover-title' : ''">{{
                  item.text
                }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-hover>
        </template>
      </v-list>
      <!-- <div v-if="mini" class="separator-mini"></div> -->
      <!-- <div v-else class="separator"></div> -->
      <!-- <div class="user d-flex" :class="mini ? 'mini' : ''">
        <v-tooltip v-if="mini" right color="#1BBAB9">
          <template v-slot:activator="{ on, attrs }">
            <v-avatar
              v-on="on"
              v-bind="attrs"
              size="36"
              style="border: solid 1px rgba(255, 255, 255, 0.12)"
            >
              <img src="https://cdn.vuetifyjs.com/images/john.jpg" />
            </v-avatar>
          </template>
          <span>Account Settings</span>
        </v-tooltip>
        <v-avatar
          v-else
          size="36"
          style="border: solid 1px rgba(255, 255, 255, 0.12)"
        >
          <img src="https://cdn.vuetifyjs.com/images/john.jpg" />
        </v-avatar>
        <p class="user-text">emiliomanull</p>
      </div> -->
      <!-- <div v-if="mini" class="separator-mini"></div> -->
      <!-- <div v-else class="separator"></div> -->
      <!-- <v-list dense nav class="mt-3">
        <v-tooltip color="#1BBAB9" right v-if="mini">
          <template v-slot:activator="{ on, attrs }">
            <v-list-item v-on="on" v-bind="attrs" link>
              <v-list-item-icon class="mr-4">
                <v-icon>mdi-key-variant</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Permissions/Roles</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
          <span>Permissions/Roles</span>
        </v-tooltip>
        <v-list-item v-else link>
          <v-list-item-icon class="mr-4">
            <v-icon>mdi-key-variant</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Permissions/Roles</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list> -->
    </v-navigation-drawer>
  </div>
</template>

<script>
import AppLogo from "./core/AppLogo.vue";
import SeparatorText from "./core/SeparatorText.vue";
import sql from "./svg/sql.vue";

export default {
  name: "AppLeftMenu",
  components: {
    AppLogo,
    SeparatorText,
    sql,
  },
  data() {
    return {
      mini: false,
      tooltipShow: false,
      input: {
        user_id: 2,
      },
      mnuProjects: [
        {
          text: "Projects",
          icon: "mdi-chart-box",
          url: "/projects",
        },
        {
          text: "Favorites",
          icon: "mdi-star",
          url: "/favorites"
        },
        {
          text: "Recent Modified",
          icon: "mdi-history",
        },
        {
          text: "Recent Deleted",
          icon: "mdi-delete-clock",
        },
      ],
      mnuVisualizations: [
        {
          text: "Charts",
          icon: "mdi-chart-bar",
          url: "/charts",
        },
        {
          text: "Dashboards",
          icon: "mdi-view-dashboard",
          url: "/dashboards",
        },
      ],
      mnuDatasources: [
        {
          text: "Datasets",
          icon: "mdi-database-settings",
          url: "/datasets",
        },
        {
          text: "Database",
          icon: "mdi-database",
          url: "/databases",
        },
        {
          text: "Saved Queries",
          icon: "mdi-database-search",
          url: "/saved-query",
        },
        {
          text: "Query History",
          icon: "mdi-database-clock",
          url: "/query-history",
        },
        // Add here for permissioned users to see admin-users
        // {
        //   text: "???",
        //   icon: "mdi-chart-box",
        //   url: "/users",
        // },
      ],
    };
  },
  methods: {
    changeMini() {
      this.mini = !this.mini;
    },
    goToHome() {
      // this.$router.push("/");
      if (this.$route.path !== "/") {
        this.$router.push("/");
      } else {
        this.$router.go()
      }
    },
  },
};
</script>

<style scoped>
.theme--dark.v-navigation-drawer {
  background: #262626 !important;
  overflow: inherit !important;
}

.v-list-item .v-list-item__title,
.v-list-item .v-list-item__subtitle,
.v-list .v-list-item .v-list-item__title,
.v-list .v-list-item .v-list-item__subtitle,
.v-list-item--dense .v-list-item__title,
.v-list-item--dense .v-list-item__subtitle,
.v-list--dense .v-list-item .v-list-item__title,
.v-list--dense .v-list-item .v-list-item__subtitle {
  font-size: 17px;
  font-weight: normal !important;
  line-height: 25px;
}
.theme--dark.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled) {
  color: grey;
}
.v-list-group.v-list-group--active.primary--text {
  color: red !important;
}

.v-list--nav .v-list-item {
  padding: 0px 0 0 16px;
}

.separator-logo {
  margin-left: 12px;
  width: 175px;
  border: 1px solid #707070;
}

.separator {
  margin-left: 12px;
  margin-right: 12px;
  border: 1px solid #707070;
}

.separator-mini {
  margin-left: 4px;
  margin-right: 4px;
  border: 1px solid #707070;
}

.user {
  color: #707070;
  align-items: center;
  padding: 16px 0 16px 24px;
}

.user.mini {
  padding: 16px 0 16px 8px;
}

.user:hover {
  background: #454545;
  color: white;
}

.user-text {
  font-size: 17px;
  align-items: center;
  margin-bottom: 0px;
  margin-left: 16px;
}

.hover-title {
  color: #ffffff;
}

.hover-item {
  background-color: #454545;
}
</style>
