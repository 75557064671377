

<template>
<svg id="Group_160417" data-name="Group 160417" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
  <rect id="Canvas" width="18" height="18" fill="#b9b9b9" opacity="0"/>
  <path id="Path_83576" data-name="Path 83576" d="M16.536,15.88,12,7V2h.5a.5.5,0,0,0,.5-.5V.5a.5.5,0,0,0-.5-.5h-7A.5.5,0,0,0,5,.5v1a.5.5,0,0,0,.5.5H6V7L1.464,15.88A1.5,1.5,0,0,0,2.829,18H15.17a1.5,1.5,0,0,0,1.366-2.121Zm-12.172-3.7L7,7.227V2h4V7.227l1.318,2.455Z" fill="#b9b9b9"/>
</svg>

</template>



<script>
export default {
  name: "beakerIcon",
  props: {
    fill: {
      type: String,
      default() {
        return "#B9B9B9";
      },
    },
  },
};
</script>

<style>
.fill {
  fill: #B9B9B9;
  /* fill: blue; */
}
</style>
