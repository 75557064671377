<template>
  <div class="d-flex flex-column fill-height flex-grow-1 overflow-hidden pt-6">
    <!-- :breadcrumb-return="" -->
    <ModuleHeader
      title="Projects" 
      :view-grid="viewGrid"
      :selectedItems="selectedItemIds"
      returnToPage="Index"
      @updateLayoutOption="viewGrid = !viewGrid" 
      @updateViewFavOnly="viewFavOnly = !viewFavOnly"/>

    <ModuleAction
      title="New Project"
      search-label="Search Projects"
      :selectedItems="selectedItemIds"
      :n_items="filteredItemCount"
      @clickNewItem="clickNewItem"
      @deleteAll="deleteAll"
      @selectItemsEvent="selectItemsEvent"
      @moduleFilterChanged="moduleFilterChanged"
    />
    <div v-if="viewGrid">
      <v-container>
        <v-row v-if="loading" 
          class="container-row-resolution py-5 d-flex justify-center"
          >
          <v-progress-circular
            indeterminate
            color="primary"
          ></v-progress-circular>
        </v-row>
        <v-row 
          v-else-if="getModels(collection, viewFavOnly).length >0" 
          class="container-row-resolution"
        >
          <BoxItem 
            v-for="item in getModels(collection, viewFavOnly)" 
            v-bind:key="item.id"
            :item="item" 
            :class-name="`project`" 
            :title-field="`project_title`" 
            :selectable=true
            @toggled="toggleFav"
            @checked="toggleChecked"
            @clickItem="clickItem"
            @clickInfoItem="clickInfoItem"
          />
        </v-row>
        <v-row v-else class="container-row-resolution pt-5 d-flex justify-center">
          <p>No Projects Found ...</p>
        </v-row>
      </v-container>
    </div>

    <!-- Objects in List View -->
    <div
      v-if="!viewGrid"
      class="
        d-flex
        flex-grow-1 flex-column flex-basis-0
        fill-height
        overflow-x-auto
      "
    >
      <v-container>
        <v-row v-if="loading" class="container-row-resolution py-5 d-flex justify-center">
          <v-progress-circular
            indeterminate
            color="primary"
          ></v-progress-circular>
        </v-row>
        <v-data-table
          id="tblItems"
          v-else-if="getModels(collection, viewFavOnly).length > 0"
          v-model="selectedItems"
          :items="getModels(collection, viewFavOnly)"
          :items-per-page="$pageItemCount"
          :headers="headers"
          :search="search"
          @click:row="editItem"
          @item-selected="updateGridViewSelection"
          @toggle-select-all="toggleSelectAll"
          show-select
          item-key="id"      
          class="mt-3"
          :hide-default-footer="true"
          :header-props="{sortIcon: 'mdi-chevron-down'}"
        >
          <template v-slot:top="{ pagination, options, updateOptions }">
            <v-data-footer 
              :pagination="pagination" 
              :options="options"
              @update:options="updateOptions"
              items-per-page-text="$vuetify.dataTable.itemsPerPageText"/>
          </template>
          <template v-slot:[`header.favorite`]="{header}">
            <v-icon v-if="header.favorite">mdi-star</v-icon>
            <v-icon v-else>mdi-star-outline</v-icon>
          </template>
          <template #[`item.favorite`]="{ item }">
            <div @click.stop="toggleFav(item, 'project')" class="actions">
              <v-btn icon width="24" v-tippy content="Favorite">
                <v-icon v-if="item.favorite">mdi-star</v-icon>
                <v-icon v-else>mdi-star-outline</v-icon>
              </v-btn>
            </div>
          </template>
          <template #[`item.name`]="{ item }">
            <div @click.stop class="title">
              <a :href="`/#/projects/${item.id}`">{{item.project_title}}</a>
            </div>
            <span>{{item.description}}</span>
          </template>
          <template #[`item.created_by.username`]="{ item }">
            <div v-if="item.created_by" class="actions">
              {{item.created_by.username}}
            </div>
          </template>
          <template #[`item.action`]="{ item }">
            <RowAction 
              :item="item" 
              :disabledList="disabledRowActions"
              @editItem="editItem" 
              @deleteItem="deleteItem"
              @infoItem="clickInfoItem"/>
          </template>
        </v-data-table>
        <v-row v-else class="container-row-resolution pt-5 d-flex justify-center">
          <p>No Projects Found ...</p>
        </v-row>
      </v-container>
    </div>

    <!-- project detail modal -->
    <div>
      <v-dialog
        content-class="custom-dialog"
        v-model="detailModalVisible"
        max-width="900"
        persistent
        scrollable
        max-height="300"
      >

        <!-- style="min-height: 66vh;" -->
        <DetailModal
          :project="curProject"
          :visible="detailModalVisible"
          @closeProjectDialog="closeProjectDialog"
        />
        <!-- <v-card v-if="curProject" 
          color="#262626"
          style="max-height: 200;"
          >
          <v-card-title>
            <h3>{{curProject.project_title}}</h3>
          </v-card-title>
          <v-divider/>
          <v-container overflow-hidden>
            <v-card-subtitle>
              <p>
                  Created By: {{curProject.created_by.first_name}} {{curProject.created_by.last_name}}<br>
                  Last Modified: {{curProject.changed_on_delta_humanized}} by {{curProject.created_by.username}}<br>
                  Description: {{ curProject.description }}
              </p>
            </v-card-subtitle>
            <v-divider/>
            <div class="outline d-flex">
              <v-col md="12">
                <v-tabs color="white" v-model="tab">
                  <v-tab>MEMBERS</v-tab>
                  <v-tab>CHARTS</v-tab> 
                  <v-tab>DASHBOARDS</v-tab>
                </v-tabs>
                <v-tabs-items v-model="tab">
                  <v-tab-item>
                  <DetailTabs detailTab="members"
                      :metadata="memberMetadata"
                    />
                  </v-tab-item>
                  <v-tab-item>
                    <DetailTabs detailTab="charts"
                      :metadata="sliceMetadata"
                    />
                  </v-tab-item>
                  <v-tab-item>
                    <DetailTabs detailTab="dashboards"
                      :metadata="dashboardMetadata"
                    />
                  </v-tab-item>
                </v-tabs-items>
              </v-col>
            </div>
            </v-container>
            <v-divider/>
            <v-card-actions>
              <v-btn class="mr-5 fs-16" color="primary" plain x-large
                @click="goToProject">
                GO TO PROJECT
              </v-btn>
              <v-btn
                class="fs-16"
                color="primary"
                plain
                x-large
                @click="detailModalVisible = false; curProject=null;"
              >
                CLOSE
              </v-btn>
          </v-card-actions>
        </v-card> -->
      </v-dialog>
    </div>
  </div>
</template>

<script>
import BoxItem from "@/views/_partials/BoxItem";
import RowAction from "@/views/_partials/RowAction";
import ModuleHeader from "@/views/_partials/ModuleHeader";
import ModuleAction from "@/views/_partials/ModuleAction";
import '@spectrum-web-components/icons-workflow/icons/sp-icon-graph-scatter.js';
import '@spectrum-web-components/icons-workflow/icons/sp-icon-table-and-chart.js';
import DetailModal from './modals/DetailModal.vue'

import { 
  GET_PROJECT_ALL,
  DELETE_PROJECT,
  DELETE_PROJECT_MULTI,
  SET_FAV,
  UNSET_FAV
} from "@/store/actionType";

import { 
    toggleSelectAll,
    modifyAllCheck,
    selectItemsEvent,
    filteredObjs,
  } from "@/utils/moduleUtils.js";

export default {
  name: "Projects-Index",
  components: {
    // DetailTabs,
    DetailModal,
    ModuleHeader,
    ModuleAction,
    BoxItem,
    RowAction
  },
  data: () => ({
    avatarIndex: 0,
    alignments: [
        'start',
        'center',
        'end',
      ],
    colors: ['blue', 'red', 'green'],
    moduleFilter:'',
    sortFields: [
      {txt: "Title", field: "project_title"},
      {txt: "Date", field: "changed_on_utc"},
    ],
    curSortField: '',
    viewGrid: true,
    viewFavOnly: false,
    selectedItems: [],
    search: '',
    headers: [
      { text: "Favorite", value: "favorite", width: 75 },
      { text: "Name", value: "name" },
      { text: "Members", value: "member_count" },
      { text: "Slices", value: "slice_count" },
      { text: "Dashboards", value: "dashboard_count" },
      { text: "Last Modified", value: "changed_on_delta_humanized" },
      { text: "Created By", value: "created_by.username" },
      { text: "Actions", value: "action", width: 200, sortable: false },
    ],
    collection: [], // use one variable for Recent + Favorites for consistency
    detailModalVisible: false,
    curProject: null,
    tab: null,
    loading: false,
    disabledRowActions: {
      'edit':true, 
      'info': false, 
      'trashcan':false, 
      'action': true
    },
  }),
  computed: {
    // dashboardMetadata() {
    //   console.log('db meta', this.curProject.dashboard_metadata)
    //   return this.curProject.dashboard_metadata;
    // },
    // sliceMetadata() {
    //   return this.curProject.slice_metadata;
    // },
    // memberMetadata() {
    //   return this.curProject.members_metadata;
    // },
    // n_rows() {
    //   return Math.ceil((this.curProject.members_metadata.length || 0) / 3)
    // },
    filteredItemCount () {
      return [ this.collection.length, this.getModels(this.collection, this.viewFavOnly).length]
    },
    selectedItemIds () {
      let id_list = this.selectedItems.map(a => a.id);
      return id_list;
    },
  },
  methods: {
    // Imports
    //////////
    //moduleUtils
    toggleSelectAll,
    modifyAllCheck,
    selectItemsEvent,
    filteredObjs,
    //////////
    closeProjectDialog() {
      this.detailModalVisible = false; 
      this.curProject=null;
    },
    goToProject () {
      let project_id= this.curProject.id
      this.$router.push({ name: 'Projects-Detail', params: { id: project_id } });
      this.detailModalVisible = false; 
      this.curProject=null;

    },
    moduleFilterChanged (input) {
      this.moduleFilter = input;
    },
    getRecentModels(arr) {
      const filteredArr = arr.filter(function(item) {
        return item.recent === true;
      });
      return filteredArr;
    },
    getFavModels(arr) {
      return arr.filter(function(item) {
        return item.favorite === true;
      });
    },
    getModels(arr, viewFavOnly) {
      if (arr.length > 0 && this.moduleFilter.length > 0) {
        arr = this.filteredObjs(arr, 'project_title', this.moduleFilter)
      }
      return viewFavOnly ? this.getFavModels(arr) : this.getRecentModels(arr)
    },
    load() {
      const columns = [
        "id",
        "changed_by.username",
        'created_by.first_name',
        'created_by.last_name',
        'created_by.username',
        "changed_on_utc",
        "changed_on_delta_humanized",
        "project_title",
        "description",
        "members.username",
        "dashboard_count",
        "slice_count",
        "member_count",
        'slice_metadata',
        'dashboard_metadata',
        'members_metadata'
      ];
      
      const q= {
        "columns": columns,
        "order_column": this.curSortField,
        "order_direction": "desc",
      };

      const favQ = {
        ...q,
        "filters": [
          { 
          "col": "id", 
          "opr": "project_is_favorite", 
          "value": "!t"
          }
        ]
      };

      // Load Recent Project
      let recentArr=[];
      this.loading = true;
      this.$store
        .dispatch(GET_PROJECT_ALL, q)
        .then((r) => {
          recentArr  = r.data.result;          

          // Load Favorite Projects
          return this.$store
            .dispatch(GET_PROJECT_ALL, favQ);
        })
        .then((favResult) => {
          this.collection = this.mergeFavItems(recentArr, favResult);
        })
        .catch((r) => {
          console.error("We have error:", r);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    mergeFavItems(recentArr, favResult) {
      let favIds = favResult.data.ids;

      for (let index in favResult.data.result) {
        favResult.data.result[index]['favorite'] = true;
      }
      for (let index in recentArr) {
        const _index = favIds.indexOf(recentArr[index].id);
        if ( _index === -1) {
          recentArr[index]['favorite'] == false;
          recentArr[index]['recent'] = true;
          favResult.data.result.push(recentArr[index]);
        } else {
          favResult.data.result[_index]['recent'] = true;
        }
      }
      return favResult.data.result; 
    },

    toggleFav(item, className) {
      const param = {
        "id": item.id,
        "className": 'project'
      };

      let collection = this.collection;
      
      let itemIndex = collection.indexOf(item);

      if (item.favorite === true) {
        this.$store
          .dispatch(UNSET_FAV, param)
          .then((r) => {
            item.favorite = false;            
            let editedItem = { ...item};
            collection.splice(itemIndex, 1, editedItem)
            // console.log(collection);
          })
          .catch((r) => {
            console.error("We have error: ", r);
          });
      } else {
        this.$store
          .dispatch(SET_FAV, param)
          .then((r) => {
            item.favorite = true;
            let editedItem = { ...item};
            collection.splice(itemIndex, 1, editedItem)
          })
          .catch((r) => {
            console.error("We have error: ", r);
          });
      }
    },
    clickItem(item) {
      this.$router.push({ name: 'Projects-Detail', params: { id: item.id } });
    },
    deleteItem(item) {
      this.$store
        .dispatch(DELETE_PROJECT, item.id)
        .then(() => {
          this.load();
        });
    },
    clickInfoItem(item) {
      // console.log('inf0', this.detailModalVisible)
      this.detailModalVisible = true;
      this.curProject = item;
    //   console.log('inf0', this.detailModalVisible)
    },
    deleteAll() {
      let id_list = this.selectedItemIds
      console.log('delete thise:', id_list)
      // const selIds = this.getModels(this.collection, this.viewFavOnly).map(ele => ele.id);
      let selIds = id_list;
      this.$store
        .dispatch(DELETE_PROJECT_MULTI, selIds)
        .then(() => {
          this.load();
        })
        .catch((r) => {
          console.error("We have error:", r);
        });
    },
    clickNewItem() {
      this.$router.push({ name: "Projects-New" });
    },
    editItem(item) {
      this.$router.push({ name: 'Projects-Detail', params: { id: item.id } });
    },
    /*
    // selectItemsEvent(item) {
    //   switch (item.action) {
    //     case "select_all":
    //       this.collection.map(o => this.$set(o, 'checked', true))
    //       this.selectedItems=[...this.collection]
    //       break;
    //     case "unselect_all":
    //       this.collection.map(o => this.$set(o, 'checked', false))
    //       this.selectedItems=[]
    //       break;
    //   }
    // },
    */
    /**
     * Update the selection status in Grid View
     */
    updateGridViewSelection(obj) {
      
      let selItem = this.collection.find(item => item.id === obj.item.id)
      if (selItem) {
        selItem.checked = obj.value
      }
    },
    /**
     * Update the selection status in Grid View
     */
    toggleChecked(item) {
      let index = this.selectedItems.findIndex(_item => _item.id === item.id)
      if (item.checked === true) {
        if (index == -1) {
          this.selectedItems.push(item)
        }
      }
      else {
        if (index > -1) {
          this.selectedItems.splice(index, 1)
        }
      }
    },
  },
  mounted() {
    this.load();
  },
};
</script>

<style scoped>

  .title {
    padding-top: 6px;
  }

  .member-role {
    padding-right: 8px;
    text-align: right; 
    width: 100%;
  }

  .card-email {
    padding-right: 20px;
    font-size: 12px;
    font-style: italic;
    text-align: right; 
    width: 100%;
  }

  .close {
    margin-right: 15px;
    cursor: pointer;
    font-size: 20px;
  }
  .list-item {
    background-color: black;
    width: 33%;
  }

  /* .list-item> div { */
    /* align-items: center; */
  /* } */

  .list-item .title {
    font-size: 14px;
  }
  .list-item .desc {
    font-size: 10px;
  }
</style>
