<template>
  <div class="filter">
    <div class="filter-header d-flex">
      <div class="filter-header-title">Filters</div>
      <!-- <v-icon color="#B9B9B9" class="ml-auto">
        mdi-alert-circle-outline
      </v-icon> -->
      <v-tooltip right>
            <template v-slot:activator="{ on, attrs }">
              <v-icon 
                small
                v-bind="attrs" 
                v-on="on"
                class="ml-auto" 
                color="#B9B9B9"
              >
                mdi-information-outline 
              </v-icon>
            </template>
            <!-- <span style="color:#ffbb33">
              Required<br>
            </span> -->
            <span>
              Creates Server Side Filter for the Visualization<br>
              <!-- &ensp; <i>Categorical/Numerical</i><br> -->
              <!-- - If none an index for each row in dataset is applied<br> -->
          </span>
        </v-tooltip>
    </div>
    <div
      class="filter-container"
      ref="filterCon"
      @dragover.prevent
      @drop="dragFilterFinish($event)"
    >
      <template v-if="filters.length > 0">
        <div
          v-for="(filter, index) in filters"
          :key="index"
          class="column-item"
        >
          <v-btn
            v-if="filter.applied"
            class="mb-08"
            color="#1bbab9"
            rounded
            width="100%"
            height="24px"
            @contextmenu.prevent="$emit('showFilterOption', $event, index)"
          >
            <span class="ml-auto mr-auto">
              {{ filter.subject }} {{ filter.operator }} {{ filter.comparator || "{null}" }}
            </span>
            <v-icon @click="$emit('deleteFilter', index)" class="ml-auto" right>
              mdi-close-circle
            </v-icon>
          </v-btn>
          <v-btn
            v-else
            class="mb-08 filter-btn"
            rounded
            width="100%"
            height="24px"
            outlined
            :key="index"
            @contextmenu.prevent="$emit('showFilterOption', $event, index)"
          >
            <span class="ml-auto mr-auto">
              {{ filter.subject }}  {{ filter.operator }} {{ filter.comparator || "{null}" }}
            </span>
            <v-icon @click="$emit('deleteFilter', index)" class="ml-auto" right>
              mdi-close-circle
            </v-icon>
          </v-btn>
        </div>
      </template>
      <template v-else>
        <div class="blank-drag">Drop Filters Here</div>
        <p class="blank-text">
          Columns to use as a Filter
        </p>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    filters: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  methods: {
    /**
     * drag filter finish event
     * @param event
     */
    dragFilterFinish(event) {
      this.$emit("dragFilterFinish", event);
      let ftContainer = this.$refs.filterCon;
      ftContainer.scrollTo = 0;
    },
  },
  watch: {
    filters: {
      handler: function() {
        // console.log('filters:',this.filters)
      },
      immediate: true,
    }
  }
};
</script>

<style lang="scss" scoped>
.blank-drag {
  border: 1px dashed #909090;
  border-radius: 12px;
  text-align: center;
  color: #909090;
  padding-top: 4px;
  padding-bottom: 4px;
  font-size: 12px;
}

.blank-text {
  font-size: 12px;
  text-align: center;
  color: #909090;
  margin-top: 9px;
}

.column-text-btn {
  border-color: #00a7ff !important;
}

.column-item {
  margin-bottom: .5rem;
}

.filter-btn {
  border-color: #1bbab9 !important;
}
.filter {
  &-header {
    padding: 20px 15px;

    &-title {
      font-size: 16px;
    }
  }

  &-container {
    height: 137px;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 8px;
    border-bottom: 0.5px solid #373737;
    overflow-y: auto;
    @include scrollbars(0.4rem, #717171, #00000080);
  }
}
</style>