<!-- @/views/charts/modals/SelectDataSetDlg.vue -->
<template>
  <div>
    <!-- content-class="custom-dialog" -->
    <v-dialog
      v-model="dataset_modal"
      width="900"
      persistent
      scrollable
    >
      <v-card
        height="500px"
        color="modal_background"
      >
        <v-card-title>
          <div class="card-header d-flex">
            <div class="card-title-holder d-flex">
              <v-btn icon 
                color="#2987F8"
                @click="back"
              >
                <v-icon dense>
                  mdi-chevron-left
                </v-icon>
              </v-btn>
            <div class="card-title" v-if="datasetTab===0">
              Select Dataset
            </div>
            <div class="card-title" v-else-if="datasetTab===1">
              Dataset Detail
            </div>
            </div>
          <v-text-field
              ref="searchInput"
              v-model="search"
              hide-details
              label="Search Datasets"
              no-filter
              dense
              solo
              outlined
              hide-no-data
              prepend-inner-icon="mdi-magnify"
              class="hidden-sm-and-down custom-class"
              style="max-width: 300px"
            >
            
            </v-text-field>
          <v-icon dense
            @click.prevent="cancelDatasetSelect"
          >mdi-close-circle-outline
          </v-icon>
        </div>
        </v-card-title>
        <v-divider/>
        
      <v-card-text>
        <v-tabs-items
          v-model="datasetTab"
        >
          <v-tab-item>
          <div class="data-table">
            <v-data-table
              fixed-header
              v-model="selected"
              :headers="headers"
              :items="data_set"
              :search="search"
              item-key="name"
              :loading="loading"
              :hide-default-footer="true"
              loading-text="Loading Datasets..." 
            >
              <template v-slot:top="{ pagination, options, updateOptions }">
                <v-data-footer 
                  :pagination="pagination" 
                  :options="options"
                  @update:options="updateOptions"
                />
              </template>
              <template #[`item.name`]="{ item }">
                <span class="name-href" @click="postChartForm(item.id)">{{
                  item.name
                }}</span>
              </template>
              <template #[`item.database`]="{ item }">
                <div class="database-chip ">
                <v-chip color="primary" outlined
                  text-color="grey_text"
                >
                  {{ item.database }}
                </v-chip>
                </div>
              </template>
              <template #[`item.action`]="{ item }">
                <div @click.stop>
                  <v-btn icon class="mr-2" @click="openDetail(item.id)">
                    <v-icon>mdi-magnify</v-icon>
                  </v-btn>
                </div>
              </template>
            </v-data-table>
          </div>
          </v-tab-item>
          <v-tab-item>
            <v-data-table
            :headers="detailHeaders"
            :items="data_detail"
            item-key="name"
            :search="search"
            class="elevation-1"
            :loading="loading"
            loading-text="Loading Dataset..."
            hide-default-footer
          >
            <template v-slot:top="{ pagination, options, updateOptions }">
                <v-data-footer 
                  :pagination="pagination"
                  :items-per-page-options="[20, 5, 15, 100, -1]"
                  :options="options"
                  @update:options="updateOptions"
                  :items-per-page="20"
                  showFirstLastPage
                  
                />
              </template>
          </v-data-table>
          </v-tab-item>
          </v-tabs-items>
          </v-card-text>
          <v-divider/>
            <v-card-actions tag="vis-type-actions">
              <v-spacer/>
            <v-btn 
              outlined
              @click="cancelDatasetSelect"
              class="mr-5" depressed>
              Cancel
            </v-btn>
            </v-card-actions>

      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { 
    GET_DATASET_ALL, 
    POST_CHART_FORM, 
    GET_DATASET_DATA_BY_ID
    } from "@/store/actionType";

export default {
  name: "SelectDataSetDlg",
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    chartType: {
      type: String,
      default: null,
    },
    openOnClick: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      datasetTab: 0,
      itemsPerPage: 20,
      selected: [],
      data_detail: [],
      detailHeaders: [],
      headers: [
        {
          text: "Name",
          sortable: true,
          value: "name",
        },
        { text: "Type", value: "type", sortable: true },
        { text: "Database", 
          value: "database", 
          sortable: true,
        },
        { text: "Schema", value: "schema", sortable: true },
        { text: "Action", value: "action" },
      ],
      data_set: [],
      // menuItems: [
      //   {
      //     text: "From Database",
      //     action: "from_db",
      //   },
      //   {
      //     text: "From File",
      //     action: "from_file",
      //   },
      //   {
      //     text: "Custom SQL",
      //     action: "custom_sql",
      //   },
      //   {
      //     text: "New Database",
      //     action: "new_db",
      //   },
      // ],
      detail: false,
      search: "",
      //show New database dialog
      showNewDB: false,
      //show New from database dialog
      showNewFromDB: false,
      //show New from file dialog
      showNewFromFile: false,
      loading: false,
    };
  },
  mounted() {
    this.getDatasets();
  },
  methods: {
    resetDetailTab () {
      this.datasetTab=0;
      this.data_detail=[];
      this.detailHeaders=[];
    },
    initializeDetail(result) {
      this.detailHeaders = result.colnames.map((k) => (
        {'value': k, 'text': k, width: "100px"}
      ))
      this.data_detail = result.data
    },
    getDatasetDetail(id, q) {
      const param = {
        id: id,
        q: q
      };

      this.$store.dispatch(GET_DATASET_DATA_BY_ID,param)
        .then((res) => {
        this.initializeDetail(
          res.data.message.result.query_result
        );
        this.loading=false;
      })
      .catch((error)=>{
        console.log(error)
        this.loading=false;
      })
    },
    cancelDatasetSelect() {
      this.resetDetailTab();
      this.$emit('cancelDatasetSelect')
    },
    getDatasets() {
    /**
     * get datasets
     */
      this.loading=true;
      const columns = [
        "id",
        "table_name",
        "datasource_type",
        "database.database_name",
        "schema",
      ];
      
      const q= {
        "columns": columns,
      };

      this.$store.dispatch(GET_DATASET_ALL, q)
      .then((res) => {
        this.initialize(res.data.result);
        this.loading=false;
      })
      .catch((error)=>{
        console.log(error)
        this.loading=false;
      });
    },
    initialize(values) {
    /**
     * initialize database values
     * @param values
     */
      this.data_set = [];
      for (let item of values) {
        this.data_set.push({
          selected: [],
          name: item.table_name,
          type: item.datasource_type,
          database: item.database.database_name,
          schema: item.schema,
          id: item.id,
        });
      }
    },
    openDetail (id) {
      this.loading=true;
      this.search=null;
      const q = {"columns": ["query_result"]};
      this.getDatasetDetail(id, q);
      this.datasetTab=1;
    },
    back() { // TODO
      if (this.datasetTab===1) {
        this.resetDetailTab()
      } else {
        this.$emit('datasetSelectBack')
      }
    },
    postChartForm(id) {
      if ( this.openOnClick ) {
        console.log('open sesame!')
        this.loading = true;
        const params = {
          datasource: id,
          viz_type: this.chartType
        };
        this.$store.dispatch(POST_CHART_FORM, params).then((res) => {
          this.loading = false;
          setTimeout(() => {
            this.$store.commit("SELECTED_PIE_METRIC", null);
            this.$store.commit("SELECTED_HEAT_METRIC", null);
            this.$router.push(`/visualizations/null/${res.data.key}`);
          }, 1000);
        });
      } else {
        this.$emit('selectDataset', id)
      }
    },
  },
  computed: {
    dataset_modal: {
      get: function () {
        return this.value;
      },
      set: function (newValue) {
        this.value = newValue;
      },
    },
  },
};
</script>

<style lang="scss" scoped>

.v-data-table-header {
  background-color: #262626;
}

.card-title {
  width: 170px;
  margin-top: 4px;
}

.card-header {
  width: 100%;
  justify-content: space-between;
  // margin-top: 10px;
}
#virtual-scroll-table {
  max-height: 300px;
  overflow: auto;
}
.outline {
  border-bottom: 1px solid #afafafff;
  padding: 5px;
}
.close {
  margin-right: 15px;
  cursor: pointer;
  font-size: 20px;
}
.custom-dialog {
  position: absolute;
  /*right: 10%;*/
  top: 10%;
}
.name-href {
  color: #3b96f1;
  text-decoration: underline;
  cursor: pointer;
}
.database-chip {
  // background: #3083d5;
//   color: #ffffff;
  font-size: 11px;
//   padding: 3px;
//   // width: 60px;
  // width:"100%";
//   text-align: center;
//   border-radius: 3px;
}
.dropdown-class {
  display: flex;
  justify-content: flex-end;
}
</style>