<template>
  <div class="vis-mode">
    <div class="vis-mode-header d-flex">
      <div class="vis-mode-header-text"> Viz Options </div>
      <v-icon class="ml-auto" color="#B9B9B9">mdi-menu</v-icon>
    </div>
    <div class="vis-mode-type">
      <VizModeHeader 
        :form_data="form_data" 
        @clickVisTypeModal="clickVisTypeModal"
      />
      <SelectVisTypeDlg
        v-model="vis_type_modal"
        parentPage="charts"
        @closeVisTypeSelect="closeVisTypeSelect"
      />
      <!-- <div class="delete-me">
      <v-btn color="primary"
        @click="showForm"
      />
    </div> -->
    <!-- {{ mode_active_cols }} -->
      <VizModeFilter
        :filters="filters"
        @dragFilterFinish="dragFilterFinish"
        @showFilterOption="showFilterOption"
        @deleteFilter="deleteFilter"
      />
      
      <div v-if="viz_type && viz_type == 'table'">
        <VizModeTable
          :columns="all_columns"
          :metrics="metrics"
          :validVisCols="validVisCols"
          :mode_active_cols="mode_active_cols"
          @dragColFinish="dragColFinish"
          @deleteCol="deleteCol"
          @dragMetricFinish="dragMetricFinish"
        />
      </div>
      <div  v-if="['dist_bar', 'horiz_bar'].includes(viz_type)">
        <VizModeBar
          :bar_metrics="bar_metrics"
          :bar_series="bar_series"
          :bar_categories="bar_categories"
          :validVisCols="validVisCols"
          :mode_active_cols="mode_active_cols"
          :form_data="mode_form_data"
          :showCalc="showCalc"
          :aggregations="aggregations"
          @dragPillFinishList="dragPillFinishList"
          @dragComputedListFinish="dragComputedListFinish"
          @deletePillList="deletePillList"
          @saveCalcList="saveCalcList"
          @disableCalcList="disableCalcList"
          @showCalcCard="showCalcCard"
        />
      </div>
      <div  v-if="viz_type === 'box_plot'">
        <VizModeBoxPlot
          :bar_metrics="bar_metrics"
          :bar_series="bar_series"
          :bar_categories="bar_categories"
          :validVisCols="validVisCols"
          :mode_active_cols="mode_active_cols"
          :form_data="mode_form_data"
          :showCalc="showCalc"
          :aggregations="aggregations"
          @dragPillFinishList="dragPillFinishList"
          @dragComputedListFinish="dragComputedListFinish"
          @deletePillList="deletePillList"
          @saveCalcList="saveCalcList"
          @disableCalcList="disableCalcList"
          @showCalcCard="showCalcCard"
        />
      </div>
      <!-- bubble chart -->
      <div class="bubble" v-else-if="viz_type == 'bubble'">
        <VizModeBubble
          :entity="entity"
          :xAxis="xAxis"
          :yAxis="yAxis"
          :size="size"
          :series="series"
          :showCalc="showCalc"
          :form_data="mode_form_data"
          :enableColor="enableColor"
          :aggregations="aggregations"
          :validVisCols="validVisCols"
          :mode_active_cols="mode_active_cols"
          @showColor="showColor"
          @disablePicker="disablePicker"
          @dragComputedAxisFinish="dragComputedAxisFinish"
          @dragPillFinish="dragPillFinish"
          @deletePill="deletePill"
          @saveCalc="saveCalc"
          @disableCalc="disableCalc"
          @showCalcCard="showCalcCard"
          @changeBubbleSize="changeBubbleSize"
          @showForm="showForm"
        />
      </div>
      
      <!-- scatter plot -->
      <div class="bubble" v-else-if="viz_type == 'scatter'">
        <VizModeScatter
          :entity="entity"
          :xAxis="xAxis"
          :yAxis="yAxis"
          :series="series"
          :showCalc="showCalc"
          :form_data="mode_form_data"
          :enableColor="enableColor"
          :aggregations="aggregations"
          :validVisCols="validVisCols"
          :mode_active_cols="mode_active_cols"
          @showColor="showColor"
          @disablePicker="disablePicker"
          @dragComputedAxisFinish="dragComputedAxisFinish"
          @dragPillFinish="dragPillFinish"
          @deletePill="deletePill"
          @saveCalc="saveCalc"
          @disableCalc="disableCalc"
          @showCalcCard="showCalcCard"
        />
      </div>
      <!-- pie chart -->
      <div class="pie" v-else-if="viz_type == 'pie'">
        <VizModePie
          :pieCategories="all_columns"
          :metric="sel_pie_metric"
          :aggregations="aggregations"
          :validVisCols="validVisCols"
          :mode_active_cols="mode_active_cols"
          :showCalc="showCalc"
          :form_data="mode_form_data"
          @saveCalc="saveCalc"
          @dragPillFinishList="dragPillFinishList"
          @deletePillList="deletePillList"
          @saveCalcList="saveCalcList"
          @disableCalcList="disableCalcList"
          @showCalcCard="showCalcCard"
          @dragComputedAxisFinish="dragComputedAxisFinish"
          @deletePill="deletePill"
        />
      </div>
      <!-- heatmap -->
      <div class="heatmap" v-else-if="viz_type == 'heatmap'">
        <VizModeHeatmap
          :xAxis="all_columns_x"
          :yAxis="all_columns_y"
          :metric="metric"
          :validVisCols="validVisCols"
          :mode_active_cols="mode_active_cols"
          :form_data="mode_form_data"
          :showCalc="showCalc"
          :aggregations="aggregations"
          @dragPillFinish="dragPillFinish"
          @deletePill="deletePill"
          @dragComputedAxisFinish="dragComputedAxisFinish"
          @saveCalc="saveCalc"
          @disableCalc="disableCalc"
          @showCalcCard="showCalcCard"
        />
      </div>
      <!-- Error snackbar -->
      <v-snackbar
        v-model="snackbar"
        color="#ffbb33"
      > {{ validVisCols }}
        <template v-slot:action="{ attrs }">
          <v-btn
            color="#3366FF"
            v-bind="attrs"
            @click="snackbar = false"
          >
            Close
          </v-btn>
        </template>
      </v-snackbar>
      <!-- Mode Actions -->
      <VizModeAction
        :validVisCols="validVisCols"
        @applyQuery="applyQuery" @saveChart="saveChart" 
      />
    </div>
    <!-- filter calc -->
    <div class="vis-mode-filter"
      v-if="showFilter && filters.length > 0"
      v-click-outside="disableFilter"
      :style="filterStyle"
    >
      <div class="vis-mode-filter-header">Filter</div>
      <div class="vis-mode-filter-container">
        <v-tabs color="white">
          <v-tab>Simple</v-tab>
          <!-- <v-tab>SQL</v-tab> -->
          <v-tab-item>
            <p class="mt-2 mb-2">Column</p>
            <v-text-field
              outlined
              dense
              height="40px"
              background-color="#1E1E1E"
              :value="filters[selectedFt].subject"
              hide-details
              readonly
            />
            <template>
              <p class="mt-2 mb-2">Operator</p>
              <v-combobox
                solo
                :items="operators"
                :return-object="true"
                outlined
                hide-details
                height="40px"
                v-model="filters[selectedFt].operator"
                :rules="[rules.isInOperators]"
              />
              <p class="mt-2 mb-2">Filter Value</p>
              <v-text-field
                height="40px"
                hide-details
                outlined
                background-color="#1E1E1E"
                v-model="filters[selectedFt].comparator"
              />
            </template>
            <div class="mt-3">
              <v-btn
                color="white"
                width="96px"
                height="36px"
                outlined
                @click="closeSimpleFilter"
              >
                Cancel
              </v-btn>
              <v-btn
                :disabled="!validFilter"
                class="ml-5"
                color="primary"
                @click="saveSimpleFilter"
                height="36px"
                width="96px"
              >
                Save
              </v-btn>
            </div>
          </v-tab-item>
          <!-- <v-tab-item>
            <div class="w-50">
              <div class="d-flex mb-2 mt-6">
                <p class="mb-0">Operator</p>
                <v-icon class="ml-2 mt-auto mb-auto" small
                  >mdi-alert-circle-outline</v-icon
                >
              </div>
              <v-combobox
                solo
                :items="sqlOperators"
                :return-object="true"
                outlined
                :item-text="(obj) => obj['title']"
                dense
                height="40px"
                hide-details
              />
            </div>
            <p class="mt-5 mb-2">Expression</p>
            <div class="filter-editor">
              <codemirror
                :value="filters[this.selectedFt].sqlExpression"
                ref="sqlEditor"
                @ready="onCmReady"
                :options="cmOptions"
              />
            </div>

            <div class="mt-5">
              <v-btn color="white" width="96px" height="36px" outlined>
                Cancel
              </v-btn>
              <v-btn
                class="ml-5"
                color="primary"
                @click="dialog = false"
                height="36px"
                width="96px"
              >
                Save
              </v-btn>
            </div>
          </v-tab-item> -->
        </v-tabs>
      </div>
    </div>
  </div>
</template>

<script>
// import language js
import "codemirror/mode/sql/sql.js";
// theme css
import "codemirror/theme/mbo.css";

import VizModeHeader from "./vizMode/VizModeHeader.vue";
import VizModeFilter from "./vizMode/VizModeFilter.vue";
import VizModeAction from "./vizMode/VizModeAction.vue";
import VizModeTable from "./vizMode/VizModeTable.vue";
import VizModeBar from "./vizMode/VizModeBar.vue";
import VizModeBubble from "./vizMode/VizModeBubble.vue";
import VizModePie from "./vizMode/VizModePie.vue";
import VizModeHeatmap from "./vizMode/VizModeHeatmap.vue";
import VizModeScatter from "./vizMode/VizModeScatter.vue";
import VizModeBoxPlot from "./vizMode/VizModeBoxPlot.vue"
import SelectVisTypeDlg from "@/views/charts/modals/SelectVisTypeDlg";

import {
  GET_CHART_FORM,
} from "@/store/actionType";

// import {} from "@/utils/chartUtils"

export default {
  name: "VisMode",
  components: {
    VizModeHeader,
    VizModeFilter,
    VizModeAction,
    VizModeTable,
    VizModeBar,
    VizModeBubble,
    VizModeScatter,
    VizModePie,
    VizModeHeatmap,
    VizModeBoxPlot,
    SelectVisTypeDlg,
  },

  props: {
    //visualization data
    form_data: {
      type: Object,
      default() {
        return null;
      },
    },
    active_cols: {
      type: Object,
      default() {
        return {};
      },
    },
    adhoc_filters: {
      type: Array,
      default() {
        return [];
      },
    },
    //active metrics
    active_metrics: {
      type: Array,
      default() {
        return [];
      },
    },
    //visualization type
    viz_type: {
      type: String,
      default() {
        return "";
      },
    },
    vizObject: {
      type: Object,
      default() {
        return null;
      },
    },
  },
  computed: {
    validFilter() {
      return (
        this.operators.includes(
          this.filters[this.selectedFt].operator
        )
        && (
          !!this.filters[this.selectedFt].comparator
          && this.filters[this.selectedFt].comparator !== ''
        )
      )
    },
    datasetId () {
      return this.form_data.datasource.split('__')[0]
    },
    validVisCols() {
      /**
       * Indicate which fields are missing based on viz type
       * @return {Object}
       */
      
      // TODO: refactor to class validation method
      // Bubble
      let filled_keys = {}
      // add viz objects here
      
      const vizObjectTypeArray = [
        'scatter', 'bubble', 'dist_bar', 'horiz_bar',
        'box_plot', 'heatmap', 'pie'
      ];

      // console.log(' calc validVisCols///////////', this.vizObject.requiredAxes)
      if ( vizObjectTypeArray.includes(this.form_data.viz_type)) {
        const required_keys = this.vizObject.requiredAxes;
        for (const i in this.vizObject.requiredAxes) {
          const axis=required_keys[i]
          let val=false;
          if (!this[axis]) {
            val = false;
          } else if ( typeof this[axis] === "object" && Object.keys(this[axis]).length > 0) {
            val = true;
          } else if ( typeof this[axis] === "string" && this[axis]) {
            val = true;
          }
          filled_keys = {...filled_keys,
            // [axis]: (this[axis] ? true : false)
            [axis]: val
          };
        }
        // console.log('validVisCols///////////', filled_keys)
      } else if (this.form_data.viz_type === 'table') {
        // console.log('size',this.xAxis)
        let required_keys = ['all_columns']
        for (let i = 0; i < required_keys.length; i++) {
          let axis=required_keys[i]
          filled_keys = {...filled_keys,
            [axis]: (this[axis].length > 0 ? true : false)
          };
          // console.log('computed table', filled_keys)
        }
      }
      return filled_keys
    },

    table_viz_columns() {
      // console.log('table viz_columns', this.mode_active_cols)
      return this.mode_active_cols.all_columns.map( (col) => 
          col.column_name
        )
    },

    viz_columns_list() {
      // console.log('viz_columns_list orij', this.mode_active_cols)
      const flat = Object.values(this.mode_active_cols);
      // console.log('viz_columns_list obj', flat)

      let array_cols = []
      
      flat.forEach(arr =>{
        if (typeof arr === 'object') {
          array_cols.push(arr.column_name)
        } else {
          arr.forEach( col => {
            array_cols.push(col.column_name)
          })
        }
      })
      return array_cols
      },

    viz_columns() {
      return Object.keys(this.mode_active_cols)
        .map( (key) => 
          this.mode_active_cols[key].column_name
        )
    },

    all_columns() {
      return this.mode_form_data.all_columns;
    },

    columns: {
      get() {
        return this.mode_active_cols;
      },
    },

    filters: {
      get() {
        return this.adhoc_filters;
      },
    },

    metrics: {
      get() {
        return this.active_metrics;
      },
    },

    sel_col: {
      get() {
        // console.log("get sel_col",this.$store.state.chart.sel_col)
        return this.$store.state.chart.sel_col;
      },
    },

    sel_metric: {
      get() {
        return this.$store.state.chart.sel_metric;
      },
    },

    filterStyle: {
      get() {
        return `top: ${this.ftTop}px`;
      },
    },

    // calcStyle: {
    //   get() {
    //     return `top: ${this.calcTop}px`;
    //   },
    // },

    all_columns_x () {
      return this.mode_form_data.all_columns_x;
    },
    all_columns_y() {
      return this.mode_form_data.all_columns_y;
    },
    metric() {
      return this.mode_form_data.metric;
    },

    // can i combine all these?
    bar_metrics() {
      // console.log('/// bar metrices', this.mode_form_data.metrics)
      return this.mode_form_data.metrics;
    },
    bar_categories() {
      // console.log('/// bar metrices', this.mode_form_data.columns)
      return this.mode_form_data.columns;
    },
    bar_series() {
      // console.log('/// bar metrices', this.mode_form_data.columns)
      return this.mode_form_data.groupby;
    },

    sel_pie_metric() {
      return this.mode_form_data.metric;
      // get() {
        // console.log('computed metrics', this.active_metrics[0])
        // return this.active_metrics[0];
        // return this.$store.state.chart.sel_pie_metric;
      // },
      // set(val) {
      //   console.log('setting active metric', val)
      //   this.active_metrics=[ val ]
      //   // this.$store.commit("SELECTED_PIE_METRIC", val);
      // },
    },

    sel_heat_metric: {
      get() {
        return this.$store.state.chart.sel_heat_metric;
      },
      set(val) {
        this.$store.commit("SELECTED_HEAT_METRIC", val);
      },
    },

    xAxis() {
      return this.mode_form_data.x
    },

    yAxis() {
      return this.mode_form_data.y
    },

    series() {
      return this.mode_form_data.series
    },

    entity() {
      return this.mode_form_data.entity;
    },

    size() {
      return this.mode_form_data.size
    },


  },

  data() {
    return {
      rules: {
        isInOperators: value => this.operators.includes(value) || 'Select from Operators'
      },
      mode_form_data: this.form_data,
      // mode_metric: this.active_metrics[0],
      vis_type_modal: false,
      snackbar: false,
      //codemirror options
      cmOptions: {
        tabSize: 2,
        styleActiveLine: true,
        lineNumbers: true,
        line: true,
        mode: "text/x-mysql",
        theme: "mbo",
      },
      showFilter: false,
      showCalc: {
        x:false,
        y:false,
        size:false,
        metrics: false,
        metric: false,
      },
      // showCalc_x: false,
      // showCalc_y: false,
      // showCalc_size: false,
      // showPieMetricCalc: false,
      // showHeatMetricCalc: false,
      // showBarMetricCalc: false,
      // barMetricIndex: null,
      operators: [
        "==",
        "!==",
        ">",
        "<",
        "<=",
        ">=",
        "IN",
        "NOT IN",
        "LIKE",
        "ILIKE",
        "IS NOT NULL",
        "IS NULL",
      ],
      aggregations: {
        numeric: ["SUM", "AVG", "MAX", "MIN", "COUNT", "COUNT_DISTINCT"],
        categorical: ["COUNT", "COUNT_DISTINCT"],
      },
      sqlOperators: [
        {
          title: "Where",
        },
        {
          title: "Having",
        },
      ],
      //filter top
      ftTop: null,
      //calc top
      // calcTop: null,
      //calc bottom
        // control bottom of calc window
      // calcBottom: null,
      //selected calc
      // selectedCal_x: null,
      //selected calc y
      // selectedCal_y: null,
      //selected filter
      selectedFt: null,
      //enable & disable color picker
      enableColor: false,
      //operator
      operator: null,
      //comparator
      comparator: null,
      //cause
      clause: null,
      //sql expression
      sqlExpression: null,
      //aggregation
      // aggregation_x: null,
      //aggregation
      // aggregation_y: null,
      //selected axis
      selected_Axis: null,
      //pie metric
      // pieMetric: null,
      // mode_active_cols: { ... this.active_cols }
      mode_active_cols: this.active_cols,
    };
  },

  // beforeUpdate() {
  // },
  // created() {
  // },
  // update() {
  // },
  // updated() {
  // },
  // watch: {
  //   mode_active_cols() {
  //     this.mode_active_cols  = Object.assign(
  //       {},
  //       this.active_cols
  //       )
        // { newProperty: 'Vue will definitely know about me now!' }
    //   handler() {
    //   this.mode_active_cols =  { ... this.active_cols }
    //   },
    //   immediate: true
  //   }
  // },
  methods: {
    showForm() {
      let dim = 'size'
      // console.log('Index Form', this.form_data.adhoc_filters)
      // console.log('VisMode Form', this.mode_form_data.adhoc_filters)
      // console.log('filgers', this.filters)
      // this.$emit('showForm')
    },

    postChartForm(id, chartType) {
      // console.log('post form')
      const params = {
        datasource: id,
        viz_type: chartType
      };
      let chart_form = this.$store.dispatch(GET_CHART_FORM, params)
        .then((res) => {
          return res.data.result
        })
        .catch((error) =>{
          console.error(error)
        });
      return chart_form;
    },

    clickVisTypeModal() {
      this.vis_type_modal = true;
    },

    clearFormData() {
      // console.log('clear form data')
    },

    async closeVisTypeSelect(viz_type) {
      // console.log('change vis type', viz_type)
      this.vis_type_modal = false;
      let chart_form = await this.postChartForm(this.datasetId, viz_type)
      this.$emit('changeVisType', chart_form)
    },

    resetSizeDefault () {
      delete this.form_data.extra_form_data.bubble_size
    },

    changeBubbleSize(_val) {
      // console.log('change parent:', _val);
      this.$emit('changeBubbleReact', _val);
    },


    addAllCols(colObject) {
    // Make the "add all columns menu reactive from parent"
      const colArray = colObject.all_columns.
        map( col => col.column_name)
      this.$set(
        this.mode_form_data, 'all_columns',
        colArray
      )
      this.mode_active_cols = Object.assign(
        {},
        this.mode_active_cols,
        colObject
      );
    },

    dragColFinish(event) { // this is for table only
    /**
     * drag finish event
     * @param event
     */
      // console.log('dragColFinish', this.sel_col)
      const dimension = 'all_columns'
      if ( !this.table_viz_columns.includes(this.sel_col.column_name) ) {
        // console.log('add', this.sel_col.column_name, this.mode_active_cols.all_columns)

        /// Add new Column to form.all_columns
        const all_cols = this.mode_form_data.all_columns;
        all_cols.push(this.sel_col.column_name)

        // Replace Active Columns with new Pill
        ////////////
        const newCol = {
          column_name: this.sel_col.column_name,
          type_generic: this.sel_col.type_generic,
          applied: false,
          }
        this.mode_active_cols.all_columns.push(newCol)

        // console.log('new mode active cols', this.mode_active_cols)
        // console.log('mode form data', this.mode_form_data.all_columns)
      } else {
        // console.log('do nothing')
      }
    },

    /**
     * drag filter finish event
     * @param event
     */
    dragFilterFinish(event) {
      let find = this.filters.find(
        (item) => item.subject === this.sel_col.column_name
      );
      if (!find) {
        let filter = {
          subject: this.sel_col.column_name,
          operator: null,
          comparator: null,
          expressionType: "SIMPLE",
          clause: this.clause ? this.clause : "WHERE",
        };
        this.filters.unshift({
          ...filter,
          applied: false,
        });
        this.selectedFt = 0;
        this.ftTop = 265;
        this.showFilter = true;
      }
    },

    /**
     * drag x Axis finish event
     * @param event
     */
    dragComputedAxisFinish(axis) {
      // Edit Active Cols on form
      // console.log('dragComputedPill', this.viz_columns, axis)
      // find the active columns on the form, replace if not used
      if ( !this.viz_columns.includes(this.sel_col.column_name) ) {
        // console.log('not int thie list')
        let newcol = {
          column_name: this.sel_col.column_name,
          applied: false,
        }
        this.$set(this.mode_active_cols, axis, newcol)
      
        ///////////////////////////
        // Adjust form for new Colum
        // console.log('drag axis soft', axis)
        // reset previous pill metadata
        if (typeof this[axis] !== "undefined" && this[axis] !== null) {
          // console.log('cond 1')
            this[axis].expression=null;
        }
        if (typeof this.mode_form_data[axis] !== "undefined"
          && this.mode_form_data[axis] !== null
        ) {
          // console.log('cond 2')
          this.mode_form_data[axis].aggregate = null
        }
        let axis_col = this.sel_col;
        // console.log(`finish ${axis}`, axis_col)
        let newObj = {column: axis_col}
        this.$set(this.mode_form_data, axis, newObj)
        this.showCalc[axis] = true;

      } //else {
        // console.log('do nothing computed')
      // }
    },

    dragComputedListFinish(axis) {
      // Edit Active Cols on form
      // console.log('dragComputedPill', this.viz_columns_list, axis)
      // find the active columns on the form, replace if not used
      if ( !this.viz_columns_list.includes(this.sel_col.column_name) ) {
        
        let newCol = {
          column_name: this.sel_col.column_name,
          label: this.sel_col.column_name,
          type_generic: this.sel_col.type_generic,
          applied: false,
        }
        // console.log('not int thie list', newCol)
        // console.log('thie list', this.mode_active_cols[axis])
        //   this.$set(this.mode_active_cols, axis, newcol)
        this.mode_active_cols[axis].push(newCol)
      
        let newObj = {
          column: this.sel_col,
          aggregate: null,
          label: this.sel_col.column_name,
        }
        // this.$set(this.mode_form_data, axis, newObj)
        this.mode_form_data[axis].push(newObj)
        this.showCalc[axis] = true;
        // console.log('drag form', this.mode_form_data[axis])

      } //else {
      //   console.log('do nothing computed')
      // }
    },


    /**
     * drag y Axis finish event
     * @param event
     */
    dragYAxisFinish(event) {
      this.yAxis = this.sel_col;
      this.yAxis_Label = this.sel_col.column_name;
      this.form_data.y = this.form_data.y ? this.form_data.y : {};
      this.form_data.y.column = this.yAxis;
      // this.calcTop = 510;
      this.calcTop = 409;
      this.showCalc_y = true;
    },

    dragSizeFinish() {// (event)
      /**
       * drag size Axis finish event
       */
      // console.log('drag size', this.size)
      // reset previous pill metadata
      if (typeof this.size !== "undefined" && this.size !== null) {
        // console.log('cond 1')
          this.size.expression=null;
      }
      if (typeof this.form_data.size !== "undefined"
        && this.form_data.size !== null
      ) {
        // console.log('cond 2')
        this.form_data.size.aggregate = null
      }
      // set new values
      let size_col = this.sel_col;
      // console.log('finish x', size_col)
      let newObj = {column: size_col}
      this.$set(this.mode_form_data, 'size', newObj)

      // TODO: this is a hack to reset the calculation upon reset of 
        // the size column after deletePill runs. this.sel_col stores
        // the expression of the prevous pill
      // this.size.expression=null;
      // this.size_Label = this.sel_col.column_name;
      // this.mode_form_data.size = this.form_data.size ? this.form_data.size : {};
      // this.form_data.size.column = this.size;
      // this.calcTop = 414;
      // originially in wrong spot
      // this.calcTop = 626;
      this.showCalc.size = true;
      // console.log("debug:", this.mode_form_data.size.aggregate)
    },
    
    /**
     * drag x axis for heatmap event
     */
    dragXAxisHeatFinish(event) {
      this.xAxis = this.sel_col.column_name;
    },

    /**
     * drag y axis for heatmap event
     */
    dragYAxisHeatFinish(event) {
      this.yAxis = this.sel_col.column_name;
    },

    getVizColumns() {
      // console.log('viz_columns', this.mode_active_cols)
      // let cols=[];
      // const obj = this.mode_active_cols;
      return Object.keys(this.mode_active_cols)
        .map( (key) => 
          this.mode_active_cols[key].column_name
        )
      // return this.mode_active_cols
    },

    dragPillFinishList(dimension) {
      // console.log('dragPill', dimension, this.mode_active_cols[dimension])
      // console.log('dragPill', dimension, this.getVizColumns())
      // console.log('dragged', this.sel_col.column_name)
      // console.log('checklist', this.viz_columns_list)
      if ( !this.viz_columns_list.includes(this.sel_col.column_name) ) {
        // Apply change to mode form
        const newDim = {}
        newDim[dimension] = this.sel_col.column_name
        // console.log('dragged', this.mode_form_data[dimension])
        this.mode_form_data[dimension].push(this.sel_col.column_name)
        // Replace Active Columns with new Pill
        ////////////
        const newCol = {
          column_name: this.sel_col.column_name,
          type_generic: this.sel_col.type_generic,
          applied: false,
          }
        this.mode_active_cols[dimension].push(newCol)
        // this.$set(this.mode_active_cols, dimension, newArray)
        // this.mode_active_cols[dimension].push(newCol)
        // console.log('add bew active col', newArray)
        // console.log('new active cols', this.viz_columns_list, this.mode_active_cols)
      } //else {
        // TODO: testing
        // console.log('drag nothing')
      // }
      // console.log(`mode_form_data ${dimension} post`, this.mode_form_data)
    },

    dragPillFinish(dimension) {
      // console.log('dragPill', dimension)
      // console.log('dragPill', dimension, this.getVizColumns())
      // console.log('dragged', this.sel_col.column_name)
      
      if ( !this.viz_columns.includes(this.sel_col.column_name) ) {
        // Apply change to mode form
        const newDim = {}
        newDim[dimension] = this.sel_col.column_name
        // console.log('dragged', this.mode_form_data[dimension])
        this.$set(this.mode_form_data, dimension, this.sel_col.column_name)
        // Replace Active Columns with new Pill
        ////////////
        const newCol = {
          column_name: this.sel_col.column_name,
          type_generic: this.sel_col.type_generic,
          applied: false,
          }
        this.$set(this.mode_active_cols, dimension, newCol)
        // console.log('new active cols', this.viz_columns, this.mode_active_cols)
      } else {
        // TODO: testing
        console.log('drag nothing')
      }
      // console.log(`mode_form_data ${dimension} post`, this.mode_form_data[dimension])
    },

    /**
     * drag heatmap metric event
     */
    dragHeatMetric(event) {
      this.sel_heat_metric = {
        col: this.sel_col,
        aggregation: null,
        label: this.sel_col.column_name,
      };
      this.calcTop = 510;
      this.showHeatMetricCalc = true;
    },
    /**
     * show heat metric
     */
    showHeatMetric() {
      this.showHeatMetricCalc = true;
    },

    /**
     * save simple heatmap metric
     */
    saveSimpleHeatMetricCalc() {
      if (this.sel_heat_metric.aggregation) {
        this.sel_heat_metric.label = `(${this.sel_heat_metric.aggregation})${this.sel_heat_metric.col.column_name}`;
        this.sel_heat_metric.expressionType = "SIMPLE";
      }
      this.showHeatMetricCalc = false;
    },

    /**
     * drag metric finish event
     * @param event
     */
    dragMetricFinish(event) {
      let find = this.metrics.find(
        (item) => item === this.sel_metric.expression
      );
      if (!find) {
        this.metrics.push(this.sel_metric.expression);
      }
    },

    getCalcColLabel(axis) {
      // console.log('lable?', this.mode_form_data[axis])
      let col = this.mode_form_data[axis]
      let label = `(${col.aggregate})${col.column.column_name}`
      // console.log('lable?', axis, label)
      return label
    },
    
    formatVisData() {
    // Format vis data for save/apply

      if (this.filters) {
        this.mode_form_data.adhoc_filters = this.filters;
      }
      const viz_type = this.mode_form_data.viz_type
      const scatter_types = ['bubble','scatter']
      const dist_types = ["dist_bar","horiz_bar","box_plot"]

      if ( scatter_types.includes(viz_type) ) {
        this.mode_form_data.x = {
          ...this.mode_form_data.x,
          expressionType: "SIMPLE",
          hasCustomLabel: false,
          isNew: false,
          label: this.getCalcColLabel('x'),
          sqlExpression: null,
        };
        this.mode_form_data.y = {
          ...this.mode_form_data.y,
          expressionType: "SIMPLE",
          hasCustomLabel: false,
          isNew: false,
          label: this.getCalcColLabel('y'),
          sqlExpression: null,
        };
        // add size for bubble
        if (viz_type === 'bubble') {
          this.mode_form_data.size = {
            ...this.mode_form_data.size,
            expressionType: "SIMPLE",
            hasCustomLabel: false,
            isNew: false,
            label: this.getCalcColLabel('size'),
            sqlExpression: null,
            active: true
          };
        }
        // this.mode_active_cols = Object.assign({}, this.vizObject
        //     .parseColumns(this.mode_form_data)
        //   )

      } else if (this.form_data.viz_type === "table") {
        // for (let col of this.all_columns) {
          // this.form_data.all_columns.push(col.column_name);
        // }
        // this.mode_active_cols = Object.assign({}, this.vizObject
        //     .parseColumns(this.mode_form_data)
        //   )
      } else if (this.form_data.viz_type === "pie") {
        // console.log('Pie Chart apply', this.mode_form_data)
        this.mode_form_data.metric = {
          ...this.mode_form_data.metric,
          expressionType: "SIMPLE",
          hasCustomLabel: false,
          isNew: false,
          label: this.getCalcColLabel('metric'),
          sqlExpression: null,
        };

      } else if ( this.form_data.viz_type === "heatmap" ) {
        // this.mode_active_cols = Object.assign({}, this.vizObject
        //     .parseColumns(this.mode_form_data)
        //   )

        this.mode_form_data.metric = {
          ...this.mode_form_data.metric,
          expressionType: "SIMPLE",
          hasCustomLabel: false,
          isNew: false,
          label: this.getCalcColLabel('metric'),
          sqlExpression: null,
        };


        // this.form_data.all_columns_x = this.xAxis;
        // this.form_data.all_columns_y = this.yAxis;
        // this.form_data.metric = {
        //   aggregate: this.sel_heat_metric.aggregation,
        //   column: this.sel_heat_metric.col,
        //   label: this.sel_heat_metric.label,
        //   hasCustomLabel: false,
        //   isNew: false,
        //   optionName: "",
        //   sqlExpression: null,
        //   expressionType: this.sel_heat_metric.expressionType,
        // };
      } else if ( dist_types.includes(this.form_data.viz_type) ) {
        this.bar_metrics.forEach(col=>{
          this.$set(col, 'hasCustomLabel', false)
          this.$set(col, 'isNew', false)
          this.$set(col, 'optionName', "")
          this.$set(col, 'expressionType', "SIMPLE")
          // console.log('metric', col)
        })
        
        // this.mode_active_cols = Object.assign({}, this.vizObject
        //     .parseColumns(this.mode_form_data)
        //   )
        // console.log('dist form post', this.mode_active_cols)
      } 

      // Apply axis pills
      this.mode_active_cols = Object.assign({}, this.vizObject
            .parseColumns(this.mode_form_data)
          )
      // Apply filter pills
      for (let i = 0; i < this.filters.length; i++) {
        this.filters[i].applied = true;
      }
    },

    applyQuery() {
    /**
     * apply query
     */
      this.formatVisData();
      console.log("VizMode apply")
      this.$emit("apply", this.mode_form_data);
    },
    
    /**
     * save chart
     */
    saveChart() {
      this.formatVisData();
      this.$emit("save", this.mode_form_data);
    },

    showCalcCard(axis) {
      this.showCalc[axis]=true;
    },

    saveCalcList(calc, index, axis) {
      // console.log('save me', calc, axis)
      // console.log('form', this.mode_form_data[axis])
      // console.log('activ form', this.mode_active_cols[axis][index])
      this.$set(this.mode_form_data[axis][index], 'aggregate', calc)
      // this.$set(this.mode_active_cols[axis][index], 'applied', true)
      this.showCalc[axis] = false;
    },

    disableCalcList(axis, index) {
      // console.log('disable me', axis, index)
      if (!this.mode_form_data[axis][index]?.aggregate) {
        this.deletePillList(axis, index)
      }
      this.showCalc[axis] = false;
    },

    saveCalc(calc, axis) {
    /**
     * save simple calculation
     * @param {string} calc Calculation for given axis
     * @param {string} axis Axis to apply calculation
     */
      // console.log('saveCalc', this.mode_form_data, calc)
      // this.mode_form_data[axis].aggregate = calc;
      this.$set(this.mode_form_data[axis], 'aggregate', calc)
      // console.log(`save calc ${axis}`, this.mode_form_data[axis].aggregate)

      // if (this.form_data.size.aggregate) {
      // this.size_Label = `(${this.form_data.size.aggregate})${this.form_data.size.column.column_name}`;
      // }
      this.showCalc[axis] = false;
      // console.log('saveCalc mode', this.mode_form_data[axis])
    },

    disableCalc(axis) {
    /**
     * save simple calculation
     * Disable Calculation and delete axis pill
     * @param {string} axis Axis to apply calculation
     */
      if (!this.mode_form_data[axis]?.aggregate) {
        this.deletePill(axis)
      }
      this.showCalc[axis] = false;
    },

    // saveSimpleCalcSize(calc) {
    //   // console.log("save calc", this.form_data.size.aggregate)
    //   this.form_data.size.aggregate = calc;
    //   console.log("save calc", this.form_data.size.aggregate)

    //   // if (this.form_data.size.aggregate) {
    //   // this.size_Label = `(${this.form_data.size.aggregate})${this.form_data.size.column.column_name}`;
    //   // }
    //   this.showCalc.size = false;
    // },

    
    /**
     * disable simple calculation Y
     */
    disableSimpleCalcY() {
      this.showCalc_y = false;
    },

    /**
     * save simple calculation Y
     */
    saveSimpleCalcY() {
      if (this.form_data.y.aggregate) {
        this.yAxis_Label = `(${this.form_data.y.aggregate})${this.form_data.y.column.column_name}`;
      }
      this.showCalc_y = false;
    },

    disableFilter(event) {
    /**
     * disable filter option
     */
      // This prevents closing the filter
        // !!!!(on outside click)!!!!
        // when clicking in the text fields
      let findOp = this.operators.find(
        (item) => item === event.target.innerText
      );
      let findSqOp = this.sqlOperators.find(
        (item) => item === event.target.innerText
      );
      // let findAgg = this.aggregations.find(
        // (item) => item === event.target.innerText
      // );
      if (!findOp && !findSqOp) {
        // this.showFilter = false;
        this.closeSimpleFilter()
      }
    },

    
    /**
     * code mirror ready event
     * @param cm
     */
    onCmReady(cm) {
      cm.setSize(null, "120px");
    },

    deleteFilter(index) {
    /**
     * delete filter
     * @param index
     */
      this.filters.splice(index, 1);
    },

    deletePillList(category, index) {
      // console.log('delete pill list', category, index)
      this.mode_form_data[category].splice(index,1)
      this.mode_active_cols[category].splice(index,1)
      // this.$delete(this.mode_active_cols, category);
    },

    deletePill(category) {
      // console.log('delet pill', category)
      switch (category) {
        case "size":
          this.deleteSize();
          // console.log('delete size', this.mode_active_cols.size)
          break;
        default:
          // console.log(`delete ${category}`)      
          this.$delete(this.mode_form_data, category);
          // this.$delete(this.mode_active_cols, category);
      
      }
      this.$delete(this.mode_active_cols, category);
      // console.log('this is happening ', this.mode_active_cols)
    },
    
    deleteSize() {
      // console.log('delete size')
      this.$delete(this.mode_form_data.extra_form_data, 'bubble_size');
      this.$delete(this.mode_form_data, 'size');
      this.showCalc.size=false
    },
    
    deleteCol(index) {
    /**
     * delete column
     * @param index
     */
      // console.log('delete col', this.mode_form_data.all_columns, index)
      this.mode_form_data.all_columns.splice(index, 1)
      this.mode_active_cols.all_columns.splice(index, 1)
      // this.columns.splice(index, 1);
      // this.$emit('alterSavedForm', this.columns.map(x => x.column_name))
      // console.log('after delete', this.mode_form_data.all_columns)
      // console.log('after delete', this.mode_active_cols.all_columns)
    },

    /**
     * show color picker
     */
    showColor() {
      // console.log('color:1', this.enableColor)
      // this.enableColor = !this.enableColor;
      // console.log('color2:', this.enableColor)
    },
    /**
     * disable picker
     */
    disablePicker() {
      this.enableColor = false;
    },
    /**
     * show filter option
     * @param event
     * @param index
     */
    showFilterOption(event, index) {
      this.selectedFt = index;
      this.ftTop = event.clientY - 80;
      this.showFilter = true;
    },
    /**
     * save simple filter
     */
    saveSimpleFilter() {
      // console.log('this.filters', this.filters)
      this.filters[this.selectedFt].applied=false;
      this.filters[this.selectedFt].clause = this.clause
        ? this.clause
        : "WHERE";
      this.showFilter = false;
    },
    
    closeSimpleFilter() {
    /**
     * close simple filter
     */
      // console.log('is vald filter/////////', this.validFilter)
      // console.log('is applied /////////', this.filters[this.selectedFt].applied)
      const closeConditional = (
        !this.filters[this.selectedFt].applied
        || !this.validFilter 
      )
      // console.log('shoud close/////////', closeConditional)
      if (closeConditional) {
        this.deleteFilter(this.selectedFt)
      }
      this.showFilter = false;
    },
  },
};
</script>

<style lang="scss" scoped>

  // .action {
  //   border-top: 0.5px solid #373737;
  //   padding: 11px 13px;
  //   // position: absolute;
  //   position: fixed;
  //   bottom: 0px;
  //   width: 100%;
  //   opacity: .9;
  //   background-color: red
  // }


.w-50 {
  width: 50%;
}

.blank-drag {
  border: 1px dashed #909090;
  border-radius: 12px;
  text-align: center;
  color: #909090;
  padding-top: 4px;
  padding-bottom: 4px;
  font-size: 12px;
}

.blank-text {
  font-size: 12px;
  text-align: center;
  color: #909090;
  margin-top: 9px;
}

.filter-btn {
  border-color: #1bbab9 !important;
}

.column-btn {
  border-color: #1bbab9 !important;
}

.column-numeric-btn {
  border-color: #1bbab9 !important;
}

.column-text-btn {
  border-color: #00a7ff !important;
}

.vis-mode {
  position: relative;
  border-right: 0.5px solid #373737;
  width: 240px;
  min-width: 240px;
  // height: 200px;
  // padding-left: 12px;

  &-header {
    // padding: 7px 14px;
    padding-left: 12px;
    padding-right: 8px;
    margin-top: 8px;
    margin-bottom: 4px;
    height: 24px;
    // border: 1px solid red;

    &-text {
      font-size: 16px;
      color: #ffffff;
    }
  }

  &-type {
    &-label {
      text-transform: uppercase;
      font-size: 12px;
      margin-left: 15px;
      letter-spacing: 0.48px;
      color: #939393;
    }
  }

  &-filter {
    position: absolute;
    left: 237px;
    z-index: 100;
    background: #303030;
    padding: 12px;
    width: 300px;
    height: 430px;

    &-header {
      font-size: 21px;
    }
  }

  &-filter::before {
    position: absolute;
    content: "";
    top: 24px;
    left: -16px;
    transform: rotate(270deg);
    width: 10px;
    height: 13px;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 15px solid #303030;
  }

  &-pie {
    &-calc {
      position: absolute;
      left: 237px;
      z-index: 100;
      background: #303030;
      padding: 12px;
      width: 300px;
      height: 330px;
    }

    &-calc::before {
      position: absolute;
      content: "";
      top: 170px;
      left: -16px;
      transform: rotate(270deg);
      width: 10px;
      height: 13px;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-bottom: 15px solid #303030;
    }
  }

  &-bar {
    &-calc {
      position: absolute;
      left: 237px;
      z-index: 100;
      background: #303030;
      padding: 12px;
      width: 300px;
      height: 330px;
    }

    &-calc::before {
      position: absolute;
      content: "";
      top: 130px;
      left: -16px;
      transform: rotate(270deg);
      width: 10px;
      height: 13px;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-bottom: 15px solid #303030;
    }
  }

  &-heat {
    &-calc {
      position: absolute;
      left: 237px;
      z-index: 100;
      background: #303030;
      padding: 12px;
      width: 300px;
      height: 330px;
    }

    &-calc::before {
      position: absolute;
      content: "";
      top: 130px;
      left: -16px;
      transform: rotate(270deg);
      width: 10px;
      height: 13px;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-bottom: 15px solid #303030;
    }
  }

  &-calc {
    position: absolute;
    left: 237px;
    z-index: 100;
    background: #303030;
    // background: red;
    padding: 12px;
    width: 300px;
    height: 330px;
  }

  &-calc::before {
    position: absolute;
    content: "";
    top: 24px;
    left: -16px;
    transform: rotate(270deg);
    width: 10px;
    height: 13px;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 15px solid green;
  }

  .table {
    padding: 12px 12px 16px 15px;
    border-bottom: 0.5px solid #373737;

    &-name {
      font-size: 16px;
      color: #939393;
    }
  }

  .pie {
    &-categories {
      &-container {
        height: 161px;
        padding-left: 15px;
        padding-right: 15px;
        padding-bottom: 8px;
        border-bottom: 0.5px solid #373737;
        overflow-y: auto;
        @include scrollbars(0.4rem, #717171, #00000080);
      }
    }
  }

  .search {
    padding-bottom: 12px;
    border-bottom: 0.5px solid #373737;

    &-header {
      padding: 11px 12px 12px 15px;
      color: #939393;
    }
  }

  .filter {
    &-header {
      padding: 20px 15px;

      &-title {
        font-size: 16px;
      }
    }

    &-container {
      height: 137px;
      padding-left: 15px;
      padding-right: 15px;
      padding-bottom: 8px;
      border-bottom: 0.5px solid #373737;
      overflow-y: auto;
      @include scrollbars(0.4rem, #717171, #00000080);
    }
  }

  .column {
    &-header {
      padding: 20px 15px;

      &-title {
        font-size: 16px;
      }
    }

    &-container {
      padding-left: 15px;
      padding-right: 15px;
      padding-bottom: 8px;
      border-bottom: 0.5px solid #373737;
      height: 137px;
      overflow-y: auto;
      @include scrollbars(0.4rem, #717171, #00000080);
    }
  }

  .metric {
    &-header {
      padding: 20px 15px;

      &-title {
        font-size: 16px;
      }
    }

    &-container {
      padding-left: 15px;
      padding-right: 15px;
      padding-bottom: 8px;
      height: 137px;
      margin-bottom: auto;
    }
  }

  .bar-metric {
    &-header {
      padding: 10px 15px;
    }

    &-title {
      font-size: 12px;
    }

    &-container {
      height: 85px;
      padding-left: 15px;
      padding-right: 15px;
      padding-bottom: 8px;
      margin-bottom: auto;
      overflow-y: auto;
      @include scrollbars(0.4rem, #717171, #00000080);
      border-bottom: 0.5px solid #373737;
    }
  }

  .series {
    &-header {
      padding: 10px 15px;
    }

    &-title {
      font-size: 12px;
    }

    &-container {
      height: 85px;
      padding-left: 15px;
      padding-right: 15px;
      padding-bottom: 8px;
      margin-bottom: auto;
      overflow-y: auto;
      @include scrollbars(0.4rem, #717171, #00000080);
      border-bottom: 0.5px solid #373737;
    }
  }

  .category {
    &-header {
      padding: 10px 15px;
    }

    &-title {
      font-size: 12px;
    }

    &-container {
      height: 85px;
      padding-left: 15px;
      padding-right: 15px;
      padding-bottom: 8px;
      margin-bottom: auto;
      overflow-y: auto;
      @include scrollbars(0.4rem, #717171, #00000080);
    }
  }

  // .action {
  //   border-top: 0.5px solid #373737;
  //   padding: 11px 13px;
  //   // position: absolute;
  //   position: fixed;
  //   bottom: 0px;
  //   width: 100%;
  //   opacity: .9;
  //   background-color: red
  // }

  .xAxis {
    &-header {
      padding: 8px 15px;

      &-title {
        font-size: 12px;
      }
    }

    &-container {
      overflow-y: auto;
      @include scrollbars(0.4rem, #717171, #00000080);
      border-bottom: 0.5px solid #373737;
      height: 40px;
      padding-left: 15px;
      padding-right: 15px;
    }
  }

  .yAxis {
    &-header {
      padding: 8px 15px;

      &-title {
        font-size: 12px;
      }
    }

    &-container {
      overflow-y: auto;
      @include scrollbars(0.4rem, #717171, #00000080);
      border-bottom: 0.5px solid #373737;
      height: 40px;
      padding-left: 15px;
      padding-right: 15px;
    }
  }

  .categories {
    &-header {
      padding: 8px 15px;

      &-title {
        font-size: 12px;
      }
    }

    &-container {
      overflow-y: auto;
      @include scrollbars(0.4rem, #717171, #00000080);
      border-bottom: 0.5px solid #373737;
      height: 40px;
      padding-left: 15px;
      padding-right: 15px;
    }

    &-color {
      position: absolute;
      z-index: 11;
      left: 40px;
    }
  }

  .index {
    &-header {
      padding: 8px 15px;

      &-title {
        font-size: 12px;
      }
    }

    &-container {
      overflow-y: auto;
      @include scrollbars(0.4rem, #717171, #00000080);
      border-bottom: 0.5px solid #373737;
      height: 40px;
      padding-left: 15px;
      padding-right: 15px;
    }
  }

  .size {
    &-header {
      padding: 8px 15px;

      &-title {
        font-size: 0px;
      }
    }

    &-container {
      overflow-y: auto;
      @include scrollbars(0.4rem, #717171, #00000080);
      height: 100px;
      padding-left: 15px;
      padding-right: 15px;
    }
  }
}

.ml {
  &-06 {
    margin-left: 6px;
  }
}

.mb {
  &-08 {
    margin-bottom: 8px;
  }
}

.mr {
  &-06 {
    margin-right: 6px;
  }
}
</style>