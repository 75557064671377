import { mainAxios } from "./axios";

class DatasetService {
  get(id) {
    return mainAxios.get(`/dataset/${id}`);
  }
  getData(id, q) {
    return mainAxios.get(`/dataset/${id}/data?q=${JSON.stringify(q)}`);
  }
  getAll(q) {
    return mainAxios.get(`/dataset/?q=${JSON.stringify(q)}`);
  }
  getFavStatusAll(q) {
    return mainAxios.get(`/dataset/favorite_status/?q=${q}`);
  }
  create(params) {
    return mainAxios.post(`/dataset/`, params);
  }
  update(id, params) {
    return mainAxios.put(`/dataset/${id}`, params);
  }
  delete(id) {
    return mainAxios.delete(`/dataset/${id}`);
  }
  deleteMulti(q) {
    return mainAxios.delete(`/dataset/?q=${JSON.stringify(q)}`);
  }
}

export default new DatasetService();
