<template>
<div>
  <v-container
      class="fill-height"
    >
  <v-data-table
    class="elevation-1"
    dense
    :headers="vis_res_headers"
    :items="vis_data_rows"
    :items-per-page="20"
    :search="search"
    :hide-default-footer="true"
  >
    <template v-slot:top="{ pagination, options, updateOptions }">
      <v-data-footer 
        :pagination="pagination" 
        :options="options"
        @update:options="updateOptions"
        items-per-page-text="$vuetify.dataTable.itemsPerPageText"
        :show-first-last-page="true"
        :items-per-page-options="[5,10,20,-1]"
      />
    </template>
  </v-data-table>
  </v-container>
</div>
</template>

<script>

export default {
  name: "VisMainTable",
  data() {
    return {
    }
  },
  props: {
    search: {
      type: String,
      default() {
        return '';
      }
    },
    vis_data_rows: {
      type: Array,
      default() {
        return [];
      },
    },
    vis_res_headers: {
      type: Array,
      default() {
        return [];
      },
    },
    // filters: {
    //   type: Array,
    //   default() {
    //     return [];
    //   },
    // },
  },

  methods: {
  },
  computed: {
  },
};
</script>

<style lang="scss" scoped>
.v-data-table {
  // TODO: horizontal scroll isn't working
  max-width: 100%;
  // overflow-x: scroll;
  overflow: auto;
  // overflow-x: scroll;
  // overflow-y: scroll;
  // @include scrollbars(0.4rem, #717171, #00000080);
}
</style>