<template>
  <div class="query-calc pl-6">
    <div ref="codemirror">
      <!-- SQL Editor -->
      <codemirror
        ref="cmEditor"
        :value="code"
        :options="cmOptions"
        @input="onCmCodeChange"
        @keyHandled="onKeyHandled"
      />
      <!-- Action Buttons -->
      <div class="pt-3">
        <!-- Run -->
        <v-tooltip 
          bottom
          color="#454545"  
        >
          <template v-slot:activator="{ on, attrs }">
            <!-- @click="runQuery" -->
            <!-- @click="loader = 'queryRunning'" -->
            <!-- :disabled="queryRunning" -->
            <v-btn color="primary" 
              @click="runQuery"
              v-bind="attrs"
              v-on="on"
              :loading="queryRunning"
              :disabled="!diableRunBtn"
            >
              <v-icon left>mdi-cached</v-icon>
              Run
            </v-btn>
          </template>
          <span>Shortcuts:<br/>
            &ensp; <i>Cntrl/Cmd-Enter</i>
          </span>
        </v-tooltip>
        <!-- Save -->
        <v-btn 
          class="ml-2" 
          outlined 
          color="grey"
          @click="saveQueryAction"
        >Save</v-btn>
        <!-- Export  -->
        <!-- <v-btn class="ml-2">
          <v-icon>mdi-tray-arrow-up</v-icon>
        </v-btn> -->
      </div>
    </div>
    <!-- Query Results -->
    <div class="pt-3">
      <v-tabs color="white" class="query-result-tabs" v-model="activeTab">
        <v-tab>Result</v-tab>
        <v-tab @click="getQueryHistory">Query History</v-tab>
        <v-tab-item class="query-result-tab">
          <div class="pt-3 pb-2">
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" outlined color="grey">
                  Explore
                </v-btn>
              </template>
              <v-list>
                <v-list-item-group>
                  <v-list-item
                    dense
                    v-for="(item, index) in menuItems"
                    :key="index"
                    @click="exploreEvent(item)"
                  >
                    <v-list-item-content>
                      <v-list-item-title v-text="item.text"></v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-menu>
            <v-btn class="ml-2" outlined color="grey"> .CSV </v-btn>
          </div>
          <ag-grid-vue
            style=" height: 250px"
            class="ag-theme-alpine-dark"
            :columnDefs="columnDefs"
            :rowData="rowData"
            :defaultColDef="defaultColDef"
            @grid-ready="onGridReady"
          />
        </v-tab-item>
        <v-tab-item class="query-history-tab">
          <v-simple-table
            dark
            class="query-history-table"
            height="250px"
            fixed-header
          >
            <template v-slot:default>
              <thead>
                <tr>
                  <th v-for="col in historyColumnDefs" :key="col">
                    {{ col }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(row, index) in historyRowData" :key="index">
                  <td>{{ row.status }}</td>
                  <td>{{ row.changed_on | formatDate }}</td>
                  <td>{{ row.end_time - row.start_time }}</td>
                  <td>{{ row.rows }}</td>
                  <td class="query-history-pre">
                    <pre><code class="language-sql">{{row.sql}}</code></pre>
                  </td>
                  <td>
                    <v-btn @click="runQueryCache(row)" icon>
                      <v-icon left>mdi-cached</v-icon>
                    </v-btn>
                    <v-btn @click="editQueryCache(row)" icon>
                      <v-icon left>mdi-pencil</v-icon>
                    </v-btn>
                    <v-btn @click="deleteQuery(row)" icon>
                      <v-icon left>mdi-delete</v-icon>
                    </v-btn>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-tab-item>
      </v-tabs>
    </div>
    <!-- Query Error Snackbar -->
    <v-snackbar
      v-model="apiSnackbar"
      color="#ffbb33"
      min-width="320"
      max-width="500"
    > 
      <span>Query Failed</span><br/>
      <span>Code: {{ apiError.status_code }}</span><br/>
      <span>Message: {{ apiError.message }}</span>
      <!-- @click="apiSnackbar = false" -->
      <template v-slot:action="{ attrs }">
        <v-btn
          color="#3366FF"
          v-bind="attrs"
          @click="closeSnackBar"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
    <!-- Save Chart name -->
    <SaveChartDialog
      :dialog="saveQueryDialog"
      @rename="applyQueryTitle"
      @cancel="saveQueryDialog=false"
    />
  </div>
</template>

<script>
// import language js
import "codemirror/mode/sql/sql.js";
// theme css
import "codemirror/theme/mbo.css";
import { AgGridVue } from "ag-grid-vue";
import { LicenseManager } from "ag-grid-enterprise";
LicenseManager.setLicenseKey(process.env.VUE_APP_AG_LICENSE);
import { GET_QUERY_HISTORY_ALL, RUN_QUERY } from "@/store/actionType";
import { v4 as uuidv4 } from "uuid";

import SaveChartDialog from "@/views/queryHistory/SaveChartTitle";

import { POST_SAVED_QUERY } from "@/store/actionType";

export default {
  name: "QueryCalc",
  components: {
    AgGridVue,
    SaveChartDialog,
  },
  data() {
    return {
      //codemirror options
      cmOptions: {
        tabSize: 2,
        styleActiveLine: true,
        lineNumbers: true,
        line: true,
        mode: "text/x-mysql",
        theme: "mbo",
        extraKeys: {
          "Ctrl-Enter": function(){},
          "Cmd-Enter": function(){},
        },
      },
      //inputed code
      // code: "-- sql Explorer",
      code: null,
      columnDefs: [],
      rowData: [],
      historyColumnDefs: [
        "State",
        "Started",
        "Duration",
        "Rows",
        "SQL",
        "Actions",
      ],
      historyRowData: [],
      defaultColDef: {
        flex: 1,
        minWidth: 200,
        resizable: true,
        floatingFilter: true,
        sortable: true,
        filter: true,
      },
      //Ag grid API
      gridApi: null,
      //active tab
      activeTab: 0,
      //expolor menu items
      menuItems: [
        {
          text: "Explore",
          action: "explore",
        },
        {
          text: "Export as (csv, excel)",
          action: "exportCSV",
        },
        {
          text: "Copy to Clipboard",
          action: "copyClip",
        },
        {
          text: "Copy to Clipboard with no headers",
          action: "copyClipNoHeader",
        },
      ],
      apiSnackbar: false,
      apiError: {},
      loader: null,
      queryRunning: false,
      saveQueryDialog: false,
    };
  },
  computed: {
    diableRunBtn() {
      let returnVal = !!this.selectedDB && !this.queryRunning
      return returnVal
    },
    codemirror() {
      return this.$refs.cmEditor
    },
    changed_db: {
      get() {
        return this.$store.state.query.sqlLab_selected_db;
      },
    },
    sqlLab: {
      get() {
        return this.$store.state.query.sqlLab;
      },
      set(val) {
        this.$store.commit("SQLLAB", val);
      },
    },
    selectedTabIndex: {
      get() {
        return this.$store.state.query.sqlLab_selected_tabIndx;
      },
      set(val) {
        this.$store.commit("SELECTED_TABINDEX", val);
      },
    },
    selectedTab: {
      get() {
        return this.$store.state.query.sqlLab_selected_tab;
      },
      set(val) {
        this.$store.commit("SELECTED_TAB", val);
      },
    },
    selectedDB: {
      get() {
        return this.selectedTab.database ?
          this.selectedTab.database : null;
        // return this.sqlLab.databases
          // ? this.sqlLab.databases[this.selectedTab.id]
          // : null;
      },
    },
    queryResults: {
      get() {
        return this.selectedTab.queryResults;
        // return this.sqlLab &&
        //   this.sqlLab.query_results &&
        //   this.sqlLab.query_results[this.selectedTab.id]
        //   ? this.sqlLab.query_results[this.selectedTab.id]
        //   : null;
      },
    },
  },
  mounted() {
    this.initialize();
  },
  methods: {
    /// Imports
    // postSavedQuery,
    ///////////
    /**
     * initialize
     */
    initialize() {
      if (this.selectedTab) {
        this.getQueryHistory();
        if (this.selectedTab.code) {
          this.code = this.selectedTab.code;
        }
        if (
          this.selectedTab.queryResults
          // this.sqlLab.query_results[this.selectedTab.id]
        ) {
          this.initializeTable();
        } else {
          this.columnDefs = [];
          this.rowData = [];
        }
      }
      // if (this.sqlLab) {
      //   this.getQueryHistory();
      //   if (this.sqlLab.codes && this.sqlLab.codes[this.selectedTab.id]) {
      //     this.code = this.sqlLab.codes[this.selectedTab.id];
      //   } else {
      //     this.code = "-- sql Explorer";
      //   }
      //   if (
      //     this.sqlLab.query_results &&
      //     this.sqlLab.query_results[this.selectedTab.id]
      //   ) {
      //     this.initializeTable();
      //   } else {
      //     this.columnDefs = [];
      //     this.rowData = [];
      //   }
      // }
    },
    /**
     * get query history
     */
    getQueryHistory() {
      // if (this.selectedDB) {
        this.$store.dispatch(GET_QUERY_HISTORY_ALL).then((res) => {
          this.historyRowData = res.data.result//.filter((item) => {
            // return (
              // item.database.database_name === this.selectedDB.database_name
            // );
          // });
        });
      // }
    },
    /**
     * sql query input event
     * @param newCode
     */
    onCmCodeChange(newCode) {
      this.code = newCode;
    },
    onKeyHandled(cm, name, event) {
      // Set Keys for running the query hot-keys
      let keymap_keys = ["Ctrl-Enter", "Cmd-Enter"];
      if (keymap_keys.includes(name)) {
        this.runQuery();
      }
    },
    /**
     * run sql query
     */
    runQuery() {
      this.queryRunning=true;
      this.activeTab = 0;
      const sendingData = {
        client_id: uuidv4(),
        database_id: this.selectedDB.id, //
        json: true,
        queryLimit: 1000,
        expand_data: true,
        runAsync: false,
        select_as_cta: false,
        sql: this.code, //
        sql_editor_id: "",
        tab: "",
        tmp_table_name: "",
        ctas_method: "TABLE",
      };
      this.$store.dispatch(RUN_QUERY, sendingData)
      .then(res => {
          this.setQueryRes(res.data);
          console.log('runQuery', )
          this.initializeTable();
          this.queryRunning=false;
        }).catch((r) => {
          this.queryRunning=false;
          console.error("Uncaught Error: ", r);
          this.apiError={...{
          'status_code':r.response.status, 
          'message': r.response.statusText
          }
        }
        this.apiSnackbar=true;
        this.queryRunning=false;
          }
        )
    },
    closeSnackBar() {
      this.apiSnackbar = false;
      this.queryRunning = false;
    },
    /**
     * set query result
     * @param data
     */
    setQueryRes(data) {
      console.log('query res', data)
      this.selectedTab.queryResults = data;
      this.$store.commit("SELECTED_TAB", this.selectedTab);
      // let new_sqlLab = {
      //   ...this.sqlLab,
      // };
      // new_sqlLab["query_results"] = new_sqlLab["query_results"]
      //   ? new_sqlLab["query_results"]
      //   : new Object();
      // new_sqlLab["query_results"][this.selectedTab.id] = data;
      // this.$store.commit("SQLLAB", new_sqlLab);
    },
    /**
     * initialize query result
     */
    initializeTable() {
      this.columnDefs = [];
      let cols = this.queryResults.columns;
      for (let col of cols) {
        this.columnDefs.push({
          field: `${col.name}`,
          filter: true,
          rowDrag: true,
        });
      }
      this.rowData = this.queryResults.data;
    },
    /**
     * run query with cache
     * @param history
     */
    runQueryCache(history) {
      this.code = history.executed_sql;
      this.runQuery();
    },
    /**
     * edit query cache
     * @param history
     */
    editQueryCache(history) {
      this.code = history.executed_sql;
    },
    /**
     * delete query cache
     * @param history
     */
    deleteQuery(history) {
      console.log(history);
    },
    /**
     * grid table ready event
     */
    onGridReady(params) {
      this.gridApi = params.api;
      this.gridApi.setRowData(this.rowData);
    },
    /**
     * explore menu event
     * @param menuItem
     */
    exploreEvent(menuItem) {
      switch (menuItem.action) {
        case "explore":
          break;
        case "exportCSV":
          this.gridApi.exportDataAsExcel();
          break;
        case "copyClip":
          this.gridApi.setSuppressClipboardPaste(true);
          break;
        case "copyClipNoHeader":
          break;
      }
    },
    applyQueryTitle (query_name) {
      // console.log('input', query_name)
      this.saveQueryDialog=false;
      this.saveQuery(query_name)
      // this.saveQuery(query_nm) = query_name;
      // this.
      // this.$emit('actionItemEvent', 'save_query', this.item, query_name)
    },
    saveQueryAction() {
      console.log('state', this.sqlLab)
      //  TODO: restor this to correct order
      // refactoring to test sqlLab object and page state
      // this.saveQueryDialog=true;
      // this.saveQuery({'name': 'def', 'description':'test'})
    },
    // saveQuery(row, title_fields) {
    saveQuery(query_name) {
        // console.log('save', this.selectedDB)
        let params = {
          db_id: this.selectedDB.id,
          label: query_name.name,
          description: query_name.description,
          sql: this.code
        }
        // console.log('params', params)
        this.postSavedQuery(params);
    },
    postSavedQuery(params) {
      this.$store
        .dispatch(POST_SAVED_QUERY, params)
        .catch((r) => {
          console.error("We have error: ", r);
        });
    },
  },
  watch: {
    code: {
      handler() {
        this.selectedTab.code = this.code;
        this.$store.commit("SELECTED_TAB", this.selectedTab);
        // let new_sqlLab = {
        //   ...this.sqlLab,
        // };
        // new_sqlLab["codes"] = new_sqlLab["codes"]
        //   ? new_sqlLab["codes"]
        //   : new Object();
        // new_sqlLab["codes"][this.selectedTab.id] = this.code;
        // this.$store.commit("SQLLAB", new_sqlLab);
      },
    },
    // queryResults: {
    //   handler() {
    //     if (this.queryResults) {
    //       this.selectedTab.queryResults = this.queryResults;
    //       this.$store.commit("SELECTED_TAB", this.selectedTab);
    //       this.initializeTable()
    //     }
    //   },
    // },
    selectedTab: {
      handler() {
        this.activeTab = 0;
        this.initialize();
      },
    },
    changed_db: {
      handler() {
        this.code = "-- sql Explorer";
        this.setQueryRes(null);
        this.columnDefs = [];
        this.rowData = [];
        this.getQueryHistory();
      },
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~ag-grid-community/dist/styles/ag-grid.css";
@import "~ag-grid-community/dist/styles/ag-theme-alpine-dark.css";

.query {
  &-calc {
    @include scrollbars(0.4rem, black, transparent);
  }

  &-result {
    &-tabs {
      >>> .v-item-group {
        border-bottom: 1px solid grey;
      }
    }

    &-tab {
      border-top: 1px solid grey;
    }
  }

  &-history {
    &-tab {
      @include scrollbars(0.25rem, black, transparent);
    }

    &-pre {
      max-width: 300px;

      pre {
        @include scrollbars(0.4rem, black, transparent);
        display: block;
        overflow-x: auto;
        padding: 0.5em;
        color: rgb(248, 248, 248);
        background: rgb(51, 51, 51);
        border: none;
        font-size: 12px;
        margin: 0;
        padding: 0 !important;

        code {
          white-space: pre;
          background-color: initial;
          border-radius: 0;
          color: inherit;
          font-size: inherit;
          padding: 0;
        }
      }
    }

    &-table {
      @include scrollbars(0.4rem, black, transparent);

      >>> .v-data-table__wrapper {
        @include scrollbars(0.4rem, black, transparent);
      }
    }
  }
}
</style>
