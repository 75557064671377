import Vue from 'vue';
import Vuex from 'vuex';

import auth from './auth.module';
import query from './query.module';
import project from './project.module';
import cinema from './cinema.module';
import whitepaper from './whitepaper.module';
import chart from './chart.module';
import dashboard from './dashboard.module';
import dataset from './dataset.module';
import fav from './fav.module';
import datasource from './datasource.module';
import database from './database.module';
import saved_query from './saved_query.module';
import explore from './explore.module';
import security from './security.module';
import char from './char.module';
import async from './async.module';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth,
    project,
    query,
    cinema,
    whitepaper,        
    chart,
    dashboard,
    dataset,
    fav,
    datasource,
    database,
    saved_query,
    explore,
    security,
    char,
    async
  }
})