import DatasourcesService from "../services/DatasourcesService";

import {
  TEST_DATABASE_CONNECTION,
  CREATE_DB,
  UPLOAD_FILE,
  EDIT_DB,
} from "./actionType";

const state = {};

const actions = {
  [TEST_DATABASE_CONNECTION](_, data) {
    return DatasourcesService.testDbConnect(data).then(
      res => {
        return res;
      }
    )
  },
  [CREATE_DB](_, data) {
    return DatasourcesService.createDB(data).then(
      res => {
        return res;
      }
    )
  },
  [EDIT_DB](_, data) {
    let id = data.id
    let params = data.data
    return DatasourcesService.updateDB(id, params).then(
      res => {
        return res;
      }
    )
  },
  [UPLOAD_FILE](_, params) {
    return DatasourcesService.uploadFile(params).then(
      res => {
        return res;
      }
  )
  }
}

const mutations = {};

export default {
  state,
  actions,
  mutations
}