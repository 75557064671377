<template>
  <div class="d-flex flex-column fill-height flex-grow-1 overflow-hidden pt-6">
    <NewDB :showNewDB="showNewDB" @cancel="cancelNewDB" />
    <NewFromDB
      :showNewFromDB="showNewFromDB"
      @cancel="cancelNewFromDB"
      @createDB="showCreateDB"
      @goToDatasets="goToDatasets"
    />
    <NewFromFile
      :showNewFromFile="showNewFromFile"
      @cancel="cancelNewFromFile"
      @showAdvance="showAdvance"
      @save_file="saveFile"
    />
    <NewFromFileAdvance
      :showNewFromFileAdvance="showNewFromFileAdvance"
      @cancel="cancelNewAdvance"
      @save="saveNewAdvance"
    />
    <ModuleHeader
      title="Saved Queries"
      :disabled-grid-btn="true"
      :disabled-list-btn="true"
      :disabled-fav-btn="true"
      :view-grid="false"
    />

    <ModuleAction
      title="Saved Queries"
      search-label="Search Saved Queries"
      :hide-new-button="true"
      :selectedItems="selectedItemIds"
      :n_items="filteredItemCount"
      @deleteAll="deleteAll"
      @selectItemsEvent="selectItemsEvent"
      @moduleFilterChanged="moduleFilterChanged"
    />

    <div
      class="
        d-flex
        flex-grow-1 flex-column flex-basis-0
        fill-height
        overflow-x-auto
      "
    >
      <v-container>
        <v-row v-if="loading" class="container-row-resolution py-5 d-flex justify-center">
          <v-progress-circular
            indeterminate
            color="primary"
          ></v-progress-circular>
        </v-row>

        <v-data-table
          id="tblItems"
          v-else-if="getModels(collection, viewFavOnly).length"
          v-model="selectedItems"
          :items="getModels(collection)"
          :items-per-page="$pageItemCount"
          :headers="headers"
          :search="search"
          @click:row="editItem"
          @item-selected="updateGridViewSelection"
          show-select
          item-key="id"
          class="mt-3"
          :hide-default-footer="true"
          :header-props="{ sortIcon: 'mdi-chevron-down' }"
        >
          <template v-slot:top="{ pagination, options, updateOptions }">
            <v-data-footer
              :pagination="pagination"
              :options="options"
              @update:options="updateOptions"
              items-per-page-text="$vuetify.dataTable.itemsPerPageText"
            />
          </template>
          <template #[`item.label`]="{ item }">
            <div @click.stop="clickSavedQuery(item)"
              class="title"
            ><a>{{ item.label }}</a>
              <!-- <a :href="`/#/sql-page/`">{{item.label}}</a> -->
            </div>
          </template>
          <template #[`item.database.database_name`]="{ item }">
            <v-chip color="primary" label>{{
              item.database.database_name
            }}</v-chip>
          </template>
          <template #[`item.action`]="{ item }">
            <RowAction
              :item="item"
              :disabledList="disabledRowActions"
              @editItem="editItem"
              @deleteItem="deleteItem"
              @infoItem="infoItem"
            />
          </template>
        </v-data-table>

        <v-row v-else class="container-row-resolution pt-5 d-flex justify-center">
          <p>No Saved Queries Found ...</p>
        </v-row>
      </v-container>
    </div>
    <!-- Info Navigation Drawer -->
    <!-- :width="navigation.width" -->
    <v-navigation-drawer
      v-model="detailModalVisible"
      hide-overlay
      right
      temporary
      absolute
      color="#262626"
      width="500px"
    >
    <v-list-item>
      <v-card 
        v-if="curItem" 
        width="500"
        color="#262626"
        flat
      >
        <v-card-title>{{ pageTitle + ' Info' }}</v-card-title>
        <!-- TODO: make this look nice -->
        <v-container overflow-hidden>
          <div class="outline d-flex">
            <v-col md="12">
              <table class="w-100 prop-table">
                <tr v-for="(prop, key) in curItem" v-bind:key="key">
                  <td class="pl-2">{{ key }}</td>
                  <td class="pl-2">{{ prop }}</td>
                </tr>
              </table>
            </v-col>
          </div>
        </v-container>
      </v-card>
      </v-list-item>
    </v-navigation-drawer>
  </div>
</template>

<script>
import NewDB from "@/components/modal/NewDB.vue";
import NewFromDB from "@/components/modal/NewFromDB.vue";
import NewFromFile from "@/components/modal/NewFromFile.vue";
import NewFromFileAdvance from "@/components/modal/NewFromFileAdvance.vue";
import ModuleHeader from "@/views/_partials/ModuleHeader";
import RowAction from "@/views/_partials/RowAction";
import ModuleAction from "@/views/_partials/ModuleAction";

import { 
    toggleSelectAll,
    modifyAllCheck,
    selectItemsEvent,
    filteredObjs,
  } from "@/utils/moduleUtils.js";

import {
  GET_SAVED_QUERY_ALL,
  DELETE_SAVED_QUERY,
  DELETE_SAVED_QUERY_MULTI,
  UPLOAD_FILE,
} from "@/store/actionType";

export default {
  name: "SavedQueryIndex",
  components: {
    NewDB,
    NewFromDB,
    NewFromFile,
    NewFromFileAdvance,
    ModuleHeader,
    ModuleAction,
    RowAction,
  },
  data: () => ({
    viewGrid: false,
    viewFavOnly: false,
    selectedItems: [],
    search: "",
    moduleFilter:"",
    //table headers
    headers: [
      { text: "Name", value: "label" },
      { text: "Description", value: "description" },
      { text: "Database", value: "database.database_name" },
      { text: "Schema", value: "schema", width: 120 },
      { text: "Created By", value: "created_by.username", width: 150 },
      {
        text: "Last Modified",
        value: "changed_on_delta_humanized",
        width: 180,
      },
      { text: "Actions", value: "action", width: 200, sortable: false },
    ],
    disabledRowActions: {
      'edit':true, 
      'info':false, 
      'trashcan':false, 
      'action': true
    },
    collection: [],
    loading: false,
    //saved query
    savedQueries: [],
    //selected all
    selected_all: false,
    //checkbox indeterminate
    indeterminate: false,
    //show New database dialog
    showNewDB: false,
    //show New from database dialog
    showNewFromDB: false,
    //show New from file dialog
    showNewFromFile: false,
    //show New from file advance dialog
    showNewFromFileAdvance: false,
    //advance file object
    advance_file: null,
    // Detail Modal
    pageTitle: "Saved Query",
    detailModalVisible: false,
    curItem: null,
  }),
  mounted() {
    // this.getSavedQueries();
    this.load();
  },
  computed: {
    filteredItemCount () {
      return [ 
        this.collection.length, // count of items
        // filtered items
        this.getModels(this.collection).length || 0 //?
        // this.getModels(this.collection, this.viewFavOnly).length : 0
      ]
    },

    selectedItemIds () {
      let id_list = this.selectedItems.map(a => a.id);
      return id_list;
    },
  },
  methods: {
    // Imports
    //////////
    //moduleUtils
    toggleSelectAll,
    modifyAllCheck,
    selectItemsEvent,
    filteredObjs,
    //////////
    clickSavedQuery (item) {
      // item
      // console.log('query', this.$store.state.query.sqlLab)
      console.log('query', item)
      // this.$router.push("/sql-page");
    },
    moduleFilterChanged (input) {
      this.moduleFilter = input;
    },
    getRecentModels(arr) {
      //This module doesn't have Favorite
      return arr;
    },
    getModels(arr) {
      //This module doesn't have Favorite
      console.log('arr', arr)
      if (arr.length && this.moduleFilter.length > 0) {
        arr = this.filteredObjs(arr, 'label', this.moduleFilter)
      }
      return this.getRecentModels(arr)
    },
    load() {
      const columns = [
        "id",
        "created_by.username",
        "changed_on_delta_humanized",
        "label",
        "description",
        "database.database_name",
        "schema",
        "description",
        "sql",
      ];

      const q = {
        columns: columns,
        order_column: this.curSortField,
        order_direction: "desc",
      };

      this.loading = true
      // Load Recent Objects
      // Saved Query doesn't have favorites
      this.$store
        .dispatch(GET_SAVED_QUERY_ALL, q)
        .then((r) => {
          this.collection = r.data.result;
        })
        .catch((r) => {
          console.error("We have error:", r);
        })
        .finally(() => {
          this.loading = false
        })
    },
    /**
     * get all saved queries
     */
    getSavedQueries() {
      this.$store.dispatch(GET_SAVED_QUERY_ALL).then((res) => {
        this.initialize(res.data.result);
      });
    },
    /**
     * close new from DB and show new DB
     */
    showCreateDB() {
      this.showNewFromDB = false;
      this.showNewDB = true;
    },
    /**
     * go to datasets page
     */
    goToDatasets() {
      this.showNewFromDB = false;
      this.$router.push("/datasets");
    },
    /**
     * initialize saved query values
     * @param values
     */
    initialize(values) {
      this.savedQueries = [];
      for (let item of values) {
        this.savedQueries.push({
          name: item.label,
          description: item.description,
          database: item.database.database_name,
          schema: item.schema,
          created_by: item.created_by
            ? `${item.created_by.first_name[0]} ${item.created_by.last_name[0]}`
            : "",
          last_modified: item.changed_on_delta_humanized,
          selected: false,
        });
      }
    },
    /**
     * select one row
     */
    selectRow() {
      const isTrue = (element, index) => {
        return element.selected === true;
      };
      if (this.savedQueries.every(isTrue)) {
        this.selected_all = true;
        this.indeterminate = false;
      } else {
        this.selected_all = false;
        let find = this.savedQueries.find((item) => item.selected == true);
        if (find) {
          this.indeterminate = true;
        } else {
          this.indeterminate = false;
        }
      }
    },

    /**
     * select all datasets
     */
    selectAll(event) {
      for (let i = 0; i < this.savedQueries.length; i++) {
        this.savedQueries[i].selected = event;
      }
    },
    /**
     * cancel new DB
     */
    cancelNewDB() {
      this.showNewDB = false;
    },
    /**
     * cancel new from DB
     */
    cancelNewFromDB() {
      this.showNewFromDB = false;
    },
    /**
     * cancel new from file
     */
    cancelNewFromFile() {
      this.showNewFromFile = false;
    },
    /**
     * cancel new from file advance
     */
    cancelNewAdvance() {
      this.showNewFromFileAdvance = false;
    },
    /**
     * save new from file advance
     */
    saveNewAdvance(val) {
      this.advance_file = val;
      this.showNewFromFileAdvance = false;
    },
    /**
     * show new from file advance
     */
    showAdvance() {
      this.showNewFromFileAdvance = true;
    },
    deleteItem(item) {
      this.$store.dispatch(DELETE_SAVED_QUERY, item.id).then(() => {
        this.load();
      });
    },
    infoItem(item) {
      this.detailModalVisible = true;
      // console.log('item', item)
      this.curItem = item;
    },
    deleteAll() {
      let selIds = this.selectedItemIds
      console.log('delete these:', selIds)
      this.$store
        .dispatch(DELETE_SAVED_QUERY_MULTI, selIds)
        .then(() => {
          this.load();
        })
        .catch((r) => {
          console.error("We have error:", r);
        });
    },
    clickNewItem() {},
    editItem(item) {
      console.log('edit button')
      // this.$router.push({ name: 'Databases-Detail', params: { id: item.id } });
    },
    /**
     * Update the selection status in Grid View
     */
    updateGridViewSelection(obj) {
      let selItem = this.collection.find((item) => item.id === obj.item.id);
      if (selItem) {
        selItem.checked = obj.value;
      }
    },
    /**
     * Update the selection status in Grid View
     */
    toggleChecked(item) {
      let index = this.selectedItems.findIndex((_item) => _item.id === item.id);
      if (item.checked === true) {
        if (index == -1) {
          this.selectedItems.push(item);
        }
      } else {
        if (index > -1) {
          this.selectedItems.splice(index, 1);
        }
      }
    },
    /**
     * save from file
     */
    saveFile(data) {
      if (this.advance_file) {
        data.append("index_col", this.advance_file.index_col);
        data.append("skip_rows", this.advance_file.skip_rows);
        data.append("n_rows", this.advance_file.n_rows);
        data.append("mangle_dupe_cols", this.advance_file.mangle_dupe_cols);
        data.append("skip_spaces", this.advance_file.skip_spaces);
        data.append("skip_blank_lines", this.advance_file.skip_blank_lines);
        data.append("dataframe", this.advance_file.dataframe);
        data.append("use_columns", this.advance_file.use_columns);
        data.append("index_label", this.advance_file.index_label);
        data.append("decimal_char", this.advance_file.decimal_char);
        data.append("parse_dates", this.advance_file.parse_dates);
        data.append("infer_datetime", this.advance_file.infer_datetime);
        data.append("null_values", this.advance_file.null_values);
      }
      this.$store.dispatch(UPLOAD_FILE, data).then((res) => {
        this.showNewFromFile = false;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.w-100 {
  width: 100%;
}

.dropdown-btn {
  min-width: 32px !important;
  padding: 0 !important;
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
  height: 32px !important;
}
.dropdown-text-btn {
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
  margin-right: 1px;
  height: 32px !important;
}

.avatar-text {
  text-transform: uppercase;
}

.name {
  color: #8ab7ed;
  font-size: 14px;
}

.header {
  &-text {
    font-size: 16px;
    color: #ffffff;
  }

  &-icon {
    margin-left: 12px;
    margin-right: auto;
  }

  &-left {
    margin-left: inherit;
  }
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.cursor-pointer {
  cursor: pointer;
}
</style>