import AuthService from "../services/AuthService";
import {
    LOGIN,
    LOGOUT,
} from './actionType';

import {
    SUCCESSLOGIN,
    LOGINFAILURE,
    LOGOUTSUCCESS
} from './mutationType';

const kodoti = JSON.parse(localStorage.getItem("kodoti_application"));

const initialState = kodoti
    ? { status: { loggedIn: true }, user: kodoti.user, token: kodoti.access_token }
    : { status: { loggedIn: false }, user: null, token: null };

const state = {
    isAuthenticated: false,
    user: {},
    initialState
}

const actions = {
    [LOGIN](context, data) {
        console.log('outside')
        return AuthService.login(data).then(
            res => {
                console.log('res', res)
                if (res.status===400) {
                    // throw new Error('Error Code 400, Bad Request')
                    throw res
                } else if (res.statusText === "OK") {
                    // console.log('is ok', res)
                    const kodoti_info = {
                        ...res.data,
                        user: {
                            name: data.username,
                            email: data.username
                            //email: `${data.username}@gmail.com`
                        }
                    }
                    context.commit(SUCCESSLOGIN, kodoti_info);
                    return Promise.resolve(res.data);
                } else {
                    console.log('is not ok', res)
                    context.commit(LOGINFAILURE);    
                }
            },
            error => {
                console.log('erro')
                context.commit(LOGINFAILURE);
                return Promise.reject(error);
            }
        )
    },
    [LOGOUT](context) {
        localStorage.removeItem("kodoti_application");
        context.commit(LOGOUTSUCCESS);
    }
}

const mutations = {
    [SUCCESSLOGIN](state, data) {
        state.initialState.status.loggedIn = true;
        state.initialState.user =  data.user;
        state.initialState.token = data.access_token
        localStorage.setItem("kodoti_application", JSON.stringify(data)); 
    },
    [LOGINFAILURE](state) {
        state.initialState.status.loggedIn = false;
        state.initialState.user =  null;
        state.initialState.token = null;
    },
    [LOGOUTSUCCESS](state) {
        state.initialState.status.loggedIn = false;
        state.initialState.user =  null;
        state.initialState.token = null;
    }
}

export default {
    state,
    actions,
    mutations
}