<template>
  <div class="project-nav">
    <div v-if="collapse" class="project-nav-hide">
      <v-btn icon @click="showMenu">
        <v-icon> mdi-chevron-right </v-icon>
      </v-btn>
      <div>
        <div class="project-nav-hide-tabs">
          <div class="project-nav-hide-tab active">Filters</div>
          <div class="project-nav-hide-tab">Charts</div>
        </div>
      </div>
    </div>
    <div v-else class="project-nav-show">
      <div class="d-flex">
        <v-icon class="ml-2" color="#B9B9B9">mdi-menu</v-icon>
        <span class="ml-3 mt-auto mb-auto mr-auto">Control Panel</span>
        <v-btn icon class="vis-data-back" @click="hideMenu">
          <v-icon> mdi-chevron-left </v-icon>
        </v-btn>
      </div>
      <div class="position-relative">
        <div class="filter-icon" v-if="activeTab == 0">
          <filterAdd @click.native="enableDsFilter = true" />
        </div>
        <div v-else>
          <span class="filter-switch-text">Edit Mode</span>
          <v-switch class="filter-switch" dense></v-switch>
          <v-icon class="filter-chart" small @click="enableChartList = true">
            mdi-chart-box-plus-outline
          </v-icon>
        </div>
        <v-tabs height="33px" color="white" v-model="activeTab">
          <v-tab>Filters</v-tab>
          <v-tab>Charts</v-tab>
          <v-tab-item>
            <div class="filter-tab">
              <div class="filter-tab-header">Search</div>
              <v-text-field
                hide-details
                no-filter
                dense
                solo
                outlined
                hide-no-data
                prepend-inner-icon="mdi-magnify"
              />
            </div>
            <div class="filter-container">
              <template v-for="(filter, index) in filters" >
                <div :key="index">
                  {{filter.column.column_name}}
                </div>
              </template>
            </div>
          </v-tab-item>
          <v-tab-item>
            <div class="filter-tab">
              <div class="filter-tab-header">Search</div>
              <v-text-field
                hide-details
                no-filter
                dense
                solo
                outlined
                hide-no-data
                prepend-inner-icon="mdi-magnify"
              />
            </div>
            <div class="chart-container">
              <template v-for="(chart, index) in selectedCharts">
                <div class="d-flex chart-item" :key="index">
                  <div v-if="chart.viz_type == 'pie'" class="d-flex">
                    <Pie class="chart-item-icon" />
                  </div>
                  <div v-if="chart.viz_type == 'heatmap'" class="d-flex">
                    <v-icon aria-hidden="false"> mdi-heat-pump </v-icon>
                  </div>
                  <div v-if="chart.viz_type == 'table'" class="d-flex">
                    <v-icon aria-hidden="false"> mdi-table </v-icon>
                  </div>
                  <div v-if="chart.viz_type == 'bubble'" class="d-flex">
                    <v-icon aria-hidden="false">mdi-chart-bubble</v-icon>
                  </div>
                  <div class="chart-item-text mt-auto mb-auto">
                    {{ chart.slice_name }}
                  </div>
                  <v-icon class="ml-auto" right>
                    mdi-close-circle-outline
                  </v-icon>
                </div>
              </template>
            </div>
          </v-tab-item>
        </v-tabs>
        <div class="action d-flex">
          <v-btn class="ml-auto" plain height="30" min-width="90">Apply</v-btn>
          <v-btn color="primary" height="30" min-width="90" @click="saveAction">
            Save
          </v-btn>
        </div>
      </div>
      <div
        class="dashboard-filter"
        v-if="enableDsFilter"
        v-click-outside="disableDsFilter"
      >
        <div class="dashboard-filter-header">Dashboard Filter</div>
        <div class="dashboard-filter-dataset">
          <div class="dashboard-filter-label">Dataset</div>
          <v-combobox
            solo
            dense
            :items="datasets"
            :item-text="(obj) => obj['name']"
            :item-value="(obj) => obj['name']"
            v-model="dataset"
            outlined
            hide-details
            height="40px"
            :return-object="true"
            @change="changeDataset"
          >
            <template v-slot:selection="data">
              {{ data.item.name }}
            </template>
          </v-combobox>
        </div>
        <div class="dashboard-filter-column">
          <div class="dashboard-filter-label">Column</div>
          <v-combobox
            solo
            :items="columns"
            v-model="column"
            :item-text="(obj) => obj['column_name']"
            :item-value="(obj) => obj['column_name']"
            outlined
            hide-details
            height="40px"
            :return-object="true"
          >
            <template v-slot:selection="data">
              {{ data.item.column_name }}
            </template>
          </v-combobox>
        </div>
        <div class="dashboard-filter-operator">
          <div class="dashboard-filter-label">Operator</div>
          <v-combobox
            solo
            :items="operators"
            v-model="operator"
            :return-object="true"
            outlined
            hide-details
            height="40px"
          />
        </div>
        <div class="dashboard-filter-filter">
          <div class="dashboard-filter-label">Filter Variable optional</div>
          <v-text-field
            outlined
            dense
            background-color="#080808"
            height="40px"
            v-model="filter_val"
          />
        </div>
        <div class="d-flex">
          <v-btn
            color="white"
            width="96px"
            height="36px"
            outlined
            @click="enableDsFilter = false"
          >
            Cancel
          </v-btn>
          <v-btn
            class="dashboard-filter-save"
            color="primary"
            @click="saveFilter"
            height="36px"
            width="96px"
          >
            Save
          </v-btn>
        </div>
      </div>
      <div
        class="chart-list"
        v-if="enableChartList"
        v-click-outside="disableChartlist"
      >
        <div class="chart-list-container">
          <div class="d-flex">
            <div class="d-flex align-center">
              <v-text-field
                ref="searchInput"
                hide-details
                label="Geo"
                no-filter
                dense
                solo
                outlined
                hide-no-data
                prepend-inner-icon="mdi-magnify"
                class="hidden-sm-and-down custom-class ml-auto"
                style="max-width: 300px"
              >
              </v-text-field>
            </div>
            <div class="d-flex align-center ml-7">
              <div class="overline mr-2">sort by</div>
              <v-select
                v-model="curSortField"
                :items="sortFields"
                item-text="txt"
                item-value="field"
                label="Sort"
                solo
                outlined
                hide-details="true"
                class="mr-2"
                style="width: 150px"
                v-on:change="chartLoad"
              ></v-select>

              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    v-bind="attrs"
                    v-on="on"
                    :class="!viewGrid && 'inactive'"
                  >
                    <v-icon size="18" @click="viewGrid = !viewGrid">
                      mdi-view-grid
                    </v-icon>
                  </v-btn>
                </template>
                <span>Grid View</span>
              </v-tooltip>

              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    v-bind="attrs"
                    v-on="on"
                    :class="viewGrid && 'inactive'"
                  >
                    <v-icon size="24" @click="viewGrid = !viewGrid">
                      mdi-view-list
                    </v-icon>
                  </v-btn>
                </template>
                <span>List View</span>
              </v-tooltip>
            </div>
          </div>
          <div v-if="viewGrid">
            <v-container>
              <v-row class="chart-list-row">
                <BoxItem
                  v-for="item in getRecentModels(collection)"
                  v-bind:key="item.id"
                  :item="item"
                  :class-name="`slice`"
                  :title-field="`slice_name`"
                  :selectable="true"
                  @toggled="toggleFav"
                  @checked="toggleChecked"
                  @clickItem="toggleChecked"
                />
              </v-row>
            </v-container>
          </div>
          <div
            v-else
            class="d-flex flex-grow-1 flex-column flex-basis-0 fill-height overflow-x-auto chart-list-row"
          >
            <v-data-table
              id="tblItems"
              v-model="selectedItems"
              :items="getRecentModels(collection)"
              :headers="headers"
              :search="search"
              @click:row="clickItem"
              @item-selected="updateGridViewSelection"
              show-select
              item-key="id"
              class="mt-3"
              :hide-default-footer="true"
              :header-props="{ sortIcon: 'mdi-chevron-down' }"
            >
              <template #[`item.slice_name`]="{ item }">
                <div @click.stop class="actions">
                  <a @click="clickItem(item)">{{ item.slice_name }}</a>
                </div>
              </template>
              <template #[`item.created_by.username`]="{ item }">
                <div v-if="item.created_by" class="actions">
                  <div
                    class="chips"
                    :class="{ 'bg-green': item.created_by.username }"
                  >
                    {{ item.created_by.username.substring(0, 2).toUpperCase() }}
                  </div>
                </div>
              </template>
            </v-data-table>
          </div>
        </div>
        <div class="chart-list-action">
          <v-btn
            color="white"
            outlined
            @click="enableChartList = false"
            height="32px"
            width="94px"
            class="ml-auto mr-2"
          >
            Cancel
          </v-btn>
          <v-btn color="primary" width="96px" height="32px" @click="saveChart">
            Save
          </v-btn>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BoxItem from "@/views/_partials/BoxItem";
import filterAdd from "../../../components/svg/filterAdd.vue";
import Pie from "../../../components/svg/pie.vue";
import {
  GET_DASHBOARD_DATASET_BY_ID,
  GET_CHART_ALL,
  GET_USERS_ALL,
  UNSET_FAV,
  SET_FAV,
} from "@/store/actionType";

export default {
  components: {
    filterAdd,
    BoxItem,
    Pie,
  },
  data() {
    return {
      //collapse project nav left menu
      collapse: false,
      //active tab
      activeTab: 0,
      //enable & disable dashboard filter
      enableDsFilter: false,
      //operator
      operators: [
        "==",
        "!==",
        ">",
        "<",
        "<=",
        ">=",
        "IN",
        "NOT IN",
        "LIKE",
        "ILIKE",
        "IS NOT NULL",
        "IS NULL",
      ],
      //operator
      operator: ">",
      //datasets
      datasets: [],
      //dataset
      dataset: null,
      //columns
      columns: [],
      //column
      column: null,
      //filter variable
      filter_val: null,
      //dashboard id
      id: null,
      //enable chart list
      enableChartList: false,
      //view grid in chart list
      viewGrid: true,
      curSortField: "",
      sortFields: [
        { txt: "Title", field: "dashboard_title" },
        { txt: "Date", field: "changed_on_utc" },
      ],
      collection: [],
      selectedItems: [],
      headers: [
        { text: "Title", value: "slice_name" },
        { text: "Visualization type", value: "viz_type" },
        { text: "Dataset", value: "datasource_type" },
        { text: "Created By", value: "created_by.username" },
        { text: "Last Modified", value: "changed_on_delta_humanized" },
      ],
      search: "",
    };
  },
  props: {
    selectedCharts: {
      type: Array,
      default() {
        return [];
      },
    },
    filters: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  mounted() {
    this.id = this.$route.params.id;
    this.getData(this.id);
    this.chartLoad();
  },
  methods: {
    showMenu() {
      this.collapse = false;
    },
    hideMenu() {
      this.collapse = true;
    },
    /**
     * disable dashboard filter
     */
    disableDsFilter(event) {
      let findOp = this.operators.find(
        (item) => item === event.target.innerText
      );
      let findDataset = this.datasets.find(
        (item) => item.name === event.target.innerText
      );
      let findCol = this.columns.find(
        (item) => item.column_name === event.target.innerText
      );
      if (!findOp && !findDataset && !findCol) {
        this.enableDsFilter = false;
      }
    },
    /**
     * save filter
     */
    saveFilter() {
      console.log(this.dataset, this.column, this.operator, this.filter_val);
      const data = {
        dataset: this.dataset, 
        column: this.column, 
        operator: this.operator, 
        filter_val: this.filter_val
      }
      this.$emit("saveFilter", data);
      this.enableDsFilter = false;
    },
    /**
     * get initial data
     * @param id
     */
    getData(id) {
      this.$store.dispatch(GET_DASHBOARD_DATASET_BY_ID, id).then((res) => {
        this.datasets = res.data.result;
        if (this.datasets.length > 0) {
          this.dataset = this.datasets[0];
          this.columns = this.dataset.columns;
          this.column = this.columns[0];
        }
      });
    },
    /**
     * change dataset
     * @param dataset
     */
    changeDataset(dataset) {
      this.dataset = dataset;
      this.columns = this.dataset.columns;
      this.column = this.columns[0];
    },
    /**
     * disable chart list
     */
    disableChartlist() {
      this.enableChartList = false;
    },
    /**
     * chart load
     */
    chartLoad() {
      const columns = [
        "id",
        "changed_by.username",
        "created_by.first_name",
        "created_by.last_name",
        "created_by.username",
        "changed_on_utc",
        "changed_on_delta_humanized",
        "slice_name",
        "viz_type",
        "datasource_type",
      ];

      const q = {
        columns: columns,
        order_column: this.curSortField,
        order_direction: "desc",
      };

      const favQ = {
        ...q,
        filters: [
          {
            col: "id",
            opr: "chart_is_favorite",
            value: "!t",
          },
        ],
      };

      // Load Recent Item
      let recentArr = [];
      this.$store
        .dispatch(GET_CHART_ALL, q)
        .then((r) => {
          recentArr = r.data.result;

          // Load Favorite Charts
          return this.$store.dispatch(GET_CHART_ALL, favQ);
        })
        .then((favResult) => {
          this.collection = this.mergeFavItems(recentArr, favResult);
        })
        .catch((r) => {
          console.error("We have error:", r);
        });

      // Load Users (Owners)
      this.$store
        .dispatch(GET_USERS_ALL)
        .then((r) => {
          this.users = r.data.result.Users;
        })
        .catch((r) => {
          console.error("We have error:", r);
        });
    },
    mergeFavItems(recentArr, favResult) {
      let favIds = favResult.data.ids;

      for (let index in favResult.data.result) {
        favResult.data.result[index]["favorite"] = true;
      }
      for (let index in recentArr) {
        const _index = favIds.indexOf(recentArr[index].id);
        if (_index === -1) {
          recentArr[index]["favorite"] == false;
          recentArr[index]["recent"] = true;
          favResult.data.result.push(recentArr[index]);
        } else {
          favResult.data.result[_index]["recent"] = true;
        }
      }
      return favResult.data.result;
    },
    getRecentModels(arr) {
      const filteredArr = arr.filter(function (item) {
        return item.recent === true;
      });
      return filteredArr;
    },
    toggleFav(item, className) {
      const param = {
        id: item.id,
        className: className,
      };

      let collection = this.collection;
      let itemIndex = collection.indexOf(item);

      if (item.favorite === true) {
        this.$store
          .dispatch(UNSET_FAV, param)
          .then((r) => {
            item.favorite = false;
            let editedItem = { ...item };
            collection.splice(itemIndex, 1, editedItem);
          })
          .catch((r) => {
            console.error("We have error: ", r);
          });
      } else {
        this.$store
          .dispatch(SET_FAV, param)
          .then((r) => {
            item.favorite = true;
            let editedItem = { ...item };
            collection.splice(itemIndex, 1, editedItem);
          })
          .catch((r) => {
            console.error("We have error: ", r);
          });
      }
    },
    /**
     * Update the selection status in Grid View
     */
    toggleChecked(item) {
      let index = this.selectedItems.findIndex((_item) => _item.id === item.id);
      if (item.checked === true) {
        if (index == -1) {
          this.selectedItems.push(item);
        }
      } else {
        if (index > -1) {
          this.selectedItems.splice(index, 1);
        }
      }
    },
    /**
     * click item in table
     * @param item
     */
    clickItem(item) {},
    /**
     * Update the selection status in Grid View
     */
    updateGridViewSelection(obj) {
      let selItem = this.collection.find((item) => item.id === obj.item.id);
      if (selItem) {
        selItem.checked = obj.value;
      }
    },
    /**
     * save chart list
     */
    saveChart() {
      this.$emit("saveChart", this.selectedItems);
      this.enableChartList = false;
    },
    /**
     * save all info
     */
    saveAction() {
      this.$emit("saveAction");
    },
  },
};
</script>

<style lang="scss" scoped>
.project-nav {
  position: relative;
  border-right: 0.5px solid #373737;
  background: #262626;

  &-show {
    width: 240px;
  }

  &-hide {
    width: 30px;

    &-tab {
      writing-mode: tb-rl;
      transform: rotate(-180deg);
      height: 75px;
      text-align: center;
      margin-left: auto;
      margin-right: auto;
      cursor: pointer;
      color: #747474;
      line-height: 30px;

      &.active {
        color: #ffffff;
        border-left: 2px solid #ffffff;
      }
    }
  }
}

.filter-icon {
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 1000;
  cursor: pointer;
}

.filter-tab {
  margin-top: 1.5rem;
  padding: 0.5rem 0.5rem 1.5rem 0.5rem;
  border-top: 0.5px solid #373737;
  border-bottom: 0.5px solid #373737;

  &-header {
    color: #a2a2a2;
    margin-bottom: 1rem;
    text-transform: uppercase;
  }
}

.filter-switch {
  position: absolute;
  right: 12px;
  top: 10px;
  z-index: 1000;
  margin-top: 0;

  &-text {
    position: absolute;
    right: 10px;
    top: -8px;
    z-index: 1000;
  }
}

.filter-chart {
  position: absolute;
  right: 3px;
  top: 12px;
  z-index: 1000;
}

.action {
  position: fixed;
  bottom: 0;
  width: 240px;
  padding: 10px;
}

.dashboard-filter::before {
  position: absolute;
  content: "";
  top: 3px;
  left: -12px;
  transform: rotate(270deg);
  width: 10px;
  height: 13px;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 15px solid #303030;
}

.dashboard-filter {
  z-index: 1000;
  position: absolute;
  background: #303030;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 4px;
  top: 45px;
  left: 252px;
  width: 220px;
  padding: 12px;
  height: 441px;

  &-header {
    font-size: 16px;
  }

  &-save {
    margin-left: 5px;
  }

  &-label {
    margin-bottom: 9px;
    margin-top: 14px;
  }
}

.chart-list::before {
  position: absolute;
  content: "";
  top: 3px;
  left: -12px;
  transform: rotate(270deg);
  width: 10px;
  height: 13px;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 15px solid #303030;
}

.chart-list {
  position: absolute;
  z-index: 1000;
  background: #303030;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 4px;
  top: 45px;
  left: 252px;
  width: 640px;
  padding: 18px;
  height: 725px;

  &-header {
    font-size: 16px;
  }

  &-save {
    margin-left: 5px;
  }

  &-label {
    margin-bottom: 9px;
    margin-top: 14px;
  }

  &-container {
    background: #121212;
    padding: 5px;
  }

  &-row {
    height: 590px;
    overflow-y: auto;
    @include scrollbars(0.4rem, #717171, #00000080);
  }

  &-action {
    display: flex;
    margin-top: 1.5rem;
  }
}

.inactive {
  color: rgba(255, 255, 255, 0.3) !important;
}
.inactive:hover {
  color: inherit !important;
}

.chart-container {
  padding: 10px;
}

.chart-item {
  height: 32px;
  &-icon {
    width: 25px;
    height: 25px;
  }

  &-text {
    margin-left: 1rem;
  }
}
</style>