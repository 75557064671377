import AsyncService from "../services/AsyncService";

import {
  GET_SPADE_JOB_STATUS,  
} from "./actionType";

const state = {};

const actions = {
  [GET_SPADE_JOB_STATUS](_, id) {
    return AsyncService.getSpadeJobStatus(id).then(
      res => {
        return res;
      }
    )
  },
};

const mutations = {};

export default {
  state,
  actions,
  mutations
}