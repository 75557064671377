<template>
  <div class="d-flex flex-row logo">
    <div class="logo-box d-flex" @click="goToHome" :class="mini ? 'mini' : ''">
      <img alt="Forecastagility" src="@/assets/logo.svg" class="logo-img" />
      <p v-if="!mini" class="logo-text">Forecastagility</p>
    </div>
    <div class="minimize" @click="expandMinimize">
      <sidebarMin v-if="mini" />
      <sidebarExp v-else />
    </div>
  </div>
</template>

<script>
import sidebarExp from "../svg/sidebarExp.vue";
import sidebarMin from "../svg/sidebarMin.vue"
export default {
  components: {
    sidebarExp,
    sidebarMin
  },
  props: {
    mini: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    expandMinimize() {
      this.$emit("expandMinimize")
    },
    goToHome() {
      this.$emit('goToHome')
    }
  }
};
</script>

<style>
.logo {
  padding: 12px;
  cursor: pointer;
}

.logo-box {
  padding: 12px 11px 16px 11px;
}

.logo-box.mini {
   padding: 12px 11px 16px 0px;
}

.logo-img {
  height: 24px;
  margin-right: 5px;
}

.logo-text {
  margin-bottom: 0 !important;
  font-size: 20px;
}

.minimize {
  position: absolute;
  top: 55px;
  right: -25px;
  height: 59px;
}
</style>