<template>
  <div class="action d-flex">
    <!-- Apply btn -->
    <!-- {{ validVisCols }} -->
    <div class="d-flex mr-2">
      <v-btn
        plain
        outlined
        height="30"
        min-width="90"
        :disabled="requiredColsFilled"
        @click="$emit('applyQuery')"
      >
        Apply
      </v-btn>
    </div>
    <!-- save btn -->
    <div class="d-flex">
      <v-btn
        :disabled="requiredColsFilled"
        color="primary"
        height="30"
        min-width="90"
        @click="$emit('saveChart')"
      >
        Save
      </v-btn>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    // passing required axis status
    validVisCols: {
      type: Object,
      default() {
        return null;
      },
    },
    // ?
    actions: {
      type: Boolean,
      default() {
        return null;
      },
    },
  },
  computed: {
    requiredColsFilled() {
      // return false
      return !Object.values(this.validVisCols).every(Boolean)
    },
  }
};
</script>

<style lang="scss" scoped>
.action {
  opacity: .9;
  background-color: #262626;
  justify-content: center;
  width: 240px;
  // position
  // position: relative;
  // transform: translateY(-100%);
  position: fixed;
  bottom: 0px;
  // left: 240px;

  border-top: 0.5px solid #373737;
  padding-top: 12px;
  padding-bottom: 10px;
}
</style>