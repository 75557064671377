import DatasetService from "../services/DatasetService";

import {
  GET_DATASET_BY_ID,
  GET_DATASET_DATA_BY_ID,
  GET_DATASET_ALL,
  GET_DATASET_FAVSTATUS_ALL,
  CREATE_DATASET,
  UPDATE_DATASET,
  DELETE_DATASET,
  DELETE_DATASET_MULTI
} from "./actionType";

const state = {};

const actions = {
  async [GET_DATASET_BY_ID](_, id) {
    return DatasetService.get(id).then(
      res => {
        return res;
      }
    )
  },

  [GET_DATASET_DATA_BY_ID](_, params) {
    return DatasetService.getData(params.id, params.q).then(
      res => {
        return res;
      }
    )
  },
  [GET_DATASET_ALL](_, q) {
    return DatasetService.getAll(q).then(
      res => {
        return res;
      }
    )
  },
  [GET_DATASET_FAVSTATUS_ALL](_, q) {
    return DatasetService.getFavStatusAll(q).then(
      res => {
        return res;
      }
    )
  },
  [CREATE_DATASET](_, params) {
    return DatasetService.create(params).then(
      res => {
        return res;
      }
    )
  },
  [UPDATE_DATASET](_, id, params) {
    return DatasetService.update(id, params).then(
      res => {
        return res;
      }
    )
  },
  [DELETE_DATASET](_, id) {
    return DatasetService.delete(id).then(
      res => {
        return res;
      }
    )
  },
  [DELETE_DATASET_MULTI](_, q) {
    return DatasetService.deleteMulti(q).then(
      res => {
        return res;
      }
    )
  }
};

const mutations = {};

export default {
    state,
    actions,
    mutations
}