import Vue from "vue";
import Vuetify from "vuetify/lib";
// import { VerticalBarIcon } from "@spectrum-web-components/icons-workflow/";
import barChart from "@/components/svg/barChart.vue";
import Bubble from "@/components/svg/bubble.vue";
import abcIcon from "@/components/svg/IconAbc.vue";
import tableIcon from "@/components/svg/tableIcon.vue";
import hbarIcon from "@/components/svg/hbarIcon.vue";
import pieIcon from "@/components/svg/pieIcon.vue";
import boxplotIcon from "@/components/svg/boxplotIcon.vue";
import itemKeyIcon from "@/components/svg/ItemKey.vue";
import beakerIcon from "@/components/svg/beakerIcon.vue";
import beakerCheckIcon from "@/components/svg/beakerCheckIcon.vue";
import magicIcon from "@/components/svg/magic.vue";
import algoIcon from "@/components/svg/algoIcon.vue";
import scatterIcon from "@/components/svg/scatterIcon.vue";

Vue.use(Vuetify,{
  options: {
    customProperties: true
  }
});
// export default new Vuetify({
//   theme: { dark: true },
// })

export default new Vuetify({
  theme: {
    dark: true,
    themes: {
      dark: {
        primary: "#1473E6", // #E53935
        modal_background: "#262626",
        secondary: "#FFCDD2",//colors.red.lighten4, // #FFCDD2
        hover_blue: "#2987F8",
        //accent: colors.indigo.base, // #3F51B5
        grey_text: "#B9B9B9",
        snackbar_success: "#00BAB9",
        snackbar_warning: "#FFBB33",
      },
    },
  },
  icons: {
    values: {
      barChart: { 
        component: barChart, 
      },
      bubbleChart: {
        component: Bubble, 
      },
      abcIcon: {
        component: abcIcon, 
      },
      tableIcon: {
        component: tableIcon, 
      },
      hbarIcon: {
        component: hbarIcon, 
      },
      pieIcon: {
        component: pieIcon, 
      },
      boxplotIcon: {
        component: boxplotIcon, 
      },
      itemKeyIcon: {
        component: itemKeyIcon, 
      },
      beakerIcon: {
        component: beakerIcon, 
      },
      beakerCheckIcon: {
        component: beakerCheckIcon, 
      },
      magicIcon: {
        component: magicIcon, 
      },
      algoIcon: {
        component: algoIcon, 
      },
      scatterIcon: {
        component: scatterIcon
      }
    },
  },
});
