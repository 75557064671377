

// queryTabObject: {
//   name: "",
//   active: null, // Boolean
//   id: "",//uuidv4(),
//   code: "-- sql Explorer",
//   database: null,
//   schema: "",
//   table: "",
//   tableData: null,
//   queryResults: null,
// },

import { v4 as uuidv4 } from "uuid";

export default class SqlTab {
  id=""
  name=""
  active=null
  code="-- sql Explorer"
  tableData={}
  database=null
  schema=""
  schemas=[]
  table=""
  tableData=null
  queryResults=null

  constructor(name)
  {
    this.name = name;
    this.id = uuidv4()
  }

  // getDatbase() {
    // return this.databaseObject
  // }
}