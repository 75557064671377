<template>
  <v-app-bar
    app
    :outlined="true"
    height="auto"
    style="z-index: 101"
  >    
    <v-text-field
      v-if="$store.state.auth.initialState.status.loggedIn"
      ref="searchInput"
      hide-details
      label="Search your projects"
      no-filter
      dense
      solo
      outlined
      hide-no-data
      prepend-inner-icon="mdi-magnify"
      class="hidden-sm-and-down mx-auto custom-class"
    >
    </v-text-field>
    <v-spacer />

<!--    <v-btn color="transparent" class="mr-2" height="30" v-if="$store.state.auth.initialState.status.loggedIn">-->
<!--      <v-icon left> mdi-plus </v-icon>-->
<!--      Invite members-->
<!--    </v-btn>-->
<!--    <router-link :to="`/projects/new`" v-if="$store.state.auth.initialState.status.loggedIn">-->
<!--      <v-btn color="primary" class="mr-2" height="30"> New Project </v-btn>-->
<!--    </router-link>-->

    <v-btn icon small v-if="$store.state.auth.initialState.status.loggedIn">
      <v-icon small>mdi-bell</v-icon>
    </v-btn>
    <v-menu
      v-if="$store.state.auth.initialState.status.loggedIn"
      v-model="logoutMenu"
      :close-on-content-click="false"
      :nudge-width="200"
      offset-y
    >
      <template #activator="{ on, attrs }">
        <v-btn v-bind="attrs" icon small class="ml-1 mr-1" v-on="on">
          <v-icon>mdi-dots-vertical</v-icon>
        </v-btn>
      </template>
      <v-card>
        <v-list>
          <v-list-item>
            <v-list-item-avatar color="teal">
              <span class="white--text text-h6 font-weight-regular mx-auto">{{
                initials
              }}</span>
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title>{{ user.name }}</v-list-item-title>
              <v-list-item-subtitle>{{ user.email }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn text elevation="1" outlined class="btn-logout" @click="logout">
            Logout
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-menu>
  </v-app-bar>
</template>

<script>
import { mapState } from "vuex";
import { extractInitials } from "./../utils/stringUtils";
import { LOGOUT } from "@/store/actionType";

export default {
  name: "AppHeader",
  data() {
    return {
      logoutMenu: false,
    };
  },
  methods: {
    logout() {
      this.$store.dispatch(LOGOUT);
      this.$router.push("/login");
      this.logoutMenu = false;
    },
  },

  computed: {
    ...mapState({
      user: (state) => state.auth.initialState.user,
    }),
    initials() {
      return extractInitials(this.user.name);
    },
  },
};
</script>

<style scoped>
.theme--dark.v-app-bar.v-toolbar.v-sheet {
  background: #121212;
}

.v-app-bar.v-toolbar.v-sheet {
  border: none !important;
  border-bottom: solid 1px rgba(255, 255, 255, 0.12) !important;
  height: 48px;
}

.v-toolbar__title {
  display: flex;
  align-content: center;
  width: 204px !important;
}

.v-menu__content--fixed {
  margin-top: 8px;
}

.btn-logout {
  margin: 8px auto 10px;
  padding-left: 32px !important;
  padding-right: 32px !important;
  text-transform: initial;
  letter-spacing: normal;
}

.logo {
  height: 24px !important;
}
</style>
