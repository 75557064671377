<template>
  <div>
    <ModuleAction
      class="pa-0 pt-3"
      title="Dashboard"
      search-label="Search Dashboard Titles"
      :selectedItems="selectedItemIds"
      :newDropdown="true"
      :n_items="filteredItemCount"
      @clickNewItem="clickNewDashboard"
      @deleteAll="deleteAll(`dashboards`)"
      @selectItemsEvent="selectItemsEvent"
      @menuClick="menuClick"
      @moduleFilterChanged="moduleFilterChanged"
    />
    <!-- charts grid view -->
    <div v-if="viewGrid">
      <v-container class="pa-0 pt-3">
        <v-row class="container-row-resolution mt-3">
          <!-- @toggled="toggleFav" -->
          <BoxItem
            v-for="item in collection"
            v-bind:key="item.id"
            :item="item"
            :class-name="`dashboard`"
            :title-field="`title`"
            :selectable="true"
            @checked="toggleChecked"
            @clickItem="clickDashboard"
          />
          <!-- Change clickDashboard to clickItem('dashboard') -->
        </v-row>
      </v-container>
    </div>
    <!-- dashboard list view -->
    <div
      v-if="!viewGrid"
      class="
        d-flex
        flex-grow-1 flex-column flex-basis-0
        fill-height
        overflow-x-auto
      "
    >
      <v-data-table
        id="tblDashboards"
        v-model="selectedItems"
        :items="collection"
        :headers="dashboardHeaders"
        :search="searchDashboard"
        @click:row="clickDashboard"
        @item-selected="updateGridViewSelection"
        @toggle-select-all="toggleSelectAll"
        show-select
        item-key="id"
        class="mt-3"
        :hide-default-footer="true"
        :header-props="{ sortIcon: 'mdi-chevron-down' }"
      >
        <template v-slot:top="{ pagination, options, updateOptions }">
          <v-data-footer
            :pagination="pagination"
            :options="options"
            @update:options="updateOptions"
            items-per-page-text="$vuetify.dataTable.itemsPerPageText"
          />
        </template>
        <template v-slot:[`header.favorite`]="{ header }">
          <v-icon v-if="header.favorite">mdi-star</v-icon>
          <v-icon v-else>mdi-star-outline</v-icon>
        </template>
        <template #[`item.favorite`]="{ item }">
          <!-- <div @click.stop="toggleFav(item, 'slice')" class="actions"> -->
          <div class="actions">
            <v-btn icon width="24" v-tippy content="Favorite">
              <v-icon v-if="item.favorite">mdi-star</v-icon>
              <v-icon v-else>mdi-star-outline</v-icon>
            </v-btn>
          </div>
        </template>
        <template #[`item.action`]="{ item }">
          <RowAction
            :item="item"
            :disabledList="disabledRowActions"
            @clickItem="clickDashboard"
            @deleteItem="deleteItem"
          />
        </template>
      </v-data-table>
    </div>
    <div>
      <!-- v-model="dashboard_select_modal" -->
      <SelectDashboards
        :projectMeta="project"
        v-model="dashboard_select_modal"
        @reloadProject="$emit('reloadProject', project.id)"
        @showErrorSnackbar="snackbar=true"
        @closeDialog="closeDialog"
      />
    </div>
    <v-snackbar
      v-model="snackbar"
      color="#ffbb33"
    > Failed to add dashboard(s) to Project
      <template v-slot:action="{ attrs }">
        <v-btn
          color="#3366FF"
          v-bind="attrs"
          @click="snackbar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import BoxItem from "@/views/_partials/BoxItem";
import RowAction from "@/views/_partials/RowAction";
import ModuleAction from "@/views/_partials/ModuleAction";
import SelectDashboards from "./modals/SelectDashboards";

import {
  // GET_PROJECT_BY_ID,
  // SET_FAV,
  // UNSET_FAV,
  // GET_FAV_STATUS_ALL,
  REMOVE_PROJECT_CHILDREN,
} from "@/store/actionType";

import { 
    toggleSelectAll,
    modifyAllCheck,
    selectItemsEvent,
    filteredObjs,
  } from "@/utils/moduleUtils.js";
// import { objectEach } from 'highcharts';

export default {
  name: "Dashboard-Detail",
  components: {
    ModuleAction,
    RowAction,
    BoxItem,
    SelectDashboards,
  },
  data: () => ({
    moduleFilter:'',
    snackbar: false,
    selectedItems: [],
    searchDashboard: "",
    dashboard_select_modal: false,
    dashboardHeaders: [
      { text: "Favorite", value: "favorite", width: 75 },
      { text: "Title", value: "title" },
      { text: "Slices", value: "slice_count" },
      { text: "Last Modified", value: "changed_on_delta_humanized" },
      { text: "Last Modified By", value: "changed_by" },
      { text: "Created by", value: "created_by" },
      { text: "Actions", value: "action", width: 200, sorteble: false },
    ],
    disabledRowActions: {
      'edit':true, 
      'info':true, 
      'trashcan':false, 
      'action': true
    },
  }),
  props: {
    project: {
      type: Object,
      default: null,
    },
    viewGrid: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    filteredItemCount () {
      return [this.project.dashboard_metadata.length, this.collection.length]
    },
    collection() {
      let arr = this.project.dashboard_metadata;
      // console.log('computed', arr)
      if (arr && this.moduleFilter.length > 0) {
        arr = this.filteredObjs(arr, 'title', this.moduleFilter)
      }
      return arr
      // return this.project.dashboard_metadata;
    },
    selectedItemIds () {
      // console.log('selitems', this.selectedItems)
      let id_list = this.selectedItems.map(a => a.id);
      return id_list;
    },
  },
  methods: {
    // Imports
    //////////
    //moduleUtils
    toggleSelectAll,
    modifyAllCheck,
    selectItemsEvent,
    filteredObjs,
    //////////
    closeDialog() {
      this.dashboard_select_modal=false;
    },
    moduleFilterChanged (input) {
      this.moduleFilter = input;
    },
    menuClick(item) {
      // console.log('menu', item)
      switch (item) {
        case 'Add Existing':
          this.clickNewDashboard();
          break
        case 'Create New':
          console.log('Create new dashboard')
          break
      }
    },
    deleteItem(item) {
      const className = "dashboards";
      const param = {
        className: className,
        id: this.project.id,
        q: [item.id],
      };
      this.$store
        .dispatch(REMOVE_PROJECT_CHILDREN, param)
        .then((r) => {
          this.$emit('reloadProject', this.project.id);
        })
        .catch((r) => {
          console.error("Error: ", r);
          this.snackbar=true;
        });
    },
    clickDashboard(item) {
      console.log("Need to confirm how to implement");
      // this.$router.push({ name: 'Charts-Detail', params: { id: item.id } });
    },
    clickNewDashboard() {
      console.log('vis select', this.project, this.dashboard_select_modal)
      this.dashboard_select_modal = true;
      console.log('vis post', this.dashboard_select_modal)
      // this.vis_type_modal = true;
    },
    deleteAll() {
      console.log('delete me', this.selectedItemIds)
      let selIds = this.selectedItemIds
      const param = {
        className: "dashboards",
        id: this.project.id,
        q: selIds,
      };
      // console.log('delete these', param)
      this.$store
        .dispatch(REMOVE_PROJECT_CHILDREN, param)
        .then(() => {
          this.$emit('reloadProject', this.project.id);
        })
        .catch((r) => {
          console.error("We have error:", r);
          this.$emit('showErrorSnackbar')
        });
        this.selectedItems=[];
    },
    /**
     * Update the selection status in Grid View
     */
    updateGridViewSelection(obj) {
      let selItem = this.collection.find((item) => item.id === obj.item.id);
      if (selItem) {
        selItem.checked = obj.value;
      }
    },
    /**
     * Update the selection status in Grid View
     */
    toggleChecked(item, type) {
      let index = this.selectedItems.findIndex((_item) => _item.id === item.id);
      if (item.checked === true) {
        if (index == -1) {
          this.selectedItems.push(item);
        }
      } else {
        if (index > -1) {
          this.selectedItems.splice(index, 1);
        }
      }
    },
  },
};
</script>