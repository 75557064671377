
<template>
  


<svg 
    id="Group_160402" 
    data-name="Group 160402" 
    xmlns="http://www.w3.org/2000/svg" 
    width="18" 
    height="18" 
    viewBox="0 0 18 18"
  >
  <rect id="Canvas" width="18" height="18" fill="#b9b9b9" opacity="0"/>
  <rect id="Rectangle_147774" data-name="Rectangle 147774" width="12" height="2" rx="0.447" transform="translate(3 16)" fill="#b9b9b9"/>
  <path id="Path_83568" data-name="Path 83568" d="M12.592,6H10.655A3,3,0,1,0,7.345,6H5.408A.408.408,0,0,0,5,6.408V7.591A.408.408,0,0,0,5.408,8H7.5L6,15h6L10.5,8h2.092A.408.408,0,0,0,13,7.592V6.408A.408.408,0,0,0,12.592,6Z" fill="#b9b9b9"/>
</svg>

</template>


<script>
export default {
  name: "ItemKeyIcon",
  props: {
    fill: {
      type: String,
      default() {
        return "#B9B9B9";
      },
    },
  },
};
</script>

<style>
.fill {
  fill: #B9B9B9;
  /* fill: blue; */
}
</style>