var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-navigation-drawer',{attrs:{"clipped":_vm.$vuetify.breakpoint.lgAndUp,"mini-variant":_vm.mini,"app":"","width":"220","mini-variant-width":"50"},on:{"update:miniVariant":function($event){_vm.mini=$event},"update:mini-variant":function($event){_vm.mini=$event}}},[_c('AppLogo',{attrs:{"mini":_vm.mini},on:{"expandMinimize":_vm.changeMini,"goToHome":_vm.goToHome}}),(!_vm.mini)?_c('div',{staticClass:"separator-logo"}):_vm._e(),_c('v-list',{staticClass:"my-4",attrs:{"dense":"","nav":""}},[_vm._l((_vm.mnuProjects),function(item){return [_c('v-hover',{key:item.text,scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [(_vm.mini)?_c('v-tooltip',{attrs:{"color":"#1BBAB9","right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-list-item',_vm._g(_vm._b({class:hover ? 'hover-item' : '',attrs:{"link":"","to":item.url}},'v-list-item',attrs,false),on),[_c('v-list-item-icon',{staticClass:"mr-4"},[_c('v-icon',{attrs:{"color":hover ? 'white' : 'grey'}},[_vm._v(_vm._s(item.icon))])],1),_c('v-list-item-content',[_c('v-list-item-title',{class:hover ? 'hover-title' : ''},[_vm._v(_vm._s(item.text))])],1)],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.text))])]):_c('v-list-item',{class:hover ? 'hover-item' : '',attrs:{"link":"","to":item.url}},[_c('v-list-item-icon',{staticClass:"mr-4"},[_c('v-icon',{attrs:{"color":hover ? 'white' : 'grey'}},[_vm._v(_vm._s(item.icon))])],1),_c('v-list-item-content',[_c('v-list-item-title',{class:hover ? 'hover-title' : ''},[_vm._v(_vm._s(item.text))])],1)],1)]}}],null,true)})]})],2),(_vm.mini)?_c('div',{staticClass:"separator-mini"}):_c('SeparatorText',{attrs:{"text":"Visualizations"}}),_c('v-list',{staticClass:"mt-3",attrs:{"dense":"","nav":""}},[_vm._l((_vm.mnuVisualizations),function(item){return [_c('v-hover',{key:item.text,scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [(_vm.mini)?_c('v-tooltip',{attrs:{"color":"#1BBAB9","right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-list-item',_vm._g(_vm._b({class:hover ? 'hover-item' : '',attrs:{"link":"","to":item.url}},'v-list-item',attrs,false),on),[_c('v-list-item-icon',{staticClass:"mr-4"},[_c('v-icon',{attrs:{"color":hover ? 'white' : 'grey'}},[_vm._v(_vm._s(item.icon))])],1),_c('v-list-item-content',[_c('v-list-item-title',{class:hover ? 'hover-title' : ''},[_vm._v(_vm._s(item.text))])],1)],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.text))])]):_c('v-list-item',{class:hover ? 'hover-item' : '',attrs:{"link":"","to":item.url}},[_c('v-list-item-icon',{staticClass:"mr-4"},[_c('v-icon',{attrs:{"color":hover ? 'white' : 'grey'}},[_vm._v(_vm._s(item.icon))])],1),_c('v-list-item-content',[_c('v-list-item-title',{class:hover ? 'hover-title' : ''},[_vm._v(_vm._s(item.text))])],1)],1)]}}],null,true)})]}),_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [(_vm.mini)?_c('v-tooltip',{attrs:{"color":"#1BBAB9","right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-list-item',_vm._g(_vm._b({class:hover ? 'hover-item' : '',attrs:{"link":"","to":"/sql-page"}},'v-list-item',attrs,false),on),[_c('v-list-item-icon',{staticClass:"mr-4"},[_c('sql',{attrs:{"hover":hover}})],1),_c('v-list-item-content',[_c('v-list-item-title',{class:hover ? 'hover-title' : ''},[_vm._v("Sql Explorer")])],1)],1)]}}],null,true)},[_c('span',[_vm._v("Sql Explorer")])]):_c('v-list-item',{class:hover ? 'hover-item' : '',attrs:{"link":"","to":"/sql-page"}},[_c('v-list-item-icon',{staticClass:"mr-4"},[_c('sql',{attrs:{"hover":hover}})],1),_c('v-list-item-content',[_c('v-list-item-title',{class:hover ? 'hover-title' : ''},[_vm._v("Sql Explorer")])],1)],1)]}}])})],2),(_vm.mini)?_c('div',{staticClass:"separator-mini"}):_c('SeparatorText',{attrs:{"text":"Data Sources"}}),_c('v-list',{staticClass:"my-4",attrs:{"dense":"","nav":""}},[_vm._l((_vm.mnuDatasources),function(item){return [_c('v-hover',{key:item.text,scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [(_vm.mini)?_c('v-tooltip',{attrs:{"color":"#1BBAB9","right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-list-item',_vm._g(_vm._b({class:hover ? 'hover-item' : '',attrs:{"link":"","to":item.url}},'v-list-item',attrs,false),on),[_c('v-list-item-icon',{staticClass:"mr-4"},[_c('v-icon',{attrs:{"color":hover ? 'white' : 'grey'}},[_vm._v(_vm._s(item.icon))])],1),_c('v-list-item-content',[_c('v-list-item-title',{class:hover ? 'hover-title' : ''},[_vm._v(_vm._s(item.text))])],1)],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.text))])]):_c('v-list-item',{class:hover ? 'hover-item' : '',attrs:{"link":"","to":item.url}},[_c('v-list-item-icon',{staticClass:"mr-4"},[_c('v-icon',{attrs:{"color":hover ? 'white' : 'grey'}},[_vm._v(_vm._s(item.icon))])],1),_c('v-list-item-content',[_c('v-list-item-title',{class:hover ? 'hover-title' : ''},[_vm._v(_vm._s(item.text))])],1)],1)]}}],null,true)})]})],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }