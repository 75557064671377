<template>
  <div>
    <!-- {{ validVisCols}} -->
    <!-- <div class="delete-me">
      <v-btn color="primary"
        @click="showForm"
      />
        <!- - Scatter: {{ this.mode_active_cols }} - ->
    </div> -->
    <div class="index">
      <div class="index-header d-flex">
        <div class="index-header-title">Index</div>
          <v-tooltip right>
            <template v-slot:activator="{ on, attrs }">
              <v-icon 
                small
                v-bind="attrs" 
                v-on="on"
                class="ml-auto" 
                :color="validVisCols.entity ? '#B9B9B9' : '#ffbb33'"
              >
                mdi-information-outline
              </v-icon>
            </template>
            <span style="color:#ffbb33">
              Required<br>
            </span>
            <span>
              Index to define each point on the plot<br>
              &ensp; <i>Categorical/Numerical</i><br>
          </span>
        </v-tooltip>
      </div>
      <div
        class="index-container"
        ref="indexCon"
        @dragover.prevent
        @drop="$emit('dragPillFinish', 'entity')"
      >
        <VizModePill v-if="entity"
          :column="getSimpleCol('entity')"
          @deleteCol="$emit('deletePill', 'entity')"
        />
      </div>
    </div>
    <!-- X-Axis -->
    <div class="xAxis">
      <!-- header -->
      <div class="xAxis-header d-flex">
        <div class="xAxis-header-title">X Axis</div>
        <v-tooltip right>
          <template v-slot:activator="{ on, attrs }">
            <v-icon 
              small
              v-bind="attrs" 
              v-on="on"
              :color="validVisCols.xAxis ? '#B9B9B9' : '#ffbb33'"
              class="ml-auto"
            >
              mdi-information-outline
            </v-icon>
          </template>
            <span style="color:#ffbb33"> 
              Required<br>
            </span>
          <span>
            Column to show on the horizontal axis<br/>
            <i>Numerical/Calculation</i><br/>
            &ensp; -	Usually the <i>independent</i> variable
          </span>
        </v-tooltip>
      </div>
      <div class="xAxis-container"
        ref="xAxisCon"
        @dragover.prevent
        @drop="$emit('dragComputedAxisFinish', 'x')"
      >
        <!-- pill/calc -->
        <div 
          class="d-flex"
          v-if="xAxis"
          :class="[showCalc.x ? 'size-menu': 'size-item']"
        >
          <!-- Pill and Calculation menu card -->
          <v-menu
            attach
            :value="showCalc.x"
            offset-x
            nudge-right="22px"
            nudge-top="153"
            :close-on-content-click="false"
            height="0"
            flat="true"
            :close-on-click="false"
          >    
          <template v-slot:activator="{ on }">
            <v-btn 
              v-on="on"
              class="mb-08"
              :outlined="!isApplied('x')"
              :color=" xAxis.column.type_generic ? '#1bbab9' : '#00A7FF' "
              rounded
              width="100%"
              height="24px"
              v-if="xAxis"
              @click.stop="on.click"
              @contextmenu.prevent="contextClickPill('x')"
            >
              {{ getComputedCol('xAxis').column_name }}
              <v-icon 
                class="ml-auto" right
                @click.prevent
                @click="removePillX('x')" 
              >
                mdi-close-circle
              </v-icon>
            </v-btn>
          </template>
            <CalculationCard
              v-if="showCalc.x"
              :calcStyle="'Calculation'"
              :form_data="form_data"
              :aggregations="getAggType(xAxis)"
              :size="xAxis"
              @disableCalcCard="disableSimpleCalcX"
              @saveSimpleCalcSize="saveSimpleCalcX"
            />
          </v-menu>
        </div>
      </div>
    </div>
    <div class="yAxis">
      <div class="yAxis-header d-flex">
        <div class="yAxis-header-title">Y Axis</div>
          <v-tooltip right>
            <template v-slot:activator="{ on, attrs }">
              <v-icon 
                small
                v-bind="attrs" 
                v-on="on"
                class="ml-auto" 
                :color="validVisCols.yAxis ? '#B9B9B9' : '#ffbb33'"
              >
                mdi-information-outline
              </v-icon>
            </template>
            <span style="color:#ffbb33">
              Required<br>
            </span>
            <span>
              Column to show on the vertical axis<br/>
              <i>Numerical/Calculation</i><br/>
              &ensp; -	Usually the <i>dependent</i> variable
          </span>
        </v-tooltip>
      </div>
      <div class="yAxis-container"
        ref="yAxisCon"
        @dragover.prevent
        @drop="$emit('dragComputedAxisFinish', 'y')"
      >
        <!-- pill/calc -->
        <div 
          class="d-flex"
          v-if="yAxis"
          :class="[showCalc.y ? 'size-menu': 'size-item']"
        >
          <!-- Pill and Calculation menu card -->
          <v-menu
            attach
            :value="showCalc.y"
            offset-x
            nudge-right="22px"
            nudge-top="153"
            :close-on-content-click="false"
            height="0"
            flat="true"
            :close-on-click="false"
          >    
          <template v-slot:activator="{ on }">
            <v-btn 
              v-on="on"
              class="mb-08"
              :outlined="!isApplied('y')"
              :color=" yAxis.column.type_generic ? '#1bbab9' : '#00A7FF' "
              rounded
              width="100%"
              height="24px"
              v-if="yAxis"
              @click.stop="on.click"
              @contextmenu.prevent="contextClickPill('y')"
            >
              {{ getComputedCol('yAxis').column_name }}
              <v-icon 
                class="ml-auto" right
                @click.prevent
                @click="removePillX('y')" 
              >
                mdi-close-circle
              </v-icon>
            </v-btn>
          </template>
            <CalculationCard
              v-if="showCalc.y"
              :calcStyle="'Calculation'"
              :form_data="form_data"
              :aggregations="getAggType(yAxis)"
              :size="yAxis"
              @disableCalcCard="disableSimpleCalcY"
              @saveSimpleCalcSize="saveSimpleCalcY"
            />
          </v-menu>
        </div>
      </div>
    </div>
    <div class="categories">
      <div class="categories-header d-flex">
        <div class="categories-header-title">Categories</div>
          <v-tooltip right>
            <template v-slot:activator="{ on, attrs }">
              <v-icon 
                small
                v-bind="attrs" 
                v-on="on"
                class="ml-auto" 
                color="#B9B9B9">
                mdi-information-outline
              </v-icon>
            </template>
            <span>
              Column that encodes intersection of the x and y variables<br>
              &ensp; <i>Categorical/Numerical</i><br>
              - Gives each point a distinct hue =><br>
              &ensp; Easily shows membership of each point to it's group<br>
              <br>
              -	Can be used to specify color and/or shape<br>
              -	If numerical column (color only) is used the hue scale will be continuous
          </span>
        </v-tooltip>
      </div>
      <div
        class="categories-container"
        @dragover.prevent
        @drop="$emit('dragPillFinish', 'series')"
        ref="categoryCon"
      >
        <div class="categories-item d-flex" v-if="series">
          <!-- Pallate Picker dialog -->
          <v-dialog
            v-model="showColorPicker"
            content-class="colorpicker-dialog"
          >
            <template v-slot:activator="{ on, attrs }">
            <v-icon 
              v-on="on"
              v-bind="attrs"
              color="#B9B9B9" 
              class="mr-auto" 
              @click.stop="on.click"
              @click="clickColorAdjust">
              mdi-palette
            </v-icon>
            </template>
            <v-card
              :style="[
                {'position': 'absolute'}, 
                {'top': this.colorDialogPos.top - 301 + 'px'}, 
                {'left': this.colorDialogPos.x + 'px'},
                ]"
              class="slider-card"
              width="520"
              height="270"
            >
              <v-card-title>Color Picker</v-card-title>
              <v-container fluid>
            <v-row
              justify="space-between"
            >
              <v-col cols="2">
                <v-subheader>
                  Color<br>Pallate
                </v-subheader>
              </v-col>
              <v-col cols="9">
              <v-select
                v-model="color_pallate"
                :items="color_schemes"
                :menu-props="{
                  top: true,
                  closeOnContentClick: true,
                  }"
                item-text="id"
                return-object
                dense
                @change="changePallate"
              > 
                <template v-slot:selection="{ item }">
                  <PallatePicker
                    :item=item
                  />
                  </template>   
                <template v-slot:item="{ item }">
                  <PallatePicker
                    :item=item
                  />
                </template>
              </v-select>
              </v-col>
            </v-row>
            </v-container>
            <v-divider/>
            
            <v-card-subtitle>Opacity</v-card-subtitle>
            <div class="opacity-slider">
            <v-slider
              v-model="bubbleOpacity"
              @end="changeOpacity"
              prepend-icon="mdi-circle-opacity"
              dense
              thumb-label
              :max=100
              :min=0
              :ticks="false"
            >
              <template v-slot:append>
                  <v-tooltip right>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        @click="resetOpacity"
                        v-bind="attrs"
                        v-on="on"
                      >
                      mdi-radiobox-marked
                      </v-icon>
                    </template>
                    <span>Reset Default</span>
                  </v-tooltip>
                </template>
            </v-slider>
            </div>
            <v-card-actions>
                <v-btn
                  class="ml-5"
                  color="primary"
                  @click="showColorPicker=false"
                  height="30px"
                  width="90px"
                >
                  Accept
                </v-btn>
            </v-card-actions>
            </v-card>
          </v-dialog>
          <VizModePill
            :column="getSimpleCol('series')"
            @deleteCol="$emit('deletePill', 'series')"
            pillWidth="85%"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import CalculationCard from "./components/CalculationCard.vue";
import schemes from "../../../../packages/ui-color-pallates/categorical/superset.js"
import PallatePicker from "./components/PalattePicker.vue"
import VizModePill from "./components/VizModePill.vue"  
// import CalculatedPill from "./components/CalculatedPill.vue"  

export default {
  components: {
    CalculationCard,
    // PallatePicker,
    VizModePill,
    // CalculatedPill,
    // VizModeCalc,
  },
  data () {
    return {
      // dialog: false,
      // show: false,
      // showCalcCard: false,
      // showSizeSlider: false,
      showColorPicker: false,
      // sizeDialogPos: {},
      colorDialogPos: {},
      // bubbleSize: 0,
      // bubbleRelative: [8,20],
      color_schemes: schemes,
      color_pallate: schemes[0], // TODO: this is default, change to form_data option
      bubbleOpacity: 0,
    }
  },
  props: {
    validVisCols: {
      type: Object,
      default() {
        return null;
      },
    },
    aggregations: {
      type: Object,
      default() {
        return null;
      },
    },
    showCalc: {
      type: Object,
      default() {
        return null;
      },
    },
    xAxis: {
      type: Object,
      default() {
        return null;
      },
    },
    yAxis: {
      type: Object,
      default() {
        return null;
      },
    },
    series: {
      type: String,
      default() {
        return "";
      },
    },
    entity: {
      type: String,
      default() {
        return null;
      },
    },
    form_data: {
      type: Object,
      default() {
        return null;
      },
    },
    enableColor: {
      type: Boolean,
      default() {
        return false;
      },
    },
    mode_active_cols: {
      type: Object,
      default() {
        return {};
      },
    },
  },

  methods:{
    isApplied(axis) {
      return this.mode_active_cols[axis]?.applied;
    },
    showForm() {
      // let dim = 'size'
      // console.log('vizModeBubble Form', this.form_data[dim], this.dim)
      this.$emit('showForm')
    },

    changePallate(e) {
      console.log('color', e)
    },

    resetOpacity() {
      console.log('reset opacity')
      this.bubbleOpacity=0
    },

    changeOpacity(_val) {
      console.log('change opacity:', this.bubbleOpacity);
      // let bubbleObj = [this.bubbleSize, this.bubbleRelative]
      // this.form_data.extra_form_data.bubble_size = bubbleObj
      // this.$emit('changeBubbleSize', bubbleObj);
    },

    clickColorAdjust() {
      // TODO: merge with above function
      let sizeDialogElem = this.$el.querySelector('.categories-container')
      this.colorDialogPos = sizeDialogElem.getBoundingClientRect()
      this.showColorPicker=true;
    },

    // clickColorPicker() {
      // let sizeDialogElem = this.$el.querySelector('.categories-container')
      // this.sizeDialogPos = sizeDialogElem.getBoundingClientRect()
      // this.showColorPicker=true;
    // },
    
    removePillX(axis) {
      this.$emit('deletePill', axis)
      if (axis==='size') {
        this.bubbleSize = 0;
      }
    },
    clickDialog() {
      console.log('I was clicked')
    },

    contextClickPill(axis) {
      console.log('open calc menu')
      this.$emit('showCalcCard', axis)
      // this.$emit('showCalcOption', event, this.size, 'size')
    },

    saveSimpleCalcX(calc) {
      // console.log('saveme bubble', calc)
      // this.menuBool = false;
      this.$emit('saveCalc', calc, 'x')
    },

    disableSimpleCalcX() {
      console.log('closeme size')
      this.$emit('disableCalc', 'x')
    },

    saveSimpleCalcY(calc) {
      this.$emit('saveCalc', calc, 'y')
    },
    disableSimpleCalcY() {
      console.log('closeme y')
      this.$emit('disableCalc', 'y')
    },
    
    closeDialog() {
      this.dialog = false;
    },

    getComputedCol(val) {
      // console.log('computeds', val, this[val].column)
      return {
        column_name: (
          this[val].column ?
            this[val].aggregate ? `(${this[val].aggregate})${this[val].column.column_name}` : 
            this[val].column.column_name : 'error?'
        ),
        type_generic: this[val].column.type_generic,
        applied: true,
      }
    },

    getSimpleCol(val) {
      // console.log('getsimple', val, this.isApplied(val))
      const this_col = this.mode_active_cols[val]
      // console.log('getsimple2', this_col)
      return {
        // column_name: this.form_data[val],
        column_name: this_col.column_name,
        type_generic: this_col.type_generic,
        // applied: false,
        applied: this.isApplied(val),
        }
    },

    getAggType(col) {
      // console.log('get agg type', col)
      if ( col.column.type_generic === 1 ) {
        return this.aggregations.categorical;
      } else {
        return this.aggregations.numeric;
      }
    },
  },

  // computed: {},
  // mounted() {},
};
</script>


<style lang="scss" scoped>

.opacity-slider {
  width: 220px;
  padding-left:20px;
}

.column-btn {
  border-color: #1bbab9 !important;
}

.column-numeric-btn {
  border-color: #1bbab9 !important;
}

.column-text-btn {
  border-color: #00a7ff !important;
}

.slider-card {
  background-color: #303030;
  padding: 0px 12px;
  opacity: 0.96
  
}

.blank-drag {
  border: 1px dashed #909090;
  border-radius: 12px;
  text-align: center;
  color: #909090;
  padding-top: 4px;
  padding-bottom: 4px;
  font-size: 12px;
}

.blank-text {
  font-size: 12px;
  text-align: center;
  color: #909090;
  margin-top: 9px;
}
.xAxis {
  &-header {
    padding: 8px 15px;

    &-title {
      font-size: 12px;
    }
  }

  &-container {
    overflow-y: auto;
    @include scrollbars(0.4rem, #717171, #00000080);
    border-bottom: 0.5px solid #373737;
    height: 40px;
    padding-left: 15px;
    padding-right: 15px;
  }
}

.yAxis {
  &-header {
    padding: 8px 15px;

    &-title {
      font-size: 12px;
    }
  }

  &-container {
    overflow-y: auto;
    @include scrollbars(0.4rem, #717171, #00000080);
    border-bottom: 0.5px solid #373737;
    height: 40px;
    padding-left: 15px;
    padding-right: 15px;
  }
}

.categories {
  &-header {
    padding: 8px 15px;

    &-title {
      font-size: 12px;
    }
  }

  &-container {
    overflow-y: auto;
    @include scrollbars(0.4rem, #717171, #00000080);
    height: 40px;
    padding-left: 15px;
    padding-right: 15px;
  }

  &-color {
    position: absolute;
    z-index: 11;
    left: 40px;
  }
}

.index {
  &-header {
    padding: 8px 15px;

    &-title {
      font-size: 12px;
    }
  }

  &-container {
    overflow-y: auto;
    @include scrollbars(0.4rem, #717171, #00000080);
    border-bottom: 0.5px solid #373737;
    height: 40px;
    padding-left: 15px;
    padding-right: 15px;
    // background: red;
  }
}

.btn-test {
  // position: absolute;
  // right: 180;
//   background: orange;
  width:185px;
  height: 30px;
  // margin-top: 5px;
  background: green;
}

.btn-test::after {
  position: absolute;
  content: "";
  // top: 9px;
  left: 185px;  
  border-top: 16px solid transparent;
  border-bottom: 16px solid transparent;  
  border-left: 16px solid green;
  transform: rotate(180deg);
}

// Animate the tooltips to match v-menu
@keyframes fade {
  0% { opacity: 0 }
  100% { opacity: 1 }
}

.size {
  &-header {
    padding: 8px 15px;
    &-title {
      font-size: 12px;
    }
  }

  &-container {
    overflow-y: auto;
    @include scrollbars(0.4rem, #717171, #00000080);
    border-bottom: 0.5px solid #373737;
    padding-left: 15px;
    padding-right: 15px;
    height: 40px;
  }

  &-menu::after {
    animation: fade .2s linear;
    position: absolute;
    content: "";
    // top: 9px;
    z-index: 10;
    left: 230px;  
    border-top: 12.5px solid transparent;
    border-bottom: 12.5px solid transparent;  
    border-left: 16px solid #303030;
    transform: rotate(180deg);  
  }

}
</style>