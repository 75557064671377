<template>
  <v-dialog v-model="showNewFromDB" max-width="1355px" persistent>
    <v-card class="new-from-db">
      <p class="new-from-db-title">
        Datasource (Import Table/View from Database)
      </p>
      <div class="new-from-db-container">
        <div class="new-from-db-row">
          <p>Database *</p>
          <v-combobox
            solo
            :item-text="(obj) => obj['database_name']"
            :item-value="(obj) => obj['database_name']"
            :items="databases"
            @change="changeDB"
            :return-object="true"
            v-model="database"
            outlined
            class="new-from-db-select"
          >
            <template v-slot:selection="data">
              <v-chip color="primary">{{ data.item.backend }}</v-chip>
              {{ data.item.database_name }}
            </template>
          </v-combobox>
        </div>
        <div class="new-from-db-row">
          <p>Schema</p>
          <v-combobox
            solo
            :items="schemas"
            v-model="schema"
            @change="getTables"
            :return-object="true"
            outlined
            messages="Schema, as used only in some databases like Postgres, Redshift and DB2"
          />
        </div>
        <div class="new-from-db-row">
          <p>Table Name *</p>
          <v-combobox
            solo
            :items="tables"
            v-model="table"
            :return-object="true"
            outlined
            @change="changeTable"
            :item-text="(obj) => obj['title']"
            :item-value="(obj) => obj['title']"
          >
            <template v-slot:selection="data">
              {{ data.item.title }}
            </template>
          </v-combobox>
        </div>
        <div class="separator">
          <SeparatorTextV2 text="Or" />
        </div>
        <div class="new-from-db-action">
          <v-btn
            min-width="320px"
            color="primary"
            height="32px"
            @click="createNewDB"
          >
            Create New Database
          </v-btn>
        </div>
      </div>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="white"
          plain
          @click="cancel"
          height="32px"
          width="94px"
          class="mr-2"
        >
          Cancel
        </v-btn>
        <v-btn color="primary" height="32px" width="94px" @click="createDataset"
          >Apply</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {
  GET_SCHEMA_ALL,
  GET_DB_ALL,
  GET_TABLE_ALL,
  CREATE_DATASET,
} from "@/store/actionType";
import SeparatorTextV2 from "../../components/core/SeparatorTextV2.vue";

export default {
  components: {
    SeparatorTextV2,
  },
  props: {
    showNewFromDB: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      //databases
      databases: [],
      //schemas
      schemas: [],
      //tables
      tables: [],
      //selected db
      database: null,
      //selected schema
      schema: null,
      //selected table
      table: null,
    };
  },
  mounted() {
    this.getDB();
  },
  methods: {
    /**
     * cancel event
     */
    cancel() {
      this.$emit("cancel");
    },
    /**
     * get DB
     */
    getDB() {
      this.$store
        .dispatch(GET_DB_ALL)
        .then((res) => {
          this.databases = res.data.result;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    /**
     * change db event
     * @param db
     */
    changeDB(db) {
      this.database = db;
      this.schemas = [];
      this.schema = "";
      this.tables = [];
      this.getSchema(db);
    },
    /**
     * change table event
     * @param table
     */
    changeTable(table) {
      console.log(table);
      this.table = table;
    },
    /**
     * get schema
     */
    getSchema(db) {
      this.$store
        .dispatch(GET_SCHEMA_ALL, db.id)
        .then((res) => {
          this.schemas = res.data.result;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    /**
     * get tables
     * @param schema
     */
    getTables(schema) {
      this.tables = [];
      const sendingData = {
        pk: this.database.id,
        schema_name: schema,
      };
      this.$store
        .dispatch(GET_TABLE_ALL, sendingData)
        .then((res) => {
          this.tables = res.data.result.options;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    /**
     * create New DB
     */
    createNewDB() {
      this.$emit("createDB");
    },
    /**
     * create new Dataset
     */
    createDataset() {
      const data = {
        database: this.database.id,
        schema: this.schema,
        table_name: this.table.value,
      };
      this.$store
        .dispatch(CREATE_DATASET, data)
        .then((res) => {
          this.$emit("goToDatasets");
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.new-from-db {
  padding: 45px 68px 45px 45px;

  &-title {
    font-size: 24px;
    color: #e3e3e3;
  }

  &-container {
    margin-top: 90px;
    margin-bottom: 100px;
  }

  &-row {
    display: grid;
    grid-template-columns: 170px auto;
  }

  &-select {
    width: 300px;
  }

  &-action {
    margin-top: 40px;
    text-align: center;
  }
}

.separator {
  margin-top: 80px;
  padding-left: 369px;
  padding-right: 382px;
}
</style>