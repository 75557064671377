import { mainAxios } from "./axios";

class CharService {
  create(params) {
    console.log('params', params)
    return mainAxios.post(`/chars/`, params);
  }
  trainModels(chardIds, n_rows) {
    let query = `/chars/train_models?charIDs=${chardIds.join(",")}`;
    if (n_rows) {
      query += `?n_rows=${n_rows}`
    }
    return mainAxios.post(query);
  }
  getDatasetChars(id) {
    return mainAxios.get(`/chars/dataset_chars/${id}`);
  }
  putDatasetCharIndex(id, colId) {
    return mainAxios.put(`/chars/dataset_chars/${id}?columnId=${colId}`);
  }
  deleteChar(id) {
    return mainAxios.delete( `/chars/${id}` );
  }
  getChars(q) {
		return mainAxios.get(`/chars/get_chars?${q}`);
	}
  createPrediction(modelId, n_rows, params) {
    let query = `/chars/predictions/${modelId}`
    if (n_rows) {
      query += `?n_rows=${n_rows}`
    }
    return mainAxios.post(query, params);
  }
  getPrediction(jobId, modelId, datasetId, postPredictions) {
    let query = `/chars/predictions?jobId=${jobId}&modelId=${modelId}&postPredictions=${postPredictions}`;
    if (datasetId) {
      query += `&datasetId=${datasetId}`
    }
    return mainAxios.get(query);
  }
  getEvaluation(modelId, postPredictions) {
    let query = `/chars/evaluation/${modelId}?postPredictions=${postPredictions}`;
    return mainAxios.get(query);
  }
  trainEmbedding(modelId, n_rows) {
    let query = `/chars/flatten/train_embedding?charID=${modelId}&n_rows=${n_rows}`;
    console.log('train me,', query)
    return mainAxios.post(query);
  }
  postEmbedding(modelId, n_clusters, n_rows, body) {
    let query = `/chars/flatten/post_embedding/${modelId}?n_clusters=${n_clusters}&n_rows=${n_rows}`;
    return mainAxios.post(query, body);
  }
  getEmbedding(modelId, postData, datasetId, n_rows) {
    let query = `/chars/flatten/embedding/${modelId}?postData=${postData}&datasetId=${datasetId}&n_rows=${n_rows}`;
    return mainAxios.get(query);
  }
}

export default new CharService();