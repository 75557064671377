<template>
  <div class="d-flex flex-row-reverse">
    <div class="d-flex align-center" v-if="showLayoutOption">
      <!-- Favorites Button -->
      <v-btn outlined 
        :class="!viewFavOnly && 'inactive'"
        :disabled="disabledFavBtn"
        @click="updateViewFavOnly()">
        <v-icon v-if="viewFavOnly" small>mdi-star</v-icon>
        <v-icon v-if="!viewFavOnly" small>mdi-star-outline</v-icon>
        SHOW FAVORITES ONLY
      </v-btn>
      <!-- List View Button -->
      <v-divider vertical class="mx-4"></v-divider>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn 
            v-bind="attrs" 
            v-on="on" 
            :class="viewGridStatus && 'inactive'" 
            width="70"
            :disabled="disabledListBtn"
            @click="viewGridStatus==true && updateLayoutOption()">
            <v-icon size="24">
              mdi-view-list
            </v-icon>
            LIST
          </v-btn>
        </template>
        <span>List View</span>
      </v-tooltip>
      <!-- Grid View Button -->
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn 
            v-bind="attrs" 
            v-on="on" 
            class="ml-3" 
            :class="!viewGridStatus && 'inactive'" 
            width="70"
            :disabled="disabledGridBtn"
            @click="viewGridStatus==false && updateLayoutOption()">
            <v-icon size="18">
              mdi-view-grid
            </v-icon>
            GRID
          </v-btn>
        </template>
        <span>Grid View</span>
      </v-tooltip>
    </div>
    <!-- Return to button (chevron left) -->
    <div class="mr-auto d-flex align-center" v-if="title">
      <!-- <v-btn icon class="mr-0" @click="$router.go(-1)"> -->
        <v-btn icon class="mr-0" @click="goToPage">
        <v-icon>
          mdi-chevron-left
        </v-icon>
      </v-btn>

      <div class="d-flex flex-grow-0">
        <h1 class="text-h6 pl-2">{{ title }}</h1>
      </div>
    </div>
  </div>
</template>

<script>

import { NavigationFailureType, isNavigationFailure } from 'vue-router'

export default {
  name: "ModuleHeader",
  props: {
    title: {
      type: String,
    },
    returnToPage: {
      type: String,
      default: "Index"
    },
    showLayoutOption: {
      type: Boolean,
      default: true
    },
    disabledFavBtn: {
      type: Boolean,
      default: false
    },
    disabledListBtn: {
      type: Boolean,
      default: false
    },
    disabledGridBtn: {
      type: Boolean,
      default: false
    },
    viewGrid: {
      type: Boolean,
      default: true
    }
  },
  data: () => ({
    viewFavOnly: false,
    returnToOptions: [
      {"option":"projects-page", action:""}
    ]
  }),
  computed: {
    viewGridStatus() {
      return this.viewGrid
    }
  },
  methods: {
    goToPage() {
      console.log('goToPage', this.returnToPage)
      if (this.returnToPage === "back") {
        this.$router.go(-1)
      } else {
        this.$router.push({ name: this.returnToPage });
      }
    },
    updateLayoutOption() {
      this.$emit('updateLayoutOption')
    },
    updateViewFavOnly() {
      this.viewFavOnly =! this.viewFavOnly
      this.$emit('updateViewFavOnly')
    }
  },
};
</script>

<style scoped>
.inactive {
  color: rgba(255, 255, 255, 0.3) !important;
}
.inactive:hover {
  color: inherit !important;
}
</style>
