<template>
  <v-card v-if="project" 
          color="#262626"
          style="max-height: 200;"
          >
          <v-card-title>
            <h3>{{project.project_title}}</h3>
          </v-card-title>
          <v-divider/>
          <v-container overflow-hidden>
            <v-card-subtitle>
              <p>
                  Created By: {{project.created_by.first_name}} {{project.created_by.last_name}}<br>
                  Last Modified: {{project.changed_on_delta_humanized}} by {{project.created_by.username}}<br>
                  Description: {{ project.description }}
              </p>
            </v-card-subtitle>
            <v-divider/>
            <div class="outline d-flex">
              <v-col md="12">
                <v-tabs color="white" v-model="tab">
                  <v-tab>MEMBERS</v-tab>
                  <v-tab>CHARTS</v-tab> 
                  <v-tab>DASHBOARDS</v-tab>
                </v-tabs>
                <v-tabs-items v-model="tab">
                  <v-tab-item>
                  <DetailTabs detailTab="members"
                      :metadata="memberMetadata"
                    />
                  </v-tab-item>
                  <v-tab-item>
                    <DetailTabs detailTab="charts"
                      :metadata="sliceMetadata"
                    />
                  </v-tab-item>
                  <v-tab-item>
                    <DetailTabs detailTab="dashboards"
                      :metadata="dashboardMetadata"
                    />
                  </v-tab-item>
                </v-tabs-items>
              </v-col>
            </div>
            </v-container>
            <v-divider/>
            <!-- TODO::  -->
            <v-card-actions>
              <v-btn class="mr-5 fs-16" color="primary" plain x-large
                @click="goToProject">
                GO TO PROJECT
              </v-btn>
              <v-btn
                class="fs-16"
                color="primary"
                plain
                x-large
                @click="$emit('closeProjectDialog')"
              >
                CLOSE
              </v-btn>
          </v-card-actions>
        </v-card>
</template>

<script>
import DetailTabs from './DetailTabs.vue'


export default {
  name: "ProjectDetailModal",
  components: {
    DetailTabs,
  },
  data: () => ({
    tab: null,
  }),
  props: {
    project: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  methods: {
    goToProject () {
      let project_id= this.project.id
      this.$router.push({ name: 'Projects-Detail', params: { id: project_id } });
      this.detailModalVisible = false; 
      this.$emit('closeProjectDialog')
      // this.project=null;
    },
    clickInfoItem(item) {
      // console.log('inf0', this.detailModalVisible)
      this.detailModalVisible = true;
      this.project = item;
      // console.log('inf0', this.detailModalVisible)
    },
  },
  computed:{
    dashboardMetadata() {
      console.log('db meta', this.project.dashboard_metadata)
      return this.project.dashboard_metadata;
    },
    sliceMetadata() {
      return this.project.slice_metadata;
    },
    memberMetadata() {
      return this.project.members_metadata;
    },
    n_rows() {
      return Math.ceil((this.project.members_metadata.length || 0) / 3)
    },
  },

}



</script>

<style scoped>

</style>