import Vue from 'vue';
import VueRouter from 'vue-router';
import App from './App.vue';
import routes from './routes/index';
import store from './store/store';
import vuetify from './plugins/vuetify';
import VueTippy, { TippyComponent } from "vue-tippy";
import VeeValidate from "vee-validate";
import CKEditor from "@ckeditor/ckeditor5-vue2";
import VueCodemirror from 'vue-codemirror'
import 'codemirror/lib/codemirror.css'
import moment from 'moment';
import Draggable from "vuedraggable";
import vco from "v-click-outside";
import HighchartsVue from 'highcharts-vue'
import Highcharts from 'highcharts';
import more from 'highcharts/highcharts-more';
import hcHeatmap from "highcharts/modules/heatmap";

// import "@spectrum-web-components/icons-workflow/";

more(Highcharts);
hcHeatmap(Highcharts);  
import Axios from "axios";
import { LOGOUT } from "@/store/actionType";

Vue.config.productionTip = false;
Vue.prototype.$pageItemCount = 10;

Vue.use(VeeValidate, { inject: false, events: 'input' });

// Use codemirror as Global
Vue.use(VueCodemirror);

// Router config
Vue.use(VueRouter);

//highchart
Vue.use(HighchartsVue, { Highcharts });

Vue.component("draggable", Draggable);
Vue.use(vco);

let router = new VueRouter({
  routes
});

//set auth header
// Axios.defaults.headers.common['Authorization'] = `Bearer ${store.state.auth.initialState.token}`;
// Axios.defaults.baseURL = process.env.VUE_APP_API_URL;

// Axios.interceptors.response.use(function (response) {
//   // Do something with response data
//   return response;
//  }, function (error) {
//   // Do something with response error
//   console.log('Interceptor Error');
//   if (error?.response?.status === 401) {
//     store.dispatch(LOGOUT);
//     router.push('/login').catch(()=>{});
//     return;
//   }
  
//   return Promise.reject(error);
// });
// console.log('Before BeforeEach');
router.beforeEach((to, from, next) => {
  if (!store.state.auth.initialState.status.loggedIn && !to.path.includes('/login')) {
    // console.log('Before BeforeEach Not Login');
    next({ path: '/login' });
    return;
  }

  next();
});

Vue.use(VueTippy);
Vue.component("tippy", TippyComponent);

Vue.use(CKEditor);
Vue.filter('formatDate', function (value) {
  if (value) {
    if (value instanceof Date) {
      return moment(value).format("MM/DD/YYYY - hh:mm");
    } else {
      return moment(String(value)).format("MM/DD/YYYY - hh:mm");
    }
  }
})


Vue.filter('datediff', function (dateStr) {
  if (!dateStr) return ''
  
  const oneDay = 24 * 60 * 60 * 1000; 
  const prevDate = new Date(dateStr);
  const curDate = new Date();

  const diffDays = Math.round((curDate - prevDate) / oneDay);

  if (diffDays<31) {
    return '' + diffDays + ' days';
  } else if (diffDays<365) {
    const diffMonths = Math.round(diffDays / 30);
    return '' + diffMonths + ' months';
  } else {
    const diffYears = Math.round(diffDays / 365);
    return '' + diffYears + ' years';
  }

})

new Vue({
  render: h => h(App),
  vuetify,
  router,
  store
}).$mount('#app');
