<!-- @/views/charts/modals/SelectVistypeDlg.vue -->
<template>
  <div>
    <!-- Row right click context menu -->
    <v-menu
      v-click-outside="closeContextMenu"
      v-model="showRowMenu"
      :position-x="x"
      :position-y="y"
      absolute
      offset-y
    >
        <v-list color="modal_background">
          <v-list-item @click="setAsIndex">
            <v-list-item-content  style="font-size: 12px;">
              Set as Dataset Index
            </v-list-item-content>
          </v-list-item>
        </v-list>
    </v-menu>
    <!-- scrollable -->
    <v-dialog
      content-class="advModel-dialog"
      v-model="model_modal"
      width="1000"
      persistent
      
    >
      <!-- color="modal_background" -->
      <v-card 
        height="560px"
        color="modal_background"
      >
        <v-card-title>
          <div class="card-header d-flex">
          Prediction Models (Advanced)
          <!-- v-model="viz_search" -->
          <v-text-field
            ref="searchInput"
            hide-details
            label="Search Models/Parameters"
            no-filter
            dense
            solo
            outlined
            hide-no-data
            prepend-inner-icon="mdi-magnify"
            
            style="max-width: 300px;"
          >
          </v-text-field>
          <v-icon dense
            @click.prevent="cancelModal"
          >mdi-close-circle-outline
          </v-icon>
          </div>
        </v-card-title>
        <v-card-text>
        <!-- <v-container> -->
          <v-divider/>
          <!-- Card Content -->
          <!-- <div class="col-md-12 d-flex"> -->
            <v-container>
            <!-- <div> -->
            <v-row>
            <!-- Column/model list -->
            <v-col md="4">
              
              <!-- {{ selectedColumn }} -->
              <v-card>
              <v-card-title> Dataset Features </v-card-title>
              <v-divider/>
              <div class="model-section" style="height:378px">
                <!-- <div style="height:200px"> -->
                  <!-- 
                -->
              <!-- v-model="selectedCol" -->
              <!-- @click:row="rowClick" -->
              <v-data-table
                v-model="selectedColumn"
                item-key="id"
                
                single-select
                :items="columns"
                :headers="table_headers"
                dense
                hide-default-footer
              >
                <!-- Row formatting / icons -->
                <template v-slot:item="{ item, isSelected, select }">
                    <tr :class="isSelected?'hover_blue':''" 
                      @click="toggle(isSelected,select,$event)"
                      @contextmenu.prevent="rowContextMenu($event, item)"
                    >
                      <td> <!-- data type -->
                        <v-icon dense v-if="item.type_generic === 1" color="#A2A2A2">
                          mdi-format-text
                        </v-icon>
                        <v-icon dense v-if="item.type_generic === 0" color="#A2A2A2">
                          mdi-numeric
                        </v-icon>
                      </td>
                      <td> {{ item.column_name }}</td>
                      <td>
                        <div v-if="is_trained(item.id)===null"></div>
                        <v-icon v-else-if="is_trained(item.id)" small> $beakerCheckIcon </v-icon>
                        <v-icon v-else small> $beakerIcon </v-icon>
                      </td>
                      <td> 
                        <v-icon v-if="item.id===datasetIndexCol" small> $itemKeyIcon </v-icon>
                      </td>
                    </tr>
                </template>
              </v-data-table> 
              index: {{ datasetIndexCol }}
              </div>
              </v-card>
            </v-col>

            <v-divider vertical inset/>
            <!-- Chart Selection -->
            <!-- TODO Check for many rows and order n-rows x 6 chart types -->
            <v-col md="8" style="position: relative">
              <v-card>
                <v-card-title>
                  <div v-if="is_active_model" >Model: {{ activeModel.model_name }}</div>
                  <div v-else >No Model Selected</div>

                  </v-card-title>
                <v-divider/>
                <v-tabs fixed-tabs inset
                  background-color="indigo"
                  dark
                >
              <v-tab>Model Configuration</v-tab>
              <v-tab>Model Variables</v-tab>
              <!-- <div v-if="selectedColumn!==null && selectedColumn>=0"> -->
                <!-- <div v-if="activeModel.length>0"> -->
                  <!-- <div v-show="cfg_object"> -->
                <v-tab-item>
                  <!-- {{ rowMenuItem }} -->
                  <!-- {{ activeModel }} -->
                  <!-- <div class="model-section" style="height:330px"> -->
                    <v-treeview dense :items="cfg_object"
                    />
                    <!-- </v-treeview> -->
                  <!-- </div>  -->
                </v-tab-item>
              <v-tab-item>
                {{ dataset_chars }}
              </v-tab-item>
              <!-- </div> -->
                </v-tabs>
              </v-card>
            </v-col>
                        </v-row>
          <!-- </div> -->
          </v-container>
        </v-card-text>
        <v-divider/>
        <v-card-actions tag="vis-type-actions">
          <v-spacer/>
          <!-- cancel -->
          <v-btn
            class="mr-5"
            depressed
            @click="cancelModal"
          >
            Cancel
          </v-btn>
          <!-- apply -->
          <!-- @click.stop="moveToDataSet"
            :disabled="!viz_type" -->
          <v-btn
            color="primary"
            depressed
            @click="cancelModal"
          >
            OK
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>

// import { vizIcon, viz_objects } from "@/utils/chartUtils"
// import SpIcon from "@/utils/SpIcon.vue" // use for getting svg data

import '@spectrum-web-components/icons-workflow/icons/sp-icon-beaker.js';
import '@spectrum-web-components/icons-workflow/icons/sp-icon-beaker-check.js';

export default {
  name: "AdvacedModels",
  components: {
    // SpIcon,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    datasetIndexCol: {
      type: Number,
      default() {
        return null;
      }
    },
    dataset_chars: {
      type: Array,
      default() {
        return [];
      },
    },
    columns: {
      type: Array,
      default() {
        return [{'type': 'bigint', 'column_name': 'blah'}];
      },
    },
  },
  data () {
    return {
      // row context menu
      rowMenuItem: null,
      showRowMenu: false,
      x: null,
      y: null,
      
      //
      model_index: [],
      // activeModel: null,
      selectedColumn: [],
      // selectedColumn:-1,
      is_model: null,
      model_cfg: null,
      estimators: {
        "regression": ["xg-boost","linear regression"],
        "classification":["xg-boost","logistic regression"]
      },

      cfg_display_options: {
        "model_name": {
          "verbose_name": "Model Name",
          "edit": false,
          },
        "target": {
          "verbose_name": "Dependant Variable (Target)",
          "edit": false,
          },
        "algo_type": {
          "verbose_name": "Model Type",
          "edit": false,
        },
        "base_estimator": {
          "verbose_name": "Algorithm",
          "edit": true,
          "options": this.activeModel? this.estimators[ this.activeModel['algo_type'] ] : [],
        },
        "feature_selection": {
          "verbose_name": "Feature Selection",
          "edit": true,
          // options: TODO
        },
      },
      selectedCol: [],
      table_headers: [
        {text: 'Type', value: 'type',},
        {text: 'Name', value: 'column_name'},
        {text: 'Status', value: 'status'},
        {text: 'Index', value: 'model_id'},
      ],
    };
  },
  methods: {
    closeContextMenu() {
      this.rowMenuItem=null;
    },
    setAsIndex() { // TODO: flash alert to verify and delet model if set as index
      // console.log('rv', this.rowMenuItem)
      this.$emit('putDatasetCharIndex', this.rowMenuItem.id)
      this.rowMenuItem=null;
    },
    rowContextMenu(e, item) {
      e.preventDefault()
      this.rowMenuItem = item;
      this.showRowMenu=true;
      this.x = e.clientX;
      this.y = e.clientY;
    },
    toggle(isSelected,select,e) {
      select(!isSelected)
    },
    cancelModal() {
      this.$emit("cancelAdvModal")
    },
    traverse(jsonObj) {
      // console.log('traverse', jsonObj)
      let obj = [];
      if( jsonObj !== null && typeof jsonObj == "object" ) {
        Object.entries(jsonObj).forEach(([key, value]) => {
          // console.log('obj', key, value)
            // key is either an array index or object key
            if( value !== null && typeof value == "object"  && !Array.isArray(value)) {
              // console.log('is obj', key)
              obj.push({ 'name': key, 'children': this.traverse(value) })
            } else {
              // console.log('not obj', key)
              obj.push({ 'name': key+':'+ JSON.stringify(value) })
            }
        });
      }
      return obj
    },
    is_trained(col_id) {
      let model;
      if (this.dataset_chars) {
        model = this.dataset_chars.find( c => c.model_id===col_id)
      }
      return model ? model.clf.exists : null;
    },
  },
  computed: {
    is_active_model () {
      return Object.keys(this.activeModel).length>0;
    },
    cfg_object() {
      return this.traverse(this.activeModel)
    },
    model_modal: {
      get: function () {
        return this.value;
      },
      set: function (newValue) {
        this.value = newValue;
      },
    },
    activeModel() {
      // console.log('selected item', this.selectedColumn)
      // if ( this.selectedColumn!==null && this.selectedColumn>=0 ) {
      if ( this.selectedColumn.length>0 ) {
        let filteredModels = this.dataset_chars.filter(a => {
          // return a.model_id===this.columns[this.selectedColumn].id
          return a.model_id===this.selectedColumn[0].id
        })
        return filteredModels.length > 0 ? filteredModels[0].cfg : {};
      } else {
        return {}
      }
    },
  },
};
</script>

<style lang="scss" scoped>

.theme--dark.v-data-table tbody tr.v-data-table__selected {
        background:red !important;
    }
.theme--dark.v-data-table tbody tr.v-data-table__selected:hover {
        background: red !important;
  }
.v-card {
  padding: 8px;
//   background-color: teal;
//   // overflow: hidden; /* it works! */
}

.model-section {
  // height: 360px;
  overflow-y: auto;
  @include scrollbars(0.4rem, #717171, #00000080);
  // scrollbar-width: thin;
}

.chart-block {
  width: 20%; cursor: pointer
}

.card-header {
  width: 100%;
  justify-content: space-between;
  // margin-top: 10px;
}

.chart-filter {
  width: 100%;
  justify-content: space-between;

  &-header {
    font-weight:bold;
    font-size: 16px;
    margin-bottom: 4px;
  }
}
.btn-active {
  background-color: #2987F8; // hover blue
}

.v-btn-toggle {
  flex-direction: column;
  width: 100%;
}

.v-expansion-panel-header {
  border-bottom: solid 1px #DDDDDD;
}

.listing-item {
  background-color: blue;
  margin-top: 8px;
}

.icon-text {
  color: #B9B9B9;
}
.close {
  margin-right: 15px;
  cursor: pointer;
  font-size: 20px;
}
// .custom-dialog {
//   position: absolute;
//   /*right: 10%;*/
//   top: 10%;
// }
.outline-selected {
  border-bottom: 1px solid #ffffff;
  color: white;
}
</style>