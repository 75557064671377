<template>
  <div class="box-item-container">
    <v-col class="box-item-column">
      <!-- checkboxes -->
      <div v-if="selectable" class="position-relative">
        <v-checkbox
          class="item-checkbox"
          v-model="item.checked"
          @change="toggleChecked"
          color="grey"
        ></v-checkbox>
      </div>
      <!-- Action ellipsis (vertical) -->
      <div class="position-relative">
        <v-btn icon class="vertical-dots" color="black">
          <v-icon color="grey">mdi-dots-vertical</v-icon>
        </v-btn>
      </div>
      <!-- bubble vs table -->
      <div v-if="className=='slice'" class="position-relative">
        <v-btn v-if="item.viz_type=='bubble'" depressed rounded class="chart-type" color="primary">
          <v-icon>mdi-chart-scatter-plot</v-icon>
          Scatter Plot 
        </v-btn>
        <v-btn v-if="item.viz_type=='table'" depressed rounded class="chart-type" color="deep-orange">
          <v-icon>mdi-folder-table</v-icon>
          Data Table
        </v-btn>
      </div>
      <!-- Click item -->
      <div class="box-item" @click="clickItem">
        <v-img v-if="item.viz_type=='table'" src="@/assets/charts/type/aggrid_stock.jpg"></v-img>
        <v-img v-else src="@/assets/charts/chart1.jpg"></v-img>
      </div>
      <!-- Item Info -->
      <div class="d-flex title-fixed">
        <div class="mr-auto">
          <div class="box-title body-1">{{ item[titleField] }}</div>
          <div class="body-2">
            Modified {{ item.changed_on_delta_humanized}}
          </div>
          <div class="body-2">
            <span v-if="item.changed_by">By {{ item.changed_by.username }}</span>
          </div>
        </div>
        <div class="d-flex flex-column">
          <v-btn icon small @click="toggleFav">
            <v-icon v-if="!item.favorite" small>mdi-star-outline</v-icon>
            <v-icon v-if="item.favorite" small>mdi-star</v-icon>
          </v-btn>
          <v-btn icon small @click.stop="clickInfoItem">
            <v-icon small>mdi-information-outline</v-icon>
          </v-btn>
        </div>
      </div>
    </v-col>
  </div>
</template>

<script>

export default {
  name: "BoxItem",
  props: ["item", "className", "titleField", "selectable"],
  methods: {
    toggleFav() {
      this.$emit("toggled", this.item, this.className);
    },
    toggleChecked() {
      this.$emit("checked", this.item, this.className);
    },
    /**
     * Item Click event
     */
    clickItem() {
      this.$emit("clickItem", this.item);
    },
    /**
     * Item Click event
     */
    clickInfoItem() {
      console.log('info item')
      this.$emit("clickInfoItem", this.item);
    },
  },
};
</script>

<style scoped>
.box-item-column {
  float: left;
  flex-grow: initial !important;
}

.box-item {
  height: 187px;
  width: 260px;
  border: solid 1px #a2a2a2;
  cursor: pointer;
}

.box-title {
  margin: 3px 0;
  color: #b9b9b9;
}
.body-2 {
  color: #b9b9b9;
}
.title-fixed {
  margin-top: 18px;
  width: 260px;
}
.item-checkbox{
  position: absolute;
  z-index: 100;
  left: 10px;
  top: -10px;
}
.vertical-dots{
  position: absolute;
  z-index: 100;
  right: 0px;
  top: 5px;
}

.chart-type{
  position: absolute;
  z-index: 100;
  left: 10px;
  top: 140px;
}
</style>
