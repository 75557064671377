import { mainAxios } from "./axios";

class ProjectService {
	get(id) {
		return mainAxios.get(`/projects/${id}`);
	}
	getAll(q) {
		return mainAxios.get(`/projects/?q=${JSON.stringify(q)}`);
	}
	getFavStatusAll(q) {
		return mainAxios.get(`/projects/favorite_status/?q=${q}`);
	}
	create(params) {
		return mainAxios.post(`/projects/`, params);
	}
	update(id, params) {
		return mainAxios.put(`/projects/${id}`, params);
	}
	delete(id) {
		return mainAxios.delete(`/projects/${id}`);
	}
	deleteMulti(q) {
		return mainAxios.delete(`/projects/?q=${JSON.stringify(q)}`);
	}
	removeChildren(id, className, q) {
		return mainAxios.delete(`/projects/${id}/${className}?q=${JSON.stringify(q)}`);
	}
	addChildren(id, className, q, body) {
		return mainAxios.put(`/projects/${id}/${className}?q=${JSON.stringify(q)}`, body);
	}
}

export default new ProjectService();
