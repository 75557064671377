<template>
  <div class="vis-data">
    <!-- {{ vis_type }} -->
    <!-- Train/predict onto snackbars -->
    <v-snackbar
      v-model="visibleSnackbar"
      :timeout="15000"
      :color="snackbarColor"
      :multi-line="true"
      :vertical="true"
    >
      {{ snackbarMessage }}
      <div class="pt-2">
        <div
          v-for="(item, index) in snackbarItems"
          :key="index"
          class="pt-1 pb-1"
        >
          <a
            :href="`/#/visualizations/null/${item.key}`"
            target="_blank"
            class="has-text-link"
          >
            <v-btn role="link" class="bg-primary mr-1" color="white" text>
              Open Page
            </v-btn>
          </a>

          <v-btn
            class="bg-primary"
            color="white"
            text
            :disabled="true"
            @click="showNewDatasetFromDBModal()"
          >
            Save Data
          </v-btn>
        </div>
      </div>
      <template v-slot:action="{ attrs }">
        <v-btn
          color="white"
          text
          v-bind="attrs"
          @click="closeSnackBar()"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>

    <!-- prediction toolbox -->
    <!-- predict onto context menu -->
    <v-menu
      v-model="visibleOnToContextMenu"
      :position-x="x"
      :position-y="y"
      absolute
      offset-y
    >
      <v-card>
        <v-card-text class="px-0 py-0">
          <v-btn
            @click="predictModelOntoDataset(curPrediction.model_id, datasource)"
          >
            Predict Model onto this Dataset
          </v-btn>
        </v-card-text>
      </v-card>
    </v-menu>

    <!-- prediction toolbox pill NOT trained context menu -->
    <v-menu
      v-model="visibleNotTrainContextMenu"
      :position-x="x"
      :position-y="y"
      absolute
      offset-y
    >
      <v-card>
        <v-card-text class="px-0 py-0">
          <v-list-item-group color="primary">
            <v-list-item @click="trainPrediction(curPrediction)">
              <v-list-item-content style="width: 100px">
                Train Model
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-card-text>
      </v-card>
    </v-menu>

    <!-- prediction toolbox pill TRAINED context menu -->
    <v-menu
      v-model="visibleTrainedContextMenu"
      :position-x="x"
      :position-y="y"
      absolute
      offset-y
    >
      <v-card>
        <v-list density="compact" color="modal_background">
          <v-list-item-group color="primary">
            <v-list-item @click="openTrainingDataset(curPrediction)">
              <v-list-item-content> Open Training Dataset </v-list-item-content>
            </v-list-item>

            <v-list-item @click="OpenEvaluationPage()">
              <v-list-item-content> Open Evaluation Page </v-list-item-content>
            </v-list-item>

            <v-list-item @click="openTrainingPrediction()">
              <v-list-item-content>
                Open Train/Test Predictions
              </v-list-item-content>
            </v-list-item>

            <v-list-item @click="showDatasetMenu($event)">
              <v-list-item-content>
                Predict Model onto new Dataset
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-card>
    </v-menu>
    <!-- ?visible dataset menu? -->
    <v-menu
      v-model="visibleDatasetMenu"
      :close-on-content-click="false"
      :position-x="x"
      :position-y="y"
      absolute
      offset-y
    >
      <v-card class="prediction-menu">
        <v-card-subtitle> Datasets </v-card-subtitle>

        <v-card-text>
          <v-select
            v-model="newDataset"
            :items="datasets"
            item-text="table_name"
            item-value="id"
            label="Dataset Features"
            solo
            outlined
            return-object
            hide-details="true"
            style="width: 250px"
          ></v-select>
        </v-card-text>

        <v-card-actions>
          <v-btn
            class="px-5"
            outlined
            color="gray"
            @click="visibleDatasetMenu = false"
          >
            Cancel
          </v-btn>
          <v-spacer></v-spacer>

          <v-btn
            color="#00A7FF"
            class="px-5"
            :disabled="this.newDataset == null"
            @click="predictModelOntoDataset(curPrediction.id, newDataset)"
          >
            Apply
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-menu>

    <!-- Row right click context menu -->
    <v-menu
      v-model="showRowMenu"
      :position-x="x"
      :position-y="y"
      absolute
      offset-y
      :close-on-content-click="false"
    >
      <v-list dense density="compact" color="modal_background">
        <!-- add all columns -->
        <!-- <v-list-item v-if="false" -->
        <v-list-item v-if="vis_type==='table'"
          @click="addAllColumns(); showRowMenu=false"
        >
          <v-list-item-content class="context-items">
            Add all columns
          </v-list-item-content>
        </v-list-item>
        <!-- Model options -->
        <v-list-group
          :value="true"
          sub-group
        >
          <template v-slot:activator>
              <v-list-tile>
                <v-list-tile-content>
                  <v-list-tile-title>Model Options</v-list-tile-title>
                </v-list-tile-content>
              </v-list-tile>
            </template>

          <!-- Create model -->
          <v-list-item @click="postCharAction(); showRowMenu=false"
            :disabled="rowMenuItem ? model_id_list.includes(rowMenuItem.id) || rowMenuItem.id===datasetIndexCol : false"
          >
            <v-list-item-content class="context-items">
              Create Model
            </v-list-item-content>
          </v-list-item>
          <!-- Delete model -->
          <v-list-item 
            :disabled="rowMenuItem ? !model_id_list.includes(rowMenuItem.id) : false"
            @click="deleteChar(); showRowMenu=false">
            <v-list-item-content class="context-items">
              Delete Model
            </v-list-item-content>
          </v-list-item>
          <!-- Show Advanced Modal -->
          <v-list-item 
            :disabled="rowMenuItem ? !model_id_list.includes(rowMenuItem.id) : false"
            @click="model_modal=true; showRowMenu=false">
            <v-list-item-content class="context-items">
              Show Advanced Options
            </v-list-item-content>
          </v-list-item>
          <!-- Train model -->
          <v-list-item 
            :disabled="rowMenuItem ? !model_id_list.includes(rowMenuItem.id) : false"
            @click="trainCharAction(); showRowMenu=false">
            <v-list-item-content class="context-items">
              Train Model
            </v-list-item-content>
          </v-list-item>
        </v-list-group>
        <!-- Set as Index -->
        <v-list-item  
          :disabled="rowMenuItem ? rowMenuItem.id===datasetIndexCol : false"
          @click="putDatasetCharIndex(rowMenuItem.id); showRowMenu=false"
        >
          <v-list-item-content class="context-items">
            Set as Index
          </v-list-item-content>
        </v-list-item>
        <!-- Delete Index -->
        <v-list-item :disabled="rowMenuItem ? rowMenuItem.id!==datasetIndexCol : false"
          @click="putDatasetCharIndex(-1); showRowMenu=false"
        >
          <v-list-item-content class="context-items">
            Delete Index
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-menu>

    <!-- Model right click context menu -->
    <!-- :close-on-content-click="false" -->
    <v-menu
      v-model="modelContextMenu"
      :position-x="x"
      :position-y="y"
      absolute
      offset-y
    >
      <v-list dense density="compact" color="modal_background">
        <!-- add all columns -->
        <v-list-item
          @click="doRightClick"

        >
          <v-list-item-content class="context-items">
            Show Labels
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-menu>

    <!-- Add predicted dataset to database -->
    <NewDB :showNewDB="visibleShowNewDBModal" @cancel="hideNewDBModal" />
    <NewFromDB
      :showNewFromDB="visibleShowNewDatasetFromDBModal"
      @cancel="hideNewDatasetFromDBModal"
      @createDB="showCreateDBModal"
      @goToDatasets="goToVisualizationForm"
    />

    <!-- Modals  -->
    <AdvancedModal
      v-model="model_modal"
      :columns="datasource.columns"
      :dataset_chars="datasetChars"
      :datasetIndexCol="datasetIndexCol"
      @cancelAdvModal="cancelAdvModal"
      @putDatasetCharIndex="putDatasetCharIndex"
    />

    <!-- Flatten Dataset Modal -->
    <v-dialog
      v-model="flattenModal"
      color="modal_background"
      width="400px"
      persistent
    >
      <v-card
        color="modal_background"
      >
        <v-card-title class="text-h5">
          Flatten Dataset Options
        </v-card-title>
        <v-card-subtitle>
          Select Model to Use for Embedding
        </v-card-subtitle>
        <v-divider/>
          <v-card-text>
            <v-autocomplete
              dense
              clearable
              v-model="flattenModelId"
              :items="datasetCharColumns"
              item-text="column_name"
              item-value="id"
            >
            </v-autocomplete>
            <v-divider/>
            <!-- supervised embedding -->
            <div class="d-flex align-items-center">
              <div>
                <v-checkbox
                  v-model="flattenSupervised"
                  dense
                  disabled
                  label="Supervised Embedding"
                />
              </div>
              <div style="padding: 8px;">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      small
                      v-bind="attrs"
                      v-on="on"
                    >
                      mdi-help-circle-outline
                    </v-icon>
                  </template>
                  <span>Use Model Target for<br>Supervised Embedding</span>
                </v-tooltip>
              </div>
            </div>
            <!-- Cluster embedding -->
            <div class="d-flex align-items-center">
              <div>
                <v-checkbox
                  v-model="flattenCluster"
                  dense
                  label="Cluster Embedding"
                />
              </div>
              <div style="padding: 8px;">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      small
                      v-bind="attrs"
                      v-on="on"
                    >
                      mdi-help-circle-outline
                    </v-icon>
                  </template>
                  <span>Choose number of clusters for Embedding</span>
                </v-tooltip>
              </div>
            </div>
            <v-slider
              v-model="n_clusters"
              :disabled="!flattenCluster"
              :max="20"
              :min="-1"
              discrete
              label="Number Clusters"
              class="align-center"
            >
              <template v-slot:prepend>
                <v-text-field
                  v-model="n_clusters"
                  class="mt-0 pt-0"
                  type="number"
                  style="width: 60px"
                ></v-text-field>
              </template>
            </v-slider>
          </v-card-text>
          <v-divider/>
          <v-card-actions>       
            <v-btn
            class="px-5"
              outlined
              color="gray"
              @click="flattenModal=false"
            >
              Cancel
            </v-btn>
            <v-btn
              color="#00A7FF"
              class="px-5"
              :disabled="this.flattenModelId===null"
              @click="flattenDataset"
            >
              Apply
            </v-btn>
          </v-card-actions>
      </v-card>

    </v-dialog>

    <!-- Collapse vis data column -->
    <div v-if="collapse" class="vis-data-hide">
      <v-btn icon @click="showMenu">
        <v-icon> mdi-chevron-right </v-icon>
      </v-btn>
      <!-- hidden tab select -->
      <div>
        <div class="vis-data-hide-tabs">
          <div class="vis-data-hide-tab"
            :class="{ active: activeTab===0 }"
            @click="showMenu(); activeTab=0"
          >Data</div>
          <div class="vis-data-hide-tab"
            :class="{ active: activeTab===1 }"
            @click="showMenu(); activeTab=1"
          >Analysis</div>
          
        </div>
      </div>
    </div>
    <!-- Vis Data column -->
    <div v-else class="vis-data-show">
      <!-- minimize column button -->
      <v-btn icon class="vis-data-back" @click="hideMenu">
        <v-icon> mdi-chevron-left </v-icon>
      </v-btn>
      <!-- Data and analysis tabs -->
      <v-tabs v-model="activeTab" height="33px" color="white">
        <v-tab>Data</v-tab>
        <v-tab>Analysis</v-tab>
        <!-- Data Tab -->
        <v-tab-item class="vis-data-tab">
          <!-- Edit Viz datasource -->
          <div class="table d-flex justify-content-start">
            <div>
              <v-btn
                class="ma-2"
                text-color="#B9B9B9"
                @click.stop="clickDatasource"
              >
                <v-icon dense color=grey-text >mdi-database-edit</v-icon>
                <div class="table-name">{{ datasource.table_name }}</div>
              </v-btn>
            </div>
          </div>
          <!-- search bar -->
          <div class="data-search">
            <v-text-field
              v-model="colFilter"
              placeholder="Fields & Metrics"
              dense
              height="2"
              prepend-icon="mdi-magnify"
            />
          </div>
          <!-- Data Fields ("columns") -->
          <div class="columns">
            <div class="columns-header d-flex">
              <div class="columns-header-text">Data Fields</div>
              <div class="columns-header-description ml-auto" v-if="showCol">
                <!-- Showing 1 of {{datasource.columns.length}} -->
                Showing
                {{
                  filteredColumns(datasource.columns, "column_name").length
                }}
                of {{ datasource.columns.length }}
              </div>
              <div class="columns-carrot ml-auto">
                <v-icon color="#B9B9B9" @click="showCol = !showCol">
                  mdi-chevron-down
                </v-icon>
              </div>
            </div>
            <!-- <a class="search bar">
              <v-text-field
                  v-if="showCol"
                  v-model="colFilter"
                  placeholder="Columns & Metrics"
                  dense
                  height="2"
                  prepend-icon="mdi-magnify"
                />
            </a> -->
            <div class="columns-container pr-1" v-if="datasource && showCol">
              <!-- <div v-for="column in datasource.columns" :key="column.id"> -->
                <div v-for="column in filteredColumns(datasource.columns, 'column_name')" :key="column.id"> 
                  <draggable 
                    @choose="chooseCol(column)"
                  >
                    <div @contextmenu.prevent="rowContextMenu($event, column)">
                      <div class="columns-item d-flex">
                        <v-icon dense v-if="column.type_generic === 1" color="#A2A2A2"
                          >mdi-format-text</v-icon>
                        <v-icon dense v-if="column.type_generic === 0" color="#A2A2A2"
                          >mdi-numeric</v-icon>
                        <div class="columns-item-text">
                          {{ column.column_name }} 
                          <v-tooltip v-if="isDatasetIndex(column)"
                          right>
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon dense
                                color="hover_blue"
                                dark
                                v-bind="attrs"
                                v-on="on"
                              >
                                mdi-information-variant
                              </v-icon>
                            </template>
                            <span><b>Dataset Index</b><br>
                              &ensp;<i>Cannot</i> create model on Dataset Index
                            </span>
                          </v-tooltip>
                        </div>
                        <!-- Model trained indicator status -->
                        <div class="trained_beaker_icons d-flex ml-auto">
                          <v-progress-circular
                            v-if="getColumnTrainedStatus(column.id) == 3"
                            indeterminate
                            :size = "16"
                            :width="2"
                            color="primary"
                          >
                          </v-progress-circular>
                          <v-icon dense 
                            v-else-if="getColumnTrainedStatus(column.id) == 2"
                          >
                            $beakerCheckIcon
                          </v-icon>
                          <v-icon dense 
                            v-else-if="getColumnTrainedStatus(column.id) == 1"
                          >
                            $beakerIcon
                          </v-icon>
                        </div>
                      </div>
                    </div>
                  </draggable>
              </div>
            </div>
          </div>
          <!-- Metrics -->
          <div class="metrics">
            <div class="metrics-header d-flex">
              <div class="metrics-header-text">Metrics</div>
              <div class="columns-header-description ml-auto" v-if="showCol">
                <!-- Showing 1 of {{datasource.columns.length}} -->
                Showing
                {{
                  filteredColumns(datasource.metrics, "metric_name").length
                }}
                of {{ datasource.metrics.length }}
              </div>
              <v-icon
                class="ml-auto"
                color="#B9B9B9"
                @click="showMetrics = !showMetrics"
                >mdi-chevron-down</v-icon
              >
            </div>
            <div class="metrics-container" v-if="datasource && showMetrics">
              <!-- <div v-for="(metric, index) in datasource.metrics" :key="index"> -->
              <div
                v-for="(metric, index) in filteredColumns(
                  datasource.metrics,
                  'metric_name'
                )"
                :key="index"
              >
                <draggable @choose="chooseMetric(metric)">
                  <div class="metrics-item d-flex">
                    <v-icon dense color="#A2A2A2">mdi-function-variant</v-icon>
                    <div class="metrics-item-text">{{ metric.expression }}</div>
                    <!-- <v-icon dense color="#B9B9B9" class="ml-auto">
                      mdi-information-outline
                    </v-icon> -->
                    <!-- class="ml-2"  -->
                    <v-tooltip right>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon 
                          v-bind="attrs" 
                          v-on="on"
                          class="ml-auto"
                          color="#B9B9B9"
                          small
                        >
                          mdi-information-outline
                        </v-icon>
                      </template>
                      <span>
                        Metric: {{ metric.expression }}<br/>
                        - TODO: metric definition & edit
                      </span>
                    </v-tooltip>
                  </div>
                </draggable>
              </div>
            </div>
          </div>
          <!-- prediction toolbox -->
          <div class="predictions">
            <div class="predictions-header d-flex">
              <!-- toolbox header -->
              <div class="predictions-header-text">Prediction Toolbox</div>
              <!-- toolbox header/buttons -->
              <div class="ml-auto">
                <!-- Prediction toolbox tooltip -->
                <v-tooltip right>
                  <template v-slot:activator="{ on, attrs }">

                    <v-icon 
                      v-bind="attrs" 
                      v-on="on"
                      class="ml-2" 
                      color="#B9B9B9"
                      dense
                    >
                      <!-- mdi-alert-circle-outline -->
                      mdi-information-outline
                    </v-icon>
                  </template>
                  <span>
                    Prediction/Toolbox<br/>
                    -See target/trained models here:<br/>
                    -Use model eval columns as dateset columns:<br/>
                    -Add models trained from other datasets to predict onto this dataset:
                  </span>
                </v-tooltip>
                <!-- load chars menu/icon -->
                <!-- <v-menu -->
                <v-dialog
                  v-model="visiblePredictionMenu"
                  :close-on-content-click="false"
                  :nudge-width="250"
                  top
                  offset-x
                  width="800px"
                  persistent
                >
                  <template v-slot:activator="{ on: on, attrs }">
                    <v-tooltip right>
                    <template v-slot:activator="{ on: on2, attrs: attrs2 }">
                      <v-icon 
                        class="ml-2"
                        color="#B9B9B9"
                        v-bind="{...attrs, ...attrs2}"
                        v-on="{...on, ...on2}"
                        dense
                      >mdi-plus-circle-outline</v-icon>
                    </template>
                    <span>
                      Predict Model <b>ONTO</b> this dataset
                    </span>
                    </v-tooltip>
                  </template>

                  <!-- Prediction Menu -->
                  <v-card class="prediction-menu"
                    color="modal_background"
                  >
                    <v-card-title>
                      <p>Load Chars for Prediction</p>
                    </v-card-title>
                    
                    <v-card-subtitle>
                      Choose Model&nbsp;&nbsp;&nbsp;
                    </v-card-subtitle>
                    <v-divider/>
                    <v-card-text>
                      {{ curTrainedChar }}
                      <v-row>
                        <v-col>
                          <v-progress-circular
                            indeterminate
                            color="primary"
                            v-if="!trainedCharsApiRetrieved"
                          ></v-progress-circular>
                          
                          <v-data-table v-else
                            group-by="dataset_id"
                            show-group-by
                            show-select
                            item-key="model_id"
                            dense
                            :single-select="true"
                            :search="modelSearch"
                            v-model="curTrainedChar"
                            :items="trainedChars"
                            :headers="loadHeaders"
                          >
                            <template v-slot:top>
                              <v-text-field
                                ref="searchRef"
                                v-model="modelSearch"
                                label="Search Trained Models"
                                prepend-inner-icon="mdi-magnify"
                                clearable
                                clear-icon="mdi-close"
                                class="mx-4"
                                @keydown.esc.prevent="modelSearch=''"
                              ></v-text-field>
                            </template>
                          </v-data-table>
                        </v-col>
                        <v-divider vertical inset/>
                        <v-col>
                          <v-card-subtitle>
                            Compare Columns/Features
                          </v-card-subtitle>
                          <div>

                          <v-data-table
                            dense
                            :items="columnCompareTable"
                            :headers="compareHeaders"
                            
                            disable-pagination
                          >
                            <template #[`item.match`]="{ item }">
                              <v-chip :color="getColor(item.match)" dark/>
                              </template>
                          </v-data-table>
                          <!-- item-class="matched" -->
                          </div>
                        </v-col>
                      </v-row>
                    </v-card-text>
                    <!-- <v-card-text>
                      <v-select
                        v-model="curTrainedChar"
                        :items="trainedChars"
                        item-text="column_name"
                        item-value="model_id"
                        label="Dataset Features"
                        solo
                        outlined
                        return-object
                        hide-details="true"
                        style="width: 250px"
                        :disabled = "!trainedCharsApiRetrieved"
                      ></v-select>
                    </v-card-text> -->
                    <v-divider/>
                    <v-card-actions>          
                      <v-btn
                      class="px-5"
                        outlined
                        color="gray"
                        @click="visiblePredictionMenu = false"
                      >
                        Cancel
                      </v-btn>
                      <v-spacer></v-spacer>
                      
                      <!-- :disabled="this.curTrainedChar==null" -->
                      <v-btn
                        color="#00A7FF"
                        class="px-5"
                        :disabled="curTrainedChar.length<1"
                        @click="addOtherDatasetTrainedCharIntoPredictionBox(curTrainedChar)"
                      >
                        Apply
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                <!-- </v-menu> -->
                </v-dialog>
                <!-- show prediction toolbox icon -->
                <v-icon
                  class="ml-auto pl-2"
                  color="#B9B9B9"
                  @click="showPrediction = !showPrediction"
                  >mdi-chevron-down
                </v-icon>
              </div>
            </div>
            <!-- predictions container -->
            <div
              class="predictions-container"
              @dragover.prevent
              @drop="dragColumnOnToPredictionToolbox($event)"
              v-if="showPrediction"
            >
              <template v-if="predictions.length > 0">
                <!-- 
                  If item has dataset_id without id 
                  it means it is trained from other dataset and won't be included in Train param
                -->
                <div class="d-flex mb-2" v-for="prediction in predictions"
                  :key="prediction.id ? prediction.id : prediction.dataset_id">
                  <!-- <v-btn
                    rounded
                    outlined
                    width="80%"
                    height="28px"
                    @contextmenu="showContextMenu($event, prediction)"
                  > -->
                  <v-btn
                    rounded
                    width="100%"
                    height="28px"
                    color="modal_background"
                    @contextmenu="showContextMenu($event, prediction)"
                  >
                    <div class="d-flex magic-pill">
                      <div class="magic-pill-colname">
                        <v-icon dense v-if="prediction.type_generic === 1" color="#A2A2A2">
                          mdi-format-text
                        </v-icon>
                        <v-icon dense v-if="prediction.type_generic === 0" color="#A2A2A2">
                          mdi-numeric
                        </v-icon>
                        <v-icon v-if="prediction.dataset_id">$algoIcon</v-icon>
                    
                    <!-- <div class="d-flex"> -->
                    <span> {{ prediction.column_name }}</span>
                    </div>
                    <div class="magic-pill-status">
                    <!-- Magic Wind icon will be hidden when this model_id is not included in DataSetChar 
                      and while not being trained
                      trainingJob.finished : parent training Job is finished
                      isChildJobFinished : child training Job is being finished
                    -->
                    
                    <v-icon small left
                      v-if="(isChildJobFinished(prediction.id)
                        && !prediction.dataset_id
                        && prediction.trained)" 
                    >$magicIcon
                    </v-icon>
                    <!-- <v-icon v-else-if="prediction.dataset_id">$algoIcon</v-icon> -->
                    <!-- <sp-icon-magic-wand
                      width="18" 
                      height="18" 
                      v-if="(isChildJobFinished(prediction.id)
                          && !prediction.dataset_id
                          && prediction.trained)" 
                    /> -->
                        <!-- && isModelInDatasetChars(prediction.id)"/> -->

                    <!-- while being trained, it should show the spinner icon for each column-->
                    <v-progress-circular
                      indeterminate
                      :size="18"
                      color="primary"
                      v-if="
                        trainingJob.finished !== true &&
                        !prediction.dataset_id &&
                        !isChildJobFinished(prediction.id)
                      "
                    ></v-progress-circular>
                    <!-- </div> -->
                    <!-- Algorithm icon will be only shown from external insert-->
                    <!-- <sp-icon-algorithm width="18" height="18" v-if="prediction.dataset_id"/> -->
                    <!-- <div class="d-flex"> -->
                    <v-icon dense
                    :disabled="trainingJob.finished !== true"
                    @click="removeColumnFromPredictionToolbox(prediction)"
                  > mdi-close-circle
                  </v-icon>
                    </div>
                    </div>
                  </v-btn>
                  <!-- <v-icon dense
                    :disabled="trainingJob.finished !== true"
                    @click="removeColumnFromPredictionToolbox(prediction)"
                  > mdi-close-circle
                  </v-icon> -->
                  <!-- <v-btn icon 
                    class="ml-auto" 
                    height="28" 
                    :disabled = "trainingJob.finished !== true"
                    @click="removeColumnFromPredictionToolbox(prediction)">
                    <sp-icon-close-circle color="#B9B9B9"/>
                  </v-btn> -->
                </div>
              </template>
              <template v-else>
                <div class="blank-drag">Drag here</div>
                <p class="blank-text">
                  Create Prediction Targets
                </p>
              </template>
            </div>
          </div>
          <!-- train button -->
          <div class="d-flex action">
            <v-btn
              color="primary"
              @click.native="trainPredictions"
              min-width="194"
              height="30"
              :disabled="
                predictions.filter((ele) => ele.id).length == 0 ||
                (this.trainingJob !== null &&
                  this.trainingJob.finished !== true)
              "
              >Train</v-btn
            >
            <!-- advanced button popup -->
            <v-icon color="#939393" small class="ml-2"
              @click.stop="showAdvancedModal"
            >mdi-cog
            </v-icon>
          </div>
        </v-tab-item>
        <!-- Analysis tab -->
        <v-tab-item class="vis-analysis-tab">
          <!-- Datasource edit -->
          <div class="table d-flex justify-content-start">
            <div>
              <v-btn
                class="ma-2"
                text-color="#B9B9B9"
                @click.stop="clickDatasource"
              >
                <v-icon dense color=grey-text >mdi-database-edit</v-icon>
                <div class="table-name">{{ datasource.table_name }}</div>
              </v-btn>
            </div>
          </div>
          <!-- search bar -->
          <div class="data-search">
              <!-- placeholder="Fields & Metrics" -->
              <!-- Model Parameters -->
              <v-text-field
                  v-model="colFilter"
                  placeholder="Model Filter"
                  dense
                  height="2"
                  prepend-icon="mdi-magnify"
                />
          </div>
          <!-- Model config button -->
          <div class="analysis-actions d-flex flex-column">
            <!-- config button -->
            <v-btn dense block
              @click="model_modal=true"
            >
            <v-icon color="grey_text" left dense>
              mdi-cog
            </v-icon>
              Model Configuration
            </v-btn>
            <!-- queue button TODO: disabled - enable for ml version -->
            <v-btn dense block
              disabled
            >
              <v-icon color="grey_text" dense left>
                mdi-application-cog-outline
              </v-icon>
                Open Queue
            </v-btn>
          </div>
          <!-- ?? -->
          <div class="columns">
            <div class="columns-header d-flex">
              <div class="columns-header-text">Models</div>
              <!-- <div class="columns-header-description ml-auto">
                Showing 4 of 128
              </div>
              <v-icon color="#B9B9B9">mdi-chevron-down</v-icon> -->
            </div>
              <!-- v-model="modelTreeObject" -->
              <div class="model-tree">
              <v-treeview
                :items="modelTreeObject"
                :search="colFilter"
                item-key="name"
                open-on-click
                hoverable
                dense
              >
                <!-- <template v-slot:prepend="{ item, open }"> -->
                <template v-slot:append="{ item }">
                  <v-icon v-if="item.icon" small >$magicIcon</v-icon>
                </template>
                <!-- @click="modelContextMenu=true" -->
                
                <template v-slot:label="{ item }">
                  <div v-if="!item.menu" class="treeview-item">{{ item.name }}</div>
                  <div v-else class="treeview-item"
                    @contextmenu.prevent="showModelContextMenu($event)"
                  >{{ item.name }}</div>
                </template>
              </v-treeview>
            </div>
          </div>
          <div class="columns">
            <!-- <div class="columns-header d-flex"> -->
              <div class="columns-header-text">Actions</div>
            <!-- </div> -->
              <div class="analysis-actions d-flex flex-column">
            <!-- config button -->
            <v-btn dense block
              @click.stop="openFlattenDatasetModal"
              :loading="flat_loading"
            >
            <v-icon color="grey_text" left dense>
              mdi-fingerprint
            </v-icon>
              Flatten Dataset
            </v-btn>
            </div>
          </div>
          <div class="d-flex action" style="height: 53px;">
            </div>
        </v-tab-item>
      </v-tabs>
    </div>
  </div>
</template>

<script>
import "@spectrum-web-components/icons-workflow/icons/sp-icon-magic-wand.js";
import "@spectrum-web-components/icons-workflow/icons/sp-icon-beaker.js";
import "@spectrum-web-components/icons-workflow/icons/sp-icon-beaker-check.js";
import "@spectrum-web-components/icons-workflow/icons/sp-icon-close-circle.js";
import "@spectrum-web-components/icons-workflow/icons/sp-icon-algorithm.js";

import NewDB from "@/components/modal/NewDB.vue";
import NewFromDB from "@/components/modal/NewFromDB.vue";
import AdvancedModal from "@/views/models/modals/AdvancedModal.vue"
// import ColumnContextMenu from "./VizData/ColumnContextMenu.vue"
// import NewDB from "@/views/visualization/components /modal/NewDB.vue";

import {
  TRAIN_MODEL,
  GET_SPADE_JOB_STATUS,
  GET_DATASET_CHAR_BY_ID,
  CREATE_CHAR,
  GET_CHARS,
  CREATE_PREDICTION,
  GET_PREDICTION,
  GET_EVALUATION,
  POST_CHART_FORM,
  GET_DATASET_ALL, GET_DATASET_BY_ID,
  PUT_DATASET_CHAR_INDEX,
  DELETE_CHAR,
  TRAIN_EMBEDDING,
  POST_EMBEDDING,
  GET_EMBEDDING,
} from "@/store/actionType";

export default {
  name: "VisData",
  components: {
    NewDB,
    NewFromDB,
    AdvancedModal,
    // ColumnContextMenu,
  },
  props: {
    //params
    vis_type: {
      type: String,
      default() {
        return null;
      },
    },
    datasource: {
      type: Object,
      default() {
        return null;
      },
    },
  },
  data() {
    return {
      compareHeaders: [
        {
            text: `Dataset Cols`,
            align: 'start',
            value: 'column_name',
            groupable: true,
            matched:"matched",
          },
          {
            text: `Features Cols`,
            align: 'start',
            value: 'feature_name',
            groupable: true,
            matched:"matched",
          },
          {
            text: 'Is Matched',
            align: 'start',
            value: 'match',
            groupable: true,
            matched:"match",
          },
      ],
      modelSearch: "",
      loadHeaders: [
        {
            text: 'Dataset',
            align: 'start',
            value: 'dataset_id',
            groupable: true,
          },
        {
            text: 'Model Name',
            align: 'start',
            value: 'text',
            groupable: false,
          },
      ],
      n_clusters: 0,
      flattenCluster: false,
      flattenSupervised: false,
      // model contet menu
      modelContextMenu: false,
      // row context menu
      rowMenuItem: null,
      showRowMenu: false,
      ///
      datasetIndexCol: null,
      model_modal: false,
      activeTab: 0,
      // activeTab: 1, // delete this to go back to data tab on load
      //collpase vis data left men
      collapse: false,
      //predictions
      predictions: [],
      // Current Prediction
      curPrediction: null,
      // Show Prediction Modal
      visiblePredictionMenu: false,
      // visiblePredictionMenu: true,
      visibleShowNewDatasetFromDBModal: false,
      visibleShowNewDBModal: false,
      trainedChars: [],
      trainedCharsApiRetrieved: false, // used for trainedchars api returned
      // curTrainedChar: null,
      curTrainedChar: [],
      datasetChars: null,
      //show & hidden search
      showSearch: true,
      //search distinct
      distinct: "",
      //show & hidden column
      showCol: true,
      //show & hidden metrics
      showMetrics: true,
      //show & hidden prediction
      showPrediction: true,
      //snackbar variables
      visibleSnackbar: false,
      //snackbar message
      snackbarMessage: "",
      //snackbar Color
      snackbarColor: "#00BAB9",
      //Train Model Job Status Polling Interval
      trainingInterval: null,
      trainingJob: { finished: true }, // we assume initial operation is done
      trainingModelIds: [], // Indicate currently being trained model ids (column ids)
      spadeJobStatuses: [], // used to contain polling job status
      visibleOnToContextMenu: false,
      visibleNotTrainContextMenu: false,
      visibleTrainedContextMenu: false,
      x: 0,
      y: 0,
      snackbarItems: [],
      datasets: [],
      // menu when shows when Predict Model onto new Dataset clicked
      visibleDatasetMenu: false,
      newDataset: null,
      colFilter: "",
      // columns: ['blah','test','abs']
      flat_loading: false,
      flattenModal: false,
      // flattenModal:true,
      flattenModelId: null,
    };
  },
  computed: {
    columnCompareTable() {
      // const datasetId = this.datasource.id
      const datasetColumns = this.datasource.columns.map( col =>{
        return col.column_name
      })
      let colResult;
      if ( this.curTrainedChar.length>0 ) {
        // const compareId = this.curTrainedChar.dataset_id;
        const compareId = this.curTrainedChar && this.curTrainedChar[0] ? 
          this.curTrainedChar[0].dataset_id : 
          null;
        const compareCols = this.datasets.filter( df => {
          return df.id === compareId
        })
        .map( table => table.columns)[0];
        
        // console.log('////// full join //////', compareCols)
        
      
        colResult = this.fullJoin(this.datasource.columns, compareCols);
        // colResult = [];
      } else {
        colResult = [];
      }
      // console.log(this.fullJointhis.datasource.columns, compareCols)

      return colResult;//this.fullJoin(this.datasource.columns, compareCols)//this.datasource.columns
    },
    datasetCharColumns() {
      // console.log('comps////////////')
      if (this.datasetChars) {
      const trainedcols = this.datasource.columns.filter( (col) => {
        return this.datasetChars.some((mdl) =>{
          return mdl.model_id === col.id
        })
      })//.map (x=> x.column_name)
      // console.log(trainedcols)
      
      // return this.datasetChars ? this.datasetChars : [];
      return trainedcols;
      }
      else return []


      // for (const id in this.datasetChars) {
      //   let indx = this.datasetChars[id]
      //   let col = this.datasource.columns.filter(
      //     a=>indx.model_id === a.id
      //   )
    },

    modelTreeObject() {

      // let colsWithModels = this.datasource.columns.filter(a=>this.model_id_list.includes(a.id))
      // console.log('model cols', colsWithModels)

      // let treeArray=[
      //   {
      //     "name":"col1",
      //     "icon": `$algoIcon`,
      //     children: [
      //       {
      //         name: 'static',
      //         children: [{
      //           name: 'logo.png',
      //           file: 'png',
      //         }],
      //   }]},
      //   {
      //     "name": "col2",
      //     "icon": `$algoIcon`,
      //   },
      // ];

      let treeArray=[];
      for (const id in this.datasetChars) {
        let indx = this.datasetChars[id]
        let col = this.datasource.columns.filter(
          a=>indx.model_id === a.id
        )[0]
        // console.log('model cols', col)
        // console.log('char', indx)
        
        let treeObj = {
          "name": col.column_name,
          "icon": indx.clf.exists,
          children: [
            {
              name: `index : ${indx._id}`
            },
          ]
        };

        let estimator; 
        if(indx.clf.exists) {
          estimator = {
            name: `Estimator: ${indx.cfg.base_estimator}`,
            children: [
                {name: `Trained: ${this.unixTimeToDate(indx.clf.ts)}`}
              ]
            }
          treeObj.children.push(estimator)
        } 

        let labels;
        if(indx.labels.exists) {
          labels = {
            name: `Labels: ${this.unixTimeToDate(indx.labels.ts)}`,
            menu: 'labels',
            }
          treeObj.children.push(labels)
        }

        treeArray.push(treeObj)
      }
      // console.log("tree", treeArray)
      return treeArray
    },

    model_id_list () {
      let id_list=[]
      for (const i in this.datasetChars) {
        
        id_list.push(this.datasetChars[i].model_id)
      }
      // console.log('iter', id_list)
      return id_list
    },

    sel_col: {
      get() {
        return this.$store.state.chart.sel_col;
      },
    },

    source: {
      get() {
        return this.datasource
      } 
    }
  },

  methods: {
    fullJoin(left, right) {
      let results = [];
      for (let i=0; i<left.length; i++) {
          let found = false;
          for (let j=0; j<right.length; j++) {
              if (left[i].column_name === right[j].column_name) {
                  results.push({
                      column_name: left[i].column_name, 
                      feature_name: left[i].column_name,
                      match: 1,
                  });
                  found = true;
                  break;
              }
          }
          if (found === false) {
              results.push({
                  column_name: left[i].column_name,
                  feature_name: null,
                  match: 0,
              });    
          }
      }
      for (let i=0; i<right.length; i++) {
          let found = false;
          for (let j=0; j<left.length; j++) {
              if (left[j].column_name === right[i].column_name) {
                  results.push({
                      column_name: left[j].column_name, 
                      feature_name: left[j].column_name,
                      match: 1,
                  });
                  found = true;                
              }
          }
          if (found === false) {
              results.push({
                  column_name: null, 
                  feature_name: right[i].column_name,
                  match: 0,
              });    
          }
      }

      // remove duplicates
      let duplicates=[];
      for (let i=0; i< results.length; i++) {
          duplicates[JSON.stringify(results[i])] = results[i];
      }

      results = [];
      for (let key in duplicates) {
          results.push(duplicates[key]);
      }

      ////
      return results
    },
    // fullJoin(left, right) {
    //   let results = [];
    //   // console.log('left', right)
    //   for (let i=0; i<left.length; i++) {
    //       let found = false;
    //       for (let j=0; j < right.length; j++) {
    //           if (left[i].column_name === right[j].column_name) {
    //             // console.log('iter',i, j, left[i].column_name, right[j].column_name)
    //               results.push({
    //                   column_name: left[i].column_name,
    //                   feature_name: left[i].column_name,
    //                   column_match: 1,
    //               });
    //               found = true;
    //               break;
    //           }
    //       }
    //       if (found === false) {
    //           results.push({
    //               column_name: left[i].colummn_name, 
    //               feature_name: null,
    //               column_match: 0,
    //           });    
    //       }
    //   }
    //   for (let i=0; i < right.length; i++) {
    //     let found = false;
    //     for (let j=0; j < left.length; j++) {
    //       console.log('iter',i, j, right[i].column_name, left[j].column_name)
    //         if (left[j].column_name === right[i].column_name) {
              
    //             results.push({
    //                 column_name: right[i].column_name, 
    //                 feature_name: right[i].column_name,
    //                 column_match: 1,
    //             });
    //             found = true;     
    //             break;           
    //         }
    //     }
    //     if (found === false) {
    //         results.push({
    //             column_name: null,
    //             feature_name: right[i].column_name,
    //             column_match: 0,
    //         });    
    //     }
    //   }

    //   // remove duplicates
    //   let duplicates=[];
    //   for (var i=0; i< results.length; i++) {
    //     duplicates[JSON.stringify(results[i])] = results[i];
    //   }

    //   // results = [];
    //   // for (let key in duplicates) {
    //       // results.push(duplicates[key]);
    //   // }
    //   // console.log('results', results)
    //   return results;
    // },
    unixTimeToDate(ts) {
      return new Date(ts * 1000).toLocaleDateString("default");
    },

    include () {
        return [document.querySelector('.included')]
    },

    isDatasetIndex(column) {
      if (column.id === this.datasetIndexCol) {
        return true
      } else {
        return false
      }
      // this.columns
      // console.log('indx', column.id, column.column_name)
      // console.log('indx data', this.datasetIndexCol)
    //   return true
    },

    addAllColumns() {
      this.$emit("addAllColumns")
    },

    showModelContextMenu(e) {
      e.preventDefault()
      this.modelContextMenu=true;
      this.x = e.clientX;
      this.y = e.clientY;
      // this.$nextTick()
    },

    rowContextMenu(e, item) {
      e.preventDefault()
      this.rowMenuItem = item;
      this.showRowMenu=true;
      this.x = e.clientX;
      this.y = e.clientY;
      // this.$nextTick()
    },

    closeContextMenu() {
      this.rowMenuItem=null;
    },

    doRightClick() {
      console.log('riht click')
    },

    cancelAdvModal() {
      this.model_modal=false;
    },

    showAdvancedModal() {
      this.model_modal=true;
    },

    clickDatasource() {
      this.$emit('clickDatasetModal')
    },
    
    hideMenu() {
    /**
     * hide menu
     */
      this.collapse = true;
    },
    
    showMenu() {
    /**
     * show menu
     */
      this.collapse = false;
    },

    filteredColumns(cols, column_name) {
      const filterVal = this.colFilter ? this.colFilter : '';

      const myfilter = cols.filter(
        (col) => col[column_name].includes(
          filterVal.toLowerCase(),
        ),
      );
      return myfilter;
    },

    /**
     * choose column
     * @param col
     */
    chooseCol(col) {
      this.$store.commit("SELECTED_COL", col);
      this.$store.commit("SELECTED_METRIC", null);
    },

    /**
     * choose metric
     * @param metric
     */
    chooseMetric(metric) {
      this.$store.commit("SELECTED_COL", null);
      this.$store.commit("SELECTED_METRIC", metric);
    },

    /**
     * drag prediction
     * @param event
     */
    dragColumnOnToPredictionToolbox(event) {
      // TODO: incorporate this.postChar into this method
      if (this.datasetChars === null) {
        return;
      }
      let find = this.predictions.find(
        (item) => item.column_name === this.sel_col.column_name
      );

      if (!find) {
        // Call POST Chars
        const datasetChar = this.datasetChars.find(
          (ele) => ele.model_id === this.sel_col.id
        );

        if (datasetChar && datasetChar.clf && datasetChar.clf.exists == true) {
          this.sel_col.trained = true;
          console.log('DatasetChar Trained: ', true)
        }
        else if (!datasetChar || !datasetChar.cfg) {
          let params;
        if (this.datasetIndexCol) {
          params = {
            column_id: this.sel_col.id,
            index_column_id: this.datasetIndexCol,
          }
        } else {
          params = {
            column_id: this.sel_col.id,
          }
        }
          console.log('Create Char: ', params)
          this.trainingJob.finished = false
          this.$store
            .dispatch(CREATE_CHAR, params)
            .then((r) => {
              // Update DatasetChars
              this.loadDatasetChars();
            })
            .catch((error) => {
              this.visibleSnackbar = true;
              this.snackbarColor = "warning";
              this.snackbarMessage = error.message
                ? error.message + ". Please remove and readd "
                : "Error occured";
            })
            .finally(() => {
              this.trainingJob.finished = true;
              console.log("Training Job Finished:");
            });
        }

        this.predictions.push(this.sel_col);
        console.log(this.predictions);
      }
    },

    deleteChar () {
      console.log('deleteChar', this.datasetChars, this.rowMenuItem.id)
      let char = this.datasetChars.find( a => a.model_id === this.rowMenuItem.id )

      // console.log('adfadsf', char)
      this.$store
          .dispatch(DELETE_CHAR, char.pk)
          .then((r) => {
            // Update DatasetChars
            this.loadDatasetChars()
          })
          .catch((error) => {
            this.visibleSnackbar = true;
            this.snackbarColor = "warning";
            this.snackbarMessage =  error.message ? error.message : 'Error occured'
          })
    },

    postCharAction () {
      console.log('postCharMenu', this.datasetIndexCol)
      this.postChar(this.rowMenuItem.id)
    },

    trainCharAction() {
      this.trainPrediction(this.rowMenuItem)
    },

    postChar(col_id) {
      // console.log('postChar', col_id) // this.rowMenuItem.id)
      const datasetChar = this.datasetChars.find((ele) => ele.model_id === col_id);
      console.log('postChar', this.datasetChar) // this.rowMenuItem.id)
      
      if (datasetChar && datasetChar.clf && datasetChar.clf.exists == true) {
        console.log('Char exists: ', datasetChar)
        // this.sel_col.trained = true;
        console.log('DatasetChar Trained: ', true)
      } else if (!datasetChar || !datasetChar.cfg) {
        let params;
        if (this.datasetIndexCol) {
          params = {
            column_id: col_id,
            index_column_id: this.datasetIndexCol,
          }
        } else {
          params = {
            column_id: col_id,
          }
        }
        console.log('Create Char: ', params)
        this.trainingJob.finished = false
        this.$store
          .dispatch(CREATE_CHAR, params)
          .then((r) => {
            // Update DatasetChars
            this.loadDatasetChars()
          })
          .catch((error) => {
            this.visibleSnackbar = true;
            this.snackbarColor = "warning";
            this.snackbarMessage =  error.message ? error.message + ". Please remove and readd " : 'Error occured'
          })
          .finally(() => {
            this.trainingJob.finished = true
            console.log('Training Job Finished:')
          })
      }
      // TODO??
      //   this.predictions.push(this.sel_col)
      //   console.log(this.predictions);
    },

    updateDatasetCharClf(modelName, status) {
      const datasetChar = this.datasetChars.find(
        (ele) => ele.model_name === modelName
      );
      if (!datasetChar) {
        return;
      }

      if (!datasetChar.clf) {
        datasetChar.clf = {};
      }
      datasetChar.clf.exists = status;
    },

    // Same function as this.getJobStatus
    // reduce to poll and move the post processing (open page, etc)
      // to outer function
    // pollJobStatus(id, isTraining, datasetId) {
    async pollJobStatus() {
      let id = this.trainingJob.job_id;
      const _this = this;
      /**
       * Sample output of Spade Job Status
       * {
            "job_status": [
                {
                    "job_id": "49ce6753-4075-4a4a-9228-d19cfd0e03e4",
                    "model_id": 13,
                    "model_names": "1-demo-chest",
                    "status": "started"
                }
            ],
            "parent_job_id": "49ce6753-4075-4a4a-9228-d19cfd0e03e4",
            "parent_status": "started"
        }
       */
      let response = await this.$store
        .dispatch(GET_SPADE_JOB_STATUS, id)
        .then((r) => {
          // return r.data.result
        // })
        // return response 
          /**
           * It polls the Job Status Id of Parent Job and we can find
           * individual child job statuses inside array.
           * Note: Same number of Child Jobs are created from number of columns(models) in Prediction Toolbox
          */

          // console.log('Polling: ', r.data);
          _this.spadeJobStatuses = r.data.result.job_status
          const result = r.data.result
          if (result.parent_status == 'finished') {
            const unfinishedJob = result.job_status.find(
              (ele) => ele.status !== 'finished'
            );
            if (!unfinishedJob) {
              if (_this.trainingJob.finished !== true) {
                  // All child jobs are finished
                clearInterval(_this.trainingInterval);
                // _this.getPredictions(result.job_status, isTraining, datasetId);
                // return r.data.result
                // TODO: sort this out to work generally. Right now it only 
                  // will work with a singleton job (parent job-id = child job-id)\
                return {
                  job_id: id,
                  status: "finished"
                }
              }
            }
          } else if (result.parent_status == 'failed') {
            if (_this.trainingJob.finished !== true) {
              // All child jobs are failed
              clearInterval(_this.trainingInterval);
            }
            this.visibleSnackbar = true;
            this.snackbarColor = "warning";
            this.snackbarMessage =  "Job Failed"
            this.trainingModelIds = [];
            this.trainingJob.finished = true
            // return r.data.result
            return {
                  job_id: id,
                  status: "failed"
                }
          // } else {
          //   return r.data.result
          // }
          } else if (result.parent_status == 'started') {
            return {
                  job_id: id,
                  status: "running"
                }
          } else if (result.parent_status == 'queued') {
            // return r.data.result
            return {
                  job_id: id,
                  status: "queued"
                }
          }
        })
        return response
    },

    /**
     * Get Job Status
     */
    getJobStatus(id, isTraining, datasetId) {
      const _this = this;
      /**
       * Sample output of Spade Job Status
       * {
            "job_status": [
                {
                    "job_id": "49ce6753-4075-4a4a-9228-d19cfd0e03e4",
                    "model_id": 13,
                    "model_names": "1-demo-chest",
                    "status": "started"
                }
            ],
            "parent_job_id": "49ce6753-4075-4a4a-9228-d19cfd0e03e4",
            "parent_status": "started"
        }
       */
      this.$store.dispatch(GET_SPADE_JOB_STATUS, id).then((r) => {
        /**
         * It polls the Job Status Id of Parent Job and we can find
         * individual child job statuses inside array.
         * Note: Same number of Child Jobs are created from number of columns(models) in Prediction Toolbox
         */
        console.log("Polling: ", r.data);
        _this.spadeJobStatuses = r.data.result.job_status;
        const result = r.data.result;
        if (result.parent_status == "finished") {
          const unfinishedJob = result.job_status.find(
            (ele) => ele.status !== "finished"
          );
          if (!unfinishedJob) {
            if (_this.trainingJob.finished !== true) {
              // All child jobs are finished
              clearInterval(_this.trainingInterval);
              _this.getPredictions(result.job_status, isTraining, datasetId);
            }
          }
        } else if (result.parent_status == "failed") {
          if (_this.trainingJob.finished !== true) {
            // All child jobs are failed
            clearInterval(_this.trainingInterval);
          }
          this.visibleSnackbar = true;
          this.snackbarColor = "warning";
          this.snackbarMessage = "Job Failed";
          this.trainingModelIds = [];
          this.trainingJob.finished = true;
        }
      });
    },

    /**
     * Get Predictions of all child job statuses
     */
    getPredictions(childJobStatuses, isTraining, datasetId) {
      // Construct individual prediction api dispatch
      const predictionDispatches = childJobStatuses.map((jobStatus) => {
        let param = {
          modelId: jobStatus.model_id,
          postPredictions: 1,
        };

        if (datasetId) {
          param.datasetId = datasetId;
        }

        if (isTraining) {
          param.jobId = "train";
          param.datasetId = this.datasource.id;
        } else {
          param.jobId = jobStatus.job_id;
        }
        return this.$store.dispatch(GET_PREDICTION, param);
      });

      const _this = this;
      Promise.all(predictionDispatches)
        .then((results) => {
          console.log("Get Predictions:", results);
          // Construct Post Chart Form Api dispatches
          const postChartFormDispatches = results.map((r) => {
            const chartFormParam = {
              datasource: r.data.result.dataset_id,
              viz_type: "table",
            };

            // Search from Prediction (Prediction Toolbox) and update proper prediction status
            let _prediction = _this.predictions.find(
              (ele) => ele.id === r.data.result.model_id
            );
            if (_prediction) {
              _prediction.trained = true;
              _prediction.trained_dataset_id = r.data.result.dataset_id;
            }

            return _this.$store.dispatch(POST_CHART_FORM, chartFormParam);
          });
          return Promise.all(postChartFormDispatches);
        })
        .then((results) => {
          console.log("Post Chart Form Result:", results);
          this.visibleSnackbar = true;
          const modelNames = childJobStatuses.map((ele) => ele.model_names);

          this.snackbarMessage = `Success: Predicted from ${modelNames.join()}`;
          // Construct Get Chart Form Api dispatches
          this.snackbarItems = results.map((r) => {
            return {
              key: r.data.key,
              dataset_id: JSON.parse(r.config.data).datasource,
            };
          });
        })
        .catch((error) => {
          console.log("Error Handling in Get Prediction: ");
          this.snackbarItems = [];
          this.visibleSnackbar = true;
          this.snackbarColor = "warning";
          this.snackbarMessage = "Error in Training & Get Predictions";
        })
        .finally(() => {
          this.trainingJob.finished = true;
          this.spadeJobStatuses = [];
          this.trainingModelIds = [];
          this.$forceUpdate();
          // Update DatasetChars
          this.loadDatasetChars();
          console.log("Training Job Finished:");
        });
    },
    
    openFlattenDatasetModal() {
      this.flattenModal=true;
    },

    async flattenDataset() {
      // TODO: make this a parameterized func, not global
      const modelId = this.flattenModelId
      console.log('flat me', modelId)
      this.flattenModal=false;

      let id_col=[{column_name:"_id"}];
        if ( this.datasetIndexCol ) {
          id_col = this.datasource.columns
            .filter( col => col.id === this.datasetIndexCol )
        }
      console.log('debug me')
      const trainStatus = await this.trainEmbedding(modelId)

      const embeddingStatus = await this.postEmbedding(modelId, this.n_clusters, this.datasource.id)
      const getEmbeddingResult = await this.getEmbedding(modelId, 'embedding', this.datasource.id)
      if (getEmbeddingResult.status === 'success') {
        await this.finishGetDataset(getEmbeddingResult.result, id_col[0].column_name)
      }
    // console.log('embadding', embeddingStatus)
    // reset Embedding options
    this.n_clusters=0;
    this.flattenCluster=false;
    this.flattenSupervised=false;
    this.flattenModelId=null;
    },
    

    async finishGetDataset(datasetMetadata, id_col){
      const flatDatasetId=datasetMetadata.dataset_id
      
      const flatDataset = await this.getDataset(flatDatasetId)
      console.log('flatDataset', id_col)


      const colKeys = ['x_plt', 'y_plt']
      let datasetCols = {};
      flatDataset.columns
        .filter( col => colKeys.includes(col.column_name) )
        .map(col => {
          const colname = col.column_name
          datasetCols[colname] = col;
        })

      console.log('datasetCols', datasetCols)
      const x = {
          column: datasetCols.x_plt,
          expressionType: "SIMPLE",
          hasCustomLabel: false,
          isNew: false,
          label: "AVG(x_plt)",
          aggregate: "AVG",
          sqlExpression: null,
        };
      const y = {
          column: datasetCols.y_plt,
          expressionType: "SIMPLE",
          hasCustomLabel: false,
          isNew: false,
          label: "AVG(y_plt)",
          aggregate: "AVG",
          sqlExpression: null,
        };
      // console.log('flat me indx', this.datasetIndexCol)
      let chartFormParam = {
        datasource: datasetMetadata.dataset_id,
        viz_type: 'scatter',
        entity: id_col,
        x: x, y: y,
      }
      if (this.n_clusters > 0) {
        chartFormParam.series = "cluster_category";
      }
    console.log('post form///////////', this.n_clusters, chartFormParam)
    const chartForm = await this.$store.dispatch(POST_CHART_FORM, chartFormParam)
      .then((results) => {
        console.log('Post Chart Form Result:', results);
        this.visibleSnackbar = true
        const modelNames = datasetMetadata.tableName
        // console.log('snackbar data', results.data.key, JSON.parse(results.config.data).datasource)
        this.snackbarMessage = `Success: Predicted from ${modelNames}`
        this.snackbarItems = [{
              'key': results.data.key, 
              'dataset_id':  JSON.parse(results.config.data).datasource
            }]
      })
      .catch((error) => {
          console.log('Error Opening Embedding Dataset: ', error)
          this.snackbarItems = []
          this.visibleSnackbar = true;
          this.snackbarColor = "warning";
          this.snackbarMessage =  "Error in Embedding Dataset"
        })
      // console.log('open dataset predictions')
    },

    async getEmbedding(model_id, suffix, datasetId) {
      // TODO: recreated this.getPredictions as parameterized func
      /// Make them the same
      // const predictionDispatches = childJobStatuses.map((jobStatus) => {
      const n_rows="None";
      let result;
      let param = {
          modelId: model_id,
          datasetId: datasetId,
          postData: 1,
          n_rows: n_rows,
        }
        const status = await this.$store.dispatch(GET_EMBEDDING, param)
        .then((r) => {
          return {status: 'success', result: r.data.result }
        })
        .catch((error) => {
          console.log('Error Handling in Get Embedding: ', error)
          this.snackbarItems = []
          this.visibleSnackbar = true;
          this.snackbarColor = "warning";
          this.snackbarMessage =  "Error in Get Embedding"
          return {status: 'failed', result: {} }
        })

        // console.log('my emb//////', status)
        return status
        
    },

    async postEmbedding(model_id, n_cluster, datasetId) {
      const n_rows="None";
      const params = {
        modelId: model_id,
        n_clusters: n_cluster,
        n_rows: n_rows,
        body: {datasetId: datasetId},
      }
      this.trainingJob = {'finished': false }
      // Hide the snackbar if any
      this.visibleSnackbar = false;
      // let status;
      let job_id = await this.$store
        .dispatch(POST_EMBEDDING, params)
        .then((r) => {
          this.trainingJob = r.data.result;
          // console.log('result', r.data.result)
          return r.data.result
        })
        .catch((error) => {
          console.log('Error Handling in POST Embedding: ', error)
          this.trainingJob = {'finished': true }
          this.trainingModelIds = []
          this.visibleSnackbar = true;
          this.snackbarColor = "warning";
          this.snackbarMessage =  error.message ? error.message : 'Error in creating Embedding'
          this.flat_loading=false;
          // status = 'failed'
        });
        ////

        console.log('start poll', job_id)
        const poll = async ({fn, validate, interval, maxAttempts}) => {
          let attempts = 0;
          const executePoll = async (resolve, reject) => {
            // console.log('- poll');
            const result = await fn();
            attempts++;

            console.log('- poll', result);
            if (validate(result)) {
              return resolve(result)
            } else if (maxAttempts && attempts == maxAttempts) {
              return reject(new Error('Exceeded Max Attempts'));
            } else {
              setTimeout(executePoll, interval, resolve, reject)
            }
          };
          return new Promise(executePoll)
        }
        
        const validateUser = status => ['finished','failed'].includes(status.status) ;
        const POLL_INTERVAL = 2000;
        const maxAttempts = 100;

        const pollResult = await poll({
          fn: this.pollJobStatus,
          validate: validateUser,
          interval: POLL_INTERVAL,
          maxAttempts: maxAttempts,
        })
          .then(r => {
            // console.log('now what?', r)
            if ( r.status === 'failed' ) {
              throw r;
            }
            this.flat_loading=false
            let status = 'success'
            return status
          })
          .catch(err => {console.error(err)
            let status = 'failed'
            this.visibleSnackbar = true;
            this.snackbarColor = "warning";
            this.snackbarMessage = `Error in creating Embedding, job_id: ${job_id.job_id}`
            return status
          });
      console.log('end pooll', pollResult, job_id)
      return {status: pollResult, jobStatus: job_id}
    },

    async trainEmbedding(model_id) {
      let n_rows="None";
      let params = {
        modelId: model_id,
        n_rows: n_rows
      }
      this.trainingJob = {'finished': false }
      // Hide the snackbar if any
      this.visibleSnackbar = false;
      // let status;
      let job_id = await this.$store
        .dispatch(TRAIN_EMBEDDING, params)
        .then((r) => {
          this.trainingJob = r.data.result;
          console.log('result', r.data.result)
          return r.data.result
        })
        .catch((error) => {
          console.log('Error Handling in Train Prediction: ')
          this.trainingJob = {'finished': true }
          this.trainingModelIds = []
          this.visibleSnackbar = true;
          this.snackbarColor = "warning";
          this.snackbarMessage =  error.message ? error.message : 'Error occured'
          this.flat_loading=false;
          // status = 'failed'
        });
        ////

          console.log('start pooll', job_id)
          const poll = async ({fn, validate, interval, maxAttempts}) => {
            let attempts = 0;
            const executePoll = async (resolve, reject) => {
              // console.log('- poll');
              const result = await fn();
              attempts++;

              console.log('- poll', result);
              if (validate(result)) {
                return resolve(result)
              } else if (maxAttempts && attempts == maxAttempts) {
                return reject(new Error('Exceeded Max Attempts'));
              } else {
                setTimeout(executePoll, interval, resolve, reject)
              }
            };
            return new Promise(executePoll)
          }
          
          const validateUser = status => ['finished','failed'].includes(status.status) ;
          const POLL_INTERVAL = 2000;
          const maxAttempts = 100;

          const pollResult = await poll({
            fn: this.pollJobStatus,
            validate: validateUser,
            interval: POLL_INTERVAL,
            maxAttempts: maxAttempts,
          })
            .then(r => {
              console.log('now what?', r)
              this.flat_loading=false
              let status = 'success'
              return status
            })
            .catch(err => {console.error(err)
              let status = 'failed'
              return status
            });
        console.log('end pooll', pollResult)
        return pollResult
    },

    /**
     * train predictions
     */
    trainPredictions() {
      // n_rows: None at this moment
      this.trainingModelIds = this.predictions
        .filter((ele) => ele.id)
        .map((ele) => ele.id);

      const params = {
        charIDs: this.trainingModelIds,
      };

      this.trainingJob = { finished: false }; // disable train button during training model api call
      // Hide the snackbar if any
      this.visibleSnackbar = false;
      this.$store
        .dispatch(TRAIN_MODEL, params)
        .then((r) => {
          this.trainingJob = r.data.result;

          if (r.data.result.job_id) {
            this.trainingInterval = setInterval(() => {
              this.getJobStatus(r.data.result.job_id, true);
            }, 2000);
          }
        })
        .catch((error) => {
          console.log("Error Handling in Train Prediction: ");
          this.trainingJob = { finished: true };
          this.trainingModelIds = [];
          this.visibleSnackbar = true;
          this.snackbarColor = "warning";
          this.snackbarMessage = error.message
            ? error.message
            : "Error occured";
        })
        .finally(() => {})
    },

    /**
     * train one prediction
     */
    trainPrediction(prediction) {
      // n_rows: None at this moment
      this.trainingModelIds = [prediction.id];

      const params = {
        charIDs: this.trainingModelIds,
      };

      // disable train button during training model api call
      this.trainingJob = {'finished': false }
      // Hide the snackbar if any
      this.visibleSnackbar = false;
      this.$store
        .dispatch(TRAIN_MODEL, params)
        .then((r) => {
          this.trainingJob = r.data.result;

          if (r.data.result.job_id) {
            this.trainingInterval = setInterval(() => {
              this.getJobStatus(r.data.result.job_id, true);
            }, 2000);
          }
        })
        .catch((error) => {
          console.log("Error Handling in Train Prediction: ");
          this.trainingJob = { finished: true };
          this.trainingModelIds = [];
          this.visibleSnackbar = true;
          this.snackbarColor = "warning";
          this.snackbarMessage =  error.message ? error.message : 'Error occured'
        })
        .finally(() => {})
    },

    /**
     * Get Dataset Char
     */
    loadDatasetChars() {
      this.$store
        .dispatch(GET_DATASET_CHAR_BY_ID, this.datasource.id)
        .then((r) => {
          // console.log('load datasetChars', r.data.result)
          this.datasetChars = r.data.result.cfg_metadata;
          this.datasetIndexCol = r.data.result.dataset.id_column;
        })
        .catch((error) => {
          this.visibleSnackbar = true;
          this.snackbarColor = "warning";
          this.snackbarMessage =  error.message ? error.message : 'Error occured while loading datachar'
        })
    },

    putDatasetCharIndex(colId) {
      // console.log('put me', colId)
      this.datasetIndexCol = colId
      let params = [this.datasource.id, this.datasetIndexCol]
      this.$store
        .dispatch(PUT_DATASET_CHAR_INDEX, params)
        .then((r) => {
          // console.log(' put result', r)
          // this.datasetIndexCol = colId
          // this.datasetChars = r.data.result.cfg_metadata;
          // this.datasetIndexCol = r.data.result.dataset.id_column;
        })
        .catch((error) => {
          this.visibleSnackbar = true;
          this.snackbarColor = "warning";
          this.snackbarMessage = error.message
            ? error.message
            : "Error occured while loading datachar";
        });
    },

    /**
     * Get Trained Chars
     */
    loadTrainedChars() {
      this.trainedCharsApiRetrieved = false
      
      this.$store
        .dispatch(
          GET_CHARS,
          `isTrained=true&current_dataset_id=${this.datasource.id}`
        )
        .then((r) => {
          // console.log('load trained chars on laod', r)
          this.trainedChars = r.data.result
          // console.log('Trained Chars: ', this.trainedChars)
        })
        .catch((error) => {
          this.visibleSnackbar = true;
          this.snackbarColor = "warning";
          this.snackbarMessage = error.message
            ? error.message
            : "Error occured while loading trained chars";
        })
        .finally(() => {
          this.trainedCharsApiRetrieved = true;
        });
    },

    getDataset(datasetId) {

      const dataset  = this.$store.dispatch(GET_DATASET_BY_ID, datasetId)
        .then((res) => {
          return res.data.result
        })
        .catch((error) => {
          console.error('getDataset', error)
        });
      return dataset;
    },

    loadDatasets() {
    /**
     * Load Datasets
    */
      const columns = [
        "id",
        "table_name",
        "datasource_type",
        "columns.column_name"
      ];

      const q= {
        "columns": columns,
      };

      this.$store.dispatch(GET_DATASET_ALL, q).then((res) => {
        this.datasets = res.data.result
        // return res.data.result;
        // for (let item of res.data.result) {
        //   this.datasets.push({
        //     name: item.table_name,
        //     type: item.datasource_type,
        //     database: item.database.database_name,
        //     schema: item.schema,
        //     id: item.id,
        //   });
        // }
      });
    },

    /**
     * Get Column Status
     */
    getColumnTrainedStatus(modelId) {
      if (this.datasetChars === null) {
        return -1;
      }
      const datasetChar = this.datasetChars.find(
        (ele) => ele.model_id === modelId
      );
      if (!datasetChar) {
        return -1;
      }
      if (!datasetChar.clf) {
        return 0;
      } else if (datasetChar.clf.exists == false) {
        return 1;
      } else if (
        this.trainingJob.finished !== true
        && this.trainingModelIds.includes(modelId)
        // && !this.isChildJobFinished(modelId)
        // !this.prediction.dataset_id
      ) {
        console.log('trainingids', this.trainingModelIds)
        return 3
      }
      return 2; // trained
    },
    /**
     * checkModelInDataSet
     */
    isModelInDatasetChars(modelId) {
      if (this.datasetChars === null) {
        return false;
      }
      const datasetChar = this.datasetChars.find(
        (ele) => ele.model_id === modelId
      );
      return datasetChar ? true : false;
    },
    /**
     * check Child Job Finished
     */
    isChildJobFinished(modelId) {
      const childJob = this.spadeJobStatuses.find(
        (ele) => ele.model_id === modelId
      );
      if (!childJob) {
        // Check whether it exists in trainingIds which means SpadeJobStatuses has not been created yet
        // And it should return false
        return this.trainingModelIds.indexOf(modelId) == -1;
        // return true;
      }
      return childJob.status === "finished" || childJob.status === "failed";
    },
    /**
     * Get Column Status
     */
    removeColumnFromPredictionToolbox(prediction) {
      const index = this.predictions.findIndex(
        (ele) => ele.id === prediction.id
      );
      if (index > -1) {
        this.predictions.splice(index, 1);
      }
      if (this.predictions.length == 0) {
        this.trainingJob.finished = true;
        console.log("Job Finished: Ignored by removing from Prediction");
      }
    },
    /**
     * Add Trained Char In Other Dataset
     */
    addOtherDatasetTrainedCharIntoPredictionBox(char) {
      
      const existingChar = this.predictions.find((ele) => ele.dataset_id === char.dataset_id);
      console.log('asdfsdfads', existingChar)
      if (!existingChar) {
        this.predictions.push(char[0])
      }
      this.visiblePredictionMenu = false;
      console.log("Add Trained Char", char, this.predictions);
    },

    /**
     * Mouse Right Click behavior
     */
    showContextMenu(e, prediction) {
      e.preventDefault();
      this.visibleTrainedContextMenu = false;
      this.visibleNotTrainContextMenu = false;
      this.visibleOnToContextMenu = false;

      if (prediction.trained && !prediction.dataset_id) {
        this.$nextTick(() => {
          this.visibleTrainedContextMenu = true;
        });
      } else if (!prediction.trained && !prediction.dataset_id) {
        this.$nextTick(() => {
          this.visibleNotTrainContextMenu = true;
        });
      } else if (!prediction.trained && prediction.dataset_id) {
        this.$nextTick(() => {
          this.visibleOnToContextMenu = true;
        });
      }

      this.x = e.clientX;
      this.y = e.clientY;
      this.curPrediction = prediction;
      console.log("Current Rightclick Prediction: ", this.curPrediction);
    },

    /**
     * Show Dataset Selection Menu
     */
    showDatasetMenu(e) {
      this.visibleDatasetMenu = true;
      this.x = e.clientX;
      this.y = e.clientY;
    },

    /**
     * CreatePrediction
     */
    createPrediction() {
      const params = {
        modelId: this.curPrediction.value,
        datasetId: this.curPrediction.dataset_id,
      };
      this.$store
        .dispatch(CREATE_PREDICTION, params)
        .then((r) => {
          this.visibleSnackbar = true;
          this.snackbarColor = "#00BAB9";
          this.snackbarMessage = "Prediction Created";
        })
        .catch((error) => {
          this.visibleSnackbar = true;
          this.snackbarColor = "warning";
          this.snackbarMessage = error.message
            ? error.message
            : "Error occured while loading trained chars";
        });
    },

    /**
     * Open Training Dataset
     */
    openTrainingDataset(prediction) {
      let param = {
        modelId: prediction.id,
        postPredictions: 1,
        jobId: "train",
        datasetId: this.datasource.id,
      };
      const _this = this; //use this for debuggin in chrome

      this.$store
        .dispatch(GET_PREDICTION, param)
        .then((r) => {
          const chartFormParam = {
            datasource: r.data.result.dataset_id,
            viz_type: "table",
          };
          return this.$store.dispatch(POST_CHART_FORM, chartFormParam);
        })
        .then((r) => {
          this.visibleSnackbar = true;
          this.snackbarMessage = `Success: Predicted from ${prediction.column_name}`;
          // Construct Get Chart Form Api dispatches
          this.snackbarItems = [
            {
              key: r.data.key,
              dataset_id: JSON.parse(r.config.data).datasource,
            },
          ];
          window.open(`/#/visualizations/null/${r.data.key}`, "_blank");
        })
        .catch((error) => {
          console.log("Error Handling in Open Training Dataset: ");
          this.snackbarItems = [];
          this.visibleSnackbar = true;
          this.snackbarColor = "warning";
          this.snackbarMessage = "Error in Opening Training Dataset";
        })
        .finally(() => {});
    },

    /**
     * Open Training Prediction
     */
    openTrainingPrediction() {
      const params = {
        jobId: "train",
        modelId: this.curPrediction.id,
        postPredictions: 1,
      };

      this.$store
        .dispatch(GET_PREDICTION, params)
        .then((results) => {
          const chartFormParams = {
            datasource: results.data.result.dataset_id,
            viz_type: "table",
          };
          return this.$store.dispatch(POST_CHART_FORM, chartFormParams);
        })
        .then((results) => {
          window.open(`/#/visualizations/null/${results.data.key}`, "_blank");
        })
        .catch((error) => {
          this.visibleSnackbar = true;
          this.snackbarColor = "warning";
          this.snackbarMessage = error.message
            ? error.message + ". Please retry"
            : "Error occured";
        })
        .finally(() => {});
    },

    /**
     * Open Evaluation Page
     */
    OpenEvaluationPage() {
      const params = {
        modelId: this.curPrediction.id,
        postPredictions: 1,
      };

      this.$store
        .dispatch(GET_EVALUATION, params)
        .then((results) => {
          const chartFormParams = {
            datasource: results.data.result.dataset_id,
            viz_type: "table",
          };
          return this.$store.dispatch(POST_CHART_FORM, chartFormParams);
        })
        .then((results) => {
          window.open(`/#/visualizations/null/${results.data.key}`, "_blank");
        })
        .catch((error) => {
          this.visibleSnackbar = true;
          this.snackbarColor = "warning";
          this.snackbarMessage = error.message
            ? error.message + ". Please retry"
            : "Error occured";
        })
        .finally(() => {
          console.log("Open Training Prediction:");
        });
    },

    /**
     * Predict Model onto this dataset
     */
    predictModelOntoDataset(modelId, datasource) {
      this.visibleDatasetMenu = false;
      const params = {
        modelId: modelId,
        datasetId: datasource.id,
      };
      const _this = this;
      this.trainingJob = { finished: false }; // disable train button during training model api call

      this.$store
        .dispatch(CREATE_PREDICTION, params)
        .then((r) => {
          _this.trainingJob = r.data.result;

          _this.trainingInterval = setInterval(() => {
            _this.getJobStatus(r.data.result.job_id, false, datasource.id);
          }, 2000);
        })
        .catch((error) => {
          this.visibleSnackbar = true;
          this.snackbarColor = "warning";
          this.snackbarMessage = error.message
            ? error.message
            : "Error occured while loading trained chars";
        });
    },

    /**
     * Close Snackbar
     */
    closeSnackBar() {
      this.visibleSnackbar = false;
    },
    /**
     * Show NewDatasetFromDBModal
     */
    showNewDatasetFromDBModal() {
      this.visibleShowNewDatasetFromDBModal = true;
    },

    /**
     * Cancel NewDatasetFromDBModal
     */
    hideNewDatasetFromDBModal() {
      this.visibleShowNewDatasetFromDBModal = false;
    },

    /**
     * Cancel NewDatasetFromDBModal
     */
    hideNewDBModal() {
      this.visibleShowNewDBModal = false;
    },

    /**
     * Cancel NewDatasetFromDBModal
     */
    showCreateDBModal() {
      this.visibleShowNewDatasetFromDBModal = false;
      this.visibleShowNewDBModal = true;
    },

    /**
     * Open Visualization Page
     */
    goToVisualizationForm() {
      this.visibleShowNewDatasetFromDBModal = false;
      // this.$router.push("/datasets");
    },

    // compareRowBackground (item) {
    //   console.log('iter', item)
    //   return 'matched'//item.column_match === 1 ? 'matched' : 'unmatched'
    // },
    getColor (match) {
      if (match)  return 'success'
      return 'warning'
    },

  },
  // watch: {
  //   distinct(val) {
  //     console.log(val);
  //   },
  // },

  mounted() {
    // Load GetDataset and TrainedChars
    this.loadDatasetChars();
    this.loadTrainedChars();
    // this.datasets = await this.loadDatasets();
    this.loadDatasets();
  },
};
</script>

<style lang="scss" scoped>

// .matched {
//   color: red;
//   background-color: green;
// }

.model-tree {
  width: 100%;
  display: inline-block;
  white-space: nowrap; 
        // overflow: auto;
  overflow-x: auto;
}

.treeview-item {
  max-height: 26px;
  min-height: 1px;
  font-size: 10px;
}

.analysis-actions {
  padding: 8px;
  display: flex;
  // background-color: red;
  align-items: center;
  gap: 8px;

}

.magic-pill {
  width: 100%;
  // background-color: #262626;
  justify-content: space-between;

  // &-status {
    // padding-left: 8px;
  // }

  // &-colname {
  // }

}

.blank-drag {
  border: 1px dashed #909090;
  border-radius: 12px;
  text-align: center;
  color: #909090;
  padding-top: 4px;
  padding-bottom: 4px;
  font-size: 12px;
}

.blank-text {
  font-size: 12px;
  text-align: center;
  color: #909090;
  margin-top: 9px;
}

.vis-data {
  position: relative;
  border-right: 0.5px solid #373737;

  &-show {
    width: 240px;
  }

  &-hide {
    width: 30px;

    &-tab {
      writing-mode: tb-rl;
      transform: rotate(-180deg);
      height: 75px;
      text-align: center;
      margin-left: auto;
      margin-right: auto;
      cursor: pointer;
      color: #747474;
      line-height: 30px;

      &.active {
        color: #ffffff;
        border-left: 2px solid #ffffff;
      }
    }
  }

  &-back {
    position: absolute;
    z-index: 1000;
    right: 0;
  }

  .data-source {
    // text-transform: uppercase;
    font-size: 16px;
    margin-left: 12px;
    // margin-right: 8px;
    // margin-top: 15px;
    // letter-spacing: 0.48px;
    color: #B9B9B9;
  }

  .table {
    // padding: 12px 12px 16px 15px;
    height:55.5px;
    padding: 2px 8px;
    border-bottom: 0.5px solid #373737;
    margin: auto;
    

    &-name {
      // margin: auto;;
      padding-left: 8px;
      font-size: 16px;
      color: #939393;
      // color: blue;
    }
  }

  .search {
    padding-bottom: 12px;
    border-bottom: 0.5px solid #373737;

    &-header {
      padding: 11px 12px 12px 15px;
      color: #939393;
    }
  }

  .columns {
    padding: 12px;
    border-bottom: 0.5px solid #373737;

    &-header {
      &-text {
        text-transform: uppercase;
        color: #939393;
        font-size: 12px;
      }

      &-description {
        color: #939393;
        font-size: 12px;
        font-style: italic;
        margin-right: 5px;
      }
    }

    &-container {
      padding-top: 11px;
      height: 152px;
      overflow-y: auto;
      @include scrollbars(0.4rem, #717171, #00000080);
    }

    &-item {
      margin-bottom: 12px;
      background: #121212;
      cursor: pointer;

      &-sub {
        margin-left: 20px;
      }

      &-text {
        font-size: 16px;
        color: #939393;
        margin-left: 10px;

        &-sub {
          margin-left: 15px;
          color: #939393;
        }
      }

      &-desciption {
        color: #939393;
        font-size: 12px;
        font-style: italic;
        margin-right: 5px;
      }
    }
  }

  .metrics {
    padding: 12px;
    border-bottom: 0.5px solid #373737;

    &-header {
      &-text {
        text-transform: uppercase;
        color: #939393;
        font-size: 12px;
      }
    }

    &-item {
      margin-bottom: 5px;
      cursor: pointer;

      &-text {
        font-size: 14px;
        color: #939393;
        margin-left: 10px;
      }
    }

    &-container {
      padding-top: 11px;
      height: 152px;
      overflow-y: auto;
      overflow-x: hidden;
      @include scrollbars(0.4rem, #717171, #00000080);
    }
  }

  .predictions {
    padding: 12px;
    margin-bottom: auto;

    &-header {
      &-text {
        // text-transform: uppercase;
        color: #939393;
        font-size: 12px;
      }
    }

    &-item {
      margin-bottom: 12px;

      &-text {
        font-size: 16px;
        color: #939393;
        margin-left: 10px;
      }
    }

    &-container {
      padding-top: 11px;
      min-height: 174px;
      overflow-y: auto;
      @include scrollbars(0.4rem, #717171, #00000080);
    }
  }

  .config {
    padding: 11px 7px;
    border-bottom: 0.5px solid #373737;
  }
}

.vis-analysis {
  &-btn {
    background: #292929;
    border-radius: 3px;
    margin-bottom: 5px;
    padding: 7px 9px;
    color: #939393;
  }
}

.ml {
  &-06 {
    margin-left: 6px;
  }
}

.mr {
  &-06 {
    margin-right: 6px;
  }
}

.context-items {
  font-size: 12px;
}

.action {
  width: 240px;
  border-top: 0.5px solid #373737;
  padding: 11px 11px;
  bottom: 0px;
  position: fixed;
  opacity: .9;
  background-color: #262626
}

.prediction-menu {
  .v-card__title {
    font-size: 21px;
  }

  .v-card__subtitle {
    font-size: 15px;
    color: white;
  }
}
.data-search {
  font-size: 2px;
  // padding: 12px 11px;
  // padding-top
  padding-right: 12px;
  // padding-bottom: -10px;
  padding-left: 12px;
}
</style>