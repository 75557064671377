<template>
<!--  -->
  <div class="d-flex align-center "
  >
    <!-- Trashcan Button -->
    <v-btn 
      :disabled="!selectedItems.length>0"
      tile depressed icon small  
      class="mr-1 bg-primary" @click="deleteAll()"
    >
      <v-icon size="18"> mdi-trash-can </v-icon>
    </v-btn>
    <!-- Select Options -->
    <v-menu offset-y right z-index="100">
      <template v-slot:activator="{ on, attrs }">
        <v-btn tile depressed icon small 
          class="bg-primary"
          v-bind="attrs"
          v-on="on"
        >
          <v-icon size="18">mdi-chevron-down</v-icon>
        </v-btn>
      </template>
      <v-list dense color="#454545">
        <v-list-item-group>
          <v-list-item
            dense
            v-for="(item, index) in selectDropDownOptions"
            :key="index"
            @click="clickSelectItem(item)"
          >
            <v-list-item-content>
              <v-list-item-title v-text="item.title"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-menu>
    <v-divider vertical class="mx-4"></v-divider>
    <!-- New Button -->
    <div class="new-button" v-if="!newDropdown">
    <v-btn
      v-if="!menuItems && !hideNewButton"
      tile depressed small 
      class="mr-1 bg-primary rounded" 
      @click="clickNewItem()">
      <!-- New {{title}} -->
      {{ title }}
    </v-btn>
    </div>
    <div class="new-button" v-else>
      <v-menu offset-y>
      <template v-slot:activator="{ on, attrs }">
        <!-- @click="clickNewItem()" -->
      <v-btn
        v-if="!menuItems && !hideNewButton"
        tile depressed small 
        class="mr-1 bg-primary rounded"
        v-bind="attrs"
        v-on="on"
        >
        Add {{ title }}
      </v-btn>
      </template>
      <v-list>
        <v-list-item
          v-for="(item, index) in this.newItems"
          :key="index"
          @click.stop="$emit('menuClick',item)"
        >
          <v-list-item-title>{{ item }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
    </div>
    <v-btn 
      v-if="menuItems"
      small 
      :ripple=false
      color="primary"
      class="dropdown-text-btn">
      New {{title}}
    </v-btn>
    <v-menu v-if="menuItems" offset-y left>
      <template v-slot:activator="{ on, attrs }">
        <v-btn class="dropdown-btn" color="primary" v-bind="attrs" v-on="on">
          <v-icon size="20">mdi-chevron-down</v-icon>
        </v-btn>
      </template>
      <v-list dense>
        <v-list-item-group>
          <v-list-item
            dense
            v-for="(item, index) in menuItems"
            :key="index"
            @click="clickMenuItem(item)"
          >
            <v-list-item-content>
              <v-list-item-title v-text="item.text"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-menu>
    <v-divider v-if="!hideNewButton" vertical class="mx-4"></v-divider>
    <div style="width: 400px;">
      <v-text-field
        v-model="moduleFilter"
        ref="searchInput"
        @input="userInputFilter"
        hide-no-data
        hide-details
        no-filter
        :label="`${searchLabel}`"
        dense
        solo
        outlined
        prepend-inner-icon="mdi-magnify"
        class="hidden-sm-and-down mx-auto custom-class"
      >
      <template v-slot:append>
        <span 
          v-if="showCounter" 
          class="text-counter"
        >
          {{n_items[1]}}/{{n_items[0]}}
        </span>
      </template>
      </v-text-field>
    </div>   
  </div>
</template>

<script>
import DataViewerTable from '../visualization/components/vizMainComponents/DataViewerTable.vue';
export default {
  name: "ModuleAction",
  props: {
    title: String, 
    searchLabel: String, 
    // menuItems: Object, 
    menuItems: Array, 
    hideNewButton: Boolean,
    newDropdown: {
      type: Boolean,
      default() {
        return false
      },
    },
    n_items: {
      type: Array,
      default() {
        return []
      }
    },
    selectedItems: {
      type: Array,
      default() {
        return []
      },
    },
  },
  data: () => ({
    newItems: ["Add Existing"],//,"Create New"],
    selectDropDownOptions: [
      {title: 'Select All', action:'select_all'},
      {title:'Unselect All', action: 'unselect_all'},
    ],
    moduleFilter: null,
  }),
  computed: {
    showCounter() {
      return !!this.moduleFilter //? this.moduleFilter.length : false
    },
  },
  methods: {
    clickNewItem() {
      this.$emit("clickNewItem");
    },    
    deleteAll() {
      this.$emit("deleteAll");
    },
    clickMenuItem(item) {
      this.$emit('menuEvent', item);
    },
    clickSelectItem (item) {
      this.$emit('selectItemsEvent', item)
    },
    userInputFilter(e) {
      // console.log('changed', this.moduleFilter)
      this.$emit('moduleFilterChanged', this.moduleFilter)
    },
  },
};
</script>

<style scoped>

  .text-counter{
    font-size: 12px;
    font-weight: "lighter";
    font-style: italic;
  }

  .dropdown-btn {
    min-width: 32px !important;
    padding: 0 !important;
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
    height: 32px !important;
  }
  .dropdown-text-btn {
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
    margin-right: 1px;
    height: 32px !important;
  }
</style>
