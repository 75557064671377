<template>
<svg class="minimize-icon" width="59" height="59" viewBox="0 0 59 59">
    <defs>
        <filter id="Ellipse_102" x="0" y="0" width="59" height="59" filterUnits="userSpaceOnUse">
            <feOffset dy="3" input="SourceAlpha" />
            <feGaussianBlur stdDeviation="3" result="blur" />
            <feFlood flood-opacity="0.161" />
            <feComposite operator="in" in2="blur" />
            <feComposite in="SourceGraphic" />
        </filter>
    </defs>
    <g id="sidebar_minimize_and_expand" data-name="sidebar minimize and expand" transform="translate(9 6)">
        <g transform="matrix(1, 0, 0, 1, -9, -6)" filter="url(#Ellipse_102)">
            <circle id="Ellipse_102-2" data-name="Ellipse 102" cx="20.5" cy="20.5" r="20.5" transform="translate(9 6)" fill="#fff" />
        </g>
        <g id="Component_35_1" data-name="Component 35 – 1" transform="translate(12 11)">
            <g id="Rectangle_147634" data-name="Rectangle 147634" fill="#fff" stroke="#1bbab9" stroke-width="1">
                <rect width="18" height="20" rx="3" stroke="none" />
                <rect x="0.5" y="0.5" width="17" height="19" rx="2.5" fill="none" />
            </g>
            <path id="Polygon_4" data-name="Polygon 4" d="M4,0,8,7H0Z" transform="translate(13 6) rotate(90)" fill="#1bbab9" />
        </g>
    </g>
</svg>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.minimize-icon {
    &:hover {
        #Ellipse_102-2 {
            fill: #1bbab9;
        }

        #Rectangle_147634 {
            fill: #1bbab9;
            stroke: #fff;
        }

        #Polygon_4 {
            fill: #fff;
        }
    }

    &:active {
        #Ellipse_102-2 {
            fill: #169f9c;
        }

        #Rectangle_147634 {
            fill: #169f9c;
            stroke: #fff;
        }

        #Polygon_4 {
            fill: #fff;
        }
    }
}
</style>
