
<template v-if="vis_data_cols.length > 0">
  <div class="main-table-grid" ref="tbGrid">
      <!-- @cellEditingStarted="cellEditingStarted" -->
      <!-- @cellEditingStopped="cellEditingStopped" -->
      <!-- :autoGroupColumnDef="autoGroupColumnDef" -->
    <!-- <div> {{ vis_data_rows.length }} </div> -->
    <ag-grid-vue
      class="ag-theme-alpine-dark"
      style="width: 100%; height: 100%"
      :columnDefs="vis_data_cols"
      :defaultColDef="defaultColDef"
      :rowData="vis_data_rows"
      :rowGroupPanelShow="rowGroupPanelShow"
      :getMainMenuItems="getMainMenuItems"
      @grid-ready="onGridReady"
      :sideBar="sideBar"
      :copyHeadersToClipboard="true"
      :gridOptions="gridOptions"
    />
    <!-- Hack, aggrid wouldn't-->
    <!-- register the component -->
    <div v-show="false"><EditPanel/></div>
  </div>
</template>

<script>
import { AgGridVue } from "ag-grid-vue";
import { LicenseManager } from "ag-grid-enterprise";
import EditPanel from "./EditPanel.vue"

LicenseManager.setLicenseKey(process.env.VUE_APP_AG_LICENSE);

export default {
  name: "TableVis",
  components: {
    AgGridVue,
    EditPanel,
  },
  props: {
    searchText: {
      type: String,
      default() {
        return null;
      },
    },
    vis_data: {
      default() {
        return {
          'row_data': [],
          'cols': null,
          'headers': null,
          'gridState': null,
        };
      },
    },
  },
  data() {
    return { 
      pivot: false,
      // editMode: false,
      editMode: false,
      defaultColDef: {
        flex: 1,
        minWidth: 200,
        resizable: true,
        floatingFilter: true,
        sortable: true,
        filter: true,
        enableValue: true,
        enablePivot: true,
        //////// set true for testing
        // editable: this.isEditable(),
        // editable: true,//this.editMode,
        // wrapText: true,
        // autoHeight: true,
      },
      // autoGroupColumnDef: {editable: false,},
      gridApi: null,
      gridColumnApi: null,
      tableMH: 520,
      resizeH: 10,
      rowGroupPanelShow: "always",
      // Undo/Redo not working with Hacky component 
            //** EditPanel **
      // undoRedoCellEditing: true,
      // undoRedoCellEditingLimit: 20,
      sideBar: {
        toolPanels: [
          "columns", "filters",
          {
            id: 'customStats',
            labelDefault: 'Edit Data',
            labelKey: 'customStats',
            iconKey: 'custom-stats',
            toolPanel: 'EditPanel',
          },
        ],
        defaultToolPanel: 'EditPanel',
        hiddenByDefault: false,
      },
      gridOptions: {
        autoGroupColumnDef: {
          editable: false,//this.isEditable(),
          minWidth: 200,
          valueSetter: params => {
            // console.log(params);
            params.data.name = params.newValue;
            const cellRangesExist = params.api.getCellRanges().length > 0;
            
            if (cellRangesExist) {
              const allCellRanges = params.api.getCellRanges();
              
              for (let i = 0; i < allCellRanges.length; i++) {
                const cellRanges = allCellRanges[i];
                //new value entered by user
                // const newValue = params.newValue;
                //rows to be updated
                let itemsToUpdate = [];

                //cycle through all rows in the current range
                for (
                  let j = cellRanges.startRow.rowIndex;
                  j <= cellRanges.endRow.rowIndex;
                  ++j
                ) {

                  // row Node contains the selected in the grouped rows.
                  const rowNode = params.api.getDisplayedRowAtIndex(j);

                  // Update all child rows recursively
                  itemsToUpdate = this.updateChildChildNodes(params.node.field, params.newValue, rowNode);
                  //process all row updates
                  params.api.applyTransaction({ update: itemsToUpdate });
                }
              }
            }
            return true;
          }
        },
        enableRangeSelection: true,
        enableGroupEdit: true,
        enableFillHandle: true,
        fillHandleDirection: "y",
        // onCellClicked: (params) => {console.log('test', params, this.gridOptions)},
        onCellValueChanged: (params) => {
          console.log('update cell////////', params.api.getCellRanges())
          const cellRangesExist = params.api.getCellRanges().length > 0;
          // console.log(cellRangesExist);

          if (cellRangesExist) {
            const allCellRanges = params.api.getCellRanges();
            // console.log("rabges", allCellRanges);
            for (let i = 0; i < allCellRanges.length; i++) {
              const cellRanges = allCellRanges[i];
              //new value entered by user
              const newValue = params.newValue;
              //rows to be updated
              let itemsToUpdate = [];

              //cycle through all rows in the current range
              for (
                let j = cellRanges.startRow.rowIndex;
                j <= cellRanges.endRow.rowIndex;
                ++j
              ) {
                const rowNode = params.api.getDisplayedRowAtIndex(j);
                if (rowNode?.group == false ) {
                  const data = rowNode.data;
                  //cycle through all the columns in this range
                  cellRanges.columns.forEach((col) => {
                    const colId = col.getColId();
                    data[colId] = newValue;
                    itemsToUpdate.push(data);
                  });
                }
                //process all row updates
                params.api.applyTransaction({ update: itemsToUpdate });
              }
            }
          }
        },
      },
    };
  },
  computed: {
    vis_data_cols: {
      get() {
        return this.vis_data && this.vis_data.cols ? this.vis_data.cols : [];
      },
      set(newValue) {
        // console.log('commmmpp',this.vis_data, newValue)
        this.vis_data.cols = newValue
        // [this.firstName, this.lastName] = newValue.split(' ')
      }
    },
    vis_data_rows() {
      return this.vis_data && this.vis_data.row_data ? this.vis_data.row_data : [];
    },
  },
  watch: {
    searchText: function() {
      this.onFilterTextBoxChanged();
    }
  },
  methods: {
    getMainMenuItems(params) {
      console.log('//////params', params)//.defaultItems)
      const colId = params.column.colId
      let menuItems = params.defaultItems;
      const wrapItemPosition = menuItems.indexOf('autoSizeAll')+1;
      const wrapItem = {
            name: 'Wrap Column Text',
            action: () => {
              let colDefs = this.gridApi.getColumnDefs();
              console.log('////', colDefs)
              const colIndex = colDefs.map(e => e.colId).indexOf(colId);
              colDefs[colIndex].wrapText=true;
              colDefs[colIndex].autoHeight=true;
              this.gridApi.setColumnDefs(colDefs);
              this.gridApi.redrawRows();
            },
          };
      console.log('new defs', this.gridApi.getColumnDefs());
      menuItems.splice(wrapItemPosition, 0, wrapItem);
      return menuItems
    },
    isEditable() {
      return this.editMode
    },
    onFilterTextBoxChanged() {
      this.gridApi.setQuickFilter(this.searchText)
    },
    setColDef(option, bool) {
      // wrapText: true,
      console.log('dowrap')
      // var columnDefs = this.gridApi.getColumnDefs();
      // columnDefs.forEach(function (colDef, index) {  
        // colDef.editable=true;
      // this.vis_data_cols.forEach(function (colDef, index) {
      // this.$set(columnDefs[0], option, bool);
      // this.$set(columnDefs[0], 'width', 200);
        // colDef.width=600;
      // this.$set(columnDefs[0], 'autoHeight', true);
        // colDef.enableValue = !bool;
      // });
      // console.log('/////', columnDefs)
      // this.gridApi.setColumnDefs(columnDefs);
      // this.gridOptions.api.setColumnDefs(columnDefs)
      
    },
    setEditMode(bool) {
      var columnDefs = this.gridApi.getColumnDefs();
      columnDefs.forEach(function (colDef, index) {
      // this.vis_data_cols.forEach(function (colDef, index) {
        colDef.editable = bool;
        // colDef.enableValue = !bool;
      });
      this.gridApi.setColumnDefs(columnDefs);

      // this.vis_data_cols = columnDefs;
      // this.gridOptions.enableGroupEdit=bool;
      // this.gridOptions = {... this.gridOptions.autoGroupColumnDef, editable: true}
      this.$set(this.gridOptions.autoGroupColumnDef, 'editable', bool)
      this.gridApi.setAutoGroupColumnDef(this.gridOptions.autoGroupColumnDef)
      console.log('coldefs',this.gridApi.getColumnDefs())
      console.log('gridops', this.gridOptions.autoGroupColumnDef)
    },

    tableEditMode(bool) {
      this.editMode=bool
      this.setEditMode(bool)
    },

    closeToolPanel() {
      this.tableEditMode(false)
      this.gridOptions.api.closeToolPanel();
    },

    updateChildChildNodes(field, value, node) {
      let arr = [];

      function recursive(field, value, node) {
        // updateChildChildNodes(params.node.field, params.newValue, rowNode);
      
        if (node?.group == true) {
          let childrenNodes = node.childrenAfterFilter;
          // let arr = [];      
          for (let i =0; i<childrenNodes?.length; i++) {
            recursive(field, value, childrenNodes[i]);
          }
        }
        else {
          // At the real row, and should update the row
          const data = node.data;
          data[field] = value;
          arr.push(data);
        }
      }

      recursive(field,value, node);
      console.log('***');
      console.log(arr);
      return arr;
    },

    // onFilterTextBoxChanged() {
    //   console.log('//////// filter meth')
    //   if (this.searchText) {
    //     this.gridApi.setQuickFilter( this.searchText )
    //   } else {
    //     if (this.gridApi && this.gridApi.isQuickFilterPresent() ) {
    //     this.gridApi.setQuickFilter('')
    //     }
        
    //   }
    // },

    onGridReady(params) {
      let gridState = this.vis_data.gridState ? 
        this.vis_data.gridState.grid_state :
        null;

      // console.log('onGridReady', this.vis_data)
      this.gridApi = params.api;
      this.gridApi.setRowData(this.vis_data_rows);
      this.gridColumnApi = params.columnApi;

      if (gridState) {
        // console.log('set pivot', gridState)
        this.gridColumnApi.applyColumnState({
          state: gridState.gridColumnApi,
          applyOrder: true,
        });
      this.gridColumnApi.setPivotMode(gridState.isPivotMode)
    }
    },
  }
}
</script>

<style lang="scss" scoped></style>