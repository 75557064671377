<template>
  <div>
    <div class="bar-metric">
      <div class="bar-metric-header d-flex">
        <div class="bar-metric-header-title">Metrics</div>
        <AxisInfoTooltip
          :validCol="bar_metrics.length>0"
          :requiredCol="true"
          :message="['Metric to display on Chart']"
          displayType="Categorical/Numerical"
        />
      </div>
      <div
        class="bar-metric-container"
        ref="barMetricCon"
        @dragover.prevent
        @drop="$emit('dragComputedListFinish', 'metrics'); calcIndex=bar_metrics.length-1"
      >
        <template v-if="bar_metrics.length > 0">
          <div v-for="(bar_metric, index) in bar_metrics" 
            :key="index"
            class="column-item"
          >
            <div 
              class="d-flex"
              :class="[showCalc.metrics && index===calcIndex ? 'size-menu': 'size-item']"
            >
              <!-- Pill and Calculation menu card -->
          <v-menu
            attach
            :value="showCalc.metrics"
            offset-x
            nudge-right="22px"
            nudge-top="153"
            :close-on-content-click="false"
            height="0"
            flat="true"
            :close-on-click="false"
          >    
          <template v-slot:activator="{ on }">
            <v-btn 
              v-on="on"
              class="mb-08"
              :outlined="!getComputedMetrics(bar_metric, index).applied"
              :color=" bar_metric.column.type_generic ? '#1bbab9' : '#00A7FF' "
              rounded
              width="100%"
              height="24px"
              
              @click.stop="on.click"
              @contextmenu.prevent="contextClickPill('metrics'); calcIndex=index;"
            >
              {{ getComputedMetrics(bar_metric, index).label }}
              <v-icon 
                class="ml-auto" right
                @click.prevent
                @click="$emit('deletePillList', 'metrics', index)"
              >
                mdi-close-circle
              </v-icon>
            </v-btn>
          </template>
            <CalculationCard
              v-if="showCalc.metrics && index===calcIndex"
              :calcStyle="'Calculation'"
              :form_data="form_data"
              :aggregations="getAggType(bar_metric)"
              :size="bar_metric"
              @disableCalcCard="$emit('disableCalcList', 'metrics', index)"
              @saveSimpleCalcSize="saveSimpleCalc($event, index)"
            />
          </v-menu>
        </div>
          </div>
        </template>
        <template v-else>
          <div class="blank-drag">Drag here</div>
          <p class="blank-text">
            Metric to display In Chart
          </p>
        </template>
      </div>
    </div>
    <div class="series">
      <div class="series-header d-flex">
        <div class="series-header-title">Series</div>
        <AxisInfoTooltip
          :validCol="bar_series.length>0"
          :requiredCol="false"
          :message="['Columns to Aggregate By']"
          displayType="Categorical"
        />
      </div>
      <!-- @drop="$emit('dragBarSeries', $event)" -->
      <div class="series-container"
        @dragover.prevent
        @drop="$emit('dragPillFinishList', 'groupby')"
      >
        <template v-if="bar_series.length > 0">
        <!-- <template v-if="bar_series"> -->
        <div v-for="(bar_serie, index) in bar_series" 
          :key="index"
          class="column-item"
        >
          <VizModePill
            :column="getListCol('groupby', bar_serie)"
            @deleteCol="$emit('deletePillList', 'groupby',index)"
          />
        </div>
          <!-- <div v-for="(bar_serie, index) in bar_series" :key="index">
            <v-btn
              class="mb-08"
              :color="bar_serie.type_generic === 0 ? '#1bbab9' : '#00A7FF'"
              rounded
              width="100%"
              height="24px"
            >
              <span class="ml-auto mr-auto">
                {{ bar_serie.column_name }}
              </span>
              <v-icon
                @click="$emit('deleteBarSeries', index)"
                class="ml-auto"
                right
              >
                mdi-close-circle
              </v-icon>
            </v-btn>
          </div> -->
        </template>
        <template v-else>
          <div class="blank-drag">Drag here</div>
          <p class="blank-text">
            Column(s) to Group Columns By
          </p>
        </template>
      </div>
    </div>
    <div class="category">
      <div class="category-header d-flex">
        <div class="category-header-title">Distribution</div>
        <AxisInfoTooltip
          :validCol="true"
          :requiredCol="false"
          :message="['Columns to Distribute Metrics Across', 'Usually an ID Column']"
          displayType="Categorical/ID - Numeric"
        />
      </div>
      <div
        class="category-container"
        @dragover.prevent
        @drop="$emit('dragPillFinishList', 'columns')"
      >
        <template v-if="bar_categories.length > 0">
          <div v-for="(bar_category, index) in bar_categories" 
            :key="index"
            class="column-item"
          >
            <VizModePill
              :column="getListCol('columns', bar_category)"
              @deleteCol="$emit('deletePillList', 'columns', index)"
            />
          </div>
        </template>
        <template v-else>
          <div class="blank-drag">Drag here</div>
          <p class="blank-text">
            Column(s) for Metric Distribution
          </p>
        </template>
      </div>
    </div>
  </div>
</template>

<script>


import AxisInfoTooltip from "./components/AxisInfoTooltip.vue"
import VizModePill from "./components/VizModePill.vue"  
import CalculationCard from "./components/CalculationCard.vue";

export default {
  components:{
    AxisInfoTooltip,
    VizModePill,
    CalculationCard,
  },
  data() {
    return {
      calcIndex: null,
    }
  },
  props: {
    form_data: {
      type: Object,
      default() {
        return null;
      },
    },
    validVisCols: {
      type: Object,
      default() {
        return null;
      },
    },
    showCalc: {
      type: Object,
      default() {
        return null;
      },
    },
    aggregations: {
      type: Object,
      default() {
        return null;
      },
    },
    bar_metrics: {
      type: Array,
      // type: Object,
      default() {
        return [];
      },
    },
    bar_series: {
      type: Array,
      default() {
        return [];
      }
    },
    // bar_series: {
    //   type: Array,
    //   default() {
    //     return [];
    //   },
    // },
    bar_categories: {
      type: Array,
      // type: String,
      default() {
        return [];
      },
    },
    mode_active_cols: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  methods: {
    saveSimpleCalc(calc, index) {
      console.log('calc', calc)
      console.log('index', index)
      this.$emit('saveCalcList', calc, index, 'metrics')
    },
    getAggType(col) {
      // console.log('get agg type', col)
      if ( col.column.type_generic === 1 ) {
        return this.aggregations.categorical;
      } else {
        return this.aggregations.numeric;
      }
    },
    contextClickPill(val) {
      console.log('show calc card')
      this.$emit('showCalcCard', val)
    },
    // isAppliedList(colname, axis) {
    //   return this.mode_active_cols[axis]?.applied;
    // },
    getComputedMetrics(val, index) {
      // console.log('get metrcs', val)
      // console.log('metrics list', this.bar_metrics)
      return {
        // label: val.label,
        label: (
          val.column ? 
            val.aggregate ? `(${val.aggregate})${val.column.column_name}` : 
            val.column.column_name : 'error?'
        ),
        type_generic: val.column.type_generic,
        applied: this.isApplied('metrics', index),
      }
    },
    getListCol(val, axis) {
      console.log('getsimple', this.mode_active_cols, val, axis)
      const this_col_array = this.mode_active_cols[val]
      const this_col = this_col_array.filter(x =>
        x.column_name === axis)[0]
      // console.log('getsimple2', this_col, val, axis)
      return {
        // column_name: this.form_data[val],
        column_name: this_col.column_name,
        type_generic: this_col.type_generic,
        // applied: false,
        applied: this_col.applied,
        }
    },
    isAppliedList(axis, index) {
      // const find = this.mode_active_cols[axis].find( x =>
        // x.
      // )
      // console.log('isapplied///////', axis, this.mode_active_cols[axis])
      return this.mode_active_cols[axis][index]?.applied
    },
    // getSimpleCol(col) {
    //   const all_cols = this.mode_active_cols.all_cols;
    //   console.log('get simple///////', all_cols)
      
    //   const result = all_cols.filter(obj => {
    //     return obj.column_name === col;
    //   })
    // return result[0]
    // },
    isApplied(axis, index) {
      // const find = this.mode_active_cols[axis].find( x =>
        // x.
      // )
      console.log('isapplied///////', axis, this.mode_active_cols[axis])
      return this.mode_active_cols[axis][index]?.applied
    },
    /**
     * drag bar metrics event
     * @param event
     */
    // dragBarMetrics(event) {
      // let barMetricCon = this.$refs.barMetricCon;
      // barMetricCon.scrollTop = 0;
      // this.$emit("dragBarMetrics", event);
    // },
  },
};
</script>

<style lang="scss" scoped>

// Animate the tooltips to match v-menu
@keyframes fade {
  0% { opacity: 0 }
  100% { opacity: 1 }
}

.size {
  &-menu ::after {
    animation: fade .2s linear;
    position: absolute;
    content: "";
    // top: 9px;
    z-index: 10;
    left: 230px;  
    border-top: 12.5px solid transparent;
    border-bottom: 12.5px solid transparent;  
    border-left: 16px solid #303030;
    transform: rotate(180deg);  
  }

}

.column-item {
    margin-bottom: 5px;
  }

.blank-drag {
  border: 1px dashed #909090;
  border-radius: 12px;
  text-align: center;
  color: #909090;
  padding-top: 4px;
  padding-bottom: 4px;
  font-size: 12px;
}

.blank-text {
  font-size: 12px;
  text-align: center;
  color: #909090;
  margin-top: 9px;
}

.bar-metric {
  &-header {
    padding: 10px 15px;
  }

  &-title {
    font-size: 12px;
  }

  &-container {
    height: 85px;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 8px;
    margin-bottom: auto;
    overflow-y: auto;
    @include scrollbars(0.4rem, #717171, #00000080);
    border-bottom: 0.5px solid #373737;
  }
}

.series {
  &-header {
    padding: 10px 15px;
  }

  &-title {
    font-size: 12px;
  }

  &-container {
    height: 85px;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 8px;
    margin-bottom: auto;
    overflow-y: auto;
    @include scrollbars(0.4rem, #717171, #00000080);
    border-bottom: 0.5px solid #373737;
  }
}

.category {
  &-header {
    padding: 10px 15px;
  }

  &-title {
    font-size: 12px;
  }

  &-container {
    height: 85px;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 8px;
    margin-bottom: auto;
    overflow-y: auto;
    @include scrollbars(0.4rem, #717171, #00000080);
  }
}
</style>