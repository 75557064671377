var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex flex-column fill-height flex-grow-1 overflow-hidden pt-6"},[_c('NewDB',{attrs:{"selectedDB":_vm.selectedDB,"showNewDB":_vm.showNewDB,"editItemId":_vm.editItemId},on:{"cancel":_vm.cancelNewDB,"db_success":_vm.db_success}}),_c('ModuleHeader',{attrs:{"title":"Databases","disabled-grid-btn":true,"disabled-list-btn":true,"disabled-fav-btn":true,"view-grid":false,"returnToPage":"Index"}}),_c('ModuleAction',{attrs:{"title":"New Database","search-label":"Search Databases","selectedItems":[],"n_items":_vm.filteredItemCount},on:{"selectItemsEvent":_vm.selectItemsEvent,"clickNewItem":_vm.clickNewItem,"moduleFilterChanged":_vm.moduleFilterChanged}}),_c('ErrorDialog',{attrs:{"showModal":_vm.errModalOpen,"message":_vm.errorMsg},on:{"close":_vm.closeErrDialog}}),_c('div',{staticClass:"d-flex flex-grow-1 flex-column flex-basis-0\n      fill-height overflow-x-auto"},[_c('v-container',[(_vm.loading)?_c('v-row',{staticClass:"container-row-resolution py-5 d-flex justify-center"},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary"}})],1):(_vm.getModels(_vm.collection, _vm.viewFavOnly).length)?_c('v-data-table',{staticClass:"mt-3",attrs:{"id":"tblItems","items":_vm.getModels(_vm.collection, _vm.viewFavOnly),"items-per-page":_vm.$pageItemCount,"headers":_vm.headers,"search":_vm.search,"show-select":"","item-key":"id","hide-default-footer":true,"header-props":{sortIcon: 'mdi-chevron-down'}},on:{"click:row":_vm.editItem,"item-selected":_vm.updateGridViewSelection},scopedSlots:_vm._u([{key:"top",fn:function(ref){
var pagination = ref.pagination;
var options = ref.options;
var updateOptions = ref.updateOptions;
return [_c('v-data-footer',{attrs:{"pagination":pagination,"options":options,"items-per-page-text":"$vuetify.dataTable.itemsPerPageText"},on:{"update:options":updateOptions}})]}},{key:"item.backend",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":"primary","label":""}},[_vm._v(_vm._s(item.backend))])]}},{key:"item.allow_run_async",fn:function(ref){
var item = ref.item;
return [(item.allow_run_async)?_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-check-circle-outline")]):_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-close-circle-outline")])]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('RowAction',{attrs:{"item":item,"disabledList":_vm.disabledRowActions},on:{"editItem":_vm.editItem,"deleteItem":_vm.deleteItem,"infoItem":_vm.infoItem}})]}}],null,true),model:{value:(_vm.selectedItems),callback:function ($$v) {_vm.selectedItems=$$v},expression:"selectedItems"}}):_c('v-row',{staticClass:"container-row-resolution pt-5 d-flex justify-center"},[_c('p',[_vm._v("No Databases Found ...")])])],1)],1),_c('v-navigation-drawer',{attrs:{"hide-overlay":"","right":"","temporary":"","absolute":"","color":"#262626","width":_vm.navigation.width},model:{value:(_vm.detailModalVisible),callback:function ($$v) {_vm.detailModalVisible=$$v},expression:"detailModalVisible"}},[_c('v-list-item',[(_vm.dbInfoMap)?_c('v-card',{attrs:{"width":"500","color":"#262626","flat":""}},[_c('v-card-title',[_vm._v("Selected DB: "+_vm._s(_vm.dbInfoMap[0].database_name))]),_c('v-divider'),_c('v-container',{attrs:{"overflow-hidden":""}},[_c('v-data-table',{attrs:{"headers":[
              {text: 'key', value: 'key'},
              {text: 'value', value: 'value'} ],"items":_vm.dbInfoMap[1]}})],1)],1):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }