<!-- @/views/charts/modals/SelectVistypeDlg.vue -->
<template>
  <div>
    <v-dialog
      content-class="custom-dialog"
      v-model="vis_type_modal"
      width="900"
      persistent
      scrollable
    >
      <v-card 
        height="500px"
        color="modal_background"
      >
        <v-card-title>
          <div class="card-header d-flex">
          Select Visualization type
          <v-text-field
            v-model="viz_search"
            ref="searchInput"
            hide-details
            label="Search Visualization Types"
            no-filter
            dense
            solo
            outlined
            hide-no-data
            prepend-inner-icon="mdi-magnify"
            
            style="max-width: 300px;"
          >
          </v-text-field>
          <v-icon dense
            @click.prevent="cancelVizType"
          >mdi-close-circle-outline
          </v-icon>
          </div>
        </v-card-title>
        <v-card-text>
        <!-- <v-container> -->
          <v-divider/>
          <!-- Card Content -->
          <div class="col-md-12 d-flex">
            <!-- filter charts -->
            <v-col md="4">
              <!-- Filter chart header -->
              <div class="chart-filter d-flex">
                <div class="chart-filter-header">
                  Filter Charts
                </div>
                <div class="p-2">
                  <v-icon
                    right
                    @click="clearFilter"
                    v-if="filter_category"
                  >
                    mdi-close-circle-outline
                  </v-icon>
                </div>
              </div>
              <v-divider/>
              <!-- filter button group -->
              <v-btn-toggle 
                dense
                v-model="toggle_filter"
              >
                <v-expansion-panels inset flat>
                  <!-- frequently used -->
                  <v-expansion-panel
                    flat
                    inset
                    style="background-color: #262626"
                  >
                    
                    <v-expansion-panel-header
                    >
                      Frequently Used
                    </v-expansion-panel-header>
                    <v-expansion-panel-content
                      style="background-color: #262626"
                    >
                      <div v-for="(val, n) in chart_filters['frequent']" :key="n">
                        <v-btn
                          active-class="btn-active"
                          outlined
                          text
                          width="100%"
                          @click="filterClick('frequent', n)"
                        >
                          <!-- {{ n }} {{val}} -->
                          {{val}}
                          <v-icon
                            v-if="val===filter_name"
                            right
                          >
                            mdi-check
                          </v-icon>
                        </v-btn>
                      </div>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                  <!-- </v-expansion-panels> -->
                    <!-- remove this close/open tag to make them joined -->
                  <!-- <v-expansion-panels inset flat> -->
                  <!-- Chart categories -->
                  <v-expansion-panel
                    flat
                    inset
                    style="background-color: #262626"
                  >
                    <v-expansion-panel-header
                    >
                      Chart Category
                    </v-expansion-panel-header>
                    <v-expansion-panel-content
                      style="background-color: #262626"
                    >
                        <div v-for="(val, n) in chart_filters['category']" :key="n">
                        <v-btn
                          active-class="btn-active"
                          outlined
                          text
                          width="100%"
                          @click="filterClick('category', n)"
                        >
                          {{ n }} {{val}}
                          <v-icon
                            v-if="val===filter_name"
                            right
                          >
                            mdi-check
                          </v-icon>
                        </v-btn>
                        </div>
                        </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-btn-toggle>
            </v-col>
            <v-divider vertical inset/>
            <!-- Chart Selection -->
            <!-- TODO Check for many rows and order n-rows x 6 chart types -->
            <v-col md="8" style="position: relative">
              <!-- <div class=" d-flex"> -->
              <!-- </div> -->
              <!-- mt-10  -->
              <div class=" chart-box d-flex flex-wrap">
                <!-- class="d-block text-center mr-4" -->
                  <!-- style="width: 80px; cursor: pointer" -->
                <div v-for="(value, n) in vizObjects" :key="n"
                  class="chart-block text-center mb-4"
                >
                  <v-btn icon
                    @click.stop="clickVizType(value['viz_type'])"
                  >
                  <v-icon :focus="false"
                    color="#B9B9B9"
                  >
                    {{ vizIcon( value["viz_type"] ) }}
                  </v-icon>
                  </v-btn>
                  <div
                    :class="[ viz_type === value['viz_type'] ?
                      'outline-selected': 'icon-text'
                    ]"
                  >
                    {{ value['verbose_name'] }}
                  </div>
                </div>
                <!-- <div> -->
                  <!-- <SpIcon/> -->
                <!-- </div> -->
              </div> 
              </v-col>
          </div>
        </v-card-text>
        <v-divider/>
        <v-card-actions tag="vis-type-actions">
          <v-spacer/>
          <!-- cancel -->
          <v-btn
            class="mr-5"
            depressed
            @click="cancelVizType"
          >
            Cancel
          </v-btn>
          <!-- apply -->
          <v-btn
            color="primary"
            depressed
            @click.stop="moveToDataSet"
            :disabled="!viz_type"
          >
            OK
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>

import { vizIcon, viz_objects } from "@/utils/chartUtils"
// import SpIcon from "@/utils/SpIcon.vue" // use for getting svg data

export default {
  name: "SelectVisTypeDlg",
  components: {
    // SpIcon,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      viz_objects,
      viz_search: null,
      toggle_filter: null,
      filter_name: null,
      filter_category: null,
      viz_type: null,
      chart_filters: {
        "frequent": [
          "Popular","ECharts",
          "Advanced-Analytics"
        ],
        "category": [
          "Correlation",
          "Distribution", "Flow",
          "KPI","Map", "Part of a Whole",
          "Ranking", "Statistical"
        ],
      },
    };
  },
  methods: {
    vizIcon,
    clearFilter() {
      this.toggle_filter=null;
      this.filter_name=null;
      this.filter_category=null;
    },
    filterClick(category, n) {
      let filter_name= this.chart_filters[category][n]

      if (this.filter_name === filter_name) {
        this.filter_name=null;
        this.filter_category=null;
      } else {
          this.filter_name = filter_name;
          this.filter_category = category;
      }
    },
    cancelVizType() {
      this.viz_type=null;
      this.$parent.vis_type_modal = false;
    },
    clickVizType(viz_type) {
      this.viz_type=viz_type;
    },
    moveToDataSet() {
      this.$emit('closeVisTypeSelect', this.viz_type)
    },
  },
  computed: {
    vizObjects() {
      let filt;
      if (this.viz_search) {
        filt =  Object.keys(this.viz_objects).
          filter((key) => key.includes(this.viz_search)).
          reduce((cur, key) => { 
            return Object.assign(cur, { [key]: this.viz_objects[key] })
          }, {});
      } else {
        filt = this.viz_objects
      }
      return filt;
    },
    vis_type_modal: {
      get: function () {
        return this.value;
      },
      set: function (newValue) {
        this.value = newValue;
      },
    },
  },
};
</script>

<style lang="scss" scoped>

.chart-block {
  width: 20%; cursor: pointer
}

.card-header {
  width: 100%;
  justify-content: space-between;
  // margin-top: 10px;
}

.chart-filter {
  width: 100%;
  justify-content: space-between;

  &-header {
    font-weight:bold;
    font-size: 16px;
    margin-bottom: 4px;
  }
}
.btn-active {
  background-color: #2987F8; // hover blue
}

.v-btn-toggle {
  flex-direction: column;
  width: 100%;
}

.v-expansion-panel-header {
  border-bottom: solid 1px #DDDDDD;
}

.listing-item {
  background-color: blue;
  margin-top: 8px;
}

.icon-text {
  color: #B9B9B9;
}
.close {
  margin-right: 15px;
  cursor: pointer;
  font-size: 20px;
}
// .custom-dialog {
//   position: absolute;
//   /*right: 10%;*/
//   top: 10%;
// }
.outline-selected {
  border-bottom: 1px solid #ffffff;
  color: white;
}
</style>