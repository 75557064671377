<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height="18"
    viewBox="0 0 18 18"
    width="18"
  >
    <title>S GraphBubble 18 N</title>
    <rect id="Canvas" fill="#ff13dc" opacity="0" width="18" height="18" />
    <circle class="fill" cx="4" cy="4" r="3" />
    <circle class="fill" cx="3" cy="12" r="2" />
    <path
      class="fill"
      d="M13.25,7.169a2.47049,2.47049,0,1,0-3.2735.2535A5.02,5.02,0,1,0,13.25,7.169Z"
    />
  </svg>
</template>

<script>
export default {
    name: 'Bubble'
};
</script>

<style>
.fill {
  fill: #ffffff;
}
</style>