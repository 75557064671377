<template>
  <div class="d-flex flex-column fill-height flex-grow-1 overflow-hidden pt-6">
    <ModuleHeader
      title="Charts"
      :view-grid="viewGrid"
      @updateLayoutOption="viewGrid = !viewGrid"
      @updateViewFavOnly="viewFavOnly = !viewFavOnly"
    />

    <!-- Action Header -->
    <ModuleAction
      title="New Chart"
      search-label="Search Charts"
      :selectedItems="selectedItemIds"
      :n_items="filteredItemCount"
      @clickNewItem="clickNewItem"
      @deleteAll="deleteAll" 
      @selectItemsEvent="selectItemsEvent"
      @moduleFilterChanged="moduleFilterChanged"
      
    />
    <!-- <div class="d-flex align-center">
      <v-btn
        tile
        depressed
        icon
        small
        disabled
        class="mr-1 bg-primary"
        @click="deleteAll()"
      >
        <v-icon size="18"> mdi-trash-can </v-icon>
      </v-btn>
      <v-btn tile depressed icon small class="bg-primary">
        <v-icon size="18"> mdi-chevron-down </v-icon>
      </v-btn>
      <v-divider vertical class="mx-4"></v-divider>
      <v-btn
        tile
        depressed
        small
        class="mr-1 bg-primary"
        @click="clickNewItem()"
      >
        New Chart
      </v-btn>
      <v-divider vertical class="mx-4"></v-divider>
      <div style="width: 400px">
        <v-text-field
          ref="searchInput"
          hide-details
          label="Search your charts"
          no-filter
          dense
          solo
          outlined
          hide-no-data
          prepend-inner-icon="mdi-magnify"
          class="hidden-sm-and-down mx-auto custom-class"
        >
        </v-text-field>
      </div>
    </div> -->
    
    <!-- Show Charts found grid view -->
    <div v-if="viewGrid">
      <v-container>
        <v-row
          v-if="loading"
          class="container-row-resolution py-5 d-flex justify-center"
        >
          <v-progress-circular
            indeterminate
            color="primary"
          ></v-progress-circular>
        </v-row>

        <v-row
          v-else-if="getModels(collection, viewFavOnly).length"
          class="container-row-resolution"
        >
          <BoxItem
            v-for="item in getModels(collection, viewFavOnly)"
            v-bind:key="item.id"
            :item="item"
            :class-name="`slice`"
            :title-field="`slice_name`"
            :selectable=true
            @toggled="toggleFav"
            @checked="toggleChecked"
            @clickItem="clickItem"
          />
        </v-row>

        <v-row
          v-else
          class="container-row-resolution pt-5 d-flex justify-center"
        >
          <p>No Charts Found ...</p>
        </v-row>
      </v-container>
    </div>
    <!-- Show Charts found list view -->
    <div
      v-if="!viewGrid"
      class="
        d-flex
        flex-grow-1 flex-column flex-basis-0
        fill-height
        overflow-x-auto
      "
    >
      <v-container>
        <v-row
          v-if="loading"
          class="container-row-resolution py-5 d-flex justify-center"
        >
          <v-progress-circular
            indeterminate
            color="primary"
          ></v-progress-circular>
        </v-row>

        <v-data-table
          id="tblItems"
          v-else-if="getModels(collection, viewFavOnly).length"
          v-model="selectedItems"
          :items="getModels(collection, viewFavOnly)"
          :items-per-page="$pageItemCount"
          :headers="headers"
          :search="search"
          @click:row="clickItem"
          @item-selected="updateGridViewSelection"
          @toggle-select-all="toggleSelectAll"
          show-select
          item-key="id"
          class="mt-3"
          :hide-default-footer="true"
          :header-props="{ sortIcon: 'mdi-chevron-down' }"
        >
          <template v-slot:top="{ pagination, options, updateOptions }">
            <v-data-footer
              :pagination="pagination"
              :options="options"
              @update:options="updateOptions"
              items-per-page-text="$vuetify.dataTable.itemsPerPageText"
            />
          </template>
          <template v-slot:[`header.favorite`]="{ header }">
            <v-icon v-if="header.favorite">mdi-star</v-icon>
            <v-icon v-else>mdi-star-outline</v-icon>
          </template>
          <template #[`item.favorite`]="{ item }">
            <div @click.stop="toggleFav(item, 'project')" class="actions">
              <v-btn icon width="24" v-tippy content="Favorite">
                <v-icon v-if="item.favorite">mdi-star</v-icon>
                <v-icon v-else>mdi-star-outline</v-icon>
              </v-btn>
            </div>
          </template>
          <template #[`item.slice_name`]="{ item }">
            <div @click.stop class="title">
              <a @click="clickItem(item)">{{ item.slice_name }}</a>
            </div>
          </template>
          <template #[`item.created_by.username`]="{ item }">
            <div v-if="item.created_by" class="actions">
              {{ item.created_by.username }}
            </div>
          </template>
          <template #[`item.action`]="{ item }">
            <RowAction
              :item="item"
              :disabledList="disabledRowActions"
              @clickItem="clickItem"
              @deleteItem="deleteItem"
            />
          </template>
        </v-data-table>
        <!-- No rows (charts) found -->
        <v-row
          v-else
          class="container-row-resolution pt-5 d-flex justify-center"
        >
          <p>No Charts Found ...</p>
        </v-row>
      </v-container>
    </div>
    <!-- Select Visualization Type modal -->
    <div>
      <SelectVisTypeDlg 
        v-model="vis_type_modal" 
        @closeVisTypeSelect="closeVisTypeSelect"
      />
      <SelectDataSetDlg 
        v-model="dataset_modal" 
        :chartType="chart_type" 
        @cancelDatasetSelect="cancelDatasetSelect"
        @datasetSelectBack="datasetSelectBack"
      />
      <!-- v-if is necessary othewise it won't reload based on Id change-->
      <!-- <DatasetDetailDlg
        v-if="dataset_detail_modal"
        v-model="dataset_detail_modal"
        :id="data_set_id"
        :chartType="chart_type"
      /> -->
    </div>
  </div>
</template>

<script>
import BoxItem from "@/views/_partials/BoxItem";
import RowAction from "@/views/_partials/RowAction";
import ModuleHeader from "@/views/_partials/ModuleHeader";
import ModuleAction from "@/views/_partials/ModuleAction";
import SelectVisTypeDlg from "@/views/charts/modals/SelectVisTypeDlg";
import SelectDataSetDlg from "@/views/charts/modals/SelectDataSetDlg";
// import DatasetDetailDlg from "@/views/charts/modals/DatasetDetailDlg";

import {
  GET_CHART_ALL,
  DELETE_CHART,
  DELETE_CHART_MULTI,
  SET_FAV,
  UNSET_FAV,
  // GET_CHART_BY_ID,
  // POST_CHART_FORM
  // SAVE_FORM_DATA
} from "@/store/actionType";

import {
  ChartRowActions as disabledRowActions
} 
from "@/utils/listRowActions";

import { clickItem } from "@/utils/chartUtils";
import { 
    toggleSelectAll,
    modifyAllCheck,
    selectItemsEvent,
    filteredObjs,
  } from "@/utils/moduleUtils.js";

export default {
  namespaced: true,
  name: "Charts-Index",
  components: {
    ModuleHeader,
    ModuleAction,
    BoxItem,
    RowAction,
    SelectVisTypeDlg,
    // DatasetDetailDlg,
    SelectDataSetDlg,
  },
  data: () => ({
    disabledRowActions,
    // disabledRowActions: {
    //   'edit':true, 
    //   'info':true, 
    //   'trashcan':false, 
    //   'action': true
    // },
    moduleFilter:'',
    sortFields: [
      { txt: "Title", field: "slice_name" },
      { txt: "Date", field: "changed_on_utc" },
    ],
    curSortField: "",
    viewGrid: true,
    viewFavOnly: false,
    selectedItems: [],
    search: "",
    headers: [
      { text: "Favorite", value: "favorite", width: 75 },
      { text: "Title", value: "slice_name" },
      { text: "Visualization type", value: "viz_type" },
      { text: "Dataset", value: "datasource_type" },
      { text: "Last Modified", value: "changed_on_delta_humanized" },
      { text: "Created By", value: "created_by.username" },
      { text: "Actions", value: "action", width: 200, sortable: false },
    ],
    collection: [], // use one variable for Recent + Favorites for consistency
    loading: false,
    vis_type_modal: false,
    dataset_modal: false,
    dataset_detail_modal: false,
    chart_type: null,
    data_set_id: null,
  }),
  computed: {
    filteredItemCount () {
      return [ 
        this.collection.length, // count of items
        // filtered items
        this.getModels(this.collection, this.viewFavOnly).length
      ]
    },

    selectedItemIds () {
      let id_list = this.selectedItems.map(a => a.id);
      return id_list;
    },
  },
  methods: {
    // Imports
    //////////
    // chartUtils
    clickItem,
    //moduleUtils
    toggleSelectAll,
    modifyAllCheck,
    selectItemsEvent,
    filteredObjs,
    //////////
    datasetSelectBack () {
      // go back to chart type select
      console.log('go back')
      this.vis_type_modal = true;
      this.dataset_detail_modal = false;
      this.dataset_modal = false;
    },
    cancelDatasetSelect() {
      console.log('close dialog')
      this.chart_type=null;
      this.vis_type_modal = false;
      this.dataset_modal = false;
    },
    closeVisTypeSelect(viz_type) {
      console.log('select vis type', viz_type)
      this.chart_type=viz_type;
      this.vis_type_modal = false;
      this.dataset_modal = true;
    },
    moduleFilterChanged (input) {
      this.moduleFilter = input;
    },
    getRecentModels(arr) {
      const filteredArr = arr.filter(function (item) {
        return item.recent === true;
      });
      return filteredArr;
    },
    getFavModels(arr) {
      return arr.filter(function (item) {
        return item.favorite === true;
      });
    },
    getModels(arr, viewFavOnly) {
      if (arr.length > 0 && this.moduleFilter.length > 0) {
        arr = this.filteredObjs(arr, 'slice_name', this.moduleFilter)
      }
      return viewFavOnly ? this.getFavModels(arr) : this.getRecentModels(arr);
    },
    load() {
      const columns = [
        "id",
        "changed_by.username",
        "created_by.first_name",
        "created_by.last_name",
        "created_by.username",
        "changed_on_utc",
        "changed_on_delta_humanized",
        "slice_name",
        "viz_type",
        "datasource_type",
        "datasource_id",
        "form_data_key",
      ];

      const q = {
        columns: columns,
        order_column: this.curSortField,
        order_direction: "desc",
      };

      const favQ = {
        ...q,
        filters: [
          {
            col: "id",
            opr: "chart_is_favorite",
            value: "!t",
          },
        ],
      };

      // Load Recent Item
      let recentArr = [];
      this.loading = true;
      this.$store
        .dispatch(GET_CHART_ALL, q)
        .then((r) => {
          recentArr = r.data.result;

          // Load Favorite Charts
          return this.$store.dispatch(GET_CHART_ALL, favQ);
        })
        .then((favResult) => {
          this.collection = this.mergeFavItems(recentArr, favResult);
        })
        .catch((r) => {
          console.error("We have error:", r);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    mergeFavItems(recentArr, favResult) {
      let favIds = favResult.data.ids;

      for (let index in favResult.data.result) {
        favResult.data.result[index]["favorite"] = true;
      }
      for (let index in recentArr) {
        const _index = favIds.indexOf(recentArr[index].id);
        if (_index === -1) {
          recentArr[index]["favorite"] == false;
          recentArr[index]["recent"] = true;
          favResult.data.result.push(recentArr[index]);
        } else {
          favResult.data.result[_index]["recent"] = true;
        }
      }
      return favResult.data.result;
    },

    toggleFav(item, className) {
      const param = {
        id: item.id,
        className: "slice",
      };

      let collection = this.collection;
      let itemIndex = collection.indexOf(item);

      if (item.favorite === true) {
        this.$store
          .dispatch(UNSET_FAV, param)
          .then((r) => {
            item.favorite = false;
            let editedItem = { ...item };
            collection.splice(itemIndex, 1, editedItem);
            // console.log(collection);
          })
          .catch((r) => {
            console.error("We have error: ", r);
          });
      } else {
        this.$store
          .dispatch(SET_FAV, param)
          .then((r) => {
            item.favorite = true;
            let editedItem = { ...item };
            collection.splice(itemIndex, 1, editedItem);
          })
          .catch((r) => {
            console.error("We have error: ", r);
          });
      }
    },

    /**
     * click item in table
     * @param item
     */
    /**
    clickItem(item) {
      // TODO: assign new key if explore form_data != chart.form_data
      
      // if (item.form_data_key) {
      //   console.log("myform")
      // // if (item.form_data_key===2) {
      //   // this.$router.push(
      //     // `/visualizations/${item.id}/${item.form_data_key}`
      //   // );
      // } else {
        this.$store.dispatch(GET_CHART_BY_ID, item.id).then((res) => {
          let params = JSON.parse(res.data.result.params);
          let _this = item.form_data_key
          let key_form = {
            key: item.form_data_key,
            data: {
              chart_id: item.id,
              dataset_id: item.datasource_id,
              form_data: JSON.stringify(params)
            },
            };
          
          this.$store.dispatch(SAVE_FORM_DATA, key_form);
          this.$router.push(`/visualizations/${item.id}/${_this}`);
          // this.$router.push(`/visualizations/${item.id}/${item.form_data_key}`);
          // this.$store.dispatch(POST_CHART_FORM, params).then((result) => {
            // this.$router.push(`/visualizations/${item.id}/${result.data.key}`);
          // })
        })
      // }
    },
    */

    deleteItem(item) {
      this.$store.dispatch(DELETE_CHART, item.id).then(() => {
        this.load();
      });
    },

    deleteAll() {
      let selIds = this.selectedItemIds
      this.$store
        .dispatch(DELETE_CHART_MULTI, selIds)
        .then(() => {
          this.load();
          this.selectedItems=[];
        })
        .catch((r) => {
          console.error("We have error:", r);
        });
    },
    clickNewItem() {
      this.$store.commit("SET_PROJECT_INFO", null);
      this.vis_type_modal = true;
    },
    /**
     * Update the selection status in Grid View
     */
    updateGridViewSelection(obj) {
      let selItem = this.collection.find((item) => item.id === obj.item.id);
      if (selItem) {
        selItem.checked = obj.value;
      }
    },
    /**
     * Update the selection status in Grid View
     */
    toggleChecked(item) {
      let index = this.selectedItems.findIndex((_item) => _item.id === item.id);
      if (item.checked === true) {
        if (index == -1) {
          this.selectedItems.push(item);
        }
      } else {
        if (index > -1) {
          this.selectedItems.splice(index, 1);
        }
      }
    },
  },
  mounted() {
    this.load();
  },
};
</script>

<style scoped>
</style>
