<template>
  <div class="separator-text">
    <span>{{ text }}</span>
  </div>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      default: "",
    },
  },
};
</script>

<style>
.separator-text {
  color: #FFFFFF;
  display: flex;
  align-items: center;
  text-align: center;
  padding: 0px 12px;
  font-size: 11px;
}

.separator-text::before,
.separator-text::after {
  content: "";
  flex: 1;
  border-bottom: 1px solid #707070;
}

.separator-text:not(:empty)::before {
  margin-right: 16px;
}

.separator-text:not(:empty)::after {
  margin-left: 16px;
}
</style>