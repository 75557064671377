import DatabaseService from "../services/DatabaseService";

import {
  GET_DATABASE_ALL,
  GET_DATABASE_BY_ID,
  DELETE_DATABASE
} from "./actionType";

const state = {};

const actions = {
  [GET_DATABASE_BY_ID](_, id) {
    return DatabaseService.get(id).then(
      res => {
        return res;
      }
    )
  },
  [GET_DATABASE_ALL](_, q) {
    return DatabaseService.getAll(q).then(
      res => {
        return res;
      }
    )
  },
  [DELETE_DATABASE](_, id) {
    return DatabaseService.delete(id).then(
      res => {
        return res;
      }
    )
  },
}

const mutations = {};

export default {
    state,
    actions,
    mutations
}