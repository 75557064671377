<template>
  <div class="d-flex flex-column flex-grow-1 overflow-hidden pt-6">
    <ModuleHeader
      :title="`Projects /  ${project.project_title}`"
      :disabled-fav-btn="true"
      :disabledListBtn="listBtnDisabled"
      :disabledGridBtn="gridBtnDisabled"
      returnToPage="Projects-Index"  
      @updateLayoutOption="viewGrid = !viewGrid"
      @updateViewFavOnly="viewFavOnly = !viewFavOnly"

    />

    <v-tabs color="white" v-model="tab">
      <v-tab>CHARTS</v-tab>
      <v-tab>DASHBOARDS</v-tab>
      <v-tab>MEMBERS & DETAILS</v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab">
      <!-- Charts Tab -->
      <v-tab-item>
        <ChartTab
        :project="project"
        :viewGrid="viewGrid"
        @reloadProject="getProject"
        />
      </v-tab-item>
      <!-- dashboard tab -->
      <v-tab-item>
        <DashboardTab
        :project="project"
        :viewGrid="viewGrid"
        @reloadProject="getProject"
        />
        <!-- <ModuleAction
          class="pa-0 pt-3"
          title="Dashboard"
          :search-label="`Search Dashboards in ${project.project_title}`"
          @clickNewItem="clickNewDashboard"
          @deleteAll="deleteAll"
        /> -->
        <!-- dashboard grid view -->
        <!-- <div v-if="viewGrid">
          <v-container class="pa-0 pt-3">
            <v-row class="container-row-resolution mt-3">
              <BoxItem
                v-for="item in project.dashboard_metadata"
                v-bind:key="item.id"
                :item="item"
                :class-name="`dashboard`"
                :title-field="`title`"
                :selectable="true"
                @toggled="toggleFav"
                @checked="toggleChecked"
                @clickItem="clickDashboard"
              />
            </v-row>
          </v-container>
        </div> -->
        <!-- dashboard list view -->
        <!-- <div
          v-if="!viewGrid"
          class="
            d-flex
            flex-grow-1 flex-column flex-basis-0
            fill-height
            overflow-x-auto
          "
        >
          <v-data-table
            id="tblDashboards"
            v-model="selectedDashboards"
            :items="project.dashboard_metadata"
            :headers="dashboardHeaders"
            :search="searchDashboard"
            @click:row="clickDashboard"
            @item-selected="updateGridViewSelection"
            show-select
            item-key="id"
            class="mt-3"
            :hide-default-footer="true"
            :header-props="{ sortIcon: 'mdi-chevron-down' }"
          >
            <template v-slot:top="{ pagination, options, updateOptions }">
              <v-data-footer
                :pagination="pagination"
                :options="options"
                @update:options="updateOptions"
                items-per-page-text="$vuetify.dataTable.itemsPerPageText"
              />
            </template>
            <template v-slot:[`header.favorite`]="{ header }">
              <v-icon v-if="header.favorite">mdi-star</v-icon>
              <v-icon v-else>mdi-star-outline</v-icon>
            </template>
            <template #[`item.favorite`]="{ item }">
              <div @click.stop="toggleFav(item, 'dashboard')" class="actions">
                <v-btn icon width="24" v-tippy content="Favorite">
                  <v-icon v-if="item.favorite">mdi-star</v-icon>
                  <v-icon v-else>mdi-star-outline</v-icon>
                </v-btn>
              </div>
            </template>
            <template #[`item.title`]="{ item }">
              <div @click.stop class="title">
                <a :href="`/#/dashboards/${item.id}`">{{ item.title }}</a>
              </div>
              <span>{{ item.Description }}</span>
            </template>
            <template #[`item.action`]="{ item }">
              <RowAction
                :item="item"
                @clickItem="clickDashboard"
                @deleteItem="deleteItem"
              />
            </template>
          </v-data-table>
        </div> -->
      </v-tab-item>
      <!-- members/metadata tab -->
      <v-tab-item>
        <MemberTab
        :project="project"
        :viewGrid="viewGrid"
        @reloadProject="getProject"
        />
        <!-- <v-container class="pa-0 pt-3">
          <v-row>
            <v-col
              cols="6"
              class="flex-grow-0 flex-shrink-0 pt-0"
              style="min-width: 100px"
            >
              <v-card class="mx-auto" style="background: rgb(18, 18, 18)">
                <v-card-title class="pa-0"> Members </v-card-title>

                <ModuleAction
                  class="pl-0 pt-3"
                  title="Member"
                  :search-label="`Search Members in ${project.project_title}`"
                  @clickNewItem="clickNewDashboard"
                  @deleteAll="deleteAll"
                />
              </v-card>
              <div
                class="
                  d-flex
                  flex-grow-1 flex-column
                  pa-0
                  flex-basis-0
                  fill-height
                  overflow-x-auto
                "
              >
                <v-data-table
                  id="tblMembers"
                  v-model="selectedMembers"
                  :items="project.members_metadata"
                  :headers="memberHeaders"
                  :search="searchMember"
                  show-select
                  item-key="id"
                  class="mt-3"
                  :hide-default-footer="true"
                  :header-props="{ sortIcon: 'mdi-chevron-down' }"
                >
                  <template #[`item.username`]="{ item }">
                    <div @click.stop class="title">
                      {{ item.name }}
                    </div>
                    <span>{{ item.email }}</span>
                  </template>
                  <template #[`item.action`]="{ item }">
                    <RowAction
                      :item="item"
                      @clickItem="clickDashboard"
                      @deleteItem="deleteItem"
                    />
                  </template>
                </v-data-table>
              </div>
            </v-col>
            <v-col
              cols="6"
              style="min-width: 500px; padding: 0 !important"
              class=""
            >
              <v-card
                class="mx-auto"
                style="min-height: 80vh; background: rgb(18, 18, 18)"
              >
                <v-card-title> Project Name & Description </v-card-title>

                <v-card-text>
                  <div class="mb-3">Project Name</div>
                  <v-text-field
                    filled
                    label=""
                    v-model="project.project_title"
                    :readonly="true"
                  ></v-text-field>

                  <p>Description</p>
                  <v-textarea
                    filled
                    label=""
                    v-model="project.description"
                    :readonly="true"
                  ></v-textarea>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-container> -->
      </v-tab-item>
    </v-tabs-items>
    <div>
      <!-- <SelectVisTypeDlg v-model="vis_type_modal" /> -->
      <!-- <SelectDataSetDlg v-model="dataset_modal" :chartType="chart_type" /> -->
      <!-- v-if is necessary othewise it won't reload based on Id change-->
      <!-- <DatasetDetailDlg
        v-if="dataset_detail_modal"
        v-model="dataset_detail_modal"
        :id="data_set_id"
        :chartType="chart_type"
      /> -->
    </div>
  </div>
</template>

<script>
import ChartTab from "./DetailChart.vue"
import DashboardTab from "./DetailDashboard.vue"
import MemberTab from "./DetailMembers.vue"
// import BoxItem from "@/views/_partials/BoxItem";
// import RowAction from "@/views/_partials/RowAction";
import ModuleHeader from "@/views/_partials/ModuleHeader";
// import ModuleAction from "@/views/_partials/ModuleAction";
// import SelectVisTypeDlg from "@/views/charts/modals/SelectVisTypeDlg";
// import SelectDataSetDlg from "@/views/charts/modals/SelectDataSetDlg";
// import DatasetDetailDlg from "@/views/charts/modals/DatasetDetailDlg";
// import SelectCharts from "@/views/charts/modals/SelectCharts";

import {
  GET_PROJECT_BY_ID,
  SET_FAV,
  UNSET_FAV,
  GET_FAV_STATUS_ALL,
  REMOVE_PROJECT_CHILDREN,
} from "@/store/actionType";

import { 
    toggleSelectAll,
    modifyAllCheck,
    selectItemsEvent,
    filteredObjs,
  } from "@/utils/moduleUtils.js";

export default {
  name: "Projects-Detail",
  components: {
    ChartTab,
    DashboardTab,
    ModuleHeader,
    MemberTab,
    // ModuleAction,
    // RowAction,
    // BoxItem,
    // SelectVisTypeDlg,
    // SelectDataSetDlg,
    // DatasetDetailDlg,
    // SelectCharts,
  },
  data: () => ({
    project: {
      project_title: "",
    },
    orderByItems: ["Date", "Favorites", "Creator"],
    viewGrid: true,
    viewFavOnly: false,
    selectedCharts: [],
    selectedDashboards: [],
    selectedMembers: [],
    // searchDashboard: "",
    // searchMember: "",
    // searchChart: "",
    vis_select_modal: false,
    vis_type_modal: false,
    dataset_modal: false,
    dataset_detail_modal: false,
    chart_type: null,
    data_set_id: null,
    // chartHeaders: [
    //   { text: "Favorite", value: "favorite", width: 75 },
    //   { text: "Title", value: "chart" },
    //   { text: "Visualization type", value: "visualization_type" },
    //   { text: "Dataset", value: "dataset" },
    //   { text: "Last Modified", value: "changed_on_delta_humanized" },
    //   { text: "Created by", value: "created_by" },
    //   { text: "Actions", value: "action", width: 200, sorteble: false },
    // ],
    // dashboardHeaders: [
    //   { text: "Favorite", value: "favorite", width: 75 },
    //   { text: "Title", value: "title" },
    //   { text: "Slices", value: "slice_count" },
    //   { text: "Last Modified", value: "changed_on_delta_humanized" },
    //   { text: "Last Modified By", value: "changed_by" },
    //   { text: "Created by", value: "created_by" },
    //   { text: "Actions", value: "action", width: 200, sorteble: false },
    // ],
    // memberHeaders: [
    //   { text: "Name", value: "username" },
    //   { text: "Role", value: "role" },
    //   { text: "Actions", value: "action", width: 200, sorteble: false },
    // ],
    tab: null,
  }),
  computed: {
    gridBtnDisabled() {
      // console.log('computed', this.tab)
      if (this.tab === 2) {
        return true;
      } else {
        return false
      }
    },
    listBtnDisabled() {
      if (this.tab === 2) {
        return true;
      } else {
        return false
      }
    },
    collection() {
      if (this.tab === 0) {
        return this.project.slice_metadata;
      } else {
        return false;
      }
    },

    selectedChartIds () {
      console.log('selitems', this.selectedCharts)
      let id_list = this.selectedCharts.map(a => a.id);
      return id_list;
      // return true;
    },
  },
  methods: {
    // Imports
    //////////
    //moduleUtils
    toggleSelectAll,
    modifyAllCheck,
    selectItemsEvent,
    filteredObjs,
    //////////
    getProject(id) {
      console.log('get project', id)
      let _obj = null;
      this.$store
        .dispatch(GET_PROJECT_BY_ID, id)
        .then((r) => {
          _obj = r.data.result;
          this.$store.commit('SET_PROJECT_INFO', {
            project_id: _obj.id,
            project_title: _obj.project_title,
            members_metadata: _obj.members_metadata,
          });

          // Load Favorite Dashboards
          const favIds = _obj.dashboards.map((ele) => ele.id);
          const param = {
            className: "dashboard",
            q: favIds,
          };

          return this.$store.dispatch(GET_FAV_STATUS_ALL, param).then((r) => {
            for (let index in r.data.result) {
              _obj.dashboard_metadata[index]["favorite"] =
                r.data.result[index].value;
            }

            const favIds = _obj.slices.map((ele) => ele.id);
            const param = {
              className: "chart",
              q: favIds,
            };

            return this.$store.dispatch(GET_FAV_STATUS_ALL, param);
          });
        })
        .then((r) => {
          for (let index in r.data.result) {
            _obj.slice_metadata[index]["favorite"] = r.data.result[index].value;
          }
          this.project = _obj;
        })
        .catch((r) => {
          console.error("Error: ", r);
        })
        .finally((r) => {
          this.project = _obj;
        });
    },
    clickDashboard(item) {
      this.$router.push({ name: "Dashboards-Detail", params: { id: item.id } });
    },
    clickChart(item) {
      alert("Need to confirm how to implement");
      // this.$router.push({ name: 'Charts-Detail', params: { id: item.id } });
    },
    clickNewChart() {
      console.log('vis select', this.project)
      this.vis_select_modal = true;
      // this.vis_type_modal = true;
    },
    clickNewDashboard() {
      this.$router.push({ name: "Dashboards-New" });
    },
    clickNewMember() {
      this.$router.push({ name: "Dashboards-New" });
    },
    deleteItem(item) {
      const className = this.tab === 0 ? "slice" : "dashboard";
      const param = {
        className: className,
        id: this.project.id,
        q: [item.id],
      };

      this.$store
        .dispatch(REMOVE_PROJECT_CHILDREN, param)
        .then((r) => {
          this.getProject(this.project.id);
        })
        .catch((r) => {
          console.error("Error: ", r);
        });
    },
    deleteAll(type) {
      console.log('delete me', type, this.selectedChartIds)
      // let className = "dashboards";
      // let collection = this.project.dashboard_metadata;

      // if (this.tab === 1) {
      //   // Dashboards
      //   className = "charts";
      //   collection = this.project.slice_metadata;
      // }
      let selIds=[]
      switch (type) {
        case "charts":
          selIds = this.selectedChartIds;
          break;
      }
    //       this.collection.map(o => this.$set(o, 'checked', true))
    //       this.selectedItems=[...this.collection]
    //       break;
    //     case "unselect_all":
    //       this.collection.map(o => this.$set(o, 'checked', false))
    //       this.selectedItems=[]
    //       break;
    //   }
      

      // const selIds = collection.map((ele) => ele.id);
      const param = {
        className: type,
        id: this.project.id,
        q: selIds,
      };
      console.log('delete these', param)
      this.$store
        .dispatch(REMOVE_PROJECT_CHILDREN, param)
        .then(() => {
          this.getProject(this.project.id);
        })
        .catch((r) => {
          console.error("We have error:", r);
        });
        this.selectedCharts=[];
    },
    toggleFav(item, className) {
      const param = {
        id: item.id,
        className: className,
      };

      let collection = [];
      if (className === "dashboard") {
        collection = this.project.dashboard_metadata;
        param.className = "Dashboard";
      } else if (className === "slice") {
        collection = this.project.slice_metadata;
      }

      let itemIndex = collection.indexOf(item);

      if (item.favorite === true) {
        this.$store
          .dispatch(UNSET_FAV, param)
          .then((r) => {
            item.favorite = false;
            let editedItem = { ...item };
            collection.splice(itemIndex, 1, editedItem);
          })
          .catch((r) => {
            console.error("We have error: ", r);
          });
      } else {
        this.$store
          .dispatch(SET_FAV, param)
          .then((r) => {
            item.favorite = true;
            let editedItem = { ...item };
            collection.splice(itemIndex, 1, editedItem);
          })
          .catch((r) => {
            console.error("We have error: ", r);
          });
      }
    },
    /**
     * Update the selection status in Grid View
     */
    updateGridViewSelection(obj) {
      const collection =
        this.tab === 0
          ? this.project.slice_metadata
          : this.project.dashboard_metadata;
      let selItem = collection.find((item) => item.id === obj.item.id);
      if (selItem) {
        selItem.checked = obj.value;
      }
    },
    /**
     * Update the selection status in Grid View
     */
    toggleChecked(item, type) {
      const selectedItems =
        type === "slice" ? this.selectedCharts : this.selectedDashboards;
      let index = selectedItems.findIndex((_item) => _item.id === item.id);
      if (item.checked === true) {
        if (index == -1) {
          selectedItems.push(item);
        }
      } else {
        if (index > -1) {
          selectedItems.splice(index, 1);
        }
      }
    },
  },
  mounted() {
    this.getProject(this.$route.params.id);
  },
};
</script>