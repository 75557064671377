var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-dialog',{attrs:{"content-class":"custom-dialog","width":"960","min-height":"900px","scrollable":""},on:{"click:outside":_vm.cancelDialog},model:{value:(_vm.dashboard_select_modal),callback:function ($$v) {_vm.dashboard_select_modal=$$v},expression:"dashboard_select_modal"}},[_c('v-card',{attrs:{"color":"#262626","min-height":"500px"}},[_c('v-card-title',[_c('v-container',[_c('v-row',[_c('v-col',[_vm._v("Share Dashboards to Project")]),_c('v-col',[_c('v-text-field',{ref:"searchInput",staticClass:"hidden-sm-and-down custom-class ml-auto",staticStyle:{"max-width":"300px"},attrs:{"hide-details":"","label":"Filter Chart Titles","no-filter":"","dense":"","solo":"","outlined":"","hide-no-data":"","prepend-inner-icon":"mdi-magnify"},on:{"input":_vm.userInputFilter}})],1),_c('v-col',[_c('div',{staticClass:"gridSelect d-flex  align-center ml-7"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({class:!_vm.viewGrid && 'inactive',attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":"18"},on:{"click":function($event){_vm.viewGrid = !_vm.viewGrid}}},[_vm._v(" mdi-view-grid ")])],1)]}}])},[_c('span',[_vm._v("Grid View")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({class:_vm.viewGrid && 'inactive',attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":"24"},on:{"click":function($event){_vm.viewGrid = !_vm.viewGrid}}},[_vm._v(" mdi-view-list ")])],1)]}}])},[_c('span',[_vm._v("List View")])]),_c('div',{staticClass:"d-flex  align-center ml-7"})],1)])],1)],1)],1),_c('v-divider'),_c('v-card-text',[_c('v-container',[(_vm.viewGrid)?_c('div',[_c('v-container',[(_vm.getModels.length>0)?_c('v-row',{staticClass:"container-row-resolution"},_vm._l((_vm.getModels),function(item){return _c('BoxItem',{key:item.id,attrs:{"item":item,"class-name":"dashboard","title-field":"title","selectable":true},on:{"checked":_vm.toggleChecked,"clickItem":_vm.toggleChecked}})}),1):_c('v-row',{staticClass:"container-row-resolution pt-5 d-flex justify-center"},[_c('p',[_vm._v("No Charts found ...")])])],1)],1):_vm._e(),(!_vm.viewGrid)?_c('div',{staticClass:"\n              d-flex\n              flex-grow-1 flex-column flex-basis-0\n              fill-height\n              overflow-x-auto\n            "},[_c('v-data-table',{staticClass:"mt-3",attrs:{"id":"tblItems","items":_vm.getModels,"headers":_vm.headers,"search":_vm.search,"show-select":"","item-key":"id","hide-default-footer":true,"header-props":{ sortIcon: 'mdi-chevron-down' }},on:{"click:row":_vm.clickItem,"item-selected":_vm.updateGridViewSelection},scopedSlots:_vm._u([{key:"item.slice_name",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"actions",on:{"click":function($event){$event.stopPropagation();}}},[_c('a',{on:{"click":function($event){return _vm.clickItem(item)}}},[_vm._v(_vm._s(item.slice_name))])])]}},{key:"item.created_by.username",fn:function(ref){
var item = ref.item;
return [(item.created_by)?_c('div',{staticClass:"actions"},[_c('div',{staticClass:"chips",class:{ 'bg-green': item.created_by.username }},[_vm._v(" "+_vm._s(item.created_by.username.substring(0, 2).toUpperCase())+" ")])]):_vm._e()]}}],null,true),model:{value:(_vm.selectedItems),callback:function ($$v) {_vm.selectedItems=$$v},expression:"selectedItems"}})],1):_vm._e()])],1),_c('v-divider'),_c('v-card-actions',{staticStyle:{"padding-top":"12px","padding-bottom":"12px"}},[_c('v-btn',{staticClass:"mr-5",attrs:{"depressed":""},on:{"click":_vm.cancelDialog}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"color":"primary","depressed":"","disabled":!_vm.selectedItemIds.length>0},on:{"click":_vm.selectDashboards}},[_vm._v(" OK ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }