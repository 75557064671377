import {
  GET_CHART_BY_ID,
  SAVE_FORM_DATA,
  GET_CHART_FORM,
} from "@/store/actionType";


export let viz_objects = {
  "bubble": {
    "viz_type":"bubble",
    "name":"bubble",
    "verbose_name": "Bubble Chart",
  },
  "scatter": {
    "viz_type":"scatter",
    "name":"scatter",
    "verbose_name": "Scatter Plot",
  },
  "dist_bar": {
    "viz_type":"dist_bar",
    "name":"dist_bar",
    "verbose_name": "Bar Chart",
  },
  "table": {
    "viz_type":"table",
    "name":"tableIcon",
    "verbose_name": "Table",
  },
  "boxplot": {
    "viz_type":"box_plot",
    "name":"boxplotIcon",
    "verbose_name": "Boxplot",
  },
  "pie": {
    "viz_type":"pie",
    "name":"pie",
    "verbose_name": "Pie Chart",
  },
  "horiz_bar": {
    "viz_type":"horiz_bar",
    "name":"hbarIcon",
    "verbose_name": "Horizontal Bar",
  },
  // removed until vis is programmed better on frontend
  // "heatmap": {
  //   "viz_type":"heatmap",
  //   "name":"heatmapIcon",
  //   "verbose_name": "Heatmap",
  // },
};

export async function postChartForm(id, chartType) {
  // console.log('post form')
  const params = {
    datasource: id,
    viz_type: chartType
  };
  // console.log('post form', params)
  this.$store.dispatch(GET_CHART_FORM, params)
    .then((res) => {
      return res.data.result
    })
    .catch((error) =>{
      console.error(error)
    })
}



export function vizIcon(viz_type) {
  let viz_icon;
  switch(viz_type) {
    case 'dist_bar':
      viz_icon=`$barChart`
      break;
    case 'bubble':
      viz_icon=`$bubbleChart`
      break;
    case 'table':
        viz_icon=`$tableIcon`
      break;
    case 'abcIcon':
      viz_icon=`$abcIcon`
      break;
    case 'box_plot':
      viz_icon=`$boxplotIcon`
      break;
    case 'horiz_bar':
      viz_icon=`$hbarIcon`
      break;
    case 'heatmap':
      viz_icon="mdi-heat-pump"
      break;
    case 'pie':
      viz_icon=`$pieIcon`
      break;    
    case 'scatter':
        viz_icon=`$scatterIcon`
  }
  // console.log('calc', viz_icon)
  return viz_icon
}

export function clickItem(item) {
  // TODO: assign new key if explore form_data != chart.form_data
  console.log("open from chartUtils", item)
  this.$store.dispatch(GET_CHART_BY_ID, item.id).then((res) => {
    let params = JSON.parse(res.data.result.params);
    let _this = item.form_data_key;
    if (!_this) {
      _this = params.url_params.form_data_key
    }
    let key_form = {
      key: _this,
      data: {
        chart_id: item.id,
        dataset_id: item.datasource_id,
        form_data: JSON.stringify(params)
        },
      }; 
    this.$store.dispatch(SAVE_FORM_DATA, key_form);
    this.$router.push(`/visualizations/${item.id}/${_this}`);
  })
  }
// TODO create chart classes
export function parseFormDataToViz() {
  // Parse form data to visualiation page
  this.bubble_max_size = this.form_data.max_bubble_size;
  this.bubble_series = this.form_data.series;
  this.$store.commit("SELECTED_SERIES", this.bubble_series);
  this.bubble_entity = this.form_data.entity;
  this.$store.commit("SELECTED_ENTITY", this.bubble_entity);
  this.bubble_size = this.form_data.size;
  this.$store.commit("SELECTED_SIZE", this.bubble_size);
  this.bubble_x = this.form_data.x;
  this.x_col = this.bubble_x ? this.bubble_x.column : null;
  this.bubble_y = this.form_data.y;
  this.y_col = this.bubble_y ? this.bubble_y.column : null;
  this.$store.commit("SELECTED_X", this.x_col);
  this.$store.commit("SELECTED_Y", this.y_col);
  this.$store.commit(
    "SELECTED_X_LABEL",
    this.bubble_x ? this.bubble_x.label : ""
  );
  this.$store.commit(
    "SELECTED_Y_LABEL",
    this.bubble_y ? this.bubble_y.label : ""
  );
  this.$store.commit(
    "SELECTED_SIZE_LABEL",
    this.bubble_size ? this.bubble_size.label : ""
  );
}


// Chart objects
export class BaseViz {
  // required
  // database_name=""
  viz_type;
  // verbose_name = "Base Viz"
  is_timeseries = false
  cache_type = "df"
  enforce_numerical_metrics = true
  default_form_data_dict = {
    "url_params":{},
    "time_range_endpoints":["inclusive","exclusive"],
    "time_range":"No filter",
    "adhoc_filters":[],
    "extra_form_data":{},
    "force":false,
    "result_format": "json",
    "result_type": "full",
    "include_time": false,
  }

  //methods

  pushFilters(filter_list) {
    return 
  }

  constructor(
    datasource,
    form_data,
    // force: bool = False,
    // force_cached: bool = False,
  ) {
    // if not datasource:
        // raise QueryObjectValidationError(_("Viz is missing a datasource"))

    this.datasource = datasource;
    // this.request = request;
    this.viz_type = form_data.viz_type;
    this.form_data = Object.assign({}, this.default_form_data_dict, form_data)
    this.force = false

    this.query = ""
    // this.token = utils.get_form_data_token(form_data)

    this.groupby = this.form_data.groupby || [];
    // this.time_shift = timedelta()

    // this.status = null;
    // self.error_msg = ""
    // self.results: Optional[QueryResult] = None
    this.applied_template_filters = [];
    // self.errors: List[Dict[str, Any]] = []
    
    // this._force_cached = false
    // self.from_dttm: Optional[datetime] = None
    // self.to_dttm: Optional[datetime] = None
    // self._extra_chart_data: List[Tuple[str, pd.DataFrame]] = []

    // self.process_metrics()

    // self.applied_filters: List[Dict[str, str]] = []
    // self.rejected_filters: List[Dict[str, str]] = []
  }
}

export class TableViz extends BaseViz {

  //  Table definition for ag-grid
  viz_type = "table"
  verbose_name = "Table"
  is_timeseries = false
  enforce_numerical_metrics = false
  
  // TODO: Move some of these options to BaseViz and do dict concatenation
  // default_table_dict = default_viz_dict | {k:v}
  default_table_dict = {
      "time_grain_sqla":"P1D",
      "query_mode":"raw",
      "groupby":[],
      // "all_columns":["season"],
      "all_columns":[],
      "percent_metrics":[],
      "order_by_cols":[],
      "row_limit": 500000,
      "server_page_length":10,
      "order_desc":true,
      "table_timestamp_format":"smart_date",
      "show_cell_bars":false,
      "color_pn":true,
      }

  constructor(datasource, form_data) {
    super(datasource, form_data);
    this.form_data = Object.assign({}, form_data, this.default_table_dict)
    this.datasource = datasource;
  }
  
  // methods
  //////////
  parseVisData(data, form_data) {
    
    let dataObj = {};
    let colInfo = this.parseHeaders(data)
    let gridColumnState;
    // console.log('parseVisData', data.data)
    if (form_data.extra_form_data) {
      gridColumnState = form_data.extra_form_data
    }
    dataObj = {
      ...dataObj, 
      'row_data': data.data,
      'headers': colInfo[0],
      'cols': colInfo[1],
      'gridState': gridColumnState,
    }
    // console.log('parseVisData', form_data.extra_form_data)
    return dataObj
  }

  parseHeaders(col_array) {
    let cols=[], headers=[];
    for (let col of col_array.colnames) {
      cols.push({
        headerName: col,
        field: col,
        enableRowGroup: true,
      });
      headers.push({
        text: col,
        sortable: true,
        value: col
      })
    }
    return [headers, cols]
  }

  isValidForm(form_data) {
    // console.log('obj cols', form_data.all_columns.length > 0)
    return form_data.all_columns.length > 0 ? true : false;
  }
  parseColumns(form_data) {

    // console.log('tableParseCols')
    const all_cols = form_data.all_columns ? form_data.all_columns : [] 
    let activeCols = {'all_columns':[]};
    for (let item of all_cols) {
        const find = this.datasource.columns.find(
          (col) => col.column_name === item
        );
        activeCols.all_columns.push({
          column_name: find.column_name,
          type_generic: find.type_generic,
          applied: true,
        });
        // activeCols[item] = {
          // column_name: find.column_name,
          // applied: true,
        // }
    }
    // console.log('table activeCols', activeCols)
    return activeCols;
  }

}

export class BarChartViz extends BaseViz {

  //  Table definition for ag-grid
  viz_type = "dist_bar"
  verbose_name = "Bar Chart"
  is_timeseries = false
  enforce_numerical_metrics = false
  
  // TODO: Move some of these options to BaseViz and do dict concatenation
  // default_table_dict = default_viz_dict | {k:v}
  default_table_dict = {
      "time_grain_sqla":"P1D",
      "query_mode":"raw",
      "groupby":[],
      "columns":[],
      "metrics":[],
      "percent_metrics":[],
      "order_by_cols":[],
      "row_limit": 500000,
      "server_page_length":10,
      "order_desc":true,
      "table_timestamp_format":"smart_date",
      "show_cell_bars":false,
      "color_pn":true,
      }

  constructor(datasource, form_data) {
    super(datasource, form_data);
    this.form_data = Object.assign({}, form_data, this.default_table_dict)
    this.datasource = datasource;
    this.metrics = form_data.metrics ? form_data.metrics : null;
    this.series = form_data.series ? form_data.series : null;
    this.columns = form_data.columns ? form_data.columns : null;
    // this.requiredAxes = ['metrics', 'series', 'categories'];
    this.requiredAxes = ['bar_metrics', 'bar_series'];
  }
  
  // methods
  //////////
  parseVisData(data, form_data) {
    // console.log('parseVisData', data)
    let dataObj = {};
    let colInfo = this.parseHeaders(data)


    // convert keyed row data to table format

    let series=[];
    let row_data = data.data;
    let x_axis_labels = [];

    let bar_metrics = row_data.map((item) => item.key);
        // const xCategories = [...new Set(xlist)];
        // x_axis_labels = row_data.map(row => row.values.x)
        for (const key in bar_metrics) {
          // console.log('???????????????', bar_metrics[key])
          const row_values = row_data[key];
          const xlist = row_values.values.map(row => row.x)
          const ylist = row_values.values.map(row => row.y)
          series.push({
            name: bar_metrics[key],
            data: ylist
            });
          if (key === '0') {
            x_axis_labels = [...xlist];
          }
        }

    // console.log('convert//////////', series,'/////////')
    let table_data = [];
    // data.data.forEach(key => {
      // console.log('convert iter', key)
    // })

    // console.log('convert//////////', colInfo,'/////////')

    dataObj = {
      ...dataObj, 
      'vis_data': data.data,
      'headers': colInfo[0],
      'cols': colInfo[1],
      'row_data': [],//[{case: 1, site: 2}],
    }
    // console.log('parseVisData', dataObj)
    return dataObj
  }

  parseHeaders(col_array) {
    // console.log('parseHeaders///////', col_array)
    let cols=[], headers=[];
    for (let col of col_array.colnames) {
      cols.push({
        headerName: col,
        field: col,
        enableRowGroup: true,
      });
      headers.push({
        text: col,
        sortable: true,
        value: col
      })
    }
    return [headers, cols]
  }

  isValidForm(form_data) {
    
    // return form_data.all_columns.length > 0 ? true : false;
    const is_valid = (
      form_data.metrics.length > 0 && 
      !!form_data.groupby.length > 0
    );
    // console.log('obj cols', is_valid)
    return is_valid;
  }
  parseColumns(form_data) {
    // console.log('form_data input//////', form_data.metrics)
    let activeCols = {
      "groupby": [],
      "columns": [],
      "metrics": [],
    };
    
    // simple cols
    for (let item of ['groupby','columns']) {
      let form_col = form_data[item]
      for (let icol of form_col) {
        const find = this.datasource.columns.find(
          (col) => col.column_name === icol
        );
        if (find) {
          activeCols[item].push({
            column_name: find.column_name,
            type_generic: find.type_generic,
            applied: true,
          })
        }
      }
    }
    // Aggregates
    for (let item in form_data['metrics']) {
      // console.log('find', item, form_data['metrics'][item])
      if ( form_data['metrics'][item]?.column ) {
        let form_col = form_data['metrics'][item].column
        // console.log('find', form_col)
        const find = this.datasource.columns.find(
          (col) => col.column_name === form_col.column_name
        );
        if (find) {
          // console.log('find', find)
          activeCols['metrics'].push({
            column_name: find.column_name,
            applied: true,
          })
        }
      }
    }
    // console.log('cols/////////', activeCols)
    return activeCols;
  }

}

export class BoxPlotViz extends BaseViz {

  //  Table definition for ag-grid
  viz_type = "box_plot"
  verbose_name = "Box Plot"
  is_timeseries = false
  enforce_numerical_metrics = false
  
  // TODO: Move some of these options to BaseViz and do dict concatenation
  // default_table_dict = default_viz_dict | {k:v}
  default_table_dict = {
      "time_grain_sqla":"P1D",
      "query_mode":"raw",
      "groupby":[],
      "columns":[],
      "metrics":[],
      "percent_metrics":[],
      "order_by_cols":[],
      "row_limit": 500000,
      "server_page_length":10,
      "order_desc":true,
      "table_timestamp_format":"smart_date",
      "show_cell_bars":false,
      "color_pn":true,
      }

  constructor(datasource, form_data) {
    super(datasource, form_data);
    this.form_data = Object.assign({}, form_data, this.default_table_dict)
    this.datasource = datasource;
    this.metrics = form_data.metrics ? form_data.metrics : null;
    this.series = form_data.series ? form_data.series : null;
    this.columns = form_data.columns ? form_data.columns : null;
    // this.requiredAxes = ['metrics', 'series', 'categories'];
    this.requiredAxes = ['bar_metrics'];
  }
  
  // methods
  //////////
  parseVisData(data, form_data) {
    // console.log('parseVisData', data)
    let dataObj = {};
    let colInfo = this.parseHeaders(data)


    // convert keyed row data to table format
    // console.log('convert//////////', data.data,'/////////')
    // let table_data = [];
    data.data.forEach(key => {
      // console.log('convert iter', key)
    })

    // console.log('convert//////////', colInfo,'/////////')

    dataObj = {
      ...dataObj, 
      'vis_data': data.data,
      'headers': colInfo[0],
      'cols': colInfo[1],
    }
    console.log('parseVisData', dataObj)
    return dataObj
  }

  parseHeaders(col_array) {
    // console.log('parseHeaders///////', col_array)
    let cols=[], headers=[];
    for (let col of col_array.colnames) {
      cols.push({
        headerName: col,
        field: col,
        enableRowGroup: true,
      });
      headers.push({
        text: col,
        sortable: true,
        value: col
      })
    }
    return [headers, cols]
  }

  isValidForm(form_data) {
    
    // return form_data.all_columns.length > 0 ? true : false;
    const is_valid = (
      form_data.metrics && form_data.metrics.length > 0 && 
      !!form_data.groupby.length > 0
    );
    return is_valid;
  }
  parseColumns(form_data) {
    // console.log('form_data input//////', form_data)
    let activeCols = {
      "groupby": [],
      "columns": [],
      "metrics": [],
    };
    
    // simple cols
    for (let item of ['groupby','columns']) {
      let form_col = form_data[item] ? form_data[item] : []
      for (let icol of form_col) {
        const find = this.datasource.columns.find(
          (col) => col.column_name === icol
        );
        if (find) {
          activeCols[item].push({
            column_name: find.column_name,
            type_generic: find.type_generic,
            applied: true,
          })
        }
      }
    }
    // Aggregates
    // console.log('form_data', form_data.metrics)
    if (form_data['metrics']) {
      for (let item in form_data['metrics']) {
        // console.log('parse metrics', item)
        if ( form_data['metrics'][item]?.column ) {
          let form_col = form_data['metrics'][item].column
          // console.log('find', form_col)
          const find = this.datasource.columns.find(
            (col) => col.column_name === form_col.column_name
          );
          if (find) {
            console.log('find', find)
            activeCols['metrics'].push({
              column_name: find.column_name,
              applied: true,
            })
          }
        }
      }
    }
    // console.log('cols/////////', activeCols)
    return activeCols;
  }

}

export class PieChartViz extends BaseViz {

  //  Table definition for ag-grid
  viz_type = "pie"
  verbose_name = "Pie Chart"
  is_timeseries = false
  enforce_numerical_metrics = false

  constructor(datasource, form_data) {
    super(datasource, form_data);
    // this.form_data = Object.assign({}, form_data, this.default_table_dict)
    this.form_data = form_data
    this.datasource = datasource;
    this.requiredAxes = ['sel_pie_metric'];
  }
  // methods
  //////////
  parseVisData(data) {
    let dataObj = {};
    let colInfo = this.parseHeaders(data)

    dataObj = {
      ...dataObj, 
      'row_data': data.data,
      'headers': colInfo[0],
      'cols': colInfo[1],
    }
    return dataObj
  }

  isValidForm(form_data) {
    // console.log('obj cols1', !!form_data.metric)
    let is_valid = (
      // form_data.all_columns.length > 0 && 
      !!form_data.metric
    );
    // console.log('obj cols', is_valid)
    return is_valid;
  }

  parseColumns(form_data) {
    // console.log('form_data input//////', form_data)
    let activeCols = {
      "all_columns": [],
      "metric": [],
    };
    
    // simple cols
    for (let item of ['all_columns']) {
      let form_col = form_data[item] ? form_data[item] : []
      for (let icol of form_col) {
        const find = this.datasource.columns.find(
          (col) => col.column_name === icol
        );
        if (find) {
          activeCols[item].push({
            column_name: find.column_name,
            type_generic: find.type_generic,
            applied: true,
          })
        }
      }
    }
    // Aggregates
    // console.log('form_data', form_data.metric)
    if (form_data['metric']) {
      if ( form_data['metric']?.column ) {
        let form_col = form_data['metric'].column
        // console.log('find', form_col)
        const find = this.datasource.columns.find(
          (col) => col.column_name === form_col.column_name
        );
        if (find) {
          // console.log('find', find)
          activeCols['metric'].push({
            column_name: find.column_name,
            applied: true,
          })
        }
      }
    }
    // console.log('cols/////////', activeCols)
    return activeCols;
  }

  parseHeaders(col_array) {
    let cols=[], headers=[];
    for (let col of col_array.colnames) {
      cols.push({
        headerName: col,
        field: col,
        enableRowGroup: true,
      });
      headers.push({
        text: col,
        sortable: true,
        value: col
      })
    }
    return [headers, cols]
  }
}

/// Copy Bubble chart then make Bubble chart extend ScatterPlot
export class ScatterPlotViz extends BaseViz {

  //  Table definition for ag-grid
  viz_type = "scatter"
  verbose_name = "Scatter Plot"
  is_timeseries = false
  enforce_numerical_metrics = false

  constructor(datasource, form_data) {
    super(datasource, form_data);
    this.form_data = form_data
    this.datasource = datasource;
    this.entity = form_data.entity ? form_data.entity : null;
    this.x = form_data.x ? form_data.x : null;
    this.y = form_data.y ? form_data.y : null;
    this.series = form_data.series ? form_data.series : null;
    this.requiredAxes = ['xAxis', 'yAxis', 'entity'];
  }
  // methods
  //////////

  parseHeaders(col_array) {
    let cols=[], headers=[];
    for (let col of col_array.colnames) {
      headers.push({
        text: col,
        sortable: true,
        value: col
      })
    }
    return headers
  }
  parseVisData(data, form_data) {
    
    const colInfo = this.parseHeaders(data)
    // console.log('colInfo////////', colInfo)

    const dataObj = {
      'row_data': data.data,
      'columns': data.colnames,
      'coltypes': data.coltypes,
      'headers': colInfo,
    }
    return dataObj
  }

  isValidForm() {
    let is_valid = (
      !! this.entity &&
      !! this.x &&
      !! this.y
    //   // !!this.series // not required
    );
    return is_valid;
  }

  parseColumns(form_data, columns) {
    // Parse columns from form to populate active columns
    // console.log('parse active cols: scatter', form_data)
    let activeCols = {};
    
    // simple cols
    for (let item of ['entity', 'series']) {
      let form_col = form_data[item]
      const find = this.datasource.columns.find(
        (col) => col.column_name === form_col
      );
      if (find) {
        activeCols[item] = {
          column_name: find.column_name,
          type_generic: find.type_generic,
          applied: true,
        }
      }
    }
    // Aggregates
    for (let item of ['x', 'y']) {
      if ( form_data[item]?.column ) {
        let form_col = form_data[item].column
        const find = this.datasource.columns.find(
          (col) => col.column_name === form_col.column_name
        );
        if (find) {
          activeCols[item] = {
            column_name: find.column_name,
            applied: true,
          }
        }
      }
    }
    // console.log('cols', activeCols)
    return activeCols;
  }

}

export class BubbleChartViz extends BaseViz {

  //  Table definition for ag-grid
  viz_type = "bubble"
  verbose_name = "Bubble Chart"
  is_timeseries = false
  enforce_numerical_metrics = false

  constructor(datasource, form_data) {
    super(datasource, form_data);
    this.form_data = form_data
    this.datasource = datasource;
    this.entity = form_data.entity ? form_data.entity : null;
    this.x = form_data.x ? form_data.x : null;
    this.y = form_data.y ? form_data.y : null;
    this.size = form_data.size ? form_data.size : null;
    this.series = form_data.series ? form_data.series : null;
    this.requiredAxes = ['xAxis', 'yAxis', 'entity','size'];
  }
  // methods
  //////////

  parseHeaders(col_array) {
    let cols=[], headers=[];
    for (let col of col_array.colnames) {
      headers.push({
        text: col,
        sortable: true,
        value: col
      })
    }
    return headers
  }
  parseVisData(data, form_data) {
    
    const colInfo = this.parseHeaders(data)
    // console.log('colInfo////////', colInfo)

    const dataObj = {
      'row_data': data.data,
      'columns': data.colnames,
      'coltypes': data.coltypes,
      'headers': colInfo,
    }
    return dataObj
  }

  isValidForm(form_data) {
    let is_valid = (
      !! this.entity &&
      !! this.x &&
      !! this.y &&
      !!this.size 
    //   // !!this.series // not required
    );
    // console.log('obj form valid?', is_valid)
    return is_valid;
  }

  parseColumns(form_data, columns) {
    // Parse columns from form to populate active columns
    console.log('parse active cols: bubble', form_data)
    let activeCols = {};
    
    // simple cols
    for (let item of ['entity', 'series']) {
      let form_col = form_data[item]
      const find = this.datasource.columns.find(
        (col) => col.column_name === form_col
      );
      if (find) {
        activeCols[item] = {
          column_name: find.column_name,
          type_generic: find.type_generic,
          applied: true,
        }
      }
    }
    // Aggregates
    for (let item of ['x', 'y', 'size']) {
      if ( form_data[item]?.column ) {
        let form_col = form_data[item].column
        const find = this.datasource.columns.find(
          (col) => col.column_name === form_col.column_name
        );
        if (find) {
          activeCols[item] = {
            column_name: find.column_name,
            applied: true,
          }
        }
      }
    }
    // console.log('cols', activeCols)
    return activeCols;
  }

}

export class HeatmapViz extends BaseViz {

  //  Table definition for ag-grid
  viz_type = "heatmap"
  verbose_name = "Heatmap"
  is_timeseries = false
  enforce_numerical_metrics = false
  
  // TODO: Move some of these options to BaseViz and do dict concatenation
  // default_table_dict = default_viz_dict | {k:v}
  default_table_dict = {
      "time_grain_sqla":"P1D",
      "query_mode":"raw",
      "all_columns_x":"",
      "all_columns_y":"",
      "metric":{},
      // "percent_metrics":[],
      // "order_by_cols":[],
      "row_limit": 500000,
      // "server_page_length":10,
      // "order_desc":true,
      // "table_timestamp_format":"smart_date",
      // "show_cell_bars":false,
      // "color_pn":true,
      }

  constructor(datasource, form_data) {
    super(datasource, form_data);
    this.form_data = Object.assign({}, form_data, this.default_table_dict)
    this.datasource = datasource;
    this.metric = form_data.metric ? form_data.metric : null;
    this.all_columns_x = form_data.all_columns_x ? form_data.all_columns_x : null;
    this.all_columns_y = form_data.all_columns_y ? form_data.all_columns_y : null;
    // this.requiredAxes = ['metrics', 'series', 'categories'];
    this.requiredAxes = ['all_columns_x','all_columns_y','metric'];
  }
  
  // methods
  //////////
  parseVisData(data, form_data) {
    console.log('parseVisData', data)
    let dataObj = {};
    let colInfo = this.parseHeaders(data)


    // convert keyed row data to table format
    // console.log('convert//////////', data.data,'/////////')
    // let table_data = [];
    // data.data.forEach(key => {
      // console.log('convert iter', key)
    // })

    // console.log('convert//////////', colInfo,'/////////')

    dataObj = {
      ...dataObj, 
      'vis_data': data.data.records,
      'headers': colInfo[0],
      'cols': colInfo[1],
    }
    console.log('parseVisData', dataObj)
    return dataObj
  }

  parseHeaders(col_array) {
    // console.log('parseHeaders///////', col_array)
    let cols=[], headers=[];
    for (let col of col_array.colnames) {
      cols.push({
        headerName: col,
        field: col,
        enableRowGroup: true,
      });
      headers.push({
        text: col,
        sortable: true,
        value: col
      })
    }
    return [headers, cols]
  }

  isValidForm(form_data) {
    
    // return form_data.all_columns.length > 0 ? true : false;
    const is_valid = (
      !!form_data.metric && 
      !!form_data.all_columns_x &&
      !!form_data.all_columns_y
    );
    // console.log('asdfasfasdfasd/////', is_valid)
    return is_valid;
  }

  parseColumns(form_data, columns) {
    // Parse columns from form to populate active columns
    // console.log('parse active cols: scatter', form_data)
    let activeCols = {};
    
    // simple cols
    for (let item of ['all_columns_x', 'all_columns_y']) {
      let form_col = form_data[item]
      const find = this.datasource.columns.find(
        (col) => col.column_name === form_col
      );
      if (find) {
        activeCols[item] = {
          column_name: find.column_name,
          type_generic: find.type_generic,
          applied: true,
        }
      }
    }
    // Aggregates
    for (let item of ['metric']) {
      if ( form_data[item]?.column ) {
        let form_col = form_data[item].column
        const find = this.datasource.columns.find(
          (col) => col.column_name === form_col.column_name
        );
        if (find) {
          activeCols[item] = {
            column_name: find.column_name,
            applied: true,
          }
        }
      }
    }
    // console.log('cols', activeCols)
    return activeCols;
  }

}