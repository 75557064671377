<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height="18"
    viewBox="0 0 36 36"
    width="18"
    aria-hidden="false"
    role="img"
    :fill="fill"
    aria-label="title"
  >
    <path
      d="M13 6h-3V.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5V6H5a1 1 0 0 0-1 1v22a1 1 0 0 0 1 1h3v5.5a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5V30h3a1 1 0 0 0 1-1V7a1 1 0 0 0-1-1ZM31 10h-3V.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5V10h-3a1 1 0 0 0-1 1v14a1 1 0 0 0 1 1h3v9.5a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5V26h3a1 1 0 0 0 1-1V11a1 1 0 0 0-1-1Z"
    />
  </svg>
</template>



<script>
export default {
  name: "boxplotIcon",
  props: {
    fill: {
      type: String,
      default() {
        return "#B9B9B9";
      },
    },
  },
};
</script>

<style>
.fill {
  fill: #B9B9B9;
  /* fill: blue; */
}
</style>