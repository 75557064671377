<template>
  <div>
    {{ validVisCols }}
    <div class="xAxis">
      <div class="xAxis-header d-flex">
        <div class="xAxis-header-title">X Axis</div>
        <AxisInfoTooltip 
          :validCol="!!xAxis"
          :requiredCol="true"
          :message="['Columns across X-Axis']"
          displayType="Categorical"
        />      
      </div>
      <div
        class="xAxis-container"
        ref="xAxisCon"
        @dragover.prevent
        @drop="$emit('dragPillFinish','all_columns_x')"
      >
        <VizModePill v-if="xAxis"
            :column="getSimpleCol('all_columns_x')"
            @deleteCol="$emit('deletePill', 'all_columns_x')"
          />
      </div>
    </div>
    <div class="yAxis">
      <div class="yAxis-header d-flex">
        <div class="yAxis-header-title">Y Axis</div>
        <AxisInfoTooltip 
          :validCol="!!yAxis"
          :requiredCol="true"
          :message="['Columns up the Y-Axis']"
          displayType="Categorical"
        />      
      </div>
      <div
        class="yAxis-container"
        ref="yAxisCon"
        @dragover.prevent
        @drop="$emit('dragPillFinish','all_columns_y')"
      >
        <VizModePill v-if="yAxis"
            :column="getSimpleCol('all_columns_y')"
            @deleteCol="$emit('deletePill', 'all_columns_y')"
          />
      </div>
    </div>
    <div class="metric">
      <div class="metric-header d-flex">
        <div class="metric-header-title">Metrics</div>
        <v-tooltip right>
          <template v-slot:activator="{ on, attrs }">
            <v-icon 
              small
              v-bind="attrs" 
              v-on="on"
              :color="validVisCols.metric ? '#B9B9B9' : '#ffbb33'"
              class="ml-auto"
            >
              mdi-information-outline
            </v-icon>
          </template>
            <span style="color:#ffbb33"> 
              Required<br>
            </span>
          <span>
            Metric to show in heatmap cells<br/>
            <i>Numerical/Calculation</i><br/>
          </span>
        </v-tooltip>
      </div>
      <div
        class="metric-container"
        @dragover.prevent
        @drop="$emit('dragComputedAxisFinish', 'metric')"
      >
        <!-- pill/calc -->
        <template v-if="Object.keys(metric).length >0">
          <div 
          class="d-flex"
          v-if="Object.keys(metric).length >0"
          :class="[showCalc.x ? 'size-menu': 'size-item']"
        >
          <!-- Pill and Calculation menu card -->
          <v-menu
            attach
            :value="showCalc.metric"
            offset-x
            nudge-right="22px"
            nudge-top="153"
            :close-on-content-click="false"
            height="0"
            flat="true"
            :close-on-click="false"
          >    
          <template v-slot:activator="{ on }">
            <v-btn 
              v-on="on"
              class="mb-08"
              :outlined="!isApplied('metric')"
              :color=" metric.column.type_generic ? '#1bbab9' : '#00A7FF' "
              rounded
              width="100%"
              height="24px"
              v-if="metric"
              @click.stop="on.click"
              @contextmenu.prevent="contextClickPill('metric')"
            >
              {{ getComputedCol('metric').column_name }}
              <v-icon 
                class="ml-auto" right
                @click.prevent
                @click="removePillX('metric')" 
              >
                mdi-close-circle
              </v-icon>
            </v-btn>
          </template>
            <CalculationCard
              v-if="showCalc.metric"
              :calcStyle="'Calculation'"
              :form_data="form_data"
              :aggregations="getAggType(metric)"
              :size="metric"
              @disableCalcCard="disableSimpleCalc"
              @saveSimpleCalcSize="saveSimpleCalc"
            />
          </v-menu>
        </div>
          <!-- <v-btn
            class="mb-08"
            color="#00A7FF"
            rounded
            width="100%"
            height="24px"
            @contextmenu.prevent="
              $emit('showHeatMetric', $event, metric)
            "
          >
            {{ metric.label }}
          </v-btn> -->
        </template>
        <template v-else>
          <div class="blank-drag">Drag here</div>
          <p class="blank-text">
            Drag here the columns you want to use as metrics
          </p>
        </template>
      </div>
    </div>
  </div>
</template>

<script>

import AxisInfoTooltip from "./components/AxisInfoTooltip.vue"
import VizModePill from "./components/VizModePill.vue"  
import CalculationCard from "./components/CalculationCard.vue";

export default {
  components:{
    AxisInfoTooltip,
    VizModePill,
    CalculationCard,
  },
  props: {
    xAxis: {
      type: String,
      default() {
        return "";
      }
    },
    yAxis: {
      type: String,
      default() {
        return "";
      }
    },
    metric: {
      type: Object,
      default() {
        return null;
      }
    },
    mode_active_cols: {
      type: Object,
      default() {
        return {};
      },
    },
    validVisCols: {
      type: Object,
      default() {
        return null;
      },
    },
    aggregations: {
      type: Object,
      default() {
        return null;
      },
    },
    showCalc: {
      type: Object,
      default() {
        return null;
      },
    },
    form_data: {
      type: Object,
      default() {
        return null;
      },
    },
  },
  methods: {
    disableSimpleCalc() {
      this.$emit('disableCalc', 'metric')
    },
    saveSimpleCalc(calc) {
      // console.log('saveme bubble', calc)
      // this.menuBool = false;
      this.$emit('saveCalc', calc, 'metric')
    },

    isApplied(axis) {
      return this.mode_active_cols[axis]?.applied;
    },
    getSimpleCol(val) {
      console.log('getsimple', val, this.isApplied(val))
      const this_col = this.mode_active_cols[val]
      // console.log('getsimple2', this_col)
      return {
        // column_name: this.form_data[val],
        column_name: this_col.column_name,
        type_generic: this_col.type_generic,
        // applied: false,
        applied: this.isApplied(val),
        }
    },
    getAggType(col) {
      // console.log('get agg type', col)
      if ( col.column.type_generic === 1 ) {
        return this.aggregations.categorical;
      } else {
        return this.aggregations.numeric;
      }
    },
    getComputedCol(val) {
      // console.log('computeds', val, this[val].column)
      return {
        column_name: (
          this[val].column ?
            this[val].aggregate ? `(${this[val].aggregate})${this[val].column.column_name}` : 
            this[val].column.column_name : 'error?'
        ),
        type_generic: this[val].column.type_generic,
        applied: true,
      }
    },
    removePillX(axis) {
      this.$emit('deletePill', axis)
    },
  }
};
</script>

<style lang="scss" scoped>
.blank-drag {
  border: 1px dashed #909090;
  border-radius: 12px;
  text-align: center;
  color: #909090;
  padding-top: 4px;
  padding-bottom: 4px;
  font-size: 12px;
}

.blank-text {
  font-size: 12px;
  text-align: center;
  color: #909090;
  margin-top: 9px;
}

.metric {
  &-header {
    padding: 8px 15px;
  &-title {
      font-size: 12px;
    }
  }
  &-container {
    overflow-y: auto;
    @include scrollbars(0.4rem, #717171, #00000080);
    border-bottom: 0.5px solid #373737;
    height: 90px;
    padding-left: 15px;
    padding-right: 15px;
  }
}

.xAxis {
  &-header {
    padding: 8px 15px;

    &-title {
      font-size: 12px;
    }
  }

  &-container {
    overflow-y: auto;
    @include scrollbars(0.4rem, #717171, #00000080);
    border-bottom: 0.5px solid #373737;
    height: 40px;
    padding-left: 15px;
    padding-right: 15px;
  }
}

.yAxis {
  &-header {
    padding: 8px 15px;

    &-title {
      font-size: 12px;
    }
  }

  &-container {
    overflow-y: auto;
    @include scrollbars(0.4rem, #717171, #00000080);
    border-bottom: 0.5px solid #373737;
    height: 40px;
    padding-left: 15px;
    padding-right: 15px;
  }
}

.categories {
  &-header {
    padding: 8px 15px;

    &-title {
      font-size: 12px;
    }
  }

  &-container {
    overflow-y: auto;
    @include scrollbars(0.4rem, #717171, #00000080);
    border-bottom: 0.5px solid #373737;
    height: 40px;
    padding-left: 15px;
    padding-right: 15px;
  }

  &-color {
    position: absolute;
    z-index: 11;
    left: 40px;
  }
}

.index {
  &-header {
    padding: 8px 15px;

    &-title {
      font-size: 12px;
    }
  }

  &-container {
    overflow-y: auto;
    @include scrollbars(0.4rem, #717171, #00000080);
    border-bottom: 0.5px solid #373737;
    height: 40px;
    padding-left: 15px;
    padding-right: 15px;
  }
}

.size {
  &-header {
    padding: 8px 15px;

    &-title {
      font-size: 12px;
    }
  }

  &-container {
    overflow-y: auto;
    @include scrollbars(0.4rem, #717171, #00000080);

    padding-left: 15px;
    padding-right: 15px;
  }

    &-menu::after {
    animation: fade .2s linear;
    position: absolute;
    content: "";
    // top: 9px;
    z-index: 10;
    left: 230px;  
    border-top: 12.5px solid transparent;
    border-bottom: 12.5px solid transparent;  
    border-left: 16px solid #303030;
    transform: rotate(180deg);  
  }

}

// Animate the tooltips to match v-menu
@keyframes fade {
  0% { opacity: 0 }
  100% { opacity: 1 }
}
</style>