<template>
  <div
    class="d-flex flex-column fill-height flex-grow-1 pt-6"
  >
    <!-- <v-btn
      @click="saveFile"
    >Blah</v-btn> -->
    <NewDB :showNewDB="showNewDB" @cancel="cancelNewDB" />
    <NewFromDB
      :showNewFromDB="showNewFromDB"
      @cancel="cancelNewFromDB"
      @createDB="showCreateDB"
      @goToDatasets="goToDatasets"
    />
    <NewFromFile
      :showNewFromFile="showNewFromFile"
      @cancel="cancelNewFromFile"
      @showAdvance="showAdvance"
      @save_file="saveFile"
    />
    <NewFromFileAdvance
      :showNewFromFileAdvance="showNewFromFileAdvance"
      @cancel="cancelNewAdvance"
      @save="saveNewAdvance"
    />
    <ModuleHeader
      title="Datasets"
      :disabled-grid-btn="true"
      :disabled-list-btn="true"
      :view-grid="false"
      @updateViewFavOnly="viewFavOnly = !viewFavOnly"
    />
    <ModuleAction
      title="Dataset"
      search-label="Search Datasets"
      :selectedItems="selectedItemIds"
      :menuItems="menuItems"
      :n_items="filteredItemCount"
      @menuEvent="menuEvent"
      @deleteAll="deleteAll"
      @selectItemsEvent="selectItemsEvent"
      @moduleFilterChanged="moduleFilterChanged"
    />

    <div class="data-table d-flex
        flex-grow-1 flex-column flex-basis-0
        fill-height overflow-x-auto
      "
    >
      <v-container>
        <v-row v-if="loading" class="container-row-resolution py-5 d-flex justify-center">
          <v-progress-circular
            indeterminate
            color="primary"
          ></v-progress-circular>
        </v-row>
        <v-data-table
          id="tblItems"
          v-else-if="getModels(collection, viewFavOnly).length"
          v-model="selectedItems"
          fixed-header
          :items="getModels(collection, viewFavOnly)"
          :items-per-page="$pageItemCount"
          :headers="headers"
          :search="search"
          show-select
          item-key="id"
          class="mt-3"
          :hide-default-footer="true"
          :header-props="{ sortIcon: 'mdi-chevron-down' }"
        >
          <template v-slot:top="{ pagination, options, updateOptions }">
            <v-data-footer
              :pagination="pagination"
              :options="options"
              @update:options="updateOptions"
              items-per-page-text="$vuetify.dataTable.itemsPerPageText"
            />
          </template>
          <template v-slot:[`header.favorite`]="{ header }">
            <v-icon v-if="header.favorite">mdi-star</v-icon>
            <v-icon v-else>mdi-star-outline</v-icon>
          </template>
          <template #[`item.favorite`]="{ item }">
            <div @click.stop="toggleFav(item)" class="actions">
              <v-btn icon width="24" v-tippy content="Favorite">
                <v-icon v-if="item.favorite">mdi-star</v-icon>
                <v-icon v-else>mdi-star-outline</v-icon>
              </v-btn>
            </div>
          </template>
          <template #[`item.table_name`]="{ item }">
            <div @click.stop class="title">
              <a @click="openDatasetViz(item)">{{ item.table_name }}</a>
            </div>
          </template>
          <template #[`item.changed_by.username`]="{ item }">
            <div v-if="item.created_by" class="actions">
              {{ item.created_by.username }}
            </div>
          </template>
          <template #[`item.database.database_name`]="{ item }">
            <div v-if="item.database" class="actions">
              <v-chip color="primary">{{ item.database.database_name }}</v-chip>
            </div>
          </template>
          <template #[`item.action`]="{ item }">
            <RowAction
              :item="item"
              :disabledList="disabledRowActions"
              
              @editItem="editItem"
              @deleteItem="deleteItem"
              @infoItem="infoItem"
            />
          </template>
        </v-data-table>

        <v-row v-else class="container-row-resolution pt-5 d-flex justify-center">
          <p>No Datasets Found ...</p>
        </v-row>
      </v-container>
    </div>
    <SnackbarMessage v-if="showSnackbar"
      :showSnackbar="showSnackbar"
      :snackbarError="snackbarError"
      :snackbarMsg="snackbarMsg"
      :color="snackbarColor"
      @closeSnackbar="closeSnackbar"
    />
  </div>
</template>

<script>
import NewDB from "@/components/modal/NewDB.vue";
import NewFromDB from "@/components/modal/NewFromDB.vue";
import NewFromFile from "@/components/modal/NewFromFile.vue";
import NewFromFileAdvance from "@/components/modal/NewFromFileAdvance.vue";
import RowAction from "@/views/_partials/RowAction";
import ModuleHeader from "@/views/_partials/ModuleHeader";
import ModuleAction from "@/views/_partials/ModuleAction";
import SnackbarMessage from "@/views/_partials/snackbarMessage.vue";

import {
  GET_DATASET_ALL,
  DELETE_DATASET,
  DELETE_DATASET_MULTI,
  SET_FAV,
  UNSET_FAV,
  UPLOAD_FILE,
} from "@/store/actionType";

import { 
    filteredObjs,
  } from "@/utils/moduleUtils.js";

import { openDatasetViz } from "./utils"

export default {
  name: "DatasetsIndex",
  components: {
    NewDB,
    NewFromDB,
    NewFromFile,
    NewFromFileAdvance,
    ModuleHeader,
    ModuleAction,
    RowAction,
    SnackbarMessage,
  },
  data: () => ({
    showSnackbar: false,
    snackbarColor: null,
    snackbarError: "",
    snackbarMsg: "",
    moduleFilter:'',
    curSortField: "changed_on_utc",
    viewFavOnly: false,
    selectedItems: [],
    search: "",
    headers: [
      { text: "Favorite", value: "favorite", width: 75, sortable: false },
      { text: "Name", value: "table_name" },
      { text: "Type", value: "datasource_type" },
      { text: "Database", value: "database.database_name" },
      { text: "Schema", value: "schema" },
      { text: "Owners", value: "owners" },
      { text: "Modified By", value: "changed_by.username" },
      { text: "Last Modified", value: "changed_on_delta_humanized" },
      { text: "Actions", value: "action", width: 200, sortable: false },
    ],
    collection: [], // use one variable for Recent + Favorites for consistency,
    loading: false,
    menuItems: [
      { text: "From Database", action: "from_db" },
      { text: "From File", action: "from_file" },
      { text: "Custom SQL", action: "custom_sql" },
      { text: "New Database", action: "new_db" },
    ],
    disabledRowActions: {
      'edit':true, 
      'info':true, 
      'trashcan':false, 
      'action': true
    },
    //datasets
    datasets: [],
    //selected all
    selected_all: false,
    //checkbox indeterminate
    indeterminate: false,
    //show New database dialog
    showNewDB: false,
    //show New from database dialog
    showNewFromDB: false,
    //show New from file dialog
    showNewFromFile: false,
    //show New from file advance dialog
    showNewFromFileAdvance: false,
    //advance file object
    advance_file: null,
  }),
  mounted() {
    // this.getDatasets();
    this.load();
  },
  computed: {
    filteredItemCount () {
      return [ 
        this.collection.length, // count of items
        // filtered items
        this.getModels(this.collection, this.viewFavOnly).length
      ]
    },
    selectedItemIds () {
      let id_list = this.selectedItems.map(a => a.id);
      return id_list;
    },
    // showCheckedItems () {
    //   let blah = this.selectedItems;
    //   return true
    // },
  },
  methods: {
    // imports - moduleUtils
    openDatasetViz,
    filteredObjs,
    //////////
    moduleFilterChanged (input) {
      this.moduleFilter = input;
    },
    getRecentModels(arr) {
      const filteredArr = arr.filter(function (item) {
        return item.recent === true;
      });
      return filteredArr;
    },
    getFavModels(arr) {
      return arr.filter(function (item) {
        return item.favorite === true;
      });
    },
    getModels(arr, viewFavOnly) {
      // console.log('filter', arr)
      if (arr.length > 0 && this.moduleFilter.length > 0) {
        arr = this.filteredObjs(arr, 'table_name', this.moduleFilter)
      }
      return viewFavOnly ? this.getFavModels(arr) : this.getRecentModels(arr);
    },
    load() {
      const columns = [
        "id",
        "changed_by.username",
        "created_by.first_name",
        "created_by.last_name",
        "created_by.username",
        "changed_on_utc",
        "changed_on_delta_humanized",
        "table_name",
        "datasource_type",
        "database.database_name",
        "schema",
        "owners",
      ];

      const q = {
        columns: columns,
        order_column: this.curSortField,
        order_direction: "desc",
      };

      const favQ = {
        ...q,
        filters: [
          {
            col: "id",
            opr: "dataset_is_favorite",
            value: "!t",
          },
        ],
      };

      // Load Recent Dataset
      let recentArr = [];
      this.loading = true
      this.$store
        .dispatch(GET_DATASET_ALL, q)
        .then((r) => {
          recentArr = r.data.result;
          // Load Favorite Dataset
          return this.$store.dispatch(GET_DATASET_ALL, favQ);
        })
        .then((favResult) => {
          this.collection = this.mergeFavItems(recentArr, favResult);
        })
        .catch((r) => {
          console.error("We have error:", r);
        })
        .finally(() => {
          this.loading = false
        })
    },

    // openDatasetViz(item) {
    //   console.log('open me!', item)
    //   let params = {
    //     datasource: item.id,
    //     viz_type: 'table'
    //   };
      
    //   this.loading = true;
    //   this.$store.dispatch(GET_DATASET_BY_ID, item.id)
    //     .then( (res) => {
    //       let result = res.data.result
    //       let all_cols = result.columns.map(a => a.column_name)
    //       params.all_columns=all_cols;
    //       // Post Chart by form
    //       console.log('open sesame!', params)
    //       this.$store.dispatch(POST_CHART_FORM, params).then((res) => {
    //         this.$router.push(`/visualizations/null/${res.data.key}`);
    //         this.loading = false;
    //         params={};

    //   //       setTimeout(() => {
    //   //         // this.$store.commit("SELECTED_PIE_METRIC", null);
    //   //         // this.$store.commit("SELECTED_HEAT_METRIC", null);
    //   //         this.$router.push(`/visualizations/null/${res.data.key}`);
    //   //       }, 1000);
    //   //       // this.loading = false;
    //         // params={};
    //       })
    //       .catch((error) => {
    //         console.error('error in chart form post', error)
    //       })
    //     })
    //     .catch((error) => {
    //         console.error('error in dataset get by id', error)
    //       });
    // },

    mergeFavItems(recentArr, favResult) {
      let favIds = favResult.data.ids;

      for (let index in favResult.data.result) {
        favResult.data.result[index]["favorite"] = true;
      }
      for (let index in recentArr) {
        const _index = favIds.indexOf(recentArr[index].id);
        if (_index === -1) {
          recentArr[index]["favorite"] == false;
          recentArr[index]["recent"] = true;
          favResult.data.result.push(recentArr[index]);
        } else {
          favResult.data.result[_index]["recent"] = true;
        }
      }
      return favResult.data.result;
    },

    toggleFav(item) {
      const param = {
        id: item.id,
        className: "dataset",
      };

      let collection = this.collection;
      let itemIndex = collection.indexOf(item);

      if (item.favorite === true) {
        this.$store
          .dispatch(UNSET_FAV, param)
          .then((r) => {
            item.favorite = false;
            let editedItem = { ...item };
            collection.splice(itemIndex, 1, editedItem);
          })
          .catch((r) => {
            console.error("We have error: ", r);
          });
      } else {
        this.$store
          .dispatch(SET_FAV, param)
          .then((r) => {
            item.favorite = true;
            let editedItem = { ...item };
            collection.splice(itemIndex, 1, editedItem);
          })
          .catch((r) => {
            console.error("We have error: ", r);
          });
      }
    },
    /**
     * get datasets
     */
    // getDatasets() {
    //   this.$store.dispatch(GET_DATASET_ALL).then((res) => {
    //     this.initialize(res.data.result);
    //   });
    // },
    // /**
    //  * initialize database values
    //  * @param values
    //  */
    // initialize(values) {
    //   this.datasets = [];
    //   for (let item of values) {
    //     this.datasets.push({
    //       selected: false,
    //       name: item.table_name,
    //       type: item.datasource_type,
    //       database: item.database.database_name,
    //       schema: item.schema,
    //       owners: item.owners,
    //       last_modified: item.changed_on_delta_humanized,
    //     });
    //   }
    // },
    /**
     * close new from DB and show new DB
     */
    showCreateDB() {
      this.showNewFromDB = false;
      this.showNewDB = true;
    },
    /**
     * go to datasets page
     */
    goToDatasets() {
      this.showNewFromDB = false;
      this.$router.push("/datasets");
    },
    /**
     * menu event
     * @param item
     */
    menuEvent(item) {
      switch (item.action) {
        case "custom_sql":
          this.$router.push("/sql-page");
          break;
        case "from_db":
          this.showNewFromDB = true;
          break;
        case "from_file":
          this.showNewFromFile = true;
          break;
        case "new_db":
          this.showNewDB = true;
          break;
      }
    },
    selectItemsEvent(item) {
      switch (item.action) {
        case "select_all":
          this.selectedItems=[...this.collection]
          break;
        case "unselect_all":
          this.selectedItems = []
          break;
      }
    },
    /**
     * select all datasets
     */
    selectAll(event) {
      console.log('select all event', event) // these don't work
      for (let i = 0; i < this.datasets.length; i++) {
        this.datasets[i].selected = event;
      }
    },
    /**
     * select one row
     */
    selectRow() {
      console.log('select one row') // these don't work
      const isTrue = (element, index) => {
        return element.selected === true;
      };
      if (this.datasets.every(isTrue)) {
        this.selected_all = true;
        this.indeterminate = false;
      } else {
        this.selected_all = false;
        let find = this.datasets.find((item) => item.selected == true);
        if (find) {
          this.indeterminate = true;
        } else {
          this.indeterminate = false;
        }
      }
    },

    /**
     * cancel new DB
     */
    cancelNewDB() {
      this.showNewDB = false;
    },
    /**
     * cancel new from DB
     */
    cancelNewFromDB() {
      this.showNewFromDB = false;
    },
    /**
     * cancel new from file
     */
    cancelNewFromFile() {
      this.showNewFromFile = false;
    },
    /**
     * cancel new from file advance
     */
    cancelNewAdvance() {
      this.showNewFromFileAdvance = false;
    },
    /**
     * save new from file advance
     */
    saveNewAdvance(val) {
      this.advance_file = val;
      this.showNewFromFileAdvance = false;
    },
    /**
     * show new from file advance
     */
    showAdvance() {
      this.showNewFromFileAdvance = true;
    },

    deleteItem(item) {
      this.$store.dispatch(DELETE_DATASET, item.id).then(() => {
        this.load();
      });
    },
    infoItem(item) {
      // this.detailModalVisible = true;
      // this.curProject = item;
    },
    deleteAll() {
      let selIds = this.selectedItemIds;
      this.$store
        .dispatch(DELETE_DATASET_MULTI, selIds)
        .then(() => {
          this.load();
          this.selectedItems=[];
        })
        .catch((r) => {
          console.error("We have error:", r);
        });
    },
    clickNewItem() {
      console.log('click new item')
    },
    editItem(item) {
      console.log('edit item')
      // this.$router.push({ name: "Databases-Detail", params: { id: item.id } });
    },
    /**
     * Update the selection status in Grid View
     */
    toggleChecked(item) {
      console.log('toggle checked') // not working -> no grid view
      let index = this.selectedItems.findIndex((_item) => _item.id === item.id);
      if (item.checked === true) {
        if (index == -1) {
          this.selectedItems.push(item);
        }
      } else {
        if (index > -1) {
          this.selectedItems.splice(index, 1);
        }
      }
    },
    /**
     * save from file
     */
    saveFile(data) {
      // console.log('data', typeof data)
      // if (data) {
      
      const table_name = data.get("table_name")

      if (this.advance_file) {
        data.append("index_col", this.advance_file.index_col);
        data.append("skip_rows", this.advance_file.skip_rows);
        data.append("n_rows", this.advance_file.n_rows);
        data.append("mangle_dupe_cols", this.advance_file.mangle_dupe_cols);
        data.append("skip_spaces", this.advance_file.skip_spaces);
        data.append("skip_blank_lines", this.advance_file.skip_blank_lines);
        data.append("dataframe", this.advance_file.dataframe);
        data.append("use_columns", this.advance_file.use_columns);
        data.append("index_label", this.advance_file.index_label);
        data.append("decimal_char", this.advance_file.decimal_char);
        data.append("parse_dates", this.advance_file.parse_dates);
        data.append("infer_datetime", this.advance_file.infer_datetime);
        data.append("null_values", this.advance_file.null_values);
      }
      this.$store.dispatch(UPLOAD_FILE, data)
        .then((res) => {
          this.showNewFromFile = false;
          this.load();
          this.snackbarError="Success!"
          this.snackbarMsg=`Created Table: ${table_name}`
          this.snackbarColor="#00BAB9"
          this.showSnackbar = true;
          
        }
        )
        .catch((error) => {
          console.error('Error: ', error)
          this.showSnackbar = true;
          this.showNewFromFile = false;
          this.snackbarError=error.data.message
          // this.snackbarMsg=error.message
          this.snackbarColor="snackbar_warning"
          this.load();
        });
      // } else {
      //   this.showSnackbar = true;
      //       this.showNewFromFile = false;
      //       this.snackbarError="Success!"
      //       this.snackbarMsg=`table_name} Uploaded`
      //       this.snackbarColor="snackbar_success"
      //       this.load();
      // }
    },
    closeSnackbar() {
      this.showSnackbar=false
      this.snackbarColor="snackbar_warning"
      this.snackbarMsg=null;
      this.snackbarError=null;
    },
  },
};
</script>

<style lang="scss" scoped>
// .w-100 {
//   width: 100%;
// }
</style>