<template>
  <div class="query-db">
    <!-- database dropdown -->
    <div>
      <p class="mb-0">Database</p>
      <v-combobox
        solo
        :item-text="(obj) => obj['database_name']"
        :item-value="(obj) => obj['database_name']"
        :items="databases"
        @change="changeDB"
        :return-object="true"
        v-model="database"
      >
        <template v-slot:selection="data">
          <v-chip 
            v-if="data.item.database_name" 
            color="primary">{{ data.item.backend }}
          </v-chip>
          {{ data.item.database_name }}
          <!-- {{ "this"+data.item.database_name }} -->
        </template>
      </v-combobox>
    </div>
    <!-- Schema dropdown -->
    <div>
      <p class="mb-0">Schema</p>
      <!-- progress circle -->
      <template v-if="is_load_schema">
        <v-progress-circular class="mb-5" size="30" :width="3" indeterminate color="primary" />
      </template>
      <template v-else>
        <v-combobox
          solo
          :items="schemas"
          v-model="schema"
          @change="getTables"
          :return-object="true"
        ></v-combobox>
      </template>
    </div>
    <!-- Table Dropdown -->
    <div>
      <p class="mb-0">Table</p>
      <div class="query-table" v-if="is_load_table">
        <v-progress-circular class="mb-5" size="30" :width="3" indeterminate color="primary" />
      </div>
      <div class="query-table" v-else>
        <div
          class="context-menu"
          v-click-outside="disableContext"
          ref="contextMenu"
          v-if="showContextMenu"
          :style="contextStyle"
        >
          <div class="list">
            <span class="list__item" @click="selectByLimit"
              >Select * limit 100</span
            >
          </div>
        </div>
        <v-treeview
          :items="tables"
          :active.sync="tableActive"
          :open.sync="tableOpen"
          :load-children="fetchTable"
          activatable
          open-on-click
          transition
        >
          <template v-slot:prepend="{ item, open }">
            <v-icon v-if="item.children" @contextmenu="showMenu($event, item)">
              {{ open ? "mdi-table-minus" : "mdi-table-plus" }}
            </v-icon>
            <v-icon @click="addDataType(item)" v-else>mdi-table-column</v-icon>
          </template>
          <template v-slot:label="{ item }">
            <span @contextmenu="showMenu($event, item)">{{ item.name }}</span>
          </template>
        </v-treeview>
      </div>
    </div>
  </div>
</template>

<script>
import {
  GET_DB_ALL,
  GET_SCHEMA_ALL,
  GET_TABLE_ALL,
  FETCH_TABLE,
  RUN_QUERY,
} from "@/store/actionType";
import { v4 as uuidv4 } from "uuid";

import { findTabIndex } from "./Index.vue"

export default {
  name: "QueryDb",
  data() {
    return {
      //databases
      databases: [],
      //schemas
      schemas: [],
      //tables
      tables: [],
      //selected tab name
      selectedTabName: "",
      //selected schema
      selectedSchema: "",
      //table active
      tableActive: [],
      //table open
      tableOpen: [],
      //selected database
      database: null,
      //selected schema
      schema: "",
      //context left
      contextLeft: 0,
      //context top
      contextTop: 0,
      //show Context Menu
      showContextMenu: false,
      //selected Item
      selectedItem: null,
      //get schema
      is_load_schema: false,
      //get table
      is_load_table: false,
    };
  },
  computed: {
    sqlLab: {
      get() {
        return this.$store.state.query.sqlLab;
      },
      set(val) {
        this.$store.commit("SQLLAB", val);
      },
    },
    selectedTabIndex: {
      get() {
        // return this.$store.state.query.sqlLab_selected_tabIndx;
        return this.$store.state.query.sqlLab_selected_tab.id;
      },
      set(val) {
        this.$store.commit("SELECTED_TABINDEX", val);
      },
    },
    selectedTab: {
      get() {
        // console.log('tab get')
        return this.$store.state.query.sqlLab_selected_tab;
      },
      set(val) {
        // console.log('tab set', val)
        this.$store.commit("SELECTED_TAB", val);
      },
    },
    selectedDB: {
      get() {
        return this.selectedTab.database || null;
        // return this.sqlLab.databases
          // ? this.sqlLab.databases[this.selectedTab.id]
          // : null;
      },
    },
    contextStyle() {
      return {
        top: this.contextTop + "px",
        left: this.contextLeft + "px",
      };
    },
  },
  mounted() {
    // console.log('init database', this.selectedTab.database)
    this.initialize();
    this.getDB();
  },

  methods: {
    /**
     * initialize
     */
    initialize() {
      if (this.sqlLab) {
        console.log('init tab', this.selectedTab)
        // console.log('init tab', this.databases)
        // this.database = this
        // this.database = this.sqlLab.databases
          // ? this.sqlLab.databases[this.selectedTab.id]
          // : null;
        this.database = this.selectedTab.database ?
          this.selectedTab.database : null;
        this.schema = this.selectedTab.schema ?
          this.selectedTab.schema: "";
        this.schemas = this.selectedTab.schemas ?
          this.selectedTab.schemas: null;
        // this.schemas = this.getSchema(this.database)
        // this.sqlLab.schemas
        //   ? this.sqlLab.schemas[this.selectedTab.id]
        //   : "";
        this.tables = this.selectedTab.tableData ?
          this.selectedTab.tableData : [];
        // this.tables = this.sqlLab.tableData
          // ? this.sqlLab.tableData[this.selectedTab.id]
          // : [];
        if (this.database && Object.keys(this.database) > 0) {
          // console.log('do getSchema', this.database)
          this.getSchema(this.database);
        }
      }
    },
    /**
     * get DB
     */
    getDB() {
      this.$store
        .dispatch(GET_DB_ALL)
        .then((res) => {
          this.databases = res.data.result;
        })
        .catch((error) => {
          console.error(error);
        });
        
    },
    /**
     * change db event
     * @param db
     */
    changeDB(db) {
      this.database = db;
      this.schemas = [];
      this.schema = "";
      this.tables = [];
      this.getSchema(db);
      // this.$store.commit("SQLLAB_SELECTED_DB", db);
      // this.$store.commit("SQLLAB_SELECTED_DB", db);
      this.selectedTab.database = db;
      this.$store.commit("SELECTED_TAB", this.selectedTab);
    },
    /**
     * get schema
     * @param db
     */
    getSchema(db) {
      this.is_load_schema = true;
      this.$store
        .dispatch(GET_SCHEMA_ALL, db.id)
        .then((res) => {
          this.is_load_schema = false;
          this.schemas = res.data.result;
          this.selectedTab.schemas = this.schemas;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    /**
     * get tables
     * @param schema
     */
    getTables(schema) {
      this.tables = [];
      this.is_load_table = true;
      const sendingData = {
        pk: this.database.id,
        schema_name: schema,
      };
      this.$store
        .dispatch(GET_TABLE_ALL, sendingData)
        .then((res) => {
          let tbs = res.data.result.options;
          for (let i = 0; i < tbs.length; i++) {
            this.tables.push({
              name: tbs[i].title,
              children: [],
            });
          }
          this.is_load_table = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    /**
     * fetch table
     */
    async fetchTable(item) {
      const sendingData = {
        pk: this.database.id,
        table: item.name,
        schema: this.schema,
      };
      return this.$store
        .dispatch(FETCH_TABLE, sendingData)
        .then(({ data }) => data.columns)
        .then((json) => item.children.push(...json));
    },
    /**
     * add data type
     * @param item
     */
    addDataType(item) {
      if (!item.addType) {
        item.name = `${item.name} (${item.type})`;
        item.addType = true;
      }
    },
    /**
     * right click event
     */
    showMenu(event, item) {
      event.preventDefault();
      this.showContextMenu = true;
      this.contextTop = event.y;
      this.contextLeft = event.x;
      this.selectedItem = item;
    },
    /**
     * disable context
     */
    disableContext() {
      this.showContextMenu = false;
    },
    /**
     * select limit
     */
    selectByLimit() {
      this.disableContext();
      const sendingData = {
        client_id: uuidv4(),
        database_id: this.selectedDB.id,
        json: true,
        queryLimit: 100,
        expand_data: true,
        runAsync: false,
        select_as_cta: false,
        sql: `Select * FROM ${this.selectedItem.name} LIMIT 100`,
        sql_editor_id: "",
        tab: "",
        tmp_table_name: "",
        ctas_method: "TABLE",
      };
      this.$store.dispatch(RUN_QUERY, sendingData).then((res) => {
        this.setQueryRes(res.data);
      });
    },
    /**
     * set query result
     * @param data
     */
    setQueryRes(data) {
      let new_sqlLab = {
        ...this.sqlLab,
      };
      new_sqlLab["query_results"] = new_sqlLab["query_results"]
        ? new_sqlLab["query_results"]
        : new Object();
      new_sqlLab["query_results"][this.selectedTab.id] = data;
      this.$store.commit("SQLLAB", new_sqlLab);
    },
  },
  watch: {
    database: {
      handler() {
        console.log('db watcher', this.database)
        if (this.database) {
          let new_sqlLab = {
            ...this.sqlLab,
          };
          // this.selectedTab.id
          // new_sqlLab["databases"] = new_sqlLab["databases"]
          //   ? new_sqlLab["databases"]
          //   : new Object();
          // new_sqlLab["databases"][this.selectedTab.id] = this.database;
        console.log('new lab', new_sqlLab.query_tabs)
        //   this.$store.commit("SQLLAB", new_sqlLab);
        }
      },
    },
    schema: {
      handler() {
        // console.log('schema handler', this.schema)
        this.selectedTab.schema = this.schema;
        this.$store.commit("SELECTED_TAB", this.selectedTab);
        
        // let new_sqlLab = {
          // ...this.sqlLab,
        // };
        // new_sqlLab["schemas"] = new_sqlLab["schemas"]
        //   ? new_sqlLab["schemas"]
        //   : new Object();
        // new_sqlLab["schemas"][this.selectedTab.id] = this.schema;
        // this.$store.commit("SQLLAB", new_sqlLab);
      },
    },
    tables: {
      handler() {
        // console.log('table handler', this.tables)
        this.selectedTab.tableData = this.tables;
        this.$store.commit("SELECTED_TAB", this.selectedTab);
        // let new_sqlLab = {
        //   ...this.sqlLab,
        // };
        // new_sqlLab["tableData"] = new_sqlLab["tableData"]
        //   ? new_sqlLab["tableData"]
        //   : new Object();
        // new_sqlLab["tableData"][this.selectedTab.id] = this.tables;
        // this.$store.commit("SQLLAB", new_sqlLab);
      },
    },
    selectedTab: {
      handler() {
        this.initialize();
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.query {
  &-table {
    max-height: 450px;
    overflow-y: auto;
    @include scrollbars(0.25rem, black, transparent);
  }
}

.context-menu {
  position: fixed;
  background: white;
  z-index: 999;
  outline: none;
  cursor: pointer;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

.list {
  display: flex;
  flex-direction: column;
  background: #1e1e1e;
  top: 2rem;
  right: 0.5rem;
  border-radius: inherit;

  &__item {
    display: inline-flex;
    align-items: center;
    border-top: 1px solid transparent;
    border-bottom: 1px solid transparent;
    border-radius: 0.3rem;
    padding: 0.4rem 0.65rem;
    margin: 0.2rem;
    text-align: left;
    color: grey;
    height: 2rem;

    &:hover {
      opacity: 0.8;
    }
  }
}
</style>