<template>
  <div id="app">
    <v-app>
      <AppLeftMenu v-if="loggedIn && !isVis" />
      <AppHeader v-if="!isVis" />
      <v-main :class="isVis ? 'visualization' : ''">
        <v-container fluid fill-height class="pa-0 align-baseline" :class="isVis ? '' : 'px-10'">
          <router-view fill-height>Content</router-view>
        </v-container>
      </v-main>
    </v-app>
  </div>
</template>

<script>
import { mapState } from "vuex";
import AppHeader from "./components/AppHeader.vue";
import AppLeftMenu from "./components/AppLeftMenu.vue";

export default {
  name: "app",

  components: {
    AppHeader,
    AppLeftMenu,
  },

  computed: {
    ...mapState({
      loggedIn: state => state.auth.initialState.status.loggedIn,
    })
  },
  data: () => ({
    //check visualization 
    isVis: false
  }),
  mounted() {
    if (this.$route.name === 'VisualizationPage' || this.$route.name === "Dashboards-Detail") {
      this.isVis = true
    } else {
      this.isVis = false
    }
  },
  watch: {
    $route (to, from) {
      if (to.name === 'VisualizationPage' || this.$route.name === "Dashboards-Detail") {
        this.isVis = true
      } else {
        this.isVis = false;
      }
    },
  }
};
</script>

<style>
html {
  font-size: 0.875rem;
}

.v-main {
  margin-top: 48px;
}

.v-main.visualization {
  margin-top: 0;
}

.v-btn {
  text-transform: none !important;
  letter-spacing: normal !important;
}

.v-label {
  font-size: 0.875rem !important;
  font-style: italic;
}

.v-text-field.v-text-field--solo .v-label {
  padding-right: 10px;
}

/* Extend Flex */
.flex-basis-0 {
  flex-basis: 0;
}

.tippy-tooltip {
  font-family: "Roboto" !important;
}

table tr td:last-child div.list-menu {
  position: relative !important;
  width: 120px !important;
  /* background: #eeeeee; */
  padding: 8px;
  border-radius: 50px 0 0 50px;
  padding-left: 10px;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
    0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
  opacity: 0;
  transition: opacity 0.2s linear;
  margin-right: -16px;
  margin-left: -60px;
  float: right;
}

table tr:hover td:last-child div {
  opacity: 1;
  transition: opacity 0.2s linear;
}

tbody tr td {
  cursor: pointer;
}
/* tbody tr td.non-clickable {
  cursor: default;
} */

.v-toolbar__content,
.v-toolbar__extension {
  padding: 2px 16px !important;
  height: 48px;
}
.v-text-field.v-text-field--solo.v-input--dense > .v-input__control {
  min-height: 32px;
}
.v-text-field .v-input__control .v-input__slot {
  min-height: 32px !important;
}

.v-text-field.v-text-field--solo .v-input__control {
  min-height: 28px !important;
}

.theme--dark.v-data-table {
  background: transparent !important;
}

/* /// TABS /// */
.theme--dark.v-tabs > .v-tabs-bar,
.theme--dark.v-tabs-items {
  background-color: transparent !important;
}
.v-tab {
  text-transform: none !important;
  letter-spacing: normal !important;
  font-size: 15px !important;
}

/* .box-project-container { height: 280px; width: 260px;} */
.container-row-resolution {
  /*height: 280px;*/
  margin-left: auto;
  margin-right: auto;
}
.box-project-column {
  margin: 0 3px;
  /* height: 290px !important; */
  height: inherit !important;
}
/* /// MEDIA QUERIES /// */
@media screen and (max-width: 1280px) {
  .container-row-resolution {
    width: 890px !important;
    /* margin-left: auto;
    margin-right: auto; */
  }
  .box-project-column {
    margin: 0 6px;
  }
}

.image-fit {
  background-image: url("assets/charts/chart1.jpg");
  height: 100%;
  width: 100%;
  object-fit: cover;
  background-size: cover;
}

.box-project-column.col a {
  color: #ffffff;
}

/* #app .container {
  padding-left: 100px;
  padding-right: 100px;
} */

.position-relative {
  position: relative;
}

.v-data-table th {
  font-family: Roboto, serif !important;
  font-size: 14px !important;
}

.v-data-table .title {
  font-size: 16px !important;
}
.bg-primary {
  background-color: #1473e6 !important;
}
.bg-red{
  background: #F44336;
}
.bg-green{
  background: #13ADAA;
}
.overline {
  letter-spacing: 1px !important;
  color: #a2a2a2;
}
.fs-16 {
  font-size: 16px !important;
}
.fs-24 {
  font-size: 24px !important;
}
</style>
