<template>
  <div>

    <div class="column">
      <div class="column-header d-flex">
        <div class="column-header-title">Columns</div>
        <!-- Columns tooltip -->
        <v-tooltip right>
          <template v-slot:activator="{ on, attrs }">
            <v-icon 
              small
              v-bind="attrs" 
              v-on="on"
              class="ml-auto" 
              :color="validVisCols.all_columns ? '#B9B9B9' : '#ffbb33'"
            >
              mdi-information-outline
            </v-icon>
          </template>
          <span style="color:#ffbb33">
            Required<br>
          </span>
          <span>
            Columns to display on data grid<br>
            &ensp; <i>Categorical/Numerical</i><br>
          </span>
        </v-tooltip>
      </div>
      <div
        class="column-container"
        @dragover.prevent
        @drop="$emit('dragColFinish', $event)"
      >
        <template v-if="columns.length > 0">
        <!-- <template v-if="columns.all_columns"> -->
          <div v-for="(column, index) in columns" 
            :key="index" class="column-item"
          >
            <!-- :index="index" -->
            <!-- :validVisCols="validVisCols" -->
            <VizModePill 
              :column="getSimpleCol(column)"
              @deleteCol="$emit('deleteCol', index)"
            />
            <!--
            <v-btn
              v-if="column.applied"
              class="mb-08"
              :color="column.type_generic === 0 ? '#1bbab9' : '#00A7FF'"
              rounded
              width="100%"
              height="24px"
            >
              <span class="ml-auto mr-auto">
                {{ column.column_name }}
              </span>
              <v-icon @click="$emit('deleteCol', index)" 
                class="ml-auto" right
              >
                mdi-close-circle
              </v-icon>
            </v-btn>
            <v-btn
              v-else
              class="mb-08"
              :class="
                column.type_generic === 0
                  ? 'column-numeric-btn'
                  : 'column-text-btn'
              "
              rounded
              width="100%"
              height="24px"
              outlined
            >
              <span class="ml-auto mr-auto">
                {{ column.column_name }}
              </span>
              <v-icon @click="$emit('deleteCol', index)" 
                class="ml-auto" right
              >
                mdi-close-circle
              </v-icon>
            </v-btn>
            -->
          </div>
        </template>
        <!-- no columns -->
        <template v-else>
          <div class="blank-drag">Drop Columns Here</div>
          <p class="blank-text">
            Data Fields to Render
          </p>
        </template>
      </div>
    </div>
    <!-- <div class="metric">
      <div class="metric-header d-flex">
        <div class="metric-header-title">Metrics</div>
        <v-icon color="#B9B9B9" class="ml-auto">
          mdi-alert-circle-outline
        </v-icon>
      </div>
      <div
        class="metric-container"
        @dragover.prevent
        @drop="$emit('dragMetricFinish', $event)"
      >
        <template v-if="metrics.length > 0">
          <v-btn
            class="mb-08"
            color="#00A7FF"
            rounded
            width="100%"
            height="24px"
            v-for="metric in metrics"
            :key="metric"
          >
            {{ metric }}
          </v-btn>
        </template>
        <template v-else>
          <div class="blank-drag">Drag here</div>
          <p class="blank-text">
            Drag here the columns you want to use as metrics
          </p>
        </template>
      </div>
    </div> -->
  </div>
</template>

<script>

import VizModePill from "./components/VizModePill.vue"  

export default {
  components:{
    VizModePill,
  },
  props: {
    mode_active_cols: {
      type: Object,
      default() {
        return {};
      },
    },
    columns: {
      type: Array,
      // type: Object,
      default() {
        return [];
      },
    },
    metrics: {
      type: Array,
      default() {
        return [];
      },
    },
    validVisCols: {
      type: Object,
      default() {
        return null;
      },
    },
  },
  methods: {
    isApplied(colname) {
      // const all_cols = this.mode_active_cols.all_columns;
      
      // const result = all_cols.filter(obj => {
      //   return obj.column_name === colname;
      // })

      // return result[0]?.applied;
    },
    getSimpleCol(col) {
      const all_cols = this.mode_active_cols.all_columns;
      
      const result = all_cols.filter(obj => {
        return obj.column_name === col;
      })

      // console.log('simplecol result', result[0])
      return result[0]
      // return {
      //   column_name: col,
      //   type_generic: 0,
      //   // applied: false,
      //   applied: this.isApplied(col),
      //   }
    },
  }

};
</script>

<style lang="scss" scoped>
.column-btn {
  border-color: #1bbab9 !important;
}

.column-numeric-btn {
  border-color: #1bbab9 !important;
}

.column-text-btn {
  border-color: #00a7ff !important;
}

.blank-drag {
  border: 1px dashed #909090;
  border-radius: 12px;
  text-align: center;
  color: #909090;
  padding-top: 4px;
  padding-bottom: 4px;
  font-size: 12px;
}

.blank-text {
  font-size: 12px;
  text-align: center;
  color: #909090;
  margin-top: 9px;
}

.column {
  &-header {
    padding: 20px 15px;

    &-title {
      font-size: 16px;
    }
  }

  &-item {
    margin-bottom: 5px;
  }

  &-container {
    padding-left: 15px;
    padding-right: 15px;
    // padding-bottom: 8px;
    border-bottom: 0.5px solid #373737;
    // background: rgb(85, 84, 84);
    // height: 137px;
    height: 300px;
    overflow-y: auto;
    @include scrollbars(0.4rem, #717171, #00000080);
    // background: red;
  }
}

// .metric {
//   &-header {
//     padding: 20px 15px;

//     &-title {
//       font-size: 16px;
//     }
//   }

  // &-container {
    // padding-left: 15px;
    // padding-right: 15px;
//     padding-bottom: 8px;
//     height: 137px;
//     margin-bottom: auto;
  // }
// }
</style>