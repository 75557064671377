<template>
  <div class="visualization">
    <RenameChartTitle
      :enableEditChartTitle="enableEditChartTitle"
      @rename="renameChartTitle"
      @cancel="cancelChartTitle"
      :chartTitle="slice_name"
    />
    <!-- TODO project info is bad. Referencing a $store variable -->
    <!-- <VisHeader :slice_name="slice_name" :project_info="project_info" /> -->
    <VisHeader :slice_name="slice_name"/>
    <SelectDataSetDlg 
        v-model="dataset_modal" 
        :openOnClick="false"
        @selectDataset="closeDatasetSelect"
        @cancelDatasetSelect="cancelDatasetSelect"
        @datasetSelectBack="cancelDatasetSelect"
      />
    <div class="visualization-container" v-if="load_data">
      <VisData 
        :datasource="datasource" 
        :vis_type="form_data.viz_type"
        @clickDatasetModal="clickDatasetModal"
        @addAllColumns="addAllColumns"
      />
      <!-- @alterFormData="alterFormData" -->
      <VisMode ref="vismode"
        :key="componentKey"
        :form_data="form_data"
        :active_cols="active_cols"
        :adhoc_filters="adhoc_filters"
        :active_metrics="active_metrics"
        :viz_type="viz_type"
        :datasetId="datasource_id"
        :vizObject="vizObject"
        @apply="preApplyQuery"
        @save="saveChart"
        @changeBubbleReact="changeBubbleReact"
        @alterSavedForm="alterSavedForm"
        
        @dragFinish="dragFinish"
        @changeVisType="changeVisType"
        @showForm="showForm"
        @deletePill="deletePill"
      />
      <VisMain ref="VisMain"
        :vis_data="vis_data"
        :form_data="form_data"
        :viz_type="viz_type"
        @showEditChartName="showEditChartName"
        :slice_name="slice_name"
        @getViewerSample="getViewerSample"
        :sample_data_cols="sample_data_cols"
        :sample_data_rows="sample_data_rows"
        :sample_res_headers="sample_res_headers"
        :query_time="formattedElapsedTime"
        :active_query="query_running"
        :unsavedForm="is_altered"
        
      />
    </div>
    <v-layout align-center justify-center column fill-height v-else>
      <v-flex row align-center>
        <v-progress-circular
          size="70"
          :width="7"
          indeterminate
          color="primary"
        />
      </v-flex>
    </v-layout>
    <v-snackbar
      v-model="showSnackbar"
      color="#ffbb33"
    > 
      {{ snackbarError }}
      {{ snackbarMsg }}
      <template v-slot:action="{ attrs }">
        <v-btn
          color="#3366FF"
          v-bind="attrs"
          @click="showSnackbar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>

import { ref } from 'vue';

import VisHeader from "./components/VisHeader.vue";
import VisData from "./components/VisData.vue";
import VisMode from "./components/VisMode.vue";
import VisMain from "./components/VisMain.vue";
import RenameChartTitle from "./components/RenameChartTitle.vue";

// TODO :required until refactor bubble plot
import Highcharts from "highcharts";
require("highcharts/modules/accessibility");(Highcharts);
require("highcharts/modules/no-data-to-display")(Highcharts);


import SelectDataSetDlg from "@/views/charts/modals/SelectDataSetDlg";

import {
  GET_CHART_BY_ID,
  GET_EXPLORE_FORM_BY_ID,
  GET_CHART_DATA_BY_ID,
  POST_CHART_DATA,
  SAVE_FORM_DATA,
  CREATE_CHART,
  UPDATE_CHART,GET_CHART_FORM
} from "../../store/actionType";

import { 
  parseFormDataToViz,
  BaseViz, TableViz,
  PieChartViz, BubbleChartViz,
  ScatterPlotViz,
  BarChartViz, BoxPlotViz, HeatmapViz
} from "@/utils/chartUtils"

export default {
  components: {
    VisHeader,
    VisData,
    VisMode,
    VisMain,
    RenameChartTitle,
    SelectDataSetDlg,
  },
  data() {
    return {
      dataset_modal: false,
      showSnackbar: false,
      snackbarError: null,
      snackbarMsg: null,
      vizObject: null,
      componentKey: 0,
      is_altered: false,
      saved_form_data: null,
      query_running: false,
      query_time: null,
      elapsedTime: 0,
      timer: undefined,
      sliceId: null,
      //cache key
      formDataKey: null,
      //slice data
      slice: null,
      slice_name: null,
      //slice param
      param: null,
      //visualization type
      viz_type: null,
      //query context
      query_context: null,
      //visualization data
      vis_data: null,
      //// Viewer Samples
      ///////////////////
      sample_data: null,
      sample_data_cols: [],
      //visualization data rows
      sample_data_rows: [],
      //visualization result tab headers
      sample_res_headers: [],
      ////////////////////
      //visualization active columns
      // active_cols: [],
      active_cols: {},
      //visualization active filters
      adhoc_filters: [],
      //visualization active metrics
      active_metrics: [],
      //datasource
      datasource: null,
      //form_data
      form_data: null,
      //data source id
      datasource_id: null,
      //data source type
      datasource_type: null,
      //bubble max size
      bubble_max_size: null,
      //bubble series
      bubble_series: null,
      //bubble x
      bubble_x: null,
      //bubble y
      bubble_y: null,
      //bubble x column
      x_col: null,
      //bubble y column
      y_col: null,
      //bubble option with data
      bubble_def_min_size:8,
      bubble_def_max_size:20,
      bubble_data: null,
      //scatter plot option with data
      scatter_data: null,
      bubble_abs_size: {
            'maxSize': '20%',
            'minSize': '8%',
            },
      //enable edit chart title
      enableEditChartTitle: false,
      //bubble chart entity
      bubble_entity: null,
      //chart load
      load_data: false,
      // save chart on rename
      saveRename: false,
      //project information
      project_info: null,
    };
  },
  mounted() {
    this.load_data = false;
    
    // parse url params
    this.sliceId = this.$route.params.sliceId;
    this.formDataKey = this.$route.params.forDatakey;
    
    // get project info
    // TODO: Something is off here. Caching in store doesn't clear correctly
    if (this.$store.state.chart.project_info) {
      this.project_info = this.$store.state.chart.project_info;
    } else {
      this.project_info = JSON.parse(localStorage.getItem('project_info'));
    }
    this.load_chart_page();

  },

  computed: {
    formattedElapsedTime() {
      // let time_str = new Date()
      let lapsed = this.elapsedTime;
      let ms = Math.floor((lapsed / 10) % 100)
      let sec = Math.floor((lapsed / 1000) % 60);
      let hrs = Math.floor((lapsed / 1000 / 60 / 60));
      let mins = Math.floor((lapsed / 1000 / 60) % 60);
      if(hrs.toString().length == 1) {
          hrs = '0' + hrs;
        }
      if(mins.toString().length == 1) {
          mins = '0' + mins;
        }
      if(sec.toString().length == 1) {
          sec = '0' + sec;
        }   
      let times_array=[hrs, mins, sec]
      let time_str = times_array.join(':') + "." + ms
      return time_str;
    }
  },
  methods: {
    showForm() {
      // console.log('INdex Form', this.form_data.series)
    },
    // Imports
    parseFormDataToViz,
    //////////

    fillScatterEmbedding() {
      // console.log('fill out a scatter plot fool')
    },

    addAllColumns() {
      const all_cols = this.datasource.columns.map(a => a.column_name);
      // this.$set(this.form_data, 'all_collumns', all_cols)
      this.form_data.all_columns = all_cols
      
      const newActiveCols ={...this.vizObject
            .parseColumns(this.form_data)
      }
      this.active_cols = Object.assign(
        {}, this.active_cols, newActiveCols
      )
      this.$refs.vismode.addAllCols(newActiveCols)
      this.applyQuery(this.form_data)
    },

    clickDatasetModal() {
      this.dataset_modal=true;
    },

    cancelDatasetSelect() {
      this.dataset_modal = false;
    },

    postChartForm(id, chartType) {
    // console.log('post form')
    const params = {
      datasource: id,
      viz_type: chartType
    };
    let chart_form = this.$store.dispatch(GET_CHART_FORM, params)
      .then((res) => {
        return res.data.result
      })
      .catch((error) =>{
        console.error(error)
      });
    return chart_form;
    },

    async closeDatasetSelect(dataset) {
      this.dataset_modal = false;
      
      let chart_form = await this.postChartForm(dataset, this.viz_type)
      chart_form.url_params = this.form_data.url_params;
      await this.saveFormData(chart_form, dataset)
      this.load_chart_page();
    },

    async changeVisType (chart_form) {
      chart_form.url_params = this.form_data.url_params;
      this.vis_data=null;
      this.active_cols=[];
      this.adhoc_filters=[];
      // this.active_metrics=[];
      this.viz_type = chart_form.viz_type;
      await this.saveFormData(chart_form, this.datasource_id)
      this.load_chart_page();
    },

    async load_chart_page() {
      // console.log('load chart page')
      this.load_data=false;
      this.sliceId = this.$route.params.sliceId;
      this.formDataKey = this.$route.params.forDatakey;

      /// HACK || TODO: fix for pie chart active columns
      // this.active_cols = [];
      // this.active_cols={};

      if (this.sliceId !== "null") {
        await this.getChartFromId(this.sliceId);
        await this.getChartFormFromKey(this.formDataKey); // set this.form_data????
      } else if (this.formDataKey) {
        await this.getChartFormFromKey(this.formDataKey);
      }

      this.parseVizObject();
      
      // If form is valid apply the query
      if (this.vizObject.isValidForm(this.form_data)) {
        this.applyQuery(this.form_data)
        this.load_data=true;
        // Else, stop loading and show nothing
      } else {
        // console.log('do nothing')
        this.load_data=true;
      } // else no form data / key -> 
      //     // open default viz page with new fdk
      // console.log('load page done', this.form_data, this.vizObject)
    },

    parseVizObject() {
      // console.log("parse viz \\\\\\\\\\\\\\", this.viz_type,":", this.form_data)

      const vizTypeObj = { 
        'pie': PieChartViz, 
        'bubble': BubbleChartViz, 
        'scatter': ScatterPlotViz, 
        'dist_bar': BarChartViz,
        'horiz_bar': BarChartViz, 
        'box_plot': BoxPlotViz,
      }
      if ( Object.keys(vizTypeObj).includes(this.viz_type) ) {
        this.vizObject = new vizTypeObj[this.viz_type](this.datasource, this.form_data)
          // console.log('parseVizObject', this.vizObject, this.form_data)
          this.active_cols = Object.assign({}, this.vizObject
            .parseColumns(this.form_data)
          )
      } else {
        switch(this.viz_type) {
          case "table":
            this.vizObject = new TableViz(this.datasource, this.form_data);
            // \/~~ correct, using the below for testing
            this.form_data = {...this.vizObject.form_data, ...this.form_data};
            // using the below for testing
            // this.form_data = {...this.form_data, ...this.vizObject.form_data};
            ///////
            this.active_cols = this.vizObject
              .parseColumns(this.form_data);
            break;
          case "heatmap":
            this.vizObject = new HeatmapViz(this.datasource, this.form_data)
            this.form_data = {...this.vizObject.form_data, ...this.form_data};
            // console.log('parse Heatmap', this.form_data)
            this.active_cols = Object.assign({}, this.vizObject
              .parseColumns(this.form_data)
            )
            break;
        }
      }
      // console.log("parse active cols", this.active_cols)
    },

    deletePill(type) {
      // this.form_data
      this.$delete(this.form_data, type);
      // console.log('form data val', this.form_data) 
    },

    dragFinish(type, val) {
      // console.log('form data val', this.form_data[type], type, val)
      let new_obj = {}
      new_obj[type]= val
      this.form_data = Object.assign({}, this.form_data, new_obj)
      // console.log('form data val', this.form_data) 
    },

    // alterFormData(new_form_data) {
    //   console.log('alter form', new_form_data)
    // },

    // TODO: these are the same function
    alterSavedForm (cols) {
      if (this.saved_form_data !== null) {
        if (Object.values(this.saved_form_data).includes("all_columns")) {
          // console.log('alter_form', this.saved_form_data.all_columns)
        }
        // console.log('new cols', cols)
        
        let saved_cols = this.saved_form_data.all_columns
        // let altered_cols = cols
        // console.log('eq', cols.toString() === saved_cols.toString())
        if ( saved_cols.toString() !== cols.toString() ) {
          this.is_altered=true;
        } else {
          this.is_altered=false;
        }
      }
    },

    calcBubbleAbsSize(_val) {
      // console.log("calc me", _val)
      let size_val = _val[0]
      let min_size =_val[1][0]
      let max_size =_val[1][1]
      // console.log('_val',_val)
      let maxRatioChange = 2
      let sizeFactor=1
      // control sizes proportionally
      if (size_val === 0) {
        sizeFactor = 1
      } else if (size_val > 0) {
        sizeFactor = 1 + ( size_val/100 ) * maxRatioChange
      } else if (size_val < 0 ) {
        sizeFactor = 1/(1 + Math.abs( size_val/100) * maxRatioChange * 2)
      }

      return {
        'maxSize': ( max_size * sizeFactor ) +"%",
        'minSize': ( min_size * sizeFactor ) +"%"
        } 
    },

    changeBubbleReact(_val) {
      if (this.bubble_data !== null) {
        let size = this.calcBubbleAbsSize(_val)

        this.bubble_data.series[0].maxSize = size['maxSize']
        this.bubble_data.series[0].minSize = size['minSize']
        // console.log('react size',size)
      }
    },
    
    /**
     * get chart data
     * @param id
     */
    getChart(id) {
      // console.log('getChart')
      this.$store
        .dispatch(GET_CHART_BY_ID, id)
        .then((res) => {
          let result = res.data.result;
          this.slice_name = result.slice_name;
          this.param = JSON.parse(result.params);
          this.viz_type = result.viz_type;
          this.query_context = JSON.parse(result.query_context);
          if (!this.formDataKey) {
            this.getChartFormFromKey(this.formDataKey);
            // this.formDataKey = this.param.url_params.form_data_key;
            // this.getChartForm(this.formDataKey);
          } else {
            // this.getChartForm(this.formDataKey);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },

    /**
     * get chart data
     * @param id
     */
    getChartData(id) {
      this.$store
        .dispatch(GET_CHART_DATA_BY_ID, id)
        .then((res) => {
          this.vis_data = res.data.result[0]
            // this.viz_type == "heatmap" ? res.data.data : res.data.result[0];
            this.viz_type == "heatmap" ? res.data.result[0].data : res.data.result[0];

          // TODO: remove individual data name for each viz. 
            /// use something more like vis_data_rows
            /// Everything should parse via the vizObject to enable this
            /// This doesn't go to vis main. Do we need it?
          // const scatterArray = ['bubble', 'scatter'];
          if (this.viz_type === "scatter") {
            this.scatter_data = this.parseBubbleData(this.form_data);
          } else if (this.viz_type === "bubble") {
            this.bubble_data = this.parseBubbleData(this.form_data);
          } else if (this.viz_type === "table") {
            for (let col of this.vis_data.colnames) {
              this.vis_data_cols.push({
                headerName: col,
                field: col,
                enableRowGroup: true,
              });
              this.vis_res_headers.push({
                text: col,
                sortable: true,
                value: col,
              });
            }
            this.vis_data_rows = this.vis_data.data;
            // this.vis_data.gridColumnApi = this.form_data
            // console.log('load gridColumnApi Data', this.form_data)
          } else if (this.viz_type === "heatmap") {
            console.log('heatmap getChartData', this.vis_data)
            let xlist = this.vis_data.records.map((item) => item.x);
            let xCategories = [...new Set(xlist)];
            let ylist = this.vis_data.records.map((item) => item.y);
            let yCategories = [...new Set(ylist)];
            let series = [];
            // console.log('heatmap getChartData', this.vis_data)
            for (let i = 0; i < xCategories.length; i++) {
              for (let j = 0; j < yCategories.length; j++) {
                let v_value = this.vis_data.records[i].v
                // let find_val = this.vis_data.records.find(
                  // (item) =>
                    // item.x === xCategories[i] && item.y === yCategories[j]
                // );
                series.push([i, j, v_value]);
              }
            }
            this.heatmap_data = {
              chart: {
                type: "heatmap",
                marginTop: 40,
                marginBottom: 80,
                plotBorderWidth: 1,
                backgroundColor: "rgb(21, 21, 21)",
              },
              title: {
                text: "",
              },
              xAxis: {
                categories: xCategories,
              },
              yAxis: {
                categories: yCategories,
              },
              colorAxis: {
                min: 0,
                minColor: "#FFFFFF",
                maxColor: Highcharts.getOptions().colors[0],
              },
              legend: {
                align: "right",
                layout: "vertical",
                margin: 0,
                verticalAlign: "top",
                y: 25,
                symbolHeight: 280,
              },
              tooltip: {
                formatter: function () {
                  return (
                    "<b>" +
                    this.series.xAxis.categories[this.point.x] +
                    "</b> sold <br><b>" +
                    this.point.value +
                    "</b> items on <br><b>" +
                    this.series.yAxis.categories[this.point.y] +
                    "</b>"
                  );
                },
              },
              series: [
                {
                  borderWidth: 1,
                  data: series,
                  dataLabels: {
                    enabled: true,
                    color: "#000000",
                  },
                },
              ],
            };
          } else if (this.viz_type === "pie") {
            this.vis_data = this.vizObject.parseVisData(this.form_data)
          } else if (
            this.viz_type === "dist_bar" ||
            this.viz_type === "horiz_bar" 
          ) {
            // dist_bar_data
            this.bar_data = this.parseBubbleData(this.form_data);
          } else if (this.viz_type === "box_plot") {
            let xVals = this.vis_data.colnames.filter(
              (item) => !item.includes("__")
            );
            let yVals = this.vis_data.colnames.filter((item) =>
              item.includes("mean")
            );
            let yCategories = yVals.map((item) => item.slice(0, -6));
            let xCategories = this.vis_data.data.map((item) => {
              let result = "";
              for (let i = 0; i < xVals.length; i++) {
                if (i === 0) {
                  result += `${item[xVals[i]]}`;
                } else {
                  result += `, ${item[xVals[i]]}`;
                }
              }
              return result;
            });
            let series = [];
            let yCat_data = [];
            for (let yCategory of yCategories) {
              let series_data = this.vis_data.data.map((item) => {
                return [
                  item[`${yCategory}__min`],
                  item[`${yCategory}__q1`],
                  item[`${yCategory}__median`],
                  item[`${yCategory}__q3`],
                  item[`${yCategory}__max`],
                ];
              });
              let outlier_data = this.vis_data.data.map((item) => {
                return [item[`${yCategory}__outliers`]];
              });
              series.push({
                name: yCategory,
                data: series_data,
                tooltip: {
                  headerFormat: "<em>{point.key}</em><br/>",
                },
              });
              series.push({
                name: "Outliers",
                color: Highcharts.getOptions().colors[0],
                type: "scatter",
                data: outlier_data,
                marker: {
                  fillColor: "white",
                  lineWidth: 1,
                  lineColor: Highcharts.getOptions().colors[0],
                },
                tooltip: {
                  pointFormat: "Observation: {point.y}",
                },
              });
              yCat_data.push({
                title: {
                  text: yCategory,
                },
              });
            }
            this.box_plot_data = {
              chart: {
                type: "boxplot",
              },
              title: {
                text: "",
              },
              legend: {
                enabled: true,
              },
              xAxis: {
                categories: xCategories,
              },
              yAxis: yCat_data,
              series: series,
            };
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
    
    async getChartFromId(id) {
      // console.log('getChart from id')
      await this.$store
        .dispatch(GET_CHART_BY_ID, id)
        .then((res) => {
          let result = res.data.result;
          this.slice_name = result.slice_name;
          // this.param = JSON.parse(result.params);
          this.form_data = JSON.parse(result.params);
          this.formDataKey = this.$route.params.forDatakey;
          this.query_context = JSON.parse(result.query_context);
        })
        .catch((error) => {
          console.error(error);
        });
    },

    async getChartDataFromId(id) {
      // console.log('getChartData from id')
      let payload = await this.$store.dispatch(GET_CHART_DATA_BY_ID, id)
        .then((res) => {
          return res.data.result[0];
        })
        .catch((error) => {
          console.error(error);
        });
      return payload;
    },

    async getChartFormFromKey(formDataKey) {
      // console.log('getChartFormFromKey')
      await this.$store
        .dispatch(GET_EXPLORE_FORM_BY_ID, formDataKey)
        .then((res) => {
          this.datasource = res.data.response.datasource;
          this.datasource_id = res.data.response.datasource_id;
          this.datasource_type = res.data.response.datasource_type;

          let init_form_data = res.data.response.form_data;

          this.viz_type = init_form_data.viz_type;
          this.form_data = init_form_data;

          // parse filters on reload
          // TODO: refactor this to set filters with parseVizObject
          for (let filter of this.form_data.adhoc_filters) {
            this.adhoc_filters.push({
              ...filter,
              applied: true,
            });
          }
          // console.log('getChartFormFromKey', this.form_data)
          
        })
        .catch((error) => {
          // this.showSnackbar = true;
          // this.snackbarMsg = error;

          console.error(error);
        });
    },

    /**
     * get chart form
     * @param formDatakey
     */
    getChartForm(formDatakey) {
      // console.log('getChartForm')
      const dist_type = ["dist_bar","horiz_bar","box_plot"]
      this.$store
        .dispatch(GET_EXPLORE_FORM_BY_ID, formDatakey)
        .then((res) => {
          this.datasource = res.data.response.datasource;
          this.form_data = res.data.response.form_data;
          this.datasource_id = res.data.response.datasource_id;
          this.datasource_type = res.data.response.datasource_type;
          this.viz_type = this.form_data.viz_type;
          this.adhoc_filters = [];
          let vizObject;
          for (let filter of this.form_data.adhoc_filters) {
            this.adhoc_filters.push({
              ...filter,
              applied: true,
            });
          }
          if (this.viz_type === "bubble") {
            this.bubble_max_size = this.form_data.max_bubble_size;
            this.bubble_series = this.form_data.series;
            this.bubble_entity = this.form_data.entity;
            this.bubble_size = this.form_data.size;
            this.bubble_x = this.form_data.x;
            this.x_col = this.bubble_x ? this.bubble_x.column : null;
            this.bubble_y = this.form_data.y;
            this.y_col = this.bubble_y ? this.bubble_y.column : null;
          /// TODO: tyhis should be handled via vizObject as well
            // eliminate global viz variables
          } else if (this.viz_type === "scatter") {
            // this.bubble_max_size = this.form_data.max_bubble_size;
            this.bubble_series = this.form_data.series;
            this.bubble_entity = this.form_data.entity;
            // this.bubble_size = this.form_data.size;
            this.bubble_x = this.form_data.x;
            this.x_col = this.bubble_x ? this.bubble_x.column : null;
            this.bubble_y = this.form_data.y;
            this.y_col = this.bubble_y ? this.bubble_y.column : null;
          } else if (this.viz_type === "table") {
            // parse table viz
            vizObject = new TableViz(this.datasource_id, this.form_data);
            this.form_data = vizObject.form_data;
            this.active_cols = vizObject.parseColumns(this.datasource.columns);
            // TODO: fix metrics, there is currently no functionality for m
            this.active_metrics = vizObject.form_data.metric;
            // console.log('vizObjectValid', vizObject.isValidForm())

          } else if (this.viz_type === "heatmap") {
            // console.log('load heatmap from form')
            let selected_x = this.form_data.all_columns_x
              ? this.form_data.all_columns_x
              : null;
            let selected_y = this.form_data.all_columns_y
              ? this.form_data.all_columns_y
              : null;
            // this.$store.commit("SELECTED_X", selected_x);
            // this.$store.commit("SELECTED_Y", selected_y);
            if (this.form_data.metric) {
              let heat_metric = {
                col: this.form_data.metric.column,
                aggregation: this.form_data.metric.aggregate,
                label: this.form_data.metric.label,
                expressionType: this.form_data.metric.expressionType,
              };
              // this.$store.commit("SELECTED_HEAT_METRIC", heat_metric);
            }
          } else if (this.viz_type === "pie") {
            // console.log('pie load from form', pie_metric)
            let pie_metric;
            let categories;
            if (this.form_data.metric) {
              pie_metric = {
                col: this.form_data.metric.column,
                aggregation: this.form_data.metric.aggregate,
                label: this.form_data.metric.label,
                expressionType: this.form_data.metric.expressionType,
              };
            }
            if (this.form_data.all_columns) {
            categories = this.datasource.columns.filter((item) =>
            this.form_data.all_columns.includes(item.column_name)
            );
            }
            this.$store.commit("SELECTED_PIE_METRIC", pie_metric);
            this.$store.commit("SELECTED_PIE_CATEGORIES", categories);
          } else if ( dist_type.includes(this.viz_type) ) {
            let metrics = [];
            let series = [];
            let categories = [];
            if (this.form_data.metrics) {
              for (let item of this.form_data.metrics) {
                metrics.push({
                  col: item.column,
                  aggregation: item.aggregate,
                  label: item.label,
                  expressionType: item.expressionType,
                });
              }
            }
            if (this.form_data.groupby) {
              series = this.datasource.columns.filter((item) =>
                this.form_data.groupby.includes(item.column_name)
              );
            }
            if (this.form_data.columns) {
              categories = this.datasource.columns.filter((item) =>
                this.form_data.columns.includes(item.column_name)
              );
            }

            this.$store.commit("BAR_SERIES", series);
            this.$store.commit("BAR_CATEGORIES", categories);
            this.$store.commit("BAR_METRICS", metrics);
          }
          this.load_data = true;
          // Get Chart Data
          if (vizObject) {
            if (vizObject.isValidForm()) {
              if (this.sliceId !== "null") {
                this.getChartData(this.sliceId);
                this.saved_form_data = {...this.form_data}
              } 
              else {
                this.getChartDataByForm(this.form_data);
              }
            }
          } else {
            // this.load_data = true;
            if (this.sliceId !== "null") {
              // console.log("get data by id", this.form_data)
              this.getChartData(this.sliceId);
              this.saved_form_data = {...this.form_data}
            } 
            else {
              // console.log("get data by form", this.form_data)
              this.getChartDataByForm(this.form_data);
            }
          }
        })
        .catch((error) => {
          // this.showSnackbar = true;
          // this.snackbarMsg = error;
          // console.log('asdfasdfasdfasdds',error);
          console.error(error.data.message);
        });
    },

    getChartDataByForm(form_data) {
      let params = {
        datasource: {
          id: this.datasource_id,
          type: this.datasource_type,
        },
        force: true,
        create_query_context: true,
        form_data: form_data,
        result_format: "json",
        result_type: "results",
      };
      // console.log('params', params)
      this.$store
        .dispatch(POST_CHART_DATA, params)
        .then((res) => {
          this.fillVizFromForm(res)
        })
        .catch((error) => {
          console.error(error);
          this.showSnackbar = true;
          this.snackbarError = {
            'err_code': error.status, 
            'err_text': error.statusText
          }
          this.snackbarMsg = error.data.message;
          console.error(error.data.message);
        });
    },

    fillVizFromForm(form_post_result) {
      let res = form_post_result
      let payload = res.data.result[0];
      // console.log('payload', payload)

      const vizTypeArray = [
        'bubble', 'scatter', 'table', 'heatmap',
        'dist_bar','horiz_bar','box_plot','pie',
      ];

      if ( vizTypeArray.includes( this.viz_type )) {
        this.vis_data = this.vizObject.parseVisData(payload, this.form_data)
      } 
    },

    /**
     * save form data
     * @param form_data
     */
    async saveFormData(form_data, dataset_id) {
      let form_params;
  
      form_params = {
        dataset_id: dataset_id,
        form_data: JSON.stringify(form_data),
      };
  
      const params = {
        key: form_data.url_params.form_data_key,
        data: form_params,
      };
      // console.log('save form', params)
      // console.log('save form', form_params)
      await this.$store.dispatch(SAVE_FORM_DATA, params);
    },

    startQueryTimer () {
      this.elapsedTime = 0;
      this.timer = null;
      this.timer = setInterval(() => {
        this.elapsedTime += 10;
      }, 10);
      this.query_running=true;
    },

    stopQueryTimer() {
      clearInterval(this.timer);
      this.query_running=false;
    },

    getGridState() {
      let gridState;
        // console.log('getgridz', this.$refs.VisMain)
        if (this.$refs.VisMain) {
          let childRef = this.$refs.VisMain.$refs.TableVis
          gridState = { "grid_state": {
            'gridColumnApi':
              childRef ? childRef.gridColumnApi.getColumnState() : []
            ,
            'isPivotMode': childRef ? childRef.gridColumnApi.isPivotMode() : false
          }
          }
        }
      return gridState
    },

    preApplyQuery(form_data) {
      
      if (this.viz_type === 'table') {
        let gridState = this.getGridState()
        // console.log('grixz', gridState)
        form_data =  {...form_data, extra_form_data: gridState}
        // form_data =  {...form_data, extra_form_data: {"test": "value"}}
      }
      this.applyQuery(form_data)
    },

    async applyQuery(form_data) {
    /**
     * apply query (save form data & run query)
     * @param form_data
     */
      this.startQueryTimer()
      await this.saveFormData(form_data, this.datasource_id);

      let data = {
          datasource: {
            id: this.datasource_id,
            type: this.datasource_type,
          },
          force: true,
          create_query_context: true,
          form_data: form_data,
          result_format: "json",
          result_type: "results",
        };
      // console.log("apply query", data)
      this.$store.dispatch(POST_CHART_DATA, data)
        .then((res) => {
        this.form_data=Object.assign({}, form_data);
        this.active_cols = Object.assign({}, this.vizObject
            .parseColumns(this.form_data)
          )
        // console.log('pre fill', this.active_cols)
        this.fillVizFromForm(res)
        
      })
      .catch((error) => {
          console.error('error: ', error);
          this.showSnackbar = true;
          this.snackbarError = {
            'err_code': error.status, 
            'err_text': error.statusText
          }
          this.snackbarMsg = error.data.message;
          console.error(error.data.message);
        });

      this.stopQueryTimer()
      // this.alterSavedForm(this.form_data.all_columns)
    },

    async getViewerSample() {
      // console.log("apply sample query")
      // await this.saveFormData(form_data);
      let data;
      data = {
        datasource: {
            id: this.datasource_id,
            type: this.datasource_type,
        },
        force: true,
        create_query_context: true,
        form_data: this.form_data,
        result_format: "json",
        result_type: "samples",
      };
      // console.log('gete sampel data', JSON.stringify(data))
      this.$store.dispatch(POST_CHART_DATA, data).then((res) => {
          // console.log('sample data//////', res)
            this.sample_data = res.data.result[0];
            this.sample_data_cols = [];
            this.sample_res_headers = [];

            this.sample_data_rows = this.sample_data.data;
            for (let col of this.sample_data.colnames) {
              this.sample_data_cols.push({
                headerName: col,
                field: col,
                enableRowGroup: true,
              });
              this.sample_res_headers.push({
                text: col,
                sortable: true,
                value: col,
              });
            }
        });
    },

    /**
     * save chart
     * @param form_data
     */
    saveChart(form_data) {
      // TODO: Search for already used chart_name in scope
      if (this.slice_name === null) {
          this.showEditChartName(true)
        } 
        else {
          this.saveChartAction(form_data)
        }
      // this.saved_form_data = true
      this.saved_form_data = {...form_data}
      this.is_altered=false;
    },

    async saveChartMeta(chart_name) {
      const data = {
        // create_query_context: true,
        // params: JSON.stringify(form_data),
        // datasource_id: this.datasource_id,
        // // datasource_name: this.datasource.datasource_name,
        // datasource_type: this.datasource_type,
        // description: "",
        slice_name: this.slice_name,
        // viz_type: form_data.viz_type,
      };
      await this.$store.dispatch(UPDATE_CHART, [this.sliceId, data]);
    },

    async saveChartAction(form_data) {
      // console.log("save the chart", JSON.stringify(form_data.size))
      if (this.viz_type === 'table') {
        let gridState = this.getGridState()
        // console.log('saveChart', gridState)
        // console.log('saveFormData', form_data)
        form_data =  {...form_data, extra_form_data: gridState}
      }
      // console.log('saveFormData', form_data)
      await this.saveFormData(form_data, this.datasource_id);
      if (this.sliceId === "null") {
        const data = {
          create_query_context: true,
          query_context_generation: true,
          datasource_id: this.datasource_id,
          datasource_name: this.datasource.datasource_name,
          datasource_type: this.datasource_type,
          description: "",
          params: JSON.stringify(form_data),
          slice_name: this.slice_name ? this.slice_name : "",
          viz_type: form_data.viz_type,
        };

        this.$store.dispatch(CREATE_CHART, data).then((res) => {
          this.sliceId = res.data.id;
          this.$router.push(
            `/visualizations/${this.sliceId}/${form_data.url_params.form_data_key}`
          );
        });
      } else {
        // UPDATE_CHART metadata
        const data = {
          create_query_context: true,
          params: JSON.stringify(form_data),
          datasource_id: this.datasource_id,
          // datasource_name: this.datasource.datasource_name,
          datasource_type: this.datasource_type,
          description: "",
          slice_name: this.slice_name,
          viz_type: form_data.viz_type,
        };
        // console.log('UPDATE_CHART', form_data)
        await this.$store.dispatch(UPDATE_CHART, [this.sliceId, data]);
        // // TODO: Catch errors and show snackbar --> do this globally
        // } catch(e) {
        //   // .catch((error) => {
        //   console.log('error: ', e);
        // }
      }
      this.applyQuery(form_data);
    },

    showEditChartName(doSaveChart) {
    /**
     * show edit chart name
     */
      this.enableEditChartTitle = true;
      // console.log('save me?', doSaveChart)
      if(doSaveChart) {
        this.saveRename=true;
      }
    },

    renameChartTitle(name) {
    /**
     * rename chart title
     * @param name
     */
      this.slice_name = name.trim();
      if (this.saveRename === true) {
        this.saveChartAction(this.form_data);
      } else if ( !this.sliceId || this.sliceId !== 'null' ) {
        this.saveChartMeta(this.slice_name);
      }
      
      this.enableEditChartTitle = false;
      this.saveRename = false;
    },

    cancelChartTitle() {
    /**
     * cancel rename chart title
     */
      this.enableEditChartTitle = false;
      this.saveRename = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.visualization {
  width: 100%;
  height: 100%;

  &-container {
    border-top: 0.5px solid #373737;
    margin-top: 48px;
    display: flex;
    height: calc(100% - 48px);
  }
}
</style>