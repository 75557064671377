<template>
  <div class="d-flex flex-column fill-height flex-grow-1 overflow-hidden pt-6">
    <ModuleHeader
      :title="`Dashboards /  New Dashboard`" 
      :show-layout-option = "false"/>
    <v-container fluid  fill-height align-start>
      <v-row>
        <v-col
          cols="3"
          class="flex-grow-0 flex-shrink-0"
          style="min-width: 100px;"
        >
          <v-form
            ref="form"
            v-model="formValid"
            lazy-validation
          >
            <v-card
              class="mx-auto"
              max-width="344"
              style="min-height: 80vh; background: rgb(18,18,18)"
            >
              <v-card-title style="text-decoration: underline">
                Dashboards
              </v-card-title>
              
              <v-container>
                <div class="mb-3">Title</div>
                <v-text-field
                  filled
                  label=""
                  v-model="title"
                  :rules="titleRules"
                ></v-text-field>
                
                <p class="mt-5">Description</p>
                <v-textarea
                  filled
                  label=""
                  v-model="description"
                  :rules="descriptionRules"
                ></v-textarea>

                <p class="mt-5">Owners</p>
                <v-select
                  v-model="selectedUsers"
                  :items="users"
                  item-value="userId"
                  item-text="username"
                  chips
                  label="Owners"
                  multiple
                  solo
                ></v-select>
              </v-container>
              <v-card-actions class="justify-center">
                <v-btn
                  depressed
                  @click="cancel"
                >
                  Cancel
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn
                  color="primary"
                  @click="create"
                >
                  Apply
                </v-btn>

              </v-card-actions>
            </v-card>
          </v-form>
        </v-col>
        <v-col
          cols="9"
          style="min-width: 500px; padding: 0 !important;"
          class=""
        >
          <div class="d-flex flex-row mt-5">
            <div class="d-flex flex-grow-1 align-center">Select Charts to add to the dashboard</div>
            <div class="d-flex align-center ">
              <v-text-field
                ref="searchInput"
                hide-details
                label="Geo"
                no-filter
                dense
                solo
                outlined
                hide-no-data
                prepend-inner-icon="mdi-magnify"
                class="hidden-sm-and-down custom-class ml-auto"
                style="max-width: 300px;"
              >
              </v-text-field>
            </div>
            <div class="d-flex  align-center ml-7">
              <div class="overline mr-2">sort by</div>
              <v-select
                v-model="curSortField"
                :items="sortFields"
                item-text="txt"
                item-value="field"
                label="Sort"
                solo
                outlined
                hide-details="true"
                class="mr-2"
                style="width: 150px"
                v-on:change="load"
              ></v-select>

              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon v-bind="attrs" v-on="on" :class="!viewGrid && 'inactive'">
                    <v-icon size="18" @click="viewGrid = !viewGrid">
                      mdi-view-grid
                    </v-icon>
                  </v-btn>
                </template>
                <span>Grid View</span>
              </v-tooltip>

              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon  v-bind="attrs" v-on="on" :class="viewGrid && 'inactive'">
                    <v-icon size="24" @click="viewGrid = !viewGrid">
                      mdi-view-list
                    </v-icon>
                  </v-btn>
                </template>
                <span>List View</span>
              </v-tooltip>
            </div>
          </div>
          <div v-if="viewGrid">
            <v-container>
              <v-row class="container-row-resolution">
                <BoxItem
                  v-for="item in getRecentModels(collection)"
                  v-bind:key="item.id"
                  :item="item"
                  :class-name="`slice`"
                  :title-field="`slice_name`"
                  :selectable=true
                  @toggled="toggleFav"
                  @checked="toggleChecked"
                  @clickItem="toggleChecked"
                />
              </v-row>
            </v-container>
          </div>
          <div
            v-if="!viewGrid"
            class="
              d-flex
              flex-grow-1 flex-column flex-basis-0
              fill-height
              overflow-x-auto
            "
          >
            <v-data-table
              id="tblItems"
              v-model="selectedItems"
              :items="getRecentModels(collection)"
              :headers="headers"
              :search="search"
              @click:row="clickItem"
              @item-selected="updateGridViewSelection"
              show-select
              item-key="id"
              class="mt-3"
              :hide-default-footer="true"
              :header-props="{ sortIcon: 'mdi-chevron-down' }"
            >
              <template #[`item.slice_name`]="{ item }">
                <div @click.stop class="actions">
                  <a
                    @click="clickItem(item)"
                    >{{ item.slice_name }}</a
                  >
                </div>
              </template>
              <template #[`item.created_by.username`]="{ item }">
                <div v-if="item.created_by" class="actions">
                  <div
                    class="chips"
                    :class="{ 'bg-green': item.created_by.username }"
                  >
                    {{ item.created_by.username.substring(0, 2).toUpperCase() }}
                  </div>
                </div>
              </template>
            </v-data-table>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import {
  GET_CHART_ALL,
  SET_FAV,
  UNSET_FAV,
  CREATE_DASHBOARD,
  GET_USERS_ALL,
} from "@/store/actionType";

import ModuleHeader from "@/views/_partials/ModuleHeader";
import BoxItem from "@/views/_partials/BoxItem";

export default {
  name: "NewDashboard",
  components: {
    ModuleHeader,
    BoxItem
  },
  data(){
    return {
      viewGrid: true,
      curSortField: '',
      sortFields: [
        {txt: "Title", field: "dashboard_title"},
        {txt: "Date", field: "changed_on_utc"},
      ],
      users: [],
      selectedUsers: [],
      selectedItems: [],
      search: "",
      headers: [
        { text: "Title", value: "slice_name" },
        { text: "Visualization type", value: "viz_type" },
        { text: "Dataset", value: "datasource_type" },
        { text: "Created By", value: "created_by.username" },
        { text: "Last Modified", value: "changed_on_delta_humanized" },
      ],
      title: "",
      description: "",
      collection: [],
      formValid: true,
      titleRules: [
        v => !!v.trim() || 'Title is required',
      ],
      descriptionRules: [
        v => !!v.trim() || 'Description is required',
      ]
    }
  },
  methods:{
    getRecentModels(arr) {
      const filteredArr = arr.filter(function (item) {
        return item.recent === true;
      });
      return filteredArr;
    },
    getFavModels(arr) {
      return arr.filter(function (item) {
        return item.favorite === true;
      });
    },
    load() {
      const columns = [
        "id",
        "changed_by.username",
        "created_by.first_name",
        "created_by.last_name",
        "created_by.username",
        "changed_on_utc",
        "changed_on_delta_humanized",
        "slice_name",
        "viz_type",
        "datasource_type",
      ];

      const q = {
        columns: columns,
        order_column: this.curSortField,
        order_direction: "desc",
      };

      const favQ = {
        ...q,
        filters: [
          {
            col: "id",
            opr: "chart_is_favorite",
            value: "!t",
          },
        ],
      };

      // Load Recent Item
      let recentArr=[];
      this.$store
        .dispatch(GET_CHART_ALL, q)
        .then((r) => {
          recentArr  = r.data.result;

          // Load Favorite Charts
          return this.$store
            .dispatch(GET_CHART_ALL, favQ);
        })
        .then((favResult) => {
          this.collection = this.mergeFavItems(recentArr, favResult);
        })
        .catch((r) => {
          console.error("We have error:", r);
        });

      // Load Users (Owners)
      this.$store
        .dispatch(GET_USERS_ALL)
        .then((r) => {
          this.users = r.data.result.Users;
        })
        .catch((r) => {
          console.error("We have error:", r);
        });
    },
    mergeFavItems(recentArr, favResult) {
      let favIds = favResult.data.ids;

      for (let index in favResult.data.result) {
        favResult.data.result[index]["favorite"] = true;
      }
      for (let index in recentArr) {
        const _index = favIds.indexOf(recentArr[index].id);
        if (_index === -1) {
          recentArr[index]["favorite"] == false;
          recentArr[index]["recent"] = true;
          favResult.data.result.push(recentArr[index]);
        } else {
          favResult.data.result[_index]["recent"] = true;
        }
      }
      return favResult.data.result;
    },

    toggleFav(item, className) {

      const param = {
        id: item.id,
        className: className,
      };

      let collection = this.collection;
      let itemIndex = collection.indexOf(item);

      if (item.favorite === true) {
        this.$store
          .dispatch(UNSET_FAV, param)
          .then((r) => {
            item.favorite = false;
            let editedItem = { ...item };
            collection.splice(itemIndex, 1, editedItem);
            console.log(collection);
          })
          .catch((r) => {
            console.error("We have error: ", r);
          });
      } else {
        this.$store
          .dispatch(SET_FAV, param)
          .then((r) => {
            item.favorite = true;
            let editedItem = { ...item };
            collection.splice(itemIndex, 1, editedItem);
          })
          .catch((r) => {
            console.error("We have error: ", r);
          });
      }
    },

    /**
     * click item in table
     * @param item
     */
    clickItem(item) {
      
    },
    /**
     * Update the selection status in Grid View
     */
    updateGridViewSelection(obj) {
      let selItem = this.collection.find(item => item.id === obj.item.id)
      if (selItem) {
        selItem.checked = obj.value
      }
    },
    /**
     * Update the selection status in Grid View
     */
    toggleChecked(item) {
      let index = this.selectedItems.findIndex(_item => _item.id === item.id)
      if (item.checked === true) {
        if (index == -1) {
          this.selectedItems.push(item)
        }
      }
      else {
        if (index > -1) {
          this.selectedItems.splice(index, 1)
        }
      }
      
    },
    create() {
      if (!this.$refs.form.validate()) return;
      const selIds = this.selectedItems.map(ele => ele.id);
      const params = {
        dashboard_title: this.title,
        post_charts: selIds,
        description: this.description,
        owners: this.selectedUsers
      }
      this.$store
        .dispatch(CREATE_DASHBOARD, params)
        .then((r) => {
          this.$router.push({ name: 'Dashboards-Detail', params: { id: r.data.id } });
        })
        .catch((r) => {
          console.error("We have error: ", r);
        });
    },
    cancel() {
      // this.$router.push({ name: 'Dashboards-Index'});
      this.$refs.form.reset();
    }
  },
  mounted() {
    this.load();
  }
}
</script>

<style scoped>

.owner-name{
  height: 38px;
  width:38px;
  border-radius: 50%;
  background: #15D2D2FF;
  padding: 8px 9px;
  font-size: 14px;
  display: flex;
}

.inactive {
  color: rgba(255, 255, 255, 0.3) !important;
}
.inactive:hover {
  color: inherit !important;
}
</style>