//authentication
export const SUCCESSLOGIN = "loginSuccess";
export const LOGINFAILURE = "loginFailure";
export const LOGOUTSUCCESS = "logout";


//sql query
export const SQLLAB_SELECTED_DB = "SQLLAB_SELECTED_DB";
export const SQLLAB = "SQLLAB";
export const SELECTED_TAB = "SELECTED_TAB";
export const SELECTED_TABINDEX = "SELECTED_TABINDEX";
