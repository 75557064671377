<template>
  <div>
    <v-dialog
      content-class="custom-dialog"
      v-model="member_select_modal"
      width="960"
      persistent
    >
      <v-card color="#262626">
        <v-card-title>Edit Project Metadata</v-card-title>
        <v-divider/>
        <v-form ref="form"
          @submit="editMetadata"
          v-model="formValid"
          lazy-validation
        >
        <v-container>
          <v-row>
            <!-- Title and Members -->
            <v-col>
              <div class="title-text">
              <div class="mb-3">Title</div>
                <v-text-field
                  filled
                  label=""
                  v-model="title"
                  @input="descrChanged=true"
                  :rules="titleRules"
                ></v-text-field>
              </div>
              <div class="members-autocomplete">
                <p class="mb-3" >Members</p>
                <!-- @input="usersChanged=true" -->
                <!-- @change="usersChanged=true" -->
                  <!-- @input="usersChanged=true" -->
                <v-autocomplete
                  v-model="selectedUsers"
                  :items="users"
                  item-value="userId"
                  item-text="username"
                  dense
                  chips
                  filled
                  multiple
                  deletable-chips
                  @change="changeUsers"
                ></v-autocomplete>
              </div>
            </v-col>
            <!-- Description Area -->
            <v-col>
              <div class="description-textarea">
              <div class="mb-3" >Description</div>
                <!-- :rules="descriptionRules" -->
                <v-textarea
                  filled
                  label=""
                  v-model="description"
                  @input="descrChanged=true"
                ></v-textarea>
              </div>
            </v-col>
          </v-row>
        
        </v-container>
        </v-form>
        <v-divider></v-divider>
        <v-card-actions 
          style="padding-top: 12px; padding-bottom: 12px;" 
        >
          <v-btn
            class="mr-5"
            depressed
            @click="cancelDialog"
          >
            Cancel
          </v-btn>
          <v-btn
            color="primary"
            depressed
            :disabled="!submitForm"
            @click="editMetadata"
            type="submit"
          >
            OK
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>

import {
  GET_USERS_ALL,
  ADD_PROJECT_CHILDREN,
} from "@/store/actionType";

export default {
  name: "EditMetadataDlg",
  components: {
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    projectMeta: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      collection: [],
      selectedItems: [],
      search:"",
      moduleFilter:'',
      descrChanged: false,
      users:[],
      selectedUsers: this.projectMeta.members_metadata,
      description: this.projectMeta.description,
      title: this.projectMeta.project_title,
      formValid: true,
      titleRules: [
        v => !!v.trim() || 'Title is required',
      ],
      usersChanged: false,
    }
  },
  mounted() {
    console.log('cmp', this.projectMeta.members_metadata)
    this.load();
  },
  watch: {
    value() {
      this.load();
    }
  },
  methods: {
    changeUsers() {
      console.log('edit users')
      this.descrChanged=true;
      this.usersChanged=true;
    },
    editMetadata() {
      let params = {
        id: this.projectMeta.id,
        className: "metadata",
        q: this.userObject,
        body: {
          'project_title': this.title.trim(),
          'description': this.description,
          }
      }
      console.log('params', JSON.stringify(params))
      this.$store
        .dispatch(ADD_PROJECT_CHILDREN, params)
        .then((r) => {
          this.$emit('reloadProject', this.projectMeta.id);
        })
        .catch((error) => {
          this.$emit('showErrorSnackbar')
          console.error(error)    
        });
      this.$emit('closeDialog')
      this.descrChanged=false;
      this.usersChanged=false;
    },
    cancelDialog() {
      this.$emit('closeDialog')
      this.title=this.projectMeta.project_title;
      this.description = this.projectMeta.description;
      this.selectedUsers = this.projectMeta.members_metadata;
      this.descrChanged = false;

    },
    load() {
      this.$store
        .dispatch(GET_USERS_ALL)
        .then((r) => {
          this.users = r.data.result.Users;
          console.log('load users', this.users)
        })
        .catch((r) => {
          console.error("We have error:", r);
        });
      this.selectedUsers = this.projectMeta.members_metadata;
      this.description = this.projectMeta.description;
      this.title = this.projectMeta.project_title;
      this.descrChanged = false;
      this.usersChanged = false;
    },
  },
  computed: {
    userObject() {
      if (!this.usersChanged) {
        let userarray=this.projectMeta.members_metadata.map(a => a.userId )
        return userarray;
      } else {
        return this.selectedUsers;
      }
    },
    submitForm () {
      if (this.$refs.form) {
        return this.descrChanged && this.$refs.form.validate()
      } else {
        console.log('no form')
        return this.descrChanged;
      }
    },
    member_select_modal: {
      get: function () {
        return this.value;
      },
      set: function (newValue) {
        this.value = newValue;
      },
    },
  },
};
</script>

<style lang="scss" scoped>

.gridSelect{
  justify-content: right;
  background-color: #262626;
}

.close {
  margin-right: 15px;
  cursor: pointer;
  font-size: 20px;
}
.custom-dialog {
  position: absolute;
  /*right: 10%;*/
  top: 10%;
}
.outline-selected {
  border-bottom: 1px solid #ffffff;
}
</style>