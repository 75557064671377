<template>
  <div class="d-flex flex-column fill-height flex-grow-1 overflow-hidden pt-6">
    <ModuleHeader
      title="Query History"
      :disabled-grid-btn="true"
      :disabled-fav-btn="true"
      :disabled-list-btn="true"
      :view-grid="false"
      @updateViewFavOnly="viewFavOnly = !viewFavOnly"
    />
    <!-- <div class="save-dialog"/> -->
    <!-- @menuEvent="menuEvent" -->
    <!-- :selectedItems="selectedItems" -->
    <ModuleAction
      title="New Query"
      search-label="Search Query History (SQL)"
      @clickNewItem="clickNewItem"
      @deleteAll="deleteAll"
      @selectItemsEvent="selectItemsEvent"
      @moduleFilterChanged="moduleFilterChanged"
    />
    <!-- Data Table View  -->
    <div
      class="
        d-flex
        flex-grow-1 flex-column flex-basis-0
        fill-height
        overflow-x-auto
      "
    >

      <v-container>
        <v-row v-if="loading" class="container-row-resolution py-5 d-flex justify-center">
          <v-progress-circular
            indeterminate
            color="primary"
          ></v-progress-circular>
        </v-row>
          <!-- @click:row="editItem" -->
          <!-- @item-selected="updateGridViewSelection" -->
          <!-- @toggle-select-all="toggleSelectAll" -->
          <!-- show-select -->
          <!-- v-model="selectedItems" -->
        <v-data-table
          id="tblItems"
          v-else-if="getModels(collection, viewFavOnly).length"
          
          :items="getModels(collection, viewFavOnly)"
          :items-per-page="$pageItemCount"
          :headers="headers"
          :search="search"
          item-key="id"
          class="mt-3"
          :hide-default-footer="true"
          :header-props="{ sortIcon: 'mdi-chevron-down' }"
        >
          <template v-slot:top="{ pagination, options, updateOptions }">
            <v-data-footer
              :pagination="pagination"
              :options="options"
              @update:options="updateOptions"
              items-per-page-text="$vuetify.dataTable.itemsPerPageText"
            />
          </template>
          <template #[`item.status`]="{ item }">
            <span
              class="text-capitalize"
              :class="item.status == 'success' ? 'text-success' : 'text-failed'"
              >{{ item.status }}</span
            >
          </template>
          <template #[`item.start_time`]="{ item }">
            {{ new Date(item.start_time) | formatDate }}
          </template>
          <template #[`item.duration`]="{ item }">
            {{ item.duration }}
            <!-- {{ (item.start_time) }} -->
            <!-- {{ formattedElapsedTimeSeconds(item) }} -->
            <!-- {{ formattedElapsedTimeSeconds(item.end_time - item.start_time) }} -->
            <!-- {{ formattedElapsedTimeSeconds(item.end_time - item.start_time) }} -->
            
          </template>
          <template #[`item.database.database_name`]="{ item }">
            <v-chip color="primary">{{ item.database.database_name }}</v-chip>
          </template>
          <template #[`item.sql`]="{ item }">
            <pre><code class="language-sql">{{item.sql}}</code></pre>
            <!-- <pre><codemirror
              class="codemirror-display"
              :value="item.sql"
              ref="sqlEditor"
              @ready="onCmReady"
              :options="cmOptions"
            /></pre> -->
          </template>
          <template #[`item.action`]="{ item }">
            <RowAction
              :item="item"
              :selectActionOptions="selectActionOptions"
              :disabledList="disabledRowActions"
              @editItem="editItem"
              @deleteItem="deleteItem"
              @infoItem="infoItem"
              @actionItemEvent="actionItemEvent"
            />
          </template>
        </v-data-table>

        <v-row v-else class="container-row-resolution pt-5 d-flex justify-center">
          <p>No Query History Found ...</p>
        </v-row>
      </v-container>
    
    <!-- TODO: Create Component for other Module Pages -->
    <!-- width="500" -->
    <v-navigation-drawer
      v-model="detailModalVisible"
      hide-overlay
      right
      temporary
      absolute
      color="#262626"
      
      :width="navigation.width"
    >
    <v-list-item>
      <v-card 
        v-if="curHistory" 
        width="500"
        color="#262626"
        flat
      >
        <v-card-title>{{ pageTitle + ' Info' }}</v-card-title>
        <v-container overflow-hidden>
          <div class="outline d-flex">
            <v-col md="12">
              <table class="w-100 prop-table">
                <tr v-for="(prop, key) in curHistory" v-bind:key="prop.id">
                  <td class="pl-2">{{ key }}</td>
                  <td class="pl-2">{{ prop }}</td>
                </tr>
              </table>
            </v-col>
          </div>
        </v-container>
      </v-card>
      </v-list-item>
    </v-navigation-drawer>
    </div>
  </div>
</template>

<script>

import "codemirror/mode/sql/sql.js";
// theme css
import "codemirror/theme/mbo.css";

import { 
    toggleSelectAll,
    modifyAllCheck,
    selectItemsEvent,
    filteredObjs,
  } from "@/utils/moduleUtils.js";

import { GET_QUERY_HISTORY_ALL, POST_SAVED_QUERY } from "@/store/actionType";
// UPLOAD_FILE
import ModuleHeader from "@/views/_partials/ModuleHeader";
import RowAction from "@/views/_partials/RowAction";
import ModuleAction from "@/views/_partials/ModuleAction";

export default {
  name: "QueryHistoryIndex",
  components: {
    // NewDB,
    // NewFromDB,
    // NewFromFile,
    // NewFromFileAdvance,
    ModuleHeader,
    ModuleAction,
    RowAction,
  },
  data: () => ({
    cmOptions: {
      tabSize: 2,
      // styleActiveLine: true,
      lineNumbers: false,
      // line: true,
      mode: "text/x-mysql",
      theme: "mbo",
      readOnly: true,
      lineSeparator: "\n",
      // lineWrapping:true,
    },
    selectActionOptions: [
      {title: 'Save Query', action:'save_query'},
    ],
    moduleFilter:'',
    navigation: {
        shown: false,
        width: 500,
        borderSize: 3
    },
    time_str: null,
    pageTitle: "Query History",
    viewFavOnly: false,
    selectedItems: [],
    search: "",
    //selected all
    selected_all: false,
    //checkbox indeterminate
    indeterminate: false,
    detailModalVisible: false,
    curHistory: null,
    //table headers
    headers: [
      { text: "Status", value: "status", width: 100 },
      { text: "Start Time", value: "start_time", width: 120 },
      { text: "Duration", value: "duration" },
      { text: "Database", value: "database.database_name" },
      { text: "Rows", value: "rows", width: 100 },
      { text: "User", value: "user.username", width: 100 },
      { text: "SQL", value: "sql", width: 300, sortable: false },
      { text: "Actions", value: "action", width: 200, sortable: false },
    ],
    disabledRowActions: {
      'edit':false, 
      'info':true, 
      'trashcan':true, 
      'action': true
    },
    // props: [
    //   { name: "a1", value: 50 },
    //   { name: "cl", value: 60.8 },
    //   { name: "mn02", value: 9.8 },
    //   { name: "mxph", value: 8 },
    //   { name: "nh4", value: 578 },
    //   { name: "no3", value: 6.238 },
    //   { name: "po4", value: 170 },
    //   { name: "season", value: "winter" },
    // ],
    collection: [],
    loading: false,
    //query history
    historyData: [],
    //menu items
    // menuItems: [
    //   {
    //     text: "From Database",
    //     action: "from_db",
    //   },
    //   {
    //     text: "From File",
    //     action: "from_file",
    //   },
    //   {
    //     text: "Custom SQL",
    //     action: "custom_sql",
    //   },
    //   {
    //     text: "New Database",
    //     action: "new_db",
    //   },
    // ],
    //show New database dialog
    // showNewDB: false,
    //show New from database dialog
    // showNewFromDB: false,
    //show New from file dialog
    // showNewFromFile: false,
    //show New from file advance dialog
    // showNewFromFileAdvance: false,
    //advanced from file value
    advance_file: null,
  }),
  mounted() {
    this.load();
    // this.setBorderWidth();
    // this.setEvents();
    // this.getQueryHistory();
  },
  methods: {
    // Imports
    //////////
    //moduleUtils
    filteredObjs,
    toggleSelectAll,
    modifyAllCheck,
    selectItemsEvent,
    // onCmReady(cm) {
      // cm.setSize(null, "120px");
    // },
    postSavedQuery(params) {
      this.$store
        .dispatch(POST_SAVED_QUERY, params)
        .catch((r) => {
          console.error("We have error: ", r);
        });
    },
    moduleFilterChanged (input) {
      this.moduleFilter = input;
    },
    saveQuery(row, title_fields) {
      if ( Object.keys(row).length > 0 ) {
        let params = {
          db_id: row.database.id,
          label: title_fields.name,
          description: title_fields.description,
          sql: row.sql
        }
        this.postSavedQuery(params);
      }
    },

    actionItemEvent (action, row, user_input) {
      switch (action) {
        case "save_query":
          this.saveQuery(row, user_input)
          break;
      }
    },
    //////////
    /*** nav drawer resize
    // setBorderWidth() {
    //   let i = this.$el.querySelector(".v-navigation-drawer__border");
    //   i.style.width = this.navigation.borderSize + "px";
    //   i.style.cursor = "ew-resize";
    //   i.style.backgroundColor = "red";
    //   // console.log('refs', this.$el.querySelector(".v-navigation-drawer__border"))
    // },
    // ///// Border resize
    // setEvents() {
    //   const minSize = this.navigation.borderSize;
    //   const el = this.$el;
    //   const drawerBorder = this.$el.querySelector(".v-navigation-drawer__border");

    //   const vm = this;
    //   const direction = el.classList.contains("v-navigation-drawer--right")
    //     ? "right"
    //     : "left";

    //   console.log('refs', direction)

    //   function resize(e) {
    //     console.log('resize')
    //     document.body.style.cursor = "ew-resize";
    //     let f =
    //       direction === "right"
    //         ? document.body.scrollWidth - e.clientX
    //         : e.clientX;
    //     el.style.width = f + "px";
    //   }

    //   drawerBorder.addEventListener(
    //     "mousedown",
    //     (e) => {
    //       console.log('mousedown')
    //       if (e.offsetX < minSize) {
    //         el.style.transition = "initial";
    //         document.addEventListener("mousemove", resize, false);
    //       }
    //     },
    //     false
    //   );

    //   document.addEventListener(
    //     "mouseup",
    //     () => {
    //       console.log('mouseup')
    //       el.style.transition = "";
    //       this.navigation.width = el.style.width;
    //       document.body.style.cursor = "";
    //       document.removeEventListener("mousemove", resize, false);
    //     },
    //     false
    //   );
    // },
    */
    /////
    formattedElapsedTimeSeconds(qh_item) {
      // take query_history item and calc formatted time
      // TODO: make utils function with VisMain computed property
      if (qh_item.end_time ==null) {
        return "-1"
      } else {
        let lapsed = (qh_item.end_time - qh_item.start_time);
        let ms = Math.floor((lapsed / 10) % 100)
        let sec = Math.floor((lapsed / 1000) % 60);
        let hrs = Math.floor((lapsed / 1000 / 60 / 60));
        let mins = Math.floor((lapsed / 1000 / 60) % 60);
        if(hrs.toString().length == 1) {
            hrs = '0' + hrs;
          }
        if(mins.toString().length == 1) {
            mins = '0' + mins;
          }
        if(sec.toString().length == 1) {
            sec = '0' + sec;
          }   
        let times_array=[hrs, mins, sec]
        let time_str = times_array.join(':') + "." + ms
        return time_str;
      }
    },
    getRecentModels(arr) {
      //This module doesn't have Favorite
      return arr;
    },
    getModels(arr) {
      //This module doesn't have Favorite
      if (arr.length > 0 && this.moduleFilter.length > 0) {
        arr = this.filteredObjs(arr, 'sql', this.moduleFilter)
      }
      return this.getRecentModels(arr);
    },
    load() {
      const columns = [
        "id",
        "changed_by.username",
        "created_by.first_name",
        "created_by.last_name",
        "created_by.username",
        "changed_on_utc",
        "changed_on_delta_humanized",
        "backend",
        "database_name",
        "database_id"
      ];

      const q = {
        columns: columns,
        order_column: this.curSortField,
        order_direction: "desc",
      };

      // Load Recent Objects
      // Database doesn't have favorites
      this.loading = true
      this.$store
        .dispatch(GET_QUERY_HISTORY_ALL, q)
        .then((r) => {
          let res = r.data.result;
          for (const property in res) {
            let duration = this.formattedElapsedTimeSeconds(res[property]);
            res[property].duration  = duration;
          }
          this.collection = res;
        })
        .catch((r) => {
          console.error("We have error:", r);
        })
        .finally(() => {
          this.loading = false
        })
    },
    /**
     * select all query history
     */
    selectAll(event) {
      for (let i = 0; i < this.historyData.length; i++) {
        this.historyData[i].selected = event;
      }
    },
    /**
     * select one row
     */
    selectRow() {
      const isTrue = (element, index) => {
        return element.selected === true;
      };
      if (this.historyData.every(isTrue)) {
        this.selected_all = true;
        this.indeterminate = false;
      } else {
        this.selected_all = false;
        let find = this.historyData.find((item) => item.selected == true);
        if (find) {
          this.indeterminate = true;
        } else {
          this.indeterminate = false;
        }
      }
    },
    /**
     * get query history
     */
    // getQueryHistory() {
    //   this.$store.dispatch(GET_QUERY_HISTORY_ALL).then((res) => {
    //     this.initiailize(res.data.result);
    //   });
    // },
    /**
     * initialize database values
     * @param values
     */
    // initiailize(values) {
    //   this.historyData = [];
    //   for (let item of values) {
    //     this.historyData.push({
    //       status: item.status == "success" ? "Success" : "Failed",
    //       start_time: new Date(item.start_time),
    //       duration: item.end_time - item.start_time,
    //       // duration: this.formattedElapsedTimeSeconds(item.end_time - item.start_time),
    //       database: item.database.database_name,
    //       rows: item.rows,
    //       username: item.user.username[0],
    //       sql: item.sql,
    //       selected: false,
    //     });
    //   }
    // },
    /**
     * menu event
     * @param item
     */
    // menuEvent(item) {
    //   switch (item.action) {
    //     case "custom_sql":
    //       this.$router.push("/sql-page");
    //       break;
    //     case "from_db":
    //       this.showNewFromDB = true;
    //       break;
    //     case "from_file":
    //       this.showNewFromFile = true;
    //       break;
    //     case "new_db":
    //       this.showNewDB = true;
    //       break;
    //   }
    // },
    /**
     * close new from DB and show new DB
     */
    // showCreateDB() {
    //   this.showNewFromDB = false;
    //   this.showNewDB = true;
    // },
    /**
     * go to datasets page
     */
    // goToDatasets() {
      // this.showNewFromDB = false;
      // this.$router.push("/datasets");
    // },
    /**
     * cancel new DB
     */
    // cancelNewDB() {
      // this.showNewDB = false;
    // },
    /**
     * cancel new from DB
     */
    // cancelNewFromDB() {
      // this.showNewFromDB = false;
    // },
    /**
     * cancel new from file
     */
    // cancelNewFromFile() {
      // this.showNewFromFile = false;
    // },
    /**
     * cancel new from file advance
     */
    // cancelNewAdvance() {
      // this.showNewFromFileAdvance = false;
    // },
    /**
     * save new from file advance
     */
    // saveNewAdvance(val) {
    //   this.advance_file = val;
    //   this.showNewFromFileAdvance = false;
    // },
    /**
     * show new from file advance
     */
    // showAdvance() {
    //   this.showNewFromFileAdvance = true;
    // },
    deleteItem(item) {
      // this.$store
      //   .dispatch(DELETE_PROJECT, item.id)
      //   .then(() => {
      //     this.load();
      //   });
    },
    infoItem(item) {
      this.detailModalVisible = true;
      this.curHistory = item;
    },
    deleteAll() {},
    clickNewItem() {
      this.$router.push({ name: "SqlPageIndex" });
    },
    editItem(item) {
      console.log('edit me', item)
      // TODO: Add sql store data here
      this.$router.push({ name: "SqlPageIndex" });
      // this.$router.push({ name: 'Databases-Detail', params: { id: item.id } });
    },
    /**
     * Update the selection status in Grid View
     */
    updateGridViewSelection(obj) {
      let selItem = this.collection.find((item) => item.id === obj.item.id);
      if (selItem) {
        selItem.checked = obj.value;
      }
    },
    /**
     * Update the selection status in Grid View
     */
    toggleChecked(item) {
      let index = this.selectedItems.findIndex((_item) => _item.id === item.id);
      if (item.checked === true) {
        if (index == -1) {
          this.selectedItems.push(item);
        }
      } else {
        if (index > -1) {
          this.selectedItems.splice(index, 1);
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>

// .codemirror-display {
  // border: 20px solid yellow;
    // height: auto;
    // max-height:100px;
//   background: red;
//   border: solid 20px red;
//   height: auto;
  // &-scroll {
    // height: auto;
    // max-height:40px;
  // }
// }

// .CodeMirror {
//     border: 20px solid yellow;
//     height: auto;
//     max-height:20px;
// }

// .CodeMirror-scroll {
//     height: auto;
//     max-height:20px;
// }

.w-100 {
  width: 100%;
}

pre {
  @include scrollbars(0.4rem, black, transparent);
  display: block;
  overflow-x: auto;
  padding: 0.5em;
  // color: rgb(248, 248, 248);
  background: rgb(51, 51, 51);
  border: none;
  font-size: 12px;
  margin: 0;
  padding: 0 !important;
  margin: 10px;
  max-width: 300px;
  code {
    white-space: pre;
    background-color: initial;
    border-radius: 0;
    color: inherit;
    font-size: inherit;
    padding: 0;
  }
}
.text {
  &-code {
    max-width: 300px;
    padding: 5px;
  }

  &-left {
    text-align: left;
  }

  &-center {
    text-align: center;
  }

  &-success {
    color: #8aedac;
    font-size: 14px;
  }

  &-failed {
    font-size: 14px;
    color: #ed8ac7;
  }
}

.v-dialog__content {
  justify-content: right;
  align-items: flex-start;
}

.prop-table {
  tr {
    height: 35px;
  }
  tr:nth-child(odd) {
    background-color: #151515;
    td {
      border-top: 1px solid gray;
      border-bottom: 1px solid gray;
    }
  }
}
.info-dialog {
  height: 100%;
  width: 400px;
  max-height: 100% !important;
  background-color: #262626;
}

.info-dialog .v-card {
  background-color: #262626;
  height: 100%;
}
</style>