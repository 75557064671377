<template >
<!--<template v-if="pie_data"> -->
  
  <div v-if="!!vis_data" ref="tbGrid">
    <highcharts :options="chart_data"/>
  </div>
</template>

<script>
import Highcharts from "highcharts"
require("highcharts/modules/accessibility");(Highcharts);
require("highcharts/modules/no-data-to-display")(Highcharts);

// These enable the export hamburger
// require("highcharts/modules/export-data")(Highcharts);
// require("highcharts/modules/exporting")(Highcharts);

export default {
  name: "BarChartVis",
  components: {
  },
  props: {
    viz_type: {
      type: String,
      default() {
        return null;
      }
    },
    form_data: {
      default() {
        return null;
      },
    },
    vis_data: {
      default() {
        return {
          'row_data': [],
          'columns': [],
          'coltypes': [],
          'vis_data': [],

        };
      },
    },
  },
  data() {
    return {
      chartTypeObj: {
        "dist_bar": "column",
        "horiz_bar": "bar",
      }
    };
  },
  // methods: {},
  computed: {
    chart_data() {
      let chart_data;
      let series=[];
      // let xlist=[];
      // let ylist=[];
      let bar_keys=[];
      let bar_metrics=[];
      // let x_axis_labels=this.form_data.groupby;
      let x_axis_labels = []//["Null", "EU","ME"]
      let sub_row_data = [];
      // console.log('my bar data', this.vis_data)
      // console.log('chart from')
      // console.log('my bar form', this.form_data)
      const chart_type='dist_bar';
      const row_data = this.vis_data.vis_data;
      // if (this.form_data.columns.length <1) {
        bar_metrics = row_data.map((item) => item.key);
        // const xCategories = [...new Set(xlist)];
        // x_axis_labels = row_data.map(row => row.values.x)
        for (const key in bar_metrics) {
          // console.log('???????????????', bar_metrics[key])
          const row_values = row_data[key];
          const xlist = row_values.values.map(row => row.x)
          const ylist = row_values.values.map(row => row.y)
          series.push({
            name: bar_metrics[key],
            data: ylist
            });
          if (key === '0') {
            x_axis_labels = [...xlist];
          }
        }


      chart_data = {
        chart: {
          type: this.chartTypeObj[this.viz_type],
          backgroundColor: "rgb(21, 21, 21)",
          // events: {
          //   load: function () {
          //     console.log(this)
          //   }
          // },
        },
        title: {
          text: "",
        },
        xAxis: {
          tickWidth: 0,
          labels: {
            style: {
              color: "#fff",
            },
          },
          categories: x_axis_labels,
        },
        yAxis: {
          gridLineWidth: 0.5,
          gridLineDashStyle: "dash",
          gridLineColor: "black",
          title: {
            text: "",
            style: {
              color: "#fff",
            },
          },
          labels: {
            formatter: function () {
              return Highcharts.numberFormat(this.value, 0, "", ",");
            },
            style: {
              color: "#fff",
            },
          },
        },
        legend: {
          enabled: true,
        },
        credits: {
          enabled: false,
        },
        tooltip: {
          pointFormat: 
            "<b>Row</b>: {point.category}<br/>" +
            "<b>{series.name}</b>: {point.y}<br>"
        },
        plotOptions: {
          series: {
            dataLabels: {
              enabled: false,
              // format: `{point.name}`,
            },
          },
          column: {
            borderRadius: 0,
            pointPadding: 0,
            groupPadding: 0.05,
          },
        },
        series: series,
        // series: [],
      }
      return chart_data
    },  
  },
}
</script>

<style lang="scss" scoped>

</style>