<template>
  <div class="d-flex flex-column fill-height flex-grow-1 overflow-hidden pt-6">
    <ModuleHeader
      :view-grid="viewGrid"
      :disabledListBtn="moduleTab === 3"
      :disabledGridBtn="moduleTab === 3"
      @updateLayoutOption="viewGrid = !viewGrid" 
      @updateViewFavOnly="viewFavOnly = !viewFavOnly"/>

    <!-- Select Visualization Type modal -->
    <div>
      <SelectVisTypeDlg
        v-model="vis_type_modal"
        @closeVisTypeSelect="closeVisTypeSelect"
      />
      <SelectDataSetDlg
        v-model="dataset_modal"
        :chartType="chart_type"
        @cancelDatasetSelect="cancelDatasetSelect"
        @datasetSelectBack="datasetSelectBack"
      />
      <!-- v-if is necessary othewise it won't reload based on Id change-->
      <DatasetDetailDlg
        v-if="dataset_detail_modal"
        v-model="dataset_detail_modal"
        :id="data_set_id"
        :chartType="chart_type"
      />
      <NewDB :showNewDB="showNewDB" @cancel="cancelNewDB" />
      <NewFromDB
        :showNewFromDB="showNewFromDB"
        @cancel="cancelNewFromDB"
        @createDB="showCreateDB"
        @goToDatasets="goToDatasets"
      />
      <NewFromFile
        :showNewFromFile="showNewFromFile"
        @cancel="cancelNewFromFile"
        @showAdvance="showAdvance"
        @save_file="saveFile"
      />
      <NewFromFileAdvance
        :showNewFromFileAdvance="showNewFromFileAdvance"
        @cancel="cancelNewAdvance"
        @save="saveNewAdvance"
      />
    </div>

    <v-container fluid>
      <v-tabs
        v-model="moduleTab" color="white" slider-color="primary"
      >
        <v-tab>PROJECTS</v-tab>
        <v-tab>CHARTS</v-tab>
        <v-tab>DASHBOARDS</v-tab>
        <v-tab>DATASETS</v-tab>
      </v-tabs>

      <v-tabs-items v-model="moduleTab">
        <v-tab-item> <!-- Projects -->
          <div class="mb-10 pt-3 mt-3 section">
            <div class="d-flex flex-row">
              <div class="mr-auto">
                <h4 class="pl-2">
                  <v-icon>mdi-chevron-right</v-icon>
                  Projects
                </h4>
              </div>
              <div class="d-flex align-center">
                <div v-if="viewGrid" class="overline mr-2">sort by</div>
                <v-select
                  v-if="viewGrid"
                  v-model="curSortField"
                  :items="sortFields"
                  item-text="txt"
                  item-value="field"
                  label="Sort"
                  solo
                  outlined
                  hide-details="true"
                  class="mr-2"
                  style="width: 150px"
                  v-on:change="getAll"
                ></v-select>

                <v-btn dense color="primary" class="standard-btn" :to="{name: 'Projects-New'}">
                  New
                </v-btn>
                <v-btn outlined color="white" class="standard-btn" :to="{name: 'Projects-Index'}">
                  See All
                </v-btn>
              </div>
            </div>

            <!--  Grid View: Projects  -->
            <v-container v-if="viewGrid && accordion.projects" fluid>
              <v-row v-if="loadingProjects" class="container-row-resolution py-5 d-flex justify-center">
                <v-progress-circular
                  indeterminate
                  color="primary"
                ></v-progress-circular>
              </v-row>
              <v-row v-else-if="getModels(projectCollection, viewFavOnly).length" class="container-row-resolution mt-2">
                <BoxItem 
                  v-for="item in getModels(projectCollection, viewFavOnly)" 
                  v-bind:key="item.id" 
                  :item="item" 
                  :class-name="`project`" 
                  :title-field="`project_title`" 
                  @toggled="toggleFav"
                  @clickInfoItem="clickInfoProject"
                  @clickItem="clickProject"/>
              </v-row>
              <v-row v-else class="container-row-resolution pt-5 d-flex justify-center">
                <p>No projects found ...</p>
                <v-progress-circular
                  indeterminate
                  color="primary"
                  v-if="loadingProjects"
                ></v-progress-circular>
              </v-row>
            </v-container>

            <!--  List View: Projects  
              convert to module to align with project page
            -->
            <v-container v-if="!viewGrid && accordion.projects" fluid>
              <v-data-table
                id="tblProjects"
                v-model="selected"
                :items="getModels(projectCollection, viewFavOnly)"
                :items-per-page="$pageItemCount"
                :headers="projectHeaders"
                :search="search"
                @click:row="clickProject"
                show-select
                item-key="id"
                class="mt-3"
                :hide-default-footer="true"
                :header-props="{sortIcon: 'mdi-chevron-down'}"
              >
              <template v-slot:top="{ pagination, options, updateOptions }">
                <v-data-footer 
                  :pagination="pagination" 
                  :options="options"
                  @update:options="updateOptions"
                  items-per-page-text="$vuetify.dataTable.itemsPerPageText"/>
              </template>
                <template v-slot:[`header.favorite`]="{header}">
                  <v-icon v-if="header.favorite">mdi-star</v-icon>
                  <v-icon v-else>mdi-star-outline</v-icon>
                </template>
                <template #[`item.favorite`]="{ item }">
                  <div @click.stop="toggleFav(item, 'project')" class="actions">
                    <v-btn icon width="24" v-tippy content="Favorite">
                      <v-icon v-if="item.favorite">mdi-star</v-icon>
                      <v-icon v-else>mdi-star-outline</v-icon>
                    </v-btn>
                  </div>
                </template>
                <template #[`item.name`]="{ item }">
                  <div @click.stop class="title">
                    <a :href="`/#/projects/${item.id}`">{{item.project_title}}</a>
                  </div>
                  <span>{{item.description}}</span>
                </template>
                <template #[`item.created_by.username`]="{ item }">
                  <div v-if="item.created_by" class="actions">
                    {{item.created_by.username}}
                  </div>
                </template>
                <template #[`item.action`]="{ item }">
                  <RowAction 
                    :item="item" 
                    :disabledList="ProjectRowActions"
                    @clickItem="clickProject" 
                    @deleteItem="deleteProject"/>
                </template>
              </v-data-table>
            </v-container>
          </div>
        </v-tab-item>
        <v-tab-item> <!-- Charts -->
          <div class="mb-10 pt-3 mt-3 section section">
            <div class="d-flex flex-row">
              <div class="mr-auto">
                <h4 class="pl-2">
                  <!-- <v-btn icon class="mr-0" @click="accordion.charts = !accordion.charts"> -->
                    <!-- <v-icon v-if="accordion.charts">mdi-chevron-down</v-icon> -->
                    <!-- <v-icon v-else>mdi-chevron-right</v-icon> -->
                  <!-- </v-btn> -->
                  <v-icon>mdi-chevron-right</v-icon>
                  Charts
                </h4>
              </div>
              <div class="d-flex align-center">
                <div v-if="viewGrid" class="overline mr-2">sort by</div>
                <v-select
                  v-if="viewGrid"
                  v-model="curSortField"
                  :items="sortFields"
                  item-text="txt"
                  item-value="field"
                  label="Sort"
                  solo
                  outlined
                  hide-details="true"
                  class="mr-2"
                  style="width: 150px"
                  v-on:change="getAll"
                ></v-select>

                <v-btn dense color="primary" class="standard-btn" @click="clickNewChart()">
                  New
                </v-btn>
                <v-btn outlined color="white" class="standard-btn" :to="{name: 'Charts-Index'}">
                  See All
                </v-btn>
              </div>
            </div>

            <!--  Grid View: Charts  -->
            <v-container v-if="viewGrid && accordion.charts">
              <v-row v-if="loadingCharts" class="container-row-resolution py-5 d-flex justify-center">
                <v-progress-circular
                  indeterminate
                  color="primary"
                ></v-progress-circular>
              </v-row>
              <v-row  v-else-if="getModels(chartCollection, viewFavOnly).length" class="container-row-resolution mt-2">
                <BoxItem 
                  v-for="item in getModels(chartCollection, viewFavOnly)" 
                  v-bind:key="item.id"
                  :item="item" 
                  :class-name="`slice`" 
                  :title-field="`slice_name`" 
                  @toggled="toggleFav"
                  @clickItem="clickChart"/>
              </v-row>
              <v-row v-else class="container-row-resolution pt-5 d-flex justify-center">
                <p>No chart found ...</p>
              </v-row>
            </v-container>

            <!--  List View: Charts  -->
            <v-container v-if="!viewGrid && accordion.charts" 
              fluid 
              :class="{'row-list': viewGrid }"
            >
              <v-data-table
                id="tblCharts"
                v-model="selected"
                :items="getModels(chartCollection, viewFavOnly)"
                :items-per-page="$pageItemCount"
                :headers="chartHeaders"
                :search="search"
                @click:row="clickChart"
                show-select
                item-key="id"
                class="mt-3"
                :hide-default-footer="true"
              >
              <template v-slot:top="{ pagination, options, updateOptions }">
                <v-data-footer 
                  :pagination="pagination" 
                  :options="options"
                  @update:options="updateOptions"
                  items-per-page-text="$vuetify.dataTable.itemsPerPageText"/>
              </template>
                <template v-slot:[`header.favorite`]="{header}">
                  <v-icon v-if="header.favorite">mdi-star</v-icon>
                  <v-icon v-else>mdi-star-outline</v-icon>
                </template>
                <template #[`item.favorite`]="{ item }">
                  <div @click.stop="toggleFav(item, 'slice')" class="actions">
                    <v-btn icon width="24" v-tippy content="Favorite">
                      <v-icon v-if="item.favorite">mdi-star</v-icon>
                      <v-icon v-else>mdi-star-outline</v-icon>
                    </v-btn>
                  </div>
                </template>
                <template #[`item.slice_name`]="{ item }">
                  <div @click.stop class="actions">
                    <a href="#">{{item.slice_name}}</a>
                  </div>
                </template>
                <template #[`item.created_by.username`]="{ item }">
                  <div v-if="item.created_by" class="actions">
                    {{item.created_by.username}}
                  </div>
                </template>
                <template #[`item.action`]="{ item }">
                  <RowAction 
                    :item="item" 
                    :disabledList="ChartRowActions"
                    @clickItem="clickChart" 
                    @deleteItem="deleteChart"/>
                </template>
              </v-data-table>
            </v-container>
          </div>
        </v-tab-item>
        <v-tab-item> <!-- Dashboards -->
          <div class="mb-10 pt-3 mt-3 section">
            <div class="d-flex flex-row">
              <div class="mr-auto">
                <h4 class="pl-2">
                  <v-btn icon class="mr-0" @click="accordion.dashboards = !accordion.dashboards">
                    <v-icon v-if="accordion.dashboards">mdi-chevron-down</v-icon>
                    <v-icon v-else>mdi-chevron-right</v-icon>
                  </v-btn>
                  Dashboard
                </h4>
              </div>
              <div class="d-flex align-center">
                <div v-if="viewGrid" class="overline mr-2">sort by</div>
                <v-select
                  v-if="viewGrid"
                  v-model="curSortField"
                  :items="sortFields"
                  item-text="txt"
                  item-value="field"
                  label="Sort"
                  solo
                  outlined
                  hide-details="true"
                  class="mr-2"
                  style="width: 150px"
                  v-on:change="getAll"
                ></v-select>

                <v-btn dense color="primary" class="standard-btn" :to="{name: 'Dashboards-New'}">
                  New
                </v-btn>
                <v-btn outlined color="white" class="standard-btn" :to="{name: 'Dashboards-Index'}">
                  See All
                </v-btn>
              </div>
            </div>

            <!--  Grid View: Dashboard  -->
            <v-container v-if="viewGrid && accordion.dashboards" fluid>              
              <v-row v-if="loadingDashboards" class="container-row-resolution py-5 d-flex justify-center">
                <v-progress-circular
                  indeterminate
                  color="primary"
                ></v-progress-circular>
              </v-row>
              <v-row v-else-if="getModels(dashboardCollection, viewFavOnly).length" class="container-row-resolution mt-2">
                <BoxItem 
                  v-for="item in getModels(dashboardCollection, viewFavOnly)"
                  v-bind:key="item.id" 
                  :item="item"
                  :class-name="`dashboard`" 
                  :title-field="`dashboard_title`"
                  @toggled="toggleFav"
                  @clickItem="clickDashboard"/>
              </v-row>
              <v-row v-else class="container-row-resolution pt-5 d-flex justify-center">
                <p>No dashboard found ...</p>
              </v-row>
            </v-container>

            <!--  List View: Dashboard  -->
            <v-container v-if="!viewGrid && accordion.dashboards" fluid>
              <v-data-table
                  id="tblDashboards"
                  v-model="selected"
                  :items="getModels(dashboardCollection, viewFavOnly)"
                  :items-per-page="$pageItemCount"
                  :headers="dashboardHeaders"
                  :search="search"
                  @click:row="clickDashboard"
                  show-select
                  item-key="id"
                  class="mt-3"
                  :hide-default-footer="true"
              >
                <template v-slot:top="{ pagination, options, updateOptions }">
                  <v-data-footer 
                    :pagination="pagination" 
                    :options="options"
                    @update:options="updateOptions"
                    items-per-page-text="$vuetify.dataTable.itemsPerPageText"/>
                </template>
                <template v-slot:[`header.favorite`]="{header}">
                  <v-icon v-if="header.favorite">mdi-star</v-icon>
                  <v-icon v-else>mdi-star-outline</v-icon>
                </template>
                <template #[`item.favorite`]="{ item }">
                  <div @click.stop="toggleFav(item, 'dashboard')" class="actions">
                    <v-btn icon width="24" v-tippy content="Favorite">
                      <v-icon v-if="item.favorite">mdi-star</v-icon>
                      <v-icon v-else>mdi-star-outline</v-icon>
                    </v-btn>
                  </div>
                </template>
                <template #[`item.name`]="{ item }">
                  <div @click.stop class="actions">
                    <a href="#">{{item.dashboard_title}}</a>
                  </div>
                  <span>{{item.description}}</span>
                </template>
                <template #[`item.created_by.username`]="{ item }">
                  <div v-if="item.created_by" class="actions">
                    {{item.created_by.username}}
                  </div>
                </template>
                <template #[`item.changed_by.username`]="{ item }">
                  <div v-if="item.changed_by" class="actions">
                    {{item.changed_by.username}}
                  </div>
                </template>
                <template #[`item.action`]="{ item }">
                  <RowAction 
                    :item="item" 
                    :disabledList="DashboardRowActions"
                    @clickItem="clickDashboard" 
                    @deleteItem="deleteDashboard"/>
                </template>
              </v-data-table>
            </v-container>
          </div>
        </v-tab-item>
        <v-tab-item> <!-- Datasets -->
          <div class="mb-10 pt-3 mt-3 section">
            <div class="d-flex flex-row">
              <div class="mr-auto" style="padding-left: 16px">
                <h4 class="pl-2">
                  <v-icon>mdi-chevron-right</v-icon>
                  Datasets
                </h4>
              </div>
              <div class="d-flex align-center">
                <v-btn
                  dense
                  color="primary"
                  class="dropdown-text-btn standard-btn"
                >
                  New
                </v-btn>
                <v-menu offset-y left z-index="1000">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn class="dropdown-btn" color="primary" v-bind="attrs" v-on="on">
                      <v-icon size="20">mdi-chevron-down</v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item-group>
                      <v-list-item
                        dense
                        v-for="(item, index) in datasetNewMenuItems"
                        :key="index"
                        @click="clickNewDataset(item)"
                      >
                        <v-list-item-content>
                          <v-list-item-title v-text="item.text"></v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list-item-group>
                  </v-list>
                </v-menu>
                <v-btn outlined color="white" class="standard-btn ml-4" :to="{name: 'Datasets-Index'}">
                  See All
                </v-btn>
              </div>
            </div>

            <!--  Grid View: Datasets  -->
            <!-- <v-container v-if="viewGrid && accordion.datasets" fluid>
              <v-row v-if="loadingDatasets" class="container-row-resolution py-5 d-flex justify-center">
                <v-progress-circular
                  indeterminate
                  color="primary"
                ></v-progress-circular>
              </v-row>
              <v-row  v-else-if="getModels(datasetCollection, viewFavOnly).length" class="container-row-resolution mt-2">
                <BoxItem 
                  v-for="item in getModels(datasetCollection, viewFavOnly)"
                  v-bind:key="item.id" 
                  :item="item"
                  :class-name="`dataset`"
                  :title-field="`table_name`"
                  @toggled="toggleFav"
                  @clickItem="clickDataset"/>
              </v-row>
              <v-row v-else class="container-row-resolution pt-5 d-flex justify-center">
                <p>No dataset found ...</p>
              </v-row>
            </v-container> -->

            <!--  List View: Datasets  -->
            <!-- <v-container v-if="!viewGrid && accordion.datasets" fluid> -->
            <v-container fluid>
              <v-data-table
                id="tblDatasets"
                v-model="selected"
                :items="getModels(datasetCollection, viewFavOnly)"
                :items-per-page="$pageItemCount"
                :headers="datasetHeaders"
                :search="search"
                @click:row="clickDataset"
                show-select
                item-key="id"
                class="mt-3"
                :hide-default-footer="true"
              >
                <template v-slot:top="{ pagination, options, updateOptions }">
                  <v-data-footer 
                    :pagination="pagination" 
                    :options="options"
                    @update:options="updateOptions"
                    items-per-page-text="$vuetify.dataTable.itemsPerPageText"/>
                </template>
                <template v-slot:[`header.favorite`]="{header}">
                  <v-icon v-if="header.favorite">mdi-star</v-icon>
                  <v-icon v-else>mdi-star-outline</v-icon>
                </template>
                <template #[`item.favorite`]="{ item }">
                  <div @click.stop="toggleFav(item, 'dataset')" class="actions">
                    <v-btn icon width="24" v-tippy content="Favorite">
                      <v-icon v-if="item.favorite">mdi-star</v-icon>
                      <v-icon v-else>mdi-star-outline</v-icon>
                    </v-btn>
                  </div>
                </template>
                <template #[`item.table_name`]="{ item }">
                  <div @click.stop class="actions">
                    <a @click="openDatasetViz(item)">{{ item.table_name }}</a>
                    <!-- <a href="#">{{item.table_name}}</a> -->
                  </div>
                </template>
                <template #[`item.database.database_name`]="{ item }">
                  <div v-if="item.database" class="actions">
                    <v-chip color="primary">{{item.database.database_name}}</v-chip>
                  </div>
                </template>
                <template #[`item.action`]="{ item }">
                  <RowAction 
                    :item="item" 
                    :disabledList="DatasetRowActions"
                    @clickItem="clickDataset" 
                    @deleteItem="deleteDataset"/>
                </template>
              </v-data-table>
            </v-container>
          </div>
        </v-tab-item>
      </v-tabs-items>
    </v-container>
    <!-- Project Detail modal -->
    <v-dialog
        content-class="custom-dialog"
        v-model="detailModalVisible"
        max-width="900"
        persistent
        scrollable
        max-height="300"
      >
        <DetailModal
          :project="curProject"
          :visible="detailModalVisible"
          @closeProjectDialog="closeProjectDialog"
        />
      </v-dialog>
  </div>
</template>

<script>
import ModuleHeader from "@/views/_partials/ModuleHeader";
import BoxItem from "./_partials/BoxItem";
import RowAction from "@/views/_partials/RowAction";
import SelectVisTypeDlg from "@/views/charts/modals/SelectVisTypeDlg";
import SelectDataSetDlg from "@/views/charts/modals/SelectDataSetDlg";
import DatasetDetailDlg from "@/views/charts/modals/DatasetDetailDlg";
import NewDB from "@/components/modal/NewDB.vue";
import NewFromDB from "@/components/modal/NewFromDB.vue";
import NewFromFile from "@/components/modal/NewFromFile.vue";
import NewFromFileAdvance from "@/components/modal/NewFromFileAdvance.vue";
import DetailModal from './projects/modals/DetailModal.vue'

import { 
  GET_PROJECT_ALL,  
  GET_CHART_ALL,
  GET_DASHBOARD_ALL,
  GET_DATASET_ALL,  
  DELETE_PROJECT,
  DELETE_CHART,
  DELETE_DASHBOARD,
  DELETE_DATASET,
  SET_FAV,
  UNSET_FAV,
  GET_CHART_BY_ID,
  // POST_CHART_FORM,
  SAVE_FORM_DATA,
  UPLOAD_FILE,
  GET_PROJECT_BY_ID,
} from "@/store/actionType";

import { clickItem as clickChart } from "@/utils/chartUtils";
import { openDatasetViz } from "@/views/datasets/utils"

import {
  ChartRowActions,
  ProjectRowActions,
  DashboardRowActions,
  DatasetRowActions,
} 
from "@/utils/listRowActions";

const recentCount = 15;
const queryColumns = [
  "id",
  "changed_by.username",
  'created_by.first_name',
  'created_by.last_name',
  'created_by.username',
  "changed_on_utc",
  "changed_on_delta_humanized",
  "form_data_key",
  "datasource_id",
];
const queryTmpl = {
  "order_direction": "desc",
  "page_size": recentCount
}

export default {
  name: "homePage",
  components: {
    BoxItem,
    RowAction,
    ModuleHeader,
    SelectVisTypeDlg,
    DatasetDetailDlg,
    SelectDataSetDlg,
    NewDB,
    NewFromDB,
    NewFromFile,
    NewFromFileAdvance,
    DetailModal,
  },
  data: () => ({
    ChartRowActions,
    ProjectRowActions,
    DashboardRowActions,
    DatasetRowActions,
    sortFields: [
      {txt: "Date", field: "changed_on_utc"},
    ],
    curSortField: 'changed_on_utc',
    viewGrid: true,
    viewFavOnly: false,
    selected: [],
    search: '',
    projectHeaders: [
      { text: "Favorite", value: "favorite", width: 75 },
      { text: "Name", value: "name" },
      { text: "Members", value: "member_count" },
      { text: "Slices", value: "slice_count" },
      { text: "Dashboards", value: "dashboard_count" },
      { text: "Last Modified", value: "changed_on_delta_humanized" },
      { text: "Created By", value: "created_by.username" },
      { text: "Actions", value: "action", width: 200, sortable: false },
    ],
    projectTab: null,
    moduleTab: null,
    projectCollection: [], // use one variable for Recent + Favorites for consistency
    loadingProjects: false,
    chartHeaders: [
      { text: "Favorite", value: "favorite", width: 75, sortable: false },
      { text: "Name", value: "slice_name" },
      { text: "Visualization Type", value: "viz_type" },
      { text: "Datasource", value: "datasource_type" },
      { text: "Last Modified", value: "changed_on_delta_humanized" },
      { text: "Created By", value: "created_by.username" },
      { text: "Actions", value: "action", width: 200, sortable: false },
    ],
    chartTab: null,
    chartCollection: [],
    loadingCharts: false,
    dashboardHeaders: [
      { text: "Favorite", value: "favorite", width: 75, sortable: false },
      { text: "Name", value: "name" },
      { text: "Slices", value: "slice_count" },
      { text: "Last Modified", value: "changed_on_delta_humanized" },
      { text: "Last Modified By", value: "changed_by.username" },      
      { text: "Created By", value: "created_by.username" },      
      { text: "Actions", value: "action", width: 200, sortable: false },
    ],
    dashboardTab: null,
    loadingDashboards: false,
    dashboardCollection: [],
    datasetHeaders: [
      { text: "Favorite", value: "favorite", width: 75, sortable: false },
      { text: "Name", value: "table_name" },
      { text: "Type", value: "datasource_type" },
      { text: "Database", value: "database.database_name" },
      { text: "Schema", value: "schema"},
      { text: "Owners", value: "owners"},
      { text: "Last Modified", value: "changed_on_delta_humanized" },
      { text: "Actions", value: "action", width: 200, sortable: false },
    ],
    datasetTab: null,
    datasetCollection: [],
    loadingDatasets: false,
    datasetNewMenuItems: [
      { text: "From Database", action: "from_db" },
      { text: "From File", action: "from_file" },
      { text: "Custom SQL", action: "custom_sql" },
      { text: "New Database", action: "new_db" },
    ],
    accordion: {
      projects: true,
      charts: true,
      dashboards: true,
      datasets: true,
    },
    vis_type_modal: false,
    dataset_modal: false,
    // TODO: reset these
    dataset_detail_modal: false,
    chart_type: null,
    //show New database dialog
    showNewDB: false,
    //show New from database dialog
    showNewFromDB: false,
    //show New from file dialog
    showNewFromFile: false,
    //show New from file advance dialog
    showNewFromFileAdvance: false,
    // project vars/modals
    curProject: null,
    detailModalVisible: false,
  }),  
  methods: {
    //imports
    clickChart,
    openDatasetViz,
    datasetSelectBack () {
      // go back to chart type select
      console.log('go back')
      this.vis_type_modal = true;
      this.dataset_detail_modal = false;
      this.dataset_modal = false;
    },
    cancelDatasetSelect() {
      console.log('close dialog')
      this.chart_type=null;
      this.vis_type_modal = false;
      this.dataset_modal = false;
    },
    closeVisTypeSelect(viz_type) {
      console.log('vis type', viz_type)
      this.chart_type=viz_type;
      this.vis_type_modal = false;
      this.dataset_modal = true;
    },
    getRecentModels(arr) {
      const filteredArr = arr.filter(function(item) {
        return item.recent === true;
      });
      return filteredArr;
    },
    getFavModels(arr) {
      return arr.filter(function(item) {
        return item.favorite === true;
      });
    },
    getModels(arr, viewFavOnly) {
      return viewFavOnly ? this.getFavModels(arr) : this.getRecentModels(arr)
    },
    getListQuery(className, titleField) {      
      let columns = [ 
        ...queryColumns,
        titleField, 
      ];

      if (className==='projects' || className==='project'){
        columns.push(
          'description',
          'members.username',
          'dashboard_count',
          'slice_count',
          'member_count',
        );
      } else if (className==='chart'){
        columns.push(
          'viz_type', 
          'datasource_type',
        );
      } else if (className==='dashboard'){
        columns.push(
          'slice_count',
        );
      } else if (className==='dataset'){
        columns.push(
          'database.database_name',
          'datasource_type',
        );
      }

      const q= {
        "columns": columns,
        "order_column": this.curSortField,
        ...queryTmpl
      };
      return q;
    },
    getFavListQuery(className, titleField) {
      let q = this.getListQuery(className, titleField);
      q['filters'] = [
        { 
          "col": "id", 
          "opr": className+"_is_favorite", 
          "value": "!t"
        }
      ];
      return q;
    },
    loadProjectSection() {
      // Load Recent Project
      let recentArr=[];
      this.loadingProjects = true;
      this.$store
        .dispatch(GET_PROJECT_ALL, this.getListQuery('projects', "project_title"))
        .then((r) => {
          recentArr  = r.data?.result;
          // Load Favorite Projects
          return this.$store
            .dispatch(GET_PROJECT_ALL, this.getFavListQuery("project", "project_title"));
        })
        .then((favResult) => {
          this.projectCollection = this.mergeFavItems(recentArr, favResult);
        })
        .catch((r) => {
          console.error("We have error:", r);
        })
        .finally(() => {
          this.loadingProjects = false;
        })
    },
    loadChartSection() {
      // Load Recent Chart
      let recentArr=[];
      this.loadingCharts = true;
      this.$store
        .dispatch(GET_CHART_ALL, this.getListQuery('chart', "slice_name"))
        .then((r) => {
          recentArr  = r.data.result;          

          // Load Favorite Charts
          return this.$store
            .dispatch(GET_CHART_ALL, this.getFavListQuery("chart", "slice_name"));
        })
        .then((favResult) => {
          this.chartCollection = this.mergeFavItems(recentArr, favResult);
        })
        .catch((r) => {
          console.error("We have error:", r);
        })
        .finally(() => {
          this.loadingCharts = false;
        })
    },
    loadDashboardSection() {
      // Load Recent Dashboard
      let recentArr=[];
      this.loadingDashboards = true;
      this.$store
        .dispatch(GET_DASHBOARD_ALL, this.getListQuery('dashboard', "dashboard_title"))
        .then((r) => {
          recentArr  = r.data.result;          

          // Load Favorite Dashboards
          return this.$store
            .dispatch(GET_DASHBOARD_ALL, this.getFavListQuery("dashboard", "dashboard_title"));
        })
        .then((favResult) => {
          this.dashboardCollection = this.mergeFavItems(recentArr, favResult);
        })
        .catch((r) => {
          console.error("We have error:", r);
        })
        .finally(() => {
          this.loadingDashboards = false;
        })
    },
    loadDatasetSection() {
      // Load Recent Dataset
      let recentArr=[];
      this.loadingDatasets = true;
      this.$store
        .dispatch(GET_DATASET_ALL, this.getListQuery('dataset', "table_name"))
        .then((r) => {
          recentArr  = r.data.result;          

          // Load Favorite Datasets
          return this.$store
            .dispatch(GET_DATASET_ALL, this.getFavListQuery("dataset", "table_name"));
        })
        .then((favResult) => {
          this.datasetCollection = this.mergeFavItems(recentArr, favResult);
        })
        .catch((r) => {
          console.error("We have error:", r);
        })
        .finally(() => {
          this.loadingDatasets = false;
        })
    },
    mergeFavItems(recentArr, favResult) {
      let favIds = favResult.data.ids;

      for (let index in favResult.data.result) {
        favResult.data.result[index]['favorite'] = true;
      }
      for (let index in recentArr) {
        const _index = favIds.indexOf(recentArr[index].id);
        if ( _index === -1) {
          recentArr[index]['favorite'] == false;
          recentArr[index]['recent'] = true;
          favResult.data.result.push(recentArr[index]);
        } else {
          favResult.data.result[_index]['recent'] = true;
        }
      }
      return favResult.data.result; 
    },
    getAll() {            
      this.loadProjectSection();
      this.loadChartSection();
      this.loadDashboardSection();
      this.loadDatasetSection();  
    },

    clickProject(item) {
      this.$router.push({ name: 'Projects-Detail', params: { id: item.id } });
    },
    clickInfoProject(item) {
      console.log('item', item)
      let payload={}
      this.$store.dispatch(GET_PROJECT_BY_ID, item.id)
        .then((res) => {
            payload=res.data.result
            this.curProject = payload;
            this.detailModalVisible = true;
          })
        .catch( (error) => {
          console.error("proejct api eror", error)
        })
    },
    closeProjectDialog() {
      this.detailModalVisible = false; 
      this.curProject=null;
    },

    clickNewChart() {
      this.vis_type_modal = true;
    },

    clickNewDataset(item) {
      switch (item.action) {
        case "custom_sql":
          this.$router.push("/sql-page");
          break;
        case "from_db":
          this.showNewFromDB = true;
          break;
        case "from_file":
          this.showNewFromFile = true;
          break;
        case "new_db":
          this.showNewDB = true;
          break;
      }
    },

    clickDashboard(item) {
      this.$router.push({ name: 'Dashboards-Detail', params: { id: item.id } });
    },

    clickDataset(item) {
      this.$router.push({ name: 'Datasets-Detail', params: { id: item.id } });
    },

    deleteProject(item) {
      this.$store
        .dispatch(DELETE_PROJECT, item.id)
        .then(() => {
          this.loadProjectSection();
        })
    },

    deleteChart(item) {
      this.$store
        .dispatch(DELETE_CHART, item.id)
        .then(() => {
          this.loadChartSection();
        })
    },

    deleteDashboard(item) {
      this.$store
        .dispatch(DELETE_DASHBOARD, item.id)
        .then(() => {
          this.loadDashboardSection();
        })
    },

    deleteDataset(item) {
      this.$store
        .dispatch(DELETE_DATASET, item.id)
        .then(() => {
          this.loadDatasetSection();
        })
    },

    /**
     * close new from DB and show new DB
     */
     showCreateDB() {
      this.showNewFromDB = false;
      this.showNewDB = true;
    },

    /**
     * go to datasets page
     */
     goToDatasets() {
      this.showNewFromDB = false;
      this.$router.push("/datasets");
    },

    /**
     * cancel new DB
     */
     cancelNewDB() {
      this.showNewDB = false;
    },
    /**
     * cancel new from DB
     */
    cancelNewFromDB() {
      this.showNewFromDB = false;
    },
    /**
     * cancel new from file
     */
    cancelNewFromFile() {
      this.showNewFromFile = false;
    },
    /**
     * cancel new from file advance
     */
    cancelNewAdvance() {
      this.showNewFromFileAdvance = false;
    },
    /**
     * save new from file advance
     */
    saveNewAdvance(val) {
      this.advance_file = val;
      this.showNewFromFileAdvance = false;
    },
    /**
     * show new from file advance
     */
    showAdvance() {
      this.showNewFromFileAdvance = true;
    },
    
    /**
     * save from file
     */
     saveFile(data) {
      if (this.advance_file) {
        data.append("index_col", this.advance_file.index_col);
        data.append("skip_rows", this.advance_file.skip_rows);
        data.append("n_rows", this.advance_file.n_rows);
        data.append("mangle_dupe_cols", this.advance_file.mangle_dupe_cols);
        data.append("skip_spaces", this.advance_file.skip_spaces);
        data.append("skip_blank_lines", this.advance_file.skip_blank_lines);
        data.append("dataframe", this.advance_file.dataframe);
        data.append("use_columns", this.advance_file.use_columns);
        data.append("index_label", this.advance_file.index_label);
        data.append("decimal_char", this.advance_file.decimal_char);
        data.append("parse_dates", this.advance_file.parse_dates);
        data.append("infer_datetime", this.advance_file.infer_datetime);
        data.append("null_values", this.advance_file.null_values);
      }
      this.$store.dispatch(UPLOAD_FILE, data).then((res) => {
        this.showNewFromFile = false;
      });
    },

    toggleFav(item, className) {
      const param = {
        "id": item.id,
        "className": className
      };

      let collection = [];
      if (className === 'project') {
        collection = this.projectCollection;  
      } else if (className === 'slice') {
        collection = this.chartCollection;  
      } else if (className === 'dashboard') {
        collection = this.dashboardCollection;
        param.className = "Dashboard";
      } else if (className === 'dataset') {
        collection = this.datasetCollection;  
      }

      let itemIndex = collection.indexOf(item);

      if (item.favorite === true) {
        this.$store
          .dispatch(UNSET_FAV, param)
          .then((r) => {
            item.favorite = false;            
            let editedItem = { ...item};
            collection.splice(itemIndex, 1, editedItem)
          })
          .catch((r) => {
            console.error("We have error: ", r);
          });
      } else {
        this.$store
          .dispatch(SET_FAV, param)
          .then((r) => {
            item.favorite = true;
            let editedItem = { ...item};
            collection.splice(itemIndex, 1, editedItem)
          })
          .catch((r) => {
            console.error("We have error: ", r);
          });
      }
    }
  },
  // TODO: These are hacks to get favorites to work
  // Add function to ModuleHeader Favorite button 
    // to reroute to '/' if on "favorites"
    // and likewise
  beforeUpdate() {
    if (this.$route.name === "Favorites") {
      this.viewFavOnly=true;
    } else {
      this.viewFavOnly=false;
    }
  },
  mounted() {
    if (this.$route.name === "Favorites") {
      this.viewFavOnly=true;
    }
    this.getAll();
  },
};
</script>

<style scoped>
@font-face {
  font-family: Roboto;
  src: url("../assets/fonts/Roboto-Regular.woff") format("opentype");
}

/* .non-clickable {
cursor: auto;
} */
.overline {
  letter-spacing: 1px !important;
  color: #a2a2a2;
}
.row-list{
  background: #242424;
}
.section{
  background: #1E1E1E;
}

.standard-btn{
  font-family: Roboto,serif;
  font-size: 14px;
  height: 26px !important;
  border-radius: 2px !important;
  margin-right: 5px;
  padding: 13px 27px;
  width: 106px;
  cursor: pointer;
  text-align: center;
  font-style: normal;
  font-weight: normal;
  color: rgba(255,255,255,1);
}

.dropdown-btn {
  min-width: 32px !important;
  padding: 0 !important;
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
  height: 32px !important;
}
.dropdown-text-btn {
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
  margin-right: 1px;
  height: 32px !important;
}
</style>
