
<template >
  <v-row 
    align-content="start"
    dense
    no-gutters
  >
    <v-chip-group
      dense
    >
      <v-chip
        v-for="color in subColors"
        :key="color.id"
        :color="color"
        label
      />
    </v-chip-group>
  </v-row>
</template>   

<script>

import schemes from "../../../../../packages/ui-color-pallates/categorical/superset.js"


export default {
  data() {
    return {
      color_schemes: schemes,
      color_pallate: schemes[0],
    }
  },
  props: {
    item: {
      type: Object,
      default() {
        return {};
      }
    },
    filters: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  computed: {
    subColors(){ return this.item.colors }
  }

}
</script>

<style lang="scss" scoped>

::v-deep .v-chip {
  height: 4px;
  padding: 4px ;
}

</style>
