<template>
  <div class="main">
    <!-- {{ Object.keys(vis_data) }} -->
    <!-- {{ viz_type}} -->
    <div class="main-header d-flex">
      <!-- Chart Title -->
      <div class="main-title">
        <div class="d-flex">
          <div class="main-title-text">
            {{ slice_name ? slice_name : "Chart Title" }}
          </div>
          <v-icon class="ml-4" @click="showEditChartName">mdi-pencil</v-icon>
          <!-- <div > -->
          <v-chip class="ml-4 mt-auto mb-auto" color="#F5BD00" x-small
            v-if="unsavedForm"
          >
            Unsaved
          </v-chip>
          <!-- </div> -->
        </div>
        <div class="d-flex">
          <div
            :style="active_query ? { 'color': '#F5BD00' } : { 'color': '#1bbab9' }"
          >Query Time: {{ this.query_time }}</div>
        </div>
      </div>
      <!-- Chart Actions -->
      <div class="main-header-action d-flex ml-auto">
        <!-- Table Options -->
        <div v-if="viz_type==='table'" class="main-header-action mr-2 d-flex"
          
        >
        <!-- style="background: red;" -->
          <!-- <div>
            <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
                <!- - @click="showSearch=true" - ->
              <v-icon
                v-bind="attrs" v-on="on" 
                color="grey_text"
              >mdi-pencil</v-icon>
            </template>
            <span>Edit Table Data</span>
          </v-tooltip>
          </div>
          <div> -->
          <!-- Main area search function -->
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs" v-on="on"
                v-show="showSearch===false" 
                color="grey_text"
                @click="showSearch=true"
              >mdi-magnify</v-icon>
            </template>
            <span>Search Table Fields</span>
          </v-tooltip>
          <v-text-field v-show="showSearch===true" 
            v-model="mainSearchText"
            dense class="main-header-action  mt-3"
            filled
            prepend-icon=mdi-magnify
            append-icon=mdi-close
            @click:append="showSearch=false; mainSearchText=null"
            @keydown.esc="showSearch=false; mainSearchText=null"
          > 
          </v-text-field>
          <!-- </div> -->
        </div>
        <!-- copy data -->
        <v-btn dense class="main-header-action" icon>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs" v-on="on"
                color="grey_text"
                @click="copyData"
              >mdi-content-copy</v-icon>
            </template>
            <span>Copy Data</span>
          </v-tooltip>
        </v-btn>
        <!-- Export data menu -->
        <v-menu :offset-y="true">
          <template v-slot:activator="{ on, attrs }">
            <v-btn dense class="main-header-action"
              color="grey_text"
              v-bind="attrs"
              v-on="on"
              icon
            >
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs" v-on="on"
                >mdi-export</v-icon>
              </template>
              <span>Export Data to File</span>
            </v-tooltip>
              <!-- <v-icon>mdi-export</v-icon> -->
            </v-btn>
          </template>
          <v-list dense>
            <v-list-item
              @click="exportData('csv')"
            >
              <v-list-item-title>Export CSV</v-list-item-title>
            </v-list-item>
            <v-list-item
              @click="exportData('excel')"
            >
              <v-list-item-title>Export Excel</v-list-item-title>
            </v-list-item>
            <v-list-item
              disabled
            >
              <v-list-item-title>Export Image</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        <!-- More Options menu -->
            <v-menu :offset-y="true">
              <template v-slot:activator="{ on, attrs }">
                <v-btn dense class="main-header-action"
                  color="grey_text"
                  v-bind="attrs"
                  v-on="on"
                  icon
                >
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        v-bind="attrs" v-on="on"
                      >mdi-hamburger</v-icon>
                    </template>
                    <span>Viz Options</span>
                  </v-tooltip>
                </v-btn>
              </template>
              <v-list dense>
                <v-list-item
                  disabled
                >
                  <v-list-item-title>Yum!</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
      </div>
    </div>
    <!-- Viz container -->
    <div class="main-container">
      <div v-if="viz_type === 'bubble'" 
        class="bubble-container"
        ref="tbContainer"
      >
        <BubbleVis ref="TableVis"
          :vis_data="vis_data"
          :form_data="form_data"
        />
      </div>
      <div v-if="viz_type === 'scatter'" 
        class="bubble-container"
        ref="tbContainer"
      >
        <ScatterVis ref="TableVis"
          :vis_data="vis_data"
          :form_data="form_data"
        />
      </div>
      <div v-if="viz_type === 'table'"
        class="main-table-container"
        ref="tbContainer"
      >
        <TableVis ref="TableVis" 
          v-if="vis_data_cols.length > 0"
          :vis_data="vis_data"
          :searchText="mainSearchText"
        />
      </div>
      <div v-if="viz_type === 'heatmap'" class="heatmap-container">
        <!-- <template v-if="heatmap_data">
          <highcharts :options="heatmap_data" class="heatmap-chart" />
        </template> -->
        <HeatmapVis :vis_data="vis_data" />
      </div>
      <div v-if="viz_type === 'pie'" 
        class="pie-container" 
        ref="tbContainer"
      >
        <PieVis 
          ref="TableVis"
          :vis_data="vis_data" 
          :form_data="form_data" 

        />
      </div>
      <!-- Bar Charts/Box Plot -->
      <div v-if="['dist_bar', 'horiz_bar'].includes(viz_type)"
        class="dist_bar-container" 
        ref="tbContainer"
      >
        <BarChartVis ref="TableVis"
          :vis_data="vis_data"
          :form_data="form_data"
          :viz_type="viz_type"
        />
      </div>
      <div v-if="viz_type === 'box_plot'"
        class="dist_bar-container" 
        ref="tbContainer"
      >
        <BoxPlotVis ref="TableVis"
          :vis_data="vis_data"
          :form_data="form_data"
          :viz_type="viz_type"
        />
      </div>
      <div v-show="!vis_data">
          <div class="main-pred-title d-flex flex-column flex-wrap">
            <div class="d-flex">
              <Magic class="ml-auto mr-auto" />
            </div>
            <p class="main-pred-title-h1">Explore!</p>
            <p class="main-pred-title-desc">
              Drag columns to visualize or predict
            </p>
          </div>
        </div>
    </div>
    <!-- Data viewer/sample area -->
    <div class="main-resizer"
      ref="resizer"
      :style="showData ? 'opacity: 1' : 'opacity: 0'"
    >
      <v-icon class="ml-auto mr-auto main-resizer-icon">
        mdi-dots-horizontal
      </v-icon>
    </div>
    <template v-if="showData">
      <div class="main-table-data">
        <div class="d-flex">
          <div class="main-table-data-title">
            Data Viewer
          </div>
          <v-icon class="ml-2" 
            @click="disableData"
          >mdi-chevron-down
          </v-icon>
        </div>
        <v-tabs 
          color="white"
          @change="changeViewerTab"
        >
          <div class="tab-row">
            <v-tab>Raw Data</v-tab>
            <v-tab>Samples</v-tab>
            <v-spacer style="width:10%"/>
            <v-text-field
              outlined
              background-color="#373737"
              append-icon="mdi-magnify"
              v-model="search"
              label="Search"
              class="search-bar"
            />
          </div>
            <!-- raw data tab -->
            <v-tab-item>
              <div class="main-table-data-container" ref="tbResContainer">
                <ViewerTable
                  :vis_res_headers="vis_data.headers"
                  :vis_data_rows="vis_data.row_data"
                  :search="search"
                  />
              </div>
            </v-tab-item>
            <!-- sample tab -->
            <v-tab-item>
              <div class="main-table-data-container" ref="tbResContainer">
                <!-- <v-data-table></v-data-table> -->
                <!-- Sample Tab -->
                <ViewerTable
                  :vis_res_headers="sample_res_headers"
                  :vis_data_rows="sample_data_rows"
                  :vis_data_cols="sample_data_cols"
                  :search="search"
                  />
              </div>
            </v-tab-item>
        </v-tabs>
      </div>
    </template>
    <template v-else>
      <div class="main-pred">
        <!-- <div class="d-flex table-expand cursor-pointer" 
          @click="enableData"
        > -->
        <div class="d-flex table-expand">
          <div class="main-table-data-title-collapsed">Data Viewer</div>
          <v-icon v-if="vis_data" class="ml-2"
            :disabled="!vis_data"
            @click="enableData"
          >mdi-chevron-up</v-icon>
          <v-icon v-else
            small
          >mdi-circle-off-outline</v-icon>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
// import { AgGridVue } from "ag-grid-vue";
// import { LicenseManager } from "ag-grid-enterprise";

import Highcharts from "highcharts"
require("highcharts/modules/accessibility");(Highcharts);
require("highcharts/modules/no-data-to-display")(Highcharts);

import TableVis from "../components/vizMainComponents/TableVis"
import PieVis from "../components/vizMainComponents/PieVis"
import BubbleVis from "../components/vizMainComponents/BubbleVis"
import ScatterVis from "../components/vizMainComponents/ScatterVis"
import BarChartVis from "../components/vizMainComponents/BarChartVis"
import BoxPlotVis from "../components/vizMainComponents/BoxPlotVis"
import HeatmapVis from "../components/vizMainComponents/HeatmapVis"


import Magic from "../../../components/svg/magic";
import ViewerTable from "./vizMainComponents/DataViewerTable"

// LicenseManager.setLicenseKey(process.env.VUE_APP_AG_LICENSE);

export default {
  name: "VisMain",
  components: {
    // AgGridVue,
    Magic,
    ViewerTable,
    TableVis, PieVis,
    BubbleVis, ScatterVis,
    BarChartVis, BoxPlotVis,
    HeatmapVis,
  },
  props: {
    unsavedForm: {
      default() {
        return false
      }
    },
    active_query: {
      default() {
        return false;
      }
    },
    query_time: {
      default() {
        return 'blah';
      }
    },
    sample_data_rows: {
      default() {
        return null;
      }
    },
    sample_data_cols: {
      default() {
        return null;
      }
    },
    sample_res_headers: {
      default() {
        return null;
      }
    },
    vis_data: {
      default() {
        return null;
      },
    },
    viz_type: {
      default() {
        return null;
      },
    },
    slice_name: {
      default() {
        return "";
      },
    },
    bubble_data: {
      default() {
        return null;
      },
    },
    heatmap_data: {
      default() {
        return null;
      },
    },
    dist_bar_data: {
      default() {
        return null;
      },
    },
    pie_data: {
      default() {
        return null;
      },
    },
    box_plot_data: {
      default() {
        return null;
      },
    },
    scatter_data: {
      default() {
        return null;
      },
    },
    form_data: {
      default() {
        return null;
      },
    },
  },
  data() {
    return {
      // main chart search
      mainSearchText: null,
      showSearch: false,
      //table search
      search: '',
      //ag grid table min Height
      tableMH: 520,
      //resizer height
      resizeH: 10,
      //showData
      showData: false,
    };
  },

  computed: {
    vis_data_cols() {
      // console.log('compute cols', this.vis_data)
      return this.vis_data && this.vis_data.cols ? this.vis_data.cols : [];
    },
    agStyle() {
    /**
     * ag grid table style
     */
      return `width: 100%; height: ${this.tableMH}px`;
    },
  },
  methods: {
    exportData(type) {
      // for table only?
      let childData = this.$refs.TableVis.gridApi
      if (type==='csv') {
        childData.exportDataAsCsv();
      } else if (type === 'excel') {
        childData.exportDataAsExcel();
      }
    },
    copyData(e) {
      console.log('copy data')
      this.$refs.TableVis.gridApi.selectAll();
      this.$refs.TableVis.gridApi.copySelectedRowsToClipboard();
      this.$refs.TableVis.gridApi.deselectAll()
    },
    changeViewerTab (e) {
      if (e === 1 && this.sample_data_rows < 1 ) {
        this.$emit('getViewerSample')
      }
    },
    /**
     * resize event
     */
    setResizeEvent() {
      const el = this.$refs.tbContainer;
      // const tbGrid = this.$refs.tbGrid;
      const tbGrid = this.$refs.TableVis.$refs.tbGrid
      const that = this;
      function resize(e) {
        document.body.style.cursor = "row-resize";
        tbGrid.style.height = `${e.clientY - 141}px`;
        that.resizeResTb(`${861 - e.clientY}px`);
      }
      const resizer = this.$refs.resizer;
      resizer.addEventListener(
        "mousedown",
        (e) => {
          if (e.offsetY < this.resizeH) {
            el.style.transition = "initial";
            document.addEventListener("mousemove", resize, false);
          }
        },
        false
      );
      document.addEventListener(
        "mouseup",
        () => {
          el.style.transition = "";
          document.body.style.cursor = "";
          document.removeEventListener("mousemove", resize, false);
        },
        false
      );
    },
    /**
     * resize result Table
     * @param size
     */
    resizeResTb(size) {
      const ele = this.$refs.tbResContainer;
      ele.style.height = size;
    },
    /**
     * set table grid height
     */
    setTbGrid() {
      // console.log('setTbGrid/////', this.$refs.TableVis.$refs.tbGrid)
      // const el = this.$refs.tbGrid;
      const el = this.$refs.TableVis.$refs.tbGrid
      // el.style.height = "520px";
      el.style.height = "100%";
    },
    /**
     * show data
     */
    enableData() {
      console.log('show panel')
      this.showData = true;
      this.setResizeEvent();
      this.setTbGrid();
    },
    /**
     * disable data
     */
    disableData() {
      this.showData = false;
      this.setTbGrid();
    },
    /**
     * show edit chart name popup
     */
    showEditChartName() {
      this.$emit("showEditChartName");
    },
  },
};
</script>

<style lang="scss" scoped>

.tab-row {
  width: 100%;
  display: flex;
}

.cursor-pointer {
  cursor: pointer;
}

.bubble {
  &-container {
    padding-right: 20px;
    width: 90%;
    height:10%;
  }

  // &-chart {
    // width: 100%;
    // height: 700px;
  // }
}

.heatmap {
  &-container {
    padding-right: 200px;
  }

  &-chart {
    height: 700px;
  }
}

.pie {
  &-container {
    padding-right: 200px;
  }

  &-chart {
    height: 700px;
  }
}

.main {
  width: 100%;
  // height: 800px;
  // border: 3px solid green;
  display: flex;
  flex-flow: column;

  &-header {
    // top right bottom left
    // padding: 10px 33px 17px 42px;
    padding: 10px 24px 24px 24px;
    border-bottom: 0.5px solid #373737;
    height: 91px;
    
    &-action {
      height: 100%;
      display: flex;
      align-content: center;
    }

  }
  &-container {
    // align-items:start;
    // background-color: blue;
    // padding: 10px 33px 17px 42px;
    padding-right: 8px;
    border-bottom: 0.5px solid #373737;
    height: 100%;
  }

  &-title {
    &-text {
      font-size: 28px;
    }
  }

  &-table {
    display: flex;
    flex-flow: column;

    &-header {
      border-bottom: 0.5px solid #373737;
    }
    &-container{
      height: 100%;
    }

    &-grid {
      height: 100%;
    }
    &-data {
      padding: 12px 30px;
      height: 100%;
      z-index: 1;
      background: #070707;

      &-container {
        height: 300px;
        overflow: auto;
        @include scrollbars(0.4rem, #717171, #00000080);
      }

      &-title-collapsed {
        padding-left: 28px;
      }
    }
  }

  &-resizer {
    height: 10px;
    display: flex;
    background: #373737;
    cursor: row-resize;
    z-index: 1;

    &-icon {
      cursor: row-resize;
    }
  }

  &-pred {
    height: 51px;
    &-title {
      // margin-top: 100px;
      // height: 100%;
      position: absolute;
      top: 50%;
      left: 50%;

      &-h1 {
        text-align: center;
        font-size: 36px;
        margin-top: 16px;
        margin-bottom: 0;
      }

      &-desc {
        text-align: center;
        font-size: 21px;
        color: #b9b9b9;
      }
    }
  }
}
</style>