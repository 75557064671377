import { mainAxios } from "./axios";

class ChartService {
  get(id) {
    return mainAxios.get(`/chart/${id}`);
  }
  getData(id) {
    return mainAxios.get(`/chart/${id}/data/`)
  }
  getAll(q) {
    return mainAxios.get(`/chart/?q=${JSON.stringify(q)}`);
  }
  getFavStatusAll(q) {
    return mainAxios.get(`/chart/favorite_status/?q=${q}`);
  }
  create(params) {
    return mainAxios.post(`/chart/`, params);
  }
  update(id, params) {
    return mainAxios.put(`/chart/${id}`, params);
  }
  delete(id) {
    return mainAxios.delete(`/chart/${id}`);
  }
  deleteMulti(q) {
		return mainAxios.delete(`/chart/?q=${JSON.stringify(q)}`);
	}
  postChartData(data) {
    return mainAxios.post(`/chart/data`, data);
  }
  getChartList(data) {
    return mainAxios.post(`/chart/data_list/?id_list=[${data.ids.toString()}]`, data.filter)
  }
}

export default new ChartService();
