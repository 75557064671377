<template>
  <v-dialog
    v-model="showNewFromFileAdvance"
    max-width="866px"
    max-height="585px"
  >
    <div class="new-from-file-advance">
      <div class="new-from-file-advance-header">
        <p class="header-title">Advanced Settings</p>
        <v-icon @click="closeModal" class="ml-auto mt-auto mb-auto" size="36px"
          >mdi-close</v-icon
        >
      </div>
      <v-tabs color="primary" grow>
        <v-tab>COLUMN SETTINGS</v-tab>
        <v-tab>FORMATTING</v-tab>
        <v-tab-item class="h-405">
          <v-row no-gutters class="mb-4 mt-6">
            <v-col sm="4" cols="12" class="pr-1">
              <p class="mb-2">Index Column</p>
              <v-text-field
                outlined
                dense
                messages="Column to use as the row labels of the dataframe leave empty if no index column"
                height="40px"
                background-color="#1E1E1E"
                v-model="index_col"
              />
            </v-col>
            <v-col sm="4" cols="12" class="pr-1">
              <p class="mb-2">Skip Rows</p>
              <v-text-field
                outlined
                dense
                messages="Number of rows to skip at beginning of file"
                height="40px"
                type="number"
                v-model="skip_rows"
                background-color="#1E1E1E"
              />
            </v-col>
            <v-col sm="4" cols="12">
              <p class="mb-2">Rows to Read</p>
              <v-text-field
                outlined
                dense
                messages="Number of rows to skip at beginning of file"
                height="40px"
                background-color="#1E1E1E"
                type="number"
                v-model="n_rows"
              />
            </v-col>
          </v-row>
          <v-row no-gutters class="mb-4">
            <v-col sm="3" col="12" class="pr-1">
              <div class="d-flex card-select">
                <p>Manage Duplicate Columns</p>
                <v-checkbox
                  v-model="mangle_dupe_cols"
                  class="card-select-check"
                ></v-checkbox>
              </div>
            </v-col>
            <v-col sm="3" col="12" class="pr-1">
              <div class="d-flex card-select">
                <p>Skip Initial Space</p>
                <v-checkbox
                  v-model="skip_spaces"
                  class="card-select-check"
                ></v-checkbox>
              </div>
            </v-col>
            <v-col sm="3" col="12" class="pr-1">
              <div class="d-flex card-select">
                <p>Skip Blank Lines</p>
                <v-checkbox
                  v-model="skip_blank_lines"
                  class="card-select-check"
                ></v-checkbox>
              </div>
            </v-col>
            <v-col sm="3" col="12">
              <div class="d-flex card-select">
                <p>Dataframe Index</p>
                <v-checkbox
                  v-model="dataframe"
                  class="card-select-check"
                ></v-checkbox>
              </div>
            </v-col>
          </v-row>
          <div>
            <v-row no-gutters class="mb-2">
              <span class="card-label">Use Columns</span>
              <label class="card-message">
                Comma separated list of Columns to read from file. If null then
                all columns read
              </label>
            </v-row>
            <v-text-field
              outlined
              dense
              v-model="use_columns"
              height="40px"
              background-color="#1E1E1E"
            />
          </div>
          <div>
            <v-row no-gutters class="mb-2">
              <span class="card-label">Column Labels</span>
              <label class="card-message">
                Column lable for index column(s). If None is given and Dataframe
                Index is True, Index Names are used
              </label>
            </v-row>
            <v-text-field
              outlined
              dense
              v-model="index_label"
              height="40px"
              background-color="#1E1E1E"
            />
          </div>
        </v-tab-item>
        <v-tab-item class="h-405">
          <v-row no-gutters class="mt-6">
            <v-col sm="4" col="12">
              <v-row no-gutters>
                <span class="card-label">Decimal Character</span>
              </v-row>
              <v-text-field
                outlined
                dense
                v-model="decimal_char"
                messages="Character to infer as decimal"
                background-color="#1E1E1E"
              />
            </v-col>
          </v-row>
          <div>
            <v-row no-gutters class="mb-2">
              <span class="card-label">Data Columns</span>
              <label class="card-message">
                Comma separated list of Columns to read from file. If null them
                no columns read as date time
              </label>
            </v-row>
            <v-text-field
              outlined
              v-model="parse_dates"
              dense
              height="40px"
              background-color="#1E1E1E"
            />
          </div>
          <v-row no-gutters class="mb-4">
            <v-col sm="6" col="12" class="pr-1">
              <div class="d-flex card-select">
                <div>
                  <p class="card-label">Infer Datetime Format</p>
                  <p class="card-message">
                    Use Pandas to infer date time automatically
                  </p>
                </div>
                <v-checkbox
                  v-model="infer_datetime"
                  class="card-select-check"
                ></v-checkbox>
              </div>
            </v-col>
          </v-row>
          <div class="mb-10">
            <v-row no-gutters class="mb-2">
              <span class="card-label">Null Values</span>
              <label class="card-message">
                Comma separated list of values to interpret as null. Examples:
                [""], ["None", "N/A"], ["nan", "null"].
              </label>
            </v-row>
            <v-text-field
              height="40px"
              background-color="#1E1E1E"
              outlined
              dense
              v-model="null_values"
              messages='Warning: Hive database supports only single value. Use [""] for empty string'
            />
          </div>
        </v-tab-item>
      </v-tabs>
      <div class="d-flex">
        <v-btn
          class="ml-auto"
          outlined
          @click="closeModal"
          height="36px"
          width="232px"
          color="#FFFFFF"
        >
          Cancel
        </v-btn>
        <v-btn
          class="ml-7 mr-auto"
          color="primary"
          @click="saveAndClose"
          width="232px"
          height="36px"
        >
          Save & Close
        </v-btn>
      </div>
    </div>
  </v-dialog>
</template>

<script>
export default {
  props: {
    showNewFromFileAdvance: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      // column for row labels of dataset. Leave empty if no index column
      index_col: null,
      //number of rows to skip at start of file
      skip_rows: null,
      //number of rows of file to read
      n_rows: null,
      //specify duplicate columns
      mangle_dupe_cols: true,
      //skip spaces after delimiter
      skip_spaces: true,
      //skip blank lines
      skip_blank_lines: true,
      //dataframe
      dataframe: true,
      //json list of the column names that should be read
      use_columns: null,
      //column lable for index column(s)
      index_label: null,
      //character to interpret as decimal point
      decimal_char: null,
      //a comma separated list of columns that should be parsed as dates
      parse_dates: null,
      //use pandas to interpret the datetime format automatically
      infer_datetime: true,
      //json list of the values that should be treated as null
      null_values: null,
    };
  },
  methods: {
    closeModal() {
      this.$emit("cancel");
    },
    saveAndClose() {
      const data = {
        index_col: this.index_col,
        skip_rows: this.skip_rows,
        n_rows: this.n_rows,
        mangle_dupe_cols: this.mangle_dupe_cols,
        skip_spaces: this.skip_spaces,
        skip_blank_lines: this.skip_blank_lines,
        dataframe: this.dataframe,
        use_columns: this.use_columns,
        index_label: this.index_label,
        decimal_char: this.decimal_char,
        parse_dates: this.parse_dates,
        infer_datetime: this.infer_datetime,
        null_values: this.null_values,
      };
      this.$emit("save", data);
    },
  },
};
</script>

<style lang="scss" scoped>
.new-from-file-advance {
  height: 585px;
  padding: 24px;
  background: #303030;

  &-header {
    display: flex;

    .header-title {
      font-size: 28px;
      line-height: 35px;
    }
  }
}

.card-select {
  border: 1px solid #707070;
  height: 48px;

  p {
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 10px;
  }

  &-check {
    margin-left: auto;
    margin-top: 6px;
  }
}

.card-label {
  font-size: 16px;
}

.card-message {
  font-size: 12px;
  color: #a2a2a2;
  margin-top: auto;
  margin-left: 16px;
}

.h-405 {
  height: 405px;
}
</style>
