<template>
  <div class="detail-tabs">
    <v-container>
      <!-- 3 cols per row => n cols computed valuie -->
      <v-row v-for="r in n_rows" :key="r" dense >
        <v-col v-for="n in 3" :key="n">
          <v-card v-if="metadata[(r-1)*3 + n - 1]"
            color="#454545"
            outlined
          >
            <div v-if="detailTab === 'members'">
            <v-row>
              <v-col 
                :cols="2"
                class="pt-4 pb-0 pl-4"
              >
                <v-avatar size="36" color="#1BBAB9">
                  {{ avatarInitials[(r-1)*3 + n - 1] }}
                </v-avatar>
              </v-col>
              <v-col class="pb-0">
                <div class="title">{{metadata[(r-1)*3 + n - 1].name}}</div>
                <v-divider/>
                <div class="member-role">{{ metadata[(r-1)*3 + n - 1].role }} </div>
              </v-col>
            </v-row>
            <v-row class='mb-1'>
              <span class="card-email">
                {{metadata[(r-1)*3 + n - 1].email}}
              </span>
            </v-row>
            </div>
            <div v-if="detailTab === 'charts'">
            <v-row dense>
              <v-col 
                :cols="3"
                class=""
              >
                <v-btn icon x-large
                  color="white"
                  style="background-color: #1BBAB9"
                >
                  <sp-icon-graph-bubble class="chart-icon"
                    v-if="metadata[(r-1)*3 + n - 1].visualization_type==='bubble'"
                  />
                  <sp-icon-graph-scatter class="chart-icon"
                    v-if="metadata[(r-1)*3 + n - 1].visualization_type==='scatter'"
                  />
                  <sp-icon-table class="chart-icon"
                    v-if="metadata[(r-1)*3 + n - 1].visualization_type==='table'"
                  />
                  <sp-icon-graph-bar-vertical class="chart-icon"
                    v-if="metadata[(r-1)*3 + n - 1].visualization_type==='dist-bar'"
                  />
                  <sp-icon-graph-bar-horizontal class="chart-icon"
                    v-if="metadata[(r-1)*3 + n - 1].visualization_type==='horiz_bar'"
                  />
                  <sp-icon-graph-pie class="chart-icon"
                    v-if="metadata[(r-1)*3 + n - 1].visualization_type==='pie'"
                  />
                  <v-icon 
                    v-if="metadata[(r-1)*3 + n - 1].visualization_type==='box_plot'"
                  >
                    mdi-chart-box
                  </v-icon>
                  <v-icon
                    v-if="metadata[(r-1)*3 + n - 1].visualization_type==='heatmap'"
                  >
                    mdi-heat-pump
                  </v-icon>
                </v-btn>
              </v-col>
              <v-col class="pb-0">

                  <!-- onclick="clickChart" -->
                <!-- <div class="title">{{metadata[(r-1)*3 + n - 1].chart}}</div> -->
                <a :href="`#`" @click="clickChart((r-1)*3 + n - 1)"
                >{{ metadata[(r-1)*3 + n - 1].chart }}</a>
                <v-divider/>
                <div class="member-role">{{ metadata[(r-1)*3 + n - 1].visualization_type }} </div>
                <div class="card-email">
                Last modified: {{metadata[(r-1)*3 + n - 1].changed_on_delta_humanized}}
              </div>
              </v-col>              
            </v-row>
            </div>
            <div v-if="detailTab === 'dashboards'">
            <v-row dense>
              <v-col 
                :cols="3"
                class=""
              >
                <v-btn icon x-large
                  color="white"
                  style="background-color: #1BBAB9"
                >
                  <sp-icon-dashboard class="chart-icon"/>
                </v-btn>
              </v-col>
              <v-col class="pb-0">
                <div class="title">{{metadata[(r-1)*3 + n - 1].title}}</div>
                <v-divider/>
                <div class="member-role">{{ metadata[(r-1)*3 + n - 1].Description }} </div>
                <div class="card-email">
                Last modified: {{metadata[(r-1)*3 + n - 1].changed_on_delta_humanized}}
              </div>
              </v-col>              
            </v-row>
            </div>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>



<script>

import '@spectrum-web-components/icons-workflow/icons/sp-icon-graph-scatter.js';
import '@spectrum-web-components/icons-workflow/icons/sp-icon-table-and-chart.js';
import '@spectrum-web-components/icons-workflow/icons/sp-icon-graph-bubble.js';
import '@spectrum-web-components/icons-workflow/icons/sp-icon-table.js';
import '@spectrum-web-components/icons-workflow/icons/sp-icon-graph-bar-vertical.js';
import '@spectrum-web-components/icons-workflow/icons/sp-icon-graph-bar-horizontal.js';
import '@spectrum-web-components/icons-workflow/icons/sp-icon-graph-pie.js';
import '@spectrum-web-components/icons-workflow/icons/sp-icon-dashboard.js';

import { clickItem }  from '@/utils/chartUtils'

export default {
  name: "MemberDetailTab",
  data: () => ({
    // icon: IconGraphScatter
  }),
  props: {
    detailTab: {
      type: String,
      default() {
        return ""
      },
    },
    // avatarInitials: {
    //   type: Array,
    //   default() {
    //     return []
    //   },
    // },
    metadata: {
      type: Array,
      default() {
        return []
      },
    },
  },
  methods: {
    clickItem,
    clickChart(index) {
      // console.log('clicl',)
      let item = this.metadata[index]
      this.clickItem(item)
    },
  },
  computed: {
    n_rows() {
      console.log('metadata', this.metadata)
      return Math.ceil((this.metadata.length|| 0) / 3)
    },
    avatarInitials() {
      return this.metadata.map( (a) => {
        return (a.name.split(' ')[0][0] + " " + a.name.split(' ')[0][1]).toUpperCase()
        // return 'blue'
    })
    },
  },
}
</script>

<style scoped>

.chart-icon {
  color: white;
  height: 30px;
  width: 30px;
}

.title {
    padding-top: 6px;
  }

  .member-role {
    padding-right: 10px;
    text-align: right; 
    width: 100%;
  }

  .card-email {
    padding-right: 12px;
    padding-bottom: 4px;
    font-size: 12px;
    font-style: italic;
    text-align: right; 
    width: 100%;
  }
</style>