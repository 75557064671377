var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex flex-column fill-height flex-grow-1 overflow-hidden"},[_c('MainHeader',{attrs:{"title":_vm.isUpdate ? 'Edit User' : 'New User'}}),_c('h2',{staticClass:"text-h6 pl-2"},[_vm._v(" "+_vm._s(_vm.isUpdate ? "Formulario de actualización" : "Create New User")+" ")]),_c('v-card',{staticClass:"mx-auto my-12 pa-4",attrs:{"outlined":"","elevation":"1","width":"374"}},[_c('div',{staticClass:"text-center my-3"},[_c('v-avatar',{staticClass:"mx-auto",attrs:{"size":"80","color":"teal"}},[_c('span',{staticClass:"white--text text-h4 font-weight-regular mx-auto",attrs:{"dark":""},domProps:{"textContent":_vm._s(_vm.initials)}})])],1),(_vm.loading)?_c('div',{staticClass:"text-center"},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary"}})],1):_vm._e(),_c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [(!_vm.loading)?_c('form',{staticClass:"box",on:{"submit":function($event){$event.preventDefault();return _vm.save.apply(null, arguments)}}},[_c('validation-provider',{attrs:{"name":"Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":"Name","required":""},model:{value:(_vm.model.name),callback:function ($$v) {_vm.$set(_vm.model, "name", $$v)},expression:"model.name"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"Email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":"Email","required":""},model:{value:(_vm.model.email),callback:function ($$v) {_vm.$set(_vm.model, "email", $$v)},expression:"model.email"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"Password","rules":"required|strongPassword"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"counter":8,"error-messages":errors,"label":"Password","required":"","type":_vm.showPass ? 'password' : 'text',"append-icon":_vm.showPass ? 'mdi-eye' : 'mdi-eye-off'},on:{"click:append":function () { return (_vm.showPass = !_vm.showPass); },"input":function (_) { return (_vm.model.password = _); }},model:{value:(_vm.model.password),callback:function ($$v) {_vm.$set(_vm.model, "password", $$v)},expression:"model.password"}})]}}],null,true)}),_c('v-card-actions',{staticClass:"pa-0 mt-5"},[_c('v-btn',{staticClass:"mr-2",attrs:{"type":"submit","color":"primary","disabled":invalid,"depressed":""}},[_vm._v(" Save ")]),_c('v-btn',{attrs:{"depressed":""},on:{"click":_vm.gotoUsers}},[_vm._v(" Cancel ")])],1)],1):_vm._e()]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }