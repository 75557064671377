<template>
  <div>
    <v-dialog
      content-class="custom-dialog"
      v-model="vis_select_modal"
      width="960"
      min-height="900px"
      scrollable
      @click:outside="cancelDialog"
    >
      <v-card 
        color="#262626"
        min-height="500px"
      >
        <v-card-title>
          <v-container>  
            <v-row>
              <v-col>Share Charts to Project</v-col>
              <v-col>
                <v-text-field
                  ref="searchInput"
                  hide-details
                  label="Filter Chart Titles"
                  no-filter
                  dense
                  solo
                  outlined
                  hide-no-data
                  prepend-inner-icon="mdi-magnify"
                  class="hidden-sm-and-down custom-class ml-auto"
                  style="max-width: 300px;"
                  @input="userInputFilter"
                >
              </v-text-field>
              </v-col>
              <!-- Grid and list view buttons -->
              <v-col>
                <div class="gridSelect d-flex  align-center ml-7">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn icon v-bind="attrs" v-on="on" :class="!viewGrid && 'inactive'">
                        <v-icon size="18" @click="viewGrid = !viewGrid">
                          mdi-view-grid
                        </v-icon>
                      </v-btn>
                    </template>
                    <span>Grid View</span>
                  </v-tooltip>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn icon  v-bind="attrs" v-on="on" :class="viewGrid && 'inactive'">
                        <v-icon size="24" @click="viewGrid = !viewGrid">
                          mdi-view-list
                        </v-icon>
                      </v-btn>
                    </template>
                    <span>List View</span>
                  </v-tooltip>
              <div class="d-flex  align-center ml-7"/>
            </div>
              </v-col>
            </v-row>
          </v-container>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-container>
            <div v-if="viewGrid">
              <v-container>
                <v-row v-if="getModels.length>0"
                  class="container-row-resolution"
                >
                  <!-- @toggled="toggleFav" -->
                  <BoxItem
                    v-for="item in getModels"
                    v-bind:key="item.id"
                    :item="item"
                    :class-name="`slice`"
                    :title-field="`slice_name`"
                    :selectable=true
                    @checked="toggleChecked"
                    @clickItem="toggleChecked"
                  />
                </v-row>
                <v-row v-else class="container-row-resolution pt-5 d-flex justify-center">
                  <p>No Charts found ...</p>
                </v-row>
              </v-container>
            </div>
            <div v-if="!viewGrid"
              class="
                d-flex
                flex-grow-1 flex-column flex-basis-0
                fill-height
                overflow-x-auto
              "
            >
              <v-data-table
                id="tblItems"
                v-model="selectedItems"
                :items="getModels"
                :headers="headers"
                :search="search"
                @click:row="clickItem"
                @item-selected="updateGridViewSelection"
                show-select
                item-key="id"
                class="mt-3"
                :hide-default-footer="true"
                :header-props="{ sortIcon: 'mdi-chevron-down' }"
              >
                <template #[`item.slice_name`]="{ item }">
                  <div @click.stop class="actions">
                    <a
                      @click="clickItem(item)"
                      >{{ item.slice_name }}</a
                    >
                  </div>
                </template>
                <template #[`item.created_by.username`]="{ item }">
                  <div v-if="item.created_by" class="actions">
                    <div
                      class="chips"
                      :class="{ 'bg-green': item.created_by.username }"
                    >
                      {{ item.created_by.username.substring(0, 2).toUpperCase() }}
                    </div>
                  </div>
                </template>
              </v-data-table>
            </div>
        </v-container>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions 
          style="padding-top: 12px; padding-bottom: 12px;" 
        >
          <v-btn
            class="mr-5"
            depressed
            @click="cancelDialog"
          >
            Cancel
          </v-btn>
          <!-- @click="moveToDataSet" -->
            <!-- :disabled="!$parent.chart_type" -->
          <v-btn
            color="primary"
            depressed
            :disabled="!selectedItemIds.length>0"
            @click="selectCharts"
          >
            OK
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
// import VerticalBar from "../../../components/svg/verticalBar.vue";
// import HorizentalBar from "../../../components/svg/horizentalBar.vue";
// import Pie from "../../../components/svg/pie.vue";
// import Bubble from "../../../components/svg/bubble.vue";
// import Scatter from '../../../components/svg/Scatter.vue';
import BoxItem from "@/views/_partials/BoxItem";

import {
  GET_CHART_ALL,
  ADD_PROJECT_CHILDREN,
  // SET_FAV,
  // UNSET_FAV,
  // CREATE_DASHBOARD,
  GET_USERS_ALL,
} from "@/store/actionType";

import { 
    toggleSelectAll,
    modifyAllCheck,
    selectItemsEvent,
    filteredObjs,
  } from "@/utils/moduleUtils.js";

export default {
  name: "SelectChartDlg",
  components: {
    BoxItem,
    // VerticalBar,
    // HorizentalBar,
    // Pie,
    // Bubble,
    // Scatter,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    projectMeta: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      viewGrid: true,
      collection: [],
      selectedItems: [],
      search:"",
      moduleFilter:'',
      headers: [
        { text: "Title", value: "slice_name" },
        { text: "Visualization type", value: "viz_type" },
        { text: "Dataset", value: "datasource_type" },
        { text: "Created By", value: "created_by.username" },
        { text: "Last Modified", value: "changed_on_delta_humanized" },
      ],
    }
  },
  mounted() {
    this.load();
  },
  watch: {
    value() {
      this.load();
    }
  },
  methods: {
    // Imports
    //////////
    //moduleUtils
    toggleSelectAll,
    modifyAllCheck,
    selectItemsEvent,
    filteredObjs,
    //////////
    selectCharts() {
      let proj_charts = this.projectMeta.slices.map(a => a.id)
      let put_charts = this.selectedItemIds.filter( 
        ( el ) => !proj_charts.includes( el ) 
      );
      if (put_charts.length>0) {
      let params = {
        id: this.projectMeta.id,
        className: "charts",
        q: put_charts,
      }
      console.log('params', JSON.stringify(params))
      this.$store
        .dispatch(ADD_PROJECT_CHILDREN, params)
        .then((r) => {
          // this.project.id;
          this.$emit('reloadProject', this.projectMeta.id);
        })
        .catch((error) => {
          this.$emit('showErrorSnackbar')
          console.error(error)    
        })
      } else {
        this.$emit('showErrorSnackbar')
      }
      this.$parent.vis_select_modal = false;
      console.log('should reload')
      
    },
    userInputFilter(e) {
      this.moduleFilter = e;
    },
    moduleFilterChanged (input) {
      this.moduleFilter = input;
    },
    cancelDialog() {
      // this.$parent.vis_select_modal = false;
      this.selectedItems=[]
      this.$emit('closeDialog')

    },
    clickItem(item) {},
    // moveToDataSet() {
    //   console.log("Move To dataset");
    //   this.$parent.vis_type_modal = false;
    //   this.$parent.dataset_modal = true;
    // },
    toggleChecked(item) {
      console.log('checked', item)
      let index = this.selectedItems.findIndex(_item => _item.id === item.id)
      if (item.checked === true) {
        if (index == -1) {
          this.selectedItems.push(item)
        }
      }
      else {
        if (index > -1) {
          this.selectedItems.splice(index, 1)
        }
      }
    },
    updateGridViewSelection(obj) {
      let selItem = this.collection.find(item => item.id === obj.item.id)
      if (selItem) {
        selItem.checked = obj.value
      }
    },
    load() {
      const columns = [
        "id",
        "changed_by.username",
        "created_by.first_name",
        "created_by.last_name",
        "created_by.username",
        "changed_on_utc",
        "changed_on_delta_humanized",
        "slice_name",
        "viz_type",
        "datasource_type",
      ];
      const q = {
        columns: columns,
        order_column: this.curSortField,
        order_direction: "desc",
      };
      // Load Recent Item
      let recentArr=[];
      this.$store
        .dispatch(GET_CHART_ALL, q)
        .then((r) => {
          recentArr  = r.data.result;
          // default to empty array if projects charts not loaded
          let project_charts = this.projectMeta.slices ? 
            this.projectMeta.slices.map(
              a => a.id
            ) : [];
          let arr_filter = recentArr.filter(
            x => !project_charts.includes(x.id)
          );
          this.collection=arr_filter ? arr_filter : [];
        })
        .catch((r) => {
          console.error("We have error:", r);
        });
    },
  },
  computed: {
    selectedItemIds () {
      let id_list = this.selectedItems.map(a => a.id);
      return id_list;
    },
    getModels() {
      let arr = this.collection;
      if (this.collection.length > 0 && this.moduleFilter.length > 0) {
        arr = this.filteredObjs(this.collection, 'slice_name', this.moduleFilter)
      }
      return arr
    },
    vis_select_modal: {
      get: function () {
        return this.value;
      },
      set: function (newValue) {
        this.value = newValue;
      },
    },
  },
};
</script>

<style lang="scss" scoped>

.gridSelect{
  justify-content: right;
  background-color: #262626;
}

.close {
  margin-right: 15px;
  cursor: pointer;
  font-size: 20px;
}
.custom-dialog {
  position: absolute;
  /*right: 10%;*/
  top: 10%;
}
.outline-selected {
  border-bottom: 1px solid #ffffff;
}
</style>