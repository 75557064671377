<template>
  <div>
    <!-- Pie Categories (list) -->
    <div class="categories">
      <div class="categories-header d-flex">
        <div class="categories-header-title">Categories</div>
        <AxisInfoTooltip
          :validCol="true"
          :requiredCol="false"
          :message="['Pie Chart Groupby Columns']"
          displayType="Categorical / Numerical(Low Cardinality)"
        />
      </div>
      <div
        class="pie-categories-container"
        @dragover.prevent
        @drop="$emit('dragPillFinishList', 'all_columns')"
      >
        <template v-if="pieCategories.length > 0">
          <div v-for="(category, index) in pieCategories" 
            :key="index"
            class="column-item"
          >
            <VizModePill
              :column="getListCol('all_columns', category)"
              @deleteCol="$emit('deletePillList', 'all_columns', index)"
            />
          </div>
        </template>
        <template v-else>
          <div class="blank-drag">Drag here</div>
          <p class="blank-text">
            Columns to group by
          </p>
        </template>
      </div>
    </div>
    <!-- Pie Metric (object) -->
    <div class="metric">
      <div class="metric-header d-flex">
        <div class="metric-header-title">Metric</div>
        <v-tooltip right>
          <template v-slot:activator="{ on, attrs }">
            <v-icon 
              small
              v-bind="attrs" 
              v-on="on"
              :color="validVisCols.sel_pie_metric ? '#B9B9B9' : '#ffbb33'"
              class="ml-auto"
            >
              mdi-information-outline
            </v-icon>
          </template>
            <span style="color:#ffbb33"> 
              Required<br>
            </span>
          <span>
            Pie Chart Metric<br/>
            <i>Numerical/Calculation</i><br/>
          </span>
        </v-tooltip>
      </div>
      <!-- @drop="dragPieMetric($event)" -->
      <div
        class="metric-container"
        @dragover.prevent
        ref="pieMetricCon"
        
        @drop="$emit('dragComputedAxisFinish', 'metric')"
      >
        <div 
          class="d-flex"
          v-if="metric"
          :class="[showCalc.metric ? 'size-menu': 'size-item']"
        >
          <!-- Pill and Calculation menu card -->
          <v-menu
            attach
            :value="showCalc.metric"
            offset-x
            nudge-right="22px"
            nudge-top="153"
            :close-on-content-click="false"
            height="0"
            flat="true"
            :close-on-click="false"
          >    
          <template v-slot:activator="{ on }">
            <v-btn 
              v-on="on"
              class="mb-08"
              :outlined="!isApplied('metric')"
              :color=" metric.column.type_generic ? '#1bbab9' : '#00A7FF' "
              rounded
              width="100%"
              height="24px"
              v-if="metric"
              @click.stop="on.click"
              @contextmenu.prevent="contextClickPill('metric')"
            >
              {{ getComputedCol('metric').column_name }}
              <v-icon 
                class="ml-auto" right
                @click.prevent
                @click="removePillX('metric')" 
              >
                mdi-close-circle
              </v-icon>
            </v-btn>
          </template>
            <CalculationCard
              v-if="showCalc.metric"
              :calcStyle="'Calculation'"
              :form_data="form_data"
              :aggregations="getAggType(metric)"
              :size="metric"
              @disableCalcCard="disableSimpleCalcX"
              @saveSimpleCalcSize="saveSimpleCalcX"
            />
          </v-menu>
        </div>
        <!-- <template v-if="sel_pie_metric">
          <v-btn
            class="mb-08"
            color="#00A7FF"
            rounded
            width="100%"
            height="24px"
            @contextmenu.prevent="
              $emit('showPieMetric', $event, sel_pie_metric)
            "
          >
            {{ sel_pie_metric.label }}
          </v-btn>
        </template> -->
        <template v-else>
          <div class="blank-drag">Drag here</div>
          <p class="blank-text">
            Metric to Show on Pie Chart
          </p>
        </template>
      </div>
    </div>
  </div>
</template>

<script>

import AxisInfoTooltip from "./components/AxisInfoTooltip.vue"
import CalculationCard from "./components/CalculationCard.vue";
import VizModePill from "./components/VizModePill.vue"  

export default {
  components: {
    AxisInfoTooltip,
    CalculationCard,
    VizModePill,
  },
  props: {
    // Vis Axis Props
    pieCategories: {
      type: Array,
      default() {
        return [];
      },
    },
    metric: {
      type: Object,
      default() {
        return null;
      },
    },
    //////////////////////
    // Standard Mode props
    form_data: {
      type: Object,
      default() {
        return null;
      },
    },
    validVisCols: {
      type: Object,
      default() {
        return null;
      },
    },
    showCalc: {
      type: Object,
      default() {
        return null;
      },
    },
    aggregations: {
      type: Object,
      default() {
        return null;
      },
    },
    mode_active_cols: {
      type: Object,
      default() {
        return {};
      },
    },
    /////////////////
  },
  methods: {
    removePillX(axis) {
      this.$emit('deletePill', axis)
    },
    disableSimpleCalcX() {
      this.$emit('disableCalc', 'metric')
    },
    saveSimpleCalcX(calc) {
      this.$emit('saveCalc', calc, 'metric')
    },
    getComputedCol(val) {
      return {
        column_name: (
          this[val].column ?
            this[val].aggregate ? `(${this[val].aggregate})${this[val].column.column_name}` : 
            this[val].column.column_name : 'error?'
        ),
        type_generic: this[val].column.type_generic,
        applied: true,
      }
    },

    getListCol(val, axis) {
      const this_col_array = this.mode_active_cols[val]
      const this_col = this_col_array.filter(x =>
        x.column_name === axis)[0]
      return {
        column_name: this_col?.column_name,
        type_generic: this_col?.type_generic,
        applied: this_col?.applied,
        }
    },
    isAppliedList(axis, index) {
      return this.mode_active_cols[axis][index]?.applied
    },
    isApplied(axis) {
      return this.mode_active_cols[axis][0]?.applied
    },
    getAggType(col) {
      if ( col.column.type_generic === 1 ) {
        return this.aggregations.categorical;
      } else {
        return this.aggregations.numeric;
      }
    },
    contextClickPill(val) {
      this.$emit('showCalcCard', val)
    },

  },
};
</script>

<style lang="scss" scoped>

.column-item {
    margin-bottom: 5px;
  }

.blank-drag {
  border: 1px dashed #909090;
  border-radius: 12px;
  text-align: center;
  color: #909090;
  padding-top: 4px;
  padding-bottom: 4px;
  font-size: 12px;
}

.mb-05 {
  margin-bottom: .5rem;
}

.blank-text {
  font-size: 12px;
  text-align: center;
  color: #909090;
  margin-top: 9px;
}

.categories {
  &-header {
    padding: 8px 15px;

    &-title {
      font-size: 12px;
    }
  }

  &-container {
    overflow-y: auto;
    @include scrollbars(0.4rem, #717171, #00000080);
    border-bottom: 0.5px solid #373737;
    height: 40px;
    padding-left: 15px;
    padding-right: 15px;
  }

  &-color {
    position: absolute;
    z-index: 11;
    left: 40px;
  }
}

.pie {
  &-categories {
    &-container {
      height: 161px;
      padding-left: 15px;
      padding-right: 15px;
      padding-bottom: 8px;
      border-bottom: 0.5px solid #373737;
      overflow-y: auto;
      @include scrollbars(0.4rem, #717171, #00000080);
    }
  }
}

.metric {
  &-header {
    padding: 20px 15px;

    &-title {
      font-size: 12px;
    }
  }

  &-container {
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 8px;
    height: 137px;
    margin-bottom: auto;
  }


// Animate the tooltips to match v-menu
@keyframes fade {
  0% { opacity: 0 }
  100% { opacity: 1 }
}

.size {
  &-header {
    padding: 8px 15px;
    &-title {
      font-size: 12px;
    }
  }

  &-container {
    overflow-y: auto;
    @include scrollbars(0.4rem, #717171, #00000080);
    border-bottom: 0.5px solid #373737;
    padding-left: 15px;
    padding-right: 15px;
    height: 40px;
  }

  &-menu::after {
    animation: fade .2s linear;
    position: absolute;
    content: "";
    // top: 9px;
    z-index: 10;
    left: 230px;  
    border-top: 12.5px solid transparent;
    border-bottom: 12.5px solid transparent;  
    border-left: 16px solid #303030;
    transform: rotate(180deg);  
  }

}
}
</style>