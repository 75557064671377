import { mainAxios } from "./axios";

class SavedQueryService { 
  getAll(q) {
    return mainAxios.get(`/saved_query/?q=${JSON.stringify(q)}`);
  }
  delete(id) {
    console.log('MainAxios: Delete: ', id);
		return mainAxios.delete(`/saved_query/${id}`);
	}
	deleteMulti(q) {
		return mainAxios.delete(`/saved_query/?q=${JSON.stringify(q)}`);
	}
  postSavedQuery(params) {
    return mainAxios.post(`/saved_query/`, params);
  }
}

export default new SavedQueryService();
