var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex flex-column fill-height flex-grow-1 overflow-hidden pt-6"},[_c('NewDB',{attrs:{"showNewDB":_vm.showNewDB},on:{"cancel":_vm.cancelNewDB}}),_c('NewFromDB',{attrs:{"showNewFromDB":_vm.showNewFromDB},on:{"cancel":_vm.cancelNewFromDB,"createDB":_vm.showCreateDB,"goToDatasets":_vm.goToDatasets}}),_c('NewFromFile',{attrs:{"showNewFromFile":_vm.showNewFromFile},on:{"cancel":_vm.cancelNewFromFile,"showAdvance":_vm.showAdvance,"save_file":_vm.saveFile}}),_c('NewFromFileAdvance',{attrs:{"showNewFromFileAdvance":_vm.showNewFromFileAdvance},on:{"cancel":_vm.cancelNewAdvance,"save":_vm.saveNewAdvance}}),_c('ModuleHeader',{attrs:{"title":"Saved Queries","disabled-grid-btn":true,"disabled-list-btn":true,"disabled-fav-btn":true,"view-grid":false}}),_c('ModuleAction',{attrs:{"title":"Saved Queries","search-label":"Search Saved Queries","hide-new-button":true,"selectedItems":_vm.selectedItemIds,"n_items":_vm.filteredItemCount},on:{"deleteAll":_vm.deleteAll,"selectItemsEvent":_vm.selectItemsEvent,"moduleFilterChanged":_vm.moduleFilterChanged}}),_c('div',{staticClass:"\n      d-flex\n      flex-grow-1 flex-column flex-basis-0\n      fill-height\n      overflow-x-auto\n    "},[_c('v-container',[(_vm.loading)?_c('v-row',{staticClass:"container-row-resolution py-5 d-flex justify-center"},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary"}})],1):(_vm.getModels(_vm.collection, _vm.viewFavOnly).length)?_c('v-data-table',{staticClass:"mt-3",attrs:{"id":"tblItems","items":_vm.getModels(_vm.collection),"items-per-page":_vm.$pageItemCount,"headers":_vm.headers,"search":_vm.search,"show-select":"","item-key":"id","hide-default-footer":true,"header-props":{ sortIcon: 'mdi-chevron-down' }},on:{"click:row":_vm.editItem,"item-selected":_vm.updateGridViewSelection},scopedSlots:_vm._u([{key:"top",fn:function(ref){
var pagination = ref.pagination;
var options = ref.options;
var updateOptions = ref.updateOptions;
return [_c('v-data-footer',{attrs:{"pagination":pagination,"options":options,"items-per-page-text":"$vuetify.dataTable.itemsPerPageText"},on:{"update:options":updateOptions}})]}},{key:"item.label",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"title",on:{"click":function($event){$event.stopPropagation();return _vm.clickSavedQuery(item)}}},[_c('a',[_vm._v(_vm._s(item.label))])])]}},{key:"item.database.database_name",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":"primary","label":""}},[_vm._v(_vm._s(item.database.database_name))])]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('RowAction',{attrs:{"item":item,"disabledList":_vm.disabledRowActions},on:{"editItem":_vm.editItem,"deleteItem":_vm.deleteItem,"infoItem":_vm.infoItem}})]}}],null,true),model:{value:(_vm.selectedItems),callback:function ($$v) {_vm.selectedItems=$$v},expression:"selectedItems"}}):_c('v-row',{staticClass:"container-row-resolution pt-5 d-flex justify-center"},[_c('p',[_vm._v("No Saved Queries Found ...")])])],1)],1),_c('v-navigation-drawer',{attrs:{"hide-overlay":"","right":"","temporary":"","absolute":"","color":"#262626","width":"500px"},model:{value:(_vm.detailModalVisible),callback:function ($$v) {_vm.detailModalVisible=$$v},expression:"detailModalVisible"}},[_c('v-list-item',[(_vm.curItem)?_c('v-card',{attrs:{"width":"500","color":"#262626","flat":""}},[_c('v-card-title',[_vm._v(_vm._s(_vm.pageTitle + ' Info'))]),_c('v-container',{attrs:{"overflow-hidden":""}},[_c('div',{staticClass:"outline d-flex"},[_c('v-col',{attrs:{"md":"12"}},[_c('table',{staticClass:"w-100 prop-table"},_vm._l((_vm.curItem),function(prop,key){return _c('tr',{key:key},[_c('td',{staticClass:"pl-2"},[_vm._v(_vm._s(key))]),_c('td',{staticClass:"pl-2"},[_vm._v(_vm._s(prop))])])}),0)])],1)])],1):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }