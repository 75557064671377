var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex flex-column fill-height flex-grow-1 overflow-hidden pt-6"},[_c('ModuleHeader',{attrs:{"title":"Query History","disabled-grid-btn":true,"disabled-fav-btn":true,"disabled-list-btn":true,"view-grid":false},on:{"updateViewFavOnly":function($event){_vm.viewFavOnly = !_vm.viewFavOnly}}}),_c('ModuleAction',{attrs:{"title":"New Query","search-label":"Search Query History (SQL)"},on:{"clickNewItem":_vm.clickNewItem,"deleteAll":_vm.deleteAll,"selectItemsEvent":_vm.selectItemsEvent,"moduleFilterChanged":_vm.moduleFilterChanged}}),_c('div',{staticClass:"\n      d-flex\n      flex-grow-1 flex-column flex-basis-0\n      fill-height\n      overflow-x-auto\n    "},[_c('v-container',[(_vm.loading)?_c('v-row',{staticClass:"container-row-resolution py-5 d-flex justify-center"},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary"}})],1):(_vm.getModels(_vm.collection, _vm.viewFavOnly).length)?_c('v-data-table',{staticClass:"mt-3",attrs:{"id":"tblItems","items":_vm.getModels(_vm.collection, _vm.viewFavOnly),"items-per-page":_vm.$pageItemCount,"headers":_vm.headers,"search":_vm.search,"item-key":"id","hide-default-footer":true,"header-props":{ sortIcon: 'mdi-chevron-down' }},scopedSlots:_vm._u([{key:"top",fn:function(ref){
var pagination = ref.pagination;
var options = ref.options;
var updateOptions = ref.updateOptions;
return [_c('v-data-footer',{attrs:{"pagination":pagination,"options":options,"items-per-page-text":"$vuetify.dataTable.itemsPerPageText"},on:{"update:options":updateOptions}})]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-capitalize",class:item.status == 'success' ? 'text-success' : 'text-failed'},[_vm._v(_vm._s(item.status))])]}},{key:"item.start_time",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(new Date(item.start_time)))+" ")]}},{key:"item.duration",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.duration)+" ")]}},{key:"item.database.database_name",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":"primary"}},[_vm._v(_vm._s(item.database.database_name))])]}},{key:"item.sql",fn:function(ref){
var item = ref.item;
return [_c('pre',[_c('code',{staticClass:"language-sql"},[_vm._v(_vm._s(item.sql))])])]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('RowAction',{attrs:{"item":item,"selectActionOptions":_vm.selectActionOptions,"disabledList":_vm.disabledRowActions},on:{"editItem":_vm.editItem,"deleteItem":_vm.deleteItem,"infoItem":_vm.infoItem,"actionItemEvent":_vm.actionItemEvent}})]}}],null,true)}):_c('v-row',{staticClass:"container-row-resolution pt-5 d-flex justify-center"},[_c('p',[_vm._v("No Query History Found ...")])])],1),_c('v-navigation-drawer',{attrs:{"hide-overlay":"","right":"","temporary":"","absolute":"","color":"#262626","width":_vm.navigation.width},model:{value:(_vm.detailModalVisible),callback:function ($$v) {_vm.detailModalVisible=$$v},expression:"detailModalVisible"}},[_c('v-list-item',[(_vm.curHistory)?_c('v-card',{attrs:{"width":"500","color":"#262626","flat":""}},[_c('v-card-title',[_vm._v(_vm._s(_vm.pageTitle + ' Info'))]),_c('v-container',{attrs:{"overflow-hidden":""}},[_c('div',{staticClass:"outline d-flex"},[_c('v-col',{attrs:{"md":"12"}},[_c('table',{staticClass:"w-100 prop-table"},_vm._l((_vm.curHistory),function(prop,key){return _c('tr',{key:prop.id},[_c('td',{staticClass:"pl-2"},[_vm._v(_vm._s(key))]),_c('td',{staticClass:"pl-2"},[_vm._v(_vm._s(prop))])])}),0)])],1)])],1):_vm._e()],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }