import { mainAxios } from "./axios";

class SqlpageService {
  getDbAll() {
    return mainAxios.get(`/database/`);
  }
  getSchemaAll(pk) {
    return mainAxios.get(`/database/${pk}/schemas/`);
  }
  getTableAll(data) {
    return mainAxios.get(`/database/${data.pk}/${data.schema_name}/tables/`);
  }
  fetchTable(data) {
    return mainAxios.get(`/database/${data.pk}/table/${data.table}/${data.schema}/`);
  }
  runQuery(sendingData) {
    return mainAxios.post(`/database/sql_json/`, sendingData);
  }
  getQueryHistory() {
    return mainAxios.get(`/query/?q={"columns":[
      "changed_on",
      "database.database_name",
      "database.id",
      "rows",
      "sql",
      "executed_sql",
      "start_time",
      "end_time",
      "status",
      "user.username"
    ]}`)
  }
}

export default new SqlpageService();
