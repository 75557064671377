import DashboardService from "../services/DashboardService";

import {
  GET_DASHBOARD_BY_ID,
  GET_DASHBOARD_ALL,
  CREATE_DASHBOARD,
  UPDATE_DASHBOARD,
  DELETE_DASHBOARD,
  DELETE_DASHBOARD_MULTI,
  GET_DASHBOARD_DATASET_BY_ID,
  GET_DASHBOARD_CHART_BY_ID
} from "./actionType";

const state = {};

const actions = {
  [GET_DASHBOARD_DATASET_BY_ID](_, id) {
    return DashboardService.getDatasetByID(id).then(
      res => {
        return res;
      }
    )
  },
  [GET_DASHBOARD_BY_ID](_, id) {
    return DashboardService.get(id).then(
      res => {
        return res;
      }
    )
  },
  [GET_DASHBOARD_CHART_BY_ID](_, id) {
    return DashboardService.getChartById(id).then(
      res => {
        return res;
      }
    )
  },
  [GET_DASHBOARD_ALL](_, q) {
    return DashboardService.getAll(q).then(
      res => {
        return res;
      }
    )
  },  
  [CREATE_DASHBOARD](_, params) {
    return DashboardService.create(params).then(
      res => {
        return res;
      }
    )
  },
  [UPDATE_DASHBOARD](_, params) {
    return DashboardService.update(params).then(
      res => {
        return res;
      }
    )
  },
  [DELETE_DASHBOARD](_, id) {
    return DashboardService.delete(id).then(
      res => {
        return res;
      }
    )
  },
  [DELETE_DASHBOARD_MULTI](_, q) {
    return DashboardService.deleteMulti(q).then(
      res => {
        return res;
      }
    )
  }
};

const mutations = {};

export default {
    state,
    actions,
    mutations
}