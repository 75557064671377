<template>
  <div class="viz-mode-header">
    <v-btn
      class="ma-2"

      text-color="#B9B9B9"
      @click.stop="clickVizType"
    >
      <v-icon small>
        {{ vizIcon( form_data.viz_type ) }}
      </v-icon>
      <span class="viz-mode-header-label">{{ form_data.viz_type }}</span>
      <v-icon dense color="#B9B9B9">mdi-chevron-down</v-icon>
    </v-btn>
  </div>
</template>

<script>

import { vizIcon } from "@/utils/chartUtils"

export default {
  name: "VizModeHeader",
  data() {
    return {};
  },
  props: {
    //form data
    form_data: {
      type: Object,
      default() {
        return null;
      },
    },
    chart_type: {
      type: String,
      default: "dist_bar",
    },
  },
  methods: {
    vizIcon,
    clickVizType () {
      this.$emit('clickVisTypeModal')
    },
  }
};
</script>

<style lang="scss" scoped>

.header-viz-type {
  // padding: 12px 12px 16px 15px;
  // padding: 0  12px 0;
  padding: 24px;
  border-bottom: 0.5px solid #373737;

  &-name {
    font-size: 16px;
    color: #939393;
  }
}
.viz-mode-header {
  border-bottom: 0.5px solid #373737;
  // padding-right: 8px;
  
  &-label {
    // text-transform: uppercase;
    font-size: 12px;
    margin-left: 8px;
    margin-right: 8px;
    // letter-spacing: 0.48px;
    color: #939393;
  }
}
</style>