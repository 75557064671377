<template>
  <div class="d-flex flex-column fill-height flex-grow-1 overflow-hidden">
    <MainHeader title="Users"></MainHeader>

    <div
      class="
        d-flex
        flex-grow-1 flex-column flex-basis-0
        fill-height
        overflow-x-auto
      "
    >
      <v-card elevation="0" class="fill-height">
        <!-- <div
      class="d-flex flex-column flex-grow-1 overflow-y-auto height100"
      style="flex-basis: 0;"
    > -->
        <v-card-title>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          ></v-text-field>
        </v-card-title>
        <v-data-table
          id="tblUsers"
          :items="items"
          :headers="headers"
          :search="search"
          
        >
          <!-- @click:row="gotoUser" -->
          <template #[`item.name`]="{ item }">
            <Initials
              :item="item"
              :size="36"
              txtClass="white--text text-h6 font-weight-regular mx-auto"
            ></Initials>
          </template>

          <!-- <template #[`item.isAdmin`]="{ item }">
            <v-icon
              v-if="item.isAdmin"
              v-tippy
              content="Super Admin"
              :color="$store.state.user.isAdmin ? '' : 'grey lighten-1'"
            >
              mdi-account-star
            </v-icon>
            <v-icon v-else v-tippy content="Admin"> mdi-account </v-icon>
          </template> -->

          <template #[`item.action`]="{ item }">
            <!-- v-if="
                ($store.state.user.isAdmin && item.isAdmin) || !item.isAdmin
              " -->
            <div
              v-if="item.isAdmin"
              @click.stop
              class="list-menu non-clickable"
            >
              <!-- <v-btn color="primary" dark @click="openModal()">
              Open Dialog
            </v-btn> -->

              <v-btn
                icon
                class="mr-2"
                v-tippy
                content="Edit"
                
              >
                <!-- @click.stop="gotoUser(item)" -->
                <v-icon>mdi-pencil</v-icon>
              </v-btn>

              <v-btn
                icon
                v-tippy
                content="Delete"
                
              >
                <!-- @click.stop="deleteItem(item.id, item.name)" -->
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </div>
          </template>
        </v-data-table>

        <!-- <DeleteDialog v-model="modalOpen" :item="2" :title="'Seguro desea eliminar este Usuario'"></DeleteDialog> -->

        <v-btn
          v-tippy
          color="primary"
          fab
          dark
          fixed
          bottom
          right
          class="btnFab"
          content="Add User"
          to="/users-create"
        >
          <v-icon>mdi-plus</v-icon>
        </v-btn>

        <!-- </div> -->
      </v-card>
    </div>
  </div>
</template>

<script>
import MainHeader from "@/components/layout/MainHeader";
import Initials from "@/components/core/Initials";
// TODO: restore once backend api's are restored
import {
  GET_USERS_ALL, 
  // DELETE_USER 
} from "@/store/actionType";

export default {
  name: "usersIndex",
  components: {
    MainHeader,
    Initials,
    //DeleteDialog,
  },
  data() {
    return {
      search: "",
      //modalOpen: false,
      headers: [
        // { text: "User", value: "name" },
        { text: "Name", value: "full_name"},
        { text: "Email", value: "email" },
        // { text: "Password", value: "password" },
        { text: "Created At", value: "createdOn" },
        // { text: "Updated At", value: "updatedAt" },
        { text: "Type", value: "isAdmin" },
        { text: "", value: "action", width: 80, sorteble: false },
      ],

      items: [],
      showMenu: "",
    };
  },

  methods: {
    getAll() {
      this.$store
        .dispatch(GET_USERS_ALL)
        .then((r) => {
          // this.items = r.data;
          this.items = r.data.result.Users;
          console.log('/////',r.data.result)
        })
        .catch((r) => {
          console.error(r);
          console.error("Algo salió mal");
        });
    },

    // showMenuActions(item){
    //   if (this.$store.state.user.isAdmin && item.isAdmin) {
    //     console.log("Muestro menu")
    //   }
    // },

    // gotoUser(item) {
    //   if ((this.$store.state.user.isAdmin && item.isAdmin) || !item.isAdmin) {
    //     this.$router.push(`/users/${item.id}/update`);
    //   }
    // },
    deleteItem(id, name) {
      const response = confirm(`Are you sure you want to delete user: ${name}`);
      // if (response) {
      //   this.$store
      //     .dispatch(DELETE_USER, id)
      //     .then(() => {
      //       this.getAll();
      //       //this.users.data.splice(index, 1);
      //     })
      //     .catch((error) => {
      //       console.log(error);
      //     });
      // }
    },

    // openModal() {
    //         this.modalOpen = !this.modalOpen;
    //     }
  },

  mounted() {
    this.getAll();
  },
};
</script>

<style lang="scss" scoped>
// ::v-deep tbody tr {
//   cursor: pointer;
// }
// ::v-deep tbody tr td.non-clickable {
//   cursor: auto;
// }
.non-clickable {
  cursor: auto;
}
</style>
