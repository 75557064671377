import { mainAxios } from "./axios";

class FavService {
    getAll(className) {
        return mainAxios.get(`/favstar/${className}`);
    }
    setFav(id, className) {
        return mainAxios.post(`/favstar/${className}/${id}`);
    }
    unsetFav(id, className) {
        return mainAxios.delete(`/favstar/${className}/${id}`);
    }
    getStatusAll(className, q) {
        return mainAxios.get(`/${className}/favorite_status/?q=${JSON.stringify(q)}`);
    }
}

export default new FavService();

// http://fcgy-qs.azurewebsites.net/api/v1/favstar/project
// http://fcgy-qs.azurewebsites.net/api/v1/projects/