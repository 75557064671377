<template>
  <div class="main">
    <v-layout align-center justify-center column fill-height v-if="loading">
      <v-flex row align-center>
        <v-progress-circular
          size="70"
          :width="7"
          indeterminate
          color="primary"
        />
      </v-flex>
    </v-layout>
    <div v-if="layout">
      <grid-layout
        :layout.sync="layout"
        :col-num="12"
        :row-height="30"
        :is-draggable="true"
        :is-resizable="false"
        :is-mirrored="false"
        :vertical-compact="true"
        :margin="[10, 10]"
        :use-css-transforms="true"
        @layout-updated="layoutUpdatedEvent"
      >
        <grid-item
          v-for="item in layout"
          :x="item.x"
          :y="item.y"
          :w="item.w"
          :h="item.h"
          :i="item.i"
          :key="item.i"
        >
          <div :key="item.i" class="chart">
            <template v-if="selectedChart_Infos[item.i].viz_type !== 'table'">
              <highcharts :options="selectedChart_Infos[item.i].data" />
            </template>
            <template v-else>
              <ag-grid-vue
                class="ag-theme-alpine-dark"
                style="width: 100%; height: 400px"
                :columnDefs="selectedChart_Infos[item.i].data.cols"
                :defaultColDef="defaultColDef"
                :rowData="selectedChart_Infos[item.i].data.rows"
                :rowGroupPanelShow="rowGroupPanelShow"
                :sideBar="sideBar"
              />
            </template>
          </div>
        </grid-item>
      </grid-layout>
    </div>
  </div>
</template>

<script>
import { AgGridVue } from "ag-grid-vue";
import { LicenseManager } from "ag-grid-enterprise";
import VueGridLayout from "vue-grid-layout";

LicenseManager.setLicenseKey(process.env.VUE_APP_AG_LICENSE);

export default {
  name: "ProjectMain",
  components: {
    AgGridVue,
    GridLayout: VueGridLayout.GridLayout,
    GridItem: VueGridLayout.GridItem,
  },
  props: {
    selectedCharts: {
      type: Array,
      default() {
        return [];
      },
    },
    selectedChart_Infos: {
      type: Array,
      default() {
        return [];
      },
    },
    layout: {
      type: Array,
      default() {
        return [];
      },
    },
    loading: {
      type: Boolean,
      default() {
        return false;
      },
    },
  },
  data() {
    return {
      //default column def
      defaultColDef: {
        flex: 1,
        minWidth: 200,
        resizable: true,
        floatingFilter: true,
        sortable: true,
        filter: true,
        enableValue: true,
        enablePivot: true,
      },
      //row group panel show
      rowGroupPanelShow: null,
      // sideBar
      sideBar: null,
    };
  },
  created() {
    this.rowGroupPanelShow = "always";
    this.sideBar = { toolPanels: ["columns", "filters"] };
  },
  methods: {
    /**
     * layout change event
     */
    layoutUpdatedEvent(newLayout) {
      this.$emit("updateLayout", newLayout);
    },
  },
};
</script>

<style lang="scss" scoped>
.main {
  width: 100%;
}

.chart {
  width: 500px;
}
</style>