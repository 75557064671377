import { mainAxios } from "./axios";

class AuthService {
    login(params) {
        return mainAxios.post(`/security/login`, params)
        .then(res => {
            if (res && res.data) {
                mainAxios.defaults.headers.common['Authorization'] = `Bearer ${res.data.access_token}`
            }
            return res;
        },
        error => {
            return error;
        });
    }
    prepare(token) {
        mainAxios.defaults.headers.common = {
            'Authorization': `Bearer ${token}`
        };
    }
}

export default new AuthService();
