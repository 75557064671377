//authentication
export const LOGIN = "login";
export const LOGOUT = "logout";

//sql Query
export const GET_DB_ALL = "GET_DB_ALL";
export const GET_SCHEMA_ALL = "GET_SCHEMA_ALL";
export const GET_TABLE_ALL = "GET_TABLE_ALL";
export const FETCH_TABLE = "FETCH_TABLE";
export const RUN_QUERY = "RUN_QUERY";
export const GET_QUERY_HISTORY_ALL = "GET_QUERY_HISTORY_ALL";

//project
export const GET_PROJECT_BY_ID = "GET_PROJECT_BY_ID";
export const GET_PROJECT_ALL = "GET_PROJECT_ALL";
export const CREATE_PROJECT = "CREATE_PROJECT";
export const UPDATE_PROJECT = "UPDATE_PROJECT";
export const DELETE_PROJECT = "DELETE_PROJECT";
export const DELETE_PROJECT_MULTI = "DELETE_PROJECT_MULTI";
export const REMOVE_PROJECT_CHILDREN = "REMOVE_PROJECT_CHILDREN";
export const ADD_PROJECT_CHILDREN = "ADD_PROJECT_CHILDREN";

//cinema
// export const GET_CINEMA_BY_ID = "GET_CINEMA_BY_ID";
// export const GET_CINEMA_ALL = "GET_CINEMA_ALL";
// export const CREATE_CINEMA = "CREATE_CINEMA";
// export const UPDATE_CINEMA = "UPDATE_CINEMA";

//whitepaper
// export const GET_WHITEPAPER_BY_ID = "GET_WHITEPAPER_BY_ID";
// export const GET_WHITEPAPER_ALL = "GET_WHITEPAPER_ALL";
// export const CREATE_WHITEPAPER = "CREATE_WHITEPAPER";
// export const UPDATE_WHITEPAPER = "UPDATE_WHITEPAPER";
// export const DELETE_WHITEPAPER = "DELETE_WHITEPAPER";

//Chart
export const GET_CHART_BY_ID = "GET_CHART_BY_ID";
export const GET_CHART_ALL = "GET_CHART_ALL";
export const GET_CHART_FAVSTATUS_ALL = "GET_CHART_FAVSTATUS_ALL";
export const CREATE_CHART = "CREATE_CHART";
export const UPDATE_CHART = "UPDATE_CHART";
export const DELETE_CHART = "DELETE_CHART";
export const DELETE_CHART_MULTI = "DELETE_CHART_MULTI";
export const GET_CHART_DATA_BY_ID = "GET_CHART_DATA_BY_ID";
export const POST_CHART_DATA = "POST_CHART_DATA";
export const GET_CHART_LIST = "GET_CHART_LIST";

//Dashboard
export const GET_DASHBOARD_BY_ID = "GET_DASHBOARD_BY_ID";
export const GET_DASHBOARD_ALL = "GET_DASHBOARD_ALL";
export const CREATE_DASHBOARD = "CREATE_DASHBOARD";
export const UPDATE_DASHBOARD = "UPDATE_DASHBOARD";
export const DELETE_DASHBOARD = "DELETE_DASHBOARD";
export const DELETE_DASHBOARD_MULTI = "DELETE_DASHBOARD_MULTI";
export const GET_DASHBOARD_DATASET_BY_ID = "GET_DASHBOARD_DATASET_BY_ID";
export const GET_DASHBOARD_CHART_BY_ID = "GET_DASHBOARD_CHART_BY_ID";

//Dataset
export const GET_DATASET_BY_ID = "GET_DATASET_BY_ID";
export const GET_DATASET_DATA_BY_ID = "GET_DATASET_DATA_BY_ID";
export const GET_DATASET_ALL = "GET_DATASET_ALL";
export const GET_DATASET_FAVSTATUS_ALL = "GET_DATASET_FAVSTATUS_ALL";
export const CREATE_DATASET = "CREATE_DATASET";
export const UPDATE_DATASET = "UPDATE_DATASET";
export const DELETE_DATASET = "DELETE_DATASET";
export const DELETE_DATASET_MULTI = "DELETE_DATASET_MULTI";

//Favorites
export const GET_FAV_ALL = "GET_FAV_ALL";
export const GET_FAV_STATUS_ALL = "GET_FAV_STATUS_ALL";
export const SET_FAV = "SET_FAV";
export const UNSET_FAV = "UNSET_FAV";

//Data sources
export const UPLOAD_FILE = "UPLOAD_FILE";
export const TEST_DATABASE_CONNECTION = "TEST_DATABASE_CONNECTION";
export const CREATE_DB = "CREATE_DB";
export const EDIT_DB= "EDIT_DB"

//Database
export const GET_DATABASE_ALL = "GET_DATABASE_ALL";
export const GET_DATABASE_BY_ID = "GET_DATABASE_BY_ID";
export const DELETE_DATABASE = "DELETE_DATABASE";

//Saved Query
export const GET_SAVED_QUERY_ALL = "GET_SAVED_QUERY_ALL";
export const DELETE_SAVED_QUERY = "DELETE_SAVED_QUERY";
export const DELETE_SAVED_QUERY_MULTI = "DELETE_SAVED_QUERY_MULTI";
export const POST_SAVED_QUERY = "POST_SAVED_QUERY";

//Explore 
export const GET_EXPLORE_FORM_BY_ID = "GET_EXPLORE_FORM_BY_ID";
export const POST_CHART_FORM = "POST_CHART_FORM";
export const GET_CHART_FORM = "GET_CHART_FORM";
export const SAVE_FORM_DATA = "SAVE_FORM_DATA";

//Security 
export const GET_USERS_ALL = "GET_USERS_ALL";
export const REGISTER_USER = "REGISTER_USER";
export const RESET_USER = "RESET_USER";

//Char
export const CREATE_CHAR = "CREATE_CHAR";
export const DELETE_CHAR = "DELETE_CHAR";
export const TRAIN_MODEL = "TRAIN_MODEL";
export const GET_DATASET_CHAR_BY_ID = "GET_DATASET_CHAR_BY_ID";
export const PUT_DATASET_CHAR_INDEX = "PUT_DATASET_CHAR_INDEX"
export const GET_CHARS = "GET_CHARS";
export const CREATE_PREDICTION = "CREATE_PREDICTION";
export const GET_PREDICTION = "GET_PREDICTION";
export const GET_EVALUATION = "GET_EVALUATION";
export const TRAIN_EMBEDDING = "TRAIN_EMBEDDING";
export const POST_EMBEDDING = "POST_EMBEDDING";
export const GET_EMBEDDING = "GET_EMBEDDING";

//Async
export const GET_SPADE_JOB_STATUS = "GET_SPADE_JOB_STATUS";
