<template>
  <div>
    <v-snackbar
      :value="showSnackbar"
      :color="color"
    > 
      {{ snackbarError }}<br>
      {{ snackbarMsg }}
        <template v-slot:action="{ attrs }">
          <v-btn
          color="#3366FF"
          v-bind="attrs"
          @click="$emit('closeSnackbar')"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>

export default {
  name: "snackbarMessage",
  components: {},
  data() {
    return {}
  },
  props: {
    color: {
      type: String,
      default: "#ffbb33"
      // default: null,
    },
    showSnackbar: {
      type: Boolean,
      default: false
    }, 
    snackbarError: {
      type: String,
      default: null
    }, 
    snackbarMsg: {
      type: String,
      default: null
    },
  },
  methods: {},
  computed: {},
}

</script>

<style lang="scss" scoped>

</style>