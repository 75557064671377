import { mainAxios } from "./axios";

class ExploreService {
    getExploreFormById(id) {
        return mainAxios.get(`/explore/chart_form/${id}`);
    }
    postChartForm(data) {
        return mainAxios.post('/explore/chart_form', data);
    }
    getChartForm(data) {
        return mainAxios.post('/explore/get_form', data);
    }
    saveFormData(key, data) {
        return mainAxios.put(`/explore/chart_form/${key}`, data);
    }
}

export default new ExploreService();