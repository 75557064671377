
export default class Database {
  // required
  database_name=""
  sqlalchemy_uri=""
  // default
  verbose_name=null
  engine=null
  expose_in_sqllab=true
  allow_ctas=false
  allow_cvas=false
  allow_dml=false
  allow_file_upload=true
  allow_multi_schema_metadata_fetch=true
  allow_run_async=false
  cache_timeout=null
  configuration_method="sqlalchemy_form"
  encrypted_extra=""
  
  extra=null
  force_ctas_schema=null
  impersonate_user=false
  parameters={
    additionalProp1: "",
    additionalProp2: "",
    additionalProp3: "",
  }
  server_cert=""
  
  constructor()
  {
    // this.database_name = name;
    // this.sqlalchemy_uri = uri;
  }

  load(self, dbObject) {
    console.log('loader', self)
    console.log('newobj', dbObject)
    try {
      for(const key in dbObject) {
        self[key]=dbObject[key]
      }
    } catch (error) {
      console.error(error)
    }
  }
}