
// Reusable functions for Module pages


export function toggleSelectAll (e) {
  console.log('e', e)
    this.modifyAllCheck(e.value)    
}

export function modifyAllCheck(bool_val) {
  // set values
  this.collection.map(o => this.$set(o, 'checked', bool_val))
  // update grid objects
  let checked_items = this.collection.filter(x => {
    return x.checked === true
  })
  // console.log('items', checked_items)
  this.selectedItems=checked_items
}
export function selectItemsEvent(item) {
  switch (item.action) {
    case "select_all":
      this.modifyAllCheck(true)
      break;
    case "unselect_all":
      this.modifyAllCheck(false)
      break;
  }
}

export function filteredObjs (arr, column_name, val) {
  
  let filteredArr = arr.filter(
    (col) => {
      // let filtered_str = col[column_name] || ''
      // console.log('utils', filtered_str)
      return (col[column_name] +' '+ col['sql']+' '+ col['description']).includes(
      val.toLowerCase() || false
  )},
  );
  return filteredArr;
}