<template>
  <v-dialog v-model="enableEditChartTitle" max-width="300px" persistent>
    <v-card>
      <v-toolbar>Save Chart Title</v-toolbar>
      <v-card-text class="pl-0 pr-0 pt-2">
        <v-container>
          <p>Chart Title:</p>
          <v-text-field 
            v-model="name" 
            :rules="[rules.isEmpty, rules.isBlank]"
            outlined 
            required 
            v-on:keyup.enter="rename"
            >
          </v-text-field>
        </v-container>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="white" plain @click="cancel">Cancel</v-btn>
        <v-btn color="white" plain @click="rename">Save</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    enableEditChartTitle: {
      type: Boolean,
      default: false,
    },
    chartTitle: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      name: "",
      rules: {
          isEmpty: value => !!value || 'Required.',
          isBlank: value => value.trim() !== '' || 'Empty chart title',
      }
    };
  },
  mounted() {
    if (this.chartTitle !== null) {
      this.name = this.chartTitle;
    } else {
      this.name = "";
    }
    // this.rename = this.saveRename;
  },
  methods: {
    /**
     * rename action
     */
    rename() {
      this.$emit("rename", this.name);
    },
    /**
     * cancel action
     */
    cancel() {
      this.$emit("cancel");
    },
  },
};
</script>

<style>
</style>