import axios from 'axios';
import store from '../store/store';
import { LOGOUT } from "@/store/actionType";
import routes from '@/routes/index';
import VueRouter from 'vue-router';

let router = new VueRouter({
  routes
});

const mainAxios = axios.create({
  baseURL: process.env.VUE_APP_API_URL
});

mainAxios.interceptors.request.use(
  config => {
    config.headers["Authorization"] = `Bearer ${store.state.auth.initialState.token}`
    return config
  }, error => Promise.reject(error)
)

const spadeAxios = axios.create({
    baseURL: process.env.VUE_APP_SPADE_URL
});

mainAxios.interceptors.response.use(function (response) {
    // Do something with response data
    return response;
  }, function (error) {
  // Do something with response error
  console.log('MainAxios: Interceptor Error', error,);
  console.log('MainAxios: Interceptor Error', error?.response?.status);
  console.log('MainAxios Response Router: ', error.response.config.headers['Authorization']);
    // if (store.state.auth.initialState.token == null) {
    //   throw {error:
    //     {response: {status: 401}}
    //   };
    // }
    // console.log('AuthToken Request Use: ', store.state.auth.initialState.token);

  // if (error?.response?.status === 401 || error.response.config.headers['Authorization'] == 'Bearer null') {
    if (error?.response?.status === 401) {
    console.log('MainAxios: Redirection');
    store.dispatch(LOGOUT);
    router.push('/login').catch(()=>{});
    router.go() // refresh the page
    return;
  }
  // return Promise.reject(error);
  return Promise.reject(error.response);
});

spadeAxios.interceptors.request.use(
    config => {
        config.headers["X-API-KEY"] = "spade"
        return config
    }, error => Promise.reject(error))

export {
    mainAxios,
    spadeAxios
}