<template>
  <v-dialog :value="showDialog" max-width="300px" persistent>
    <v-card
      color="#454545"
    >
      <v-toolbar>Save Query</v-toolbar>
      <v-card-text class="pl-0 pr-0 pt-2">
        <v-container>
          <p>Query Title:</p>
          <v-text-field 
            v-model="name" 
            :rules="[rules.isEmpty, rules.isBlank]"
            outlined 
            required 
            v-on:keyup.enter="rename"
            >
          </v-text-field>
          <v-divider/>
          <v-card-subtitle>Description</v-card-subtitle>
          <v-textarea
            filled
            label=""
            v-model="description"
          ></v-textarea>
        </v-container>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="white" plain @click="cancel">Cancel</v-btn>
        <v-btn 
          :disabled="emptyQueryName"
          color="white" plain @click="rename
        ">Save</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      name: "",
      description: "",
      rules: {
          isEmpty: value => !!value || 'Required.',
          isBlank: value => value.trim() !== '' || 'Empty chart title',
      }
    };
  },
  computed: {
    emptyQueryName() {
      if (this.name.length <=0) {
      return true
      } else {
        return false
      }
    },
    showDialog: {
      get() { 
        return this.dialog;
      }, 
    }
  },
  methods: {
    /**
     * rename action
     */
    rename() {
      let text_data={
        name: this.name.trim(),
        description: this.description,
      }
      this.$emit("rename", text_data);
    },
    /**
     * cancel action
     */
    cancel() {
      this.$emit("cancel");
    },
  },
};
</script>

<style>
</style>