<template>
  <div @click.stop>
    <!-- edit button -->
    <v-btn
      icon
      v-tippy
      content="Edit"
      @click.stop="editItem"
      :disabled="disabledList['edit']"
    >
      <v-icon small>mdi-pencil</v-icon>
    </v-btn>
    <!-- trash button -->
    <v-btn
      icon
      v-tippy
      content="Delete"
      @click.stop="deleteItem"
      :disabled="disabledList['trashcan']"
    >
      <v-icon small>mdi-delete</v-icon>
    </v-btn>
    <!-- Info button -->
    <v-btn
      icon
      v-tippy
      content="Info"
      @click.stop="infoItem"
      :disabled="disabledList['info']"
    >
      <v-icon
        small
      >mdi-information-outline</v-icon>
    </v-btn>
    
    <SaveChartDialog
      :dialog="dialog"
      @rename="applyQueryTitle"
      @cancel="dialog=false"
    />
    <!-- Action Button -->
    <v-menu
      offset-y right z-index="100">
      <template v-slot:activator="{ on, attrs }">
        <v-btn small depressed height=0
          v-bind="attrs"
          v-on="on"
          :disabled="disabledList['action']"
        >
          <v-icon small
          >mdi-dots-vertical</v-icon>
        </v-btn>
      </template>
      <v-list dense color="#454545"
        v-if="selectActionOptions"
      >
        <v-list-item-group>
          <v-list-item
            dense
            v-for="(item, index) in selectActionOptions"
            :key="index"
            @click.stop="clickActionItem(item)"
          >
            <v-list-item-content>
              <v-list-item-title v-text="item.title"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-menu>
    </div>

</template>

<script>

import SaveChartDialog from "@/views/queryHistory/SaveChartTitle";

export default {
  name: "RowAction",
  components: {
    SaveChartDialog,
  },
  data: () => ({
    dialog: false,
  }),
  props: ["item","selectActionOptions", "disabledList"],
  // computed: {
    // isDisabled() {
      // console.log('this compute', this.disabledList)
      // return true;
    // }
  // },
  methods: {
    applyQueryTitle (query_name) {
      // console.log('input', query_name)
      this.dialog=false;
      this.$emit('actionItemEvent', 'save_query', this.item, query_name)
    },
    cancelSaveDialog () {
      this.dialog=false;
    },
    clickActionItem (action) {
      console.log('rows', action, this.item)
      switch (action.action) {
        case "save_query":
          this.dialog=true;
          break;
      }
      // this.$emit('actionItemEvent', action, this.item)
    },
    deleteItem() {
      this.$emit("deleteItem", this.item);
    },
    /**
     * Item Edit event
     */
    editItem() {
      this.$emit("editItem", this.item);
    },
    /**
     * Info Item Click event
     */
    infoItem() {
      this.$emit("infoItem", this.item);
    },
  },
};
</script>

<style scoped>
</style>
