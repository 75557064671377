import { mainAxios } from "./axios";

class DashboardService {
  get(id) {
    return mainAxios.get(`/dashboard/${id}`);
  }
  getAll(q) {
    return mainAxios.get(`/dashboard/?q=${JSON.stringify(q)}`);
  }
  create(params) {
    return mainAxios.post(`/dashboard`, params);
  }
  update(params) {
    let data = {...params};
    delete data.id;
    return mainAxios.put(`/dashboard/${params.id}`, data);
  }
  delete(id) {
    return mainAxios.delete(`/dashboard/${id}`);
  }
  deleteMulti(q) {
    return mainAxios.delete(`/dashboard/?q=${JSON.stringify(q)}`);
  }
  getDatasetByID(id) {
    return mainAxios.get(`/dashboard/${id}/datasets`);
  }
  getChartById(id) {
    return mainAxios.get(`/dashboard/${id}/charts`);
  }

}

export default new DashboardService();
