import FavService from "../services/FavService";

import {
    GET_FAV_ALL,
    GET_FAV_STATUS_ALL,
    SET_FAV,
    UNSET_FAV,
} from "./actionType";

const state = {};

const actions = {
    [GET_FAV_ALL](_, className) {
        return FavService.getAll(className).then(
            res => {
                return res;
            }
        )
    },
    [GET_FAV_STATUS_ALL](_, param) {
        return FavService.getStatusAll(param.className, param.q).then(
            res => {
                return res;
            }
        )
    },
    [SET_FAV](_, param) {
        return FavService.setFav(param.id, param.className).then(
            res => {
                return res;
            }
        )
    },
    [UNSET_FAV](_, param) {
        return FavService.unsetFav(param.id, param.className).then(
            res => {
                return res;
            }
        )
    }
};

const mutations = {};

export default {
    state,
    actions,
    mutations
}