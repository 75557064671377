<template>
  <div class="project">
    <ProjectHeader />
    <div class="project-container">
      <ProjectNav
        :selectedCharts="selectedCharts"
        :filters="filters"
        @saveChart="saveChart"
        @saveAction="saveAction"
        @saveFilter='saveFilter'
      />
      <ProjectMain
        :selectedCharts="selectedCharts"
        :selectedChart_Infos="selectedChart_Infos"
        :layout="layout"
        :loading="loading"
        @updateLayout="updateLayout"
      />
    </div>
  </div>
</template>

<script>
import ProjectHeader from "./components/ProjectHeader.vue";
import ProjectNav from "./components/ProjectNav.vue";
import ProjectMain from "./components/ProjectMain.vue";
import { GET_CHART_LIST } from "@/store/actionType";
import Highcharts, { chart } from "highcharts";
import { GET_DASHBOARD_BY_ID, UPDATE_DASHBOARD } from "@/store/actionType";

export default {
  name: "Projects-Detail",
  components: {
    ProjectHeader,
    ProjectNav,
    ProjectMain,
  },
  data() {
    return {
      selectedCharts: [],
      selectedChart_Infos: [],
      layout: [],
      id: null,
      position: null,
      loading: false,
      chart_list: [],
      order_charts: [],
      chart_data: null,
      filters: []
    };
  },
  mounted() {
    this.id = this.$route.params.id;
    this.$store.dispatch(GET_DASHBOARD_BY_ID, this.id).then((res) => {
      this.chart_data = res.data.result;
      // get position information
      this.position = JSON.parse(res.data.result.position_json);

      //layout array
      this.order_charts = [];
      //check position information
      if (this.position) {
        //get rows
        const rows = this.position.GRID_ID.children;
        let index = 0;
        //make layout
        for (let j = 0; j < rows.length; j++) {
          for (let i = 0; i < this.position[rows[j]].children.length; i++) {
            if (this.position[rows[j]].children[i]) {
              //layout item
              const data = {
                id: this.position[this.position[rows[j]].children[i]].meta
                  .chartId,
                x: i * 4,
                y: j * 10,
                index: index,
              };
              this.order_charts.push(data);
              index++;
            }
          }
        }
      }
      //get chart list
      this.chart_list = res.data.result.slices_meta;
      if (this.chart_list.length > 0) {
        let ids = this.chart_list.map((chart) => {
          return chart.slice_id;
        });
        const sendingData = {
          ids: ids,
          filter: null,
        };
        this.getCharts(sendingData);
      }
    });
  },
  methods: {
    /**
     * get charts from existing chart lists
     */
    saveChart(chartList) {
      this.layout = [];
      this.selectedChart_Infos = [];
      this.selectedCharts = [...chartList];
      let ids = this.selectedCharts.map((chart) => chart.id);
      this.order_charts = [];
      const sendingData = {
        ids: ids,
        filter: null,
      };
      this.getCharts(sendingData);
    },
    /**
     * get chart informatin
     */
    getCharts(sendingData) {
      //loading bar flag
      this.loading = true;
      //get chart list
      this.$store.dispatch(GET_CHART_LIST, sendingData).then((res) => {
        let chartInfos = res.data.result;
        for (let i = 0; i < chartInfos.length; i++) {
          let layout_item = {};
          let info = {};
          let find_chart = {};
          if (this.order_charts.length > 0) {
            find_chart = this.order_charts.find(
              (item) => item.id === chartInfos[i].id
            );
            layout_item = {
              x: find_chart.x,
              y: find_chart.y,
              w: 4,
              h: 10,
              i: find_chart.index,
            };
          } else {
            layout_item = {
              x: (i % 3) * 4,
              y: parseInt(i / 3) * 10,
              w: 4,
              h: 10,
              i: i,
            };
          }

          this.layout.push(layout_item);
          //data transform
          if (chartInfos[i].chart_metadata.viz_type === "pie") {
            let viz_data = chartInfos[i].result[0];
            let series = [];
            let nameFields = [];
            let valueField;
            for (let i = 0; i < viz_data.colnames.length; i++) {
              if (viz_data.coltypes[i] === 1) {
                nameFields.push(viz_data.colnames[i]);
              } else {
                valueField = viz_data.colnames[i];
              }
            }
            for (let item of viz_data.data) {
              let name = "";
              for (let nameField of nameFields) {
                name += " " + item[nameField];
              }
              series.push({
                name: name.substring(1),
                y: item[valueField],
              });
            }
            info = {
              id: chartInfos[i].id,
              name: chartInfos[i].chart_name,
              viz_type: chartInfos[i].chart_metadata.viz_type,
              data: {
                chart: {
                  type: "pie",
                  marginTop: 40,
                  marginBottom: 80,
                  plotBorderWidth: 1,
                  backgroundColor: "rgb(21, 21, 21)",
                },
                credits: {
                  enabled: false,
                },
                title: {
                  text: "",
                },
                series: [
                  {
                    data: series,
                  },
                ],
              },
            };
          } else if (chartInfos[i].chart_metadata.viz_type === "heatmap") {
            let viz_data = chartInfos[i].result[0];
            let xList = viz_data.data.records.map((item) => item.x);
            let xCategories = [...new Set(xList)];
            let yList = viz_data.data.records.map((item) => item.y);
            let yCategories = [...new Set(yList)];
            let series = [];
            for (let i = 0; i < xCategories.length; i++) {
              for (let j = 0; j < yCategories.length; j++) {
                let find_val = viz_data.data.records.find(
                  (item) =>
                    item.x === xCategories[i] && item.y === yCategories[j]
                );
                if (find_val) {
                  series.push([i, j, find_val.v]);
                }
              }
            }
            info = {
              id: chartInfos[i].id,
              name: chartInfos[i].chart_name,
              viz_type: chartInfos[i].chart_metadata.viz_type,
              data: {
                chart: {
                  type: "heatmap",
                  marginTop: 40,
                  marginBottom: 80,
                  plotBorderWidth: 1,
                  backgroundColor: "rgb(21, 21, 21)",
                },
                title: {
                  text: "",
                },
                xAxis: {
                  categories: xCategories,
                },
                yAxis: {
                  categories: yCategories,
                },
                colorAxis: {
                  min: 0,
                  minColor: "#FFFFFF",
                  maxColor: Highcharts.getOptions().colors[0],
                },
                legend: {
                  align: "right",
                  layout: "vertical",
                  margin: 0,
                  verticalAlign: "top",
                  y: 25,
                  symbolHeight: 280,
                },
                tooltip: {
                  formatter: function () {
                    return (
                      "<b>" +
                      this.series.xAxis.categories[this.point.x] +
                      "</b> sold <br><b>" +
                      this.point.value +
                      "</b> items on <br><b>" +
                      this.series.yAxis.categories[this.point.y] +
                      "</b>"
                    );
                  },
                },
                series: [
                  {
                    borderWidth: 1,
                    data: series,
                    dataLabels: {
                      enabled: true,
                      color: "#000000",
                    },
                  },
                ],
              },
            };
          } else if (chartInfos[i].chart_metadata.viz_type === "table") {
            let cols = [];
            let headers = [];
            let rows = chartInfos[i].result[0].data;
            for (let col of chartInfos[i].result[0].colnames) {
              cols.push({
                headerName: col,
                field: col,
                enableRowGroup: true,
              });
              headers.push({
                text: col,
                sortable: true,
                value: col,
              });
            }
            info = {
              id: chartInfos[i].id,
              name: chartInfos[i].chart_name,
              viz_type: chartInfos[i].chart_metadata.viz_type,
              data: {
                cols: cols,
                headers: headers,
                rows: rows,
              },
            };
          } else if (chartInfos[i].chart_metadata.viz_type === "bubble") {
            let viz_data = chartInfos[i].result[0];
            let series = [];
            for (let item of viz_data.data) {
              series.push({
                ...item,
                x: item[`${chartInfos[i].chart_metadata.x.label}`],
                y: item[`${chartInfos[i].chart_metadata.y.label}`],
              });
            }
            info = {
              id: chartInfos[i].id,
              name: chartInfos[i].chart_name,
              viz_type: chartInfos[i].chart_metadata.viz_type,
              data: {
                chart: {
                  type: "bubble",
                  plotBorderWidth: 1,
                  zoomType: "xy",
                  backgroundColor: "rgb(21, 21, 21)",
                },
                legend: {
                  enabled: false,
                },
                title: {
                  text: "",
                },
                tickmarkPlacement: "on",
                xAxis: {
                  gridLineWidth: 1,
                  title: {
                    text: chartInfos[i].chart_metadata.x.label,
                  },
                  labels: {
                    format: "{value}",
                  },
                },
                yAxis: {
                  startOnTick: false,
                  endOnTick: false,
                  title: {
                    text: chartInfos[i].chart_metadata.y.label,
                  },
                  labels: {
                    format: "{value}",
                  },
                  maxPadding: 0.2,
                },
                plotOptions: {
                  series: {
                    dataLabels: {
                      enabled: true,
                      format: `{point.${chartInfos[i].chart_metadata.series}}`,
                    },
                  },
                },
                series: [
                  {
                    data: series,
                  },
                ],
              },
            };
          } else if (
            chartInfos[i].chart_metadata.viz_type === "dist_bar" ||
            chartInfos[i].chart_metadata.viz_type === "horiz_bar"
          ) {
            let viz_data = chartInfos[i].result[0];
            let xlist = viz_data.data[0].values.map((item) => item.y);
            let xCategories = [...new Set(xlist)];
            let series = [];
            for (let viz_item of viz_data.data) {
              let ylist = viz_item.values.map((item) => item.y);
              let yVals = [...new Set(ylist)];
              series.push({
                name: viz_item.key,
                data: yVals,
              });
            }
            info = {
              id: chartInfos[i].id,
              name: chartInfos[i].chart_name,
              viz_type: chartInfos[i].chart_metadata.viz_type,
              data: {
                chart: {
                  type:
                    chartInfos[i].chart_metadata.viz_type === "horiz_bar"
                      ? "bar"
                      : "column",
                  backgroundColor: "rgb(21, 21, 21)",
                },
                xAxis: {
                  tickWidth: 0,
                  labels: {
                    style: {
                      color: "#fff",
                    },
                  },
                  categories: xCategories,
                },
                yAxis: {
                  gridLineWidth: 0.5,
                  gridLineDashStyle: "dash",
                  gridLineColor: "black",
                  title: {
                    text: "",
                    style: {
                      color: "#fff",
                    },
                  },
                  labels: {
                    formatter: function () {
                      return Highcharts.numberFormat(this.value, 0, "", ",");
                    },
                    style: {
                      color: "#fff",
                    },
                  },
                },
                legend: {
                  enabled: false,
                },
                credits: {
                  enabled: false,
                },
                tooltip: {
                  valuePrefix: "",
                },
                plotOptions: {
                  column: {
                    borderRadius: 0,
                    pointPadding: 0,
                    groupPadding: 0.05,
                  },
                },
                series: series,
              },
            };
          } else if (chartInfos[i].chart_metadata.viz_type === "box_plot") {
            let viz_data = chartInfos[i].result[0];
            let xVals = viz_data.colnames.filter(
              (item) => !item.includes("__")
            );
            let yVals = viz_data.colnames.filter((item) =>
              item.includes("mean")
            );
            let yCategories = yVals.map((item) => item.slice(0, -6));
            let xCategories = viz_data.data.map((item) => {
              let result = "";
              for (let i = 0; i < xVals.length; i++) {
                if (i === 0) {
                  result += `${item[xVals[i]]}`;
                } else {
                  result += `, ${item[xVals[i]]}`;
                }
              }
              return result;
            });
            let series = [];
            let yCat_data = [];
            for (let yCategory of yCategories) {
              let series_data = viz_data.data.map((item) => {
                return [
                  item[`${yCategory}__min`],
                  item[`${yCategory}__q1`],
                  item[`${yCategory}__median`],
                  item[`${yCategory}__q3`],
                  item[`${yCategory}__max`],
                ];
              });
              let outlier_data = viz_data.data.map((item) => {
                return [item[`${yCategory}__outliers`]];
              });
              series.push({
                name: yCategory,
                data: series_data,
                tooltip: {
                  headerFormat: "<em>{point.key}</em><br/>",
                },
              });
              series.push({
                name: "Outliers",
                color: Highcharts.getOptions().colors[0],
                type: "scatter",
                data: outlier_data,
                marker: {
                  fillColor: "white",
                  lineWidth: 1,
                  lineColor: Highcharts.getOptions().colors[0],
                },
                tooltip: {
                  pointFormat: "Observation: {point.y}",
                },
              });
              yCat_data.push({
                title: {
                  text: yCategory,
                },
              });
            }
            info = {
              id: chartInfos[i].id,
              name: chartInfos[i].chart_name,
              viz_type: chartInfos[i].chart_metadata.viz_type,
              data: {
                chart: {
                  type: "boxplot",
                },
                title: {
                  text: "",
                },
                legend: {
                  enabled: true,
                },
                xAxis: {
                  categories: xCategories,
                },
                yAxis: yCat_data,
                series: series,
              },
            };
          }
          if (find_chart.index !== undefined) {
            this.selectedChart_Infos[find_chart.index] = info;
          } else {
            this.selectedChart_Infos.push(info);
          }
          console.log(this.selectedChart_Infos)
        }
        this.loading = false;
      });
    },
    updateLayout(layout) {},
    generateString() {
      const characters =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      let result = "";
      const charactersLength = characters.length;
      for (let i = 0; i < 10; i++) {
        result += characters.charAt(
          Math.floor(Math.random() * charactersLength)
        );
      }
      return result;
    },
    /**
     * save changes
     */
    saveAction() {
      let position = {
        DASHBOARD_VERSION_KEY: "v2",
        GRID_ID: {
          children: [],
          id: "GRID_ID",
          parents: ["ROOT_ID"],
          type: "GRID",
        },
        HEADER_ID: {
          id: "HEADER_ID",
          meta: { text: "debug" },
          type: "HEADER",
        },
        ROOT_ID: { children: ["GRID_ID"], id: "ROOT_ID", type: "ROOT" },
      };
      let charts = [];
      for (let item of this.layout) {
        charts.push(this.selectedChart_Infos[item.i].name);
        const new_chart = `CHART-${this.generateString()}`;
        if (position.GRID_ID.children[parseInt(item.y / 10)]) {
          const row = position.GRID_ID.children[parseInt(item.y / 10)];
          const col = parseInt(item.x / 4);
          position[row].children[col] = new_chart;
          position[new_chart] = {
            children: [],
            id: new_chart,
            meta: {
              chartId: this.selectedChart_Infos[item.i].id,
              height: 50,
              sliceName: this.selectedChart_Infos[item.i].name,
              uuid: crypto.randomUUID(),
              width: 4,
            },
            parents: ["ROOT_ID", "GRID_ID", row],
            type: "CHART",
          };
        } else {
          const new_row = `ROW-${this.generateString()}`;
          const row = parseInt(item.y / 10);
          const col = parseInt(item.x / 4);
          position.GRID_ID.children[row] = new_row;
          position[new_row] = {
            children: [],
            id: new_row,
            meta: { background: "BACKGROUND_TRANSPARENT" },
            parents: ["ROOT_ID", "GRID_ID"],
            type: "ROW",
          };
          position[new_row].children[col] = new_chart;
          position[new_chart] = {
            children: [],
            id: new_chart,
            meta: {
              chartId: this.selectedChart_Infos[item.i].id,
              height: 50,
              sliceName: this.selectedChart_Infos[item.i].name,
              uuid: crypto.randomUUID(),
              width: 4,
            },
            parents: ["ROOT_ID", "GRID_ID", new_row],
            type: "CHART",
          };
        }
      }
      let metadata = JSON.parse(this.chart_data.json_metadata);
      metadata.positions = position;
      const data = {
        json_metadata: JSON.stringify(metadata),
        id: this.chart_data.id,
      };
      this.$store.dispatch(UPDATE_DASHBOARD, data).then((res) => {
        console.log(res);
      });
    },
    /**
     * save filter
     * @data filter data
     */
    saveFilter(data) {
      this.filters.push(data);
    }
  },
};
</script>

<style lang="scss" scoped>
.project {
  width: 100%;
  height: 100%;

  &-container {
    border-top: 0.5px solid #373737;
    margin-top: 48px;
    display: flex;
    height: calc(100% - 48px);
  }
}
</style>
