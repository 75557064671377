var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ModuleAction',{staticClass:"pa-0 pt-3",attrs:{"title":"Chart","search-label":"Search Chart Titles","selectedItems":_vm.selectedItemIds,"newDropdown":true,"n_items":_vm.filteredItemCount},on:{"deleteAll":function($event){return _vm.deleteAll("charts")},"selectItemsEvent":_vm.selectItemsEvent,"menuClick":_vm.menuClick,"moduleFilterChanged":_vm.moduleFilterChanged}}),(_vm.viewGrid)?_c('div',[_c('v-container',{staticClass:"pa-0 pt-3"},[_c('v-row',{staticClass:"container-row-resolution mt-3"},_vm._l((_vm.collection),function(item){return _c('BoxItem',{key:item.id,attrs:{"item":item,"class-name":"slice","title-field":"chart","selectable":true},on:{"checked":_vm.toggleChecked,"clickItem":_vm.clickChart}})}),1)],1)],1):_vm._e(),(!_vm.viewGrid)?_c('div',{staticClass:"\n          d-flex\n          flex-grow-1 flex-column flex-basis-0\n          fill-height\n          overflow-x-auto\n        "},[_c('v-data-table',{staticClass:"mt-3",attrs:{"id":"tblCharts","items":_vm.collection,"headers":_vm.chartHeaders,"search":_vm.searchChart,"show-select":"","item-key":"id","hide-default-footer":true,"header-props":{ sortIcon: 'mdi-chevron-down' }},on:{"click:row":_vm.clickChart,"item-selected":_vm.updateGridViewSelection,"toggle-select-all":_vm.toggleSelectAll},scopedSlots:_vm._u([{key:"top",fn:function(ref){
var pagination = ref.pagination;
var options = ref.options;
var updateOptions = ref.updateOptions;
return [_c('v-data-footer',{attrs:{"pagination":pagination,"options":options,"items-per-page-text":"$vuetify.dataTable.itemsPerPageText"},on:{"update:options":updateOptions}})]}},{key:"header.favorite",fn:function(ref){
var header = ref.header;
return [(header.favorite)?_c('v-icon',[_vm._v("mdi-star")]):_c('v-icon',[_vm._v("mdi-star-outline")])]}},{key:"item.favorite",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"actions"},[_c('v-btn',{directives:[{name:"tippy",rawName:"v-tippy"}],attrs:{"icon":"","width":"24","content":"Favorite"}},[(item.favorite)?_c('v-icon',[_vm._v("mdi-star")]):_c('v-icon',[_vm._v("mdi-star-outline")])],1)],1)]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('RowAction',{attrs:{"item":item,"disabledList":_vm.disabledRowActions},on:{"clickItem":_vm.clickChart,"deleteItem":_vm.deleteItem}})]}}],null,true),model:{value:(_vm.selectedItems),callback:function ($$v) {_vm.selectedItems=$$v},expression:"selectedItems"}})],1):_vm._e(),_c('div',[_c('SelectCharts',{attrs:{"projectMeta":_vm.project},on:{"reloadProject":function($event){return _vm.$emit('reloadProject', _vm.project.id)},"showErrorSnackbar":function($event){_vm.snackbar=true},"closeDialog":_vm.closeDialog},model:{value:(_vm.vis_select_modal),callback:function ($$v) {_vm.vis_select_modal=$$v},expression:"vis_select_modal"}})],1),_c('v-snackbar',{attrs:{"color":"#ffbb33"},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"color":"#3366FF"},on:{"click":function($event){_vm.snackbar = false}}},'v-btn',attrs,false),[_vm._v(" Close ")])]}}]),model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" Failed to add charts to Project ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }