<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height="18"
    viewBox="0 0 36 36"
    width="18"
    aria-hidden="false"
    role="img"
    :fill=fill
    aria-label="title"
  >
    <path
      d="M33 10H6V4h27a1 1 0 0 1 1 1v4a1 1 0 0 1-1 1ZM23 18H6v-6h17a1 1 0 0 1 1 1v4a1 1 0 0 1-1 1ZM15 26H6v-6h9a1 1 0 0 1 1 1v4a1 1 0 0 1-1 1ZM11 34H6v-6h5a1 1 0 0 1 1 1v4a1 1 0 0 1-1 1Z"
    />
    <rect height="34" rx=".5" ry=".5" width="2" x="2" y="2" />
  </svg>
</template>

<script>
export default {
  name: "hbarIcon",
  props: {
    fill: {
      type: String,
      default() {
        return "#B9B9B9";
      },
    },
  },
};
</script>

<style>
.fill {
  fill: #B9B9B9;
  /* fill: blue; */
}
</style>