<template>
  <div class="d-flex flex-column fill-height flex-grow-1 overflow-hidden pt-6">
    <ModuleHeader
      :title="`Projects /  New Project`" 
      :show-layout-option = "false"
      returnToPage="Projects-Index"  
    />
    <v-container fluid  fill-height align-start>
      <v-row>
        <!-- New Project Metadata -->
        <v-col
          cols="3"
          class="flex-grow-0 flex-shrink-0"
          style="min-width: 100px;"
        >
          <v-form
            ref="form"
            v-model="formValid"
            lazy-validation
          >
            <v-card
              class="mx-auto"
              max-width="344"
              style="min-height: 80vh; background: rgb(18,18,18)"
            >
              <v-card-title style="text-decoration: underline">
                Projects
              </v-card-title>
              
              <v-container>
                <div class="mb-3">Title</div>
                <v-text-field
                  filled
                  label=""
                  v-model="title"
                  :rules="titleRules"
                ></v-text-field>
                
                <p class="mt-5">Description</p>
                <!-- :rules="descriptionRules" -->
                <v-textarea
                  filled
                  label=""
                  v-model="description"
                ></v-textarea>

                <p class="mt-5">Members</p>
                <v-autocomplete
                  v-model="selectedUsers"
                  :items="users"
                  disabled
                  label="Coming Soon..."
                  item-value="userId"
                  item-text="username"
                  dense
                  chips
                  filled
                  multiple
                ></v-autocomplete>
              </v-container>
              <v-card-actions class="justify-center">
                <v-btn
                  depressed
                  @click="cancel"
                >
                  Cancel
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn
                  color="primary"
                  @click="create"
                >
                  Apply
                </v-btn>

              </v-card-actions>
            </v-card>
          </v-form>
        </v-col>
        <!-- Charts and Dashboards -->
        <v-col
          cols="9"
          style="min-width: 500px; padding: 0 !important;"
          class=""
        >
          <div class="d-flex flex-row mt-5">
            <!-- Include Charts -->
            <div class="align-center mr-7" style="flex:1">
              <p>Charts</p>
              <v-autocomplete
                v-model="selectedCharts"
                :items="charts"
                item-value="id"
                item-text="slice_name"
                dense
                chips
                filled
                multiple
              ></v-autocomplete>
            </div>
            <!-- Include Dashboards -->
            <div class="align-center" style="flex:1">
              <p>Dashboards</p>
              <v-autocomplete
                v-model="selectedDashboards"
                :items="dashboards"
                item-value="id"
                item-text="dashboard_title"
                dense
                chips
                filled
                multiple
              ></v-autocomplete>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import {
  GET_CHART_ALL,
  GET_DASHBOARD_ALL,
  CREATE_PROJECT,
  GET_USERS_ALL,
} from "@/store/actionType";

import ModuleHeader from "@/views/_partials/ModuleHeader";

export default {
  name: "NewProject",
  components: {
    ModuleHeader
  },
  data(){
    return {
      users: [],
      selectedUsers: [],
      charts: [],
      selectedCharts: [],
      dashboards: [],
      selectedDashboards: [],
      selectedItems: [],
      search: "",
      title: "",
      description: "",
      collection: [],
      formValid: true,
      titleRules: [
        v => !!v.trim() || 'Title is required',
      ],
      // descriptionRules: [
        // v => !!v.trim() || 'Description is required',
      // ]
    }
  },
  methods:{
    load() {
      const chartQ = {
        columns: [
          "id",
          "slice_name",
        ],
      };

      // Load Charts
      this.$store
        .dispatch(GET_CHART_ALL, chartQ)
        .then((r) => {
          this.charts  = r.data.result;
        })
        .catch((r) => {
          console.error("We have error:", r);
        });

      const dashboardQ = {
        columns: [
          "id",
          "dashboard_title",
        ]
      };

      // Load Dashboards
      this.$store
        .dispatch(GET_DASHBOARD_ALL, dashboardQ)
        .then((r) => {
          this.dashboards = r.data.result;
        })
        .catch((r) => {
          console.error("We have error:", r);
        });

      // Load Users (Owners)
      this.$store
        .dispatch(GET_USERS_ALL)
        .then((r) => {
          this.users = r.data.result.Users;
        })
        .catch((r) => {
          console.error("We have error:", r);
        });
    },

    /**
     * click item in table
     * @param item
     */
    clickItem(item) {
      // if (item.form_data_key) {
      //   this.$router.push(
      //     `/visualizations/${item.id}/${item.form_data_key}`
      //   );
      // } else {
      //   this.$store.dispatch(GET_CHART_BY_ID, item.id).then((res) => {
      //     let param = JSON.parse(res.data.result.params);
      //     let form_data_key = param.url_params.form_data_key;
      //     this.$router.push(`/visualizations/${item.id}/${form_data_key}`);
      //   });
      // }
    },
    create() {
      if (!this.$refs.form.validate()) return;
      const params = {
        project_title: this.title,
        description: this.description,
        dashboards: this.selectedDashboards,
        slices: this.selectedCharts,
        members: this.selectedUsers
      }
      this.$store
        .dispatch(CREATE_PROJECT, params)
        .then((r) => {
          this.$router.push({ name: 'Projects-Detail', params: { id: r.data.id } });
        })
        .catch((r) => {
          console.error("We have error: ", r);
        });
    },
    cancel() {
      this.$refs.form.reset();
    }
  },
  mounted() {
    this.load();
  }
}
</script>

<style scoped>

</style>