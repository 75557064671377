<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height="18"
    viewBox="0 0 18 18"
    width="18"
  >
    <title>S GraphPie 18 N</title>
    <rect id="Canvas" fill="#ff13dc" opacity="0" width="18" height="18" />
    <path
      class="fill"
      d="M8,6.3305V1.365a.25748.25748,0,0,0-.294-.2535A7.976,7.976,0,0,0,3.514,3.193a.25551.25551,0,0,0,.0285.3895l4.0605,2.95A.25.25,0,0,0,8,6.3305Zm2-4.966v15.271a.25651.25651,0,0,0,.2935.253,7.993,7.993,0,0,0,0-15.7775A.25649.25649,0,0,0,10,1.3645ZM1,9a7.99651,7.99651,0,0,0,6.7065,7.8885A.25649.25649,0,0,0,8,16.6355V9.8535a.25.25,0,0,0-.103-.2L2.155,5.4795a.255.255,0,0,0-.378.092A7.936,7.936,0,0,0,1,9Z"
    />
  </svg>
</template>

<script>
export default {
    name: "Pie"
};
</script>

<style>
.fill {
  fill: #FFFFFF;
}
</style>