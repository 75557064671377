<template >
<!--<template v-if="pie_data"> -->
  <div v-if="!!vis_data">
    <!-- {{ heatmap_data() }} -->
    <highcharts :options="heatmap_data()" class="pie-chart" />
  </div>
</template>


<script>
import Highcharts from "highcharts"
require("highcharts/modules/accessibility");(Highcharts);
require("highcharts/modules/no-data-to-display")(Highcharts);

// These enable the export hamburger
// require("highcharts/modules/export-data")(Highcharts);
// require("highcharts/modules/exporting")(Highcharts);

export default {
  name: "HeatmapVis",
  components: {
  },
  props: {
    vis_data: {
      default() {
        return {
          'row_data': [],
          'columns': [],
          'coltypes': [],
        };
      },
    },
  },
  data() {
    return {
      // pie_data: null,
    };
  },
  computed: {
    vis_data_cols() {
      console.log('comnputed pie', this.vis_data)
      // return this.vis_data
      return this.vis_data && this.vis_data.columns ? this.vis_data.columns : [];
    //   console.log('compute cols', this.vis_data)
    //   return this.vis_data && this.vis_data.cols ? this.vis_data.cols : [];
    },
    vis_data_coltypes() {
      console.log('comnputed pie', this.vis_data)
      // return this.vis_data
      return this.vis_data && this.vis_data.coltypes ? this.vis_data.coltypes : [];
    //   console.log('compute cols', this.vis_data)
    //   return this.vis_data && this.vis_data.cols ? this.vis_data.cols : [];
    },
    vis_data_rows() {
      console.log('compute cols', this.vis_data)
      return this.vis_data && this.vis_data.row_data ? this.vis_data.row_data : [];
    },
  },
  methods: {
    heatmap_data() {
      const vis_data = this.vis_data.vis_data
      const headers = this.vis_data.headers.map(col => col.value)
      // console.log('my pie', this.vis_data.headers.map(col => col.value))
      const xlist = vis_data.map((item) => item.x);
      const ylist = vis_data.map((item) => item.y);
      const xCategories = [...new Set(xlist)];
      const yCategories = [...new Set(ylist)];


      console.log('xlist', vis_data)
      let series = [];
      for (let i = 0; i < xCategories.length; i++) {
        for (let j = 0; j < yCategories.length; j++) {
          // console.log('iter', i,j, xCategories[i], yCategories[j])
          // if ( vis_data[i].x === )
          // let v_value = j;
          //vis_data[i].v
          let find_val = vis_data.find(
            (item) =>
              item.x === xCategories[i] && item.y === yCategories[j]
          );
          let v_val = find_val ? find_val.v : null;
          // console.log('iter', v_val)
          series.push([i, j, v_val ]);
        }
      }
      function getPointCategoryName(point, dimension) {
        var series = point.series,
          isY = dimension === 'y',
          axis = series[isY ? 'yAxis' : 'xAxis'];
        return axis.categories[point[isY ? 'y' : 'x']];
      }
      console.log('series', series)
      const heatmap_data = {
        chart: {
          type: "heatmap",
          marginTop: 40,
          marginBottom: 80,
          plotBorderWidth: 1,
          backgroundColor: "rgb(21, 21, 21)",
        },
        credits: {
          enabled: false,
        },
        title: {
          text: "",
        },
        xAxis: {
          categories: xCategories,
        },
        yAxis: {
          categories: yCategories,
        },
        colorAxis: {
          min: 0,
          minColor: "#FFFFFF",
          maxColor: Highcharts.getOptions().colors[0],
        },
        legend: {
          align: "right",
          layout: "vertical",
          margin: 0,
          verticalAlign: "top",
          y: 25,
          symbolHeight: 280,
        },
        tooltip: {
          formatter: function () {
            return (
              '<b>' + headers[0] +": "+ getPointCategoryName(this.point, 'x') + '</b><br>' +
              '<b>' + headers[1] +": "+ getPointCategoryName(this.point, 'y') + '</b><br>' +
              '<b>' + headers[2] +": "+ this.point.value + '</b>'
              // this.point.value + '</b> items on <br><b>' + getPointCategoryName(this.point, 'y') + '</b>'
            )
          }
        },
        // tooltip: {
        //   formatter: function () {
        //     return (
        //       //  + ":"
        //       "<b> { headers[0] } </b>" +
        //       this.series.xAxis.categories[this.point.x] +
        //       "<br>"+ headers[1] + ":" +
        //       this.series.yAxis.categories[this.point.y] +
        //       "<br>"+ headers[2] + ":" +
        //       this.point.value
        //     );
        //   },
        // },
        series: [
          {
            borderWidth: 1,
            data: series,
            dataLabels: {
              enabled: true,
              color: "#000000",
            },
          },
        ],
      };
    
      return heatmap_data;
    },
  },
}
</script>

<style lang="scss" scoped>

</style>