import ChartService from "../services/ChartService";

import {
  GET_CHART_BY_ID,
  GET_CHART_ALL,
  CREATE_CHART,
  UPDATE_CHART,
  DELETE_CHART,
  DELETE_CHART_MULTI,
  GET_CHART_DATA_BY_ID,
  POST_CHART_DATA,
  GET_CHART_LIST
} from "./actionType";

const state = {
  sel_col: null,
  sel_metric: null,
  sel_x: null,
  sel_y: null,
  sel_size: null,
  sel_pie_metric: null,
  pieCategories: [],
  sel_heat_metric: null,
  sel_x_label: "",
  sel_y_label: "",
  sel_size_label: "",
  series: "",
  entity: "",
  bar_metrics: [],
  bar_series: [],
  bar_categories: [],
  project_info: null,
};

const actions = {
  [GET_CHART_BY_ID](_, id) {
    return ChartService.get(id).then(
      res => {
        return res;
      }
    )
  },
  [GET_CHART_ALL](_, q) {
    return ChartService.getAll(q).then(
      res => {
        return res;
      }
    )
  },
  [CREATE_CHART](_, params) {
    return ChartService.create(params).then(
      res => {
        return res;
      }
    )
  },
  [GET_CHART_DATA_BY_ID](_, id) {
    return ChartService.getData(id).then(
      res => {
        return res;
      }
    )
  },
  [UPDATE_CHART](_, params) {
    const id=params[0]
    const body = params[1]
    return ChartService.update(id, body).then(
      res => {
        return res;
      }
    )
  },
  [DELETE_CHART](_, id) {
    return ChartService.delete(id).then(
      res => {
        return res;
      }
    )
  },
  [DELETE_CHART_MULTI](_, q) {
    return ChartService.deleteMulti(q).then(
      res => {
        return res;
      }
    )
  },
  [POST_CHART_DATA](_, data) {
    return ChartService.postChartData(data).then(
      res => {
        return res;
      }
    )
  },
  [GET_CHART_LIST](_, data) {
    return ChartService.getChartList(data).then(
      res => {
        return res;
      }
    )
  }
};

const mutations = {
  SELECTED_COL(state, payload) {
    state.sel_col = payload;
  },
  SET_PROJECT_INFO(state, payload) {
    localStorage.setItem('project_info', JSON.stringify(payload));
    state.project_info = payload;
  },
  SELECTED_METRIC(state, payload) {
    state.sel_metric = payload;
  },
  SELECTED_PIE_METRIC(state, payload) {
    state.sel_pie_metric = payload;
  },
  SELECTED_PIE_CATEGORIES(state, payload) {
    state.pieCategories = payload;
  },
  SELECTED_HEAT_METRIC(state, payload) {
    state.sel_heat_metric = payload;
  },
  SELECTED_X(state, payload) {
    state.sel_x = payload;
  },
  SELECTED_Y(state, payload) {
    state.sel_y = payload;
  },
  SELECTED_X_LABEL(state, payload) {
    state.sel_x_label = payload;
  },
  SELECTED_Y_LABEL(state, payload) {
    // console.log("ylab", payload)
    state.sel_y_label = payload;
  },
  SELECTED_SERIES(state, payload) {
    state.series = payload;
  },
  SELECTED_ENTITY(state, payload) {
    state.entity = payload;
  },
  SELECTED_SIZE(state, payload) {
    state.sel_size = payload;
  },
  SELECTED_SIZE_LABEL(state, payload) {
    state.sel_size_label = payload;
  },
  BAR_METRICS(state, payload) {
    state.bar_metrics = payload;
  },
  BAR_SERIES(state, payload) {
    state.bar_series = payload;
  },
  BAR_CATEGORIES(state, payload) {
    state.bar_categories = payload;
  }
};

export default {
  state,
  actions,
  mutations
}