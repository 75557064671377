<template>
<svg class="sql-icon" :class="className" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path d="M3.76781 16.4524C2.52243 16.4524 1.59367 16.1479 0.98153 15.5389C0.37467 14.9251 0.0474934 13.9483 0 12.6085L2.48549 12.2578C2.49604 13.0178 2.59367 13.5707 2.77836 13.9166C2.96834 14.2577 3.25066 14.4282 3.62533 14.4282C4.11609 14.4282 4.36148 14.1359 4.36148 13.5512C4.36148 13.2248 4.29815 12.9252 4.1715 12.6524C4.04485 12.3747 3.88918 12.1384 3.70449 11.9435C3.51979 11.7487 3.26121 11.5197 2.92876 11.2566L1.76517 10.3285C1.48549 10.1044 1.2533 9.8998 1.0686 9.71467C0.883905 9.52954 0.701847 9.3103 0.522427 9.05697C0.348285 8.79876 0.218997 8.51376 0.134565 8.20196C0.0501319 7.89016 0.00791557 7.5467 0.00791557 7.17157C0.00791557 6.1631 0.335092 5.3836 0.989446 4.83308C1.6438 4.28257 2.5409 4.00487 3.68074 4C4.07124 4 4.4248 4.03897 4.74142 4.11692C5.06332 4.19487 5.33245 4.29718 5.54881 4.42385C5.77045 4.55052 5.96306 4.71129 6.12665 4.90616C6.29551 5.09616 6.42744 5.2886 6.52243 5.48347C6.62269 5.67348 6.70449 5.89027 6.76781 6.13386C6.83641 6.37258 6.88127 6.59182 6.90237 6.79156C6.92876 6.99131 6.94723 7.20567 6.95778 7.43465L4.44855 7.73426C4.44327 7.52965 4.43536 7.36157 4.4248 7.23003C4.41952 7.09362 4.40633 6.9499 4.38522 6.79887C4.36412 6.64784 4.33509 6.52605 4.29815 6.43348C4.26121 6.34092 4.21108 6.25566 4.14776 6.17771C4.08971 6.09489 4.01319 6.03643 3.91821 6.00233C3.8285 5.96822 3.72032 5.95361 3.59367 5.95848C3.34037 5.96335 3.14512 6.06322 3.00792 6.2581C2.87599 6.4481 2.81003 6.66246 2.81003 6.90118C2.81003 7.13016 2.83905 7.33964 2.8971 7.52965C2.96042 7.71478 3.06596 7.89503 3.21372 8.07042C3.36675 8.24581 3.49077 8.37735 3.58575 8.46504C3.68074 8.55273 3.83905 8.68914 4.06069 8.87427L5.17678 9.78044C5.48285 10.0338 5.7467 10.2774 5.96834 10.5112C6.18997 10.7402 6.40106 11.0033 6.60158 11.3004C6.80211 11.5928 6.95251 11.9167 7.05277 12.2724C7.15831 12.6232 7.21108 13.0007 7.21108 13.4051C7.21108 14.282 6.89446 15.0104 6.26121 15.5901C5.62797 16.165 4.79683 16.4524 3.76781 16.4524Z" fill="black" />
    <path d="M11.9288 14.472C12.0554 14.5305 12.2058 14.5597 12.3799 14.5597C12.5541 14.5597 12.7045 14.5329 12.8311 14.4793C12.9578 14.4209 13.0554 14.3502 13.124 14.2674C13.1926 14.1797 13.2454 14.0677 13.2823 13.9312C13.3245 13.7948 13.3509 13.6682 13.3615 13.5512C13.372 13.4294 13.3773 13.2857 13.3773 13.1201V7.31041C13.3773 7.14964 13.372 7.0108 13.3615 6.89387C13.3509 6.77208 13.3245 6.64541 13.2823 6.51387C13.2454 6.37746 13.1926 6.26784 13.124 6.18502C13.0554 6.1022 12.9578 6.03399 12.8311 5.9804C12.7045 5.92194 12.5541 5.89271 12.3799 5.89271C12.2058 5.89271 12.0554 5.92194 11.9288 5.9804C11.8074 6.03399 11.7124 6.1022 11.6438 6.18502C11.5752 6.26297 11.5224 6.37258 11.4855 6.51387C11.4485 6.65028 11.4248 6.77451 11.4142 6.88656C11.409 6.99862 11.4063 7.1399 11.4063 7.31041V13.1201C11.4063 13.2906 11.409 13.4343 11.4142 13.5512C11.4248 13.6633 11.4485 13.79 11.4855 13.9312C11.5224 14.0677 11.5752 14.1773 11.6438 14.2601C11.7124 14.3429 11.8074 14.4136 11.9288 14.472ZM15.3325 18.6667C14.942 18.5156 14.4776 18.2233 13.9393 17.7897C13.4011 17.3561 12.971 16.9104 12.6491 16.4524H12.3799C11.6517 16.4524 11.029 16.3647 10.5119 16.1893C10 16.0091 9.59103 15.7436 9.28496 15.3928C8.98417 15.0371 8.76517 14.6206 8.62797 14.1432C8.49604 13.6657 8.43008 13.103 8.43008 12.4551V7.98273C8.43008 7.3299 8.49604 6.7672 8.62797 6.29464C8.76517 5.8172 8.98417 5.40309 9.28496 5.05232C9.59103 4.70154 10 4.43847 10.5119 4.26308C11.029 4.08769 11.6517 4 12.3799 4C13.7995 4 14.8153 4.33616 15.4274 5.00847C16.0449 5.67591 16.3536 6.66733 16.3536 7.98273V12.4551C16.3536 14.2723 15.7757 15.461 14.6201 16.0213C15.1372 16.5425 15.5752 16.8909 15.934 17.0663L15.3325 18.6667Z" fill="black" />
    <path d="M18.095 16.2843V4.16808H20.9921V14.472H24V16.2843H18.095Z" fill="black" />
</svg>
</template>

<script>
export default {
    props: {
        hover: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        className() {
            return this.hover ? 'hover' : ''
        }
    }
};
</script>

<style lang="scss" scoped>
.sql-icon {
    fill: #888888;

    path {
        fill: #888888;
    }

    &.hover {
        fill: #fff !important;

        path {
            fill: #fff !important;
        }
    }
}
</style>
