<template>
  <v-dialog v-model="showNewFromFile" max-width="866px" max-height="585px">
    <div class="new-from-file">
      <div class="new-from-file-header">
        <p class="header-title">Define Dataset from .csv file</p>
        <v-icon @click="closeModal" class="ml-auto mt-auto mb-auto" size="36px"
          >mdi-close</v-icon
        >
      </div>
      <div class="new-from-file-card">
        <div class="mb-2">
          <label class="card-label">Upload File *</label>
          <span class="card-message">
            Select a .csv, .xls, or .xlsx file to be uploaded to a database
          </span>
        </div>
        <div class="mb-2">
          <v-file-input
            label="CSV Import Sample.csv"
            accept="text/csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            background-color="#1E1E1E"
            outlined
            dense
            height="40px"
            v-model="chosenFile"
          ></v-file-input>
        </div>
        <v-divider></v-divider>
        <div class="mt-7">
          <div class="mb-2">
            <label class="card-label">Table Name *</label>
            <span class="card-message">
              Name of table to be created from CSV data.
            </span>
          </div>
          <v-text-field
            outlined
            dense
            height="40px"
            background-color="#1E1E1E"
            placeholder="Table Name"
            v-model="table_name"
          />
          <div class="d-flex">
            <div class="w-50 pr-1">
              <p class="mb-2 card-label">Database *</p>
              <v-combobox
                solo
                :item-text="(obj) => obj['database_name']"
                :items="databases"
                @change="changeDB"
                :return-object="true"
                v-model="database"
                outlined
                background-color="#303030"
                height="40px"
              >
                <template v-slot:selection="data">
                  {{ data.item.database_name }}
                </template>
              </v-combobox>
            </div>
            <div class="w-50">
              <div class="d-flex mb-2">
                <label class="card-label">Schema *</label>
                <span class="card-message">
                  Specify a schema (if your database supports this)
                </span>
              </div>

              <v-combobox
                solo
                :items="schemas"
                v-model="schema"
                @change="getTables"
                :return-object="true"
                outlined
                background-color="#303030"
                height="40px"
              />
            </div>
          </div>
        </div>
        <v-row no-gutters class="mb-5">
          <v-col sm="4" cols="12" class="pr-1">
            <p class="mb-2">Delimiter *</p>
            <v-combobox
              solo
              :items="delimiters"
              v-model="separator"
              :return-object="true"
              outlined
              messages="Row containing header names of data"
              background-color="#303030"
              height="40px"
            />
          </v-col>
          <v-col sm="4" cols="12" class="pr-1">
            <p class="mb-2">Header Row</p>
            <v-text-field
              outlined
              dense
              messages="Row containing header names of data"
              background-color="#303030"
              height="40px"
              type="number"
              v-model="header_row"
            />
          </v-col>
          <v-col sm="4" cols="12">
            <p class="mb-2">Table Exists *</p>
            <v-combobox
              solo
              :items="tableExists"
              :item-text="(obj) => obj['text']"
              :item-value="(obj) => obj['value']"
              @change="changeExist"
              :return-object="true"
              outlined
              background-color="#303030"
              height="40px"
            />
          </v-col>
        </v-row>
        <div class="d-flex">
          <v-btn
            class="ml-auto"
            :disabled="!isValidForm"
            color="primary"
            @click="finishStep"
            height="36px"
            width="232px"
          >
            Upload Dataset
          </v-btn>
          <v-btn
            class="ml-7"
            outlined
            @click="showAdvance"
            width="232px"
            height="36px"
            color="#FFFFFF"
            disabled
          >
            See Advanced Options
          </v-btn>
        </div>
      </div>
    </div>
  </v-dialog>
</template>

<script>
import { GET_SCHEMA_ALL, GET_DB_ALL, GET_TABLE_ALL } from "@/store/actionType";
export default {
  props: {
    showNewFromFile: { /// mutated directly
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      //step
      step: 1,
      //databases
      databases: [],
      //database
      database: null,
      //schemas
      schemas: [],
      //schema
      schema: null,
      //tables
      tables: [],
      //delimiter
      delimiters: [",", "\t", "|", ";"],
      //selected delimiter
      separator: ",",
      //table exists
      tableExists: [
        {
          text: "Fail",
          value: "fail",
        },
        {
          text: "Replace",
          value: "replace",
        },
        {
          text: "Append",
          value: "append",
        },
      ],
      //table exist
      if_exists: {
        text: "Fail",
        value: "fail",
      },
      //selected csv file
      chosenFile: null,
      //table Name
      table_name: null,
      //row containing the headers to use as column names
      header_row: 0,
      requiredFormKeys: [
        "file",
        "database_name",
        "table_name",
        "schema",
        "separator", 
        "if_exists",
      ]
    };
  },
  mounted() {
    this.getDB();
  },
  methods: {
    /**
     * get DB
     */
    getDB() {
      this.$store
        .dispatch(GET_DB_ALL)
        .then((res) => {
          this.databases = res?.data.result;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    /**
     * change DB event
     * @param db
     */
    changeDB(db) {
      this.database = db;
      this.schemas = [];
      this.schema = "";
      this.tables = [];
      this.getSchema(db);
    },
    /**
     * get schema
     */
    getSchema(db) {
      this.$store
        .dispatch(GET_SCHEMA_ALL, db.id)
        .then((res) => {
          this.schemas = res.data.result;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    /**
     * get tables
     * @param schema
     */
    getTables(schema) {
      this.tables = [];
      const sendingData = {
        pk: this.database.id,
        schema_name: schema,
      };
      this.$store
        .dispatch(GET_TABLE_ALL, sendingData)
        .then((res) => {
          let tbs = res.data.result.options;
          for (let i = 0; i < tbs.length; i++) {
            this.tables.push({
              name: tbs[i].title,
              children: [],
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    /**
     * next Step
     */
    nextStep() {
      this.step = this.step + 1;
    },
    /**
     * previous step
     */
    previousStep() {
      this.step = this.step - 1;
    },
    /**
     * change if exist
     * @param val
     */
    changeExist(val) {
      this.if_exists = val;
    },
    /**
     * finish step
     */ 
    finishStep() {
      // console.log('if exists?',this.if_exists);
      const data = new FormData();
      data.append("file", this.chosenFile);
      data.append("database_name", this.database.database_name);
      data.append("table_name", this.table_name);
      data.append("schema", this.schema);
      data.append("separator", this.separator);
      // data.append("header_row", this.header_row);
      data.append("if_exists", this.if_exists.value);
      // console.log('if exists?',data.get("table_name"));
      // console.log('if exists?', data.keys());
      this.$emit("save_file", data);
      console.log('clear form')
      this.chosenFile=null;
      this.table_name=null;
      this.database=null;
      this.schema=null;
      this.separator=",";
      this.if_exists= {
        text: "Fail",
        value: "fail",
      }
    },

    /**
     * close modal
     */
    closeModal() {
      this.$emit("cancel");
    },
    /**
     * show advance modal
     */
    showAdvance() {
      this.$emit("showAdvance");
    },
    // clickMe() {
      // console.log('clickMe', this.isValidForm)
    // }
  },
  computed: {
    isValidForm() {
      return  (
        !!this.chosenFile
        && !!this.table_name
        && !!this.database
        && !! this.schema
        // && !! this.separator
        // && !! this.if_exists.value
      )
    }
  },
};
</script>

<style lang="scss" scoped>
.new-from-file {
  height: 585px;
  padding: 24px;
  background: #303030;

  &-header {
    display: flex;

    .header-title {
      font-size: 28px;
      line-height: 35px;
    }
  }

  &-card {
    .title {
      font-size: 28px;
      line-height: 35px;
    }

    .card-label {
      font-size: 16px;
    }

    .card-message {
      font-size: 12px;
      color: #a2a2a2;
      margin-top: auto;
      margin-left: 16px;
    }

    .file-text {
      margin-left: 16px;
      width: 500px;
      margin-top: 11px;
    }

    .card-select {
      border: 1px solid #707070;
      height: 48px;

      p {
        margin-top: auto;
        margin-bottom: auto;
        margin-left: 10px;
      }

      &-check {
        margin-left: auto;
        margin-top: 6px;
      }
    }
  }
}

.w-50 {
  width: 49%;
}

.pr-1 {
  padding-right: 16px !important;
}

.ml-1 {
  margin-left: 16px !important;
}

.ml-28 {
  margin-left: 28px;
}
</style>
