<template>
<!-- <svg id="Decorative_icon" data-name="Decorative icon" xmlns="http://www.w3.org/2000/svg" width="62.922" height="62.922" viewBox="0 0 62.922 62.922">
  <rect id="Placement_Area" data-name="Placement Area" width="62.922" height="62.922" fill="#ffc400" opacity="0"/>
  <g id="Icon">
    <rect id="Canvas" width="62.922" height="62.922" fill="#ffc400" opacity="0"/>
    <rect id="Rectangle_147668" data-name="Rectangle 147668" width="52.642" height="6.991" rx="0.5" transform="translate(0.325 54.159) rotate(-45)" fill="#b9b9b9"/>
    <path id="Path_83479" data-name="Path 83479" d="M21.6,6.779l.136,3.768A3.069,3.069,0,0,0,23.3,13.117l3.289,1.842-3.768.136a3.069,3.069,0,0,0-2.569,1.573L18.411,19.96l-.136-3.768A3.069,3.069,0,0,0,16.7,13.622L13.412,11.78l3.768-.136a3.074,3.074,0,0,0,2.569-1.573Z" transform="translate(33.472 16.92)" fill="#00bab9"/>
    <path id="Path_83480" data-name="Path 83480" d="M22.966.05l.189,5.226a4.259,4.259,0,0,0,2.176,3.562L29.9,11.392l-5.226.189a4.254,4.254,0,0,0-3.564,2.176l-2.555,4.562-.191-5.226a4.265,4.265,0,0,0-2.176-3.564L11.62,6.973l5.226-.189A4.263,4.263,0,0,0,20.41,4.608Z" transform="translate(29.001 0.125)" fill="#ffca00"/>
    <path id="Path_83481" data-name="Path 83481" d="M16.783.84l.243,6.731a5.485,5.485,0,0,0,2.8,4.588L25.7,15.452l-6.731.243a5.486,5.486,0,0,0-4.59,2.8L11.09,24.373l-.25-6.731a5.486,5.486,0,0,0-2.8-4.59L2.169,9.761,8.9,9.518a5.49,5.49,0,0,0,4.59-2.8Z" transform="translate(5.414 2.096)" fill="#f0471c"/>
  </g>
</svg> -->
<svg xmlns="http://www.w3.org/2000/svg" height="18" viewBox="0 0 18 18" width="18">
  <title>S Algorithm 18 N</title>
  <rect id="Canvas" fill="#ff13dc" opacity="0" width="18" height="18" /><path class="fill" d="M15.5,12.7h-.0095L13.823,9.961A1.794,1.794,0,0,0,12.5,6.95a1.76489,1.76489,0,0,0-.468.0695L10.323,4.212a1.8,1.8,0,1,0-2.646,0L5.968,7.0195A1.76489,1.76489,0,0,0,5.5,6.95,1.794,1.794,0,0,0,4.177,9.962L2.5095,12.7H2.5A1.8,1.8,0,1,0,4.221,15H7.279a1.789,1.789,0,0,0,3.442,0h3.058A1.7965,1.7965,0,1,0,15.5,12.7ZM13.779,14H10.721a1.792,1.792,0,0,0-.571-.875l1.7155-2.696a1.78547,1.78547,0,0,0,.6345.121,1.76489,1.76489,0,0,0,.468-.0695l1.535,2.522A1.79652,1.79652,0,0,0,13.779,14ZM9,4.8a1.77154,1.77154,0,0,0,.4685-.0695L11.177,7.538A1.80862,1.80862,0,0,0,10.868,8H7.132a1.79993,1.79993,0,0,0-.309-.462L8.5315,4.7305A1.77154,1.77154,0,0,0,9,4.8ZM7.275,9h3.45a1.782,1.782,0,0,0,.339.825L9.2205,12.722A1.77969,1.77969,0,0,0,9,12.7a1.77969,1.77969,0,0,0-.2205.022L6.936,9.825A1.782,1.782,0,0,0,7.275,9ZM5.032,10.4805A1.76489,1.76489,0,0,0,5.5,10.55a1.78548,1.78548,0,0,0,.635-.121L7.85,13.125A1.792,1.792,0,0,0,7.279,14H4.221a1.79648,1.79648,0,0,0-.724-1Z" />
</svg>

</template>

<script>
export default {
    name: 'AlgoIcon'
};
</script>

<style>
</style>
