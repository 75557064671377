var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex align-center "},[_c('v-btn',{staticClass:"mr-1 bg-primary",attrs:{"disabled":!_vm.selectedItems.length>0,"tile":"","depressed":"","icon":"","small":""},on:{"click":function($event){return _vm.deleteAll()}}},[_c('v-icon',{attrs:{"size":"18"}},[_vm._v(" mdi-trash-can ")])],1),_c('v-menu',{attrs:{"offset-y":"","right":"","z-index":"100"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"bg-primary",attrs:{"tile":"","depressed":"","icon":"","small":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":"18"}},[_vm._v("mdi-chevron-down")])],1)]}}])},[_c('v-list',{attrs:{"dense":"","color":"#454545"}},[_c('v-list-item-group',_vm._l((_vm.selectDropDownOptions),function(item,index){return _c('v-list-item',{key:index,attrs:{"dense":""},on:{"click":function($event){return _vm.clickSelectItem(item)}}},[_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(item.title)}})],1)],1)}),1)],1)],1),_c('v-divider',{staticClass:"mx-4",attrs:{"vertical":""}}),(!_vm.newDropdown)?_c('div',{staticClass:"new-button"},[(!_vm.menuItems && !_vm.hideNewButton)?_c('v-btn',{staticClass:"mr-1 bg-primary rounded",attrs:{"tile":"","depressed":"","small":""},on:{"click":function($event){return _vm.clickNewItem()}}},[_vm._v(" "+_vm._s(_vm.title)+" ")]):_vm._e()],1):_c('div',{staticClass:"new-button"},[_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(!_vm.menuItems && !_vm.hideNewButton)?_c('v-btn',_vm._g(_vm._b({staticClass:"mr-1 bg-primary rounded",attrs:{"tile":"","depressed":"","small":""}},'v-btn',attrs,false),on),[_vm._v(" Add "+_vm._s(_vm.title)+" ")]):_vm._e()]}}])},[_c('v-list',_vm._l((this.newItems),function(item,index){return _c('v-list-item',{key:index,on:{"click":function($event){$event.stopPropagation();return _vm.$emit('menuClick',item)}}},[_c('v-list-item-title',[_vm._v(_vm._s(item))])],1)}),1)],1)],1),(_vm.menuItems)?_c('v-btn',{staticClass:"dropdown-text-btn",attrs:{"small":"","ripple":false,"color":"primary"}},[_vm._v(" New "+_vm._s(_vm.title)+" ")]):_vm._e(),(_vm.menuItems)?_c('v-menu',{attrs:{"offset-y":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"dropdown-btn",attrs:{"color":"primary"}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":"20"}},[_vm._v("mdi-chevron-down")])],1)]}}],null,false,1396225326)},[_c('v-list',{attrs:{"dense":""}},[_c('v-list-item-group',_vm._l((_vm.menuItems),function(item,index){return _c('v-list-item',{key:index,attrs:{"dense":""},on:{"click":function($event){return _vm.clickMenuItem(item)}}},[_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(item.text)}})],1)],1)}),1)],1)],1):_vm._e(),(!_vm.hideNewButton)?_c('v-divider',{staticClass:"mx-4",attrs:{"vertical":""}}):_vm._e(),_c('div',{staticStyle:{"width":"400px"}},[_c('v-text-field',{ref:"searchInput",staticClass:"hidden-sm-and-down mx-auto custom-class",attrs:{"hide-no-data":"","hide-details":"","no-filter":"","label":("" + _vm.searchLabel),"dense":"","solo":"","outlined":"","prepend-inner-icon":"mdi-magnify"},on:{"input":_vm.userInputFilter},scopedSlots:_vm._u([{key:"append",fn:function(){return [(_vm.showCounter)?_c('span',{staticClass:"text-counter"},[_vm._v(" "+_vm._s(_vm.n_items[1])+"/"+_vm._s(_vm.n_items[0])+" ")]):_vm._e()]},proxy:true}]),model:{value:(_vm.moduleFilter),callback:function ($$v) {_vm.moduleFilter=$$v},expression:"moduleFilter"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }