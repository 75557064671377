import ProjectService from "../services/ProjectService";

import {
  GET_PROJECT_BY_ID,
  GET_PROJECT_ALL,
  CREATE_PROJECT,
  UPDATE_PROJECT,
  DELETE_PROJECT,
  DELETE_PROJECT_MULTI,
  REMOVE_PROJECT_CHILDREN,
  ADD_PROJECT_CHILDREN,
} from "./actionType";

const state = {};

const actions = {
  [GET_PROJECT_BY_ID](_, id) {
    return ProjectService.get(id).then(
      res => {
        return res;
      }
    )
  },
  [GET_PROJECT_ALL](_, q) {
    return ProjectService.getAll(q).then(
      res => {
        return res;
      }
    )
  },
  [CREATE_PROJECT](_, params) {
    return ProjectService.create(params).then(
      res => {
        return res;
      }
    )
  },
  [UPDATE_PROJECT](_, id, params) {
    return ProjectService.update(id, params).then(
      res => {
        return res;
      }
    )
  },
  [DELETE_PROJECT](_, id) {
    return ProjectService.delete(id).then(
      res => {
        return res;
      }
    )
  },
  [DELETE_PROJECT_MULTI](_, q) {
    return ProjectService.deleteMulti(q).then(
      res => {
        return res;
      }
    )
  },
  [REMOVE_PROJECT_CHILDREN](_, param) {
    return ProjectService.removeChildren(param.id, param.className, param.q).then(
      res => {
        return res;
      }
    )
  },
  [ADD_PROJECT_CHILDREN](_, param) {
    console.log('api', param)
    let body = param.body ? param.body : {}
    return ProjectService.addChildren(param.id, param.className, param.q, body).then(
      res => {
        return res;
      }
    )
  }
};

const mutations = {};

export default {
  state,
  actions,
  mutations
}