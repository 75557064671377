<template>
  <div class="calculation-modal">
      <v-card
        class="calculation-card"
        max-width="290"
        min-width="290"
        max-height="330"
        min-height="330"
        elevation="0"
        flat
        v-click-outside="onCancel"
      >
        <div class="calculation-header">
          <v-card-title 
            class="calculation-card-title">
            {{ calcStyle }}
          </v-card-title>
          <v-divider/>
        </div>
        <div class="calculation-container">
          <v-tabs color="white">
            <v-tab
            class="combo"
            >Simple</v-tab>
          <!-- <v-tab>SQL</v-tab> -->
          <!-- Simple -->
          <v-tab-item>
            <p class="mt-2 mb-2">Column</p>
            <!-- :value="form_data.size ? form_data.size.column.column_name : ''" -->
            <v-text-field
              outlined
              dense
              height="40px"
              background-color="#1E1E1E"
              :value="size ? size.column.column_name : 'error'"
              hide-details
              readonly
            />
            <p class="mt-2 mb-2">Aggregation</p>
            <div id="thisbox"
              class="combobox"
            >
            <v-combobox
              solo
              dense
              :items="aggregations"
              :return-object="true"
              outlined
              hide-details
              height="40px"
              v-model="calc"
              attach=".calculation-card"
            />
            </div>
            <div class="mt-3">
              <v-btn
                color="white"
                width="96px"
                height="36px"
                outlined
                @click="onCancel"
              >
                Cancel
              </v-btn>
              <v-btn
                class="ml-5"
                color="primary"
                @click="onSave"
                height="36px"
                width="96px"
              >
                Save
              </v-btn>
            </div>
          </v-tab-item>
          </v-tabs>
        </div>
      </v-card>
  </div>
</template>

<script>
// import language js
import "codemirror/mode/sql/sql.js";
// theme css
import "codemirror/theme/mbo.css";

export default {
  data() {
    return {
      // def_calc: "AVG",
      sizeShow: false,
      stayOpen: false,
    }
  },
  props: {
    filters: {
      type: Array,
      default() {
        return [];
      },
    },
    calcStyle: {
      type: String,
      default() {
        return null;
      }
    },
    aggregations: {
      type: Array,
      default() {
        return [];
      },
    },
    form_data: {
      type: Object,
      default() {
        },
    },
    size: {
      type: Object,
      default() {},
    },
    cmOptions: {
      type: Object,
      default() {},
    },
  },

  methods: {
    onSave() {
      let saveCalc = this.size.expression ?? this.def_calc;
      this.$emit('saveSimpleCalcSize', saveCalc)
    },
    onCancel() {
      console.log('cnx-card')
      this.$emit('disableCalcCard')
    }
  },
  computed: {
    def_calc() {
      if (this.size.column.type_generic === 1 ) {
        return "COUNT";
      } else {
        return "AVG";
      }
    },
    getSize: {
      get () {
        return true
      },
      set (val) {
        this.sizeShow = val;
      },
    },
    calc: {
      get () {
        console.log('calc getter', this.size.aggregate)
        // if (this.form_data.size.aggregate == null ) {
        if ( !this.size.aggregate ) {
          console.log('calc getter default', this.size)
          return this.def_calc;
        } else {
          return this.size.aggregate
        }
      },
      set (val) {
        // console.log('myval', val)
        this.size.expression = val;
      }
    },
  },
};
</script>

<style lang="scss" scoped>

.calculation-card {
  background-color: #303030;
  padding: 0px 12px;
}

.blank-drag {
  border: 1px dashed #909090;
  border-radius: 12px;
  text-align: center;
  color: #909090;
  padding-top: 4px;
  padding-bottom: 4px;
  font-size: 12px;
}

.blank-text {
  font-size: 12px;
  text-align: center;
  color: #909090;
  margin-top: 9px;
}

.column-text-btn {
  border-color: #00a7ff !important;
}

.column-item {
  margin-bottom: .5rem;
}

.filter-btn {
  border-color: #1bbab9 !important;
}

</style>