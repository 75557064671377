
<template>
  <!-- <div style="text-align: center; background: red;"> -->
  <div style="text-align: center; width: 100%"> 
    <!-- color="blue" -->
    <v-card
    >
    <div class="d-flex">
      <v-card-title>
        <v-icon style="padding-top: 4px;">mdi-table-edit</v-icon>
        <span class="pt-2;">&nbsp;</span>Edit Table Data</v-card-title>
    </div>
      <v-divider/>
      <v-card-text>
        <v-checkbox
          v-model="editMode"
          :label="`Edit Data Mode: ${editMode ? 'On' : 'Off' }`"
          @change="checkboxAction"
        />
      </v-card-text>
      <v-divider/>
      <v-card-actions>
        <CardActions
          btnColor="white"
          actionFunc="cancelButtonPress"
          action="Cancel"
          @cancelButtonPress="cancelButtonPress"
        />
        <CardActions
          btnColor="primary"
          :disabled="true"
          actionFunc="cancelButtonPress"
          action="Apply"
          @cancelButtonPress="cancelButtonPress"
        />
        
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>

import CardActions from "@/views/_partials/CardActions.vue"

export default {
  name: "EditPanel",
  components: {
    CardActions,
  },
  props: {}
    // ['closeToolPanel']
  ,
  data() {
    return {
      editMode: false,
    };
  },
  methods: {
    checkboxAction() {
      // console.log('edit mode?', this.editMode)
      this.$parent.$parent.tableEditMode(this.editMode)
    },
    cancelButtonPress() {
      this.editMode=false;
      this.$parent.$parent.closeToolPanel()
    },
  },
}
</script>