import SavedQueryService from "../services/SavedQueryService";

import {
  GET_SAVED_QUERY_ALL,
  DELETE_SAVED_QUERY,
  DELETE_SAVED_QUERY_MULTI,
  POST_SAVED_QUERY,
} from "./actionType";

const state = {};

const actions = {
  // '/api/v1/saved_query/' [POST]
  [POST_SAVED_QUERY](_, params) {
    return SavedQueryService.postSavedQuery(params).then(
      res => {
        return res;
      }
    )
  },

  [GET_SAVED_QUERY_ALL](_, q) {
    return SavedQueryService.getAll(q).then(
      res => {
        return res;
      }
    )
  },
  [DELETE_SAVED_QUERY](_, id) {
    return SavedQueryService.delete(id).then(
      res => {
        return res;
      }
    )
  },
  [DELETE_SAVED_QUERY_MULTI](_, q) {
    return SavedQueryService.deleteMulti(q).then(
      res => {
        return res;
      }
    )
  },
}


const mutations = {};

export default {
    state,
    actions,
    mutations
}