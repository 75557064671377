<template>
  <!-- class="align-center mt-auto mb-auto mr-3 query-tab" -->
  <div
    class="align-center mt-auto mb-auto query-tab"
    :class="{ active: queryTab.active }"
  >
    <!-- Tab Menu -->
    <v-menu offset-y v-if="queryTab.active">
      <template v-slot:activator="{ on, attrs }">
        <v-btn icon v-bind="attrs" v-on="on">
          <v-icon size="20">mdi-dots-vertical</v-icon>
        </v-btn>
      </template>
      <v-list>
        <v-list-item-group>
          <v-list-item
            dense
            v-for="(item, index) in menuItems"
            :key="index"
            @click="menuEvent(item)"
          >
            <v-list-item-icon class="mr-1">
              <v-icon v-text="item.icon"></v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title v-text="item.text"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-menu>
    <!-- {{ queryTab.name ? queryTab.name : "Untitled Query" }} {{ queryTab.name ? '' : index + 1 }} -->
    {{ queryTab.name }}
    <v-btn 
      v-if="queryTab.active" 
      icon
      @click.stop="deleteQuery">
      <v-icon size="20">mdi-close</v-icon>
    </v-btn>
    <!-- <v-divider color="#454545"/> -->
  </div>
</template>

<script>
export default {
  name: "QueryTab",

  props: {
    queryTab: {
      type: Object,
      default: null,
    },
    // index: {
    //   type: Number,
    //   default: null,
    // },
  },
  data() {
    return {
      menuItems: [
        {
          icon: "mdi-cursor-text",
          text: "Rename Tab",
          action: "rename",
        },
        {
          icon: "mdi-close",
          text: "Close Tab",
          action: "close",
        },
        {
          icon: "mdi-close-circle",
          text: "Close All Other Tabs",
          action: "close-others",
        },
      ],
    };
  },
  mounted() {
  },
  methods: {
    /**
     * delete query from query tabs
     */
    deleteQuery() {
      this.$emit("deleteQuery", this.queryTab.id);
      // console.log('tab',this.queryTab.id)
    },
    /**
     * tab menu event
     * @param item
     */
    menuEvent(item) {
      this.$emit("menu-action", item, this.queryTab.id);
    },
  },
};
</script>

<style lang="scss" scoped>
.query-tab {
  color: grey;
  cursor: pointer;
  padding: 0 20px;

  &.active {
    color: white;
    border-bottom: 3px solid white;
    background: #454545;
  }
}
</style>
