<template >
<!--<template v-if="pie_data"> -->
  
  <div v-if="!!vis_data" ref="tbGrid">
    <highcharts :options="bubble_chart_data" class="bubble-container" />
  <!-- <div> -->
    <!-- <p>{{ bubble_chart_data }}</p> -->
  <!-- </div> -->
  
  </div>
  

</template>

<script>
import Highcharts from "highcharts"
require("highcharts/modules/accessibility");(Highcharts);
require("highcharts/modules/no-data-to-display")(Highcharts);

// These enable the export hamburger
// require("highcharts/modules/export-data")(Highcharts);
// require("highcharts/modules/exporting")(Highcharts);

export default {
  name: "ScatterVis",
  components: {
  },
  props: {
    form_data: {
      default() {
        return null;
      },
    },
    vis_data: {
      default() {
        return {
          'row_data': [],
          'columns': [],
          'coltypes': [],
        };
      },
    },
  },
  data() {
    return {};
  },
  // methods: {},
  computed: {
    bubble_chart_data() {
      console.log('my bubble data', this.vis_data)
      // console.log('my bubble form', this.form_data)
      let chart_type='scatter';
      const my_symbols = ['circle', 'square','diamond', 'triangle', 'triangle-down'];
      // const my_colors = [
      //   '#1FA8C9', '#454E7C','#5AC189','#FF7F44','#666666',
      //   '#E04355', '#FCC700','#A868B7', '#3CCCCB',
      //   ]
      let my_colors1 = [
        '#1FA8C9', '#454E7C','#5AC189','#FF7F44','#666666',
        // '#E04355', '#FCC700','#A868B7', '#3CCCCB',
        ]
      let my_colors2 = [
        // '#1FA8C9', '#454E7C','#5AC189','#FF7F44','#666666',
        '#E04355', '#FCC700','#A868B7', '#3CCCCB',
        ]
      Highcharts.setOptions({
        chart: {
          backgroundColor: "rgb(10, 10, 10)",
          plotBackgroundColor: "#2D2D2D",
          }
        });
            
      let my_colors = my_colors2;
      let bubble_data = {};
      // let data = this.vis_data.data;
      let mrkr_ln = my_symbols.length;
      let col_ln = my_colors.length;
            
      // indexes to mark color/symbols
      let i=0;
      let c=0;
      
      let category = this.form_data.series;
      let x_col = this.form_data.x.label;
      let y_col = this.form_data.y.label;

      // parse size
      // let z_col = this.form_data.size.label;
      
      let _index = this.form_data.entity

      let series = []
      let categories = {}

      // let bubble_abs_size = this.bubble_abs_size;
      ////
      // if (this.form_data.extra_form_data.bubble_size !== null &&
          // 'bubble_size' in this.form_data.extra_form_data
      // ) {

        // bubble_abs_size = this.calcBubbleAbsSize(this.form_data.extra_form_data.bubble_size)
        // console.log(this.bubble_abs_size['maxSize'], this.bubble_abs_size['minSize'])
      // }

      /////* parse api *///////
      this.vis_data.row_data.forEach(item => {
        c++;
        // console.log('iter:', c)
        if (c===1) {
          // console.log('item: ', item )
        }
        if ( item[x_col] !== "null" && item[y_col] !== "null" ) {
          /////
          if ( !(item[category] in categories) ) {
            let marker_idx = ((i % mrkr_ln) + mrkr_ln) % mrkr_ln;
            let color_idx = ((i % col_ln) + col_ln) % col_ln;
            categories[ item[category] ] = {
              id: item[category] ,
              name: item[category] ,
              marker: {
                symbol: my_symbols[marker_idx][0],
                fillColor: my_colors[color_idx],
                lineColor: my_colors[color_idx],
              },
              // select: {
                // enabled: false,
              // },
              tooltip: {
                pointFormat: 
                  '<b>'+ x_col + '</b>: {point.x}<br/>'+ 
                  '<b>'+ y_col + '</b>: {point.y}<br/>' +
                  // '<b>'+ z_col + '</b>: {point.z}<br/>' +
                  // '<b>'+_index + '</b>: ' + item[_index]
                  '<b>'+ _index + '</b>: {point.name}<br/>'
              },
              data: [{x: item[x_col], y: item[y_col], name: item[_index] }],
              // minSize: bubble_abs_size.minSize,
              // maxSize: bubble_abs_size.maxSize,
              // sizeBy: 'area',
            };
            i++;
          } else {
            // categories[ item[category] ].data.push( [ item[x_col], item[y_col], 
            categories[ item[category] ].data.push( {x: item[x_col], y: item[y_col], name: item[_index]  } )
              // item[z_col] 
              // ] )
          }
        }
        return categories;
      });

      // let series = []
      Object.values(categories).forEach(val => {
        series.push(val);
      })

      // Export Bubble chart params
      bubble_data = {
        chart: {
          type: chart_type,//"scatter",
          plotBorderWidth: 1,
          zoomType: "xy",
          allowPointSelect: false,
          // backgroundColor: "rgb(21, 21, 21)",
          // backgroundColor: "2D2D2D",//"rgb(10, 10, 10)",
          // backgroundColor: "#FFFFFF",//"rgb(10, 10, 10)",
          // plotBackgroundColor: "FFFFFF",
        },
        credits: {
          enabled: false,
        },
        legend: {
          enabled: true,
        },
        title: {
          text: "",
        },
        xAxis: {
          gridLineWidth: 1,
          title: {
            text: this.form_data.x.label,
          },
          labels: {
            format: "{value}",
          },
        },
        yAxis: {
          startOnTick: false,
          endOnTick: false,
          title: {
            text: this.form_data.y.label,
          },
          labels: {
            format: "{value}",
          },
          maxPadding: 0.2,
        },
        plotOptions: {
          // spline: {
            // turboThreshold: 0,
          // },
          series: {
            turboThreshold: 0,
            dataLabels: {
              enabled: false,
              // format: `{point.name}`,
            },
            marker: {
              radius: 6
            },
          },
        },
        series: series,
      };

      console.log('parsed bubble_data:', series)
      // let bubble_data=true;
      return bubble_data
    },  
  },
}
</script>

<style lang="scss" scoped>

</style>