<template>
  <div class="d-flex align-center">
    <v-btn icon class="mr-0" @click="$router.go(-1)">
      <v-icon>
        mdi-chevron-left
      </v-icon>
    </v-btn>

    <div class="d-flex flex-grow-0">
      <h1 class="text-h6 pl-2">{{ title }}</h1>
    </div>
  </div>
</template>
<script>
export default {
  name: "MainHeader",

  props: {
    title: {
      type: String,
      required: true,
    },
  },
};
</script>
