<template>
<svg id="Decorative_icon" data-name="Decorative icon" xmlns="http://www.w3.org/2000/svg" width="62.922" height="62.922" viewBox="0 0 62.922 62.922">
  <rect id="Placement_Area" data-name="Placement Area" width="62.922" height="62.922" fill="#ffc400" opacity="0"/>
  <g id="Icon">
    <rect id="Canvas" width="62.922" height="62.922" fill="#ffc400" opacity="0"/>
    <rect id="Rectangle_147668" data-name="Rectangle 147668" width="52.642" height="6.991" rx="0.5" transform="translate(0.325 54.159) rotate(-45)" fill="#b9b9b9"/>
    <path id="Path_83479" data-name="Path 83479" d="M21.6,6.779l.136,3.768A3.069,3.069,0,0,0,23.3,13.117l3.289,1.842-3.768.136a3.069,3.069,0,0,0-2.569,1.573L18.411,19.96l-.136-3.768A3.069,3.069,0,0,0,16.7,13.622L13.412,11.78l3.768-.136a3.074,3.074,0,0,0,2.569-1.573Z" transform="translate(33.472 16.92)" fill="#00bab9"/>
    <path id="Path_83480" data-name="Path 83480" d="M22.966.05l.189,5.226a4.259,4.259,0,0,0,2.176,3.562L29.9,11.392l-5.226.189a4.254,4.254,0,0,0-3.564,2.176l-2.555,4.562-.191-5.226a4.265,4.265,0,0,0-2.176-3.564L11.62,6.973l5.226-.189A4.263,4.263,0,0,0,20.41,4.608Z" transform="translate(29.001 0.125)" fill="#ffca00"/>
    <path id="Path_83481" data-name="Path 83481" d="M16.783.84l.243,6.731a5.485,5.485,0,0,0,2.8,4.588L25.7,15.452l-6.731.243a5.486,5.486,0,0,0-4.59,2.8L11.09,24.373l-.25-6.731a5.486,5.486,0,0,0-2.8-4.59L2.169,9.761,8.9,9.518a5.49,5.49,0,0,0,4.59-2.8Z" transform="translate(5.414 2.096)" fill="#f0471c"/>
  </g>
</svg>

</template>

<script>
export default {
    name: 'Magic'
};
</script>

<style>
</style>
