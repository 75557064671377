<template>
  <div>
    <v-dialog
      content-class="custom-dialog"
      v-model="dataset_detail_modal"
      width="900"
      persistent
      scrollable
    >
      <v-card>
        <v-container overflow-hidden>
          <div class="outline d-flex">
            <v-col md="6" class="ml-6 font-weight-bold" style="font-size: 20px;">
              Dataset Detail
            </v-col>
            <v-col md="6" class="text-right">
              <div class="close" @click="back()">x</div>
            </v-col>
          </div>
          <div class="col-md-12">
            <v-text-field
              ref="searchInput"
              hide-details
              label="Search Visualization Types"
              no-filter
              dense
              solo
              outlined
              hide-no-data
              prepend-inner-icon="mdi-magnify"
              class="hidden-sm-and-down custom-class ml-auto"
              style="max-width: 300px;"
            >
            </v-text-field>
          </div>
          <div>
          <v-data-table
            :headers="headers"
            :items="data_set"
            item-key="name"
            :search="search"
            class="elevation-1"
          >
          </v-data-table>
          </div>
        </v-container>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {GET_DATASET_DATA_BY_ID} from "@/store/actionType";

export default {
  name: "DatasetDetailDlg",
  props:{
    value:{
      type: Boolean,
      default: false
    },
    chartType:{
      type: String,
      default: null
    },
    id:{
      type: Number,
      default: null
    },
  },
  data () {
    return {
      selected: [],
      headers: [],
      data_set: [],
      search: '',
    }
  },
  mounted(){
    const q = {"columns": ["query_result"]};
    this.getDataset(this.id, q);
  },
  computed:{
    dataset_detail_modal:{
      get: function() {
        return this.value
      },
      set: function(newValue) {
        this.value = newValue
      }
    }
  },
  methods:{
    /**
     * get datasets
     */
    getDataset(id, q) {
      const param = {
        id: id,
        q: q
      };

      this.$store.dispatch(GET_DATASET_DATA_BY_ID,param).then((res) => {
        this.initialize(res.data.message.result.query_result);
      }).
      catch((error)=>{
        console.log(error)
      })
    },
    /**
     * initialize database values
     * @param values
     */
    initialize(result) {
      this.headers = result.colnames.map((k) => ({'value': k, 'text': k, width: "100px"}))
      this.data_set = result.data
    },
    back(){
      this.$parent.dataset_detail_modal = false;
      this.$parent.dataset_modal = true;
    }
  }
}
</script>

<style scoped>
.outline{
  border-bottom: 1px solid #AFAFAFFF;
  padding: 5px
}
.close{
  margin-right: 15px;
  cursor: pointer;
  font-size: 20px;
}
.custom-dialog{
  position: absolute;
  /*right: 10%;*/
  top: 10%;
}
</style>