<template>
  <div class="d-flex flex-column fill-height flex-grow-1 overflow-hidden">
    <MainHeader :title="isUpdate ? 'Edit User' : 'New User'"></MainHeader>

    <h2 class="text-h6 pl-2">
      {{ isUpdate ? "Formulario de actualización" : "Create New User" }}
    </h2>

    <v-card outlined elevation="1" class="mx-auto my-12 pa-4" width="374">
      <div class="text-center my-3">
        <v-avatar size="80" color="teal" class="mx-auto">
          <span
            dark
            class="white--text text-h4 font-weight-regular mx-auto"
            v-text="initials"
          ></span>
        </v-avatar>
      </div>

      <div v-if="loading" class="text-center">
        <v-progress-circular
          indeterminate
          color="primary"
        ></v-progress-circular>
      </div>

      <!-- form -->
      <validation-observer ref="observer" v-slot="{ invalid }">
        <form v-if="!loading" @submit.prevent="save" class="box">
          <!-- <validation-observer ref="observer" v-slot="{ invalid, validate }" > -->
          <!-- <form v-if="!loading" @submit.prevent="validate().then(save)" class="box"> -->
          <validation-provider v-slot="{ errors }" name="Name" rules="required">
            <v-text-field
              v-model="model.name"
              :error-messages="errors"
              label="Name"
              required
            ></v-text-field>
          </validation-provider>

          <validation-provider
            v-slot="{ errors }"
            name="Email"
            rules="required|email"
          >
            <v-text-field
              v-model="model.email"
              :error-messages="errors"
              label="Email"
              required
            ></v-text-field>
          </validation-provider>

          <validation-provider
            v-slot="{ errors }"
            name="Password"
            rules="required|strongPassword"
          >
            <v-text-field
              v-model="model.password"
              :counter="8"
              :error-messages="errors"
              label="Password"
              required
              :type="showPass ? 'password' : 'text'"
              :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
              @click:append="() => (showPass = !showPass)"
              @input="(_) => (model.password = _)"
            ></v-text-field>
          </validation-provider>

          <v-card-actions class="pa-0 mt-5">
            <v-btn
              class="mr-2"
              type="submit"
              color="primary"
              :disabled="invalid"
              depressed
            >
              Save
            </v-btn>
            <v-btn @click="gotoUsers" depressed> Cancel </v-btn>
          </v-card-actions>
        </form>
      </validation-observer>
    </v-card>
  </div>
</template>

<script>
import MainHeader from "@/components/layout/MainHeader";
import { extractInitials } from "@/utils/stringUtils";
import { ValidationObserver, ValidationProvider } from "vee-validate";
// import { GET_USER_BY_ID, CREATE_USER, UPDATE_USER } from "@/store/actionType";
import { Validator } from "vee-validate";
Validator.extend("strongPassword", {
  getMessage: () => {
    return "The password must contain at least: 1 uppercase letter, 1 lowercase letter, 1 number, one special character and min 8 characters";
  },
  validate: (value) => {
    var strongRegex = new RegExp(
      "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})"
    );
    return strongRegex.test(value);
  },
});

export default {
  name: "CreateOrUpdate",
  components: {
    MainHeader,
    ValidationObserver,
    ValidationProvider,
  },

  data() {
    return {
      loading: false,

      model: {
        id: 0,
        name: "",
        email: "",
        password: "",
        isAdmin: 0,
      },
      //invalid: true,
      showPass: true,
    };
  },
  computed: {
    isUpdate() {
      return this.$route.params.id != undefined;
    },
    initials() {
      if (this.model.name != "") {
        return extractInitials(this.model.name);
      } else {
        return "...";
      }
    },
  },
  methods: {
    gotoUsers() {
      this.$router.push(`/users`);
    },
    get(id) {
      this.loading = true;
      // this.$store
      //   .dispatch(GET_USER_BY_ID, id)
      //   .then((r) => {
      //     this.model.id = r.data.id;
      //     this.model.name = r.data.name;
      //     this.model.email = r.data.email;
      //     this.model.password = r.data.password;
      //     this.model.isAdmin = r.data.isAdmin;

      //     this.loading = false;
      //   })
      //   .catch(() => {
      //     console.error("Algo salió mal ..");
      //   });
    },

    save() {
      this.loading = true;

      /* Parece que no funca */
      //this.$refs.observer.validate();
      // TODO: restore once the backend api's are up to date
      // if (this.isUpdate) {
      //   this.$store
      //     .dispatch(UPDATE_USER, this.$route.params.id, this.model)
      //     .then(() => {
      //       this.loading = false;
      //       this.$router.push("/users");
      //     })
      //     .catch(() => {
      //       console.error("Algo salió mal ..");
      //     });
      // } else {
      //   this.$store
      //     .dispatch(CREATE_USER, this.model)
      //     .then(() => {
      //       this.$router.push("/users");
      //     })
      //     .catch(() => {
      //       console.error("Algo salió mal ..");
      //     });
      // }
    },
  },
  mounted() {
    if (this.isUpdate) {
      this.get(this.$route.params.id);
    }
  },
};
</script>
