<template>
  <div v-if="!!vis_data" ref="tbGrid">
    <!-- {{ vis_data.vis_data[0] }} -->
    <!-- <br> -->
    <!-- ///// -->
    <!-- <br> -->
    <!-- {{ form_data }} -->
    <!-- {{ chart_data() }} -->
    <highcharts :options="chart_data()"/>
    <!-- {{ chart_data().series[] }} -->
    <!-- {{ series }} -->
  </div>
</template>

<script>
import Highcharts from "highcharts"
require("highcharts/modules/accessibility");(Highcharts);
require("highcharts/modules/no-data-to-display")(Highcharts);

// These enable the export hamburger
// require("highcharts/modules/export-data")(Highcharts);
// require("highcharts/modules/exporting")(Highcharts);

// const H = Highcharts,
// 	c = H.seriesTypes.column.prototype,
// 	s = H.seriesTypes.scatter.prototype;

// ['getColumnMetrics', 'crispCol'].forEach((prop)=>{
// 	s[prop] = c[prop];
// });


// console.log('asdfadsfadsfasdfsd',c)
export default {
  name: "BoxPlotVis",
  components: {
  },
  props: {
    viz_type: {
      type: String,
      default() {
        return null;
      }
    },
    form_data: {
      default() {
        return null;
      },
    },
    vis_data: {
      default() {
        return {
          'row_data': [],
          'columns': [],
          'coltypes': [],
          'vis_data': [],

        };
      },
    },
  },
  data() {
    return {
      // outliersAreDirty: false,
      // series: [],
  //     // chartTypeObj: {
  //     //   "dist_bar": "column",
  //     //   "horiz_bar": "bar",
  //     // }
    };
  },
  // methods: {},
  // Computed????
  methods: {
    chart_data() {

      const H = Highcharts,
        c = H.seriesTypes.column.prototype,
        s = H.seriesTypes.scatter.prototype;

      let chart_data;
      let vis_data = this.vis_data.vis_data;
      // console.log('vis_data', vis_data);
      const metrics = this.form_data.metrics;
      const yCategories = metrics.map( item => item.label)
      // console.log('///////////yvals', yCategories)
      
      const xVals = this.form_data.groupby;
      // const xAxes = this.form_data.groupby;

      let xCategories = vis_data.map((item) => {
        let result = "";
        for (let i = 0; i < xVals.length; i++) {
          if (i === 0) {
            result += `${item[xVals[i]]}`;
          } else {
            result += `, ${item[xVals[i]]}`;
          }
        }
        return result;
      });
      // console.log('test', xCategories)

      let series = [];
      let yCat_data = [];

      // Parse Y data and outliers
      // for (let yCategory of yCategories) {
      
      for (let i = 0; i < yCategories.length; i++) {
        const yCategory = yCategories[i];
        let series_data = vis_data.map((item) => {
          return [
            item[`${yCategory}__min`],
            item[`${yCategory}__q1`],
            item[`${yCategory}__median`],
            item[`${yCategory}__q3`],
            item[`${yCategory}__max`],
          ];
        });
        let outlier_data = [];
        
        // vis_data.forEach(item => {
        // console.log('check error ', vis_data)
        for (let i = 0; i < vis_data.length; i++) {
          const row_outliers = vis_data[i][`${yCategory}__outliers`];
          if ( row_outliers ) {// > 0 ) {
            row_outliers.forEach(obs => outlier_data.push([i, obs]));
          }
        }
        
        // Add data to series
        series.push({ // yCategory
          name: yCategory,
          data: series_data,
          tooltip: {
            headerFormat: "<em>{point.key}</em><br/>",
          },
        });
        let placement;

        if ( i % 2 == 0 ) {
          placement = -0.15;
        } else {
          placement = 0.15;
        }

        series.push({ // outliers
          name: "Outliers",
          color: Highcharts.getOptions().colors[0],
          type: "scatter",
          data: outlier_data,
          // pointPlacement: placement,
          marker: {
            fillColor: "white",
            lineWidth: 1,
            lineColor: Highcharts.getOptions().colors[0],
          },
          tooltip: {
            pointFormat: "Observation: {point.y}",
          },
        });
        yCat_data.push({
          title: {
            text: yCategory,
          },
        });
      }

      function adjustOutliers() {
        const boxplotSeries = this.series.filter(series => series.type === 'boxplot' && series.visible);
              
        const outliers = this.series.filter(series => series.type ==='scatter' && series.visible);
        // console.log('load outliers orig\\\\\\\\\\', outliers)
        const calculateOutlierX = (category, series) => {
          
          const point = series.data[category];
          const shapeArgs = point.shapeArgs;
          const corr = (point.stem.strokeWidth() % 2) / 2;
          // console.log('load calc', point.stem.strokeWidth())
          return series.xAxis.toValue(shapeArgs.x + (shapeArgs.width / 2) + series.group.translateX + corr);
        }
        const adjustedOutliers = outliers.map((series, i, ser) => {
          return series.data.map(point => [calculateOutlierX(Math.round(point.x), boxplotSeries[i]), point.y])
          })
        outliers.forEach((series, i) => {
          series.setData(adjustedOutliers[i], false);
        })
        outliersAreDirty = false;
        // console.log(' after load\\\\\\\\\\', outliersAreDirty)
        this.redraw();
      }
      let outliersAreDirty = true;
      // Parse chart data and options
      chart_data = {
        chart: {
          type: "boxplot",
          // inverted: true,
          events: {
            addSeries: function () {
              // console.log('load series add')
              outliersAreDirty = true;
            },
            load: adjustOutliers,
            redraw: function () {
              // console.log('load redraw')
              if (outliersAreDirty) {
                // console.log('load redraw adjust')
                adjustOutliers.call(this);
              } 
            }
          },
        },
        plotOptions: {
          series: {
            "grouping": true,
            events: {
              legendItemClick: function () {
                return false
            //   // legendItemClick: this.dirtyOutliers
            //   legendItemClick: function () {
            //     outliersAreDirty = true;
              },
            }
          }
        },
        title: {
          text: "",
        },
        legend: {
          enabled: true,
        },
        xAxis: {
          categories: xCategories,
        },
        yAxis: yCat_data,
        series: series,
      };
      // this.series = series;
      // console.log('///////chart_data', Object.keys(H))
      return chart_data
    },
  },
}
</script>

<style lang="scss" scoped>

</style>