<template>
  <div class="loginPage d-flex">
    <div class="loginBlock">
      <v-card outlined class="overflow-hidden mt-100">
        <v-row no-gutters>
          <!-- App info Column -->
          <v-col md="6" class="hidden-sm-and-down content-div pa-2">
            <!-- :justify="'space-around'" -->
            <!-- <v-row -->
            <v-card-title class="justify-center">
              <!-- :justify="'center'" -->
            <!-- > -->
            <v-img src="@/assets/logo.svg"
              max-height="50"
              max-width="50"
            />
            <div style="width: 30px;"/>
            <div class="content-heading">See Your Data Clearly</div>
            <!-- </v-row> -->
            </v-card-title>
            <v-img src="@/assets/dashboard.svg"
              max-height="9000"
              max-width="3000"
            />
            <!-- <v-card-title class="justify-center">
              - BI powered by Artificial Intelligence -
            </v-card-title> -->
            <!-- <div class="mt-40">
              <div class="d-flex instruct-heading">
                <img src="../assets/icons/tick.svg">
                &nbsp;&nbsp;&nbsp;<b>Data-Centric BI</b>
              </div>
              <div class="instruct-text">
                - No Code AutoML, data editing, and &nbsp;&nbsp;&nbsp;visualizations - in the browser!
              </div>
              </div>
              <div class="instruct-text">
                - Visualize, Pivot, and Edit your data in &nbsp;&nbsp;&nbsp;spreadsheet format!
              </div>
              <div class="instruct-text">
                - Easily interpret patterns and insights
              <div class="instruct-text">
                - Combine data from different databases 
              </div>
            </div>
            <div class="mt-5">
              <div class="d-flex instruct-heading">
                <img src="../assets/icons/tick.svg">
                &nbsp;&nbsp;&nbsp;<b>Operational Artificial Intelligence</b>
              </div>
              <div class="instruct-text">
                - Citizen Data Scientists across the organization can utilize the latest in ML and AI
              </div>
              <div class="instruct-text">
                - Optimize Operations with intuitive Business Intelligence workflows</div>
              </div> -->
            <!-- <div class="mt-10">
              <div class="d-flex instruct-heading">
                <img src="../assets/icons/tick.svg">
                &nbsp;&nbsp;&nbsp;<b>Next-Gen Data Integration</b>
              </div>
              <div class="instruct-text">
                Create an environment where everyone in your organization can communicate with data in a whole new way.
              </div>
            </div> -->
          </v-col>
          <!-- Signin Column -->
          <v-col md="6" class="pa-8 sign-in-form">
            <h1 class="mb-1 mt-0 sign-in-heading">Sign in to Forecastagility</h1>
            <!-- New User Registration Form -->
            <!-- {{ this.envVar }} -->
            
            <!-- <div class="error-login">
              <h3 v-if="loginError">Invalid Login</h3>
            </div> -->
            <!-- form -->
            <v-form class="form-size" @submit.prevent="login">
              <div class="mt-4">
                <v-text-field
                  v-model="email"
                  label="Email / Username"
                  type="text"
                  placeholder="Email"
                  height="40px"
                  class="text-field"
                  outlined
                  dense
                  solo
                ></v-text-field>

                <v-text-field
                  v-model="password"
                  type="password"
                  placeholder="Password"
                  name="Password"
                  label="Password"
                  height="40px"
                  class="text-field"
                  outlined
                  dense
                  solo
                ></v-text-field>

                <!-- <v-alert
                  v-if="error"
                  type="error"
                  dense
                  color="red"
                  outlined
                  text
                  class="mb-5"
                >
                  Access denied, please try again
                </v-alert> -->
                <v-alert
                  v-if="showAlert"
                  :type="alert.type"
                  :color="alert.color"
                  dense
                  outlined
                  text
                  class="mb-5"
                >
                  {{ alert.message}}
                </v-alert>

                <!-- <p v-if="error" class="help has-text-danger">{{ error }}</p> -->
              </div>
            <!-- Signin Button -->
            <div class="my-2 text-right">
                <v-btn v-if="validForm"
                  :ripple="false"
                  depressed
                  rounded
                  large
                  color="grey_text"
                  class="mb-4 login-btn"
                > Sign In
                </v-btn>
                <v-btn v-else
                    :depressed="validForm"
                    rounded
                    large
                    color="primary"
                    type="submit"
                    class="mb-4 login-btn"
                >Sign In</v-btn>
            </div>
            <!-- <div v-if="true" -->
            <!-- Create account -->
            <!-- <div v-if="this.envVar==='sandbox'"
              class="d-flex signup-options">
              <v-dialog 
                v-model="createDialog"
                width="500"
                persistent
              >
                <template v-slot:activator="{ on, attrs }">
                <span 
                  class="sign-up-link">
                  <a v-bind="attrs" v-on="on"> 
                    <v-icon dense color="#1473e6">mdi-check</v-icon>
                    Create Account
                  </a> 
                </span>
                </template>
                <v-card>
                  <v-card-title class="text-h5 d-flex card-title">
                    <v-img
                      src="@/assets/logo.svg" 
                      max-height="30" max-width="30"
                    />
                    <div>Create Your Forecastagility Account</div>
                  </v-card-title>
                  <v-divider/>
                  <v-card-text>
                    <UserForm ref="childComponent"
                      @closeDialog="closeDialog"
                      @displayAlert="displayAlert"
                    ></UserForm>
                  </v-card-text>
                </v-card>
              </v-dialog>
              <!- - Reset Password - ->
              <v-dialog 
                v-model="resetDialog"
                width="500"
                persistent
              >
                <template v-slot:activator="{ on, attrs }">
                <span 
                  class="sign-up-link">
                  <a v-bind="attrs" v-on="on"> 
                    <v-icon dense class="pb-1" color="#1473e6">mdi-lock-reset</v-icon>
                    Reset Password
                  </a> 
                </span>
                </template>
                <v-card>
                  <v-card-title class="text-h5 d-flex justify-content-start">
                    <v-img
                      src="@/assets/logo.svg" 
                      max-height="30" max-width="30"
                    />
                    <div class="ml-6">Reset Password</div>
                  </v-card-title>
                  <v-divider/>
                  <v-card-text>
                    <ResetPassword ref="childComponent"
                      @closeDialog="closeDialog"
                    ></ResetPassword>
                  </v-card-text>
                </v-card>
              </v-dialog>
            </div> -->
              <!-- Signin Button -->
              <!-- v-divider -->
              <!-- <v-divider/> -->
              <!-- <div class="space-line">
                <span class="space-line-text">Login Via Oauth</span>
              </div> -->
              <!-- <div class="mb-10 text-center space-line"> -->
                
                <!-- _______________ <span class="space-line-text">Or</span> _______________ -->
              <!-- </div> -->
              <!-- OpenId Auth Choices -->
              <!-- <div class="mt-5 text-center">
                <!- - google signin - ->
                <v-btn
                    rounded
                    large
                    type="button"
                    class="mb-2 social-btn"
                    @click="testPrint"
                    :ripple="false"
                    depressed
                    :disabled="false"
                    color="grey_text"
                >
                  <img src="../assets/icons/google.svg" class="mr-40">
                  Sign in with Google &nbsp;&nbsp;&nbsp;&nbsp;
                </v-btn>
                <!- - facebook remove? - ->
                <!- - <v-btn
                    rounded
                    large
                    type="button"
                    class="social-btn"
                >
                  <img src="../assets/icons/fb.svg" class="mr-40">
                  Continue with Facebook
                </v-btn> - ->
              </div> -->
              <!-- footer -->
              <div class="footer">
                <div class="text-center foot-text">
                  By continuing, you agree to Forecastagility
                </div>
                <div class="text-center terms-cond">
                  <span class="terms">Terms of Service</span>,
                  <span class="privacy">Privacy Policy</span>.
                </div>
              </div>
            </v-form>
          </v-col>
        </v-row>
      </v-card>
    </div>
  </div>
</template>



<script>
import { LOGIN } from "@/store/actionType";
// import UserForm from "@/views/users/UserForm";
// import ResetPassword from "@/views/users/resetPassword";
// import { CREATE_USER } from "@/store/actionType";
// import SnackbarMessage from "@/views/_partials/snackbarMessage.vue";

// const envVar = process.env.VUE_APP_DEPLOYMENT;

// const deployment_env =process.env.VUE_APP_DEPLOYMENT;

export default {
  name: "Login",

  data() {
    return {
      // deployment_env: process.env.VUE_APP_DEPLOYMENT,
      // name: "test",
      // envVar: ,
      createDialog: false,
      resetDialog: false,
      showPass: false,
      password: "",
      email: "",
      // error: null,
      showAlert: false,
      alert: {
        type: null,
        color: null,
        message: null,
      },
      user: {
        name: "",
        email: "",
      },
      // loginError: false,
      // loginErrorRes: null,
    };
  },
  components: {
    // UserForm,
    // ResetPassword,
    // SnackbarMessage,
  },
  computed: {
    console: () => console,
    window: () => window,
    validForm() {
      // return this.email.length < 1 && this.password.length < 1;
      return this.password.length < 1 || this.email.length < 1;
    }
  },
  methods: {
    closeDialog() {
      this.createDialog=false;
      this.resetDialog=false;
    },
    testPrint() {
      console.log(process.env.VUE_APP_DEPLOYMENT)
    },
    login() {
      //set login data
      // e.preventDefault();
      const loginData = {
        username: this.email,
        password: this.password,
        provider: "db",
      };
      
      this.$store.dispatch(LOGIN, loginData)
        .then((res) => {
          if (res && res.access_token) {
            this.$router.push("/");
            } else {
            // this.error = "Invalid authentication";
            this.alert.message="Invalid Authentication"
            this.alert.color="red"
            this.alert.type="error"
            this.showAlert=true;
            // this.loginError=true;
          }
        })
        .catch( (e) => {
          // this.error = "Invalid authentication";
          this.alert.message="Invalid Authentication"
          this.alert.color="red"
          this.alert.type="error"
          this.showAlert=true;
          console.error("Login error", e)
          // this.loginError=true;
        });
    },
    displayAlert(alert) {
      this.alert.message = alert.message;
      this.alert.color = alert.color;
      this.alert.type = alert.type;
      this.showAlert=true;
    },
  },
  mounted() {},
  created() {
    this.envVar = process.env.VUE_APP_DEPLOYMENT;
  },

};
</script>

<style scoped>

.card-title {
  justify-content: space-evenly;
}

.disable-events {
  pointer-events: none
}


@font-face {
  font-family: AdobeClean;
  src: url("../assets/fonts/AdobeCleanRegular.otf") format("opentype");
}
@font-face {
  font-family: AdobeClean;
  font-weight: bold;
  src: url("../assets/fonts/AdobeCleanBold.otf") format("opentype");
}
@font-face {
  font-family: Roboto;
  src: url("../assets/fonts/Roboto-Regular.woff") format("opentype");
}
@font-face {
  font-family: Roboto;
  font-weight: 400;
  src: url("../assets/fonts/Roboto-Medium.woff") format("opentype");
}
@font-face {
  font-family: Roboto;
  font-weight: bold;
  src: url("../assets/fonts/Roboto-Bold.woff") format("opentype");
}

.loginPage {
  height: 100%;
  width: 100%;
  /* background: rgba(35, 108, 230, 0.12); */
  margin-top: -56px;
}

.loginBlock {
  width: 912px;
  margin: auto;
}

@media (max-width: 800px) {
  .loginBlock {
    width: 640px;
    margin: auto;
  }
}

.error-login {
    color: red; 
    height:24px;
    font-style: italic;
    /* font-weight: 100; */
}
.sign-in-form{
  background: #ffffff;
  color: #000000;
}
.sign-in-heading {
  font-family: Roboto,serif;
  font-weight: 400;
  font-size: 34px;
}
.sign-up-link {
  font-family: Roboto,serif;
  font-weight: 400;
  font-size: 14px;
}
.sign-up-link a {
  text-decoration: none;
}
.login-btn{
  width: 130px;
  font-family: AdobeClean,serif;
  font-size: 17px;
  font-weight: bold;
  margin-top: -15px;
}

.signup-options {
  justify-content: space-around;
  padding-bottom: 20px;
  align-items: center;
}

.space-line{
  /* margin-top: 100px; */
  width: 100%; 
  text-align: center; 
  border-bottom: 1px solid #dedede; 
  line-height: 0.1em;
  margin: 10px 0 20px; 
  /* font-family: AdobeClean,serif; */
  font-size: 17px;
  /* color: #dedede; */
}
.space-line-text{
  background:#fff; 
  padding:0 10px; 
  /* color: #747474; */
  /* line-height: 45px; */
}
.social-btn{
  width: 85%;
  height: 54px !important;
  font-family: Roboto,serif;
  font-weight: 300;
  font-size: 18px;
  background: transparent !important;
  border: 1px solid #dedede !important;
  color: #000000;
  pointer-events: none;
}

.footer {
  margin-top: 30px;
}
.foot-text{
  font-family: Roboto,serif;
  font-weight: 300;
  color: #747474;
}
.terms-cond{
  font-family: Roboto,serif;
  font-weight: 300;
  color: #747474;
}
.terms{
  font-family: Roboto,serif;
  font-weight: 300;
  text-decoration: underline;
  cursor: pointer;
}
.privacy{
  text-decoration: underline;
  cursor: pointer;
}
.mr-40{
  margin-right: 40px
}
.content-div{
  background: #111111;
}
.content-heading{
  font-family: AdobeClean,serif;
  font-size: 26px;
  font-weight: bold;
}
.mt-100{
  margin-top: 100px;
}
.instruct-heading{
  font-family: AdobeClean,serif;
  font-size: 22px;
  /* color: #8C8C8C; */
  color: #2987F8;
}
.instruct-text{
  font-family: AdobeClean,serif;
  color: #8C8C8C;
  font-size: 20px;
}
.mt-70{
  margin-top: 70px;
}

/* .bgImg {
  background-image: url("https://1.cms.s81c.com/sites/default/files/2021-08-04/infosphere-data-replication-overview.jpg");
} */
</style>
<style>
.text-field.theme--dark.v-text-field--solo > .v-input__control > .v-input__slot {
  background: #ffffff !important;
}
.text-field.theme--dark.v-input input, .theme--dark.v-input textarea {
  color: #747474;
}
.text-field.v-text-field.v-text-field--solo .v-label {
  color: #747474;
}
</style>
