import homePage from '@/views/Home';
import ProjectsIndex from '@/views/projects/Index';
import ProjectsDetail from '@/views/projects/Detail';
import ProjectsNew from '@/views/projects/New';

import ChartsIndex from '@/views/charts/Index';

import DashboardsIndex from '@/views/dashboards/Index';
import DashboardsDetail from '@/views/dashboards/Detail';
import DashboardsNew from '@/views/dashboards/New';

import DatasetsIndex from '@/views/datasets/Index';
// import DatasetsDetail from '@/views/datasets/Detail';

import usersIndex from '@/views/users/Index';
import userCreate from '@/views/users/CreateOrUpdate';
// import userCreate from '@/views/users/Create';

// import userCrear from '@/views/users/Create';

import userUpdate from '@/views/users/CreateOrUpdate';
// import customersIndex from '@/views/customers/Index';

// import whitePapersIndex from '@/views/whitepapers/Index';
// import whitePapersCreate from '@/views/whitepapers/CreateOrUpdate';
// import whitepapersUpdate from '@/views/whitepapers/CreateOrUpdate';

// import pressReleasesIndex from '@/views/pressreleases/Index';
// import caseStudiesIndex from '@/views/casestudies/Index';
import login from '@/views/Login';
// import cinemaIndex from '@/views/cinemas/Index';

import sqlPageIndex from '@/views/sqlpage/Index';
import DatabasesIndex from '@/views/database/Index';
import queryHistoryIndex from '@/views/queryHistory/Index';

import SavedQueryIndex from '@/views/savedQuery/Index';
import visualizationIndex from '@/views/visualization/Index';

export default [
  { path: '/', name: 'Index', component: homePage },
  { path: '/favorites', name: 'Favorites', component: homePage },
  { path: '/projects', name: 'Projects-Index', component: ProjectsIndex },
  { path: '/projects/new', name: 'Projects-New', component: ProjectsNew },
  { path: '/projects/:id', name: 'Projects-Detail', component: ProjectsDetail },

  { path: '/charts', name: 'Charts-Index', component: ChartsIndex },
    
  { path: '/dashboards', name: 'Dashboards-Index', component: DashboardsIndex },
  { path: '/dashboards/new', name: 'Dashboards-New', component: DashboardsNew },
  { path: '/dashboards/:id', name: 'Dashboards-Detail', component: DashboardsDetail },

  { path: '/datasets', name: 'Datasets-Index', component: DatasetsIndex },
  // TODO: redirect to open table
  // { path: '/datasets/:id', name: 'Datasets-Detail', component: DatasetsDetail },

  { path: '/users', name: 'UsersIndex', component: usersIndex },
  // { path: '/users-create', name: 'UserCreate', component: userCreate },
  { path: '/signup', name: 'UserCreate', component: userCreate },
  { path: '/users/:id/update', name: 'UserUpdate', component: userUpdate },
  // { path: '/users/crear', name: 'UserCrear', component: userCrear },

  // { path: '/customers', name: 'CustomersIndex', component: customersIndex },
  // { path: '/whitepapers', name: 'WhitePapersIndex', component: whitePapersIndex },
  // { path: '/whitepapers-create', name: 'whitePapersCreate', component: whitePapersCreate },
  // { path: '/whitepapers/:id/update', name: 'WhitePapersUpdate', component: whitepapersUpdate },

  // { path: '/pressreleases', name: 'PressReleases', component: pressReleasesIndex },
  // { path: '/casestudies', name: 'CaseStudiesIndex', component: caseStudiesIndex },
  { path: '/login', name: 'Login', component: login },
  // { path: '/cinemas', name: 'CinemaIndex', component: cinemaIndex },

  { path: '/sql-page', name: 'SqlPageIndex', component: sqlPageIndex },
  { path: '/databases', name: 'DatabasesIndex', component: DatabasesIndex },
  { path: '/query-history', name: 'QueryHistoryIndex', component: queryHistoryIndex },
  { path: '/saved-query', name: 'SavedQueryIndex', component: SavedQueryIndex },

  { path: '/visualizations/:sliceId/:forDatakey', name: 'VisualizationPage', component: visualizationIndex },
];