<template>
  <v-row wrap no-gutters class="separate">
    <v-col cols="12" sm="1" class="mb-auto mt-auto">
      <div class="divider"></div>
    </v-col>
    <v-col class="divider-text">{{ text }}</v-col>
    <v-col cols="12" sm="10"  class="mb-auto mt-auto">
      <div class="divider"></div>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      default: "",
    },
  },
};
</script>

<style lang="scss" scoped>
.separate {
  margin-top: 30px;
}

.divider {
    height: 2px;
    background: #707070;

    &-text {
        margin-left: 16px;
    }
}
</style>