<template>
  <div
    class="d-flex flex-column fill-height flex-grow-1 overflow-hidden pt-6"
  >
    <NewDB 
      :selectedDB="selectedDB"
      :showNewDB="showNewDB" 
      :editItemId="editItemId"
      @cancel="cancelNewDB" 
      @db_success="db_success"
    />
    <ModuleHeader
      title="Databases" 
      :disabled-grid-btn="true"
      :disabled-list-btn="true"
      :disabled-fav-btn="true"
      :view-grid="false"
      returnToPage="Index"
    />
    <ModuleAction
      title="New Database"
      search-label="Search Databases"
      :selectedItems="[]"
      :n_items="filteredItemCount"
      @selectItemsEvent="selectItemsEvent"
      @clickNewItem="clickNewItem"
      
      @moduleFilterChanged="moduleFilterChanged"
    />
    <ErrorDialog
      :showModal="errModalOpen"
      :message="errorMsg"
      @close="closeErrDialog"
    />

    <div class="d-flex flex-grow-1 flex-column flex-basis-0
        fill-height overflow-x-auto"
    >
      <v-container>
        <v-row v-if="loading" class="container-row-resolution py-5 d-flex justify-center">
          <v-progress-circular
            indeterminate
            color="primary"
          ></v-progress-circular>
        </v-row>

        <v-data-table
          id="tblItems"
          v-else-if="getModels(collection, viewFavOnly).length"
          v-model="selectedItems"
          :items="getModels(collection, viewFavOnly)"
          :items-per-page="$pageItemCount"
          :headers="headers"
          :search="search"
          @click:row="editItem"
          @item-selected="updateGridViewSelection"
          show-select
          item-key="id"      
          class="mt-3"
          :hide-default-footer="true"
          :header-props="{sortIcon: 'mdi-chevron-down'}"
        >
          <template v-slot:top="{ pagination, options, updateOptions }">
            <v-data-footer 
              :pagination="pagination" 
              :options="options"
              @update:options="updateOptions"
              items-per-page-text="$vuetify.dataTable.itemsPerPageText"/>
          </template>
          <template #[`item.backend`]="{ item }">
            <v-chip color="primary" label>{{ item.backend }}</v-chip>
          </template>
          <template #[`item.allow_run_async`]="{ item }">
            <v-icon v-if="item.allow_run_async" color="primary"
              >mdi-check-circle-outline</v-icon
            >
            <v-icon v-else color="primary">mdi-close-circle-outline</v-icon>
          </template>
          <template #[`item.action`]="{ item }">
            <RowAction 
              :item="item" 
              @editItem="editItem" 
              @deleteItem="deleteItem"
              @infoItem="infoItem"
              :disabledList="disabledRowActions"
            />
          </template>
        </v-data-table>
        <!-- No DB found msg -->
        <v-row v-else class="container-row-resolution pt-5 d-flex justify-center">
          <p>No Databases Found ...</p>
        </v-row>
      </v-container>
    </div>
    <!-- Info Nav Drawer -->
    <v-navigation-drawer
      v-model="detailModalVisible"
      hide-overlay
      right
      temporary
      absolute
      color="#262626"
      
      :width="navigation.width"
    >
    <v-list-item>
      <v-card 
        v-if="dbInfoMap" 
        width="500"
        color="#262626"
        flat
      >
        <v-card-title>Selected DB: {{ dbInfoMap[0].database_name }}</v-card-title>
        <v-divider/>
        <v-container overflow-hidden>
            <v-data-table
              :headers="[
                {text: 'key', value: 'key'},
                {text: 'value', value: 'value'},
                ]"
              :items="dbInfoMap[1]"
            >
            </v-data-table>
        </v-container>
      </v-card>
      </v-list-item>
    </v-navigation-drawer>
  </div>
</template>

<script>
import ModuleHeader from "@/views/_partials/ModuleHeader";
import ModuleAction from "@/views/_partials/ModuleAction";
import RowAction from "@/views/_partials/RowAction";
import NewDB from "@/components/modal/NewDB.vue";
import ErrorDialog from "@/components/modal/ErrorDialog";

import { 
  GET_DATABASE_ALL, 
  GET_DATABASE_BY_ID,
  UPLOAD_FILE, 
  DELETE_DATABASE 
} from "@/store/actionType";

// import Database from './database_utils.js'
import { 
    toggleSelectAll,
    modifyAllCheck,
    selectItemsEvent,
    filteredObjs,
  } from "@/utils/moduleUtils.js";


export default {
  name: "DatabasesIndex",
  components: {
    ModuleHeader,
    ModuleAction,
    NewDB,
    ErrorDialog,
    RowAction
  },
  data: () => ({
    viewGrid: false,
    viewFavOnly: false,
    selectedItems: [],
    search: '',
    editItemId: null,
    //table headers
    headers: [
      { text: "Name", value: "database_name" },
      { text: "Database", value: "backend" },
      { text: "Async", value: "allow_run_async" },
      { text: "Created By", value: "created_by.username" },
      { text: "Last Modified", value: "changed_on_delta_humanized" },
      { text: "Actions", value: "action", width: 200, sortable: false },
    ],
    moduleFilter:'',
    collection:[],
    loading: false,
    //selected all
    selected_all: false,
    //checkbox indeterminate
    indeterminate: false,
    //show New database dialog
    showNewDB: false,
    //show New from database dialog
    showNewFromDB: false,
    //show New from file dialog
    showNewFromFile: false,
    //show New from file advance dialog
    showNewFromFileAdvance: false,
    //advanced from file value
    advance_file: null,
    // show Error Modal
    errModalOpen: false,
    errorMsg: 'We have an error',
    detailModalVisible: false,
    dbInfoMap: [{'database_name':''},[]],
    // TODO create class ehre
    selectedDB: null,//new Database,
    navigation: {
        shown: false,
        width: 500,
        borderSize: 3
    },
    disabledRowActions: {
      'edit':false, 
      'info': false,  // todo
      'trashcan':false,  // todo
      'action': true
    },
  }),
  mounted() {
    this.load();
  },
  computed: {
    filteredItemCount () {
      return [ this.collection.length, this.getModels(this.collection, this.viewFavOnly).length]
    },
    selectedItemIds () {
      let id_list = this.selectedItems.map(a => a.id);
      return id_list;
    },
  },
  methods: {
    // Imports
    //////////
    //moduleUtils
    toggleSelectAll,
    modifyAllCheck,
    selectItemsEvent,
    filteredObjs,
    //////////
    moduleFilterChanged (input) {
      this.moduleFilter = input;
    },
    db_success() {
      this.load();
      this.showNewDB = false;
    },
    getRecentModels(arr) {
      //This module doesn't have Favorite
      return arr;
    },
    getModels(arr) {
      //This module doesn't have Favorite
      // return this.getRecentModels(arr)
      arr = this.getRecentModels(arr)
      if (arr.length > 0 && this.moduleFilter.length > 0) {
        arr = this.filteredObjs(arr, 'database_name', this.moduleFilter)
      }
      return arr
    },
    load() {
      const columns = [
        "id",
        "changed_by.username",
        'created_by.first_name',
        'created_by.last_name',
        'created_by.username',
        "changed_on_utc",
        "changed_on_delta_humanized",
        "backend",
        "database_name",
      ];
      
      const q= {
        "columns": columns,
        "order_column": this.curSortField,
        "order_direction": "desc",
      };

      // Load Recent Objects
      // Database doesn't have favorites
      this.loading = true
      this.$store
        .dispatch(GET_DATABASE_ALL, q)
        .then((r) => {
          this.collection = r.data.result;
        })
        .catch((r) => {
          console.error("We have error:", r);
        })
        .finally(() => {
          this.loading = false
        })
    },

    /**
     * get header class
     */
    getHeader(header) {
      if (header.value === "name" || header.value === "database")
        return "header-left";
    },
    /**
     * select all rows
     */
    selectAll(event) {
      for (let i = 0; i < this.collection.length; i++) {
        this.collection[i].selected = event;
      }
    },
    /**
     * select one row
     */
    selectRow() {
      const isTrue = (element, index) => {
        return element.selected === true;
      };
      if (this.collection.every(isTrue)) {
        this.selected_all = true;
        this.indeterminate = false;
      } else {
        this.selected_all = false;
        let find = this.collection.find((item) => item.selected == true);
        if (find) {
          this.indeterminate = true;
        } else {
          this.indeterminate = false;
        }
      }
    },
    /**
     * menu event
     * @param item
     */
    // menuEvent(item) {
    //   switch (item.action) {
    //     case "custom_sql":
    //       this.$router.push("/sql-page");
    //       break;
    //     case "from_db":
    //       this.showNewFromDB = true;
    //       break;
    //     case "from_file":
    //       this.showNewFromFile = true;
    //       break;
    //     case "new_db":
    //       this.showNewDB = true;
    //       break;
    //   }
    // },
    /**
     * cancel new DB
     */
    cancelNewDB() {
      this.showNewDB = false;
      // this.selectedDB= new Data;
      // this.getDatabases();
    },
    /**
     * cancel new from DB
     */
    cancelNewFromDB() {
      this.showNewFromDB = false;
    },
    /**
     * go to datasets page
     */
    goToDatasets() {
      this.showNewFromDB = false;
      this.$router.push("/datasets");
    },
    /**
     * cancel new from file
     */
    cancelNewFromFile() {
      this.showNewFromFile = false;
    },
    /**
     * Close Error Dialog Box
     */
    closeErrDialog() {
      this.errModalOpen = false;
    },
    /**
     * save from file
     */
    saveFile(data) {
      if (this.advance_file) {
        data.append("index_col", this.advance_file.index_col);
        data.append("skip_rows", this.advance_file.skip_rows);
        data.append("n_rows", this.advance_file.n_rows);
        data.append("mangle_dupe_cols", this.advance_file.mangle_dupe_cols);
        data.append("skip_spaces", this.advance_file.skip_spaces);
        data.append("skip_blank_lines", this.advance_file.skip_blank_lines);
        data.append("dataframe", this.advance_file.dataframe);
        data.append("use_columns", this.advance_file.use_columns);
        data.append("index_label", this.advance_file.index_label);
        data.append("decimal_char", this.advance_file.decimal_char);
        data.append("parse_dates", this.advance_file.parse_dates);
        data.append("infer_datetime", this.advance_file.infer_datetime);
        data.append("null_values", this.advance_file.null_values);
      }
      this.$store.dispatch(UPLOAD_FILE, data).then((res) => {
        this.showNewFromFile = false;
      })
    },
    /**
     * close new from DB and show new DB
     */
    showCreateDB() {
      this.showNewFromDB = false;
      this.showNewDB = true;
    },
    /**
     * cancel new from file advance
     */
    cancelNewAdvance() {
      this.showNewFromFileAdvance = false;
    },
    /**
     * save new from file advance
     */
    saveNewAdvance(val) {
      this.advance_file = val;
      this.showNewFromFileAdvance = false;
    },
    /**
     * show new from file advance
     */
    showAdvance() {
      this.showNewFromFileAdvance = true;
    },
    deleteItem(item) {
      this.$store
        .dispatch(DELETE_DATABASE, item.id)
        .then(() => {
          this.load();
        })
        .catch((error) => {
          this.errorMsg = 'While removing';
          this.errModalOpen = true;
        });
    },
    infoItem(item) {
      console.log('item', item)
      let _keys = Object.keys(item)
      let kv_map=[]
      //_keys.map( a => {
        // item['backend']
      // })

      for (const property in item) {
        console.log('ke', property, item[property] )
        kv_map.push({"key":property, "value": JSON.stringify(item[property]) })
      }
      this.detailModalVisible = true;
      this.dbInfoMap = [item, kv_map];
    },
    deleteAll() {
      // Remove ability to delete all the databases for protection
      // let id_list = this.selectedItemIds
      // console.log('delete thise:', id_list)
      // let selIds = id_list;
      // this.$store
      //   .dispatch(DELETE_PROJECT_MULTI, selIds)
      //   .then(() => {
      //     this.load();
      //   })
      //   .catch((r) => {
      //     console.error("We have error:", r);
      //   });
    },
    editItem(item) {
      console.log('item', item)
      this.editItemId=item.id
      this.showNewDB = true;
    },
    clickNewItem() {
      // let newDB = new Database()
      // this.selectedDB={'database_name':'new title'}//newDB;
      // this.selectedDB = new Database()
      // this.selectedDB.database_name="afdasfasd"
      // console.log('new db', this.selectedDB)
      this.editItemId=null
      // this.cardTitle="New Database"
      this.showNewDB = true;
    },
    // async loadInfoItem(id) {
    //   let db_detail = await this.$store
    //     .dispatch(GET_DATABASE_BY_ID, id)
    //     .then((res) => {
    //       return res.data.result;
    //     })
    //     .catch((error) => {
    //       this.errorMsg = 'While removing';
    //       this.errModalOpen = true;
    //     });
    //     return db_detail;
    // },
    // resolveAfter2Seconds() {
    //   return new Promise(resolve => {
    //     setTimeout(() => {
    //       resolve('resolved');
    //     }, 2000);
    //   });
    // },
    // async editItem(item) {
    //   // this.$router.push(
    //     // { name: 'Databases-Detail', 
    //     // params: { id: item.id } 
    //   // });
    //   // this.loading=true;
    //   const db_detail = await this.loadInfoItem(item.id)
    //   // let db_detail = await this.resolveAfter2Seconds()
    //   console.log('og', db_detail)
    //   // let editDB = new Database()
    //   // this.selectedDB = editDB.load(editDB, db_detail)
    //   // // this.loading=false;
    //   // // console.log('edit', this.selectedDB)
    //   // this.cardTitle="Edit Database";
    //   // this.showNewDB=true;
      
    // },
    /**
     * Update the selection status in Grid View
     */
    updateGridViewSelection(obj) {
      let selItem = this.collection.find(item => item.id === obj.item.id)
      if (selItem) {
        selItem.checked = obj.value
      }
    },
    /**
     * Update the selection status in Grid View
     */
    toggleChecked(item) {
      let index = this.selectedItems.findIndex(_item => _item.id === item.id)
      if (item.checked === true) {
        if (index == -1) {
          this.selectedItems.push(item)
        }
      }
      else {
        if (index > -1) {
          this.selectedItems.splice(index, 1)
        }
      }      
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
