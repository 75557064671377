<template>
  <div
    class="d-flex flex-column fill-height flex-frow-1 pt-6 pl-12 pr-12 w-100"
  >
    <!--  -->
    <RenameTab v-if="queryTabs.length>0"
      :showRename="showRename"
      @rename="renameTab"
      @cancel="showRename=false"
      :selectedTab="selectedTab.id"
      :tabName="selectedTabName"
    />
    <ModuleHeader
      title="SQL Explorer"
      :showLayoutOption="false"
      :disabled-grid-btn="true"
      :disabled-list-btn="true"
      :disabled-fav-btn="true"
    />

    <div 
      class="pl-6 pt-3">
      <template>
        <div class="d-flex flex-row mt-6 w-100 query-tabs">
          <!-- TODO: Refactor to v-tabs -->
          <!-- v-for="(queryTab, index) in queryTabs" -->
          <!-- :index="index" -->
          <QueryTab
            v-for="queryTab in queryTabs"
            :key="queryTab.id"
            :queryTab="queryTab"
            @click.native="selectQuery(queryTab.id)"
            v-on:dblclick.native="enableRename(queryTab.id)"
            @deleteQuery="deleteQuery"
            @menu-action="menuAction"
          />
          <v-btn icon class="ml-4" @click="addQueryTab">
            <v-icon color="grey">mdi-plus-circle</v-icon>
          </v-btn>
        </div>
        <div v-if="queryTabs.length > 0"
          class="query-container mt-6">
          <QueryDb :selectedTab="selectedTab" />
          <QueryCalc />
        </div>
        <div v-else class="container-row-resolution pt-5 d-flex justify-center">
          <p>No Tabs found ...</p>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
// import language js
import "codemirror/mode/sql/sql.js";
// theme css
import "codemirror/theme/mbo.css";
// import MainHeader from "@/components/layout/MainHeader";
import ModuleHeader from "@/views/_partials/ModuleHeader";
import QueryTab from "@/views/sqlpage/QueryTab";
import QueryDb from "@/views/sqlpage/QueryDb";
import QueryCalc from "@/views/sqlpage/QueryCalc";
import RenameTab from "@/views/sqlpage/RenameTab";
// import { v4 as uuidv4 } from "uuid";
import SqlTab from "./tabClass.js";

export default {
  name: "sqlPageIndex",
  components: {
    ModuleHeader,
    QueryTab,
    RenameTab,
    QueryDb,
    QueryCalc,
  },
  data() {
    return {
      //query tabs
      queryTabs: [],
      queryTabObject: {
        name: "",
        active: null, // Boolean
        id: "",//uuidv4(),
        code: "-- sql Explorer",
        database: null,
        schema: "",
        table: "",
        tableData: null,
        queryResults: null,
      },
      //codemirror options
      cmOptions: {
        tabSize: 4,
        styleActiveLine: true,
        lineNumbers: true,
        line: true,
        mode: "text/x-mysql",
        theme: "mbo",
      },
      //inputed code
      code: "-- sql Explorer",
      //show rename modal
      showRename: false,
      //selected tab name
      selectedTabName: "",
      showLab:false,
    };
  },
  computed: {
    sqlLab: {
      get() {
        return this.$store.state.query.sqlLab;
      },
      set(val) {
        this.$store.commit("SQLLAB", val);
      },
    },
    selectedTabIndex: {
      get() {
        console.log('selTabInx get')
        return this.$store.state.query.sqlLab_selected_tabIndx;
      },
      set(val) {
        console.log('selTabInx set', val)
        this.$store.commit("SELECTED_TABINDEX", val);
      },
    },
    selectedTab: {
      get() {
        return this.$store.state.query.sqlLab_selected_tab;
      },
      set(val) {
        this.$store.commit("SELECTED_TAB", val);
      },
    },
  },
  async mounted() {
    // console.log('mounted sqllab', this.sqlLab)
    // console.log('mounted tabs', this.QueryTabs)
    if (this.sqlLab) {
      // console.log('initial sqllab')
      this.queryTabs = this.sqlLab.query_tabs;
    } else {
      // console.log('create new')
      // console.log('new obj', new SqlTab("Untitled 1"))
      let initialTab = new SqlTab("Untitled Query 1")
      initialTab.active=true;
      
      this.selectedTab = initialTab
      // this.selectedTabIndex = this.initialTab.id;
      this.queryTabs.push(initialTab)
      // // // console.log('before store', this.queryTabs)
      this.setStore();
      // this.selectedTabIndex = this.initialTab.id;
    }
  },
  methods: {
    findDefaultTabName() {
      let str_find = "Untitled Query ";
      let untitled_objects = this.queryTabs.filter(function(item) {
        return (item.name.startsWith(str_find))
      });
      // console.log('name_array', untitled_objects)
      // let name_array = [
        // "Untitled Query 5",
        // "Untitled Query 11",
        // "Untitled Query 444",
      // ];
      let untitled_array = [];
      let newTabIndex = 1;
      if (untitled_objects.length > 0) {
        let name_array = untitled_objects.map(
          function(item) {
          return item.name
        });
        for (const tab in name_array)
        {
          let _name = name_array[tab]
          untitled_array.push(_name.slice(str_find.length))
        }
        newTabIndex = Math.max(...untitled_array) + 1;
      }
      let newtabName = str_find + newTabIndex;
      return newtabName
    },
    createNewTab() {
      let newTabName = this.findDefaultTabName();
      let newTab = new SqlTab(newTabName)
      newTab.active=false;
      // this.queryTabs.push(newTab)
      // // this.selectedTabIndex = this.selectedTab.id;
      console.log('new tab', newTab)
      return newTab
    },
    /**
     * add new query tab to query tabs
     */
    addQueryTab() {
      let newTab = this.createNewTab()
      console.log('new tab', newTab)
      this.queryTabs.push(newTab)
      // let index = this.queryTabs.length - 1
      // let newTabKey = this.queryTabs
      this.selectQuery(newTab.id);
      this.setStore()
    },
    /**
     * set store with current tabs
     */
    setStore() {
      let new_sqlLab = {
        ...this.sqlLab,
      };
      console.log('new sqllab', new_sqlLab)
      new_sqlLab.query_tabs = this.queryTabs;
      this.sqlLab = new_sqlLab;
    },
    findTabIndex(tabKey) {
      let tabIndex=null;
      this.queryTabs.forEach( (tab, i) => {
        if (tab.id === tabKey) {
          tabIndex = i
        }
      });
      return tabIndex;
    },
    deleteQuery(key) {
      // Delete SQL tab given tab.id (uuid key)
      let tabIndx = this.findTabIndex(key)
      // console.log('del', key, tabIndx)
      this.deleteTab(tabIndx)
    },
    /**
     * delete query from query tabs
     * @param value tab index
     */
    deleteTab(delIndex) {
      // console.log('delete me', this.queryTabs, value)
      // // this.setStore();
      if (delIndex === 0 && this.queryTabs.length > 0) {
        this.queryTabs[0].active = true;
      } else if(this.queryTabs.length > 0) {
        this.queryTabs[delIndex - 1].active = true;
      }
      this.queryTabs.splice(delIndex, 1);
      // maintain at least one tab
      if (this.queryTabs.length === 0) {
        // console.log('create new tab')
        this.addQueryTab()
      }
      this.setStore();
    },
    /**
     * query tab menu action
     * @param item menu item
     * @param value tab index
     */
    menuAction(item, value) {
      switch (item.action) {
        case "rename":
          this.enableRename(value);
          break;
        case "close":
          this.deleteQuery(value);
          break;
        case "close-others":
          console.log('close others', value)
          // var blah = this.queryTabs.filter((tab, index, arr) => {
          //   arr.pop()
          //   return tab.id !== value
          // });
          // console.log('blah',blah)
          this.queryTabs.slice().reverse().forEach( tab => {
            if (tab.id !== value) {
              // console.log('del', tab.id)
              this.deleteQuery(tab.id)
            }
          });
          // this.queryTabs = [this.queryTabs[value]];
          // this.setStore();
          break;
      }
    },
    /**
     * open rename tab modal
     * @param index tab index
     */
    enableRename(index) {
      console.log('enable', this.selectedTab.name)
      this.selectedTabName = this.selectedTab.name
      // this.selectedTabIndex =
      // this.selectedTabIndex = index;
      // this.selectedTabName = this.queryTabs[this.selectedTabIndex].name;
      this.showRename = true;
    },
    // cancelRename() {
      // console.log('cnx parent')
      // this.showRename=false;
    // },
    /**
     * rename query tab
     * @param name
     */
    renameTab(name, tab) {
      // TODO: Show warning if tab named matches another tab
      let tabIndx = this.findTabIndex(tab)
      console.log('rename', tab, name)
      this.queryTabs[tabIndx].name = name;
      this.showRename = false;
      this.setStore();
    },
    /**
     * select query from query tabs
     * @param index
     */
    selectQuery(tab_key) {
      console.log('select me', tab_key)
      let newIndex = this.queryTabs.map(
        tab => tab.id === tab_key).indexOf(true)
      this.queryTabs.map(
        tab => tab.active =false)
      // console.log('select tab', this.queryTabs[newIndex])

      this.queryTabs[newIndex].active = true;
      // console.log('select tab', newIndex)
      this.selectedTab = this.queryTabs[newIndex];
      this.setStore();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~ag-grid-community/dist/styles/ag-grid.css";
@import "~ag-grid-community/dist/styles/ag-theme-alpine-dark.css";

.w-100 {
  width: 100%;
}

.query {
  &-tabs {
    border-bottom: 1px solid grey;
  }

  &-tab {
    color: grey;
    cursor: pointer;
    background:#262626;
    padding: 0px 6px;
    border-right: solid 1px grey;

    &.active {
      color: white;
      border-bottom: 3px solid white;
      border-right: solid 0px;
    }
  }

  &-container {
    display: grid;
    grid-template-columns: 20% auto;
  }
}
</style>
