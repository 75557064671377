<template>
  <v-btn
    :color="btnColor"
    :disabled="disabled"
    width="96px"
    height="36px"
    outlined
    @click="emitFunc"
  >
    {{ action }}
  </v-btn>
</template>

<script>
export default {
  name: "CardAction",
  props: [
      'btnColor', 
      'actionFunc',
      'action',
      'disabled',
    ],
  data() {
    return {
    };
  },
  methods: {
    emitFunc() {
      this.$emit(this.actionFunc)
    }
  },
}
</script>
